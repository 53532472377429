import { REMARKS_FEEDBACK } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const FEEDBACK = `${REMARKS_FEEDBACK}/feedback?year=${year}`;

export const REMARKS = `${REMARKS_FEEDBACK}?year=${year}`;

export const UPDATE_REMARKS = (id) => `${REMARKS_FEEDBACK}/remark/${id}?year=${year}`;

export const STUDENT_REMARKS = (id) => `${REMARKS_FEEDBACK}/${id}?year=${year}`;
