import React, { useEffect, useState } from 'react';
import IncidetReported from '../../../components/Owner/IncidentReport/IncidetReported';
import ViewIncident from '../../../components/Owner/IncidentReport/ViewIncident';

import useApi from '../../../api/useApi';
import { INCIDENT } from '../../../api/urls/incident';

function index() {
  const [open, setOpen] = useState(false);
  const [selectedIncident, selectIncident] = useState();
  const [incidents, setIncidents] = useState([]);

  const [{ isLoading, isError, data }, getIncidents] = useApi();

  useEffect(() => {
    if (selectedIncident) {
      toggleView();
    }
  }, [selectedIncident]);

  useEffect(() => {
    getIncidents(INCIDENT, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setIncidents(data);
    }
  }, [isLoading, isError, data]);

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    selectIncident(null);
    toggleView();
  };

  return (
    <div className="w-full md:w-4/5 pb-10">
      {open && <ViewIncident handleClose={handleClose} selectedIncident={selectedIncident} />}
      <IncidetReported selectIncident={selectIncident} data={incidents} isLoading={isLoading} />
    </div>
  );
}

export default index;
