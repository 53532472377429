import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderTabs from './CalenderTabs';
import useApi from '../../../api/useApi';
import { CREATE_CALANDER, GET_CALANDER } from '../../../api/urls/institute';
import moment from 'moment';
import CustomToolbar from './CustomToolBar';
import { useStateValue } from '../../../ContextApi/StateProvider';

const AcademicCalender = () => {
  const [showCalender, setShow] = useState(false);
  const [calanderId, setCalanderId] = useState('');
  const [calanderData, setCalanderData] = useState(false);
  const [perm, setperm ] = useState(false);

  const currentYear = moment().year();
  const startDate = moment(`${currentYear}-04-01`).toDate();
  const endDate = moment(`${currentYear + 1}-03-31`).toDate();

  const localizer = momentLocalizer(moment);

  const [holidays, createCalander] = useApi();
  const [calanderRes, getCalander] = useApi();

  const [{ user, openMenu }, dispatch] = useStateValue();

  const handleClick = () => {
    let CurRole = GetCurrRole()  ;
    createCalander(CREATE_CALANDER(CurRole), 'POST', {});
  };

  useEffect(() => {
    getCalander(GET_CALANDER, 'GET');
  }, []);

  useEffect(() => {
    if (holidays.status === 200) {
      setShow(true);
      setCalanderId(holidays?.data?._id);
    }

    if (holidays.isError) {
      alert(typeof holidays?.data === 'string' ? holidays?.data : 'Some error occured');
    }
  }, [holidays]);

  const handleShowMainCalander = (data) => {
    setCalanderData(data);
  };
  const GetCurrRole = () => {
    let checkPerm = user.roles
    if( checkPerm.length > 0 ){ checkPerm = checkPerm[0].role }
    else{ checkPerm = "" ;  }
    // console.log(" curr role : ", checkPerm ) ;
    return checkPerm ;
  }

  useEffect(() => {  // console.log(" -- calanderRes : ", calanderRes )
    if( calanderRes && calanderRes?.data ){
      if ( Array.isArray(calanderRes.data?.cal_list) && calanderRes.data?.cal_list.length > 0 ) {
        setCalanderData(calanderRes?.data?.cal_list);
      }
      if(Array.isArray(calanderRes.data?.perm) ){
        let CurRole = GetCurrRole()  ;
        setperm(calanderRes.data?.perm.includes(CurRole) );
      }
    }
    
   
  }, [calanderRes]);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.eventType === 'Gazetted Holiday' && {
        className: 'gazettedHolidayColor',
      }),
      ...(event.eventType === 'Regional Holiday' && {
        className: 'regionalHolidayColor',
      }),
      ...(event.eventType === 'Restricted Holiday' && {
        className: 'restrictedHolidayColor',
      }),
      ...(event.eventType === 'State Holiday' && {
        className: 'stateHolidayColor',
      }),
      ...(event.eventType === 'Other Holiday' && {
        className: 'otherHolidayColor',
      }),
      ...(event.eventType === 'Summer Holidays' && {
        className: 'summerHolidayColor',
      }),
      ...(event.eventType === 'Winter Holidays' && {
        className: 'winterHolidayColor',
      }),
    }),
    []
  );

  const calEvents = [];
  Array.isArray(calanderData)
    ? calanderData.forEach((x) => {
        x.events.forEach((event) => {
          calEvents.push({
            title: event?.key,
            start: new Date(x.date),
            eventType: event.eventType || x?.type,
            end: new Date(x.date),
          });
        });
      })
    : [];

  if (calanderRes.isLoading) return <img src="assets/img/loader.svg" className="my-10 mx-auto" />;

  return (
    <div className="w-full md:w-4/5">
      <div className="relative pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow mb-1">
            <p className="text-center font-semibold text-lg">Academic Calander 2024 - 2025</p>
          </div>

          {calanderData ? (
            <Calendar
              localizer={localizer}
              events={calEvents}
              style={{ height: 500 }}
              views={['month']}
              eventPropGetter={eventPropGetter}
              min={startDate}
              max={endDate}
              components={{
                toolbar: CustomToolbar, // Use the custom toolbar
              }}
            />
          ) : !perm ? (<div className="py-6 text-center"> Academic Calander Not Created</div> ) 
          : !showCalender ? (
            <div className="py-6">
              <button
                className="bg-bgBlue text-white flex items-center gap-x-2 text-sm rounded-full px-6 py-2 mx-auto"
                onClick={handleClick}
                disabled={holidays.isLoding}
              >
                <img src="assets/img/AI.svg" />
                Create Academic Calendar
              </button>
            </div>
          ) : (
            <CalenderTabs calanderId={calanderId} showCalander={handleShowMainCalander} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AcademicCalender;
