import React, { useState, useEffect } from 'react';
import History from '../../../components/Finance/Leave/History';
import Apply from '../../../components/Finance/Leave/Apply';
import Deatils from '../../../components/Finance/Leave/Deatils';
import Balance from '../../../components/Finance/Leave/Balance';

import useApi from '../../../api/useApi';
import { LEAVE_TYPES, STAFF_USER_LEAVE_HISTORY, STAFF_ATTENDANCE_HISTORY } from '../../../api/urls/attendance';
import Calender from '../../../components/Teacher/Leave/Calender';
import AttendanceRegularization from '../../../components/Finance/Leave/AttendanceRegularization';
import { useStateValue } from '../../../ContextApi/StateProvider';
import RegularizationPopup from '../../../components/Finance/Leave/RegularizationPopup';

const Leave = ({ role }) => {
  const [details, setDetails] = useState();
  const [selected, select] = useState();
  const [leaveTypes, setLeaveTypes] = useState([]);

  const [leaveTypesState, getLeaveTypes] = useApi();

  const [{ isLoading, isError, data }, getLeaveHistory] = useApi();
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [attendanceRes, getAttendance] = useApi();
  const [{ user }, _] = useStateValue();

  useEffect(() => {
    if (user) getAttendance(STAFF_ATTENDANCE_HISTORY(user.user._id), 'GET');
  }, [user]);

  useEffect(() => {
    getLeaveHistory(STAFF_USER_LEAVE_HISTORY, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setLeaveHistory(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    getLeaveTypes(LEAVE_TYPES, 'GET');
  }, []);

  useEffect(() => {
    if (!leaveTypesState.isLoading && !leaveTypesState.isError && leaveTypesState.data) {
      setLeaveTypes(leaveTypesState.data);
    }
  }, [leaveTypesState]);

  const toggleDetails = () => {
    setDetails((prev) => !prev);
  };

  const handleClose = (refresh = false) => {
    select(null);
    if (refresh) getAttendance(STAFF_ATTENDANCE_HISTORY(user.user._id), 'GET');
  };

  const refreshList = () => {
    getLeaveHistory(STAFF_USER_LEAVE_HISTORY, 'GET');
    getLeaveTypes(LEAVE_TYPES, 'GET');
  };

  return (
    <div className="w-full md:w-4/5 relative pb-10">
      {details && <Deatils onClose={toggleDetails} details={details} setDetails={setDetails} />}

      {selected && <RegularizationPopup handleClose={handleClose} data={selected} />}

      {role === 'Teacher' && <Calender data={attendanceRes} />}

      <Apply refreshList={refreshList} />

      <Balance leaveTypes={leaveTypes} />

      <History openDetails={setDetails} leaveHistory={leaveHistory} />

      <AttendanceRegularization data={attendanceRes} select={select} />
    </div>
  );
};
export default Leave;
