import React from 'react';
import moment from 'moment';

function StaffList({ setSearch, searchName, handleOpenViewMore, searchedData }) {
  return (
    <div
      className="flex h-full w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-3/4 text-sm m-auto mt-16 block rounded-2xl relative">
        <span className="block px-6  font-bold py-5 border-b border-borderYellow">
          {`Search Results for ${searchName}`}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-5 right-8 h-3 cursor-pointer"
          alt=""
          onClick={() => setSearch(false)}
        />
        <div className="flex flex-wrap w-full">
          <div className="w-full py-4">
            <div className="bg-white m-auto border border-borderYellow mx-6 rounded-2xl">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative px-2 text-xs font-medium">
                  <div className="w-3/12 border-r-2 p-2">Photograph</div>
                  <div className="w-3/12 border-r-2 p-2">ROLEDin ID</div>
                  <div className="w-3/12 border-r-2 p-2">Name</div>
                  <div className="w-3/12 border-r-2 p-2">Designation</div>
                  <div className="w-4/12 border-r-2 p-2">Joining Date</div>
                  <div className="w-1/4 p-2">Action</div>
                </div>
              </div>
              <div
                className="customScrollBar"
                style={{
                  maxHeight: '40vh',
                }}
              >
                {searchedData.length > 0 ? (
                  searchedData.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                          searchedData.length - 1 != idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-3/12 px-4">
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src="https://static.overlay-tech.com/assets/efd1340a-8208-44a5-b4b0-e08eb253cf93.png"
                          />
                        </div>
                        <div className="w-3/12 text-tableRowText px-2">{x?.username}</div>
                        <div className="w-3/12 px-2">{x?.full_name}</div>
                        <div className="w-3/12 text-tableRowText px-2">
                          {x.jobProfile && x.jobProfile[x.jobProfile.length - 1]?.title}
                        </div>
                        <div className="w-4/12 text-tableRowText px-2">
                          {x.jobProfile && x.jobProfile[x.jobProfile.length - 1].startDate
                            ? moment(x.jobProfile[x.jobProfile.length - 1].startDate).format('ll')
                            : ''}
                        </div>
                        <div className="w-1/4 flex justify-center items-center">
                          <button
                            className="w-4/5 border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                            onClick={() => {
                              handleOpenViewMore(x);
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffList;
