import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { MEETING, PARENT_TEACHER_MEETING, UPCOMING_PARENT_TEACHER_MEETING } from '../../../api/urls/appointment';
import { GET_NON_TEACHING_STAFF, GET_TEACHING_STAFF } from '../../../api/urls/user';
import DropDown from '../../../components/UI/DropDown';
import ScheduleMeeting from '../../../components/Owner/Meeting/ScheduleMeeting';
import Search from '../../../components/UI/Search';
import ParentMeetingRequest from '../../../components/Teacher/Meeting/ParentMeetingRequest';
import ClassStudents from '../../../components/Teacher/Meeting/ClassStudents';
import MeetingAcceptReject from '../../../components/Teacher/Meeting/MeetingAcceptReject';
import CreateRequest from '../../../components/Teacher/Meeting/CreateRequest';
import UpcomingParentMeetings from '../../../components/Teacher/Meeting/UpcomingParentMeetings';
import { getRole } from '../../../utils';

const Meeting = ({ role, subjects }) => {
  const [newMeeting, setNewMeeting] = useState(false);
  const [selectedMeeting, selectMeeting] = useState();
  const [meetings, setMeetings] = useState([]);
  const [teachingStaff, setTeachingStaff] = useState([]);
  const [nonTeachingStaff, setNonTeachingStaff] = useState([]);
  const [selectedUser, selectUser] = useState();
  const [upcomingMeetingSort, setUpcomingMeetingSort] = useState();
  const [teachingSort, setTeachingSort] = useState();
  const [nonTeachingSort, setNonTeachingSort] = useState();
  const [teachingSearch, setTeachingSearch] = useState();
  const [nonTeachingSearch, setNonTeachingSearch] = useState();
  const [status, setStatus] = useState();
  const [openCreate, setOpenCreate] = useState();

  const [teachingStaffRes, getTeachingStaff] = useApi();
  const [nonTeachingRes, getNonTeachingStaff] = useApi();
  const [{ isLoading, isError, data }, getMeetings] = useApi();
  const [parentMeetings, getParentMeetings] = useApi();
  const [upcomingParentMeetings, getUpcomingParentMeetings] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getMeetings(MEETING, 'GET');
    if (role && role === 'Teacher') {
      getParentMeetings(PARENT_TEACHER_MEETING, 'GET');
      getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');
    }
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
    getNonTeachingStaff(GET_NON_TEACHING_STAFF, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setMeetings(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!teachingStaffRes.isLoading && !teachingStaffRes.isError && teachingStaffRes.data) {
      setTeachingStaff(teachingStaffRes.data);
    }
  }, [teachingStaffRes]);

  useEffect(() => {
    if (!nonTeachingRes.isLoading && !nonTeachingRes.isError && nonTeachingRes.data) {
      setNonTeachingStaff(nonTeachingRes.data);
    }
  }, [nonTeachingRes]);

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  const handleClose = (update) => {
    setNewMeeting(false);
    selectUser();
    if (update) {
      getMeetings(MEETING, 'GET');
    }
  };

  useEffect(() => {
    if (selectedUser) setNewMeeting(true);
  }, [selectedUser]);

  let upcomingMeetings = meetings || [];

  if (upcomingMeetingSort) {
    if (upcomingMeetingSort === 'Online Meeting') {
      upcomingMeetings = upcomingMeetings.filter((z) => z.mode.toLowerCase() === 'online');
    }
    if (upcomingMeetingSort === 'Offline Meeting') {
      upcomingMeetings = upcomingMeetings.filter((z) => z.mode.toLowerCase() === 'offline');
    }
  }

  let teachingData = teachingStaff || [];

  if (teachingSort) {
    if (teachingSort === 'Name - a to z') {
      teachingData = teachingData.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1));
    }
    if (teachingSort === 'Name - z to a') {
      teachingData = teachingData.sort((a, b) => (a.full_name.toLowerCase() < b.full_name.toLowerCase() ? 1 : -1));
    }
  }

  if (teachingSearch) {
    teachingData = teachingData.filter(
      (x) =>
        x.full_name.toLowerCase().includes(teachingSearch.toLowerCase()) ||
        x.username.toLowerCase().includes(teachingSearch.toLowerCase())
    );
  }

  let nonTeachingData = nonTeachingStaff || [];

  if (nonTeachingSort) {
    if (nonTeachingSort === 'Name - a to z') {
      nonTeachingData = nonTeachingData.sort((a, b) =>
        a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1
      );
    }
    if (nonTeachingSort === 'Name - z to a') {
      nonTeachingData = nonTeachingData.sort((a, b) =>
        a.full_name.toLowerCase() < b.full_name.toLowerCase() ? 1 : -1
      );
    }
  }

  if (nonTeachingSearch) {
    nonTeachingData = nonTeachingData.filter(
      (x) =>
        x.full_name.toLowerCase().includes(nonTeachingSearch.toLowerCase()) ||
        x.username.toLowerCase().includes(nonTeachingSearch.toLowerCase())
    );
  }

  const handleCloseParentMeeting = (update) => {
    setOpenCreate();
    if (update) {
      getMeetings(MEETING, 'GET');
    }
  };

  const handleCloseView = (reload) => {
    if (reload) refresh();
    setStatus(null);
  };

  const refresh = () => {
    getParentMeetings(PARENT_TEACHER_MEETING, 'GET');
    getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');
  };

  return (
    <div className="relative pb-10 w-full md:w-4/5">
      {selectedMeeting && (
        <div
          className="flex justify-center md:items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-1/2 text-sm block m-auto rounded-2xl relative">
            <div className="border-b border-borderYellow">
              <span className="block font-bold p-4 px-6">
                Meeting: {selectedMeeting?.reason}({selectedMeeting?.mode})
              </span>
              <img
                src="assets/img/Close.svg"
                className="absolute top-5 right-6 h-3 cursor-pointer"
                alt=""
                onClick={() => {
                  selectMeeting();
                }}
              />
            </div>
            <form className="mt-2 mb-2 flex flex-wrap px-6" autoComplete="off">
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                <div className="mt-2 w-full relative font-semibold">
                  <label className="text-textDark text-sm">Date</label>
                  <p
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                  >
                    {' '}
                    {moment(selectedMeeting?.requested_date).format('LL')}
                  </p>
                </div>

                <div className="mt-2 mb-2 w-full relative font-semibold">
                  <label className="text-textDark text-sm">Time</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                    {' '}
                    {moment(selectedMeeting?.requested_date).format('hh:mm a')}
                  </p>
                </div>
              </div>

              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="text-textDark text-sm">
                  {selectedMeeting.mode.toLowerCase() == 'online' ? 'Meeting Link' : 'Meeting Venue'}
                </label>
                <div className="w-full flex gap-3">
                  <p className="w-8/12 rounded-3xl text-blue-700 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                    {selectedMeeting?.link || 'NA'}
                  </p>
                  {selectedMeeting.mode.toLowerCase() == 'online' && (
                    <button
                      className="mt-2 w-4/12 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-9 bg-primary text-white"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(selectedMeeting?.link, '_blank');
                      }}
                    >
                      Join Meeting
                    </button>
                  )}
                </div>
              </div>
              {/* <div className="mt-2 mb-2 w-full relative font-semibold">
                  <label  className="text-textDark text-sm">
                    Staff Type
                  </label>
                  <p className="w-full rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">NA</p>
                </div> */}

              <div className="mt-2 mb-2 w-full relative">
                <label className="text-textDark text-sm font-semibold">
                  {` Participants ( ${selectedMeeting.meeting_with.length} Participants )`}
                </label>
                <div className="border border-borderLight mt-3 rounded-3xl p-2 flex items-center gap-x-1">
                  {selectedMeeting.meeting_with.map((x) => (
                    <p className="w-3/12 py-2 rounded-3xl bg-tableHeader px-4 border text-xs focus:outline-none">
                      {x?.full_name}
                    </p>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {newMeeting && (
        <ScheduleMeeting
          nonTeachingStaff={nonTeachingStaff}
          teachingStaff={teachingStaff}
          handleClose={handleClose}
          selectedUser={selectedUser}
        />
      )}

      {status && <MeetingAcceptReject handleClose={handleCloseView} status={status} />}

      {openCreate && <CreateRequest handleClose={handleCloseParentMeeting} selectedStudent={openCreate} />}

      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-1/2">
              <p>Upcoming Meetings</p>
            </div>
            <DropDown
              data={['All', 'Online Meeting', 'Offline Meeting']}
              width={'w-1/2 md:w-1/5'}
              title={'Sort by'}
              handleItemClick={(x) => {
                setUpcomingMeetingSort(x);
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Day</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Timings</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Meeting Agenda</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Details</div>
                  <div className="w-2/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                {Array.isArray(upcomingMeetings) && upcomingMeetings.length > 0 ? (
                  upcomingMeetings.map((x, idx) => (
                    <div className="w-full">
                      <div
                        key={x._id}
                        className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                          upcomingMeetings.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2">{moment(x?.requested_date).format('LL')}</div>
                        <div className="w-2/12 px-2 text-tableRowText">
                          {moment(x?.requested_date).format('hh:mm a')}
                        </div>
                        <div className="w-4/12 px-2 text-tableRowText">{x.reason}</div>
                        <div className="w-2/12 px-2">
                          <button
                            className="w-full border border-primary text-primary px-8 py-2 mr-4 rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectMeeting(x);
                            }}
                          >
                            View Details
                          </button>
                        </div>
                        <div className="w-2/12 flex items-center px-2">
                          {x.mode.toLowerCase() === 'online' && (
                            <button
                              className="w-full border-primary text-primary  border px-8 py-2 rounded-3xl text-xs focus:outline-none"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(x?.link, '_blank');
                              }}
                            >
                              Join Meeting
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl h-auto my-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-full md:w-4/12">
              <p>Teaching Staff</p>
            </div>
            <div className="flex justify-end w-full gap-x-3">
              <button
                type="submit"
                className="border px-2 py-2 w-1/3 md:w-1/4 rounded-3xl bg-primary text-xs font-medium text-white  focus:outline-none flex justify-center text-center items-center"
                onClick={() => {
                  setNewMeeting(true);
                }}
              >
                Schedule a meeting
              </button>
              <DropDown
                data={['Name - a to z', 'Name - z to a']}
                width={'w-1/3 md:w-1/4'}
                title={'Sort by'}
                handleItemClick={(x) => {
                  setTeachingSort(x);
                }}
              />

              <Search
                placeholder={'Search staff'}
                width={'w-1/4'}
                onChange={(x) => setTeachingSearch(x)}
                onEnter={() => console.log('enter')}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/12 border-r-2 py-2 pr-2">Photograph</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Name</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Designation</div>
                  <div className="w-3/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                {teachingData.length > 0 ? (
                  teachingData.map((x, idx) => (
                    <div key={x._id} className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                          teachingData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/12 px-2">
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src={getFile(x, 'profileImg')}
                            alt=""
                          />
                        </div>
                        <div className="w-3/12 px-2 text-tableRowText">{x?.username}</div>
                        <div className="w-3/12 px-2">{x?.full_name}</div>
                        <div className="w-3/12 px-2 text-tableRowText">
                          {/* {(x.jobProfile.length > 0 && x.jobProfile[0]?.title) || '--'} */}
                          {x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ') || ''}
                        </div>
                        <div className="w-3/12 flex items-center px-2">
                          <button
                            className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectUser(x);
                            }}
                          >
                            Send Meeting Request
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <div className="font-bold w-full md:w-4/12">
              <p> Non Teaching Staff</p>
            </div>
            <div className="flex justify-end w-full gap-x-3">
              <button
                type="submit"
                className="border px-2 py-1.5 w-1/3 md:w-1/4 rounded-3xl bg-primary text-xs font-medium text-white  focus:outline-none flex justify-center text-center items-center"
                onClick={() => {
                  setNewMeeting(true);
                }}
              >
                Schedule a meeting
              </button>
              <DropDown
                data={['Name - a to z', 'Name - z to a']}
                width={'w-1/3 md:w-1/4'}
                title={'Sort by'}
                handleItemClick={(c) => {
                  setNonTeachingSort(c);
                }}
              />

              <Search
                placeholder={'Search staff'}
                width={'w-1/4'}
                onChange={(x) => setNonTeachingSearch(x)}
                onEnter={() => console.log('enter')}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/12 border-r-2 py-2 pr-2">Photograph</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Name</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Designation</div>
                  <div className="w-3/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                {nonTeachingData.length > 0 ? (
                  nonTeachingData.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                          nonTeachingData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/12 px-2">
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src={getFile(x, 'profileImg')}
                            alt=""
                          />
                        </div>
                        <div className="w-3/12 px-2 text-tableRowText">{x?.username}</div>
                        <div className="w-3/12 px-2">{x?.full_name}</div>
                        <div className="w-3/12 px-2 text-tableRowText">
                          {(x.jobProfile.length > 0 && x.jobProfile[0]?.title) || '--'}
                        </div>
                        <div className="w-3/12 flex items-center px-2">
                          <button
                            className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectUser(x);
                            }}
                          >
                            Send Meeting Request
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {role && role === 'Teacher' && (
        <>
          <ParentMeetingRequest setStatus={setStatus} meetings={parentMeetings} refresh={refresh} />
          <UpcomingParentMeetings meetings={upcomingParentMeetings} setStatus={setStatus} role={'Teacher'} />
          <ClassStudents setOpenCreate={setOpenCreate} subjects={subjects} />
        </>
      )}
    </div>
  );
};

export default Meeting;
