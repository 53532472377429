import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { UPLOAD_COURSE } from '../../../api/urls/instituteManagement';
import { read, utils } from 'xlsx';

const CourseHandout = ({ subjects, refreshList }) => {
  const [selectedFile, setFile] = useState();
  const [selectedSubject, selectSubject] = useState();
  const [_id, setId] = useState('');

  const [uploadRes, uploadFile] = useApi();
  const [removeCourseRes, removeCourse] = useApi();

  const file = useRef();

  useEffect(() => {
    if (selectedFile) {
      readData();
    }
  }, [selectedFile]);

  const readData = () => {
    const file = selectedFile;

    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        // setCsvArray(rows);
        const data = rows.map((x) => {
          return {
            lessonNo: x.ChapterNo,
            lessonName: x.ChapterName,
            lessonDetails: wb.Sheets[x.ChapterNo] ? utils.sheet_to_json(wb.Sheets[x.ChapterNo]) : [],
          };
        });
        uploadData(data);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadData = (_data) => {
    const body = new FormData();
    body.append('file', selectedFile);
    body.append('course', JSON.stringify(_data));

    uploadFile(
      UPLOAD_COURSE(selectedSubject.class?._id, selectedSubject.section?._id, selectedSubject.subjectId),
      'POST',
      body
    );
  };

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && [200, 201, 202].includes(uploadRes.status)) {
      alert('Success');
      refreshList();
      setFile(null);
      file.current.value = null;
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('Failed');
      setFile(null);
      file.current.value = null;
    }

    selectSubject();
  }, [uploadRes]);

  const handleUploadClick = (x) => {
    file.current.click();
    selectSubject(x);
  };

  const handleRemoveClick = (x) => {
    const body = {
      path: x.course.file.path,
    };

    removeCourse(UPLOAD_COURSE(x.class?._id, x.section?._id, x.subjectId), 'DELETE', body);
  };

  useEffect(() => {
    if (!removeCourseRes.isLoading && !removeCourseRes.isError && [200, 201, 202].includes(removeCourseRes.status)) {
      alert('Success');
      refreshList();
    }

    if (!removeCourseRes.isLoading && removeCourseRes.isError) {
      selectSubject();
      alert('Failed');
    }
  }, [removeCourseRes]);

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      <input type="file" ref={file} hidden onChange={(e) => setFile(e.target.files[0])} />
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-4 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Course Plan</p>
            </div>
            <a
              className="border border-primary text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
              href={window.location.origin + '/CoursePlanTemplate.xlsx'}
              download
            >
              Download Course Template
            </a>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Upload/Remove</div>
                  <div className="w-1/5 py-2 px-2">Course Plan</div>
                </div>
              </div>
              <div>
                {Array.isArray(subjects) && subjects.length > 0 ? (
                  subjects.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs items-center ${
                          idx !== subjects.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-tableRowText">
                          {x?.subjectName.split(' ').length > 0 ? x?.subjectName.split(' ')[1] : x?.subjectName}
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.subjectName.split(' ')[0]}</div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.class?.name + '-' + x?.section.name}</div>
                        <div className="w-1/5 px-2">
                          {(removeCourseRes.isLoading || uploadRes.isLoading) && _id === idx ? (
                            <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                          ) : x.course && x.course.file ? (
                            <button
                              className="border border-primary w-9/12 px-10 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                              onClick={() => {
                                handleRemoveClick(x);
                                setId(idx);
                              }}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              className="border border-primary w-9/12 px-10 py-2 rounded-3xl text-white bg-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                              onClick={() => {
                                handleUploadClick(x);
                                setId(idx);
                              }}
                            >
                              Upload
                            </button>
                          )}
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          {x.course && x.course.file && (
                            <a
                              className="border border-primary w-9/12 px-10 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                              // onClick={() =>
                              // handleDownload(x.course?.file?.location, 'course.' + x.course?.file?.name.split('.')[1])
                              // }
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                              href={x.course?.file?.location}
                            >
                              Download
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseHandout;
