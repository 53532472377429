import { AUTH } from './baseUrls';

export const LOGIN = `${AUTH}/oauth/token`;

export const LOG_OUT = `${AUTH}/logout`;

export const FORGET_PASSWORD = `${AUTH}/password`;

export const Set_oauth_role = (role) => `${AUTH}/SetRole/${role}`;


