import styled from 'styled-components';
import fontFamily from '../fontFamily';

export const ViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #e5e5e5ab;
  z-index: 3;
`;

export const ViewPicture = styled.div`
  background-color: ${fontFamily.colors.white};
  border-radius: 20px;
  padding: 0 22.1px 0 0;
  box-shadow: 0 0 12px 0 ${fontFamily.colors.transparentBlack5};
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: 1143.31px;
  height: 544.27px;
  left: 170.7px;
  top: 73.99px;
  z-index: 2;
`;
export const FlexWrapperFour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const FlexWrapperSix = styled.div`
  margin-bottom: 14.75px;
  display: flex;
  align-items: flex-start;
`;
export const PostProfilePicture = styled.img`
  width: 6.21%;
  align-self: stretch;
  margin-right: 11.79px;
  border-radius: 20px 0px;
  object-fit: cover;
`;
export const FlexWrapperTen = styled.div`
  margin-right: 635.17px;
  padding: 9px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const PostUserName = styled.p`
  height: 60.71%;
  color: ${fontFamily.colors.black4};
  align-self: stretch;
  font-family: ${fontFamily.fonts.lato14Regular.family};
  font-size: ${fontFamily.fonts.lato14Regular.size};
  font-weight: ${fontFamily.fonts.lato14Regular.weight};
  line-height: ${fontFamily.fonts.lato14Regular.lineHeight};
`;
export const PostUserDesignation = styled.p`
  width: 93.67%;
  height: 32.14%;
  color: ${fontFamily.colors.transparentBlack};
  margin-bottom: 2px;
  font-family: ${fontFamily.fonts.roboto8Regular.family};
  font-size: ${fontFamily.fonts.roboto8Regular.size};
  font-weight: ${fontFamily.fonts.roboto8Regular.weight};
  line-height: ${fontFamily.fonts.roboto8Regular.lineHeight};
`;
export const POstTiming = styled.p`
  width: 92.41%;
  height: 32.14%;
  color: ${fontFamily.colors.green};
  font-family: ${fontFamily.fonts.roboto8Bold.family};
  font-size: ${fontFamily.fonts.roboto8Bold.size};
  font-weight: ${fontFamily.fonts.roboto8Bold.weight};
  line-height: ${fontFamily.fonts.roboto8Bold.lineHeight};
`;
export const MoreVertBlack24dp11 = styled.div`
  width: calc(2.16% - 14.27px);
  height: calc(34.78% - 5.71px);
  margin-top: 22.13px;
  padding: 2.86px 7.14px 2.85px 7.13px;
  display: flex;
  align-items: center;
`;
export const Vector = styled.img`
  width: 100%;
  align-self: stretch;
  object-fit: cover;
`;
export const RelativeWrapperOne = styled.div`
  width: 25.79%;
  height: 4.13%;
  margin-bottom: 8.21px;
  margin-left: 25px;
  position: relative;
`;
export const PostText = styled.p`
  width: 100%;
  height: 100%;
  color: ${fontFamily.colors.black4};
  position: relative;
  font-family: ${fontFamily.fonts.lato18Regular.family};
  font-size: ${fontFamily.fonts.lato18Regular.size};
  font-weight: ${fontFamily.fonts.lato18Regular.weight};
  line-height: ${fontFamily.fonts.lato18Regular.lineHeight};
`;
export const PostEmoticons = styled.div`
  width: calc(12.22% - 4.28px);
  height: calc(116.77% - 6.04px);
  padding: 3.21px 2.14px 2.83px;
  display: flex;
  align-items: center;
  position: absolute;
  right: -21.67px;
  top: -2px;
`;
export const VectorTwo = styled.img`
  flex: 1;
  align-self: stretch;
  object-fit: cover;
`;
export const FlexWrapperSeven = styled.div`
  margin-bottom: 19.99px;
  display: flex;
  align-items: center;
`;
export const Rectangle78 = styled.img`
  width: 86.9%;
  height: 99.99%;
  margin-right: 6.74px;
`;
export const FlexWrapperOne = styled.div`
  margin-bottom: 4.61px;
  position: relative;
  border: 2px solid ${fontFamily.colors.darkOrange};
`;
export const Rectangle78Two = styled.img`
  width: 103.85%;
  height: 53.05%;
  position: absolute;
  left: -1.66px;
  top: 22.01px;
`;
export const Rectangle78Three = styled.img`
  width: 99.66%;
  height: 14.07%;
  margin-bottom: 5.28px;
  margin-left: 0.34px;
`;
export const Rectangle78Four = styled.img`
  width: 99.66%;
  height: 14.07%;
  margin-bottom: 5.99px;
  margin-left: 0.34px;
`;
export const FlexWrapperTwo = styled.div`
  padding: 0 0 0.24px 0.34px;
  position: relative;
`;
export const Rectangle78Five = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const ScrollMoreShape = styled.div`
  width: 100%;
  height: 68.38%;
  background-color: ${fontFamily.colors.transparentDarkOrange3};
  position: absolute;
  left: 0;
  top: 9.9px;
`;
export const ScrollMoreIcon = styled.img`
  width: 8.64%;
  height: 15.97%;
  position: absolute;
  left: 45.64px;
  top: 17.76px;
`;
export const Divider = styled.div`
  height: 0.09%;
  background-color: ${fontFamily.colors.gold};
  margin-bottom: 6.3px;
  align-self: stretch;
`;
export const FlexWrapperEight = styled.div`
  margin-bottom: 6.05px;
  padding: 0 0 0 23.4px;
  display: flex;
  align-items: center;
`;
export const Group187 = styled.div`
  margin-right: 585.79px;
  display: flex;
  align-items: center;
`;
export const ChargeCountIcon = styled.div`
  width: calc(14.2% - 9.99px);
  margin-right: 0.48px;
  padding: 2.14px 5px 2.14px 4.99px;
  display: flex;
  align-items: center;
  align-self: stretch;
`;
export const VectorThree = styled.img`
  width: 100%;
  height: 100%;
`;
export const ChargeCountLabel = styled.p`
  width: 85.41%;
  height: 70.05%;
  color: ${fontFamily.colors.transparentBlack};
  font-family: ${fontFamily.fonts.roboto10Regular.family};
  font-size: ${fontFamily.fonts.roboto10Regular.size};
  font-weight: ${fontFamily.fonts.roboto10Regular.weight};
  line-height: ${fontFamily.fonts.roboto10Regular.lineHeight};
`;
export const CommentCount = styled.p`
  width: 7.07%;
  height: 70.05%;
  color: ${fontFamily.colors.transparentBlack};
  font-family: ${fontFamily.fonts.roboto10Regular.family};
  font-size: ${fontFamily.fonts.roboto10Regular.size};
  font-weight: ${fontFamily.fonts.roboto10Regular.weight};
  line-height: ${fontFamily.fonts.roboto10Regular.lineHeight};
`;
export const DividerTwo = styled.div`
  height: 0.09%;
  background-color: ${fontFamily.colors.gold};
  margin-bottom: 14.32px;
  align-self: stretch;
`;
export const FlexWrapperNine = styled.div`
  padding: 0 0 0 172px;
  display: flex;
  align-items: center;
`;
export const Group184 = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 338px;
  }
`;
export const VectorFour = styled.img`
  width: 11.32%;
  height: 91.79%;
  margin-right: 9.87px;
`;
export const ChargedLabel = styled.p`
  width: 73.02%;
  color: ${fontFamily.colors.darkOrange};
  align-self: stretch;
  font-family: ${fontFamily.fonts.roboto12Medium.family};
  font-size: ${fontFamily.fonts.roboto12Medium.size};
  font-weight: ${fontFamily.fonts.roboto12Medium.weight};
  line-height: ${fontFamily.fonts.roboto12Medium.lineHeight};
`;
export const CommentIcon = styled.img`
  width: 18.29%;
  align-self: stretch;
  margin-right: 10.7px;
  object-fit: cover;
`;
export const CommentLabel = styled.p`
  width: 67.98%;
  height: 98.11%;
  color: ${fontFamily.colors.transparentBlack3};
  font-family: ${fontFamily.fonts.roboto12Regular.family};
  font-size: ${fontFamily.fonts.roboto12Regular.size};
  font-weight: ${fontFamily.fonts.roboto12Regular.weight};
  line-height: ${fontFamily.fonts.roboto12Regular.lineHeight};
`;
export const DividerThree = styled.div`
  width: 0.04%;
  background-color: ${fontFamily.colors.gold};
  margin-right: 21.11px;
  align-self: stretch;
`;
export const FlexWrapperFive = styled.div`
  margin-right: 45.64px;
  padding: 28px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const CommentSectionLabel = styled.p`
  width: 30.65%;
  height: 23.71%;
  color: ${fontFamily.colors.transparentBlack3};
  margin-bottom: 13.81px;
  font-family: ${fontFamily.fonts.roboto14Medium.family};
  font-size: ${fontFamily.fonts.roboto14Medium.size};
  font-weight: ${fontFamily.fonts.roboto14Medium.weight};
  line-height: ${fontFamily.fonts.roboto14Medium.lineHeight};
`;
export const FlexWrapperThree = styled.div`
  background-color: ${fontFamily.colors.whiteSmoke};
  margin-left: 0.5px;
  border-radius: 10px;
  padding: 0 92.71px 18.83px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const FlexWrapperTwelve = styled.div`
  margin-bottom: 0.71px;
  display: flex;
  align-items: center;
`;
export const COmmentProfilePicture = styled.img`
  width: 29.19%;
  align-self: stretch;
  margin-right: 7.84px;
  border-radius: 10px 0px;
  object-fit: cover;
`;
export const CommentUserName = styled.p`
  width: 63.68%;
  height: 43.59%;
  color: ${fontFamily.colors.black};
  font-family: ${fontFamily.fonts.roboto12Medium.family};
  font-size: ${fontFamily.fonts.roboto12Medium.size};
  font-weight: ${fontFamily.fonts.roboto12Medium.weight};
  line-height: ${fontFamily.fonts.roboto12Medium.lineHeight};
`;
export const CommentText = styled.p`
  width: 69.73%;
  height: 29.9%;
  color: ${fontFamily.colors.black};
  margin-left: 39.93px;
  font-family: ${fontFamily.fonts.roboto12Regular.family};
  font-size: ${fontFamily.fonts.roboto12Regular.size};
  font-weight: ${fontFamily.fonts.roboto12Regular.weight};
  line-height: ${fontFamily.fonts.roboto12Regular.lineHeight};
`;
export const CloseWindowIcon = styled.div`
  width: calc(1.52% - 7.14px);
  height: calc(3.14% - 7.14px);
  margin-top: 22.13px;
  padding: 3.57px;
  display: flex;
  align-items: center;
`;
export const VectorFive = styled.img`
  height: 100%;
  flex: 1;
  object-fit: cover;
`;
