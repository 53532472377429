import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { UPLOAD_HOMEWORK } from '../../../api/urls/notes';
import moment from 'moment';

function Add({ handleClose, classSubject, selectedHW }) {
  const homeWorkRef = useRef();

  const formDataModel = {
    summary: '',
    date: new Date(),
    title: '',
    homework: null,
    classId: classSubject?.class?._id,
    sectionId: classSubject?.section?._id,
    subjectId: classSubject?.subjectId,
  };

  const [formData, setFormData] = useState(formDataModel);
  const [addRes, addHomeWork] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (selectedHW) {
      setFormData({
        ...formData,
        date: selectedHW.date,
        title: selectedHW.title,
        summary: selectedHW.summary,
      });
    }
  }, [selectedHW]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { summary, homework, date, title } = formData;

    let str = [];
    if (!summary?.trim()) str.push('Description');
    if (!title?.trim()) str.push('Title');
    if (!date) str.push('Date');
    if (!homework) str.push('File');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    const fd = new FormData();
    fd.append('title', formData.title);
    fd.append('date', formData.date);
    fd.append('summary', formData.summary);
    fd.append('classId', formData.classId);
    fd.append('sectionId', formData.sectionId);
    fd.append('subjectId', formData.subjectId);
    // fd.append('homework', formData.homework);

    for (let i = 0; i < formData.homework.length; i++) {
      fd.append('homework', formData.homework[i]);
    }

    addHomeWork(UPLOAD_HOMEWORK, 'POST', fd);
  };

  useEffect(() => {
    if (!addRes.isLoading && !addRes.isError && addRes.status === 201) {
      alert('Successfully added');
      setFormData(formDataModel);
      handleClose(true);
    }

    if (!addRes.isLoading && addRes.isError) {
      alert('Failed to add');
    }
  }, [addRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-11/12 md:w-6/12  m-auto rounded-2xl relative">
        <div className="border-b border-borderYellow py-4 px-6 flex justify-between items-center">
          <span className="block font-bold ">
            {classSubject
              ? `${classSubject?.subjectName} (${classSubject?.class?.name} - ${classSubject?.section?.name})`
              : selectedHW?.title}
          </span>
          <img
            src="assets/img/Close.svg"
            className=" h-3 cursor-pointer"
            alt=""
            onClick={() => {
              handleClose(false);
            }}
          />
        </div>
        <form className="px-6 py-3 grid grid-cols-1 md:grid-cols-2 gap-x-6" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mb-2 relative font-semibold disabled">
            <label htmlFor="testType" className="text-sm text-textDark">
              Date{!selectedHW && <span className="text-red-500 ml-1">*</span>}
            </label>
            {selectedHW ? (
              <p className="bg-gray-100 w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none">
                {moment(formData.date).format('ll')}
              </p>
            ) : (
              <input
                type="date"
                placeholder="Eg- 25 August 2022"
                autoComplete="off"
                name="date"
                required
                disabled={!!selectedHW}
                value={formData.date}
                onChange={handleInputChange}
                className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
              />
            )}
          </div>

          <div className="mt-2 mb-2 w-full relative font-semibold md:col-span-2">
            <label className="text-sm text-textDark">
              Title{!selectedHW && <span className="text-red-500 ml-1">*</span>}
            </label>
            <input
              type="text"
              required
              name="title"
              value={formData.title}
              disabled={!!selectedHW}
              onChange={handleInputChange}
              placeholder="Ex- title"
              className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="mt-2 mb-2 w-full relative font-semibold md:col-span-2">
            <label className="text-sm text-textDark">
              Summary{!selectedHW && <span className="text-red-500 ml-1">*</span>}
            </label>
            <textarea
              rows="4"
              cols="50"
              required
              disabled={!!selectedHW}
              name="summary"
              value={formData.summary}
              onChange={handleInputChange}
              placeholder="Ex- xyz"
              className="w-full rounded-2xl border text-sm border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className=" text-sm text-textDark">
              {selectedHW ? 'Files (Click to download)' : 'Upload File(s)'}
              {!selectedHW && <span className="text-red-500 ml-1">*</span>}
            </label>
            {selectedHW ? (
              <div className="w-full text-xs rounded-3xl gap-x-2 flex-wrap flex-row mt-1 focus:outline-none flex justify-between">
                {/* <p className="py-2 text-tableRowText px-2 truncate">
                  {selectedHW.files.length > 0 ? selectedHW.files[0].name : ''}
                </p>
                <a
                  href={selectedHW?.files?.length > 0 && selectedHW.files[0].location}
                  download
                  className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                >
                  Download
                </a> */}
                {selectedHW?.files.map((x, idx) => (
                  <a
                    href={x.location}
                    download
                    className="text-primary rounded-full text-left my-1 text-xs truncate w-2/3"
                  >
                    {x?.name || `file ${idx + 1}`}
                  </a>
                ))}
              </div>
            ) : (
              <div className="relative flex">
                <input
                  hidden
                  multiple
                  ref={homeWorkRef}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setFormData({ ...formData, homework: e.target.files })}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <input
                  // required
                  type="text"
                  disabled
                  value={formData.homework?.length > 0 ? `${formData.homework?.length} files selected` : ''}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                  onClick={(e) => {
                    e.preventDefault();
                    homeWorkRef.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
            )}
          </div>

          {!selectedHW && (
            <div className="w-full relative font-semibold md:col-span-2">
              <div className="flex float-right">
                {addRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <button
                    type="submit"
                    className=" border w-32 py-4 rounded-3xl text-xs  focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Add;
