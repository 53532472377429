import React, { useEffect } from 'react';

function EdTech() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pdfs = [
    {
      book: '12th Mathematics Book',
      link: 'https://ncert.nic.in/textbook.php?lemh1=0-6',
    },
    {
      book: '12th Biology Book',
      link: 'https://ncert.nic.in/textbook.php?lebo1=0-13',
    },
    {
      book: '11th Physics Book',
      link: 'https://ncert.nic.in/textbook.php?keph2=0-7',
    },
    {
      book: '11th Chemistry Book',
      link: 'https://ncert.nic.in/textbook.php?kech1=0-6',
    },
    {
      book: '10th English Book',
      link: 'https://ncert.nic.in/textbook.php?jefp1=0-9',
    },
    {
      book: '10th Science Book',
      link: 'https://ncert.nic.in/textbook.php?jesc1=0-13',
    },
    {
      book: '9th Hindi Book',
      link: 'https://ncert.nic.in/textbook.php?ihsp1=0-10',
    },
    {
      book: '8th Social Science Book',
      link: 'https://ncert.nic.in/textbook.php?hess3=0-8',
    },
  ];

  const videos = [
    {
      subject: 'Video 1',
      title: '',
      link: `${window.location.origin}/CreateTest.MP4`,
    },
    {
      subject: 'Video 2',
      title: '',
      link: `${window.location.origin}/TEACHER HOMEWORK.MP4`,
    },
    {
      subject: 'Video 3',
      title: '',
      link: `${window.location.origin}/TEACHER'S REMARK.MP4`,
    },
  ];

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Ed Tech</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 p-6 border-b border-borderYellow ">
          <div>
            <h3 className="font-semibold mt-2">PDFs</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 w-full gap-3">
            {pdfs.map((pdf) => (
              <a
                className="text-md px-6 py-2 rounded-lg border border-primaryLight text-primary font-medium mx-1 focus:outline-none text-center hover:bg-primary hover:text-white"
                href={pdf.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pdf.book}
              </a>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap w-full h-1/4 p-6">
          <div>
            <h3 className="font-semibold mt-2">Videos</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 w-full gap-3">
            {videos.map((video) => (
              <div className="flex flex-col mb-6">
                <video src={video.link} allowfullscreen autoPlay={false} controls />
                <p className="text-lg font-medium">{video.title}</p>
                <p className="text-gray-700 text-xs">{video.subject}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EdTech;
