import React, { useState, useEffect } from 'react';
import MapStudent from '../../../components/Admin/StudentManagement/MapStudent';
import Students from '../../../components/Admin/StudentManagement/Students';
import AdmissionWithdrawl from '../../../components/Admin/StudentManagement/AdmissionWithdrawl';
import AddStudent from '../../../components/Admin/StudentManagement/AddStudent';

import useApi from '../../../api/useApi';
import { GET_STUDENT } from '../../../api/urls/user';
import { GET_UNMAPPED_STUDENTS } from '../../../api/urls/instituteManagement';
import ClassesSections from '../../../components/Teacher/AdditionalResponsibility/ClassesSections';
import NewClassSection from '../../../components/Teacher/AdditionalResponsibility/NewClassSection';
import { CLASS_SECTIONS, CLASS } from '../../../api/urls/institute';
import { SEND_ADMISSION_NOTIFICATION, GET_NEW_ADMISSION_STATUS } from '../../../api/urls/test';

import { useStateValue } from '../../../ContextApi/StateProvider';

function AdmissionIncharge() {
  const [_, dispatch] = useStateValue();

  const [selectedStudent, selectStudent] = useState();
  const [action, selectAction] = useState();
  const [openMapStudent, setOpenMapStudent] = useState(false);
  const [openClassSection, setOpenClassSection] = useState(false);
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [unMappedStudents, setUnMappedStudents] = useState([]);
  const [classSections, setClassSections] = useState([]);

  const [{ isLoading, isError, data }, getStudents] = useApi();
  const [unMappedStudentState, getUnMappedStudents] = useApi();
  const [classSectionResponse, getClassSections] = useApi();
  const [classState, getClassSectionsALl] = useApi();
  const [sendNotificationRes, sendAdmissionNotification] = useApi();
  const [admissionStatusRes, getNewAdmissionStatus] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getStudents(GET_STUDENT, 'GET');
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
    getClassSections(CLASS_SECTIONS, 'GET');
    getNewAdmissionStatus(GET_NEW_ADMISSION_STATUS, 'GET');
  }, []);

  useEffect(() => {
    if (!classSectionResponse.isLoading && !classSectionResponse.isError && classSectionResponse.data) {
      setClassSections(classSectionResponse.data.studentInfo);
    }
  }, [classSectionResponse]);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setStudents(data);
    }
    if (isError) {
      setStudents([]);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!unMappedStudentState.isLoading && !unMappedStudentState.isError && unMappedStudentState.data) {
      setUnMappedStudents(unMappedStudentState.data.students);
    }
    if (unMappedStudentState.isError) {
      setUnMappedStudents([]);
    }
  }, [unMappedStudentState]);

  const refreshList = () => {
    getStudents(GET_STUDENT, 'GET');
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
    getNewAdmissionStatus(GET_NEW_ADMISSION_STATUS, 'GET');
    getClassSections(CLASS_SECTIONS, 'GET');
  };

  const refreshList2 = () => {
    getClassSections(CLASS_SECTIONS, 'GET');
    getClassSectionsALl(CLASS, 'GET');
  };

  const toggleMapStudent = () => {
    setOpenMapStudent((prev) => !prev);
  };

  const selectStudentToMap = (x) => {
    selectStudent(x);
    toggleMapStudent();
  };

  const toggleOpenClassSection = () => {
    setOpenClassSection((prev) => !prev);
  };

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const openDetailsView = (x, y) => {
    selectAction(y);
    selectStudent(x);
    toggleView();
  };

  const sendNofification = () => {
    sendAdmissionNotification(SEND_ADMISSION_NOTIFICATION, 'POST');
  };

  useEffect(() => {
    if (!sendNotificationRes.isLoading && !sendNotificationRes.isError && sendNotificationRes.status === 200) {
      getNewAdmissionStatus(GET_NEW_ADMISSION_STATUS, 'GET');
      alert('notification sent Successfully');
    }
    if (sendNotificationRes.isError) {
      alert('Failed to send notification');
    }
  }, [sendNotificationRes.isLoading, sendNotificationRes.isError, sendNotificationRes.data]);

  // const getClass = (_class, section) => {
  //   let x;
  //   if (classSections) x = classSections?.find((x) => x.classId === _class && x.sectionId == section);
  //   if (x) return `${x.class}-${x.section}`;
  //   return '';
  // };

  useEffect(() => {
    if (!classState.isLoading && !classState.isError && classState.data) {
      dispatch({
        type: 'CLASS_SECTION',
        payload: classState.data,
      });
    }
  }, [classState]);

  return (
    <div className="w-full md:w-4/5 pb-10">
      {openMapStudent && (
        <MapStudent toggleMapStudent={toggleMapStudent} selectedStudent={selectedStudent} refreshList={refreshList} />
      )}
      {openClassSection && (
        <NewClassSection toggleOpenClassSection={toggleOpenClassSection} refreshList={refreshList2} />
      )}
      {open && <AddStudent toggleView={toggleView} selectedStudent={selectedStudent} refreshList={refreshList} />}

      <Students
        title={'New Students'}
        selectStudent={selectStudentToMap}
        openDetails={openDetailsView}
        data={unMappedStudents}
      />
      <Students title={'Student Detail'} openDetails={openDetailsView} data={students} />
      <ClassesSections toggleOpenClassSection={toggleOpenClassSection} classSections={classSections} />

      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <div className="font-bold w-full md:w-2/3">
              <p>New Session Admissions</p>
            </div>
            <div className="flex gap-x-4 w-full md:w-1/3 justify-end">
              {sendNotificationRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto mt-2" alt="" />
              ) : (
                <button
                  className="px-3.5 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
                  onClick={() => sendNofification()}
                >
                  Send Notification to Parents
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/6 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Email</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Last Class Attended</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Status</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Fee Receipt</div>
                  <div className="w-1/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                {Array.isArray(admissionStatusRes.data?.notificationList) &&
                admissionStatusRes.data?.notificationList.length > 0 ? (
                  admissionStatusRes.data?.notificationList.map((x, idx) => {
                    return (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                            idx !== admissionStatusRes.data?.notificationList.length - 1 &&
                            'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/6 px-2 py-2 text-textDark ">{x?.student.studentName}</div>
                          <div className="w-1/6 px-2 text-textDark">{x?.student.email}</div>
                          <div className={`w-1/6 px-2 text-tableRowText`}>
                            {`${x?.classInfo?.name} - ${x?.sectionInfo?.name}`}
                          </div>
                          <div
                            className={`w-1/6 px-2 ${
                              x.student.status === 'Approved'
                                ? 'text-textGreen'
                                : x.student.status === 'Rejected'
                                ? 'text-textRed'
                                : 'text-tableRowText'
                            }`}
                          >
                            {x?.student?.status}
                          </div>
                          <div className="w-1/6 px-2 text-tableRowText">
                            {x?.student.feeRecipt.length === 0 ? (
                              'Not Uploaded'
                            ) : (
                              <a
                                href={x?.student?.feeRecipt[0]?.location}
                                download
                                className="w-11/12 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary"
                              >
                                Download
                              </a>
                            )}
                          </div>
                          <div className={`w-1/6 px-2 `}>
                            {' '}
                            <button
                              className={`w-11/12 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center ${
                                x.student.mappingStatus === 'approved' ||
                                x.student.feeRecipt.length === 0 ||
                                x.student.status !== 'Approved'
                                  ? 'border-borderLight text-tableRowText opacity-50'
                                  : 'border-primary text-primary'
                              }`}
                              onClick={() => {
                                selectStudentToMap({
                                  name: x?.student?.user_id?.full_name,
                                  parent1:
                                    x?.student?.user_id?.parentDetail.length > 0
                                      ? x?.student?.user_id?.parentDetail[0].father_name
                                      : '--',
                                  joined: x?.student?.user_id?.createdAt,
                                  lastClass: `${x?.classInfo?.name} - ${x?.sectionInfo?.name}`,
                                  id: x?.student?.user_id?._id,
                                });
                              }}
                              disabled={
                                x.student.mappingStatus === 'approved' ||
                                x.student.feeRecipt.length === 0 ||
                                x.student.status !== 'Approved'
                              }
                            >
                              {x.student.mappingStatus === 'approved' ? 'Mapped' : 'Map Class'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmissionIncharge;
