import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { SECTION_STUDENTS, STUDENT_REPORT } from '../../../api/urls/institute';
import DropDown from '../../../components/UI/DropDown';
import { useStateValue } from '../../../ContextApi/StateProvider';
import moment from 'moment';

function index() {
  const [{ classSections }, dispatch] = useStateValue();

  const [studentList, setStudents] = useState(null);
  const [selectedStudent, selectStudent] = useState();
  const [selectedClass, selectClass] = useState();
  const [selectedSection, selectSection] = useState();
  const [sections, setSections] = useState([]);
  const [report, setReport] = useState();

  const [students, getStudents] = useApi();
  const [reportRes, getReport] = useApi();

  const parentInfo = useRef();
  const address = useRef();
  const lastSchool = useRef();
  const attendance = useRef();
  const leaves = useRef();
  const assignmentsSubmit = useRef();
  const assignmentsPending = useRef();
  const ref = useRef();

  useEffect(() => {
    if (selectedClass && selectedSection) getStudents(SECTION_STUDENTS(selectedClass.id, selectedSection.id), 'GET');
  }, [selectedClass, selectedSection]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data && Array.isArray(students.data)) {
      setStudents(students.data.map((x) => x));
    }
  }, [students]);

  const handleSubmit = () => {
    getReport(
      `${STUDENT_REPORT(
        selectedClass.id,
        selectedSection.id,
        selectedStudent._id,
        assignmentsSubmit.current.checked && 'as',
        parentInfo.current.checked && 'pa',
        address.current.checked && 'ad',
        lastSchool.current.checked && 'ls',
        leaves.current.checked && 'sl',
        attendance.current.checked && 'sa'
      )}`,
      'GET'
    );
  };

  useEffect(() => {
    if (!reportRes.isLoading && !reportRes.isError && reportRes.data) {
      setReport(reportRes.data);
      setTimeout(() => {
        printDiv();
      }, 500);
    }
  }, [reportRes]);

  function printDiv() {
    // var divContents = document.getElementById('GFG').innerHTML;
    // var a = window.open('', '', 'height=100vh, width=100vw');
    // a.document.write('<html>');
    // a.document.write('<body >');
    // a.document.write(divContents);
    // a.document.write('</body></html>');
    // a.document.close();
    window.print();
  }

  return (
    <div className="w-full md:w-4/5 pb-10">
      {/* Report UI Start*/}
      <div className="flex flex-col overflow-auto px-10" id="section-to-print">
        {report?.fullName && (
          <div className="mb-2 flex flex-col mt-4">
            <label className="text-black text-xs">Name</label>
            <p className="font-medium">{report?.fullName}</p>
          </div>
        )}

        {report?.address && (
          <div className="mb-2 flex flex-col mt-4">
            <label className="text-black text-xs">Address</label>
            <div className="flex gap-x-6 flex-wrap">
              {Object.entries(report?.address).map((x) => {
                return (
                  <div className="flex gap-x-2 items-center">
                    <span className="uppercase text-sm">{x[0] + ':'}</span>
                    <span className="font-medium">{x[1]}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {report?.class && (
          <div className="mb-2 flex flex-col mt-4">
            <label className="text-black text-xs">Class</label>
            <p className="font-medium">{report?.class}</p>
          </div>
        )}

        {report?.parentInfo && report?.parentInfo?.length && (
          <div className="mb-2 flex flex-col mt-4">
            <label className="text-black text-xs">Parents</label>
            {report?.parentInfo.map((x) => (
              <div className="flex gap-x-6 flex-wrap">
                <div className="flex gap-x-2 items-center">
                  <span className="uppercase text-sm">Name:</span>
                  <span className="font-medium">{x?.father_name || x?.mother_name || x?.guardian_name}</span>
                </div>
                <div className="flex gap-x-2 items-center">
                  <span className="uppercase text-sm">Relation:</span>
                  <span className="font-medium">
                    {x?.father_name ? 'Father' : x?.mother_name ? 'Mother' : 'Guardian'}
                  </span>
                </div>
                <div className="flex gap-x-2 items-center">
                  <span className="uppercase text-sm">Email:</span>
                  <span className="font-medium">{x?.email_Id || x?.email_id}</span>
                </div>
                <div className="flex gap-x-2 items-center">
                  <span className="uppercase text-sm">Phone:</span>
                  <span className="font-medium">{x?.phone}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {report?.lastSchool && (
          <div className="mb-2 flex flex-col mt-4">
            <label className="text-black text-xs">Last School</label>
            <div className="flex gap-x-6 flex-wrap">
              <div className="flex gap-x-2 items-center">
                <span className="uppercase text-sm">School Name:</span>
                <span className="font-medium">{report?.lastSchool?.schoolName}</span>
              </div>
              <div className="flex gap-x-2 items-center">
                <span className="uppercase text-sm">Class:</span>
                <span className="font-medium">{report?.lastSchool?.class}</span>
              </div>
              <div className="flex gap-x-2 items-center">
                <span className="uppercase text-sm">Medium:</span>
                <span className="font-medium">{report?.lastSchool?.medium}</span>
              </div>
              <div className="flex gap-x-2 items-center">
                <span className="uppercase text-sm">Address:</span>
                <span className="font-medium">{report?.lastSchool?.address}</span>
              </div>
            </div>
          </div>
        )}

        {report?.studentAttendance && (
          <div className="mb-6 flex flex-col mt-4">
            <div>
              <label className="text-black text-xs">Attendance: </label>
              <p className="font-medium">{report?.studentAttendance?.cumulative}</p>
            </div>
            <div className="w-full py-4">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-b-2xl">
                  <div className="flex w-full relative text-xs font-medium px-6">
                    <div className="w-1/2 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/2 py-2 px-2">P/A</div>
                  </div>
                </div>
                <div>
                  {report?.studentAttendance.attendance.map((x, idx) => (
                    <div key={x.id} className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative py-2 text-xs font-medium px-6 ${
                          report?.studentAttendance.attendance.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/2 px-2 py-2.5 text-tableRowText">{moment(x.date).format('ll')}</div>
                        <div className="w-1/2 px-2 py-2.5 text-textDark">{x.isPresent ? 'Present' : 'Absent'}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {report?.leaves && (
          <div className="mb-6 flex flex-col mt-4">
            <div>
              <label className="text-black text-xs">Leaves: </label>
              <p className="font-medium">{report?.leaves?.length}</p>
            </div>
            <div className="w-full py-4">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-b-2xl">
                  <div className="flex w-full relative text-xs font-medium px-6">
                    <div className="w-1/3 border-r-2 py-2 px-2">Start Date</div>
                    <div className="w-1/3 border-r-2 py-2 px-2">Days</div>
                    <div className="w-1/3 py-2 px-2">Reason</div>
                  </div>
                </div>
                <div>
                  {report?.leaves.map((x, idx) => (
                    <div key={x.id} className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative py-2 text-xs font-medium px-6 ${
                          report?.leaves.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/3 px-2 py-2.5 text-tableRowText">{moment(x.start_date).format('ll')}</div>
                        <div className="w-1/3 px-2 py-2.5 text-textDark">{x.days}</div>
                        <div className="w-1/3 px-2 py-2.5 text-textDark">{x.reason}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {report?.assignmentList && (
          <div className=" mb-6 flex  flex-col mt-4">
            <div>
              <label className="text-black text-xs">Assignments:</label>
              <p className="font-medium">{report?.assignmentList?.length}</p>
            </div>
            <div className="w-full py-4">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-b-2xl">
                  <div className="flex w-full relative text-xs font-medium px-6">
                    <div className="w-1/4 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">title</div>
                    {/* <div className="w-1/4 border-r-2 py-2 px-2">Summary</div> */}
                    <div className="w-1/4  py-2 px-2">Status</div>
                  </div>
                </div>
                <div>
                  {report?.assignmentList.map((x, idx) => (
                    <div key={x.id} className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative py-2 text-xs font-medium px-6 ${
                          report?.assignmentList.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/4 px-2 py-2.5 text-textDark">
                          {x.subject.subjectName + ' - ' + x.subject.subjectCode}
                        </div>
                        <div className="w-1/4 px-2 py-2.5 text-tableRowText">{moment(x.date).format('ll')}</div>
                        <div className="w-1/4 px-2 py-2.5 text-textDark">{x.title}</div>
                        {/* <div className="w-1/4 px-2 py-2.5 text-textDark">{x.summary}</div> */}
                        <div className="w-1/4 px-2 py-2.5 text-textDark">
                          {x.files?.length ? 'Submitted' : 'Pending'}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Report UI end*/}

      {selectedStudent && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 text-sm block m-auto mt-20 rounded-2xl relative">
            <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
              <span className="block font-bold text-textBlue1 ">{`${selectedStudent?.full_name} - Student Report`}</span>
              <img
                src="assets/img/Close.svg"
                className=" h-3 cursor-pointer"
                alt=""
                onClick={() => selectStudent(null)}
              />
            </div>

            <div className="p-6">
              <div>
                <p className="font-semibold text-black mb-4">Student Details - Select Attributes</p>
                <div className="flex flex-wrap">
                  <div className="w-1/2 md:w-1/3 mb-6 flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <label className="text-black text-xs">Student Information</label>
                  </div>
                  <div className="w-1/2 md:w-1/3 mb-6 flex items-center">
                    <input type="checkbox" ref={parentInfo} className="mr-2" />
                    <label className="text-black text-xs">Parent Information</label>
                  </div>
                  <div className="w-1/2 md:w-1/3 mb-6 flex items-center">
                    <input type="checkbox" ref={address} className="mr-2" />
                    <label className="text-black text-xs">Address</label>
                  </div>
                  <div className="w-1/2 md:w-1/3 mb-4 flex items-center">
                    <input type="checkbox" ref={lastSchool} className="mr-2" />
                    <label className="text-black text-xs">Last School</label>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <p className="font-semibold text-black mb-4">Student Attendance - Select Attributes</p>
                <div className="flex flex-wrap">
                  <div className="w-1/2 md:w-1/3 mb-4 flex items-center">
                    <input type="checkbox" ref={attendance} className="mr-2" />
                    <label className="text-black text-xs">Attendance</label>
                  </div>
                  <div className="w-1/2 md:w-1/3 mb-4 flex items-center">
                    <input type="checkbox" ref={leaves} className="mr-2" />
                    <label className="text-black text-xs">Total Leaves</label>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <p className="font-semibold text-black mb-4">Assignments & Evaluations - Select Attributes</p>
                <div className="flex flex-wrap">
                  <div className="w-1/2 md:w-1/3 flex items-center">
                    <input type="checkbox" ref={assignmentsSubmit} className="mr-2" />
                    <label className="text-black text-xs">Assignments Status</label>
                  </div>
                  {/* <div className="w-1/2 md:w-1/3 flex items-center">
                    <input type="checkbox" ref={assignmentsPending} className="mr-2" />
                    <label className="text-black text-xs">Assignments Pending</label>
                  </div> */}
                </div>
              </div>
            </div>
            <button
              className="w-40 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-white bg-primary ml-auto m-6"
              onClick={() => {
                handleSubmit();
              }}
            >
              Export
            </button>
          </div>
        </div>
      )}
      <div className="w-full bg-white rounded-xl h-auto ">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold ">
              <p>Student Report</p>
            </div>

            <div className="flex gap-x-4">
              <DropDown
                field={'name'}
                data={classSections}
                type={'obj'}
                title={'Select Class'}
                handleItemClick={(x) => {
                  selectClass(x);
                  setSections(x.sections);
                  selectSection();
                }}
                width={'w-36'}
                otherClasses={'border-borderLight'}
              />

              <DropDown
                data={sections}
                type={'obj'}
                field={'name'}
                title={'Select Section'}
                handleItemClick={(x) => {
                  selectSection(x);
                }}
                width={'w-36'}
                otherClasses={'border-borderLight'}
              />

              {/* <DropDown
                width={'w-36'}
                data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                title={'Sort by'}
                handleItemClick={(x) => {}}
                otherClasses={'border-borderLight'}
              /> */}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs">
                  <div className="w-3/12 border-r-2 py-2 px-2">Roll Number</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-2/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '65vh' }}>
                {students.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto my-16" alt="" />
                ) : studentList ? (
                  studentList.length > 0 ? (
                    studentList.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-2 px-4 text-xs items-center ${
                              studentList.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-3/12 px-2">{x?.currentlyMapped?.rollNumber || '--'}</div>
                            <div className="w-3/12 px-2 ">{x?.username}</div>
                            <div className={`w-4/12 px-2 `}>{x?.full_name}</div>
                            <div className={`w-2/12 px-2 py-0.5`}>
                              <button
                                className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                                onClick={() => {
                                  selectStudent(x);
                                }}
                              >
                                Export
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                    Please select class & section from dropdown to get data
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
