import React from 'react';

const Notes = ({ notes, onClose }) => {
  const renderNotes = () => {
    if (!notes) return null;

    return notes.split('\n').map((line, index) => {
      if (line.trim().startsWith('###')) {
        const day = line.replaceAll('*', '').replaceAll('#', '').trim();
        return (
          <div key={index} className="my-2 font-medium underline">
            <p className="">{day.replaceAll('#', '').replaceAll('*', '')}</p>
          </div>
        );
      }

      if (line.trim().startsWith('##')) {
        const day = line.replaceAll('*', '').replaceAll('#', '').trim();
        return (
          <div key={index} className="my-2 font-medium underline text-lg">
            <p className="">{day.replaceAll('#', '').replaceAll('*', '')}</p>
          </div>
        );
      }

      return (
        <div className="leading-8" key={index}>
          {line.replaceAll('#', '').replaceAll('*', '')}
        </div>
      );
    });
  };

  const handleDownload = () => {
    // const input = document.getElementById('section-to-print3');
    // // Specify the id of the element you want to convert to PDF
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save('notes.pdf');
    //   // Specify the name of the downloaded PDF file
    // });
    window.print();
  };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/3 text-sm block m-auto mt-14 rounded-2xl relative">
        <div className="border-b border-borderYellow flex justify-between items-center px-6 py-2">
          <span className="block font-bold">Notes</span>
          <div className="flex gap-x-2 items-center">
            <button
              className="border border-primary text-white bg-primary px-6 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
              onClick={handleDownload}
            >
              Download PDF
            </button>
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={() => onClose()} />
          </div>
        </div>

        <div className="py-6 overflow-auto" id="section-to-print3">
          {renderNotes()}
        </div>
      </div>
    </div>
  );
};

export default Notes;
