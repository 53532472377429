import React from 'react';
import moment from 'moment';

function LeaveDeatils({ onClose, details }) { 
  // console.log(" -- details?.leaveInfo ", details?.leaveInfo )
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 pb-4 rounded-2xl relative">
        <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">Leave Details</span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-6 right-6 h-3 cursor-pointer"
          alt=""
          onClick={onClose}
        />
        <div className="mt-2 mb-4 mx-6 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">Student Name</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {details?.studentInfo?.full_name}
              </p>
            </div>
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">RoledIn ID</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {details?.studentInfo?.username}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap w-full mt-2">
            <div className="w-full py-4 overflow-auto">
              <label className="block text-textDark font-semibold mb-2">Leaves</label>
              <div className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden ">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/3 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/3 border-r-2 py-2 px-2">Category</div>
                    <div className="w-1/3 py-2 px-2">Attachment</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {Array.isArray(details?.leaveInfo) && details?.leaveInfo.length > 0 ? (
                    details?.leaveInfo?.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                            idx !== details?.leaveInfo.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/2 px-2 text-textDark">{x?.date ? moment(x.date).format('ll') : '--'}</div>
                          <div className="w-1/2 px-2 text-textDark">{x?.leaveCategory || '--'}</div>
                          <div className="w-1/2 px-2">
                            { x?.leaveAttachments?.length > 0 ? 
                            <a
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                              href={x?.leaveAttachments[0].location}
                              download
                            >
                              Download 
                            </a> : 
                           `Not Available` }
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveDeatils;
