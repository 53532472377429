import React, { useState } from 'react';
import CreatePostWithVideo from '../CreatePostWithVideoComponent';
import CreatePostWindowComponent from '../CreatePostWindow';
import CreatePostWithPicture from '../CreatePostWithPicture';
// import ShootVideoComponent from '../ShootVideoComponent';

const NewPostComponent = ({ refreshData, institutes }) => {
  const [post, setPost] = useState(false);
  const [postPicture, setPostPicture] = useState(false);
  const [postVideo, setPostVideo] = useState(false);

  const toggleOpenFeed = () => {
    setPost((prev) => !prev);
  };
  const togglePicture = () => {
    setPostPicture((prev) => !prev);
  };
  const toggleVideo = () => {
    setPostVideo((prev) => !prev);
  };

  return (
    <div className="h-auto w-full bg-white rounded-2xl shadow-sm mt-4">
      {post && (
        <CreatePostWindowComponent handleClose={toggleOpenFeed} refreshData={refreshData} institutes={institutes} />
      )}
      {postPicture && (
        <CreatePostWithPicture handleClose={togglePicture} refreshData={refreshData} institutes={institutes} />
      )}
      {postVideo && <CreatePostWithVideo handleClose={toggleVideo} refreshData={refreshData} institutes={institutes} />}
      <div className="px-4 py-2.5  text-black" style={{ borderBottom: '1px solid #E7E700' }}>
        <p className=" ">Create Post</p>
      </div>

      <p
        className="py-2 px-4 h-24 text-tableRowText text-xs"
        style={{ borderBottom: '0.5px solid #E7E700' }}
        onClick={() => toggleOpenFeed()}
      >
        Write your post here...
      </p>

      <div className="py-2 px-3 flex gap-x-3">
        <button
          className="flex w-1/2 justify-center gap-x-2 py-2 items-center rounded-2xl bg-primaryLight"
          onClick={() => togglePicture()}
        >
          <img
            className="h-2.5 mt-0.5 w-auto"
            alt=""
            src="https://static.overlay-tech.com/assets/8c17b83a-6582-4c96-abaa-700ddf50dc76.svg"
          />
          <p className="text-xs text-textDark">Picture</p>
        </button>

        <button
          className="flex w-1/2 justify-center gap-x-2 py-2 items-center rounded-full"
          onClick={() => toggleVideo()}
        >
          <img
            className="h-2.5 mt-0.5 w-auto"
            alt=""
            src="https://static.overlay-tech.com/assets/5d239d5d-bec2-44e1-8b65-67be4dbd2063.svg"
          />
          <p className="text-xs text-textDark">Video</p>
        </button>
      </div>
    </div>
  );
};

export default NewPostComponent;
