import { TEST } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const UPCOMING_TEXT = (classId, sectionId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}?year=${year}`;

export const STUDENT_MARKS = (classId, sectionId, studentId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/student/${studentId}/results?year=${year}`;

export const CREATE_TEST = (classId, sectionId, subjectId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/test?year=${year}`;

export const UPDATE_TEST = (testId) => `${TEST}/institute/class/section/subject/test/${testId}`;

export const GET_TEACHER_TEST = `${TEST}/getAllTest?year=${year}`;

export const GET_UPCOMING_TEST = `${TEST}/getUpcomingtest?year=${year}`;

export const DELETE_TEST = (classId, sectionId, testId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/${testId}?year=${year}`;

export const TEST_MARKS = (testId) => `${TEST}/${testId}?year=${year}`;

export const UPLOAD_ANSWER_SHEET = (testId, studentId) => `${TEST}/${testId}/${studentId}/answerSheet?year=${year}`;

export const EXAM_DATES = (classId, sectionId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/examDates?year=${year}`;

export const DELETE_EXAM_DATES = (id) => `${TEST}/institute/examDates/${id}?year=${year}`;

export const RESULT_RELEASE = `${TEST}/result/release?year=${year}`;

export const GET_RESULT_RELEASE = (category) => `${TEST}/result/release/${category}?year=${year}`;

export const DELETE_RESULT_RELEASE_DATE = (id) => `${TEST}/result/release/${id}?year=${year}`;

export const GET_RELEASE_DATES = `${TEST}/institute/test/release/date?year=${year}`;

export const SEND_RESULT_APPROVAL = (classId) => `${TEST}/institute/class/${classId}/result/release?year=${year}`;

export const STUDENT_REPORT = `${TEST}/institute/results?year=${year}`;

export const GET_SINGLE_TEST = (classId, sectionId, subject_id, test_id) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/subject/${subject_id}/test/${test_id}?year=${year}`;

export const SUBMIT_TEST = (testId) => `${TEST}/institute/test/${testId}?year=${year}`;

export const GET_SUBMITTED_TEST = (classId, sectionId, testId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/${testId}?year=${year}`;

export const CLASS_TEST_REPORT = (classId, sectionId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}?year=${year}`;

export const CLASS_RESULTS = (classId, sectionId) =>
  `${TEST}/institute/class/${classId}/section/${sectionId}/class/Result?year=${year}`;

export const EVALUATE_TEST = (testId) => `${TEST}/institute/test/${testId}/evaluate?year=${year}`;

export const SUBMIT_SUBJECT_RESULT = `${TEST}/institute/submitResult?year=${year}`;

export const RESULT_APPROVAL = (approvalId) => `${TEST}/institute/approval/${approvalId}?year=${year}`;

export const SEND_ADMISSION_NOTIFICATION = `${TEST}/institute/admissionNotification?year=${year}`;

export const GET_NEW_ADMISSION_STATUS = `${TEST}/institute/display/notification?year=${year}`;

export const CHANGE_ADMISSION_STATUS = (notificationId) =>
  `${TEST}/institute/display/notification/${notificationId}?year=${year}`;

export const GET_EXAM_SEQUENCE = (sessionId) =>  `${TEST}/institute/${sessionId}/examSequence`;
