import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import useApi from '../../../api/useApi';
import { EXPENSES, EXPENSE_REQUEST_LIST, EXPENSE_UPDATE, EXPENSE_CATEGORIES } from '../../../api/urls/finance';
import CreateExpense from '../../../components/Finance/ExpenseLedger/CreateExpense';
import ExpenseList from '../../../components/Finance/ExpenseLedger/ExpenseList';
import EditExpense from '../../../components/Finance/ExpenseLedger/EditExpense';
import ExpenseRequest from '../../../components/Finance/ExpenseLedger/ExpenseRequest';
import RaiseExpenseRequest from '../../../components/Finance/ExpenseLedger/RaiseExpenseRequest';
import SubmitJustification from '../../../components/Finance/ExpenseLedger/SubmitJustification';
import Receipt from '../../../components/Finance/ExpenseLedger/Receipt';

const Ledger = () => {
  const [filter, setFilter] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [openRaiseRequest, setOpenRaiseRequest] = useState(false);
  const [openSubmitJustification, setOpenSubmitJustification] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [expensesRequests, setExpensesRequests] = useState([]);
  const [selectedRequest, selectRequest] = useState();
  const [edit, setEdit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterDates, setFilterDates] = useState(false);

  const startDate = useRef();
  const endDate = useRef();

  const [{ isLoading, isError, data }, getExpenses] = useApi();
  const [expenseRequestsRes, getExpenseRequests] = useApi();
  const [deleteExpenseRes, deleteExpense] = useApi();
  const [categoryState, getCategories] = useApi();

  useEffect(() => {
    getExpenses(EXPENSES, 'GET');
    getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
    getCategories(EXPENSE_CATEGORIES, 'GET');
  }, []);

  useEffect(() => {
    if (!categoryState.isLoading && !categoryState.isError && categoryState.data) {
      setCategories(categoryState.data);
    }
  }, [categoryState.isLoading, categoryState.isError, categoryState.data]);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setExpenses(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!expenseRequestsRes.isLoading && !expenseRequestsRes.isError && expenseRequestsRes.data) {
      setExpensesRequests(expenseRequestsRes.data);
    }
  }, [expenseRequestsRes.isLoading, expenseRequestsRes.isError, expenseRequestsRes.data]);

  const refreshData = () => {
    getExpenses(EXPENSES, 'GET');
  };

  const refreshDataExpenseList = () => {
    getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
  };

  const handleDelete = (x) => {
    if (confirm('Are you sure you want to delete?')) deleteExpense(EXPENSE_UPDATE(x), 'DELETE');
  };

  useEffect(() => {
    if (!deleteExpenseRes.isLoading && !deleteExpenseRes.isError && deleteExpenseRes.status === 204) {
      refreshData();
      alert('Expense deleted successfully');
    }

    if (!deleteExpenseRes.isLoading && deleteExpenseRes.isError) {
      alert('Failed to delete');
    }
  }, [deleteExpenseRes.isLoading, deleteExpenseRes.isError, deleteExpenseRes.data]);

  let filteredData = expenses || [];
  if (filterDates) {
    if (startDate?.current?.value) {
      filteredData = filteredData.filter((x) =>
        moment(startDate?.current?.value).isSameOrBefore(moment(x.date).local())
      );
    }

    if (endDate?.current?.value) {
      filteredData = filteredData.filter((x) => moment(endDate?.current?.value).isSameOrAfter(moment(x.date).local()));
    }
  }

  return (
    <>
      {filter && (
        <div
          className="flex h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-2/5 mb-auto  pb-6 text-sm mx-auto mt-10 block rounded-2xl">
            <div
              className="flex w-full justify-between items-center relative px-2"
              style={{ borderBottom: '0.71px solid #E7E700' }}
            >
              <p className="block font-bold p-4 text-textBlue1">Filter (Date Wise)</p>

              <img
                src="assets/img/Close.svg"
                className="absolute right-4 h-3 cursor-pointer z-50"
                alt=""
                onClick={() => setFilter(false)}
              />
            </div>
            <div className="grid grid-cols-2 px-4 pb-2 gap-x-4">
              <div className="my-2 relative font-semibold w-full">
                <label className="text-sm text-textDark">From</label>
                <input
                  type="date"
                  ref={startDate}
                  placeholder="Eg-20 August 2021"
                  autoComplete="off"
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  // value={filterDates.startDate}
                />
              </div>
              <div className="my-2  relative font-semibold w-full">
                <label className="text-sm text-textDark">To</label>
                <input
                  ref={endDate}
                  type="date"
                  placeholder="Eg- 20 August 2021"
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  // value={filterDates.endDate}
                />
              </div>
            </div>
            <button
              className="border floa px-8 mr-4 py-2 mt-1 float-right rounded-3xl bg-primary text-xs font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center"
              onClick={() => {
                if (startDate?.current?.value || endDate?.current?.value) {
                  setFilterDates({
                    startDate: startDate?.current?.value,
                    endDate: endDate?.current?.value,
                  });
                  setFilter(false);
                } else alert('Please Select Start or End Date');
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      )}
      <div className="w-full md:w-4/5 relative  pb-10">
        {edit && <EditExpense onClose={() => setEdit(null)} receipt={edit} refreshData={refreshData} />}
        {receipt && <Receipt onClose={() => setReceipt(null)} receipt={receipt} />}
        {openRaiseRequest && (
          <RaiseExpenseRequest
            onClose={() => {
              setOpenRaiseRequest(false);
              selectRequest(null);
            }}
            refreshData={refreshDataExpenseList}
            selectedRequest={selectedRequest}
          />
        )}
        {openSubmitJustification && (
          <SubmitJustification
            onClose={() => setOpenSubmitJustification(false)}
            selectedData={openSubmitJustification}
            refresh={refreshData}
          />
        )}

        <CreateExpense refreshData={refreshData} />
        <ExpenseList
          expenses={filteredData}
          isLoading={isLoading}
          setFilter={setFilter}
          openReceipt={setReceipt}
          setOpenSubmitJustification={setOpenSubmitJustification}
          handleDelete={handleDelete}
          openEdit={setEdit}
          categories={categories}
        />
        <ExpenseRequest
          setOpenRaiseRequest={setOpenRaiseRequest}
          expensesRequests={expensesRequests}
          selectRequest={selectRequest}
        />
      </div>
    </>
  );
};
export default Ledger;
