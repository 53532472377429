import React, { useState } from 'react';
import DropDown from '../../UI/DropDown';

export function TimetableDataTable({ title, classes, handleCreate, handleDelete }) {
  const [sort, setSort] = useState('');

  let filteredData = classes || [];

  if (sort) {
    if (sort === 'Uploaded/Created') {
      filteredData = filteredData.filter((x) => x.timeSlot.length > 0);
    }
    if (sort === 'Pending') {
      filteredData = filteredData.filter((x) => !x.timeSlot || x.timeSlot.length <= 0);
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-6/12">
            <p>{title}</p>
          </div>
          <div className="flex justify-end items-center w-full">
            <DropDown
              data={['All', 'Uploaded/Created', 'Pending']}
              width={'w-1/2 md:w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{
              width: '96%',
            }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Section</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Class Teacher</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Action</div>
                <div className="w-1/5 py-2 px-2">Timetable</div>
              </div>
            </div>
            <div
              className="customScrollBar"
              style={{
                overflowY: 'scroll',
                maxHeight: '50vh',
              }}
            >
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/5 px-2 text-textDark">{x.classInfo.name}</div>
                      <div className="w-1/5 px-2 text-textDark">{x?.sectionInfo.name}</div>
                      <div className="w-1/5 px-2 text-textDark">
                        {x.headTeacher !== null ? x?.headTeacher?.full_name : 'Not Assigned'}
                      </div>
                      <div className="w-1/5 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                          onClick={() => (x.timeSlot.length > 0 ? handleDelete(x.timeSlot[0]._id) : handleCreate(x))}
                        >
                          {x.timeSlot.length > 0 ? 'Delete' : 'Create'}
                        </button>
                      </div>
                      <div className="w-1/5 px-2">
                        {x.timeSlot.length > 0 ? (
                          <button
                            type="submit"
                            className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                            onClick={() => handleCreate(x)}
                          >
                            View
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
