import React from 'react';
import { useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import Salaries from './StaffSalaries';
import ClasswiseFeeStructure from './ClasswiseFeeStucture';
import Ledger from './ExpenseLedger';
import FeePayment from './RecordFeePayment';
import Leave from './Leave';
import TaskBoard from '../Owner/TaskBoard';
import Feedback from '../Parents/Feedback';
import Attendance from './Attendance';
import Meeting from '../Owner/Meeting';
import TransportFee from './TransportFee';
import FeeApproval from './FeeApproval';
import AcademicCalender from '../Principal/AcademicCalender';

const Finance = () => {
  const location = useLocation();

  return (
    <div>
      {location.search === '' && <Dashboard />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('StaffandSalaries') && <Salaries />}
      {location.search.includes('ClassWiseFeeStructure') && <ClasswiseFeeStructure />}
      {location.search.includes('ExpenseLedger') && <Ledger />}
      {location.search.split('=')[1] === 'RecordFeePayment' && <FeePayment />}
      {location.search.split('=')[1] === 'Fee' && <FeeApproval />}
      {location.search.includes('ApplyForLeave') && <Leave />}
      {location.search.includes('Task') && <TaskBoard />}
      {location.search.includes('Meeting') && <Meeting />}
      {location.search.includes('Feedback') && <Feedback role="Finance" />}
      {location.search.includes('Attendance') && <Attendance />}
      {location.search.includes('Transport') && <TransportFee />}
    </div>
  );
};
export default Finance;
