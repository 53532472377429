import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SaveMarks from '../../../components/Teacher/Marks/SaveMarks';
import useApi from '../../../api/useApi';
import { GET_TEACHER_ASSIGNMENT } from '../../../api/urls/notes';
import { GET_TEACHER_TEST, SUBMIT_SUBJECT_RESULT } from '../../../api/urls/test';

const Marks = ({ subjects }) => {
  const [id, setId] = useState();
  const [list, setList] = useState([]);
  const [selected, select] = useState();
  const [assignments, getAssignments] = useApi();
  const [testRes, getTests] = useApi();
  const [submitRes, submitResult] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
    getTests(GET_TEACHER_TEST, 'GET');
    getAssignments(GET_TEACHER_ASSIGNMENT, 'GET');
  }, []);

  useEffect(() => {
    if (subjects.length > 0) {
      let groupedItems = {};
      subjects.forEach((subject) => {
        groupedItems[subject.subjectId + subject.section._id] = [];
      });
      setList(groupedItems);
    }
  }, [subjects]);

  useEffect(() => {
    if (
      !testRes.isLoading &&
      !testRes.isError &&
      testRes.data &&
      subjects.length > 0 &&
      !assignments.isLoading &&
      !assignments.isError &&
      assignments.data &&
      subjects.length > 0
    ) {
      if (Array.isArray(testRes.data)) {
        testRes.data.forEach((test) => {
          if (Array.isArray(list[test.subject_id + test.section_id]))
            list[test.subject_id + test.section_id].push({ ...test, _type: 'test' });
        });
        setList(list);
      }

      if (Array.isArray(assignments.data)) {
        assignments.data.forEach((assignment) => {
          if (Array.isArray(list[assignment.subject_id + assignment.section_id]))
            list[assignment.subject_id + assignment.section_id].push(assignment);
        });
        setList(list);
      }
      // getAssignments(GET_TEACHER_ASSIGNMENT, 'GET');
    }
  }, [testRes, subjects, assignments]);

  // useEffect(() => {
  //   if (
  //     !assignments.isLoading &&
  //     !assignments.isError &&
  //     assignments.data &&
  //     Array.isArray(assignments.data) &&
  //     subjects.length > 0 &&
  //     list &&
  //     !testRes.isLoading
  //   ) {
  //     console.log(3);
  //     assignments.data.forEach((assignment) => {
  //       if (Array.isArray(list[assignment.subject_id + assignment.section_id]))
  //         list[assignment.subject_id + assignment.section_id].push(assignment);
  //     });
  //     setList(list);
  //   }
  // }, [assignments, subjects]);

  const handleClose = () => {
    setId(null);
  };

  const handleSubmit = (item) => {
    select(item);
    const body = {
      classId: item.class_id,
      sectionId: item.section_id,
      subjectId: item.subject_id,
      test_type: item.test_type,
    };
    submitResult(SUBMIT_SUBJECT_RESULT, 'POST', body);
  };

  useEffect(() => {
    if (!submitRes.isLoading && !submitRes.isError && [200, 201].includes(submitRes.status)) {
      handleClose(false);
      alert(submitRes?.data?.message || 'Success');
      let groupedItems = {};
      subjects.forEach((subject) => {
        groupedItems[subject.subjectId + subject.section._id] = [];
      });
      setList(groupedItems);
      getTests(GET_TEACHER_TEST, 'GET');
      getAssignments(GET_TEACHER_ASSIGNMENT, 'GET');
    }

    if (!submitRes.isLoading && submitRes.isError) {
      alert('Failed to submit');
    }
  }, [submitRes.isLoading, submitRes.isError, submitRes.data]);

  return (
    <>
      {id && <SaveMarks handleClose={handleClose} id={id} />}
      {subjects.map((y) => (
        <div className="relative w-full md:w-4/5 pb-4">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold w-full">
                  <p>{`${y.subjectName} (${y.class.name} - ${y.section.name})`}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-3/12 border-r-2 py-2 px-2">Test Type</div>
                      <div className="w-3/12 border-r-2 py-2 px-2">Test Date</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Total Marks</div>
                      <div className="w-4/12 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div>
                    {/* {assignments.isLoading || testRes.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : ( */}
                    <>
                      <div className="overflow-auto customScrollBar" style={{ maxHeight: '50vh' }}>
                        {list[y.subjectId + y.section._id] &&
                          list[y.subjectId + y.section._id].map((x, idx) => (
                            <div className="w-full">
                              <div
                                className={`justify-center flex w-full relative text-left py-3 px-4 text-xs items-center ${
                                  idx !== list[y.subjectId + y.section._id].length - 1 && 'border-b border-borderYellow'
                                }`}
                              >
                                <div className="w-3/12 px-2 py-2 text-tableRowText">{x?.title}</div>
                                <div className="w-3/12 px-2 text-tableRowText">
                                  {x._type === 'test' ? moment(x.start).format('ll') : moment(x.end).format('ll')}
                                </div>
                                <div className="w-2/12 px-2 text-tableRowText">
                                  {x._type === 'test' ? x?.maxMarks : x?.marks}
                                </div>
                                <div className="w-4/12 pl-2 flex gap-x-2 items-center">
                                  {['unit', 'term', 'final', 'year'].some((substring) =>
                                    x?.title.toLowerCase().includes(substring)
                                  ) && (
                                    <>
                                      <button
                                        className="w-1/2 border border-primary py-2 rounded-3xl text-primary  text-xs focus:outline-none flex justify-center text-center items-center"
                                        onClick={() => setId(x)}
                                      >
                                        {x.isMarked ? 'Edit' : 'Upload Marks'}
                                      </button>

                                      {submitRes.isLoading && selected._id === x._id ? (
                                        <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
                                      ) : (
                                        <button
                                          className={`w-1/2 mr-4 ml-auto border ${
                                            x.resultSubmit
                                              ? 'bg-borderLight border-borderLight cursor-not-allowed'
                                              : 'border-primary bg-primary'
                                          } px-6 py-2 rounded-3xl text-white  text-xs focus:outline-none flex justify-center text-center items-center`}
                                          onClick={() => handleSubmit(x)}
                                          disabled={x.resultSubmit}
                                        >
                                          Submit
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  </div>
                </div>
              </div>
              {/* {submitRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
                ) : (
                  <button
                    className="w-36 mr-4 mb-4 ml-auto border border-primary px-6 py-2 rounded-3xl text-white bg-primary  text-xs focus:outline-none flex justify-center text-center items-center"
                    onClick={() => handleSubmit(y[0])}
                  >
                    Submit
                  </button>
                )} */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Marks;
