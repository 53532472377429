import React, { useEffect, useState } from 'react';
import { INVENTORY } from '../../../api/urls/finance';
import useApi from '../../../api/useApi';
import AddInventory from '../../../components/Teacher/AdditionalResponsibility/AddInventory';

function InventoryIncharge() {
  const [open, setOpen] = useState(false);
  const [selectedInventory, selectInventory] = useState(null);

  const [inventoryRes, getInventory] = useApi();

  useEffect(() => {
    getInventory(INVENTORY, 'GET');
    window.scrollTo(0, 0);
  }, []);

  const handleClose = (refresh) => {
    if (refresh) getInventory(INVENTORY, 'GET');

    setOpen(false);
    selectInventory(null);
  };

  let inventoryList = inventoryRes?.data || [];

  return (
    <>
      {(open || selectedInventory) && <AddInventory handleClose={handleClose} selectedInventory={selectedInventory} />}
      <div className="relative w-full md:w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-6/12">
                <p>{`Inventory Data`}</p>
              </div>
              <div className="font-bold w-6/12">
                <button
                  className="border border-primary w-40 py-2 rounded-3xl text-primary font-medium ml-auto focus:outline-none flex justify-center text-center items-center text-xs"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Add Inventory
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Category</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Inventory Name</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Size</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Quantity</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Price</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {inventoryRes?.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : inventoryList.length === 0 ? (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  ) : (
                    inventoryList.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                            idx !== inventoryList.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/6 px-2 text-textDark">{x?.category}</div>
                          <div className="w-1/6 px-2 text-textDark">{x?.type || '--'}</div>
                          <div className="w-1/6 px-2 text-textDark">{x?.size || '--'}</div>
                          <div className="w-1/6 px-2 text-textDark">{x?.quantity || '--'}</div>
                          <div className="w-1/6 px-2 text-textDark">{x?.price || '--'}</div>
                          <div className="w-1/6 px-2">
                            <button
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() => {
                                selectInventory(x);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryIncharge;
