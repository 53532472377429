import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ({ labels, values, color }) => {
  const data = {
    labels: labels || ['Red', 'Blue'],
    datasets: [
      {
        label: '',
        data: values || [12, 19],
        backgroundColor: color || ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        datalabels: {
          color: 'grey',
          align: 'top',
          formatter: (value) => {
            return `${value}%`;
          },
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <>
      <Pie data={data} plugins={[ChartDataLabels]} options={options} />
    </>
  );
};

export default PieChart;
