import { DASHBOARD } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}
console.log(year);
export const TEACHER = `${DASHBOARD}/teacher?year=${year}`;

export const STUDENT = `${DASHBOARD}/student?year=${year}`;

export const PARENT = `${DASHBOARD}/parent?year=${year}`;

export const ADMIN = `${DASHBOARD}/admin?year=${year}`;

export const OWNER = `${DASHBOARD}/owner?year=${year}`;

export const PRINCIPAL = `${DASHBOARD}/principal?year=${year}`;

export const PENDING_FEE = `${DASHBOARD}/pendingFee?year=${year}`;

export const FINANCE = `${DASHBOARD}/finance?year=${year}`;
