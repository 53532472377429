import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { TASK_CREATE } from '../../../api/urls/appointment';
import { getRole } from '../../../utils';

function CreateTask({ teachingStaff, nonTeachingStaff, getFile, handleClose }) {
  const [createState, taskCreate] = useApi();

  const [selectedList, selectList] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [query, setSearchQuery] = useState('');
  const [staffType, setStaffType] = useState('All');
  const [filteredStaffList, setFilteredStaffList] = useState([]);

  useEffect(() => {
    if (teachingStaff) {
      setFilteredStaffList([...filteredStaffList, ...teachingStaff]);
    }
  }, [teachingStaff]);

  useEffect(() => {
    if (nonTeachingStaff) {
      setFilteredStaffList([...filteredStaffList, ...nonTeachingStaff]);
    }
  }, [nonTeachingStaff]);

  const [formData, setFormData] = useState({
    title: '',
    type: '',
    dueDate: '',
    reason: '',
    teams: [],
  });

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];

    if (!formData?.title?.trim()) str.push('Title');
    if (!formData?.dueDate) str.push('Due Date');

    if (str.length > 0) {
      alert(`Missing required meetings: ${str.join(', ')}`);
      return;
    }

    if (selectedList.length === 0) {
      alert(`Please select team members`);
      return;
    }

    const body = {
      ...formData,
      date: formData.dueDate,
      teams: selectedList.map((x) => {
        return {
          id: x._id,
          name: x.full_name,
        };
      }),
    };

    taskCreate(TASK_CREATE, 'POST', body);
  };

  useEffect(() => {
    if (!createState.isLoading && !createState.isError && createState.status) {
      handleClose(true);
      alert('Success');
    }

    if (createState.isError) {
      alert('Failed');
    }
  }, [createState]);

  useEffect(() => {
    let list = [];
    if (staffType === 'All') list = [...teachingStaff, ...nonTeachingStaff];
    else if (staffType === 'Teaching') list = teachingStaff;
    else list = nonTeachingStaff;

    if (query) {
      list = list.filter((x) => {
        return x.full_name ? x.full_name.includes(query) : false;
      });
    }

    setFilteredStaffList(list);
  }, [staffType, query]);

  return (
    <div
      className="flex justify-center md:items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-2/3 md:w-7/12 text-sm mb-auto mt-16 md:mt-auto rounded-2xl relative">
        <span className="block font-bold p-4 border-b border-borderYellow pl-6">Create New Task</span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-5 right-6 h-3 cursor-pointer"
          alt=""
          onClick={() => {
            handleClose(false);
          }}
        />
        <form className="mt-2 mb-2 px-6 flex w-full flex-col" autoComplete="off" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">
                Title<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                // placeholder="School Inspection"
                autoComplete="off"
                name="title"
                required
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-2 mb-4 relative font-semibold">
              <label className="block text-textDark text-sm">
                Due Date<span className="text-red-500 ml-1">*</span>
              </label>
              <DatePicker
                selected={formData.dueDate}
                required
                onChange={(date) => setFormData({ ...formData, dueDate: date })}
                dateFormat={'dd-MM-yyyy'}
                className="w-full rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-2 mb-4 relative font-semibold">
              <label className="block text-textDark text-sm">Staff Type</label>
              <select
                type="text"
                onChange={(e) => setStaffType(e.target.value)}
                placeholder="Select Staff Type"
                className="w-full rounded-3xl  text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              >
                <option>All</option>
                <option>Teaching</option>
                <option>Non Teaching</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between w-full gap-4">
            <div className="mt-2 mb-4 w-full md:w-7/12 relative font-semibold h-48">
              <label className="block text-textDark text-sm">Description</label>
              <textarea
                type="text"
                rows={10}
                name="reason"
                onChange={handleInputChange}
                placeholder="Reason"
                className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-2 mb-4 w-full md:w-5/12 relative font-semibold h-48 ">
              <label className="block text-textDark text-sm">
                Team Members<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="w-full h-44 rounded-2xl mt-2 border border-borderLight focus:outline-none flex flex-col justify-start">
                <div className="w-8/12 border px-3 m-1.5 border-b border-borderLight rounded-2xl text-sm text-tableRowText focus:outline-none flex">
                  <img src="assets/img/Search.svg" className="h-3 mt-2 w-3 mx-2 " alt="" />
                  <input
                    type="text"
                    placeholder="Search Staff"
                    autoComplete="off"
                    className="text-left w-full bg-transparent text-tableRowText focus:outline-none h-7 text-xs"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                    }}
                    onFocus={() => setOpenList(true)}
                    style={{ borderColor: '#c4c4c4' }}
                  />
                  {openList && (
                    <div className="absolute bg-white shadow-2xl top-7 left-0 rounded-md p-4 max-h-56 overflow-auto z-50">
                      {filteredStaffList.map((x) => (
                        <div
                          className="my-2 flex cursor-pointer"
                          key={x._id}
                          onClick={() => {
                            // const isPresent = selectedList.filter((y) => x._id === y._id);
                            // if (isPresent.length > 0) {
                            //   selectList([...selectedList.filter((y) => x._id !== y._id)]);
                            // } else {
                            selectList([...new Set([...selectedList, x])]);
                            // selectList([...selectedList, x]);
                            setOpenList(false);
                            // }
                          }}
                        >
                          <img src={getFile('profileImg', x)} className="h-9 w-9 rounded-tl-md rounded-br-md" />
                          <div className="flex flex-col ml-2">
                            <span className="text-black text-xxs">{x.full_name}</span>
                            <span className="text-tableRowText text-xxs -mt-1">
                              {x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ') || ''}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="p-2 border-t flex flex-wrap gap-2 w-full overflow-auto customScrollBar">
                  {selectedList.map((x) => (
                    <div className="gap-x-2 rounded-3xl flex justify-between items-center text-textDark text-xxs bg-tableHeader border px-3 py-1.5 focus:outline-none">
                      <span>{x.full_name}</span>
                      <img
                        src="assets/img/Close.svg"
                        alt=""
                        className="cursor-pointer h-auto w-2"
                        onClick={() => {
                          selectList([...selectedList.filter((y) => x._id !== y._id)]);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className=" my-4 w-full relative">
            {createState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <button
                type="submit"
                className="border float-right px-12 py-2 rounded-3xl text-xs font-medium focus:outline-none text-center bg-primary text-white"
              >
                Create Task
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateTask;
