import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { SECTION, CLASS } from '../../../api/urls/institute';
import DropDown from '../../UI/DropDown';
import { useStateValue } from '../../../ContextApi/StateProvider';

function NewClassSection({ toggleOpenClassSection, refreshList }) {
  const [{ classSections }, _] = useStateValue();
  const [sections, setSections] = useState([]);
  const [selectedClass, selectClass] = useState();
  const [selectedClass2, selectClass2] = useState();
  const [newSection, setNewSection] = useState();
  const [newClass, setNewClass] = useState();

  const [{ isLoading, isError, status }, addSection] = useApi();
  const [addClassRes, addClass] = useApi();

  const handleSubmitClass = (e) => {
    e.preventDefault();

    let category = '';
    if (newClass.toLowerCase().includes('kg') || newClass.toLowerCase().includes('nursery')) {
      category = 'PrePrimary';
    } else if (newClass.includes(11) || newClass.includes(12)) {
      category = 'Senior-Secondary';
    } else if (newClass.includes(9) || newClass.includes(10)) {
      category = 'Secondary';
    } else if (newClass.includes(6) || newClass.includes(7) || newClass.includes(8)) {
      category = 'Middle';
    } else if (
      newClass.includes(1) ||
      newClass.includes(2) ||
      newClass.includes(3) ||
      newClass.includes(4) ||
      newClass.includes(5)
    ) {
      category = 'Primary';
    }

    const body = {
      name: newClass,
      category,
    };

    addClass(CLASS, 'POST', body);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      section: newSection,
    };

    addSection(SECTION(selectedClass2.id), 'POST', body);
  };

  useEffect(() => {
    if (!addClassRes.isLoading && !addClassRes.isError && addClassRes.status == 201) {
      alert('SUCCESS');
      setNewClass();

      refreshList();
      toggleOpenClassSection();
    }

    if (!addClassRes.isLoading && addClassRes.isError) {
      alert('FAILED');
    }
  }, [addClassRes.isLoading, addClassRes.isError, addClassRes.status]);

  useEffect(() => {
    if (!isLoading && !isError && status) {
      alert('SUCCESS');
      selectClass2();
      setNewSection();

      refreshList();
      toggleOpenClassSection();
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [isLoading, isError, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 text-sm block m-auto mt-10 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold ">New Class/Section</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleOpenClassSection} />
        </div>
        <div className="my-4">
          <span className="block font-bold px-6">View Existing Class</span>
          <div className="grid grid-cols-2 gap-x-4 place-items-end px-6">
            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-xs">Select Class</label>
              <DropDown
                field={'name'}
                data={classSections}
                title={'Select Class'}
                width={'w-full'}
                handleItemClick={(e) => {
                  selectClass(e);
                  setSections(e.sections);
                }}
                margin={'mt-2'}
                type={'obj'}
                otherClasses={'border-borderLight'}
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              {/* <button
                className="w-40 border ml-auto  py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-white bg-primary"
                onClick={() => {}}
              >
                View
              </button> */}
            </div>
          </div>

          <div className="bg-white m-auto border rounded-2xl border-borderYellow mx-6 my-4">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/2 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/2 py-2 px-2">Section</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '25vh' }}>
              {selectedClass ? (
                sections.length > 0 ? (
                  sections.map((x, idx) => {
                    return (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                            idx !== sections.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/2 px-2 py-2 text-textDark ">{selectedClass?.name}</div>
                          <div className="w-1/2 px-2 py-2 text-textDark">{x.name}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center `}
                    >
                      <div className="w-1/2 px-2 py-2 text-textDark ">{selectedClass?.name}</div>
                      <div className="w-1/2 px-2 py-2 text-textDark">{'-'}</div>
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </div>

          <div className="w-full border-t border-borderYellow px-6 py-4">
            <span className="block font-bold ">Create Class</span>
            <div className="w-full grid grid-cols-2 gap-x-4">
              <div className="mt-2 mb-2 w-full relative font-semibold ">
                <label className="block text-textDark text-xs">Class</label>
                <input
                  type="text"
                  placeholder="Enter Class"
                  onChange={(e) => {
                    setNewClass(e.target.value);
                  }}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                />
              </div>
              <button
                className="w-40 border ml-auto mt-auto mb-2 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-white bg-primary"
                onClick={handleSubmitClass}
              >
                Create
              </button>
            </div>
          </div>

          <div className="w-full border-t border-borderYellow px-6 py-4 ">
            <span className="block font-bold ">Create Section</span>
            <div className="w-full grid grid-cols-2 gap-x-4">
              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="block text-textDark text-xs">Select Class</label>
                <DropDown
                  data={classSections}
                  title={'Select Class'}
                  width={'w-full'}
                  handleItemClick={(e) => {
                    selectClass2(e);
                  }}
                  margin={'mt-2'}
                  type={'obj'}
                  field={'name'}
                  otherClasses={'border-borderLight'}
                />
              </div>

              <div className="mt-2 mb-2 w-full relative font-semibold ">
                <label className="block text-textDark text-xs">Section</label>
                <input
                  type="text"
                  placeholder="Enter Section"
                  onChange={(e) => {
                    setNewSection(e.target.value);
                  }}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                />
              </div>
            </div>
          </div>

          <button
            className="w-40 mr-6 border ml-auto py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-white bg-primary"
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewClassSection;
