import React, { useState } from 'react';
import DropDown from '../../UI/DropDown';
import Search from '../../UI/Search';

function StudentReport({ selectStudent, toggleExport, data, setType, isLoading }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');

  const getFirstandLastDateOfMonth = () => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const month = date.toLocaleString('default', { month: 'short' });

    return `${firstDay.getDate()} ${month} to ${lastDay.getDate()} ${month}`;
  };

  let filteredData = data || [];

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = filteredData.sort((a, b) =>
        a?.userInfo?.userInfo?.full_name?.toLowerCase() < b?.userInfo?.userInfo?.full_name?.toLowerCase() ? 1 : -1
      );
    }
    if (sort === 'Name- z to a') {
      filteredData = filteredData.sort((a, b) =>
        a?.userInfo?.userInfo?.full_name?.toLowerCase() > b?.userInfo?.userInfo?.full_name?.toLowerCase() ? 1 : -1
      );
    }
  }

  if (search) {
    filteredData = filteredData.filter(
      (x) =>
        x?.userInfo?.userInfo?.full_name?.toLowerCase().includes(search.toLowerCase()) ||
        x?.userInfo?.userInfo?.username?.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>{`Student Attendance (${getFirstandLastDateOfMonth()})`}</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <button
              className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
              onClick={() => setType('student')}
            >
              Export
            </button>

            <DropDown
              data={[
                'All',
                'Name- a to z',
                'Name- z to a',
                'Pre - Primary',
                'Primary',
                'Middle',
                'Secondary',
                'Senior Secondary',
              ]}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />

            <Search
              placeholder={'Search'}
              width={'w-1/4'}
              onChange={(x) => setSearch(x)}
              onEnter={() => console.log('enter')}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">ROLEDin ID </div>
                <div className="w-1/5 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Father’s Name</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Percentage</div>
                <div className="w-1/5 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-tableRowText ">{x?.userInfo?.userInfo?.username}</div>
                        <div className="w-1/5 px-2 text-textDark">{x?.userInfo?.userInfo?.full_name}</div>
                        <div className={`w-1/5 px-2 text-textDark`}>
                          {x?.userInfo.userInfo?.parentDetail &&
                            x?.userInfo.userInfo?.parentDetail.length > 0 &&
                            x?.userInfo.userInfo?.parentDetail[0].father_name}
                        </div>
                        <div className={`w-1/5 px-2 text-tableRowText`}>{x.cumulative + '%' || 0}</div>{' '}
                        <div className={`w-1/5 px-2 `}>
                          {' '}
                          <button
                            className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => selectStudent(x)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentReport;
