import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import Search from '../../UI/Search';

const excelHeadersNewStudent = [
  { label: 'Student Name', key: 'name' },
  { label: 'Father’s Name', key: 'father' },
  { label: 'Date of Admission', key: 'admissionDate' },
  { label: 'Last Class Attended', key: 'lastClass' },
];

const excelHeaders = [
  { label: 'Student Name', key: 'name' },
  { label: 'Father’s Name', key: 'father' },
  { label: 'Date of Admission', key: 'admissionDate' },
  { label: 'Class', key: 'class' },
];

function NewStudents({ title, selectStudent, toggleWithdrawl, openDetails, data, isLoading = false }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      let x = [];
      if (title === 'New Students') {
        x = data.map((x) => {
          return {
            name: x.name,
            father: x.parent1 || '',
            admissionDate: x.joined ? moment(x?.joined).format('ll') : '',
            lastClass: x?.lastSchool?.class || '',
          };
        });
      } else {
        x = data.map((x) => {
          return {
            name: x?.user?.full_name || '',
            father:
              (x?.user?.parentDetail && x?.user?.parentDetail.length > 0 && x?.user?.parentDetail[0].father_name) || '',
            admissionDate: x.createdDate ? moment(x?.createdDate).format('ll') : moment(x?.createdAt).format('ll'),
            class: x?.classInfo ? x?.classInfo?.name + '-' + x?.sectionInfo?.name : '',
          };
        });
      }

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  let filteredData = data;

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = data.sort((a, b) =>
        a?.user?.full_name
          ? a?.user?.full_name.toLowerCase() > b?.user?.full_name.toLowerCase()
            ? 1
            : -1
          : a?.name.toLowerCase() > b?.name.toLowerCase()
          ? 1
          : -1
      );
    }
    if (sort === 'Name- z to a') {
      filteredData = data.sort((a, b) =>
        a?.user?.full_name
          ? a?.user?.full_name.toLowerCase() < b?.user?.full_name.toLowerCase()
            ? 1
            : -1
          : a?.name.toLowerCase() < b?.name.toLowerCase()
          ? 1
          : -1
      );
    }
  }

  if (search) {
    filteredData = data.filter((x) =>
      x?.user?.full_name
        ? x?.user?.full_name.toLowerCase().includes(search.toLowerCase())
        : x?.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>{title}</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            {title !== 'Admission withdrawal' && (
              <ExcelExportBtn
                text={'Export Data'}
                fileName={'students.csv'}
                data={excelData}
                headers={title === 'New Students' ? excelHeadersNewStudent : excelHeaders}
              />
            )}

            {title === 'New Students' && (
              <button
                className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
                onClick={() => openDetails(null, 'ADD')}
              >
                Add New Student
              </button>
            )}

            {title === 'Admission withdrawal' && (
              <button
                className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
                onClick={toggleWithdrawl}
              >
                Withdrawal
              </button>
            )}

            {title !== 'New Students' && (
              <Search
                placeholder={'Search'}
                width={'w-1/4'}
                onChange={(x) => setSearch(x)}
                onEnter={() => console.log('enter')}
              />
            )}

            <DropDown
              data={['Name- a to z', 'Name- z to a']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Father’s Name</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Date of Admission</div>
                <div className="w-1/5 border-r-2 py-2 px-2">
                  {title === 'New Students' ? 'Last Class Attended' : 'Class'}
                </div>
                <div className="w-1/5 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
              ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                          idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-textDark ">{x?.user?.full_name || x.name}</div>
                        <div className="w-1/5 px-2 text-textDark">
                          {(x?.user?.parentDetail &&
                            x?.user?.parentDetail.length > 0 &&
                            x?.user?.parentDetail[0].father_name) ||
                            x.parent1}
                        </div>
                        <div className={`w-1/5 px-2 text-tableRowText`}>
                          {x.createdDate
                            ? moment(x?.createdDate).format('ll')
                            : x?.user?.createdAt
                            ? moment(x?.user.createdAt).format('ll')
                            : x?.joined
                            ? moment(x?.joined).format('ll')
                            : ''}
                        </div>
                        <div className={`w-1/5 px-2 text-tableRowText`}>
                          {x?.classInfo
                            ? x?.classInfo?.name + '-' + x?.sectionInfo?.name
                            : x?.lastSchool
                            ? x?.lastSchool?.class
                            : '--'}
                        </div>{' '}
                        <div className={`w-1/5 px-2 `}>
                          {' '}
                          <button
                            className="w-11/12 border  py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => {
                              if (title === 'New Students') {
                                selectStudent(x);
                              } else {
                                openDetails(x, 'VIEW');
                              }
                            }}
                          >
                            {title === 'New Students' ? 'Map Class' : 'View Detail'}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewStudents;
