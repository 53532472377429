import React, { useState, useEffect, useCallback } from 'react';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
// import DropDown from '../../../components/UI/DropDown';
import { EVENT } from '../../../api/urls/timeTable';
import useApi from '../../../api/useApi';
import moment from 'moment';

const Calender = () => {
  const [events, setEvents] = useState([]);

  const [{ isLoading, isError, data }, getEvents] = useApi();
  const [selectedDay, selectDay] = useState();

  // const [dropDown1, setDropDown1] = useState('School');

  useEffect(() => {
    getEvents(EVENT, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      if (Array.isArray(data) && data.length > 0) {
        const x = data.map((x) => {
          return {
            title: x.title,
            start: new Date(x.eventDate),
            end: new Date(x.eventDate),
            description: x?.description,
            startTime: moment(x.start).format('hh:mm a') || '---',
            endTime: moment(x.end).format('hh:mm a') || '---',
          };
        });
        setEvents(x);
      }
    }
  }, [isLoading, isError, data]);

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(!(event.title.includes('PTM') || event.title.includes('MEETING')) && {
        className: 'eventColor',
      }),
    }),
    []
  );

  return (
    <div className="w-full md:w-4/5">
      {selectedDay && (
        <div
          className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 mb-auto mt-16 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 border-b border-borderYellow">{`${moment(
              selectedDay.start
            ).format('DD MMM YYYY')}`}</span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectDay(null)}
            />
            <div className="mt-4 mb-4 mx-6 ">
              <div className="grid grid-cols-2">
                <div className="mt-2 mb-4 relative font-semibold ">
                  <p className="text-black text-sm font-bold mb-0.5">Start Time</p>
                  <p className="text-tableRowText text-sm">{selectedDay?.startTime}</p>
                </div>

                <div className="mt-2 mb-4 relative font-semibold ">
                  <p className="text-black text-sm font-bold mb-0.5">End Time</p>
                  <p className="text-tableRowText text-sm">{selectedDay?.endTime}</p>
                </div>
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">Title</p>
                <p className="text-tableRowText text-sm">{selectedDay?.title}</p>
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">
                  {selectedDay?.type === 'MEETING' ? 'Agenda' : 'Description'}
                </p>
                <p className="text-tableRowText text-sm">{selectedDay?.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative ">
        <div className="w-full bg-white rounded-xl pb-1 ">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>{`Calender(April ${new Date().getFullYear()} - March ${new Date().getFullYear() + 1})`}</p>
              </div>
              <div className="flex justify-end ml-auto">
                {/* <div className="relative mx-2">
                  <DropDown
                    data={['School', 'Class and Section']}
                    width={'w-40'}
                    handleItemClick={(e) => {
                      setDropDown1(e);
                    }}
                  />
                </div>
                <div className="relative mx-2">
                  <DropDown
                    data={dropDown1 === 'School' ? ['NA'] : ['XII-A(Science)', 'XII-B(Commerce)']}
                    width={'w-40'}
                    disabled={dropDown1 === 'School'}
                    handleItemClick={() => {}}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="bg-white border rounded-2xl border-borderYellow m-6 ">
            <Calendar
              localizer={localizer}
              events={events}
              views={['month']}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 450 }}
              popup
              onSelectEvent={(e) => selectDay(e)}
              eventPropGetter={eventPropGetter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calender;
