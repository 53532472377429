import React, { useState, useEffect } from 'react';
import TeacherRequests from './TeacherRequests';
import Teachers from './Teachers';
import MeetingAcceptReject from '../../../components/Teacher/Meeting/MeetingAcceptReject';
import CreateRequest from '../../../components/Teacher/Meeting/CreateRequest';
import RemarkHistory from './RemarkHistory';
import ViewRemark from './ViewRemark';
import { STUDENT_SUBJECT } from '../../../api/urls/instituteManagement';
import { PARENT_TEACHER_MEETING, UPCOMING_PARENT_TEACHER_MEETING } from '../../../api/urls/appointment';
import { STUDENT_REMARKS } from '../../../api/urls/remarks';
import useApi from '../../../api/useApi';
import { useStateValue } from '../../../ContextApi/StateProvider';
import UpcomingParentMeetings from '../../../components/Teacher/Meeting/UpcomingParentMeetings';

function MeetingRemarks() {
  const [{ user }, _] = useStateValue();

  const [status, setStatus] = useState();
  const [openCreate, setOpenCreate] = useState();
  const [openRemark, setOpenRemark] = useState();

  const [subjects, getStudentSubjects] = useApi();
  const [meetings, getMeetings] = useApi();
  const [remarks, getRemarks] = useApi();
  const [upcomingParentMeetings, getUpcomingParentMeetings] = useApi();

  useEffect(() => {
    getMeetings(PARENT_TEACHER_MEETING, 'GET');
    getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');

    getStudentSubjects(STUDENT_SUBJECT, 'GET');
    if (user?.Responsbility[0]?.childInfo?._id)
      getRemarks(STUDENT_REMARKS(user?.Responsbility[0]?.childInfo?._id), 'GET');
  }, []);

  const handleCloseView = (reload) => {
    if (reload) {
      getMeetings(PARENT_TEACHER_MEETING, 'GET');
      getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');
    }
    setStatus(null);
  };

  const refresh = () => {
    getMeetings(PARENT_TEACHER_MEETING, 'GET');
    getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');
  };

  return (
    <div className="relative pb-10 w-full md:w-4/5">
      {status && <MeetingAcceptReject handleClose={handleCloseView} status={status} />}

      {openCreate && (
        <CreateRequest
          handleClose={() => {
            setOpenCreate(null);
          }}
          selectedStudent={openCreate}
        />
      )}

      {openRemark && (
        <ViewRemark
          handleClose={() => {
            setOpenRemark(null);
          }}
          remark={openRemark}
        />
      )}

      <TeacherRequests
        setStatus={setStatus}
        meetings={meetings}
        refresh={refresh}
        teachers={subjects?.data?.subjects || []}
      />
      <UpcomingParentMeetings meetings={upcomingParentMeetings} setStatus={setStatus} role={'Parent'} />
      <Teachers setOpenCreate={setOpenCreate} teachers={subjects?.data?.subjects || []} />
      <RemarkHistory setOpenRemark={setOpenRemark} remarks={remarks} />
    </div>
  );
}

export default MeetingRemarks;
