import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
// import { GET_ALL_LECTURES, CREATE_SUMMARY, UPDATE_SUMMARY, UPLOAD_NOTES, GET_SCHEDULE } from '../../../api/urls/notes';
import { Teach_Topic_POST } from "../../../api/urls/ai" ;
import axios from 'axios';
import LoadingPopup from '../../Principal/AcademicCalender/LoadingPopup';



const TechMePopup = ({ NotesDet , onClose }) => {
    const [TopicSummery, getSartTopicTeach] = useApi();

    const [formData, setFormData] = useState({UserQuestions:"" });
    const [InteractSummery , setInteractSummery ] = useState({ChatHistory:[], ChatOptions:[] });

    useEffect(() => {  // console.log(" -- NotesDet : ", NotesDet )
        let NotesId = NotesDet?._id ;
        getSartTopicTeach(Teach_Topic_POST,'POST',{Note_Id : NotesId,question:"2dfgdfgdfhcfvghdfgdfbvgdfgregdjayhwaehdfgaer" });
    }, []);
    
    useEffect(() => {  // console.log(" TopicSummery : ", TopicSummery )
        if( !TopicSummery?.isError && !TopicSummery?.isLoading && TopicSummery?.data?.noError){
            console.log("chat data : ", TopicSummery );
            let history = TopicSummery?.data?.ChatHistory? TopicSummery.data.ChatHistory : [] ;
            let options = TopicSummery?.data?.ChatOptions? TopicSummery.data.ChatOptions : [] ;
            // console.log("chat options : ", options );
            setInteractSummery({ChatHistory:history , ChatOptions:options }) // .reverse()
        } 
    },[TopicSummery]);

    const startChat = () => {  //  console.log(" --- startChat " ) ;
        let NotesId = NotesDet?._id ;
        if( InteractSummery.ChatHistory.length == 0 ){ 
            getSartTopicTeach(Teach_Topic_POST,'POST',{Note_Id : NotesId,question:'1dfgdfgdfhcfvghdfgdfbvgdfgregdjayhwaehdfgaer' });
        } else {
            if( formData.UserQuestions?.trim() != "" ){
                let questionAsk = formData.UserQuestions?.trim() ;   
                getSartTopicTeach(Teach_Topic_POST,'POST',{Note_Id : NotesId,question:questionAsk  });
            }
        } 
    }
    const FollowUpquestion = (questionMsg) => {  // console.log(" --- FollowUpquestion :  ", questionMsg  )
        if(questionMsg ){
            if( questionMsg.trim() != "" ){
                let NotesId = NotesDet?._id ;
                getSartTopicTeach(Teach_Topic_POST,'POST',{Note_Id : NotesId,question:questionMsg  });
            }
        }
    }

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    //
    // console.log(" -- NotesDet : ", NotesDet );
    // console.log(" -- InteractSummery : ", InteractSummery );  
    // console.log(" -- ChatHistory :  ", Chathistory_reversed );
    return (
        <div
        className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
        style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
        {TopicSummery.isLoading && <LoadingPopup text={'Generating...'} />}
        <div className="bg-white w-11/12 md:w-3/5 px-2 pb-6 text-sm block m-auto mt-20 rounded-2xl relative">
            <div className="border-b border-borderYellow flex justify-between items-center pr-8">
                <span className="block font-bold p-4"> Interactive Assistant to Teach You on Various Topics</span>
                { InteractSummery.ChatHistory.length == 0 ?  
                <div className="">
                    <a
                    className="border border-primary text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                    // href={window.location.origin + '/Syallbus.xlsx'}
                    onClick={startChat}
                    >
                    Start
                    </a>
                </div> : null } 
                <img
                    src="assets/img/Close.svg"
                    className="absolute top-5 right-3 h-3 cursor-pointer"
                    alt=""
                    onClick={() => onClose()}
                /> 
            </div>  
            { InteractSummery.ChatHistory.map((chatObj , idx) => ( 
                ( chatObj.role == "agent" ? // assistant
                    chatObj.text.split("\n").map( (chatobjInner , index1) => (
                        (chatobjInner.trim() == "" ? null : 
                        <div className="w-full pr-2 flex justify-start gap-x-4 mt-4"> 
                        <span className="text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex " >
                            {chatobjInner}
                        </span>
                        </div> )
                    ))
                :
                chatObj.text.split("\n").map( (chatobjInner , index1) => (
                    // (chatobjInner.trim() == "" ? null : 
                    <div className="w-full pr-2 flex justify-end gap-x-4 mt-4">
                    <span className="text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex " >
                        {chatobjInner}
                    </span>
                    </div> // )
                )) 
                )
              )) }

            { InteractSummery.ChatOptions.map((chatOpt , idx) => ( 
                <div className="w-full pr-2 flex justify-end gap-x-4 mt-4"> 
                    <span className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none text-white bg-primary opacity-100 cursor-pointer` }
                    onClick={()=>FollowUpquestion(chatOpt)} > 
                        {chatOpt}
                    </span>
                </div>
            ))}  
            { InteractSummery.ChatHistory.length == 0  ? <div className="mx-4 my-8 flex justify-start gap-x-6"> Please click on start button to start conversation </div> : 
            <>
            <div className="grid grid-cols-2">
                <div className=" px-6 py-4">   
                </div>
                <div className="py-4">
                    <div class="flex mt-3">
                    <div class="w-5/6  ">
                        <input
                            type="text"
                            className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
                            name="UserQuestions"
                            onChange={handleInputChange}
                        /> 
                    </div>
                    <div class="w-1/6  "> 
                        <a className="w-6 border border-primary text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                        // href={window.location.origin + '/Syallbus.xlsx'}
                        onClick={startChat}
                        >
                        Ask
                        </a>
                    </div>
                </div>
                    <div className="mt-3">
                    {/* <p className="text-xs mb-2">Ask your questions</p> */} 
                    </div>
                      
                </div>
            </div> 
            </>
            }
        </div> 
    </div>
    )
}

export default TechMePopup ;