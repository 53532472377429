import React, { useState, useEffect, useRef } from 'react';
import Instructions from './Instructions';
import Preview from './Preview';
import Questions from './Questions';
import useApi from '../../../../api/useApi';
import { SUBMIT_TEST } from '../../../../api/urls/test';
import { useStateValue } from '../../../../ContextApi/StateProvider';
import SubmitSuccess from './SubmitSuccess';

function OnlineTest({ selectedTest, startTest, refreshTests }) {
  const [showQuestions, setShowQuestions] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [attentedQues, setAttentedQues] = useState(0);
  const [currentQues, setCurrentQues] = useState(0);
  const [answers, setAnswers] = useState(new Array(selectedTest?.testDetail?.question?.length).fill({}));
  const [timeRemaining, setTimeRemaining] = useState('');

  const timer = useRef();
  const finalAnswers = useRef();

  const [submitRes, submitTest] = useApi();

  const [{ user }, _] = useStateValue();

  useEffect(() => {
    timer.current = setInterval(() => {
      getRemainingTime();
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const getRemainingTime = () => {
    let datetime = new Date(selectedTest?.testDetail.end);
    let now = new Date();

    let h1 = datetime.getHours();
    let h2 = now.getHours();
    let h = h1 - h2;

    let m1 = datetime.getMinutes();
    let m2 = now.getMinutes();
    let m = m1 - m2;
    if (m < 0) {
      h -= 1;
      m = 60 - m2 + m1;
    }

    let s1 = datetime.getSeconds();
    let s2 = now.getSeconds();
    let s = s1 - s2;
    if (s < 0) {
      m -= 1;
      s = 60 - s2 + s1;
    }

    if (h === 0 && m === 0 && s === 0) {
      clearInterval(timer.current);
    }

    setTimeRemaining(`${h} hr ${m} min ${s} sec`);
  };

  useEffect(() => {
    if (timeRemaining && timeRemaining === '0 hr 0 min 0 sec') {
      handleSubmit();
    }
  }, [timeRemaining]);

  const handleStartTest = () => {
    setShowQuestions(true);
    setShowInstructions(false);
  };

  const handleOpenHelp = () => {
    setShowQuestions(false);
    setShowInstructions(true);
  };

  const handleOpenPreview = () => {
    setShowQuestions(false);
    setShowPreview(true);
  };

  const handleBackToHome = () => {
    setShowQuestions(true);
    setShowPreview(false);
  };

  const handleGoToSelectedQues = (quesNo) => {
    setShowQuestions(true);
    setShowPreview(false);
    setCurrentQues(quesNo);
  };

  const handleDone = () => {
    startTest(false);
  };

  useEffect(() => {
    finalAnswers.current = answers;
  }, [answers]);

  const handleSubmit = () => {
    const body = {
      question: [...finalAnswers.current],
    };
    submitTest(SUBMIT_TEST(selectedTest?._id), 'POST', body);
  };

  useEffect(() => {
    const { isLoading, isError, status } = submitRes;
    if (!isLoading && !isError && [200, 201, 202, 204].includes(status)) {
      setShowQuestions(false);
      setShowPreview(false);
      setOpenSuccess(true);
      refreshTests();
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [submitRes]);

  return (
    <div
      className="flex justify-center  h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-3/5  text-sm m-auto rounded-2xl relative mt-20">
        {showInstructions && (
          <Instructions selectedTest={selectedTest} handleStartTest={handleStartTest} onClose={handleDone} />
        )}
        {showQuestions && (
          <Questions
            selectedTest={selectedTest}
            setAnswers={setAnswers}
            answers={answers}
            handleOpenHelp={handleOpenHelp}
            setAttentedQues={setAttentedQues}
            attentedQues={attentedQues}
            handleOpenPreview={handleOpenPreview}
            currentQues={currentQues}
            timeRemaining={timeRemaining}
          />
        )}
        {showPreview && (
          <Preview
            selectedTest={selectedTest}
            answers={answers}
            attentedQues={attentedQues}
            handleBackToHome={handleBackToHome}
            handleSubmit={handleSubmit}
            handleGoToSelectedQues={handleGoToSelectedQues}
            timeRemaining={timeRemaining}
          />
        )}
        {openSuccess && <SubmitSuccess selectedTest={selectedTest} handleDone={handleDone} />}
      </div>
    </div>
  );
}

export default OnlineTest;
