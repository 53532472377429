import React, { useState, useEffect } from 'react';

import useApi from '../../../api/useApi';
import { CLASS, ALL_SECTIONS, DELETE_CLASS, DELETE_SUBJECT } from '../../../api/urls/institute';

import AddClass from '../../../components/Owner/Academics/AddClass';
import AddSection from '../../../components/Owner/Academics/AddSection';
import AddSubject from '../../../components/Owner/Academics/AddSubject';
import DropDown from '../../../components/UI/DropDown';

const Academics = () => {
  const [addClass, setAddClass] = useState(false);
  const [addSubject, setAddSubject] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [tabName, setTabName] = useState('Add');
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [choice, setChoice] = useState('Subject');

  const [selectedClass, selectClass] = useState('');
  const [selectedSubject, selectSubject] = useState('');

  const [classes, setClasses] = useState([]);
  const [classSections, setClassSection] = useState([]);

  const [{ isLoading, isError, data }, getClasses] = useApi();
  const [sectionState, getClassSections] = useApi();
  const [deleteClassState, deleteClass] = useApi();
  const [deleteSubjectState, deleteSubject] = useApi();

  const [selectedDropDownClass, selectDropDownClass] = useState('');
  const [selectedDropDownSection, selectDropDownSection] = useState('');

  useEffect(() => {
    getClasses(CLASS, 'GET');
    getClassSections(ALL_SECTIONS, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setClasses(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!sectionState.isLoading && !sectionState.isError && sectionState.data) {
      setClassSection(sectionState.data);
    }
  }, [sectionState]);

  const handleDelete = () => {
    if (choice === 'Class') {
      deleteClass(DELETE_CLASS(selectedClass.classId), 'DELETE');
    } else {
      deleteSubject(DELETE_SUBJECT(selectedSubject.classID, selectedSubject._id), 'DELETE');
    }
  };

  useEffect(() => {
    if (!deleteClassState.isLoading && !deleteClassState.isError && deleteClassState.status === 202) {
      refreshList();
      setDeletePopUp(false);
    }
  }, [deleteClassState]);

  useEffect(() => {
    if (!deleteSubjectState.isLoading && !deleteSubjectState.isError && deleteSubjectState.status === 201) {
      refreshList();
      setDeletePopUp(false);
    }
  }, [deleteSubjectState]);

  const refreshList = () => {
    getClasses(CLASS, 'GET');
    getClassSections(ALL_SECTIONS, 'GET');
  };

  const handleCloseClass = () => {
    setAddClass(false);
    selectClass('');
    setTabName('');
  };

  const handleCloseSubject = () => {
    setAddSubject(false);
    selectClass('');
    setTabName('');
    selectSubject('');
  };

  const handleCloseSection = () => {
    setAddSection(false);
    setTabName('');
  };

  const filteredClasses = () => {
    if (!Array.isArray(classSections)) return [];
    let y = classSections;

    if (selectedDropDownClass) y = y.filter((x) => x.className === selectedDropDownClass);
    if (selectedDropDownSection) y = y.filter((x) => x.sectionName === selectedDropDownSection);

    return y;
  };

  return (
    <div className="w-full md:w-4/5">
      <div className="relative pb-10">
        {addClass && (
          <AddClass
            onClose={handleCloseClass}
            tabName={tabName}
            selectedClass={selectedClass}
            refreshList={refreshList}
          />
        )}
        {addSubject && (
          <AddSubject
            onClose={handleCloseSubject}
            tabName={tabName}
            selectedClass={selectedClass}
            selectedSubject={selectedSubject}
            refreshList={refreshList}
          />
        )}
        {addSection && (
          <AddSection classes={classes} onClose={handleCloseSection} tabName={tabName} refreshList={refreshList} />
        )}
        {deletePopUp && (
          <div
            className="flex justify-center  h-full w-full fixed left-0 top-0 z-30"
            style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
          >
            <div className="bg-white w-11/12 md:w-5/12 mb-auto mt-52 rounded-2xl relative">
              <span className="block font-semibold py-4 px-6 text-textBlue1 border-b border-borderYellow">
                Delete{' '}
                {choice === 'Class'
                  ? selectedClass.className + ' - ' + selectedClass.sectionName
                  : selectedSubject?.subjectName}
              </span>
              <img
                src="assets/img/Close.svg"
                className="absolute top-5 right-6 h-3 cursor-pointer"
                alt=""
                onClick={() => setDeletePopUp(false)}
              />
              <p className="text-xl text-center py-6">Are you sure you want to delete?</p>
              <button
                type="submit"
                className="mt-1 float-right mx-6 lg:mt-0 w-auto lg:w-max border my-4 px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        )}
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow mb-1">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Academics</p>
              </div>
              <div className="flex flex-wrap ml-auto gap-x-4 w-full justify-end">
                <button
                  type="submit"
                  className="border px-6 py-2 rounded-3xl bg-primary text-xs w-32 text-white  focus:outline-none flex justify-center text-center items-center"
                  onClick={() => {
                    setAddClass(true);
                    setTabName('Add');
                  }}
                >
                  Add Class
                </button>
                <button
                  type="submit"
                  className="border px-6 py-2 rounded-3xl bg-primary text-xs w-32 text-white  focus:outline-none flex justify-center text-center items-center"
                  onClick={() => {
                    setAddSection(true);
                    setTabName('Add');
                  }}
                >
                  Add Section
                </button>

                <DropDown
                  data={data && Array.isArray(data) ? data.map((x) => x.name) : []}
                  width={'w-32 md:w-1/5'}
                  title={'Select Class'}
                  handleItemClick={(x) => {
                    selectDropDownClass(x);
                  }}
                />

                <DropDown
                  data={
                    sectionState?.data
                      ? sectionState?.data &&
                        Array.isArray(sectionState?.data) &&
                        sectionState?.data
                          ?.filter((x) => x.className === selectedDropDownClass)
                          .map((x) => x.sectionName)
                      : []
                  }
                  width={'w-32 md:w-1/5'}
                  title={'Select Section'}
                  handleItemClick={(x) => {
                    selectDropDownSection(x);
                  }}
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 mx-auto mt-16" alt="" />
          ) : (
            filteredClasses().map((x, idx) => (
              <div
                key={x.sectionId}
                className={`${filteredClasses().length - 1 !== idx && 'border-b border-borderYellow'} mt-1`}
              >
                <div className="lg:flex block sm:block justify-between items-center px-6 pt-3 pb-1">
                  <div className="font-bold ">
                    <p>{x.className + ' - ' + x.sectionName}</p>
                  </div>
                  <div className="flex gap-x-4 ml-auto">
                    <button
                      type="submit"
                      className="border px-2 md:px-6 w-36 py-2 rounded-3xl text-xs font-normal text-tableRowText border-tableRowText  focus:outline-none flex justify-center text-center items-center"
                      onClick={() => {
                        setAddClass(true);
                        setTabName('Edit');
                        selectClass(x.className);
                      }}
                    >
                      Edit Class
                    </button>
                    <button
                      type="submit"
                      className="border px-2 md:px-6 w-36 py-2 rounded-3xl text-tableRowText border-tableRowText text-xs font-normal  focus:outline-none flex justify-center text-center items-center"
                      onClick={() => {
                        setDeletePopUp(true);
                        setChoice('Class');
                        selectClass(x);
                      }}
                    >
                      Delete Class
                    </button>
                    <button
                      type="submit"
                      className="border px-2 md:px-6 w-36 py-2 rounded-3xl bg-primary text-xs font-normal text-white  focus:outline-none flex justify-center text-center items-center"
                      onClick={() => {
                        setAddSubject(true);
                        setTabName('Add');
                        selectClass(x.classId);
                      }}
                    >
                      Add Subject
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap w-full h-1/4">
                  <div className="w-full pb-8 pt-3 overflow-auto">
                    <div
                      className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                      style={{ width: '96%' }}
                    >
                      <div className="w-full flex bg-tableHeader rounded-3xl">
                        <div className="flex w-full relative text-left px-4 text-xs font-medium">
                          <div className="w-4/12 border-r-2 py-2 px-2">Subject Code</div>
                          <div className="w-4/12 border-r-2 py-2 px-2">Subject Name</div>
                          {/* <div className="w-5/12 border-r-2 py-2 px-2">Subject Teacher</div> */}
                          <div className="w-4/12 py-2 px-2">Action</div>
                        </div>
                      </div>
                      <div className="customScrollBar" style={{ maxHeight: '38vh' }}>
                        {x.classSubject.map((y, idx) => (
                          <div className="w-full" key={y._id}>
                            <div
                              className={`justify-center items-center flex w-full relative text-left py-3 px-4 text-xs font-medium ${
                                x.classSubject.length - 1 !== idx && 'border-b border-borderYellow'
                              }`}
                            >
                              <div className="w-4/12 px-2">{y.subjectCode}</div>
                              <div className="w-4/12 px-2 text-tableRowText">{y.subjectName}</div>
                              {/* <div className="w-5/12 px-2 text-tableRowText">{y?.teacherId?.full_name}</div> */}
                              <div className="w-4/12 flex items-center px-2">
                                <button
                                  className="w-full lg:w-max border border-tableRowText px-8 py-1.5 text-tableRowText mr-4 rounded-3xl text-xs focus:outline-none"
                                  onClick={() => {
                                    setAddSubject(true);
                                    setTabName('Edit');
                                    selectSubject(y);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="w-full lg:w-max border border-tableRowText px-8 py-1.5 text-tableRowText rounded-3xl text-xs focus:outline-none"
                                  onClick={() => {
                                    setDeletePopUp(true);
                                    setChoice('Subject');
                                    selectSubject(y);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default Academics;
