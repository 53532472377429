import React, { useState, useEffect } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { UPDATE_CALANDER_WORKINGDAY } from '../../../api/urls/institute';
import LoadingPopup from './LoadingPopup';
import { getRandomNumber, getExamName } from '../../../utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

var converter = require('number-to-words');

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ExamQuizMapping = ({ moveNext, movePrevious, calanderId, setOptions }) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const [items, setItems] = useState([]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems([...reorderedItems]);
  };

  // Determine the year for March based on the current month
  const marchYear = currentMonth >= 2 ? currentYear + 1 : currentYear;

  const handleNavigate = () => {
    // Disable navigation
  };

  const [selectedDate, setSelectedDate] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const [saveRes, saveHolidatList] = useApi();

  const dataModel = {
    noOfUnitTest: '',
    unitTestDayLimit: '',
    unitrevisionDay: '',
    unitrevisionDaylimit: '',
    noTermTestLimit: '',
    termTestDayLimit: '',
    termrevisionDay: '',
    termrevisionDaylimit: '',
    examSchedule: '',
    lastExamDate: '',
  };

  const [formData, setFormData] = useState(dataModel);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (formData.noOfUnitTest && formData.noTermTestLimit) {
      const unitBoxes = Array.from({ length: formData.noOfUnitTest }, (_, index) => `u${index + 1}`);

      const termBoxes = Array.from({ length: formData.noTermTestLimit }, (_, index) => `t${index + 1}`);
      setItems([...unitBoxes, ...termBoxes]);
    }
  }, [formData.noOfUnitTest, formData.noTermTestLimit]);

  const handleDateSelect = (slotInfo) => {
    setSelectedDate([
      {
        title: 'Last Exam Day',
        start: new Date(slotInfo.start),
        end: new Date(slotInfo.start),
      },
    ]);
    console.log(moment(slotInfo.start).format('YYYY-MM-DD'));
    setFormData({ ...formData, lastExamDate: moment(slotInfo.start).format('YYYY-MM-DD') });
  };

  const handleSave = () => {
    if (!window.confirm('Are you sure you want to save?')) return;
    if (!formData.lastExamDate) {
      alert('Please select last exam date');
      return;
    }

    if (!formData.noOfUnitTest) {
      alert('Please add Number of Unit Tests');
      return;
    }

    if (!formData.noTermTestLimit) {
      alert('Please add Number of Term Tests');
      return;
    }

    if (!formData.unitTestDayLimit) {
      alert('Please add Number of days for Unit Tests');
      return;
    }

    if (!formData.termTestDayLimit) {
      alert('Please add Number of days for Term Tests');
      return;
    }
    const body = {
      ...formData,
      unitrevisionDay: formData.unitrevisionDay === 'Yes' ? true : false,
      termrevisionDay: formData.termrevisionDay === 'Yes' ? true : false,
      examSequence: items,
    };
    setOpenLoader(true);
    saveHolidatList(UPDATE_CALANDER_WORKINGDAY(calanderId), 'PUT', body);
  };

  useEffect(() => {
    if (saveRes?.status === 200) {
      setTimeout(() => {
        moveNext();
        setOptions(saveRes.data);
        setOpenLoader(false);
      }, getRandomNumber(3, 6, false) * 1000);
    }

    if (saveRes.isError) {
      setOpenLoader(false);
      alert('Error saving');
    }
  }, [saveRes]);

  return (
    <div className="pb-8">
      {openLoader && <LoadingPopup text={'Generating Options...'} />}
      <div className="border-b border-borderYellow grid grid-cols-2">
        <div className="border-r border-borderYellow px-6 py-4">
          <div className="flex justify-between items-center">
            <p className="text-sm mb-0.5 text-black font-medium">Unit Test</p>
            <div className="w-8 h-3 rounded-full unitExamColor"></div>
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many unit tests do you want to take?</p>
            <input
              type="text"
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              name="noOfUnitTest"
              onChange={handleInputChange}
            />
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many days do you want for unit tests?</p>
            <input
              type="text"
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              name="unitTestDayLimit"
              onChange={handleInputChange}
            />
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">Do you want revision days before Unit Exam?</p>
            <select
              type="text"
              name="unitrevisionDay"
              onChange={handleInputChange}
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
            >
              <option value={''}>Select</option>
              <option value={'Yes'}>Yes</option>
              <option value={'No'}>No</option>
            </select>
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many days do you want for revision days?</p>
            <input
              type="text"
              disabled={formData.unitrevisionDay === 'No'}
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              name="unitrevisionDaylimit"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="px-6 py-4">
          <div className="flex justify-between items-center">
            <p className="text-sm mb-0.5 text-black font-medium">Term Test</p>
            <div className="w-8 h-3 rounded-full termExamColor"></div>
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many Term tests do you want to take?</p>
            <input
              type="text"
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              name="noTermTestLimit"
              onChange={handleInputChange}
            />
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many days do you want for Term tests?</p>
            <input
              type="text"
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              name="termTestDayLimit"
              onChange={handleInputChange}
            />
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">Do you want revision days before Term Exam?</p>
            <select
              type="text"
              name="termrevisionDay"
              onChange={handleInputChange}
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
            >
              <option value={null}>Select</option>
              <option value={'Yes'}>Yes</option>
              <option value={'No'}>No</option>
            </select>
          </div>

          <div className="mt-3">
            <p className="text-xs mb-2">How many days do you want for revision days?</p>
            <input
              type="text"
              className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
              disabled={formData.termrevisionDay === 'No'}
              name="termrevisionDaylimit"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="border-b border-borderYellow px-6 py-4">
        <div className="flex justify-between items-center">
          <p className="text-sm mb-0.5 text-black font-medium">Test Sequence</p>
          <p className="text-xxs text-textLight">(Drag and Drop the components to change the sequence)</p>
        </div>

        <div className="mt-10">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-container" direction="horizontal">
              {(provided) => (
                <div className="flex justify-around w-full mb-4" {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided) => (
                        <div
                          className={`${
                            item.includes('u') ? 'unitExamColor' : 'termExamColor'
                          } text-xxs text-white px-2 py-1 rounded-full`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {getExamName(item)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="w-full rounded-full h-2 bg-gray-300"></div>
          <div className="flex justify-between text-xs text-textLight mt-1">
            <p>April</p> <p>March</p>
          </div>
        </div>
      </div>

      <div className="px-6 py-4">
        <div className="flex justify-between items-center">
          <p className="text-sm mb-0.5 text-black font-medium">Last day of Last Term</p>
        </div>

        <div className="mt-10 flex">
          <div className="w-1/4 flex flex-col gap-10">
            <span className="text-xs">Select last date of Last Term Exam</span>
            <div className="flex flex-col gap-1">
              <span className="text-3xl">
                {formData?.lastExamDate ? moment(formData?.lastExamDate).format('D') : ''}
              </span>
              <span className="text-xs">
                {formData?.lastExamDate ? converter.toWords(moment(formData?.lastExamDate).format('D')) : ''}
              </span>
            </div>
          </div>
          <div className="w-3/4 pl-10">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow w-full">
              <Calendar
                localizer={localizer}
                events={selectedDate}
                style={{ height: 300 }}
                views={['month']}
                onSelectSlot={handleDateSelect}
                selectable="ignoreEvents"
                date={new Date(marchYear, 2, 1)} // March is 2nd month in Date object (0-indexed)
                onNavigate={handleNavigate}
                components={{
                  toolbar: CustomToolbar, // Use the custom toolbar
                }}
                // eventPropGetter={eventPropGetter}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center mt-6 px-6">
        {/* <button className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none">
          Reset
        </button> */}

        <div className="flex gap-x-4">
          {/* <button
            className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none"
            onClick={movePrevious}
          >
            Back
          </button> */}
          <button
            className="w-28 py-2 text-xs rounded-3xl bg-bgBlue border border-bgBlue font-medium text-white focus:outline-none"
            onClick={handleSave}
          >
            Save
          </button>
          {/* <button
            className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none"
            onClick={moveNext}
          >
            Next
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ExamQuizMapping;

const CustomToolbar = ({ date, view, onNavigate }) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{moment(date).format('MMMM')}</span>
    </div>
  );
};
