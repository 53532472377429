import React, { useState, useEffect, useCallback } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { TEACHER_TIMETABLE, EVENT } from '../../../api/urls/timeTable';
import moment from 'moment';
import { MEETING, UPCOMING_PARENT_TEACHER_MEETING } from '../../../api/urls/appointment';

function EventsTimetable({ role }) {
  const [events, setEvents] = useState([]);
  const [timetable, setTimeTable] = useState([]);
  const [current, setCurrent] = useState(0);
  const [selectedDay, selectDay] = useState();

  const [TeacherTimetable, getTeacherTimetable] = useApi();
  const [{ isLoading, isError, data }, getEvents] = useApi();
  const [upcomingParentMeetings, getUpcomingParentMeetings] = useApi();
  const [meetingsRes, getMeetings] = useApi();

  useEffect(() => {
    getEvents(EVENT, 'GET');
    getTeacherTimetable(TEACHER_TIMETABLE, 'GET');
    getMeetings(MEETING, 'GET');
    getUpcomingParentMeetings(UPCOMING_PARENT_TEACHER_MEETING, 'GET');
  }, []);

  useEffect(() => {
    if (!TeacherTimetable.isLoading && !TeacherTimetable.isError && TeacherTimetable.data) {
      let res = [];
      if (Array.isArray(TeacherTimetable.data?.teacherTimeTable)) {
        res = TeacherTimetable.data?.teacherTimeTable.reduce((result, item) => {
          if (!result[item.startTime]) {
            result[item.startTime] = [];
          }
          result[item.startTime].push(item);
          return result;
        }, {});
      }
      setTimeTable(res);
    }
  }, [TeacherTimetable]);

  useEffect(() => {   // console.log(" event data : ", data );
    if (!isLoading && !isError && data) {
      if (Array.isArray(data) && data.length > 0) {
        const x = data.map((x) => {
          return {
            title: x.title,
            start: new Date(x.eventDate),
            end: new Date(x.eventDate),
            type: 'EVENT',
            description: x.description,
            startTime: moment(x.start).format('hh:mm a') || '---',
            endTime: moment(x.end).format('hh:mm a') || '---',
            files : x.files ,
          };
        });
        setEvents(x) ; // ([...events, ...x]);
      }
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!upcomingParentMeetings.isLoading && !upcomingParentMeetings.isError && upcomingParentMeetings.data) {
      if (Array.isArray(upcomingParentMeetings.data) && upcomingParentMeetings.data.length > 0) {
        const x = upcomingParentMeetings.data
          .filter((c) => c.meeting?.status === 'Approved')
          .map((x) => {
            return {
              title: 'PTM',
              start: new Date(x?.meeting?.date),
              end: new Date(x?.meeting?.date),
              type: 'MEETING',
              description: x?.reason,
              attendees: x?.meeting?.child_id?.full_name,
            };
          });
        setEvents((prev) => [...prev, ...x]);
      }
    }
  }, [upcomingParentMeetings.isLoading, upcomingParentMeetings.isError, upcomingParentMeetings.data]);

  useEffect(() => {
    if (!meetingsRes.isLoading && !meetingsRes.isError && meetingsRes.data) {
      if (Array.isArray(meetingsRes.data) && meetingsRes.data.length > 0) {
        const x = meetingsRes.data.map((x) => {
          return {
            title: 'MEETING',
            start: new Date(x.date),
            end: new Date(x.date),
            type: 'MEETING',
            description: x?.reason,
            attendees: x?.meeting_with?.map((c) => c.full_name).join(', '),
          };
        });
        setEvents((prev) => [...prev, ...x]);
      }
    }
  }, [meetingsRes.isLoading, meetingsRes.isError, meetingsRes.data]);

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(!(event.title.includes('PTM') || event.title.includes('MEETING')) && {
        className: 'eventColor',
      }),
    }),
    []
  );

  const getPreviousDay = (noOfDays = 0) => {
    const date = new Date();
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + noOfDays);
    const month = previous.toLocaleString('default', { month: 'long' });
    const day = previous.toLocaleString('default', { weekday: 'long' });
    return `${previous.getDate()} ${month} ${previous.getFullYear()} (${day})`;
  };

  const getSubject = (item, x) => {
    if (!item) return;
    const day = x.split('(')[1].split(')')[0];
    if (day === 'Sunday') return '--';
    const selected = item.find((c) => c.day.includes(day));
    return selected ? `${selected.class}-${selected.section} (${selected.subjectName})` : 'No Class';
  };
  // console.log(" -- selectedDay : ", selectedDay );
  return (
    <div className="w-full md:w-4/5">
      {selectedDay && (
        <div
          className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 mb-auto mt-16 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 border-b border-borderYellow">{`${moment(
              selectedDay.start
            ).format('DD MMM YYYY')}`}</span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectDay(null)}
            />
            <div className="mt-4 mb-4 mx-6 ">
              <div className="grid grid-cols-2">
                {selectedDay?.title !== 'PTM' && (
                  <div className="mt-2 mb-4 relative font-semibold ">
                    <p className="text-black text-sm font-bold mb-0.5">Start Time check</p>
                    <p className="text-tableRowText text-sm">
                      {selectedDay?.type === 'EVENT'
                        ? selectedDay?.startTime
                        : moment(selectedDay.start).format('hh:mm a')}
                    </p>
                  </div>
                )}

                {selectedDay?.type === 'EVENT' && (
                  <div className="mt-2 mb-4 relative font-semibold ">
                    <p className="text-black text-sm font-bold mb-0.5">End Time</p>
                    <p className="text-tableRowText text-sm">{selectedDay?.endTime}</p>
                  </div>
                )}
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">Title</p>
                <p className="text-tableRowText text-sm">{selectedDay?.title}</p>
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">
                  {selectedDay?.type === 'MEETING' ? 'Agenda' : 'Description'}
                </p>
                <p className="text-tableRowText text-sm">{selectedDay?.description}</p>
              </div>

              {selectedDay?.type !== 'EVENT' && (
                <div className="mt-2 mb-4 relative font-semibold ">
                  <p className="text-black text-sm font-bold mb-0.5">
                    {selectedDay?.title === 'PTM' ? 'Student' : 'Members'}
                  </p>
                  <p className="text-tableRowText text-sm">{selectedDay?.attendees}</p>
                </div>
              )}
              <div className="my-2 relative font-semibold w-full md:w-7/12">
                <label className="block text-parameter text-sm text-textDark">Attachment</label>
                <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                  <p className="py-2 text-tableRowText px-2 truncate">
                    {selectedDay.files?.length > 0 ? selectedDay.files[0].name : ''}
                  </p>
                  <a
                    href={selectedDay?.files?.length > 0 && selectedDay.files[0].location}
                    download
                    className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                  >
                    Download
                  </a>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" bg-white rounded-xl h-auto mb-5 w-full overflow-auto">
        <div className="items-center px-6 py-3.5 border-b border-borderYellow">
          <p className="font-bold text-base">Timetable</p>
        </div>
        <div className="flex gap-x-3 w-full px-5">
          <div className="w-1/5  py-4 mt-11" style={{ minWidth: 150 }}>
            <div className="bg-white m-auto border border-borderYellow rounded-2xl w-full overflow-hidden">
              <div className="w-full  bg-tableHeader ">
                <p className="py-2 px-4 text-xs text-black font-medium">Timings</p>
              </div>
              <div className="overflow-auto">
                {timetable &&
                  Object.values(timetable).map((item, idx) => (
                    <div
                      key={item}
                      className={`"w-full py-2 ${
                        idx < Object.keys(timetable).length && 'border-b border-borderYellow'
                      }"`}
                    >
                      <div className="flex w-full relative text-left py-3 pl-4 text-xs font-medium items-center">
                        <div className={`${'text-tableRowText '}`}>{`${moment(item[0].startTime, ['h:mm']).format(
                          'hh:mm A'
                        )} - ${moment(item[0].endTime, ['h:mm']).format('hh:mm A')}`}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-4/5  py-4" style={{ minWidth: 600 }}>
            <div className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden flex flex-col">
              <div className="h-11 w-full flex justify-between px-6 items-center">
                <button style={{ outline: 'none' }} onClick={() => setCurrent((prev) => prev - 1)}>
                  <img src="assets/img/previous.svg" alt="previous" />
                </button>

                <button style={{ outline: 'none' }} onClick={() => setCurrent((prev) => prev + 1)}>
                  <img src="assets/img/next.svg" alt="next" />
                </button>
              </div>
              <div className="w-full flex">
                {[
                  getPreviousDay(current),
                  getPreviousDay(current + 1),
                  getPreviousDay(current + 2),
                  getPreviousDay(current + 3),
                ].map((x) => (
                  <div className=" w-1/3" key={x}>
                    <div className="w-full  bg-tableHeader border-r">
                      <p className="py-2 pl-4 text-xs text-black font-medium">{x}</p>
                    </div>
                    <div className="overflow-auto">
                      {Object.values(timetable).map((item, idx) => (
                        <div
                          key={idx}
                          className={`w-full py-2 border-r ${
                            idx < Object.values(timetable).length && 'border-b border-borderYellow'
                          }"`}
                        >
                          <div className="flex w-full relative text-left py-3 pl-4 text-xs font-medium items-center">
                            <div className={`text-tableRowText`}>{getSubject(item, x)}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <p className="font-bold text-base">Events</p>
        </div>
        <div className="flex flex-wrap w-full ">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl" style={{ borderColor: '#E7E700', width: '96%' }}>
              <Calendar
                localizer={localizer}
                events={events}
                views={['month']}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 450 }}
                popup
                onSelectEvent={(e) => selectDay(e)}
                eventPropGetter={eventPropGetter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsTimetable;
