import React, { useState } from 'react';
import DropDown from '../../UI/DropDown';
import Search from '../../UI/Search';
import moment from 'moment';

export default function TeachingStaff({
  data,
  setSearch,
  setCreateStaff,
  setSearchName,
  selectStaff,
  setStaffRole,
  setSearchedData,
  isLoading = false,
}) {
  const [sort, setSort] = useState('');

  const handleSearch = (q) => {
    if (data) {
      let x = data.filter(
        (x) => x.full_name.toLowerCase().includes(q.toLowerCase()) || x.username.toLowerCase().includes(q.toLowerCase())
      );
      setSearchedData(x);
      setSearch(true);
      setSearchName(q);
    }
  };

  let filteredData = data;

  if (sort) {
    if (sort === 'Name - a to z') {
      filteredData = data.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name - z to a') {
      filteredData = data.sort((a, b) => (a.full_name.toLowerCase() < b.full_name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Joining-Recent') {
      filteredData = data.sort((a, b) =>
        a.jobProfile.length > 0 &&
        b.jobProfile.length > 0 &&
        moment(a.jobProfile[a.jobProfile.length - 1].startDate).isBefore(
          moment(b.jobProfile[b.jobProfile.length - 1].startDate)
        )
          ? 1
          : -1
      );
    }
    if (sort === 'Joining-Oldest') {
      filteredData = data.sort((a, b) =>
        a.jobProfile.length > 0 &&
        b.jobProfile.length > 0 &&
        moment(a.jobProfile[a.jobProfile.length - 1].startDate).isAfter(
          moment(b.jobProfile[b.jobProfile.length - 1].startDate)
        )
          ? 1
          : -1
      );
    }
    if (sort === 'Salary-high to low') {
      filteredData = data.sort((a, b) =>
        +a.jobProfile[a.jobProfile.length - 1].salary > +b.jobProfile[b.jobProfile.length - 1].salary ? 1 : -1
      );
    }
    if (sort === 'Salary-low to high') {
      filteredData = data.sort((a, b) =>
        +a.jobProfile[a.jobProfile.length - 1].salary < +b.jobProfile[b.jobProfile.length - 1].salary ? 1 : -1
      );
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold ">
            <p>Teaching Staff</p>
          </div>
          <div className="flex">
            <button
              className="w-36 border py-2 rounded-3xl bg-primary text-xs  text-white mr-4 focus:outline-none flex justify-center text-center items-center"
              onClick={() => {
                setCreateStaff(true);
                setStaffRole('teaching');
              }}
            >
              Add Staff
            </button>
            <DropDown
              data={[
                'Salary-low to high',
                'Salary-high to low',
                'Name - a to z',
                'Name - z to a',
                'Joining-Recent',
                'Joining-Oldest',
              ]}
              width={'w-40'}
              margin={'mr-4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />

            <Search placeholder={'Search staff'} width={'w-44'} onChange={(x) => {}} onEnter={handleSearch} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full">
        <div className="w-full py-4 px-6 overflow-auto">
          <div className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative px-2 text-xs font-medium">
                <div className="w-2/12 border-r-2 p-2">Photograph</div>
                <div className="w-3/12 border-r-2 p-2">ROLEDin ID</div>
                <div className="w-3/12 border-r-2 p-2">Name</div>
                <div className="w-3/12 border-r-2 p-2">Designation</div>
                <div className="w-3/12 border-r-2 p-2">Joining Date</div>
                <div className="w-1/4 p-2">Action</div>
              </div>
            </div>
            <div
              className="customScrollBar"
              style={{
                overflowY: 'scroll',
                maxHeight: '35vh',
              }}
            >
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-4">
                        {x.fileArray.length > 0 && x.fileArray[0].profileImg && x.fileArray[0].profileImg.location ? (
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src={x.fileArray[0].profileImg.location}
                            alt=""
                          />
                        ) : (
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src={'/assets/img/user_placeholder.svg'}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="w-3/12 text-tableRowText px-2">{x.username}</div>
                      <div className="w-3/12  px-2">{x.full_name}</div>
                      <div className="w-3/12 text-tableRowText px-2">
                        {x.jobProfile && x.jobProfile.length > 0 && x.jobProfile[x.jobProfile.length - 1]?.title}
                      </div>
                      <div className="w-3/12 text-tableRowText px-2">
                        {x.jobProfile && x.jobProfile.length > 0 && x.jobProfile[x.jobProfile.length - 1].startDate
                          ? moment(x.jobProfile[x.jobProfile.length - 1].startDate).format('ll')
                          : ''}
                      </div>
                      <div className="w-1/4 flex justify-center items-center px-2">
                        <button
                          className="w-4/5  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                          onClick={() => {
                            selectStaff(x);
                          }}
                        >
                          Show More
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
