import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { GET_CLASS_PENDING_FEE, STUDENT_FEE } from '../../../api/urls/finance';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import Search from '../../UI/Search';
import DropDown from '../../UI/DropDown';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'roledinId' },
  { label: 'Name', key: 'name' },
  { label: 'Father’s Name', key: 'fatherName' },
  { label: 'Pending Fee', key: 'pendingFee' },
];

function FeeRecord({ combinedClassSection }) {
  const [feeRecordData, setFeeRecord] = useState();
  const [selectedStudent, selectStudent] = useState();
  const [studentFee, setStudentFee] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [search, setSearch] = useState('');

  const [feeRecordRes, getFeeRecord] = useApi();
  const [studentFeeRes, getStudentFee] = useApi();

  const getData = (classId, sectionId) => {
    getFeeRecord(GET_CLASS_PENDING_FEE(classId, sectionId), 'GET');
  };

  useEffect(() => {
    if (!feeRecordRes.isLoading && !feeRecordRes.isError && feeRecordRes.data) {
      setFeeRecord(feeRecordRes.data);

      if (feeRecordRes.data?.fees) {
        let _excel = feeRecordRes.data?.fees.map((x) => {
          return {
            roledinId: x?.userName,
            name: x?.name,
            fatherName: x?.father.father_name,
            pendingFee: x.pendingFee,
          };
        });

        if (Array.isArray(_excel)) setExcelData(_excel);
      }
    }
  }, [feeRecordRes]);

  useEffect(() => {
    if (selectedStudent) {
      getStudentFee(STUDENT_FEE(selectedStudent.student_id), 'GET');
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (!studentFeeRes.isLoading && !studentFeeRes.isError && studentFeeRes.data) {
      setStudentFee(studentFeeRes.data);
    }
  }, [studentFeeRes]);

  let _studentList = feeRecordData || [];

  if (search) {
    _studentList = _studentList.filter((x) => x?.name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <>
      {selectedStudent && (
        <div
          className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-10/12 text-sm block m-auto mt-10 rounded-2xl relative pb-6">
            <div
              className="flex w-full justify-between items-center relative"
              style={{ borderBottom: '0.71px solid #E7E700' }}
            >
              <p className="block font-bold py-4 px-6 text-black">{studentFee?.response?.userDetail?.userName}</p>
              <button
                className="border px-8 py-2 mr-16 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={() => {}}
              >
                Export Data
              </button>
              {/* </div> */}
              <img
                src="assets/img/Close.svg"
                className="absolute right-4 h-3 cursor-pointer z-50"
                alt=""
                onClick={() => selectStudent(null)}
              />
            </div>
            <div style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className=" pt-4 px-6 font-bold text-black">Student Details</p>
              <div className="flex flex-wrap px-6 py-2">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">Name</label>
                  <p className="w-full text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.userName}
                  </p>
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">ROLEDin ID</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.roledId}
                  </p>
                </div>
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">Class</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.class}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className=" pt-4 px-6 font-bold">Discount Details</p>
              <div className="flex flex-wrap px-6 py-2">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-sm text-textDark font-medium">Discount Type</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.discount?.type || '-'}
                  </p>
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark font-medium flex justify-between items-center">
                    Discount
                  </label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.discount?.discount || '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="px-6 font-bold py-4">Fee Payment History</p>
              <div className=" flex w-full overflow-auto">
                <div
                  className="m-auto border rounded-2xl"
                  style={{ borderColor: '#E7E700', width: '96%', minWidth: 500 }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-4 py-1">
                      {/* <div className="w-2/12 border-r-2 py-1 px-2">Quarter</div> */}
                      <div className="w-1/12 border-r-2 py-1 px-2">Month</div>
                      <div className="w-2/12 border-r-2 py-1 px-2">Mode Of Payment</div>
                      <div className="w-3/12 border-r-2 py-1 px-2">Transaction ID</div>
                      <div className="w-1/12 border-r-2 py-1 px-2">Actual Fee</div>
                      <div className="w-1/12 border-r-2 py-1 px-2">Fee Paid</div>
                      <div className="w-1/12 border-r-2 py-1 px-2">Balance</div>
                      <div className="w-1/12 border-r-2 py-1 px-2">Extra Paid</div>
                      <div className="w-2/12 py-1 px-2">Fee waived off</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '30vh' }}>
                    {studentFeeRes?.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 mx-auto mr-4" alt="" />
                    ) : (
                      studentFee &&
                      Array.isArray(studentFee?.response?.paymentHistory) &&
                      studentFee?.response?.paymentHistory.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-3.5 px-4 text-xs font-medium ${
                              studentFee?.response?.paymentHistory.length - 1 !== idx && 'border-b border-tableHeader'
                            }`}
                          >
                            {/* <div className="w-2/12 px-2 text-tableRowText">{'-'}</div> */}
                            <div className="w-1/12 px-2 text-tableRowText">{x?.month}</div>
                            <div className="w-2/12 px-2 text-tableRowText">{x?.mode}</div>
                            <div className="w-3/12 px-2 text-tableRowText truncate">{x?.receiptNo}</div>
                            <div className="w-1/12 px-2 text-tableRowText pl-4">&#x20b9; {x?.totalAmount || '-'}</div>
                            <div className="w-1/12 px-2 text-tableRowText pl-4">&#x20b9; {x?.amount}</div>
                            <div className="w-1/12 px-2 text-tableRowText pl-4">&#x20b9; {x?.balance}</div>
                            <div className="w-1/12 px-2 text-tableRowText pl-4">
                              &#x20b9; {x?.balanceHistory?.extraPayment || 0}
                            </div>
                            <div className="w-2/12 px-2 text-tableRowText pl-4">
                              &#x20b9; {x?.waiveoff.toFixed(2) || 0}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-end"> */}

            {/* </div> */}
          </div>
        </div>
      )}
      <div className="w-full bg-white rounded-xl h-auto mt-4">
        <div className="items-center px-6 py-3 border-b border-tableHeader">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold text-base w-1/4">
              <p>Fee Record</p>
            </div>
            <div className="flex justify-end w-full gap-x-3">
              <ExcelExportBtn
                data={excelData}
                headers={excelHeaders}
                fileName={'fee_record.csv'}
                text={'Export Data'}
                width={'w-36'}
              />
              <Search
                placeholder={'Search'}
                width={'w-36'}
                onChange={(x) => setSearch(x)}
                onEnter={() => console.log('enter')}
              />
              <DropDown
                field={'name'}
                handleItemClick={(x) => {
                  getData(x.classId, x.sectionId);
                }}
                title={'Select Class'}
                data={combinedClassSection}
                width={'w-40'}
                // otherClasses={'border-borderLight'}
                type={'obj'}
              />
            </div>
          </div>
        </div>
        {feeRecordRes.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
        ) : feeRecordData ? (
          _studentList.length > 0 ? (
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl min-w-700"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-2">
                      <div className="w-1/5 border-r-2 py-2 px-2">ROLEDin ID</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Name</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Father’s Name</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Pending Fee</div>
                      <div className="w-1/5 py-2 px-2">View Details</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {_studentList &&
                      _studentList.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`items-center flex w-full relative py-2 px-3 text-xs font-medium ${
                              _studentList.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/5 px-2">{x?.userName}</div>
                            <div className="w-1/5 px-2">{x?.name}</div>
                            <div className="w-1/5 px-3">{x?.father?.father_name}</div>
                            <div className={`w-1/5 px-3 text-textDark`}>&#x20b9; {x?.pendingFee}</div>
                            <div className="w-1/5 px-2">
                              <button
                                className="w-10/12 border border-tableRowText text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  selectStudent(x);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">No Data Available</p>
          )
        ) : (
          <p className="text-center text-lg mt-10 pb-10">Please select class from dropdown</p>
        )}
      </div>
    </>
  );
}

export default FeeRecord;
