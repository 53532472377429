import moment from 'moment';
import React from 'react';

function ViewRemark({ handleClose, remark }) {
  return (
    <div
      className="flex justify-center md:items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/5 text-sm block mx-auto mb-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow">
          <span className="block font-bold p-4 px-6">{`Remarks - ${moment(remark?.date).format('ll')}`}</span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-6 h-3 cursor-pointer"
            alt=""
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div
          // onSubmit={handleSubmit}
          className="mt-2 mb-2 grid grid-cols-2 gap-x-4 px-6"
          autoComplete="off"
        >
          <div className="mt-2 w-full relative font-semibold">
            <label className="block text-sm">Teacher Name</label>
            <p className="w-full text-textLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
              {remark?.teacher_id?.full_name}
            </p>
          </div>

          <div className="mt-2 w-full relative font-semibold">
            <label className="block text-sm">Category</label>
            <p className="w-full text-textLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
              {remark?.category}
            </p>
          </div>

          <div className="mt-2 mb-2 w-full relative font-semibold col-span-2">
            <label className="block text-sm">Remarks</label>
            <p className="w-full text-textLight rounded-2xl border h-28 px-4 mt-2 py-2 text-xs focus:outline-none">
              {remark?.remarks}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRemark;
