import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { MY_CREATED_TASKS, ASSIGNED_TASKS, UPDATE_TASK } from '../../../api/urls/appointment';
import { GET_NON_TEACHING_STAFF, GET_TEACHING_STAFF } from '../../../api/urls/user';
import DropDown from '../../../components/UI/DropDown';
import CreateTask from '../../../components/Owner/Task/CreateTask';

const TaskBoard = () => {
  const [teachingStaffRes, getTeachingStaff] = useApi();
  const [nonTeachingRes, getNonTeachingStaff] = useApi();
  const [{ isLoading, isError, data }, getTasks] = useApi();
  const [assignedTasks, getAssignedTasks] = useApi();
  const [updateRes, updateTask] = useApi();

  const [open, setOpen] = useState(false);
  const [teachingStaff, setTeachingStaff] = useState([]);
  const [nonTeachingStaff, setNonTeachingStaff] = useState([]);

  const [taskAssignedFilter, setTaskAssignedFilter] = useState('');
  const [myTaskFilter, setMyTaskFilter] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getTasks(MY_CREATED_TASKS, 'GET');
    getAssignedTasks(ASSIGNED_TASKS, 'GET');
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
    getNonTeachingStaff(GET_NON_TEACHING_STAFF, 'GET');
  }, []);

  useEffect(() => {
    if (!teachingStaffRes.isLoading && !teachingStaffRes.isError && teachingStaffRes.data) {
      setTeachingStaff(teachingStaffRes.data);
    }
  }, [teachingStaffRes]);

  useEffect(() => {
    if (!nonTeachingRes.isLoading && !nonTeachingRes.isError && nonTeachingRes.data) {
      setNonTeachingStaff(nonTeachingRes.data);
    }
  }, [nonTeachingRes]);

  const handleMark = (id) => {
    updateTask(UPDATE_TASK(id), 'PUT');
  };

  useEffect(() => {
    if (!updateRes.isLoading && !updateRes.isError && updateRes.status === 202) {
      getTasks(MY_CREATED_TASKS, 'GET');
      getAssignedTasks(ASSIGNED_TASKS, 'GET');
      alert('Success');
    }

    if (updateRes.isError) {
      alert('Failed');
    }
  }, [updateRes]);

  const getFile = (fileName, user) => {
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  let taskAssignedData = data;
  if (taskAssignedFilter === 'Closed') {
    taskAssignedData = taskAssignedData.filter((x) => x.closingDate);
  } else if (taskAssignedFilter === 'In Progress') {
    taskAssignedData = taskAssignedData.filter((x) => !x.closingDate);
  }

  let myTaskData = assignedTasks?.data || [];
  if (myTaskFilter === 'Closed') {
    myTaskData = myTaskData.filter((x) => x.closingDate);
  } else if (myTaskFilter === 'Pending') {
    myTaskData = myTaskData.filter((x) => !x.closingDate);
  }

  const handleClose = (refresh) => {
    setOpen(false);
    if (refresh) {
      getTasks(MY_CREATED_TASKS, 'GET');
      getAssignedTasks(ASSIGNED_TASKS, 'GET');
    }
  };

  return (
    <div className="w-full md:w-4/5">
      <div className="relative pb-10">
        {open && (
          <CreateTask
            teachingStaff={teachingStaff}
            nonTeachingStaff={nonTeachingStaff}
            getFile={getFile}
            handleClose={handleClose}
          />
        )}

        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-4 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold flex justify-between w-full">
                <p className="w-1/2">My Task</p>
                <div className="flex justify-end items-center w-full">
                  <DropDown
                    data={['View All', 'Pending', 'Closed']}
                    width={'w-40'}
                    title={'View All'}
                    handleItemClick={(x) => {
                      setMyTaskFilter(x);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-3/12 border-r-2 py-2 px-2">Task Assigned By</div>
                    <div className="w-5/12 border-r-2 py-2 px-2">Description</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Due Date</div>
                    <div className="w-2/12 py-2 px-2">Status</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                  {!assignedTasks.isLoading && Array.isArray(myTaskData) && myTaskData.length > 0 ? (
                    myTaskData.map((x, idx) => {
                      const daysLate = moment(x.closingDate).diff(moment(x.dueDate), 'days');
                      return (
                        <div className="w-full" key={x?._id}>
                          <div
                            className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                              idx !== myTaskData.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-3/12 px-2">
                              <div className="w-full">
                                <img
                                  className="schoolLogo"
                                  style={{ width: '2.5em', height: '2.3em' }}
                                  src={getFile('profileImg', x.user_id)}
                                />
                                <p className="font-medium text-sm pt-1">{x?.user_id?.full_name}</p>
                                <p className="text-xs text-tableRowText">{x?.staffType}</p>
                              </div>
                            </div>
                            <div className="w-5/12 px-2 text-tableRowText">{x?.reason}</div>
                            <div className={`w-2/12 px-2 ${daysLate >= 0 ? 'text-textRed' : 'text-black'}`}>
                              {moment(x.dueDate).format('LL')}
                            </div>
                            <div className={`w-2/12 px-2 ${daysLate >= 0 ? 'text-textRed' : 'text-black'}`}>
                              {x?.closingDate ? (
                                'Closed'
                              ) : (
                                <button
                                  className="border border-primary px-4 py-2 rounded-3xl bg-white text-xs font-medium text-primary mr-4 w-10/12 focus:outline-none flex justify-center text-center items-center"
                                  onClick={() => handleMark(x?._id)}
                                >
                                  Mark Close
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-xl h-auto ">
          <div className="items-center px-6 py-3 border-borderYellow border-b">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-6/12">
                <p>Task Assigned</p>
              </div>
              <div className="flex justify-end items-center w-full">
                <button
                  className="border px-4 py-2 rounded-3xl bg-primary text-xs font-medium text-white mr-4 w-40 focus:outline-none flex justify-center text-center items-center"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Create New Task
                </button>

                <DropDown
                  data={['View All', 'In Progress', 'Closed']}
                  width={'w-40'}
                  title={'View All'}
                  handleItemClick={(x) => {
                    setTaskAssignedFilter(x);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-3/12 border-r-2 py-2 px-2">Task Assigned To</div>
                    <div className="w-5/12 border-r-2 py-2 px-2">Description</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Due Date</div>
                    <div className="w-2/12 py-2 px-2">Status</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                  {Array.isArray(taskAssignedData) && taskAssignedData.length > 0 ? (
                    taskAssignedData.map((x, idx) => {
                      const daysLate = moment(new Date()).diff(moment(x.dueDate), 'days');
                      return (
                        <div key={x?._id} className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                              idx !== taskAssignedData.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-3/12 px-2">
                              <div className="w-full">
                                <img
                                  className="schoolLogo"
                                  style={{ width: '2.5em', height: '2.3em' }}
                                  src="/assets/img/user_placeholder.svg"
                                />
                                <p className="text-textDark text-sm pt-1">{`${
                                  x?.teams.length > 0 && x?.teams[0].name
                                } ${x?.teams.length > 1 ? `+ ${x?.teams.length - 1}` : ''}`}</p>
                                <p className="text-tableRowText text-xs text-gray-400">{x?.staffType}</p>
                              </div>
                            </div>
                            <div className="w-5/12 px-2 text-tableRowText">{x?.reason}</div>
                            <div className={`w-2/12 px-2 ${daysLate >= 0 ? 'text-textRed' : 'text-black'}`}>
                              {moment(x.dueDate).format('LL')}
                            </div>
                            <div
                              className={`w-2/12 px-2 ${
                                !x.closingDate && daysLate >= 0 ? 'text-textRed' : 'text-black'
                              }`}
                            >
                              {x.closingDate ? 'Closed' : daysLate >= 0 ? `${daysLate} days late` : 'In Progress'}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskBoard;
