import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { ADD_CLASS_FEE_STRUCTURE } from '../../../api/urls/finance';
import DropDown from '../../UI/DropDown';
import { MONTHS } from '../../../constants';

export default function CreateFeeStructure({ handleClose, selectedClass }) {
  const [{ isLoading, isError, status }, createFee] = useApi();

  const [monthlyFeeList, setMonthlyFeeList] = useState([0]);
  const [annualFeeList, setAnnualFeeList] = useState([0]);
  const [examFeeList, setExamFeeList] = useState([0]);
  const [miscFeeList, setMiscFeeList] = useState([0]);

  const [monthlyFee, setMonthlyFee] = useState([{ name: '', amount: '' }]);
  const [annualFee, setAnnualFee] = useState([{ name: '', amount: '' }]);
  const [annualFeeMonth, setAnnualFeeMonth] = useState();
  const [examFee, setExamFee] = useState([{ month: '', amount: '' }]);
  const [miscFee, setMiscFee] = useState([{ name: '', amount: '' }]);
  const [lateFee, setLateFee] = useState();

  useEffect(() => {
    if (selectedClass?.receipt?.monthlyFee) {
      setMonthlyFee(selectedClass?.receipt?.monthlyFee);
      setMonthlyFeeList(selectedClass?.receipt?.monthlyFee.map((_, idx) => idx));
    }

    if (selectedClass?.receipt?.miscFee) {
      setMiscFee(selectedClass?.receipt?.miscFee);
      setMiscFeeList(selectedClass?.receipt?.miscFee.map((_, idx) => idx));
    }

    if (selectedClass?.receipt?.annualFee) {
      let _annualMonthFee = selectedClass?.receipt?.annualFee.find((x) => x.fee);
      if (_annualMonthFee) {
        setAnnualFee(_annualMonthFee?.fee);
        setAnnualFeeList(_annualMonthFee?.fee.map((_, idx) => idx));
        setAnnualFeeMonth(_annualMonthFee?.month);
      }
    }

    if (selectedClass?.receipt?.examFee) {
      let _examFee = selectedClass?.receipt?.examFee
        .filter((x) => x.fee)
        .map((x) => {
          return {
            month: x.month,
            amount: x.fee,
          };
        });
      if (_examFee) {
        setExamFee(_examFee);
        setExamFeeList(_examFee.map((_, idx) => idx));
      }
    }

    if (selectedClass?.receipt?.lateFee) {
      setLateFee(selectedClass?.receipt?.lateFee);
    }
  }, [selectedClass]);

  const addMonthlyList = () => {
    if (monthlyFeeList.length > 0)
      setMonthlyFeeList([...monthlyFeeList, monthlyFeeList[monthlyFeeList.length - 1] + 1]);
    else setMonthlyFeeList([0]);
  };

  const removeMonthlyItem = (id) => {
    monthlyFeeList.pop();
    setMonthlyFeeList([...monthlyFeeList]);
    if (monthlyFee.length === 1) setMonthlyFee([]);
    else {
      monthlyFee.splice(id, 1);
      setMonthlyFee([...monthlyFee]);
    }
  };

  const addAnnualList = () => {
    if (annualFeeList.length > 0) setAnnualFeeList([...annualFeeList, annualFeeList[annualFeeList.length - 1] + 1]);
    else setAnnualFeeList([0]);
  };

  const removeAnnualItem = (id) => {
    annualFeeList.pop();
    setAnnualFeeList([...annualFeeList]);
    if (annualFee.length === 1) setAnnualFee([]);
    else {
      annualFee.splice(id, 1);
      setAnnualFee([...annualFee]);
    }
  };

  const addExamFeeList = () => {
    if (examFeeList.length > 0) setExamFeeList([...examFeeList, examFeeList[examFeeList.length - 1] + 1]);
    else setExamFeeList([0]);
  };

  const addMiscFeeList = () => {
    if (miscFeeList.length > 0) setMiscFeeList([...miscFeeList, miscFeeList[miscFeeList.length - 1] + 1]);
    else setMiscFeeList([0]);
  };

  const removeExamFeeItem = (id) => {
    examFeeList.pop();
    setExamFeeList([...examFeeList]);
    if (examFee.length === 1) setExamFee([]);
    else {
      examFee.splice(id, 1);
      setExamFee([...examFee]);
    }
  };

  const removeMiscFeeItem = (id) => {
    miscFeeList.pop();
    setMiscFeeList([...miscFeeList]);
    if (miscFee.length === 1) setMiscFee([]);
    else {
      miscFee.splice(id, 1);
      setMiscFee([...miscFee]);
    }
  };

  const handleMonthlyFeeChange = (e, index) => {
    const x = monthlyFee;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...monthlyFee[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setMonthlyFee([...x]);
  };

  const handleAnnualFeeChange = (e, index) => {
    const x = annualFee;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...annualFee[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setAnnualFee([...x]);
  };

  const handleExamFeeChange = (e, index) => {
    const x = examFee;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...examFee[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setExamFee([...x]);
  };

  const handleMiscFeeChange = (e, index) => {
    const x = miscFee;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...miscFee[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setMiscFee([...x]);
  };

  const handleLateFeeChange = (e) => {
    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    setLateFee({
      ...lateFee,
      [e.target.name]: inputValue,
    });
  };

  const handleSubmit = () => {
    let str = [];

    if (monthlyFee.length === 1 && !monthlyFee[0].amount && !monthlyFee[0].name) {
      str = 'Please enter some heads in monthly fee';
    }
    monthlyFee.forEach((x) => {
      if (x.name.trim() && !x.amount) {
        str.push('Please enter Amount for Head Name ' + x.name + ' in Monthly Fee');
      }

      if (!x.name.trim() && x.amount) {
        str.push('Please enter Head Name for Amount ' + x.amount + ' in Monthly Fee');
      }
    });

    annualFee.forEach((x) => {
      if (x.name.trim() && !x.amount) {
        str.push('Please enter Amount for Head Name ' + x.name + ' in Annual Fee');
      }

      if (!x.name.trim() && x.amount) {
        str.push('Please enter Head Name for Amount ' + x.amount + ' in Annual Fee');
      }
    });

    miscFee.forEach((x) => {
      if (x.name.trim() && !x.amount) {
        str.push('Please enter Amount for Head Name ' + x.name + ' in Miscellaneous Fee');
      }

      if (!x.name.trim() && x.amount) {
        str.push('Please enter Head Name for Amount ' + x.amount + ' in Miscellaneous Fee');
      }
    });

    if (annualFee.length >= 1 && annualFee[0].amount && annualFee[0].name && !annualFeeMonth) {
      str.push('Please select Month for Annual fee');
    }

    examFee.forEach((x) => {
      if (x.month && !x.amount) {
        str.push('Please enter Amount for Month ' + x.month + ' in Exam Fee');
      }

      if (!x.month && x.amount) {
        str.push('Please enter Month for Amount ' + x.amount + ' in Exam Fee');
      }
    });

    if (lateFee?.lateFee || lateFee?.startOn || lateFee?.basedOn) {
      if (!lateFee?.lateFee || !lateFee?.startOn || !lateFee?.basedOn) {
        str.push('Please fill all fields in Late fee');
      }
    }

    if (str.length > 0) {
      alert(str.join(', '));
      return;
    }

    const body = {
      fees: {
        monthlyFee,
        annualFee: {
          month: annualFeeMonth,
          fee: annualFee,
        },
        examFee,
        lateFee,
        miscFee,
      },
    };

    createFee(ADD_CLASS_FEE_STRUCTURE(selectedClass.classId, selectedClass.sectionId), 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && (status === 201 || status === 200)) {
      alert('Success');
      handleClose(true);
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, status]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-2/5 pb-6 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="text-sm relative border-b border-borderYellow flex justify-between mb-1 items-center py-5 px-6">
          <div className="font-bold">
            <p>{`Month Structure (${selectedClass?.className} - ${selectedClass?.sectionName})`}</p>
          </div>
          <div className="flex">
            <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={() => handleClose(false)} />
          </div>
        </div>

        <div>
          <div className="border-b border-borderYellow px-6 pt-5">
            <div className="flex justify-between mb-1">
              <p className="font-bold text-sm">Monthly Fee</p>
              <button
                className="border w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={addMonthlyList}
              >
                Add Head
              </button>
            </div>

            {monthlyFeeList.map((x) => (
              <div key={x} className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-2 relative">
                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-textDark font-medium">Head Name</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Head Name"
                    value={monthlyFee[x]?.name}
                    name="name"
                    onChange={(e) => handleMonthlyFeeChange(e, x)}
                    className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-black font-medium">Amount</label>
                  <input
                    type="number"
                    autoComplete="off"
                    placeholder="Enter Amount"
                    value={monthlyFee[x]?.amount}
                    name="amount"
                    onChange={(e) => handleMonthlyFeeChange(e, x)}
                    className="w-10/12 text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div
                  className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-8"
                  onClick={() => removeMonthlyItem(x)}
                >
                  <img src="assets/img/close_white.svg" alt="Close" className="h-3" />
                </div>
              </div>
            ))}
          </div>

          <div className="border-b border-borderYellow px-6 pt-5">
            <div className="flex items-start gap-x-4 mb-1">
              <p className="font-bold text-sm mr-auto">Annual Fee</p>

              <DropDown
                title={selectedClass?.receipt ? annualFeeMonth : 'Select Month'}
                width={'w-36'}
                otherClasses={
                  'w-full text-xxs text-textDark rounded-3xl ml-auto border border-borderLight px-4 py-2 focus:outline-none'
                }
                data={MONTHS}
                handleItemClick={(c) => setAnnualFeeMonth(c)}
              />
              {/* <select
                onClick={(c) => setAnnualFeeMonth(c.target.value)}
                value={annualFeeMonth}
                className="w-full text-xxs text-textDark rounded-3xl ml-auto border border-borderLight px-4 py-2 focus:outline-none"
              >
                <option>{'Select Month'}</option>
                {MONTHS.map((month) => (
                  <option>{month}</option>
                ))}
              </select> */}

              <button
                className="border w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={addAnnualList}
              >
                Add Head
              </button>
            </div>

            {annualFeeList.map((x, idx) => (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-2 relative">
                <div key={x} className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-textDark font-medium">Head Name</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Head Name"
                    value={annualFee[x]?.name}
                    name="name"
                    onChange={(e) => handleAnnualFeeChange(e, x)}
                    className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-black font-medium">Amount</label>
                  <input
                    type="number"
                    autoComplete="off"
                    placeholder="Enter Amount"
                    value={annualFee[x]?.amount}
                    name="amount"
                    onChange={(e) => handleAnnualFeeChange(e, x)}
                    className="w-10/12 text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div
                  className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-8"
                  onClick={() => removeAnnualItem(x)}
                >
                  <img src="assets/img/close_white.svg" alt="Close" className="h-3" />
                </div>
              </div>
            ))}
          </div>

          <div className="border-b border-borderYellow px-6 pt-5">
            <div className="flex justify-between mb-1">
              <p className="font-bold text-sm">Exam Fee</p>
              <button
                className="border w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={addExamFeeList}
              >
                Add Head
              </button>
            </div>

            {examFeeList.map((x) => (
              <div key={x} className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-2 relative">
                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-textDark font-medium">Exam Fee</label>
                  <input
                    type="number"
                    autoComplete="off"
                    placeholder="Enter amount"
                    value={examFee[x]?.amount}
                    name="amount"
                    onChange={(e) => handleExamFeeChange(e, x)}
                    className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-black font-medium">Month</label>
                  <DropDown
                    title={selectedClass?.receipt ? examFee[x]?.month : 'Select'}
                    width={'w-10/12'}
                    otherClasses={
                      'w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none'
                    }
                    data={MONTHS}
                    handleItemClick={(e) => {
                      handleExamFeeChange(
                        {
                          target: {
                            name: 'month',
                            value: e,
                          },
                        },
                        x
                      );
                    }}
                  />
                </div>
                <div
                  className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-8"
                  onClick={() => removeExamFeeItem(x)}
                >
                  <img src="assets/img/close_white.svg" alt="Close" className="h-3" />
                </div>
              </div>
            ))}
          </div>

          <div className="border-b border-borderYellow px-6 pt-5">
            <div className="flex justify-between mb-1">
              <p className="font-bold text-sm">Miscellaneous Fee</p>
              <button
                className="border w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={addMiscFeeList}
              >
                Add Head
              </button>
            </div>

            {miscFeeList.map((x) => (
              <div key={x} className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-2 relative">
                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-textDark font-medium">Head Name</label>
                  <input
                    autoComplete="off"
                    placeholder="Enter Name"
                    value={miscFee[x]?.name}
                    name="name"
                    onChange={(e) => handleMiscFeeChange(e, x)}
                    className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 relative font-semibold flex flex-col justify-between">
                  <label className="text-parameter text-xs text-black font-medium">Amount</label>
                  <input
                    type="number"
                    autoComplete="off"
                    placeholder="Enter amount"
                    value={miscFee[x]?.amount}
                    name="amount"
                    onChange={(e) => handleMiscFeeChange(e, x)}
                    className="w-10/12 text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div
                  className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-8"
                  onClick={() => removeMiscFeeItem(x)}
                >
                  <img src="assets/img/close_white.svg" alt="Close" className="h-3" />
                </div>
              </div>
            ))}
          </div>

          <div className="px-6 pt-5">
            <div className="flex justify-between mb-1">
              <p className="font-bold text-sm">Late Fee</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-2">
              <div className="my-2 relative font-semibold flex flex-col justify-between">
                <label className="text-parameter text-xs text-textDark font-medium">Late Fee</label>
                <input
                  type="number"
                  autoComplete="off"
                  placeholder="Enter amount"
                  name="lateFee"
                  onChange={handleLateFeeChange}
                  value={lateFee?.lateFee}
                  className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 relative font-semibold flex flex-col justify-between">
                <label className="text-parameter text-xs text-black font-medium">Based on </label>
                <DropDown
                  title={lateFee?.basedOn || 'Select'}
                  otherClasses={
                    'w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none'
                  }
                  data={['Per Day', 'Per Week']}
                  handleItemClick={(x) => handleLateFeeChange({ target: { name: 'basedOn', value: x } })}
                />
              </div>

              <div className="my-2 relative font-semibold flex flex-col justify-between">
                <label className="text-parameter text-xs text-black font-medium">Late Fee Starts On </label>
                <DropDown
                  title={lateFee?.startOn || 'Select'}
                  otherClasses={
                    'w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none'
                  }
                  data={
                    lateFee?.basedOn === 'Per Day'
                      ? [
                          'From 5th of every month',
                          'From 6th of every month',
                          'From 7th of every month',
                          'From 8th of every month',
                          'From 9th of every month',
                          'From 10th of every month',
                        ]
                      : ['From 2nd week of every month', 'From 3rd week of every month', 'From 4th week of every month']
                  }
                  handleItemClick={(x) => handleLateFeeChange({ target: { name: 'startOn', value: x } })}
                />
                {/* <input
                  type="date"
                  autoComplete="off"
                  placeholder="Enter Amount"
                  name="startOn"
                 onChange={handleLateFeeChange}
                 value={lateFee?.startOn}
                  className="w-full text-xxs text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                /> */}
              </div>
            </div>
          </div>

          {isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 ml-auto mr-4" alt="" />
          ) : (
            <button
              className="border w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center ml-auto mr-6 mt-4"
              onClick={handleSubmit}
            >
              {!selectedClass?.receipt ? 'Create' : 'Update'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
