import React, { useState, useRef } from 'react';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { STAFF_ATTENDANCE, STUDENTS_ATTENDANCE_TOTAL } from '../../../api/urls/attendance';
import moment from 'moment';
import { useStateValue } from '../../../ContextApi/StateProvider';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'userName' },
  { label: 'Name', key: 'name' },
  { label: 'Designation', key: 'designamtion' },
  { label: 'Percentage', key: 'pc' },
];

const studentExcelHeaders = [
  { label: 'ROLEDin ID', key: 'userName' },
  { label: 'Student Name', key: 'name' },
  { label: 'Father Name', key: 'father' },
  { label: 'Percentage', key: 'pc' },
];

function ExportReport({ toggleView, type }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [excelData, setExcelData] = useState([]);

  const [{ token }, _] = useStateValue();

  const childRef = useRef();

  const createExcelData = (_data) => {
    let x = _data.map((x) => {
      return {
        userName: x?.userInfo?.userInfo?.username || '',
        name: x?.userInfo?.userInfo?.full_name,
        pc: x.cumulative,
        designamtion:
          x?.userInfo?.userInfo?.jobProfile.length > 0
            ? x?.userInfo?.userInfo?.jobProfile[x?.userInfo?.userInfo?.jobProfile.length - 1]?.title
            : '',
      };
    });

    if (Array.isArray(x)) setExcelData(x);

    setTimeout(() => {
      childRef.current.click();
    }, 0);
  };

  const createStudentExcelData = (_data) => {
    let x = _data.map((x) => {
      return {
        userName: x?.userInfo?.userInfo?.username || '',
        name: x?.userInfo?.userInfo?.full_name,
        pc: x.cumulative,
        father:
          (x?.userInfo.userInfo?.parentDetail &&
            x?.userInfo.userInfo?.parentDetail.length > 0 &&
            x?.userInfo.userInfo?.parentDetail[0].father_name) ||
          '',
      };
    });

    if (Array.isArray(x)) setExcelData(x);

    setTimeout(() => {
      childRef.current.click();
    }, 0);
  };

  // const getExcelData = () => {
  //   return excelData;
  // };

  const handleClick = (event, done) => {
    if (startDate && endDate) {
      if (type === 'teaching') {
        fetch(
          `${STAFF_ATTENDANCE('teaching')}&startDate=${moment(startDate).toISOString()}&endDate=${moment(
            endDate
          ).toISOString()}`,
          {
            method: 'get',
            headers: {
              Authorization: `Bearer ${token.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => res.json())
          .then((res) => createExcelData(res));
      } else if (type === 'non-teaching') {
        fetch(
          `${STAFF_ATTENDANCE('non-teaching')}&startDate=${moment(startDate).toISOString()}&endDate=${moment(
            endDate
          ).toISOString()}`,
          {
            method: 'get',
            headers: {
              Authorization: `Bearer ${token.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => res.json())
          .then((res) => createExcelData(res));
      } else if (type === 'student') {
        fetch(
          `${STUDENTS_ATTENDANCE_TOTAL}&startDate=${moment(startDate).toISOString()}&endDate=${moment(
            endDate
          ).toISOString()}`,
          {
            method: 'get',
            headers: {
              Authorization: `Bearer ${token.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => res.json())
          .then((res) => createStudentExcelData(res));
      }
    } else {
      alert('Please fill the required fields');
    }
  };

  return (
    <div
      className="flex h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-2/5 flex pb-2 text-sm m-auto mt-10 flex-col rounded-2xl">
        <div className="flex w-full justify-between items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 py-4 text-textBlue1">Export Attendance</p>
          <img
            src="assets/img/Close.svg"
            className="absolute right-4 h-3 cursor-pointer z-50"
            alt=""
            onClick={toggleView}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-4 px-6 pb-2 py-4">
          <div className="my-2 relative font-semibold ">
            <label className="block text-parameter text-sm text-textDark">Start Date</label>
            <DatePicker
              className="w-full rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat={'dd-MM-yyyy'}
            />
          </div>
          <div className="my-2 relative font-semibold ">
            <label className="block text-parameter text-sm text-textDark">End Date</label>
            <DatePicker
              className="w-full rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat={'dd-MM-yyyy'}
            />
          </div>
        </div>
        <div className="mb-2 flex justify-end px-6">
          <button
            onClick={handleClick}
            className={`border w-40 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center`}
          >
            Export
          </button>
          <ExcelExportBtn
            data={excelData}
            text={'Export'}
            headers={type === 'student' ? studentExcelHeaders : excelHeaders}
            fileName={'AttendanceReport.csv'}
            // handleClick={handleClick}
            ref={childRef}
            hidden={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ExportReport;
