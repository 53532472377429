import React from 'react';
import moment from 'moment';

export default function List({ setSearch, searchName, setShowList, isSearch, handleOpenMsg, data }) {
  return (
    <div
      className="flex h-full w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-3/4 text-sm mx-auto mt-16 mb-auto block rounded-2xl relative">
        <span className="block px-6  font-bold py-5 border-b border-borderYellow">
          {isSearch ? `Search Results for ${searchName}` : `Staff on Leave (Teaching Staff)`}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-5 right-8 h-3 cursor-pointer"
          alt=""
          onClick={() => (isSearch ? setSearch(false) : setShowList(false))}
        />
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4">
            <div className="bg-white m-auto border border-borderYellow mx-6 rounded-2xl">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative px-2 text-xs font-medium">
                  <div className="w-3/12 border-r-2 p-2">Photograph</div>
                  <div className="w-3/12 border-r-2 p-2">ROLEDin ID</div>
                  <div className="w-3/12 border-r-2 p-2">Name</div>
                  <div className="w-3/12 border-r-2 p-2">Staff Type</div>
                  <div className="w-3/12 border-r-2 p-2">Designation</div>
                  <div className="w-4/12 border-r-2 p-2">Leave Start Date</div>
                  <div className="w-4/12 border-r-2 p-2">Leave End Date</div>
                  <div className="w-1/4 p-2">Action</div>
                </div>
              </div>
              <div
                className="customScrollBar"
                style={{
                  maxHeight: '35vh',
                }}
              >
                {data.length > 0 ? (
                  data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`"justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                          data.length - 1 !== idx && 'border-b border-borderYellow'
                        }"`}
                      >
                        <div className="w-3/12 px-4">
                          {x?.user_id?.fileArray.length > 0 &&
                          x?.user_id?.fileArray[0].profileImg &&
                          x?.user_id?.fileArray[0].profileImg.location ? (
                            <img
                              className="schoolLogo"
                              style={{
                                width: '3em',
                                height: '3em',
                              }}
                              src={x?.user_id?.fileArray[0].profileImg.location}
                              alt=""
                            />
                          ) : (
                            <img
                              className="schoolLogo"
                              style={{
                                width: '3em',
                                height: '3em',
                              }}
                              src={'/assets/img/user_placeholder.svg'}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="w-3/12 text-tableRowText px-2">{x?.user_id?.username}</div>
                        <div className="w-3/12 px-2">{x?.user_id?.full_name}</div>
                        <div className="w-3/12 text-tableRowText px-2">
                          {x.user_id?.currentlyMapped.role.includes('te') ||
                          x.user_id?.currentlyMapped.role.includes('pr')
                            ? 'Teaching Staff'
                            : 'Non-Teaching Staff'}
                        </div>
                        <div className="w-3/12 text-tableRowText px-2">
                          {x?.user_id?.jobProfile.length > 0 &&
                            x?.user_id?.jobProfile[x?.user_id?.jobProfile.length - 1].title}
                        </div>
                        <div className="w-4/12 text-tableRowText px-2">{moment(x?.date).format('D-MM-YYYY')}</div>
                        <div className="w-4/12 text-tableRowText px-2">{moment(x?.endDate).format('D-MM-YYYY')}</div>
                        <div className="w-1/4 flex justify-center items-center">
                          <button
                            className="w-4/5 border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                            onClick={() => {
                              handleOpenMsg(x?.user_id);
                            }}
                          >
                            Message
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
