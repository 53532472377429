import React, { useEffect, useState } from 'react';
import useApi from '../../../api/useApi';
import BrandingSettingComponent from '../Branding';
import RolesComponent from '../Role';
import DropDown from '../../UI/DropDown';
import { UPDATE_USER } from '../../../api/urls/user';
import { getRole } from '../../../utils';
import { USER_PROFILE } from '../../../api/urls/profile';
import { useStateValue } from '../../../ContextApi/StateProvider';

const ProfileGeneralSettingsComponent = ({ profile }) => {
  const [tab, setTab] = useState(0);
  const [edit, setEdit] = useState('');
  const [_, dispatch] = useStateValue();

  const formDataModel = {
    email_id: '',
    full_name: '',
    gender: '',
    age: 0,
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [addressState, setAddressState] = useState(addressModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, updateUser] = useApi();
  const [profileRes, getProfile] = useApi();

  useEffect(() => {
    if (profile?.user) {
      setFormData({
        email_id: profile?.user?.email_id,
        full_name: profile?.user?.full_name,
        gender: profile?.user.gender,
        age: profile?.user?.age,
        phone: profile?.user?.phone,
      });

      setAddressState(profile?.user.address);
    }
  }, [profile]);

  const handleSubmit = (item) => {
    const formData2 = new FormData();
    if (profile?.user?.address) formData2.append('address', JSON.stringify(addressState));
    if (profile?.user?.caddress) formData2.append('caddress', JSON.stringify(profile?.user?.caddress));
    if (profile?.user?.experience) formData2.append('experience', JSON.stringify(profile?.user?.experience));
    if (profile?.user?.education) formData2.append('education', JSON.stringify(profile?.user?.education));
    if (profile?.user?.jobProfile) formData2.append('jobProfile', JSON.stringify(profile?.user?.jobProfile));
    if (profile?.user?.bankDetail) formData2.append('bankDetail', JSON.stringify(profile?.user?.bankDetail));
    formData2.append('full_name', formData.full_name);
    formData2.append('gender', formData.gender);
    if (profile?.user?.dateOfBirth) formData2.append('dateOfBirth', profile?.user?.dateOfBirth);
    formData2.append('email_id', formData.email_id);
    formData2.append('phone', formData.phone);
    if (profile?.user?.maritalStatus) formData2.append('maritalStatus', profile?.user?.maritalStatus);
    formData2.append('roleType', profile?.user?.roleType);
    formData2.append('role', profile?.user?.role);
    formData2.append('username', profile?.user?.username);
    formData2.append('age', formData.age);

    updateUser(UPDATE_USER(profile?.user._id), 'PUT', formData2);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      getProfile(USER_PROFILE, 'GET');
      alert('Success');
      setEdit();
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  useEffect(() => {
    if (!profileRes.isLoading && !profileRes.isError && profileRes.data) {
      dispatch({
        type: 'USER',
        user: profileRes.data,
      });
      dispatch({
        type: 'VALID_ROLE',
        validRole: true,
      });
    }
  }, [profileRes]);

  const refresh = () => {
    getProfile(USER_PROFILE, 'GET');
  };

  return (
    <div className="w-full md:w-3/4">
      <div className="flex gap-x-4">
        <button
          className={`text-xxs rounded-full px-6 py-2.5 ${tab === 0 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          onClick={() => setTab(0)}
        >
          <span>{`${profile?.user?.full_name}`}</span>
        </button>
        {profile?.user?.currentlyMapped?.role.includes('ow') && (
          <button
            className={`text-xxs rounded-full px-6 py-2.5 ${
              tab === 1 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
            onClick={() => setTab(1)}
          >
            <span>{profile?.institute?.name}</span>
          </button>
        )}
      </div>

      <div className="mt-4">
        {tab === 0 && (
          <div>
            <div className="bg-white rounded-2xl shadow-sm">
              <div className=" px-6 py-4" style={{ borderBottom: '1px solid #E7E700' }}>
                <p className="font-bold ">Profile </p>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Name</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'name' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'name'}
                  name="full_name"
                  onChange={handleInputChange}
                  placeholder="Amit Sharma"
                  value={formData.full_name}
                />
                <button
                  className={`${
                    edit === 'name' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'name' ? handleSubmit() : setEdit('name'))}
                >
                  {edit === 'name' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Age</p>
                {edit === 'age' ? (
                  <input
                    className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                      edit === 'age' && ' border border-borderLight'
                    }`}
                    type="number"
                    disabled={edit !== 'age'}
                    name="age"
                    onChange={handleInputChange}
                    placeholder="xx Years"
                    value={formData.age}
                  />
                ) : (
                  <p
                    className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                      edit === 'age' && ' border border-borderLight'
                    }`}
                  >
                    {formData.age ? formData.age + ' Years' : ''}
                  </p>
                )}
                <button
                  className={`${
                    edit === 'age' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'age' ? handleSubmit() : setEdit('age'))}
                >
                  {edit === 'age' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Sex</p>
                {edit === 'sex' ? (
                  <select
                    className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                      edit === 'sex' && ' border border-borderLight'
                    }`}
                    name="gender"
                    disabled={edit !== 'sex'}
                    onChange={handleInputChange}
                    placeholder="Male"
                    value={formData.gender}
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                ) : (
                  <p
                    className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                      edit === 'sex' && ' border border-borderLight'
                    }`}
                  >
                    {formData.gender}
                  </p>
                )}
                <button
                  className={`${
                    edit === 'sex' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'sex' ? handleSubmit() : setEdit('sex'))}
                >
                  {edit === 'sex' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Profession</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full capitalize ${
                    edit === 'profession' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'profession'}
                  onChange={handleInputChange}
                  placeholder="--"
                  value={profile?.user?.role}
                />
                {/* <button
                  className={`${
                    edit === 'profession' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'profession' ? handleSubmit() : setEdit('profession'))}
                >
                  {edit === 'profession' ? 'Save' : 'Edit'}
                </button> */}
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Address</p>
                {edit === 'address' ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">
                        Flat No. / House No.<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        // placeholder="Eg-198"
                        required
                        autoComplete="off"
                        value={addressState.hno}
                        name="hno"
                        onChange={handleAddressInputChange}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">Colony / Society</label>
                      <input
                        type="text"
                        value={addressState.society}
                        name="society"
                        onChange={handleAddressInputChange}
                        // placeholder="Eg-Amar Colony"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>
                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">
                        District<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={addressState.district}
                        name="district"
                        onChange={handleAddressInputChange}
                        // placeholder="Eg-Jalandhar"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">
                        City<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={addressState.city}
                        name="city"
                        onChange={handleAddressInputChange}
                        // placeholder="Eg-Jalandhar"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>
                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">
                        State<span className="text-red-500 ml-1">*</span>
                      </label>
                      <select
                        type="text"
                        required
                        value={addressState.state}
                        name="state"
                        onChange={handleAddressInputChange}
                        // placeholder="Eg-Punjab"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      >
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div className=" w-full relative font-semibold">
                      <label className="text-textDark text-xs font-medium">
                        Pin Code<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={addressState.pincode}
                        name="pincode"
                        onChange={handleAddressInputChange}
                        // placeholder="Eg-141401"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>
                  </div>
                ) : (
                  <p
                    className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                      edit === 'address' && ' border border-borderLight'
                    }`}
                    // disabled={edit !== 'address'}
                    // onChange={handleInputChange}
                    // placeholder="125 Vidya Vihar, Pitampura, New Delhi - 110034"
                    // value={profile?.user?.address ? Object.values(profile?.user?.address).join(', ') : '--'}
                  >
                    {profile?.user?.address ? Object.values(profile?.user?.address).join(', ') : '--'}
                  </p>
                )}
                <button
                  className={`${
                    edit === 'address' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'address' ? handleSubmit() : setEdit('address'))}
                >
                  {edit === 'address' ? 'Save' : 'Edit'}
                </button>
              </div>

              {/* <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">AADHAR</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'aadhar' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'aadhar'}
                  onChange={handleInputChange}
                  placeholder="5555-5555-5555"
                  value={'--'}
                />

                <button
                  className={`${
                    edit === 'aadhar' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'aadhar' ? setEdit('') : setEdit('aadhar'))}
                >
                  {edit === 'aadhar' ? 'Save' : 'Edit'}
                </button>
              </div> */}

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Role</p>
                <p
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'role' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'role'}
                  // onChange={handleInputChange}
                  // placeholder="Owner &amp; Parent"
                  // value={'--'}
                >
                  {profile?.user?.currentlyMapped?.role.map((x) => getRole(x)).join(', ')}
                </p>

                {/* <button
                  className={`${
                    edit === 'role' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'role' ? setEdit('') : setEdit('role'))}
                >
                  {edit === 'role' ? 'Save' : 'Edit'}
                </button> */}
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Email</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'email' && ' border border-borderLight'
                  }`}
                  name="email_id"
                  disabled={edit !== 'email'}
                  onChange={handleInputChange}
                  placeholder="sharmaamit&#64;gmail.com"
                  value={formData?.email_id}
                />
                <button
                  className={`${
                    edit === 'email' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'email' ? handleSubmit() : setEdit('email'))}
                >
                  {edit === 'email' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center ">
                <p className="text-textDark text-xs font-medium w-1/5">Phone </p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'phone' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'phone'}
                  onChange={handleInputChange}
                  placeholder="8547856324"
                  name="phone"
                  value={formData?.phone}
                />
                <button
                  className={`${
                    edit === 'phone' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'phone' ? handleSubmit() : setEdit('phone'))}
                >
                  {edit === 'phone' ? 'Save' : 'Edit'}
                </button>
              </div>

              {/* <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">LinkedIN</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'linkedIn' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'linkedIn'}
                  onChange={handleInputChange}
                  placeholder=" www.linkedin.com/in/Amitsharma/"
                  value={'--'}
                />
                <button
                  className={`${
                    edit === 'linkedIn' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'linkedIn' ? setEdit('') : setEdit('linkedIn'))}
                >
                  {edit === 'linkedIn' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
                <p className="text-textDark text-xs font-medium w-1/5">Facebook</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'fb' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'fb'}
                  onChange={handleInputChange}
                  placeholder="www.facebook.com/profile.php?id&#61;100000819621829"
                  value={'--'}
                />
                <button
                  className={`${
                    edit === 'fb' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'fb' ? setEdit('') : setEdit('fb'))}
                >
                  {edit === 'fb' ? 'Save' : 'Edit'}
                </button>
              </div>

              <div className="mx-6 my-0 px-0 py-3 flex items-center">
                <p className="text-textDark text-xs font-medium w-1/5">Instagram</p>
                <input
                  className={`text-tableRowText text-xs w-4/5 bg-white mr-10 px-4 py-2 rounded-full ${
                    edit === 'insta' && ' border border-borderLight'
                  }`}
                  disabled={edit !== 'insta'}
                  onChange={handleInputChange}
                  placeholder="www.instagram.com/Sharmamit/"
                  value={'--'}
                />

                <button
                  className={`${
                    edit === 'insta' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
                  }   text-xxs ml-auto cursor-pointer  py-1.5`}
                  onClick={() => (edit === 'insta' ? setEdit('') : setEdit('insta'))}
                >
                  {edit === 'insta' ? 'Save' : 'Edit'}
                </button>
              </div> */}
            </div>

            {/* <div className="bg-white rounded-2xl shadow-sm my-4">
              <div className="border-b border-borderYellow px-6 py-4">
                <p className="font-bold ">Preference</p>
              </div>

              <div className="mx-6 my-0 px-0 py-3.5 flex items-center border-b border-borderYellow">
                <div className="w-full">
                  <p className="text-textDark text-xs font-medium">Font Size</p>
                  <p className="text-tableRowText text-xxs outline-none">Small, Default, Large, Extra Large</p>
                </div>
                <DropDown
                  data={['Default', 'Small', 'Large', 'Extra Large']}
                  width={'w-1/3'}
                  otherClasses={'ml-auto border-borderLight'}
                  handleItemClick={() => {}}
                />
              </div>

              <div className="mx-6 my-0 px-0 py-3.5 flex items-center border-b border-borderYellow">
                <div className="w-full">
                  <p className="text-textDark text-xs font-medium">Display Scaling</p>
                  <p className="text-tableRowText text-xxs outline-none">Small, Default, Large, Extra Large</p>
                </div>
                <DropDown
                  data={['Default', 'Small', 'Large', 'Extra Large']}
                  width={'w-1/3'}
                  otherClasses={'ml-auto border-borderLight'}
                  handleItemClick={() => {}}
                />
              </div>

              <div className="mx-6 my-0 px-0 py-3.5 flex items-center">
                <div className="w-full">
                  <p className="text-textDark text-xs font-medium">Background Color</p>
                  <p className="text-tableRowText text-xxs outline-none">Grey, White, Baige ......</p>
                </div>
                <DropDown
                  data={['Grey', 'White', 'Baige']}
                  width={'w-1/3'}
                  otherClasses={'ml-auto border-borderLight '}
                  handleItemClick={() => {}}
                />
              </div>
            </div> */}
          </div>
        )}

        {tab === 1 && (
          <div>
            <BrandingSettingComponent institute={profile?.institute} refresh={refresh} />
            {/* <RolesComponent /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileGeneralSettingsComponent;
