import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { STAFF_REMOVE } from '../../../api/urls/user';
import DropDown from '../../UI/DropDown';

function StaffRemovePopup({ handleClose, refreshList, staff }) {
  const timer = useRef();

  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    resignDate: '',
    noticePeriod: '',
    lastWorkingdate: '',
  });
  const [id, setId] = useState('');

  const [response, removeStaff] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      ...formData,
      resignDate: new Date(formData.resignDate),
    };
    // console.log(id, body);
    removeStaff(STAFF_REMOVE(id), 'DELETE', body);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && response.status) {
      setId('');
      alert('SUCCESS');
      handleClose();
      refreshList();
    }

    if (response.isError) {
      alert('FAILED');
    }
  }, [response]);

  // const handleSearch = (e) => {
  //   if (timer.current) clearTimeout(timer.current);
  //   let x = e.target.value;
  //   timer.current = setTimeout(() => {
  //     searchStaff(SEARCH_STAFF(x), 'GET');
  //   }, 700);
  // };

  // useEffect(() => {
  //   if (!staffRes.isLoading && !staffRes.isError && staffRes.data) {
  //     if (staffRes.data.length === 1) {
  //       let x = staffRes.data[0];
  //       setId(x.username);
  //       setFormData({
  //         ...formData,
  //         name: x.full_name,
  //         designation: x.jobProfile && x.jobProfile.length > 0 && x.jobProfile[x.jobProfile.length - 1]?.title,
  //       });
  //     }
  //   }
  // }, [staffRes]);

  useEffect(() => {
    if (formData.resignDate && formData.noticePeriod) {
      setFormData({
        ...formData,
        lastWorkingdate: moment(formData.resignDate).add(+formData.noticePeriod, 'days'),
      });
    }
  }, [formData.resignDate, formData.noticePeriod]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">Remove Staff</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
        </div>
        <form onSubmit={handleSubmit} className="my-4 px-6 " autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Name</label>
              {/* <input
                type="text"
                name="mode"
                onChange={handleSearch}
                value={id}
                autoComplete="off"
                className="w-full  rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none"
              /> */}
              <DropDown
                field={'full_name'}
                handleItemClick={(x) => {
                  setId(x.username);
                  setFormData({
                    ...formData,
                    name: x.full_name,
                    designation:
                      x.jobProfile && x.jobProfile.length > 0 && x.jobProfile[x.jobProfile.length - 1]?.title,
                  });
                }}
                title={'Select'}
                data={staff}
                width={'w-full'}
                type={'obj'}
                margin={'mt-2'}
                otherClasses={'border-borderLight'}
              />
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">ROLEDin ID</label>
              <input
                type="text"
                // name="name"
                value={id}
                disabled
                autoComplete="off"
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Designation</label>
              <input
                type="text"
                name="designation"
                disabled
                value={formData?.designation}
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label htmlFor="resignation_date" className="block text-textDark text-sm">
                Resignation Date
              </label>
              <input
                type="date"
                name="resignDate"
                onChange={handleInputChange}
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Notice Period (In Days)</label>
              <input
                type="text"
                name="noticePeriod"
                onChange={handleInputChange}
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold ">
              <label className="block text-textDark text-sm">Last Working Day</label>
              <input
                // type="date"
                disabled
                name="lastWorkingdate"
                value={formData.lastWorkingdate && moment(formData.lastWorkingdate).format('D-MM-YYYY')}
                // onChange={handleInputChange}
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>
          </div>

          <div className=" my-4 w-full flex gap-x-4 justify-end">
            {response.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <>
                <button
                  type="reset"
                  className="border py-2 w-32 rounded-3xl text-xs font-semibold focus:outline-none text-center text-tableRowText border-tableRowText"
                  onClick={() => {
                    setId('');
                    setFormData({ name: '', designation: '', resignDate: '', noticePeriod: '', lastWorkingdate: '' });
                  }}
                >
                  Reset
                </button>

                <button
                  type="submit"
                  className="border py-2 w-32 rounded-3xl text-xs font-semibold focus:outline-none text-center bg-primary border-primary text-white"
                >
                  Remove
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default StaffRemovePopup;
