import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { TIMETABLE_POST, TIMETABLE_PUT } from '../../../api/urls/timeTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

function CreateTimeTable({ setOpenCreate, selectedClass, subjects, staff, refreshData, isView }) {
  const timetableModel = {
    startTime: null,
    endTime: null,
    timeTable: [
      {
        day: 'Monday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
      {
        day: 'Tuesday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
      {
        day: 'Wednesday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
      {
        day: 'Thursday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
      {
        day: 'Friday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
      {
        day: 'Saturday',
        subject: '',
        subjectId: '',
        teacher: '',
        teacherId: '',
      },
    ],
  };

  const [timeTableEntry, setTimetableEntry] = useState(timetableModel);
  const [timetable, setTimetable] = useState([]);
  const [timeTableList, setTimetableList] = useState([]);
  const [isEdit, setEdit] = useState(!isView);

  const [{ isLoading, isError, data, status }, CreateTimetable] = useApi();

  useEffect(() => {
    if (isView && selectedClass) {
      setTimetable(selectedClass.timeSlot[0].timeSlot);
      setTimetableList(selectedClass.timeSlot[0].timeSlot.map((x, idx) => idx));
    }
  }, [isView, selectedClass]);

  const handleSubmitTime = () => {
    if (!timeTableEntry.startTime || !timeTableEntry.endTime) {
      alert('Please insert time');
      return;
    }

    const newEntry = {
      ...timeTableEntry,
      startTime: moment(new Date(timeTableEntry.startTime)).format('HH:mm a'),
      endTime: moment(new Date(timeTableEntry.endTime)).format('HH:mm a'),
    };
    setTimetable([...timetable, newEntry]);
    setTimetableEntry(timetableModel);
    setTimetableList([...timeTableList, timeTableList.length]);
  };

  const handleDropdownChange = (day, type, value, index) => {
    const x = timetable;
    const y = {
      ...timetable[index],
      timeTable: timetable[index].timeTable.map((x) => {
        if (x.day === day) {
          if (type === 'teacher') {
            return {
              ...x,
              teacher: staff.filter((x) => x._id === value)[0].full_name,
              teacherId: value,
            };
          } else {
            // console.log(subjects.filter((x) => value.includes(x.subject.subjectCode)));
            return {
              ...x,
              subject: value,
              subjectId:
                value === 'Lunch Break'
                  ? 0
                  : subjects.filter((x) => value.includes(x.subject.subjectCode))[0].subject._id,
            };
          }
        } else return x;
      }),
    };
    x[index] = y;
    // console.log(x);
    setTimetable([...x]);
  };

  const handleDelete = (x) => {
    timeTableList.pop();
    timetable.splice(x, 1);
    setTimetable([...timetable]);
    setTimetableList([...timeTableList]);
  };

  const handleSubmit = () => {
    if (isView)
      CreateTimetable(
        TIMETABLE_PUT(selectedClass.classInfo._id, selectedClass.sectionInfo._id, selectedClass.timeSlot[0]._id),
        'PUT',
        {
          timeTable: timetable,
          headTeacher: selectedClass.headTeacher?._id,
        }
      );
    else
      CreateTimetable(TIMETABLE_POST(selectedClass.classInfo._id, selectedClass.sectionInfo._id), 'POST', {
        timeTable: timetable,
        headTeacher: selectedClass.headTeacher?._id,
      });
  };

  useEffect(() => {
    if (!isLoading && !isError && (status == 201 || status == 200)) {
      refreshData();
      setOpenCreate(false);
      alert('Success');
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  const handleReset = () => {
    setTimetable([]);
    setTimetableEntry(timetableModel);
    setTimetableList([]);
  };

  return (
    <div
      className="flex justify-center h-full w-full fixed left-0 top-0 overflow-auto z-50"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 text-sm m-auto rounded-2xl relative mt-24">
        <div className="border-b border-borderYellow px-6 py-3 flex justify-between items-center">
          <span className="block font-bold ">
            {!isView && `Create Timetable`} {`${selectedClass?.classInfo?.name} - ${selectedClass?.sectionInfo?.name}`}
          </span>
          <div className="flex items-center">
            {isView && (
              <button
                className={`px-12 mr-6 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  border-primary ${
                  isEdit ? 'text-primary bg-white' : 'text-white bg-primary'
                }`}
                onClick={() => {
                  setEdit((prev) => !prev);
                }}
              >
                {isEdit ? 'Cancel' : 'Edit'}
              </button>
            )}

            <img
              src="assets/img/Close.svg"
              className=" h-3 cursor-pointer"
              alt=""
              onClick={() => {
                setOpenCreate(false);
              }}
            />
          </div>
        </div>
        <div className="my-4">
          {isEdit && (
            <>
              <span className="block font-bold px-6">Add Lecture</span>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 place-items-end px-6">
                <div className="mt-2 mb-2 w-full relative font-semibold">
                  <label className="block text-textDark mb-2 text-xs">Start Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label=""
                      value={timeTableEntry?.startTime}
                      onChange={(time) => setTimetableEntry({ ...timeTableEntry, startTime: time })}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="mt-2 mb-2 w-full relative font-semibold">
                  <label className="block text-textDark mb-2 text-xs">End Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label=""
                      value={timeTableEntry?.endTime}
                      onChange={(time) => setTimetableEntry({ ...timeTableEntry, endTime: time })}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mt-2 mb-2 pr-6 w-full relative font-semibold">
                <button
                  className="w-36 border ml-auto  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-white bg-primary"
                  onClick={handleSubmitTime}
                >
                  Submit
                </button>
              </div>
            </>
          )}

          <div className={`w-full ${!isEdit ? '' : 'border-t border-borderYellow mt-4 pt-4'} px-6`}>
            <span className="block font-bold ">Timetable</span>
            <div className="w-full py-4 overflow-auto">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left  text-xs font-medium ">
                    <div className="w-1/5 border-r-2 py-2 px-2 bg-white border-b border-borderYellow">Lecture Time</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Monday</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Tuesday</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Wednesday</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Thursday</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Friday</div>
                    <div className="w-1/5 py-2 px-2">Saturday</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                  {timeTableList.map((x, idx) => {
                    return (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left text-xs font-medium items-center ${
                            idx !== timeTableList.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 px-2  h-24  border-r border-borderYellow">
                            <div className="w-11/12 m-auto h-full flex flex-col items-center justify-evenly ">
                              <span>{`${timetable[x].startTime} to ${timetable[x].endTime}`}</span>
                              {isEdit && (
                                <button
                                  className="w-4/5 border mx-auto py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary "
                                  onClick={() => {
                                    handleDelete(x);
                                  }}
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center border-r border-borderYellow">
                            <div className="w-11/12 m-auto relative">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[0].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Monday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[0].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Monday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[0].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[0].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center border-r border-borderYellow">
                            <div className="w-11/12 m-auto">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[1].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Tuesday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[1].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Tuesday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                              text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[1].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                              text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[1].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center border-r border-borderYellow">
                            <div className="w-11/12 m-auto">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[2].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Wednesday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[2].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Wednesday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                              text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[2].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                              text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[2].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center border-r border-borderYellow">
                            <div className="w-11/12 m-auto">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[3].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Thursday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[3].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Thursday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[3].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[3].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center border-r border-borderYellow">
                            <div className="w-11/12 m-auto">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[4].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Friday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[4].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Friday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[4].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[4].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="w-1/5 px-2 flex h-24 flex-col items-center ">
                            <div className="w-11/12 m-auto">
                              {isEdit ? (
                                <>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                    value={timetable[x].timeTable[5].subject || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Saturday', 'subject', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Lunch Break">Lunch Break</option>
                                    {subjects.map((x) => (
                                      <option
                                        value={`${x.subject.subjectCode} ${x.subject.subjectName}`}
                                      >{`${x.subject.subjectCode} ${x.subject.subjectName}`}</option>
                                    ))}
                                  </select>
                                  <select
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                    value={timetable[x].timeTable[5].teacherId || '--'}
                                    onChange={(z) => {
                                      handleDropdownChange('Saturday', 'teacher', z.target.value, x);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {staff.map((x) => (
                                      <option value={x._id}>{x.full_name}</option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between`}
                                  >
                                    {timetable[x].timeTable[5].subject || '--'}
                                  </p>

                                  <p
                                    className={`w-full border px-2 py-2 relative rounded-3xl text-xs 
                                 text-tableRowText border-tableRowText focus:outline-none flex items-center justify-between mt-2`}
                                  >
                                    {timetable[x].timeTable[5].teacher || '--'}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {isEdit && (
            <div className="flex">
              <button
                className="w-36 mr-6 border ml-auto py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-tableRowText text-tableRowText"
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                className="w-36 mr-6 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-white bg-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateTimeTable;
