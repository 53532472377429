import React, { useEffect, useRef } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';

Chart.register(ChartDataLabels);

const ChartB = ({ budget, spent, extra, recieved }) => {
  const myChart2 = useRef();

  useEffect(() => {
    var ctx = myChart2.current;
    if (!ctx) return;

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['BUDGET', 'SPENT', 'EXTRA SPENT', 'Recieved' ],
        datasets: [
          {
            data: [budget, spent, extra, recieved],
            backgroundColor: ['rgba(68, 152, 126, 0.4)', 'rgba(79, 176, 211, 0.4', 'rgba(194, 70, 32, 0.4)','rgb(144, 238, 144)' ],
            // hoverBackgroundColor: 'rgba(172, 173, 168, 0.25)',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 25,
          },
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            clamp: true,
            clip: false,
            formatter: (value) => {
              return `\u20B9${value}`;
            },
            color: '#000',
          },
          legend: {
            display: false,
          },
        },
        title: {
          display: false,
        },

        legend: {
          display: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    });
  }, [budget, spent, extra]);

  return <canvas ref={myChart2} height="80"></canvas>;
};

export default ChartB;
