import React, { useEffect, useState } from 'react';
import DropDown from '../../UI/DropDown';
import { SEND_RESULT_APPROVAL } from '../../../api/urls/test';
import useApi from '../../../api/useApi';

function ExaminationInchargeClassTable({
  classes,
  setOpenResultDate,
  setOpenExamScheme,
  title,
  selectClass,
  setCategory,
  refresh,
  examSchema,
}) {
  const [selectedTestType, selectTestType] = useState();
  const [filter, setFilter] = useState();

  const [response, sendForApproval] = useApi();

  const handleSubmit = (x) => {
    const body = {
      sectionId: x.sectionId,
      teacherId: x.headTeacherId,
      teacherName: x?.headTeacher,
      classType: title.split(' ').join(''),
      testType: selectedTestType,
    };
    sendForApproval(SEND_RESULT_APPROVAL(x.classId), 'POST', body);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [201, 202, 200, 204].includes(response.status)) {
      alert(response.data);
      refresh();
    }

    if (response.isError) {
      alert('FAILED');
    }
  }, [response]);

  const getStatus = (tests) => {
    return tests.reduce((acc, x) => {
      if (x.type === selectedTestType) acc = x.isResultComplete;
      return acc;
    }, '');
  };

  let _classes = classes || [];

  if (filter && filter !== 'All') {
    _classes = _classes.filter((x) => {
      if (filter.toLowerCase() === 'incomplete') {
        return !getStatus(x.testWiseData) || getStatus(x.testWiseData).toLowerCase() === 'incomplete';
      } else if (filter.toLowerCase() === 'completed') {
        return getStatus(x.testWiseData) && getStatus(x.testWiseData).toLowerCase() !== 'incomplete';
      }
    });
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-6/12">
            <p>{title}</p>
          </div>
          <div className="flex justify-end items-center w-full">
            <button
              className="w-40 mr-6 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  border-primary text-white bg-primary"
              onClick={() => {
                setOpenResultDate(true);
                setCategory(title);
              }}
            >
              Result Release Date
            </button>
            <button
              className="w-40 mr-6 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  border-primary text-white bg-primary"
              onClick={() => {
                setOpenExamScheme(true);
                setCategory(title);
              }}
            >
              Exam Scheme
            </button>
            {examSchema?.examPattern && Array.isArray(examSchema?.examPattern) && (
              <DropDown
                data={
                  examSchema?.examPattern && Array.isArray(examSchema?.examPattern)
                    ? examSchema?.examPattern.map((x) => x.type)
                    : []
                }
                width={'w-36'}
                margin={'mr-4'}
                handleItemClick={(x) => {
                  selectTestType(x);
                }}
              />
            )}
            <DropDown
              data={['All', 'Completed', 'Incomplete']}
              width={'w-36'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setFilter(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/6 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Section</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Total Students</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Class Teacher</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Action</div>
                <div className="w-1/6 py-2 px-2">Approval</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {_classes.length == 0 ? (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              ) : (
                _classes.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        idx !== _classes.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/6 px-2 text-textDark">{x.class}</div>
                      <div className="w-1/6 px-2 text-textDark">{x.section}</div>
                      <div className="w-1/6 px-2 text-textDark">{x.totalStudent}</div>
                      <div className="w-1/6 px-2 text-textDark">{x.headTeacher || 'Not Assigned'} </div>
                      <div className="w-1/6 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-11/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                          onClick={() => {
                            selectClass(x);
                            setCategory(title);
                          }}
                        >
                          View Result
                        </button>
                      </div>
                      <div className="w-1/6 px-2">
                        {getStatus(x.testWiseData) === 'complete' ? (
                          <button
                            type="submit"
                            className="border border-primary w-11/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                            onClick={() => handleSubmit(x)}
                          >
                            Push
                          </button>
                        ) : (
                          <p className="border border-borderLight w-11/12 py-2 rounded-3xl text-borderLight font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs capitalize">
                            {!getStatus(x.testWiseData) || getStatus(x.testWiseData) === 'incomplete'
                              ? 'Incomplete'
                              : getStatus(x.testWiseData)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExaminationInchargeClassTable;
