import React, { useState, useEffect, useRef } from 'react';

import useApi from '../../../api/useApi';
import { SEND_EXPENSE_JUSTIFICATION } from '../../../api/urls/finance';

function SubmitJustification({ onClose, selectedData, refresh }) {
  const reciptUpload = useRef();

  const [formData, setFormData] = useState({
    justification: '',
    justificationFile: null,
  });

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, sendJustification] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();

    if (!formData.justification) {
      alert('Please provide justification');
      return;
    }

    if (!formData.justificationFile) {
      alert('Please attach document');
      return;
    }

    fd.append('justification', formData.justification);
    fd.append('justificationFile', formData.justificationFile);

    sendJustification(SEND_EXPENSE_JUSTIFICATION(selectedData._id), 'PUT', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 202) {
      alert('Justification send successfully');
      refresh();
      onClose();
    }

    if (!isLoading && isError) {
      alert('Error in sending Justification');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 -mt-20  rounded-2xl relative">
        <div className="border-b border-borderYellow flex justify-between items-center py-4 px-6">
          <span className=" font-bold text-textBlue1 ">Submit Justification</span>
          <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={onClose} />
        </div>

        <div className="flex flex-wrap w-full px-2 pb-6">
          <form className="px-4 w-full" onSubmit={handleSubmit}>
            <div className="my-2  relative  w-full">
              <textarea
                type="text"
                autoComplete="off"
                placeholder="Write your justification here....."
                name="justification"
                rows="8"
                required
                value={formData.justification}
                onChange={handleInputChange}
                className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 relative  w-full">
              <label className="text-sm text-textDark font-medium">Upload Document</label>
              <div className="relative flex">
                <input
                  hidden
                  ref={reciptUpload}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setFormData({ ...formData, justificationFile: e.target.files[0] })}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <input
                  // required
                  type="text"
                  disabled
                  value={formData.justificationFile?.name}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                  onClick={(e) => {
                    e.preventDefault();
                    reciptUpload.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className="w-full flex justify-end">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <button
                    type="submit"
                    className="border w-32 py-2 mt-6 float-right rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    Submit
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SubmitJustification;
