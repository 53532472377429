import React, { useState, useEffect } from 'react';
import LineChart from './LineChart';
import PieChart from './PieChart';

const MentalHealth = ({ selectedStudent, toggleView }) => {
  return (
    <div className="relative w-full md:w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-5/12">
              <p>{`MentalHealth - (${selectedStudent?.full_name})`}</p>
            </div>

            <button
              className="text-xs py-2 border rounded-3xl text-white bg-primary border-primary px-8"
              onClick={toggleView}
            >
              Back
            </button>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 p-6">
          <div className="w-full py-4 overflow-auto grid grid-cols-2 gap-14">
            <div>
              <h3 className="font-semibold text-xl text-center mb-2">Stress</h3>
              <LineChart
                _data={[3, 1, 3, 2, 4, 2, 6, 2, 3, 1, 2, 4]}
                labels={['Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']}
                color={'#F27900'}
              />
            </div>
            <div>
              <h3 className="font-semibold text-xl text-center mb-2">Performance Forcast</h3>
              <LineChart
                _data={[0, 2, 0, 1, 1, 2, 1, 0, 1, 0, 0, 1]}
                color={'#009D3F'}
                yLabels={['Down', 'Neutral', 'Up']}
                labels={['Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']}
              />
            </div>
            <div>
              <h3 className="font-semibold text-xl text-center mb-2">Anxiety</h3>
              <LineChart
                _data={[3, 1, 3, 2, 0, 2, 0, 2, 3, 1, 2, 0]}
                color={'#00317A80'}
                yLabels={['Minimal', 'Mild', 'Moderate', 'Severe']}
                labels={['Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']}
              />
            </div>
            <div>
              <h3 className="font-semibold text-xl text-center mb-2">Depression</h3>
              <LineChart
                _data={[3, 1, 3, 2, 4, 2, 5, 2, 3, 1, 2, 4]}
                color={'#E7E700'}
                yLabels={['No Depression', 'Minimal', 'Mild', 'Moderate', 'Severe', 'Very Severe']}
                labels={['Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']}
              />
            </div>
            <div className="px-10">
              <h3 className="font-semibold text-xl text-center -mb-14">Depression</h3>
              <PieChart _data={[20, 40, 30, 20]} color={['#009D3F', '#E7E700', '#00317A80', '#F27900']} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentalHealth;
