import { APPOINTMENT } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const MEETING = `${APPOINTMENT}/request?year=${year}`;

export const MEETING_DETAILS = (id) => `${APPOINTMENT}/request/${id}?year=${year}`;

export const MY_CREATED_TASKS = `${APPOINTMENT}/getTask?year=${year}`;

export const TASK_CREATE = `${APPOINTMENT}/createTask?year=${year}`;

export const ASSIGNED_TASKS = `${APPOINTMENT}/getAssignedTasks?year=${year}`;

export const UPDATE_TASK = (id) => `${APPOINTMENT}/task/${id}?year=${year}`;

export const PARENT_TEACHER_MEETING = `${APPOINTMENT}/parent/request?year=${year}`;

export const UPCOMING_PARENT_TEACHER_MEETING = `${APPOINTMENT}/parentTeacher/meetings?year=${year}`;

export const MEETING_STATUS_CHANGE = (id) => `${APPOINTMENT}/request/${id}/approve?year=${year}`;
