import React, { useEffect, useState, useRef } from 'react';
import ViewPostComponent from '../../ViewPost';
import DropDown from '../../UI/DropDown';

import {
  Vector,
  Divider,
  ChargeLabel2,
  FlexWrapperFive,
  RelativeWrapperOne,
  ChargeLabelAndIcon,
  ChargeIcon,
  ChargeLabel,
  FeedHover,
  FeedHover2,
  CommentIconAndLabel,
  CommentIcon,
  CommentLabel,
  CommentSectionLabel,
  FlexWrapperOne,
  FlexWrapperSix,
  CommentProfilePicture,
  CommentUserName,
  CommentText,
  FlexWrapperTwo,
  VideoTag,
  CommentInputWrapper,
  CommentInput,
  CommentProfilePicture2,
} from './style';
import '../../../scrollbar.css';
import { COMMENT_FEED, LIKE_FEED, DELETE_FEED } from '../../../api/urls/social';
import { REPORT_POST } from '../../../api/urls/incident';

import useApi from '../../../api/useApi';
import ImageCarousel from './ImageCarousel';

const CentralFeedComponent = ({ getUserId, feed2, profile, refreshData }) => {
  const [post, setPost] = useState(false);
  const [showCommentTab, setShowCommentTab] = useState(false);
  const [postId, setPostId] = useState('');
  const [feed, setFeed] = useState(feed2);

  const [postLike, setPostLike] = useApi();
  const [postComment, setMakeComment] = useApi();
  const [reportPostState, reportPost] = useApi();
  const [deleteRes, deletePost] = useApi();

  useEffect(() => {
    setFeed(feed2);
  }, [feed2]);

  const toggleOpenFeed = () => {
    setPost((prev) => !prev);
  };

  const likePost = (id) => {
    const body = {
      postId: id,
      userId: profile.user._id,
    };
    setPostLike(LIKE_FEED, 'PUT', body);
    getUserId(profile.user._id, 'like');
  };

  const makeComment = (value, postId) => {
    const body = {
      postId,
      text: value,
      userId: profile.user._id,
    };
    getUserId(profile.user._id, 'comment');
    setMakeComment(COMMENT_FEED, 'PUT', body);
  };

  useEffect(() => {
    if (!postLike.isLoading && !postLike.isError && postLike.data) {
      const newPosts =
        Array.isArray(feed) &&
        feed.map((item) => {
          if (item._id === postLike.data._id) {
            item.likes = postLike.data.likes;
            return item;
          }
          return item;
        });
      setFeed(newPosts);
    }
  }, [postLike.isLoading, postLike.isError, postLike.data]);

  useEffect(() => {
    if (!postComment.isLoading && !postComment.isError && postComment.data) {
      const newPosts =
        Array.isArray(feed) &&
        feed.map((item) => {
          if (item._id === postComment.data._id) {
            item.likes = postComment.data.comments;
            return item;
          }
          return item;
        });
      setFeed(newPosts);
      refreshData();
    }
  }, [postComment.isLoading, postComment.isError, postComment.data]);

  const getValue = (y) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const d = new Date(y);
    let date = d.getDate();
    let hours = d.getHours();
    let min = d.getMinutes();

    let month = months[d.getMonth()];
    let time = `${hours}:${min}`;
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 || H === 24 ? 'AM' : 'PM';
    time = h + time.substr(2, 3) + ampm;
    return `${date} ${month} at ${time}`;
    {
      /* 20 July at 09:00 AM */
    }
    // return `${date} ${month} at ${hours}:${min}`
  };

  const handleReport = (postId) => {
    reportPost(REPORT_POST, 'POST', { postId });
  };

  useEffect(() => {
    if (!reportPostState.isLoading && !reportPostState.isError && reportPostState.status == 201) {
      if (reportPostState.status) {
        alert('SUCCESS');
      } else {
        alert('FAILED');
      }
    }

    if (!reportPostState.isLoading && reportPostState.isError) {
      alert('FAILED');
    }
  }, [reportPostState.isLoading, reportPostState.isError, reportPostState.status]);

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete?')) deletePost(DELETE_FEED(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 204) {
      refreshData();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes.isLoading, deleteRes.isError, deleteRes.status]);

  const isYouTubeURL = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  const extractYouTubeVideoId = (url) => {
    const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;
    const match = url.match(videoIdRegex);
    return match ? match[1] : '';
  };

  return (
    <div className="w-full md:w-3/4">
      {Array.isArray(feed) &&
        feed.map((x) => {
          const isYouTubeVideo = isYouTubeURL(x?.summary);
          let videoThumbnail = '';
          if (isYouTubeVideo) {
            const videoId = extractYouTubeVideoId(x?.summary);
            videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
          }
          return (
            <div className=" bg-white rounded-2xl mb-4 flex flex-col shadow-sm overflow-hidden">
              <div className="w-full mb-2 flex items-start">
                <img
                  className="w-16 h-16 rounded-br-2xl"
                  alt=""
                  src={getFile(x?.userId, 'profileImg') || '/assets/img/user_placeholder.svg'}
                />
                <div className="flex flex-col ml-3">
                  <p className="text-black mt-1 mb-0 pb-0">{x?.userId?.full_name}</p>
                  <p className="text-tableRowText text-xs mb-0 pb-0">{x?.institute_id?.name}</p>
                  <p className="text-xs font-bold text-green-500 mb-0 pb-0">{getValue(x.createdAt)}</p>
                </div>
                <div className="ml-auto mr-5 mt-3.5 cursor-pointer">
                  {profile?.user?.currentlyMapped?.role?.some((c) => ['ad', 'pr', 'ow', 'te'].includes(c)) ? (
                    <DropDown
                      data={['Report Post', 'Delete']}
                      width={'w-full'}
                      src="/assets/img/options.svg"
                      handleItemClick={(z) => {
                        if (z === 'Report Post') handleReport(x._id);
                        else if (z === 'Delete') {
                          handleDelete(x._id);
                        }
                      }}
                    />
                  ) : (
                    <DropDown
                      data={['Report Post']}
                      width={'w-full'}
                      src="/assets/img/options.svg"
                      handleItemClick={(z) => {
                        handleReport(x._id);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="pl-6 pr-4 mt-2">
                <p className="text-lg text-black whitespace-pre-line">{x.summary}</p>
              </div>

              <FlexWrapperTwo>
                {x.images.length !== 0 ? <ImageCarousel images={x.images} /> : null}
                {videoThumbnail ? (
                  <img
                    src={videoThumbnail}
                    onClick={() => {
                      window.open(x.summary, '_blank');
                    }}
                    className="cursor-pointer"
                  />
                ) : null}
                {x.videos.length !== 0 ? <VideoTag alt={x.videos[0].name} src={x.videos[0].location} controls /> : null}
              </FlexWrapperTwo>
              <Divider />
              <div className="flex justify-between px-6 py-3">
                <div className="flex">
                  <img alt="" src="https://static.overlay-tech.com/assets/e6a55646-8cd5-4651-843c-967712ab46bd.svg" />
                  <p className="text-xs text-tableRowText ml-2"> {x.likes.length} Charged</p>
                </div>
                <p className="text-xs text-tableRowText">{x.comments.length} Comment</p>
              </div>
              <Divider />
              <FlexWrapperFive>
                <RelativeWrapperOne onClick={() => likePost(x._id)}>
                  {x.likes.some((value) => value.userId === profile.user._id) ? (
                    <FeedHover>
                      <ChargeLabelAndIcon>
                        <ChargeIcon>
                          <Vector alt="" src="assets/img/charge.svg" style={{ width: '8px', height: 'auto' }} />
                        </ChargeIcon>
                        <ChargeLabel>Charged </ChargeLabel>
                      </ChargeLabelAndIcon>
                    </FeedHover>
                  ) : (
                    <FeedHover2>
                      <ChargeLabelAndIcon>
                        <ChargeIcon>
                          <Vector alt="" src="assets/img/charge_black.svg" style={{ width: '8px', height: 'auto' }} />
                        </ChargeIcon>
                        <ChargeLabel2>Charge</ChargeLabel2>
                      </ChargeLabelAndIcon>
                    </FeedHover2>
                  )}
                </RelativeWrapperOne>
                <CommentIconAndLabel
                  onClick={() => {
                    setShowCommentTab((prev) => !prev);
                    setPostId(x._id);
                  }}
                >
                  <CommentIcon>
                    <Vector alt="" src="assets/img/comment.svg" style={{ width: '15px', height: 'auto' }} />
                  </CommentIcon>
                  <CommentLabel>Comment</CommentLabel>
                </CommentIconAndLabel>
              </FlexWrapperFive>

              {x.comments.length > 0 && <Divider />}

              {x.comments.length > 0 && (
                <>
                  <CommentSectionLabel>Comments</CommentSectionLabel>
                  <div className="overflow-auto customScrollBar pr-2">
                    {x.comments.map((comm) => (
                      <FlexWrapperOne>
                        <FlexWrapperSix>
                          <CommentProfilePicture alt="" src={getFile(comm?.postedBy, 'profileImg')} />
                          <CommentUserName>
                            {comm?.postedBy?.full_name}{' '}
                            <span className="text-xs text-textLight">({comm?.postedBy?.username})</span>
                          </CommentUserName>
                        </FlexWrapperSix>
                        <CommentText>{comm.text}</CommentText>
                      </FlexWrapperOne>
                    ))}
                  </div>
                </>
              )}
              {showCommentTab && postId === x._id && (
                <form
                  style={{ width: '100%' }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    makeComment(e.target[0].value, x._id);
                    e.target[0].value = '';
                  }}
                >
                  <CommentInputWrapper>
                    <CommentProfilePicture2
                      alt=""
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHTrTXBrPLFrP2Kt5_9ne8YvUaBahWmL4QiPxW03U&s"
                    />
                    <CommentInput placeholder="Write your comment here" className="postText" />
                  </CommentInputWrapper>
                </form>
              )}
            </div>
          );
        })}
      {post && <ViewPostComponent handleClose={toggleOpenFeed} />}
    </div>
  );
};

export default CentralFeedComponent;
