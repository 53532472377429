import React, { useEffect } from 'react';
import useApi from '../../../api/useApi';
import { ATTENDANCE_REGULARIZATION_REQUESTS, UPDATE_REGULARIZATION_REQUEST } from '../../../api/urls/attendance';
import moment from 'moment';

function AttendanceRegularization({ data }) {
  const [arRes, getArRequests] = useApi();
  const [approveRes, approveRequests] = useApi();

  useEffect(() => {
    getArRequests(ATTENDANCE_REGULARIZATION_REQUESTS(''), 'GET');
  }, []);

  const handleApprove = (item) => {
    approveRequests(UPDATE_REGULARIZATION_REQUEST(item?._id), 'PUT');
  };

  useEffect(() => {
    if (!approveRes.isLoading && !approveRes.isError && approveRes.status === 200) {
      alert('Successfully approved');
      getArRequests(ATTENDANCE_REGULARIZATION_REQUESTS(''), 'GET');
    }

    if (!approveRes.isLoading && approveRes.isError) {
      alert('Failed to approve');
    }
  }, [approveRes]);

  const filteredData = arRes?.data || [];

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>Attendance Regularization</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            {/* <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'leaves.csv'} text={'Export Data'} />

        <DropDown
          data={['All', 'Pending', 'Approved', 'Rejected']}
          width={'w-1/4'}
          title={'Sort By'}
          handleItemClick={(x) => {
            setSort(x);
          }}
        /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Date</div>
                <div className="w-1/6 border-r-2 py-2 px-2">In Time</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Out Time</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Reason</div>
                <div className="w-1/6 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/6 px-2 text-textDark ">{x.userId?.full_name}</div>
                        <div className={`w-1/6 px-2 text-textDark `}>{x.date ? moment(x.date).format('ll') : '--'}</div>
                        <div className={`w-1/6 px-2 text-textDark `}>
                          {x?.inTime ? moment(new Date(`2/2/2020 ${x?.inTime} UTC`)).format('hh:mm A') : '--'}
                        </div>
                        <div className={`w-1/6 px-2 text-textDark `}>
                          {x?.outTime ? moment(x?.outTime).format('hh:mm A') : '--'}
                        </div>
                        <div className="w-1/6 px-2 text-textDark ">{x?.reason}</div>
                        <div className={`w-2/12 px-2 `}>
                          {/* {x.status ? (
                            'Approved'
                          ) : x.rejected ? (
                            'Rejected'
                          ) : ( */}
                          <button
                            className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-borderGrey text-tableRowText"
                            onClick={() => handleApprove(x)}
                          >
                            Approve
                          </button>
                          {/* // )} */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceRegularization;
