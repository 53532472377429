import Cookies from 'js-cookie';

const x = Cookies.get('auth');

export const initialState = {
  token: x ? JSON.parse(x) : null,
  classSections: [],
  user: null,
  validRole: false,
  institutes: [],
  openMenu: false,
  openSocialMenu: false,
  session: sessionStorage.getItem('session') || '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOKEN':
      return {
        ...state,
        token: action.token,
      };
    case 'CLASS_SECTION':
      return {
        ...state,
        classSections: action.payload,
      };
    case 'USER':
      return {
        ...state,
        user: action.user,
      };
    case 'VALID_ROLE':
      return {
        ...state,
        validRole: action.validRole,
      };
    case 'INSTITUTES':
      return {
        ...state,
        institutes: action.payload,
      };
    case 'TOGGLE_MENU':
      return {
        ...state,
        openMenu: action.openMenu,
      };
    case 'TOGGLE_SOCIAL_MENU':
      return {
        ...state,
        openSocialMenu: action.openSocialMenu,
      };
    case 'SET_SESSION':
      return {
        ...state,
        session: action.session,
      };
    case 'LOG_OUT':
      return {
        ...state,
        token: null,
        classSections: [],
        user: null,
        validRole: false,
        institutes: [],
        openMenu: false,
        openSocialMenu: false,
        session: '',
      };

    default:
      return state;
  }
};

export default reducer;
