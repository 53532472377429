import React, { useEffect, useState } from 'react';
import moment from 'moment';

import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { MARK_ATTENDANCE } from '../../../api/urls/attendance';

function MarkAttendance({ setMarkAttendance, todayAttendance, refreshList, additionalResponsibilty }) {
  const [data, setData] = useState([]);

  const [state, markAttendance] = useApi();

  useEffect(() => {
    if (todayAttendance && todayAttendance?.data?.attendance) {
      let y = todayAttendance?.data?.attendance.map((x) => {
        return {
          ...x,
          pa: todayAttendance?.data?.isMarked
            ? x.leave
              ? false
              : x.isPresent
              ? true
              : false
            : x.leave
            ? false
            : x.isPresent
            ? true
            : false,
        };
      });
      setData(y);
    }
  }, [todayAttendance]);

  const handleMark = (e, studentId) => {
    let studentIndex = data.findIndex((x) => x._id === studentId);
    let y = data[studentIndex];
    let x = {
      ...y,
      pa: !y.pa,
    };
    data[studentIndex] = x;
    setData([...data]);
  };

  const handleRemarks = (e, studentId) => {
    let studentIndex = data.findIndex((x) => x._id === studentId);
    let y = data[studentIndex];
    let x = {
      ...y,
      remarks: e.target.value,
    };
    data[studentIndex] = x;
    setData([...data]);
  };

  const handleSubmit = () => {
    const user = data.map((x) => {
      return {
        id: x._id,
        pa: x.pa,
        remarks: x.remarks,
      };
    });

    const body = {
      users: user,
      date: moment(new Date()).format('YYYY-MM-DD'),
    };

    let info = [];
    if (additionalResponsibilty && additionalResponsibilty.length > 0) {
      info = additionalResponsibilty.filter((x) => x.category === 'Class Teacher');
    }

    markAttendance(MARK_ATTENDANCE(info[0]?.classID?._id, info[0]?.sectionID?._id), 'POST', body);
  };

  useEffect(() => {
    if (!state.isLoading && !state.isError && state.status) {
      if ([200, 201].includes(state.status)) {
        refreshList();
        setMarkAttendance(false);
        alert('Attendance Marked Successfully');
      }
    }

    if (!state.isLoading && state.isError) {
      alert('Something went wrong');
    }
  }, [state.isLoading, state.isError, state.data]);

  return (
    <div
      className="customScrollBar flex justify-center items-start h-screen pb-10 overflow-auto w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-11/12 md:w-6/12 block m-auto rounded-2xl relative mt-10 ">
        <div className="border-b border-borderYellow flex items-center">
          <span className="block font-bold py-4 px-6 text-textBlue1 ">Today's Attendance</span>

          <DropDown data={['All']} width={'w-40'} title={'Sort By'} margin={'ml-auto'} handleItemClick={() => {}} />

          <img
            src="assets/img/Close.svg"
            className="h-3 cursor-pointer mx-6"
            alt=""
            onClick={() => setMarkAttendance(false)}
          />
        </div>

        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border border-borderYellow overflow-hidden rounded-2xl min-w-700"
              style={{
                width: '96%',
              }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex  w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Roll No.</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">A/P</div>
                  <div className="w-5/12 py-2 px-2">Remark</div>
                </div>
              </div>
              <div>
                {Array.isArray(data) &&
                  data.map((x) => (
                    <div className="w-full" key={x.userName}>
                      <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center border-b border-borderYellow">
                        <div className="w-2/12 px-2 text-textDark">{x?.rollNo}</div>
                        <div className="w-3/12 px-2 text-textDark">{x.userName}</div>
                        <div className="w-3/12 px-2 text-textDark">{x.name}</div>
                        <div className="w-2/12 px-2 text-textDark">
                          <label className="switch" style={{ opacity: x.leave ? 0.3 : 1 }}>
                            <input
                              type="checkbox"
                              disabled={x.leave}
                              checked={x.pa}
                              onChange={(e) => handleMark(e, x._id)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="w-5/12 px-2">
                          <input
                            type="text"
                            value={x.remarks}
                            onChange={(e) => handleRemarks(e, x._id)}
                            className="p-2 border rounded-2xl w-full"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 mb-4">
          <button
            className="border w-40 py-2 rounded-3xl text-primary border-primary text-xs mx-4 focus:outline-none flex justify-center text-center items-center"
            onClick={() => setMarkAttendance(false)}
          >
            Reset
          </button>
          <button
            className="border w-40 py-2 rounded-3xl bg-primary border-primary text-xs text-white mx-4 focus:outline-none flex justify-center text-center items-center"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default MarkAttendance;
