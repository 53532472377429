import React, { useEffect, useState } from 'react';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import moment from 'moment';

const excelHeaders = [
  { label: 'Date', key: 'date' },
  { label: 'Absent / Present', key: 'absent_present' },
];

function OpenAttendanceStudent({ toggleView, selectedStudent }) {
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (selectedStudent?.attendance && Array.isArray(selectedStudent.attendance)) {
      const data = selectedStudent.attendance;
      let x = data.map((x) => {
        return {
          date: x?.date ? moment(x.date).format('ll') : '--',
          absent_present: x?.isPresent ? 'Present' : 'absent',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [selectedStudent]);

  return (
    <div
      className="flex justify-center  h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-1/2 text-sm m-auto rounded-2xl relative mt-20 pb-6">
        <div className="flex w-full justify-between py-3 items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 text-textBlue1">{`${selectedStudent.userInfo.userInfo.username} - ${selectedStudent.userInfo.userInfo.full_name}`}</p>
          <div className="flex gap-x-6 items-center">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'attendance.csv'} text={'Export Data'} />
            <img
              src="assets/img/Close.svg"
              className=" mr-4 h-3 cursor-pointer z-50"
              alt=""
              onClick={() => {
                toggleView();
              }}
            />
          </div>
        </div>
        <div className="w-full mt-6 px-6">
          <div className=" flex w-full">
            <div className="w-full border rounded-2xl border-borderYellow ">
              <div className="w-full flex bg-tableHeader rounded-3xl py-1">
                <div className="flex w-full relative text-xs font-medium px-4">
                  <div className="w-2/3 border-r-2 py-1 px-2">Date</div>
                  <div className="w-1/3 py-1 px-2">Absent / Present</div>
                </div>
              </div>
              <div className="overflow-auto customScrollBar">
                {selectedStudent?.attendance && selectedStudent.attendance.length > 0 ? (
                  selectedStudent.attendance.map((x, idx) => (
                    <div key={x?._id} className="w-full py-1">
                      <div
                        className={`justify-center  flex w-full relative py-3.5 px-4 text-xs font-medium ${
                          selectedStudent.attendance.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/3 px-2 text-tableRowText">
                          {x?.date ? moment(x.date).format('ll') : '--'}
                        </div>
                        <div className="w-1/3 px-2 text-textDark text-left">{x?.isPresent ? 'Present' : 'Absent'}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenAttendanceStudent;
