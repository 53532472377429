import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStateValue } from '../../ContextApi/StateProvider';
import useApi from '../../api/useApi';
import { GET_SESSIONS } from '../../api/urls/institute';

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();
  const [{ user, openMenu }, dispatch] = useStateValue();
  const [sessions, getSessions] = useApi();

  useEffect(() => {
    if (user) getSessions(GET_SESSIONS, 'GET');
  }, [user]);

  const ownerList = [
    'Academics',
    'Academic Calender',
    'Students',
    'Staff',
    'Budget & Transactions',
    'Meeting Request',
    'Task Board',
    'Manage Leaves',
    'Manage Calender',
    'Notices',
    'Manage Feedback',
    'Transport',
  ];

  let financeList = [
    'Academic Calender',
    'Staff and Salaries',
    'Class Wise Fee Structure',
    'Expense Ledger',
    'Record Fee Payment',
    'Fee',
    'Apply For Leave',
    'Meeting Request',
    'Task Board',
    'Share Feedback',
    'Attendance',
  ];

  if (user?.institute?.transportFee === 'Fixed') {
    financeList.push('Transport Fee');
  }

  if (!user?._instituteFeeStructure?.feeStructure.includes('Challan')) {
    financeList = financeList.filter((x) => x !== 'Fee');
  }

  const teacherList = [
    'Course Handout',
    'Academic Calender',
    'Syllabus',
    'Lecture Plan',
    'Attendance',
    'My Attendance / Leaves',
    // 'Lectures',
    'Live Sessions',
    'Home Work',
    'Assignments and Test',
    'Marks',
    'Events/Timetable',
    'Remarks',
    'Report Incident',
    'Meeting Request',
    'Task Board',
    'Share Feedback',
  ];

  const parentList = [
    'Academic Calender',
    'Lecture Plan',
    'Attendance',
    'Events/Timetable',
    'Course Details',
    // 'Lecture Summary',
    'Live Sessions',
    'Home Work',
    'Assignment and Evaluation',
    'Marks and Result',
    'Share Feedback',
    'Fee',
    'Meeting / Remarks',
  ];

  const adminList = [
    'Academic Calender',
    'Academics',
    'Student Management',
    'Staff Management',
    'Attendance Report',
    'Budget and Expenses',
    'Events',
    'Manage Leaves',
    'Incident Reports',
    'Transport Routes',
    'Student Report',
    'Meeting Request',
    'Feedback',
  ];

  const principalList = [
    'Academics',
    'Academic Calender',
    'Attendance Report',
    'Students',
    'Teaching Staff',
    'Admissions',
    'Manage Leaves',
    'Incident Reports',
    'Meeting Request',
    'Student Report',
    'Task Board',
    'Share Notice',
    'Feedback',
    'Release Result',
  ];

  const studentList = [
    'Academic Calender',
    'Lecture Plan',
    'Attendance',
    'Events/Timetable',
    'Course Details',
    // 'Lecture Summary',
    'Live Sessions',
    'Home Work',
    'Assignment and Evaluation',
    'Report Card',
    'Share Feedback',
    'Fee ',
  ];

  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (location && openMenu) {
      dispatch({
        type: 'TOGGLE_MENU',
        openMenu: false,
      });
    }
  }, [location]);

  useEffect(() => {  // console.log(" -- sessions : ", sessions );
    if (sessions?.data?.list) { // console.log(" -- from mem ", sessionStorage.getItem('session') , "\n -- from server : " , sessions?.data )
      if (!sessionStorage.getItem('session')) { // on user login --> new session_id should be loaded
        const _s = sessions?.data?.list[0]._id ; // .sort((a, b) => (b.startDate < a.startDate ? -1 : 1))[0]?._id;
        sessionStorage.setItem('session', _s);
        dispatch({
          type: 'SET_SESSION',
          session: _s,
        });
      }
    }
  }, [sessions]);

  let menu = [];
  if (location.pathname === '/finance') menu = financeList;
  else if (location.pathname === '/teacher') menu = teacherList;
  else if (location.pathname === '/parents') menu = parentList;
  else if (location.pathname === '/admin') menu = adminList;
  else if (location.pathname === '/principal') menu = principalList;
  else if (location.pathname === '/student') menu = studentList;
  // 
  //  console.log(" --- \n", location.search.split('=')[1]?.indexOf( '' ) ) ;

  const sessionDropDown = () => {
    return (
      <div className="relative w-full p-3 border-b border-borderYellow ">
        <div className="w-full border border-gray-400 pl-3 py-2 rounded-3xl text-xs text-gray-500 focus:outline-none flex justify-between items-center pr-3">
          <select
            className="w-full outline-none"
            onChange={(e) => {
              sessionStorage.setItem('year', e.target.value.split(',')[1]);
              sessionStorage.setItem('session', e.target.value.split(',')[0]);
              sessionStorage.setItem('isActive', e.target.value.split(',')[2]);
              window.location.reload();
            }}
            value={sessionStorage.getItem('session') + ',' + sessionStorage.getItem('year') + ',' + sessionStorage.getItem('isActive') || ''}
          >
            {sessions?.data?.list && Array.isArray(sessions.data?.list)
              ? sessions?.data?.list
                  // ?.sort((a, b) => (b.startDate < a.startDate ? -1 : 1))
                  ?.map((x) => {
                    let start, end , isActive ;
                    start = new Date(x.startDate).getFullYear();
                    end = new Date(x.endDate).getFullYear();
                    isActive = x.isActive ? "1" : "0" ;
                    return (
                      <option value={x?._id + ',' + start + ',' + isActive } key={x._id}>
                        {`Academic Year ${start} - ${end} ${isActive == "1" ? " ( Active )" : ""} ` }
                      </option>
                    );
                  })
              : null}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`w-11/12 md:w-18 md:mt-24 h-full absolute md:relative top-0 left-0 md:z-0 z-50 md:mb-6 ${
        location.pathname !== '/social' ? 'bg-white' : 'bg-transparent'
      } h-full rounded-r-xl text-center noScroll ${openMenu ? 'block' : 'hidden md:block'}`}
    >
      {openMenu && (
        <img
          src="assets/img/Close.svg"
          className="block md:hidden w-4 ml-auto mt-6 mr-6 mb-4"
          onClick={() =>
            dispatch({
              type: 'TOGGLE_MENU',
              openMenu: false,
            })
          }
        />
      )}
      {location.pathname === '/social' || location.pathname === '/' ? null : location.pathname === '/owner' ? (
        <>
          {sessionDropDown()}
          <div className="text-left p-3 w-full" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div
              className={`p-2 px-4 font-semibold rounded-3xl text-sm cursor-pointer w-full ${
                location.search === '' ? 'bg-primary text-white' : 'bg-white text-gray-500'
              }`}
              onClick={(e) => history.push(`${location.pathname}`)}
            >
              Owner
            </div>
          </div>
          <div
            className={`${
              showList ? 'border-b-2' : ''
            } mt-6 justify-between items-center mx-6 pb-3 text-left relative flex font-semibold cursor-pointer`}
            onClick={() => setShowList((prev) => !prev)}
          >
            <div>{user?.institute?.name}</div>
            {!showList && <img src="assets/img/expandIcon.svg" className=" w-3 top-2" />}
            {showList && <img src="assets/img/collapse.svg" className=" w-3 top-0" />}
          </div>
          {showList &&
            ownerList.map((x) => (
              <div
                className={`mx-6 my-2 relative flex font-semibold cursor-pointer ${
                  // location.search.split('=')[1]?.indexOf( x.replaceAll(' ', '') ) !== -1  
                  location.search.includes( x.replaceAll(' ', '') )
                    ? 'bg-primary text-white rounded-3xl'
                    : 'text-gray-500'
                }`}
              >
                <div
                  className="py-2 px-4 text-sm text-left"
                  onClick={(e) => history.push(`${location.pathname}?menu=${x.replaceAll(' ', '')}`)}
                >
                  {x}
                </div>
              </div>
            ))}
        </>
      ) : (
        <>
          {sessionDropDown()}
          <div className="text-left p-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div
              className={`p-2 px-4 rounded-3xl text-sm cursor-pointer ${
                location.search === '' ? 'bg-primary text-white' : 'bg-white text-gray-500'
              }`}
              onClick={(e) => history.push(`${location.pathname}`)}
            >
              Dashboard
            </div>
          </div>
          {menu.map((x) => (
            <div
              className={`mx-6 my-2 relative flex font-semibold cursor-pointer ${
                location.search.split('=')[1] === x.replaceAll(' ', '')
                  ? 'bg-primary text-white rounded-3xl'
                  : 'text-gray-500'
              }`}
            >
              <div
                className="py-2 px-4 text-sm text-left"
                onClick={(e) => history.push(`${location.pathname}?menu=${x.replaceAll(' ', '')}`)}
              >
                {x}
              </div>
            </div>
          ))}

          {(location.pathname === '/teacher' || location.pathname === '/principal') && (
            <div className="text-left p-3" style={{ borderTop: '0.71px solid #E7E700' }}>
              {location.pathname === '/principal' && (
                <div
                  className={`p-2 px-4 rounded-3xl text-sm cursor-pointer ${
                    location.search.includes('additional-responsibilities')
                      ? 'bg-primary text-white'
                      : 'bg-white text-gray-500'
                  }`}
                  onClick={(e) => history.push(`${location.pathname}?menu=additional-responsibilities`)}
                >
                  Additional Responsibilities / Roles
                </div>
              )}
              {user.user.currentlyMapped?.additionalResponsibilty &&
                user.user.currentlyMapped?.additionalResponsibilty.map((x) => (
                  <div
                    className={`p-2 mb-1 px-4 rounded-3xl text-sm cursor-pointer ${
                      location.search.includes(x.category.split(' ').join(''))
                        ? 'bg-primary text-white'
                        : 'bg-white text-gray-500'
                    }`}
                    onClick={(e) => history.push(`${location.pathname}?menu=${x.category.split(' ').join('')}`)}
                  >
                    {x.category}
                  </div>
                ))}
              {/* <div
                className={`p-2 mb-1 px-4 rounded-3xl text-sm cursor-pointer ${
                  location.search.includes('MentalHealth') ? 'bg-primary text-white' : 'bg-white text-gray-500'
                }`}
                onClick={(e) => history.push(`${location.pathname}?menu=${'MentalHealth'}`)}
              >
                {'Mental Health'}
              </div>
              <div
                className={`p-2 mb-1 px-4 rounded-3xl text-sm cursor-pointer ${
                  location.search.includes('EdTech') ? 'bg-primary text-white' : 'bg-white text-gray-500'
                }`}
                onClick={(e) => history.push(`${location.pathname}?menu=${'EdTech'}`)}
              >
                {'EdTech'}
              </div>
              <div
                className={`p-2 mb-1 px-4 rounded-3xl text-sm cursor-pointer ${
                  location.search.includes('TextExtraction') ? 'bg-primary text-white' : 'bg-white text-gray-500'
                }`}
                onClick={(e) => history.push(`${location.pathname}?menu=${'TextExtraction'}`)}
              >
                {'Text Extraction'}
              </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Sidebar;
