import React, { useCallback, useEffect, useState } from 'react';
import {
  CreatePostWindow,
  FlexWrapperFive,
  CreatePostMainLabel,
  RelativeWrapperOne,
  Vector,
  CloseWindowIcon,
  Divider,
  PostTextAffordance,
  DividerTwo,
  FlexWrapperSix,
  SelectSchoolLabel,
  SelectMultipleAffordance,
  FlexWrapperSeven,
  FlexWrapperTwo,
  SchoolCheckboxIcon,
  SchoolName1,
  FlexWrapperEight,
  SchoolName2,
  FlexWrapperNine,
  SchoolNAme3,
  FlexWrapperOne,
  PostButtonLabel,
  CreatePostWrapper,
} from './style';
import '../../../scrollbar.css';
import useApi from '../../../api/useApi';
import { CREATE_TEXT_FEEDS } from '../../../api/urls/social';

const CreatePostWindowComponent = ({ handleClose, refreshData, institutes }) => {
  const [postData, setPostData] = useState({
    summary: '',
  });
  const [createPost, setCreatePost] = useApi();

  const handleInputChange = useCallback((e) => setPostData({ ...postData, [e.target.name]: e.target.value }));

  const postFeed = (e) => {
    e.preventDefault();

    if (!postData.summary) {
      alert('Please enter something in the input field');
      return;
    }
    setCreatePost(CREATE_TEXT_FEEDS, 'POST', postData);
  };

  useEffect(() => {
    if (!createPost.isLoading && !createPost.isError && createPost.status == 200) {
      refreshData();
      handleClose();
    }
  }, [createPost]);

  return (
    <CreatePostWrapper className="customScrollBar">
      <CreatePostWindow onSubmit={postFeed}>
        <FlexWrapperFive>
          <CreatePostMainLabel>Create Post</CreatePostMainLabel>
          <RelativeWrapperOne onClick={() => handleClose()}>
            <CloseWindowIcon
              alt=""
              src="https://static.overlay-tech.com/assets/8b4f28ec-df94-4fae-959a-3b429c763fd7.svg"
            />
          </RelativeWrapperOne>
        </FlexWrapperFive>
        <Divider />
        <PostTextAffordance
          autoFocus
          className="postText"
          placeholder=" Write your post here..."
          type="text"
          name="summary"
          rows={10}
          onChange={handleInputChange}
        />

        {/* </PostTextAffordance> */}
        <Divider />
        {/* <FlexWrapperSix>
          <SelectSchoolLabel>Select School</SelectSchoolLabel>
          <SelectMultipleAffordance>(Select Multiple)</SelectMultipleAffordance>
        </FlexWrapperSix>

        <div className="pl-10">
          {institutes.map((x) => (
            <div key={x?._id} className="mb-2 flex items-center">
              <input type="checkbox" className="mr-3 mt-1" />
              <span className="text-base" style={{ color: '#212121B8' }}>
                {x?.name}
              </span>
            </div>
          ))}
        </div> */}

        {createPost.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 ml-auto mr-6" alt="" />
        ) : (
          <FlexWrapperOne className="postText">
            <PostButtonLabel>Post</PostButtonLabel>
          </FlexWrapperOne>
        )}
      </CreatePostWindow>
    </CreatePostWrapper>
  );
};

export default CreatePostWindowComponent;
