import React from 'react';
import moment from 'moment';
import { useStateValue } from '../../../ContextApi/StateProvider';
import DropDown from '../../../components/UI/DropDown';

function RemarkHistory({ select, data }) {
  const [{ classSections }, dispatch] = useStateValue();

  const getClassSectionName = (classId, sectionId) => {
    if (classId && sectionId) {
      const className = classSections && classSections.length > 0 && classSections?.filter((x) => x.id === classId)[0];
      // console.log({ className,  });
      if (className) {
        // console.log({ className });

        const sectionsName = className?.sections?.filter((x) => x.id === sectionId)[0];

        return `${className?.name} - ${sectionsName?.name}`;
      } else return '--';
    }
    return '';
  };

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-5/12">
            <p>Remark History</p>
          </div>

          <div className="relative mx-2 w-1/2 md:w-2/12">
            <DropDown data={['List View']} width={'w-full'} handleItemClick={() => {}} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl overflow-hidden border-borderYellow min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-3/12 border-r-2 py-2 px-2">Remark Date</div>
                <div className="w-4/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Class</div>
                <div className="w-3/12 py-2 px-2">Remark</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {data.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : Array.isArray(data.data) && data.data.length > 0 ? (
                data?.data?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs font-medium items-center ${
                        idx !== data.data.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-3/12 px-2 text-tableRowText"> {moment(x.date).format('D MMMM YYYY')}</div>
                      <div className="w-4/12 px-2 text-textDark">{x?.student_id?.full_name}</div>
                      <div className="w-3/12 px-2 text-textDark">{x?.student_id?.username}</div>
                      <div className="w-3/12 px-2 text-textDark">{getClassSectionName(x.classId, x.sectionId)}</div>
                      <div className="w-3/12 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-10/12 px-8 py-2 rounded-3xl text-primary text-xs mx-1 focus:outline-none flex justify-center text-center items-center"
                          onClick={() => select(x)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemarkHistory;
