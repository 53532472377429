import { INSTITUTE } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const ALL_INSTITUTES = `${INSTITUTE}/getAllInstitute?year=${year}`;

export const UPDATE_INSTITUTE = (id) => `${INSTITUTE}/institution/${id}?year=${year}`;

export const CLASS = `${INSTITUTE}/class?year=${year}`;

export const SECTION = (classId) => `${INSTITUTE}/class/${classId}/section?year=${year}`;

export const SUBJECT = (classId) => `${INSTITUTE}/class/${classId}/subject?year=${year}`;
export const SUBJECT_EDIT = (classId, subId) => `${INSTITUTE}/class/${classId}/subject/${subId}?year=${year}`;

export const ALL_SECTIONS = `${INSTITUTE}/class/sections?year=${year}`;

export const DELETE_CLASS = (classId) => `${INSTITUTE}/class/${classId}?year=${year}`;

export const DELETE_SUBJECT = (classId, subId) => `${INSTITUTE}/class/${classId}/subject/${subId}?year=${year}`;

export const UMMAPPED_RESPONSIBILITY = `${INSTITUTE}/additional/responsibilty/unMapped?year=${year}`;

export const MAPPED_RESPONSIBILITY = `${INSTITUTE}/additional/responsibilty/mapped?year=${year}`;

export const MAP_RESPONSIBILITY = `${INSTITUTE}/additionalResponsibilty?year=${year}`;

export const EDIT_ADDITIONAL_RESPONSIBILITY = (id, category) =>
  `${INSTITUTE}/additional/responsibilty/${id}/${category}?year=${year}`;

export const CLASS_SECTIONS = `${INSTITUTE}/all/class/section?year=${year}`;

export const CLASS_SUBJECTS = (classId, sectionId) =>
  `${INSTITUTE}/all/class/${classId}/section/${sectionId}/subject?year=${year}`;

export const EXAM_PATTERN_POST = `${INSTITUTE}/examPattern?year=${year}`;

export const ALL_EXAM_PATTERNS = `${INSTITUTE}/examPattern/all?year=${year}`;

export const EXAM_PATTERN_GET = (categoryId) => `${INSTITUTE}/examPattern/category/${categoryId}?year=${year}`;

export const CLASS_SUBJECTS_MARKS = (classId, secId, subjectId) =>
  `${INSTITUTE}/all/class/${classId}/section/${secId}/subject/${subjectId}?year=${year}`;

export const SECTION_STUDENTS = (classId, sectionId) =>
  `${INSTITUTE}/class/${classId}/section/${sectionId}/student?year=${year}`;

export const STUDENT_REPORT = (
  classId,
  sectionId,
  studentId,
  assignmaent,
  parentInfo,
  address,
  lastSchool,
  leaves,
  attendance
) =>
  `${INSTITUTE}/class/${classId}/section/${sectionId}/student/${studentId}?year=${year}&assignment=${assignmaent}&parentDetail=${parentInfo}&address=${address}&lastSchool=${lastSchool}&studentLeave=${leaves}&studentAttendance=${attendance}`;

export const MAP_ROLE = `${INSTITUTE}/mapRole?year=${year}`;

export const REMOVE_ROLE = (userId, role) => `${INSTITUTE}/role/${userId}/${role}?year=${year}`;

export const INSTITUTE_FEEDBACK = `${INSTITUTE}/instituteRemarks?year=${year}`;

export const GET_SESSIONS = `${INSTITUTE}/session?year=${year}`;

export const HOLIDAY_LIST = `${INSTITUTE}/getAcademicHolidayList?year=${year}`;

export const CREATE_CALANDER = (role) => `${INSTITUTE}/institute/calender?role=${role}&year=${year}`;

export const UPDATE_CALANDER_EVENT = (eventId) => `${INSTITUTE}/event/${eventId}/calender?year=${year}`;

export const UPDATE_CALANDER_WORKINGDAY = (eventId) => `${INSTITUTE}/event/${eventId}/workingDay?year=${year}`;

export const UPDATE_CALANDER_EXAMS = (calenderId) => `${INSTITUTE}/calender/${calenderId}/examDates?year=${year}`;

export const GET_CALANDER = `${INSTITUTE}/institute/calender?year=${year}`;
