import { INCIDENTS } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const INCIDENT = INCIDENTS;

export const REPORT_POST = `${INCIDENT}/reportIncident?year=${year}`;

export const UPDATE_INCIDENT = (id) => `${INCIDENT}/${id}?year=${year}`;
