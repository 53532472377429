import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../../UI/DropDown';
import { EXAM_PATTERN_POST } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';

function ExamScheme({ setOpenExamScheme, category, examSchema, refresh }) {
  const examModel = {
    type: '',
    marks: null,
    percentage: null,
  };

  // const [termsList, setTermsList] = useState(new Map().set(0, [0]));
  const [examPattern, setExamPattern] = useState([]);
  const [singleItem, setSingleItem] = useState(examModel);

  const [examType, setExamType] = useState([examModel]);
  const [examTypeList, setExamTypeList] = useState([0]);

  const [examPatternResponse, createExamPattern] = useApi();

  const unitTestNo = useRef(1);
  const termTestNo = useRef(1);

  useEffect(() => {
    if (examSchema) {
      setExamType(examSchema?.examPattern);
      setExamTypeList(examSchema?.examPattern.map((x, idx) => idx));
    }
  }, [examSchema]);

  const handleAddExam = (x) => {
    // console.log(singleItem.marks, singleItem.type);
    // if (!singleItem.marks || !singleItem.type) {
    //   alert('Please fill all the fields');
    //   return;
    // }
    // const y = new Map(termsList);
    // const z = y.get(x);
    // y.set(x, [...z, z.length]);
    // setTermsList(y);

    // setExamPattern([...examPattern, singleItem]);

    // setSingleItem(examModel);

    // unitTestNo.current += 1;
    setExamTypeList([...examTypeList, examTypeList.length]);
    setExamType([...examType, examModel]);
  };

  // const handleAddTerm = () => {
  //   const x = new Map(termsList);
  //   x.set(termsList.size, [0]);
  //   setTermsList(x);

  //   if (singleItem.marks && singleItem.type) {
  //     setExamPattern([...examPattern, singleItem]);
  //     setSingleItem(examModel);
  //     unitTestNo.current += 1;
  //   }
  // };

  const handleExamChange = (e, index) => {
    const x = examType;
    let value = e.target.value;
    if (e.target.name === 'type') {
      // if (e.target.value === 'Unit Test') {
      //   value += '-' + unitTestNo.current;
      //   unitTestNo.current += 1;
      // } else {
      //   value += '-' + termTestNo.current;
      //   termTestNo.current += 1;
      // }
    } else {
      value = +e.target.value;
    }
    const y = {
      ...examType[index],
      [e.target.name]: value,
    };
    x[index] = y;
    setExamType([...x]);
  };

  const handleSave = () => {
    let x = [];
    // if (singleItem.marks && singleItem.type) {
    //   x = [...examPattern, singleItem];
    //   setSingleItem(examModel);
    // }

    const body = {
      category: category.split(' ').join(''),
      examPattern: examType,
    };
    createExamPattern(EXAM_PATTERN_POST, 'POST', body);
  };

  useEffect(() => {
    if (
      !examPatternResponse.isLoading &&
      !examPatternResponse.isError &&
      examPatternResponse.status &&
      examPatternResponse.status != 400
    ) {
      alert('Success');
      setOpenExamScheme(false);
      refresh();
    }
  }, [examPatternResponse]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-10/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-3 flex justify-between items-center">
          <span className="block font-bold ">Exam Scheme</span>
          <div className="flex items-center">
            <img
              src="assets/img/Close.svg"
              className="h-3 cursor-pointer"
              alt=""
              onClick={() => {
                setOpenExamScheme(false);
              }}
            />
          </div>
        </div>
        {/* {Array.from(termsList.keys()).map((x) => ( */}
        <div className="my-4">
          <div className="flex justify-between items-center w-full mb-3">
            {/* <span className="block font-bold px-6">1st Term (Marks Distribution Per Subject)</span> */}
            <button
              className="w-36 mr-6 border ml-auto py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary"
              onClick={() => handleAddExam()}
            >
              Add Exam
            </button>
          </div>
          {examTypeList.map((x) => (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 place-items-end px-6">
              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="block text-textDark text-xs">Exam Type</label>
                <DropDown
                  data={['Unit Test-1', 'Unit Test-2', 'Unit Test-3', 'Unit Test-4', 'Term Exam-1', 'Term Exam-2']}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  // title={'Select'}
                  // handleItemClick={(x) => {
                  //   setSingleItem({ ...singleItem, type: x + '-' + unitTestNo.current });
                  // }}
                  handleItemClick={(e) => {
                    handleExamChange({ target: { value: e, name: 'type' } }, x);
                  }}
                  title={examType[x].type || 'Select'}
                />
              </div>

              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="block text-textDark text-xs">Marks</label>
                <input
                  type="number"
                  name="marks"
                  placeholder="Enter marks"
                  value={examType[x].marks}
                  onChange={(e) => handleExamChange(e, x)}
                  // onChange={(e) => {
                  //   setSingleItem({ ...singleItem, marks: e.target.value });
                  // }}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                />
              </div>
            </div>
          ))}
        </div>
        {/* ))} */}

        {/* {Array.from(termsList.keys()).map((x) => ( */}
        <div className="my-4 border-t border-borderYellow pt-4">
          <div className="flex justify-between items-center w-full mb-3">
            <span className="block font-bold px-6">Final Result Weightage</span>
          </div>
          {examTypeList.map((x) => (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 place-items-end px-6">
              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="block text-textDark text-xs">Exam Type</label>
                <p className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {examType[x].type}
                </p>
              </div>

              <div className="mt-2 mb-2 w-full relative font-semibold">
                <label className="block text-textDark text-xs">Percentage</label>
                <input
                  type="number"
                  placeholder="Enter percentage"
                  name="percentage"
                  value={examType[x].percentage}
                  onChange={(e) => handleExamChange(e, x)}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                />
              </div>
            </div>
          ))}
        </div>
        {/* ))} */}
        <div className="flex mt-2 pb-6">
          {/* <button
            className="w-36 ml-6 border mr-auto py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary"
            onClick={() => handleAddTerm()}
          >
            Add Term
          </button> */}

          <button
            className="w-36 mr-6 border ml-auto py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-borderLight text-tableRowText"
            onClick={() => {
              setOpenExamScheme(false);
            }}
          >
            Cancel
          </button>
          <button
            className="w-36 mr-6 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-white bg-primary"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExamScheme;
