import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import useApi from '../../../api/useApi';
import { LEAVE_APPLY, LEAVE_TYPES } from '../../../api/urls/attendance';

function Apply({ refreshList }) {
  const attachment = useRef();
  const formModel = { leave: '', reason: '', dateFrom: '', dateTo: '', files: null };
  const [formData, setFormData] = useState(formModel);
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, applyForLeave] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    const leaveBalance = +leaveTypes.find((z) => z?.leave._id === formData.leave)?.remaining;
    const count = moment(formData.dateTo).diff(moment(formData.dateFrom), 'days') + 1;
    if (leaveBalance === 0) {
      setMessage(
        'Leave balance is 0. Either select some other type with pending balance or else it will be counted as unpaid leaves.'
      );
      return;
    }

    if (leaveBalance < count) {
      setMessage(
        `Leave days (${count}) are more than balance (${leaveBalance}), So either select different type for ${
          count - leaveBalance
        } days or they will be counted as unpaid leaves.`
      );
      return;
    }

    handleApply();
  };

  const handleApply = () => {
    const fd = new FormData();

    if (moment(formData.dateTo).diff(moment(formData.dateFrom)) < 0) {
      alert('Start(From) Date should be less than end(To) Date');
      return;
    }

    fd.append('leave', formData.leave);
    fd.append('reason', formData.reason);
    fd.append('date', moment(formData.dateFrom));
    fd.append('endDate', moment(formData.dateTo));
    fd.append('days', moment(formData.dateTo).diff(moment(formData.dateFrom), 'days') + 1);
    fd.append('files', formData.files);

    applyForLeave(LEAVE_APPLY, 'POST', fd);
  };

  useEffect(() => {
    if (formData.dateFrom && formData.dateTo) {
      if (moment(formData.dateTo).diff(moment(formData.dateFrom)) < 0) {
        alert('Start(From) Date should be less than end(To) Date');
        setFormData({ ...formData, dateTo: null });
      }
    }
  }, [formData.dateFrom, formData.dateTo]);

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      setFormData({ reason: '', dateFrom: '', dateTo: '', files: null });
      alert('Success');
      refreshList();
      attachment.current.value = '';
      getLeaveTypes(LEAVE_TYPES, 'GET');
      setMessage('');
    }

    if (!isLoading && isError) {
      alert('Error');
    }
  }, [isLoading, isError, status]);

  const [leaveTypesState, getLeaveTypes] = useApi();
  const [leaveTypes, setLeaveTypes] = useState([]);

  useEffect(() => {
    getLeaveTypes(LEAVE_TYPES, 'GET');
  }, []);

  useEffect(() => {
    if (!leaveTypesState.isLoading && !leaveTypesState.isError && leaveTypesState.data) {
      setLeaveTypes(leaveTypesState.data);
      setFormData({ ...formModel, leave: leaveTypesState?.data[0]?.leave._id });
    }
  }, [leaveTypesState]);

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      {message && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">
              Are you sure you want to proceed?
            </span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => setMessage('')}
            />
            <div className="mt-2 mb-4 mx-2 ">
              <div className="mt-2 relative font-semibold">
                <p className="w-full rounded-3xl text-sm px-4 mt-2.5 py-2 focus:outline-none">{message}</p>
              </div>
            </div>

            <div className="w-full flex justify-end pr-5">
              <button
                className="border border-borderLight text-tableRowText px-8 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                onClick={() => setMessage('')}
              >
                Cancel
              </button>
              <button
                className="border px-8 py-2 text-xs rounded-3xl bg-primary font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                onClick={() => handleApply()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="items-center py-3">
        <div
          className="flex w-full justify-between items-center relative px-6"
          style={{ borderBottom: '0.71px solid #E7E700' }}
        >
          <p className="block font-bold  pt-1 pb-4 text-textBlue1">Apply for Leave</p>
        </div>
      </div>
      <div className="flex w-full h-1/4 px-4 pb-6">
        <form className="w-full gap-x-6 grid grid-cols-1 md:grid-cols-2 px-4 pb-2" onSubmit={handleSubmit}>
          <div className="my-2  relative font-semibold w-full">
            <label className="block  text-sm text-textDark">
              Select Leave Type<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
              <select
                type="text"
                name="leave"
                required
                placeholder="Eg-Earned Leave"
                className="w-full"
                onChange={handleInputChange}
                value={formData.leave}
              >
                {Array.isArray(leaveTypes) &&
                  leaveTypes.map((x) => <option value={x?.leave._id}>{x?.leave?.leave_type}</option>)}
              </select>
            </div>
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className="block  text-sm text-textDark">Leave Balance</label>
            <input
              type="text"
              disabled
              placeholder={
                Array.isArray(leaveTypes) && leaveTypes.filter((z) => z?.leave._id === formData.leave)[0]?.remaining
              }
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div>
          <div className="my-2 relative font-semibold w-full">
            <label className="block  text-sm text-textDark">
              Date-From<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              selected={formData.dateFrom}
              required
              onChange={(date) => setFormData({ ...formData, dateFrom: date })}
              dateFormat={'dd-MM-yyyy'}
            />
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className="block  text-sm text-textDark">
              Date-To<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              selected={formData.dateTo}
              required
              onChange={(date) => setFormData({ ...formData, dateTo: date })}
              dateFormat={'dd-MM-yyyy'}
            />
          </div>
          <div className="my-2 w-full relative font-semibold md:col-span-2">
            <label className="block  text-sm text-textDark">Reason</label>
            <textarea
              type="text"
              name="reason"
              placeholder="Feburay"
              rows="4"
              value={formData.reason}
              cols="50"
              className="w-full rounded-2xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              onChange={handleInputChange}
            />
          </div>
          <div className="my-2 relative font-semibold w-full md:w-1/2 md:col-span-2">
            <label className="block  text-sm text-textDark">Attachment</label>
            <div className="relative flex">
              <input
                hidden
                ref={attachment}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setFormData({ ...formData, files: e.target.files[0] })}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <input
                // required
                type="text"
                disabled
                value={formData.files?.name}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <button
                className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                onClick={(e) => {
                  e.preventDefault();
                  attachment.current.click();
                }}
              >
                Upload
              </button>
            </div>
          </div>

          <div className="w-full justify-end md:col-span-2">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
            ) : (
              <div className="flex w-full justify-end mt-6">
                <button
                  type="reset"
                  className="border border-tableRowText text-tableRowText px-8 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    attachment.current.value = '';
                    setFormData({ reason: '', dateFrom: '', dateTo: '', files: null });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border px-8 py-2 text-xs rounded-3xl bg-primary font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Apply;
