import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { GET_CLASS_PENDING_FEE } from '../../../api/urls/finance';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import DropDown from '../../UI/DropDown';
import { useStateValue } from '../../../ContextApi/StateProvider';

const excelHeaders = [
  { label: 'Student Name', key: 'name' },
  { label: 'Roll No.', key: 'rollno' },
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Class-Section', key: 'class' },
  { label: 'Pending Fee Amount', key: 'feePending' },
];

function PendingFee() {
  const [pending, setPending] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [filter, setFilter] = useState();
  const [combinedClassSection, setCombinedClassSection] = useState([]);

  const [{ classSections }, _] = useStateValue();

  const [{ isLoading, isError, data }, getPending] = useApi();

  // useEffect(() => {
  //   getPending(GET_CLASS_PENDING_FEE(), 'GET');
  // }, []);

  useEffect(() => {
    if (classSections && Array.isArray(classSections)) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
      setFilter(combinedClassSectionList[0]);
    }
  }, [classSections]);

  useEffect(() => {
    if (filter) {
      getPending(GET_CLASS_PENDING_FEE(filter.classId, filter.sectionId), 'GET');
    }
  }, [filter]);

  useEffect(() => {
    if (!isLoading && !isError && data && Array.isArray(data)) {
      setPending(data);

      let x = data.map((x) => {
        return {
          name: x.name,
          rollno: x?.rollNo || '-',
          username: x.userName,
          class: x.class,
          feePending: '\u20B9' + x.pendingFee,
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [isLoading, isError, data]);

  const handleClick = () => {};

  let filteredData = pending || [];
  // if (filter !== 'All Classes') filteredData = filteredData.filter((c) => c.class === filter);

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block  items-center">
          <div className="font-bold text-base">
            <p>Pending Fee</p>
          </div>
          <div className="flex justify-end ml-auto">
            <button
              className="border w-36  py-2 rounded-3xl bg-primary text-xs font-medium text-white mx-4 focus:outline-none flex justify-center text-center items-center"
              onClick={() => {}}
            >
              Reminder
            </button>
            <ExcelExportBtn
              data={excelData}
              text={'Export Data'}
              width={'w-36'}
              fileName={'Report.csv'}
              handleClick={handleClick}
              headers={excelHeaders}
            />
            <DropDown
              data={[...combinedClassSection]}
              width={'w-36'}
              type="obj"
              title={filter?.name}
              field={'name'}
              margin={'ml-4'}
              handleItemClick={(x) => {
                setFilter(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-b-2xl">
              <div className="flex w-full relative text-xs font-medium px-6">
                <div className="w-4/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Roll No.</div>
                <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Class-Section</div>
                <div className="w-3/12 py-2 px-2">Pending Fee Amount</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div key={x.id} className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative py-2 text-xs font-medium px-6 ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-4/12 px-2 py-2.5 text-tableRowText">{x.name}</div>
                      <div className="w-2/12 px-2 py-2.5 text-textDark">{x?.rollNo || '--'}</div>
                      <div className="w-3/12 px-2 py-2.5 text-textDark">{x.userName}</div>
                      <div className="w-3/12 px-2 py-2.5 text-textDark">{x.class}</div>
                      <div className="w-3/12 px-2 py-2.5 text-textDark">&#x20b9;{x?.pendingFee}</div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingFee;
