import React, { useEffect, useState } from 'react';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import moment from 'moment';

const excelHeaders = [
  { label: 'Date', key: 'date' },
  { label: 'In Time', key: 'inTime' },
  { label: 'Out Time', key: 'outTime' },
  { label: 'Absent / Present', key: 'absent_present' },
];

function OpenAttendanceStaff({ toggleView, selectedStaff }) {
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (selectedStaff?.attendance && Array.isArray(selectedStaff.attendance)) {
      const data = selectedStaff.attendance;
      let x = data.map((x) => {
        return { // time is local time on server   so its UTC time on production server
          date: x?.date ? moment(x.date).format('ll') : '--',
          inTime: moment(new Date(`2/2/2020 ${x?.inTime} UTC`)).format('hh:mm A'),
          outTime: moment(new Date(`2/2/2020 ${x?.outTime} UTC`)).format('hh:mm A'),
          absent_present: x?.isPresent ? 'Present' : 'absent',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [selectedStaff]);

  return (
    <div
      className="flex justify-center  h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-1/2 text-sm m-auto rounded-2xl relative mt-20">
        <div className="flex w-full justify-between py-3 items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 text-textBlue1">{`${selectedStaff.userInfo.userInfo.username} - ${selectedStaff.userInfo.userInfo.full_name}`}</p>
          <div className="flex gap-x-6 items-center">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'attendance.csv'} text={'Export Data'} />
            <img
              src="assets/img/Close.svg"
              className=" mr-4 h-3 cursor-pointer z-50"
              alt=""
              onClick={() => {
                toggleView();
              }}
            />
          </div>
        </div>
        <div className="w-full mt-6 px-6 mb-6">
          {selectedStaff?.attendance && selectedStaff.attendance.length > 0 ? (
            <div className=" flex w-full">
              <div className="w-full border rounded-3xl border-borderYellow ">
                <div className="w-full flex bg-tableHeader rounded-3xl py-1">
                  <div className="flex w-full relative text-xs font-medium px-4">
                    <div className="w-1/4 border-r-2 py-1 px-2">Date</div>
                    <div className="w-1/4 border-r-2 py-1 px-2">In Time</div>
                    <div className="w-1/4 border-r-2 py-1 px-2">Out Time</div>
                    <div className="w-1/4 py-1 px-2">Absent / Present</div>
                  </div>
                </div>
                <div className="overflow-auto customScrollBar">
                  {selectedStaff.attendance.map((x, idx) => (
                    <div key={x._id} className="w-full py-1">
                      <div
                        className={`justify-center items-center flex w-full relative py-3.5 px-4 text-xs font-medium ${
                          selectedStaff.attendance.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/4 px-2 text-tableRowText">{moment(x?.date).format('ll')}</div>
                        <div className="w-1/4 px-2 text-tableRowText">
                          {x?.inTime ? moment(new Date(`2/2/2020 ${x?.inTime} UTC`)).format('hh:mm A') : '--'}
                        </div>
                        <div className="w-1/4 px-2 text-tableRowText">
                          {x?.outTime ? moment(new Date(`2/2/2020 ${x?.outTime} UTC`)).format('hh:mm A') : '--'}
                        </div>
                        <div className={`w-1/4 px-2 ${x.isPresent ? 'text-textGreen' : 'text-textRed'}`}>
                          {x.isPresent ? 'Present' : 'Absent'}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpenAttendanceStaff;
