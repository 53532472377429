import React, { useState, useEffect } from 'react';
import View from '../../../components/Teacher/HomeWork/Add';
import { STUDENT_HOMEWORK } from '../../../api/urls/notes';
import useApi from '../../../api/useApi';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';

function HomeWork() {
  const [selectedHW, selectHW] = useState();
  const [filter, setFilter] = useState('');

  const [homeworks, getHomeworks] = useApi();

  useEffect(() => {
    getHomeworks(STUDENT_HOMEWORK, 'GET');
  }, []);

  const onClose = (refresh) => {
    selectHW(null);
  };

  let subjects = [];
  if (homeworks?.data?.length > 0) {
    subjects = homeworks?.data.map((x) => `${x.subjectId.subjectName} (${x.subjectId.subjectCode})`);
    subjects = [...new Set(subjects)];
  }

  let filtered = homeworks?.data || [];
  if (filter && filter !== 'All Subjects') {
    filtered = homeworks?.data.filter((x) => `${x.subjectId.subjectName} (${x.subjectId.subjectCode})` === filter);
  }

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {selectedHW && <View handleClose={onClose} selectedHW={selectedHW} />}

      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-4/12">
              <p>Home Work</p>
            </div>
            <div>
              <DropDown
                width={'w-40'}
                data={['All Subjects', ...subjects]}
                title={'All Subjects'}
                handleItemClick={(x) => {
                  setFilter(x);
                }}
                otherClasses={'border-borderLight mt-2 w-full'}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Title</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Summary</div>
                  {/* <div className="w-2/12 border-r-2 py-2 px-2">File</div> */}
                  <div className="w-2/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                {homeworks.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
                ) : filtered?.length > 0 ? (
                  filtered.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                          filtered?.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2 text-tableRowText">
                          {x?.subjectId?.subjectName + ' (' + x?.subjectId?.subjectCode + ')'}
                        </div>
                        <div className="w-2/12 px-2 text-tableRowText">{moment(x?.date).format('ll')}</div>
                        <div className="w-2/12 px-2 text-tableRowText truncate">{x?.title}</div>
                        <div className="w-4/12 px-2 text-tableRowText truncate">{x?.summary}</div>
                        {/* <div className="w-2/12 px-2">
                          <a
                            className="border border-primary py-2 rounded-3xl text-primary w-9/12 font-medium focus:outline-none flex justify-center text-center items-center text-xs"
                            href={x?.files[0].location}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </div> */}
                        <div className="w-2/12 px-2">
                          <button
                            className="border border-primary py-2 rounded-3xl text-primary w-9/12 font-medium focus:outline-none flex justify-center text-center items-center text-xs"
                            onClick={() => {
                              selectHW(x);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeWork;
