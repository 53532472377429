import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { CREATE_USER, UPDATE_USER } from '../../../api/urls/user';
import DropDown from '../../UI/DropDown';
import { useStateValue } from '../../../ContextApi/StateProvider';
import { isValidEmail, isValidPhone } from '../../../utils';
import moment from 'moment';

function AddStudent({ toggleView, selectedStudent, refreshList }) {
  const [{ classSections }, _] = useStateValue();
  const [step, setStep] = useState(0);
  const [isEdit, setEdit] = useState(!selectedStudent);
  const [sections, setSections] = useState([]);
  const [medicalCert, setMedicalCert] = useState();
  const [transferCertificate, setTransferCertificate] = useState();
  const [provisionalCertificate, setProvisionalCertificate] = useState();
  const [otherCertificate, setOtherCertificate] = useState();
  const [supportingDocument, setSupportingDocument] = useState();
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState('/assets/img/user_placeholder.svg');
  const [markSheet, setMarkSheet] = useState();
  const [addressProof, setAddress] = useState();

  const medicalRef = useRef();
  const markSheetRef = useRef();
  const transferRef = useRef();
  const provisionalRef = useRef();
  const otherRef = useRef();
  const supportingRef = useRef();
  const addressRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  const infoModel = {
    full_name: '',
    gender: '',
    email_id: '',
    placeOfBirth: '',
    dateOfBirth: '',
    class: '',
    section: '',
    // aadhar: '',
    bloodgrp: '',
    roleType: 'st',
    role: 'student',
    rollNumber: '',
  };

  const parentModel = {
    companyName: '',
    designation: '',
    officeAddress: '',
    phone: '',
    email_Id: '',
    role: 'parent',
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const lastSchoolModel = {
    schoolName: '',
    class: '',
    address: '',
    medium: '',
  };

  const feeModel = {
    amount: '',
    mode: '',
    number: '',
  };

  const [infoState, setInfoState] = useState(infoModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [caddressState, setcAddressState] = useState(addressModel);
  const [fatherState, setFatherState] = useState({ father_name: '', ...parentModel });
  const [motherState, setMotherState] = useState({ mother_name: '', ...parentModel });
  const [guardianState, setGuardianState] = useState({ guardian_name: '', ...parentModel });
  const [lastSchoolState, setLastSchoolState] = useState(lastSchoolModel);
  const [feeState, setFeeState] = useState(feeModel);

  const [isFatherChecked, checkFather] = useState(true);
  const [isMotherChecked, checkMother] = useState(false);
  const [isGuardianChecked, checkGuardian] = useState(false);
  const [isSameAddress, setSameAddress] = useState(false);

  const handleInfoChange = (e) => setInfoState({ ...infoState, [e.target.name]: e.target.value });

  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const handlecAddressInputChange = (e) => setcAddressState({ ...caddressState, [e.target.name]: e.target.value });

  const handleLastSchoolChange = (e) => setLastSchoolState({ ...lastSchoolState, [e.target.name]: e.target.value });

  const handleFatherChange = (e) => setFatherState({ ...fatherState, [e.target.name]: e.target.value });

  const handleMotherChange = (e) => setMotherState({ ...motherState, [e.target.name]: e.target.value });

  const handGuardianChange = (e) => setGuardianState({ ...guardianState, [e.target.name]: e.target.value });

  const handleFeeChange = (e) => setFeeState({ ...feeState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createStaff] = useApi();

  useEffect(() => {
    if (isSameAddress) {
      setcAddressState({ ...addressState });
    } else {
      setcAddressState(addressModel);
    }
  }, [isSameAddress]);

  useEffect(() => {
    if (selectedStudent && typeof selectedStudent === 'object') {
      setInfoState({
        ...selectedStudent?.user,
        class: selectedStudent?.classInfo?._id,
        className: selectedStudent?.classInfo?.name,
        section: selectedStudent?.sectionInfo?._id,
        sectionName: selectedStudent?.sectionInfo?.name,
        rollNumber: selectedStudent?.user?.currentlyMapped?.rollNumber,
      });
      setAddressState(selectedStudent?.user?.address);
      setcAddressState(selectedStudent?.user?.caddress);
      setLastSchoolState(selectedStudent?.user?.lastSchool);
      setFeeState(selectedStudent?.user?.addmissionFee);

      const _class = classSections.find((x) => x.id === selectedStudent?.classInfo?._id);
      setSections(_class?.sections || []);

      if (selectedStudent?.user?.parentDetail?.length > 0) {
        selectedStudent?.user?.parentDetail.forEach((x) => {
          if ('father_name' in x) {
            setFatherState(x);
          } else if ('mother_name' in x) {
            setMotherState(x);
          } else if ('guardian_name' in x) setGuardianState(x);
        });
      }
      if (selectedStudent?.user?.fileArray.length > 0) {
        setImage(getFile('profileImg')?.location);
      }
    }
  }, [selectedStudent]);

  const getFile = (fileName) => {
    let z = selectedStudent?.user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName];
    return '';
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (feeState?.amount <= 0) {
      alert('Please enter valid amount');
      return;
    }

    let str = [];
    if (step === 4) {
      if (!feeState?.amount) str.push('Amount');
      if (!feeState?.mode) str.push('Paid Via');
      if (feeState?.mode === 'Challan' && !feeState?.number.trim()) str.push('Challan number');
      if (!supportingDocument) str.push('supporting Document');

      if (str.length > 0) {
        alert(`${str.join(', ')} are mandatory`);
        return;
      }
    }

    const parentDetail = [];
    if (fatherState.father_name) parentDetail.push(fatherState);
    if (motherState.mother_name) parentDetail.push(motherState);
    if (guardianState.guardian_name) parentDetail.push(guardianState);

    const formData = new FormData();
    formData.append('markSheet', markSheet);
    formData.append('medicalCertificate', medicalCert);
    formData.append('transferCertificate', transferCertificate);
    formData.append('provisionalCertificate', provisionalCertificate);
    formData.append('otherCertificate', otherCertificate);
    formData.append('supportingDocument', supportingDocument);
    formData.append('profileImg', profileImg);
    formData.append('addressProof', addressProof);

    formData.append('address', JSON.stringify(addressState));
    formData.append('caddress', JSON.stringify(caddressState));
    formData.append('lastSchool', JSON.stringify(lastSchoolState));
    formData.append('parentDetail', JSON.stringify(parentDetail));
    formData.append('addmissionFee', JSON.stringify(feeState));
    formData.append('full_name', infoState.full_name);
    formData.append('email_id', infoState.email_id);
    formData.append('gender', infoState.gender);
    formData.append('placeOfBirth', infoState.placeOfBirth);
    formData.append('dateOfBirth', infoState.dateOfBirth);
    formData.append('class', infoState.class);
    formData.append('section', infoState.section);
    // formData.append('aadhar', infoState.aadhar);
    formData.append('bloodgrp', infoState.bloodgrp);
    formData.append('roleType', 'st');
    formData.append('role', 'student');
    formData.append('rollNumber', infoState.rollNumber);

    createStaff(CREATE_USER, 'POST', formData);
    if (selectedStudent) createStaff(UPDATE_USER(selectedStudent?.user?._id), 'PUT', formData);
    else createStaff(CREATE_USER, 'POST', formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      refreshList();
      alert('Success');
      if (selectedStudent) setEdit(false);
      else {
        setInfoState(infoModel);
        setAddressState(addressModel);
        setcAddressState(addressModel);
        setLastSchoolState(lastSchoolModel);
        setFeeState(feeModel);
        setFatherState({ father_name: '', ...parentModel });
        setMotherState({ mother_name: '', ...parentModel });
        setGuardianState({ guardian_name: '', ...parentModel });
        toggleView();
      }
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  const goto1 = (e) => {
    e.preventDefault();

    if (infoState.rollNumber && infoState.rollNumber <= 0) {
      alert('Roll Number should be greater than 0');
      return;
    }

    let str = [];

    if (!infoState?.full_name?.trim()) str.push('Name');
    if (!infoState?.class?.trim()) str.push('Class');
    if (!infoState?.section?.trim()) str.push('Section');
    if (!infoState?.dateOfBirth) str.push('Date of birth');
    if (!infoState?.placeOfBirth?.trim()) str.push('Place of birth');
    if (!infoState?.gender?.trim()) str.push('Gender');
    if (!infoState?.email_id?.trim()) str.push('Email');

    if (str.length > 0) {
      alert(`${str.join(', ')} are mandatory`);
      return;
    }

    if (selectedStudent) handleSubmit();
    else setStep(1);
  };

  const goto2 = (e) => {
    e.preventDefault();
    if (isFatherChecked) {
      let str = [];

      if (!fatherState?.father_name?.trim()) str.push('Name');
      if (!fatherState?.companyName?.trim()) str.push('Company Name');
      if (!fatherState?.officeAddress?.trim()) str.push('Office Address');
      if (!fatherState?.phone?.trim()) str.push('Contact');
      if (!fatherState?.email_Id?.trim()) str.push('Email');

      if (str.length > 0) {
        alert(`Father's ${str.join(', ')} are mandatory`);
        return;
      }
    }

    if (isMotherChecked) {
      let str = [];
      if (!motherState?.mother_name?.trim()) str.push('Name');
      if (!motherState?.companyName?.trim()) str.push('Company Name');
      if (!motherState?.officeAddress?.trim()) str.push('Office Address');
      if (!motherState?.phone?.trim()) str.push('Contact');
      if (!motherState?.email_Id?.trim()) str.push('Email');

      if (str.length > 0) {
        alert(`Mother's ${str.join(', ')} are mandatory`);
        return;
      }
    }

    if (isGuardianChecked) {
      let str = [];
      if (!guardianState?.guardian_name?.trim()) str.push('Name');
      if (!guardianState?.companyName?.trim()) str.push('Company Name');
      if (!guardianState?.officeAddress?.trim()) str.push('Office Address');
      if (!guardianState?.phone?.trim()) str.push('Contact');
      if (!guardianState?.email_Id?.trim()) str.push('Email');

      if (str.length > 0) {
        alert(`Guardian's ${str.join(', ')} are mandatory`);
        return;
      }
    }

    if (selectedStudent) handleSubmit();
    else setStep(2);
  };

  const goto3 = (e) => {
    e.preventDefault();
    let str = [];
    if (!addressState?.hno?.trim()) str.push('Flat No. / House No');
    if (!addressState?.society?.trim()) str.push('Colony / Society');
    if (!addressState?.district?.trim()) str.push('District');
    if (!addressState?.city?.trim()) str.push('City');
    if (!addressState?.state?.trim()) str.push('State');
    if (!addressState?.pincode?.trim()) str.push('Pin COde');

    if (str.length > 0) {
      alert(`${str.join(', ')} are mandatory`);
      return;
    }

    if (selectedStudent) handleSubmit();
    else setStep(3);
  };

  const goto4 = (e) => {
    e.preventDefault();

    if (selectedStudent) handleSubmit();
    else setStep(4);
  };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-4/5 pb-4 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-3">
            <div className="font-bold px-8">
              <p>{selectedStudent?.user ? selectedStudent?.user.full_name : 'Add Student'}</p>
            </div>
            <div className="flex px-8 gap-x-6 items-center">
              {selectedStudent && (
                <button
                  onClick={() => setEdit((prev) => !prev)}
                  className={`py-2 rounded-full text-xs ${
                    isEdit ? 'bg-white text-tableRowText border border-borderGrey' : 'bg-primary text-white'
                  } w-28`}
                >
                  {isEdit ? 'Cancel' : 'Edit'}
                </button>
              )}
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleView} />
            </div>
          </div>
        </div>

        <div className="py-2 px-8 relative grid grid-cols-3 md:grid-cols-5 gap-x-4 border-b border-borderYellow overflow-auto">
          <button
            onClick={() => selectedStudent && !isEdit && setStep(0)}
            className={`py-2 w-full rounded-full ${
              selectedStudent && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 0 ? 'bg-primary border-primary text-white' : 'bg-white text-black'}`}
          >
            Student Information
          </button>
          <button
            onClick={() => selectedStudent && !isEdit && setStep(1)}
            className={`py-2 w-full rounded-full ${
              selectedStudent && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 1 ? 'bg-primary border-primary text-white' : 'bg-white text-black'}`}
          >
            Parents Information
          </button>
          <button
            onClick={() => selectedStudent && !isEdit && setStep(2)}
            className={`py-2 w-full rounded-full ${
              selectedStudent && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 2 ? 'bg-primary border-primary text-white' : 'bg-white text-black'}`}
          >
            Address
          </button>
          <button
            onClick={() => selectedStudent && !isEdit && setStep(3)}
            className={`py-2 w-full rounded-full ${
              selectedStudent && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 3 ? 'bg-primary border-primary text-white' : 'bg-white text-black'}`}
          >
            Last School
          </button>
          <button
            onClick={() => selectedStudent && !isEdit && setStep(4)}
            className={`py-2 w-full rounded-full ${
              selectedStudent && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 4 ? 'bg-primary border-primary text-white' : 'bg-white text-black'}`}
          >
            Admission Fee
          </button>
        </div>

        {step === 0 ? (
          isEdit ? (
            <form autoComplete="off" className="px-8" onSubmit={goto1}>
              <div className="items-center py-4 ">
                <p className=" font-medium ">Student Information</p>
              </div>

              <div className="absolute right-6 -mt-8">
                <span className="text-red-500 ml-1 text-xs font-semibold">
                  <span className="ml-1 text-lg ">* </span>Mandatory Fields
                </span>
              </div>

              <div className="flex flex-col md:flex-row w-full">
                <div>
                  <div className="profileLogo bg-gray-500 w-56 h-56 relative">
                    {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                    <input
                      hidden
                      ref={imgRef}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => setProfileImg(e.target.files[0])}
                    />
                    <img
                      src="assets/img/camera.png"
                      className="w-10 h-10 absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2"
                      onClick={(e) => {
                        e.preventDefault();
                        imgRef.current.click();
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full md:pl-12">
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Full Name<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Rakesh Ahirwar"
                      value={infoState?.full_name}
                      name="full_name"
                      onChange={handleInfoChange}
                      autoComplete="off"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Email<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="email"
                      // placeholder="Eg-Rakesh Ahirwar"
                      value={infoState?.email_id}
                      name="email_id"
                      onBlur={() => {
                        if (!isValidEmail(infoState?.email_id)) {
                          alert('You have entered an invalid email address');
                          setInfoState({ ...infoState, email_id: '' });
                        }
                      }}
                      onChange={handleInfoChange}
                      autoComplete="off"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Gender<span className="text-red-500 ml-1">*</span>
                    </label>
                    <DropDown
                      data={['Male', 'Female']}
                      handleItemClick={(x) => {
                        handleInfoChange({ target: { value: x, name: 'gender' } });
                      }}
                      title={selectedStudent?.user ? infoState.gender : 'Select'}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2 text-textDark'}
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Place of Birth<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Male"
                      value={infoState?.placeOfBirth}
                      name="placeOfBirth"
                      onChange={handleInfoChange}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Date of Birth<span className="text-red-500 ml-1">*</span>
                    </label>
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={infoState?.dateOfBirth && new Date(infoState?.dateOfBirth)}
                      onChange={(date) => setInfoState({ ...infoState, dateOfBirth: date })}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {/* )} */}
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Roll No.</label>
                    <input
                      type="number"
                      // placeholder="Eg-Male"
                      value={infoState?.rollNumber}
                      name="rollNumber"
                      onChange={handleInfoChange}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Class<span className="text-red-500 ml-1">*</span>
                    </label>
                    <DropDown
                      field={'name'}
                      data={classSections}
                      key={infoState.class}
                      type={'obj'}
                      title={selectedStudent?.user ? infoState?.className || '--' : infoState?.className || 'Select'}
                      handleItemClick={(x) => {
                        // handleInfoChange({ target: { value: x.id, name: 'class' } });
                        setInfoState({
                          ...infoState,
                          class: x.id,
                          section: '',
                          sectionName: '',
                          className: x.name,
                        });
                        setSections(x.sections);
                      }}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2'}
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Section<span className="text-red-500 ml-1">*</span>
                    </label>
                    <DropDown
                      data={sections}
                      type={'obj'}
                      field={'name'}
                      title={selectedStudent?.user ? infoState?.sectionName || '--' : 'Select'}
                      handleItemClick={(x) => {
                        handleInfoChange({ target: { value: x.id, name: 'section' } });
                      }}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2'}
                    />
                  </div>
                  {/* <div className="my-2 w-full relative font-semibold ">
                  <label  className="text-textDark font-medium text-sm">
                    AADHAR<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    
                    // placeholder="Eg-19 may-1992"
                    value={infoState?.aadhar}
                    name="aadhar"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div> */}
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Blood Group</label>
                    <DropDown
                      data={[
                        'A positive',
                        'A negative',
                        'B positive',
                        'B negative',
                        'AB positive',
                        'AB negative',
                        'O positive',
                        'O negative',
                      ]}
                      title={selectedStudent?.user ? infoState.bloodgrp : 'Select'}
                      handleItemClick={(x) => {
                        handleInfoChange({ target: { value: x, name: 'bloodgrp' } });
                      }}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2'}
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Medical Certificate
                      {/* <span className="text-red-500 ml-1">*</span> */}
                    </label>
                    <div className="relative flex">
                      <input
                        hidden
                        ref={medicalRef}
                        type="file"
                        onChange={(e) => setMedicalCert(e.target.files[0])}
                        className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                      />
                      <input
                        //
                        type="text"
                        disabled
                        value={medicalCert?.name}
                        className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                      />
                      <button
                        className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                        onClick={(e) => {
                          e.preventDefault();
                          medicalRef.current.click();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-end">
                {selectedStudent && isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    type="submit"
                  >
                    {selectedStudent ? 'Save' : 'Next'}
                  </button>
                )}
              </div>
            </form>
          ) : (
            <div className="px-8">
              <div className="items-center py-4 ">
                <p className=" font-medium ">Student Information</p>
              </div>

              <div className="flex flex-col md:flex-row w-full">
                <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                  {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full md:pl-12">
                  <div className="my-2 w-full relative  ">
                    <label className="text-textDark font-medium text-sm">Full Name</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.full_name || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative  ">
                    <label className="text-textDark font-medium text-sm">Email</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.email_id || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative  ">
                    <label className="text-textDark font-medium text-sm">Gender</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.gender || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative  ">
                    <label className="text-textDark font-medium text-sm">Place of Birth</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.placeOfBirth || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative  ">
                    <label className="text-textDark font-medium text-sm">Date of Birth</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {moment(infoState.dateOfBirth).format('D-MM-YYYY') || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative ">
                    <label className="text-textDark font-medium text-sm">Roll No.</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.rollNumber || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative ">
                    <label className="text-textDark font-medium text-sm">Class</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.className || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative ">
                    <label className="text-textDark font-medium text-sm">Section</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.sectionName || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative ">
                    <label className="text-textDark font-medium text-sm">Blood Group</label>

                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {infoState?.bloodgrp || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Medical Certificate</label>
                    <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                      <p className="py-2 text-tableRowText px-2 truncate">{getFile('medicalCertificate')?.name}</p>
                      <a
                        href={getFile('medicalCertificate')?.location}
                        download
                        className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-end">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 1 ? (
          isEdit ? (
            <form autoComplete="off " onSubmit={goto2}>
              <p className=" font-bold text-black pt-4 px-8">Father</p>

              <div className="absolute right-6 -mt-4 flex justify-center items-center">
                <input
                  type="checkbox"
                  checked={isFatherChecked}
                  onChange={() => checkFather((prev) => !prev)}
                  className="mr-2"
                />
                <span className="text-tableRowText ml-1 text-xs font-semibold">Add Father’s Detail</span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isFatherChecked}
                    disabled={!isFatherChecked}
                    // placeholder="Eg-198"
                    value={fatherState?.father_name}
                    name="father_name"
                    onChange={handleFatherChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Company Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isFatherChecked}
                    // placeholder="Eg-Amar Colony"
                    disabled={!isFatherChecked}
                    value={fatherState?.companyName}
                    name="companyName"
                    onChange={handleFatherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={fatherState.designation}
                    disabled={!isFatherChecked}
                    name="designation"
                    onChange={handleFatherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Office Address<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isFatherChecked}
                    // placeholder="Eg-Jalandhar"
                    disabled={!isFatherChecked}
                    value={fatherState?.officeAddress}
                    name="officeAddress"
                    onChange={handleFatherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Contact Number<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isFatherChecked}
                    // placeholder="Eg-Punjab"
                    disabled={!isFatherChecked}
                    value={fatherState.phone}
                    onBlur={() => {
                      if (!isValidPhone(fatherState.phone)) {
                        alert('You have entered an invalid contact number');
                        setFatherState({ ...fatherState, phone: '' });
                      }
                    }}
                    name="phone"
                    onChange={handleFatherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Email<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    disabled={!isFatherChecked}
                    required={isFatherChecked}
                    // placeholder="Eg-141401"
                    value={fatherState.email_Id}
                    onBlur={() => {
                      if (!isValidEmail(fatherState?.email_Id)) {
                        alert('You have entered an invalid email address');
                        setFatherState({ ...fatherState, email_Id: '' });
                      }
                    }}
                    name="email_Id"
                    onChange={handleFatherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Mother</p>

              <div className="absolute right-6 -mt-4 flex justify-center items-center">
                <input
                  type="checkbox"
                  checked={isMotherChecked}
                  onChange={() => checkMother((prev) => !prev)}
                  className="mr-2"
                />
                <span className="text-tableRowText ml-1 text-xs font-semibold">Add Mother’s Detail</span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isMotherChecked}
                    // placeholder="Eg-198"
                    value={motherState.mother_name}
                    name="mother_name"
                    onChange={handleMotherChange}
                    autoComplete="off"
                    disabled={!isMotherChecked}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Company Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isMotherChecked}
                    // placeholder="Eg-Amar Colony"
                    value={motherState?.companyName}
                    name="companyName"
                    disabled={!isMotherChecked}
                    onChange={handleMotherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={motherState?.designation}
                    name="designation"
                    disabled={!isMotherChecked}
                    onChange={handleMotherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Office Address<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isMotherChecked}
                    // placeholder="Eg-Jalandhar"
                    value={motherState.officeAddress}
                    disabled={!isMotherChecked}
                    name="officeAddress"
                    onChange={handleMotherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Contact Number<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="tel"
                    required={isMotherChecked}
                    // placeholder="Eg-Punjab"
                    value={motherState.phone}
                    disabled={!isMotherChecked}
                    onBlur={() => {
                      if (!isValidPhone(motherState.phone)) {
                        alert('You have entered an invalid contact number');
                        setMotherState({ ...motherState, phone: '' });
                      }
                    }}
                    name="phone"
                    onChange={handleMotherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Email<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isMotherChecked}
                    disabled={!isMotherChecked}
                    // placeholder="Eg-141401"
                    onBlur={() => {
                      if (!isValidEmail(motherState?.email_Id)) {
                        alert('You have entered an invalid email address');
                        setMotherState({ ...motherState, email_Id: '' });
                      }
                    }}
                    value={motherState.email_Id}
                    name="email_Id"
                    onChange={handleMotherChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Guardian</p>

              <div className="absolute right-6 -mt-4 flex justify-center items-center">
                <input
                  type="checkbox"
                  checked={isGuardianChecked}
                  onChange={() => checkGuardian((prev) => !prev)}
                  className="mr-2"
                />
                <span className="text-tableRowText ml-1 text-xs font-semibold">Add Guardian’s Detail</span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isGuardianChecked}
                    // placeholder="Eg-198"
                    value={guardianState.guardian_name}
                    disabled={!isGuardianChecked}
                    name="guardian_name"
                    onChange={handGuardianChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Company Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isGuardianChecked}
                    // placeholder="Eg-Amar Colony"
                    disabled={!isGuardianChecked}
                    value={guardianState.companyName}
                    name="companyName"
                    onChange={handGuardianChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <input
                    type="text"
                    disabled={!isGuardianChecked}
                    // placeholder="Eg-Jalandhar"
                    value={guardianState.designation}
                    name="designation"
                    onChange={handGuardianChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Office Address<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isGuardianChecked}
                    disabled={!isGuardianChecked}
                    // placeholder="Eg-Jalandhar"
                    value={guardianState.officeAddress}
                    name="officeAddress"
                    onChange={handGuardianChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Contact Number<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="tel"
                    required={isGuardianChecked}
                    // placeholder="Eg-Punjab"
                    disabled={!isGuardianChecked}
                    value={guardianState.phone}
                    onBlur={() => {
                      if (!isValidPhone(guardianState.phone)) {
                        alert('You have entered an invalid contact number');
                        setGuardianState({ ...guardianState, phone: '' });
                      }
                    }}
                    name="phone"
                    onChange={handGuardianChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Email<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required={isGuardianChecked}
                    disabled={!isGuardianChecked}
                    // placeholder="Eg-141401"
                    onBlur={() => {
                      if (!isValidEmail(guardianState?.email_Id)) {
                        alert('You have entered an invalid email address');
                        setGuardianState({ ...guardianState, email_Id: '' });
                      }
                    }}
                    value={guardianState.email_Id}
                    name="email_Id"
                    onChange={handGuardianChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              {selectedStudent && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex gap-x-4 justify-between px-8">
                  {isEdit && selectedStudent ? null : (
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="submit"
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center ml-auto"
                  >
                    {selectedStudent ? 'Save' : 'Next'}
                  </button>
                </div>
              )}
            </form>
          ) : (
            <div>
              <p className=" font-bold text-black pt-4 px-8">Father</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.father_name || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Company Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.companyName || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.designation || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Office Address</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.officeAddress || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Contact Number</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.phone || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Email</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {fatherState?.email_Id || '--'}
                  </p>
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Mother</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.mother_name || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Company Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.companyName || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.designation || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Office Address</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.officeAddress || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Contact Number</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.phone || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Email</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {motherState?.email_Id || '--'}
                  </p>
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Guardian</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.guardian_name || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Company Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.companyName || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Designation</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.designation || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Office Address</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.officeAddress || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Contact Number</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.phone || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Email</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {guardianState?.email_Id || '--'}
                  </p>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>

                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 2 ? (
          isEdit ? (
            <form autoComplete="off" onSubmit={goto3}>
              <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Flat No. / House No.<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    value={addressState?.hno}
                    name="hno"
                    onChange={handleAddressInputChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Colony / Society<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-Amar Colony"
                    value={addressState?.society}
                    name="society"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    District<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={addressState?.district}
                    name="district"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    City<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={addressState?.city}
                    name="city"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    State<span className="text-red-500 ml-1">*</span>
                  </label>
                  <select
                    type="text"
                    // placeholder="Eg-Punjab"
                    value={addressState?.state}
                    name="state"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Pin Code<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="number"
                    // placeholder="Eg-141401"
                    value={addressState?.pincode}
                    name="pincode"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Address Proof Document</label>
                  <div className="relative flex">
                    <input hidden ref={addressRef} type="file" onChange={(e) => setAddress(e.target.files[0])} />
                    <input
                      //
                      type="text"
                      disabled
                      value={addressProof?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />

                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        addressRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>

                {/* <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Upload Document
                </label>
                <div className="relative flex">
                  <input
                    // 
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div> */}
              </div>

              <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>

              <div className="absolute right-6 -mt-4 flex justify-center items-center">
                <input
                  type="checkbox"
                  checked={isSameAddress}
                  onChange={() => setSameAddress((prev) => !prev)}
                  className="mr-2"
                />
                <span className="text-tableRowText ml-1 text-xs font-semibold">Same as permanent address</span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    value={caddressState?.hno}
                    name="hno"
                    onChange={handlecAddressInputChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <input
                    type="text"
                    // placeholder="Eg-Amar Colony"
                    value={caddressState?.society}
                    name="society"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={caddressState?.district}
                    name="district"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={caddressState?.city}
                    name="city"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <select
                    type="text"
                    // placeholder="Eg-Punjab"
                    value={caddressState?.state}
                    name="state"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <input
                    type="text"
                    // placeholder="Eg-141401"
                    value={caddressState?.pincode}
                    name="pincode"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                {/* <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Address Proof Document
                </label>
                <div className="relative flex">
                  <input
                    // 
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Upload Document
                </label>
                <div className="relative flex">
                  <input
                    // 
                    type="file"
                    // placeholder="Ex- Syllabus"
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    // onClick={() => setCreateTest(true)}
                  >
                    Upload
                  </button>
                </div>
              </div> */}
              </div>

              {selectedStudent && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex gap-x-4 justify-between px-8">
                  {isEdit && selectedStudent ? null : (
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="submit"
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center ml-auto"
                    // onClick={() => {
                    //   setStep(3);
                    // }}
                  >
                    {selectedStudent ? 'Save' : 'Next'}
                  </button>
                </div>
              )}
            </form>
          ) : (
            <div>
              <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.hno || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.society || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.district || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.city || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.state || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.pincode || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Address Proof Document</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('addressProof')?.name}</p>
                    <a
                      href={getFile('addressProof')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.hno || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.society || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.district || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.city || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.state || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.pincode || '--'}
                  </p>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 3 ? (
          isEdit ? (
            <form autoComplete="off " onSubmit={goto4}>
              <p className=" font-bold text-black pt-4 px-8">Last School</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">School Name</label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    value={lastSchoolState?.schoolName}
                    name="schoolName"
                    onChange={handleLastSchoolChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Class</label>
                  <input
                    type="text"
                    // placeholder="Eg-Amar Colony"
                    value={lastSchoolState?.class}
                    name="class"
                    onChange={handleLastSchoolChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Address</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={lastSchoolState?.address}
                    name="address"
                    onChange={handleLastSchoolChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Medium</label>
                  <DropDown
                    data={['Select', 'English', 'Hindi']}
                    handleItemClick={(x) => {
                      handleLastSchoolChange({ target: { value: x, name: 'medium' } });
                    }}
                    title={selectedStudent?.user ? lastSchoolState?.medium : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Documents</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Marksheet</label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={markSheetRef}
                      type="file"
                      onChange={(e) => setMarkSheet(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      //
                      type="text"
                      disabled
                      value={markSheet?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        markSheetRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Transfer Certificate</label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={transferRef}
                      type="file"
                      onChange={(e) => setTransferCertificate(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      //
                      type="text"
                      disabled
                      value={transferCertificate?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        transferRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Provisional Certificate</label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={provisionalRef}
                      type="file"
                      onChange={(e) => setProvisionalCertificate(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      //
                      type="text"
                      disabled
                      value={provisionalCertificate?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        provisionalRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Other Certificate</label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={otherRef}
                      type="file"
                      onChange={(e) => setOtherCertificate(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      //
                      type="text"
                      disabled
                      value={otherCertificate?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        otherRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>

              {selectedStudent && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex gap-x-4 justify-between px-8">
                  {isEdit && selectedStudent ? null : (
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="submit"
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center ml-auto"
                    // onClick={() => {
                    //   setStep(4);
                    // }}
                  >
                    {selectedStudent ? 'Save' : 'Next'}
                  </button>
                </div>
              )}
            </form>
          ) : (
            <div>
              <p className="font-bold text-black pt-4 px-8">Last School</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">School Name</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {lastSchoolState?.schoolName || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">Class</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {lastSchoolState?.class || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">Address</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {lastSchoolState?.address || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">Medium</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {lastSchoolState?.medium || '--'}
                  </p>
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Documents</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Marksheet</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('markSheet')?.name}</p>
                    <a
                      href={getFile('markSheet')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Transfer Certificate</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('transferCertificate')?.name}</p>
                    <a
                      href={getFile('transferCertificate')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Provisional Certificate</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('provisionalCertificate')?.name}</p>
                    <a
                      href={getFile('provisionalCertificate')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Other Certificate</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('otherCertificate')?.name}</p>
                    <a
                      href={getFile('otherCertificate')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>

                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(4);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 4 ? (
          isEdit ? (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8 mt-4">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Total Admission Fee Amount<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="number"
                    // placeholder="Eg-198"
                    value={feeState?.amount || ''}
                    name="amount"
                    onChange={handleFeeChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Paid Via<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DropDown
                    data={['Challan', 'Online', 'Cash']}
                    handleItemClick={(x) => {
                      handleFeeChange({ target: { value: x, name: 'mode' } });
                    }}
                    title={selectedStudent?.user ? feeState?.mode : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>
                {feeState?.mode === 'Challan' && (
                  <div className="my-2 w-full relative font-semibold">
                    <label className="text-textDark font-medium text-sm">
                      Challan Number<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      // placeholder="Eg-Jalandhar"
                      value={feeState?.number}
                      name="number"
                      onChange={handleFeeChange}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>
                )}

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Supporting Document<span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={supportingRef}
                      type="file"
                      onChange={(e) => setSupportingDocument(e.target.files[0])}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      type="text"
                      // disabled
                      value={supportingDocument?.name}
                      className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                      onClick={(e) => {
                        e.preventDefault();
                        supportingRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>

              {/* {!selectedStudent?.user && ( */}
              <div className="flex gap-x-4 justify-between px-8">
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                      type="submit"
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
              {/* )} */}
            </form>
          ) : (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8 mt-4">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Total Admission Fee Amount</label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    value={feeState?.amount}
                    name="amount"
                    onChange={handleFeeChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Paid Via</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {feeState?.mode || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Challan Number</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {feeState?.number || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Supporting Document</label>
                  <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
                    <p className="py-2 text-tableRowText px-2 truncate">{getFile('supportingDocument')?.name}</p>
                    <a
                      href={getFile('supportingDocument')?.location}
                      download
                      className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                {isEdit && selectedStudent ? null : (
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep((prev) => prev - 1);
                    }}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export default AddStudent;
