import React, { useState, useEffect } from 'react';
import moment from 'moment';

import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

import useApi from '../../../api/useApi';
import {
  STAFF_SALARY,
  STAFF_SALARY_HISTORY,
  UPDATE_STAFF_SALARY,
  STAFF_SALARY_RELEASE,
} from '../../../api/urls/finance';

const excelHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Designation', key: 'designation' },
  { label: 'Salary', key: 'salary' },
];

const excelUserHeaders = [
  { label: 'Month', key: 'month' },
  { label: 'Salary Release Date', key: 'releaseDate' },
  { label: 'Deduction', key: 'deduction' },
  { label: 'Salary', key: 'salary' },
];

const Salaries = () => {
  const [released, setReleased] = useState(false);
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [staffId, setStaffId] = useState('');
  const [add, setAdd] = useState(false);
  const [salary, setSalary] = useState();
  const [excelStaffData, setExcelStaffData] = useState([]);
  const [excelNonStaffData, setExcelNonStaffData] = useState([]);
  const [sort, setSort] = useState('');
  const [nonTeachSort, setNonStaffSort] = useState('');
  const [excelUserData, setExcelUserData] = useState('');

  const [{ isLoading, isError, data }, getStaffSalaries] = useApi();
  const [staffSalaries, setStaffSalaries] = useState([]);
  const [nonTeachingStaffSalaries, setNonTeachingStaffSalaries] = useState([]);

  const [releaseSalaryRes, releaseSalary] = useApi();
  const [userIds, setUserIds] = useState([]);

  const [updatedSalaryState, updateSalary] = useApi();
  const [selectedStaff, selectStaff] = useState();
  const [increment, setIncrement] = useState();

  const [singleStaffState, getSingleStaff] = useApi();
  const [singleStaff, setSingleStaff] = useState();

  useEffect(() => {
    getStaffSalaries(STAFF_SALARY, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data && Array.isArray(data)) {
      const _staff = data.filter((x) => x.roles.includes('Teacher') || x.roles.includes('Principal'));
      const _nonStaff = data.filter((x) => !(x.roles.includes('Teacher') || x.roles.includes('Principal')));

      setStaffSalaries(_staff);
      setNonTeachingStaffSalaries(_nonStaff);

      let _staffExcel = _staff.map((x) => {
        return {
          name: x.username,
          username: x.username,
          designation: x?.designation || x?.roles[0],
          salary: !x.id ? 'Not Added' : '\u20B9' + x.salary,
        };
      });

      if (Array.isArray(_staffExcel)) setExcelStaffData(_staffExcel);

      let _nonStaffExcel = _nonStaff.map((x) => {
        return {
          name: x.username,
          username: x.username,
          designation: x?.designation || x?.roles[0],
          salary: !x.id ? 'Not Added' : '\u20B9' + x.salary,
        };
      });

      if (Array.isArray(_nonStaffExcel)) setExcelNonStaffData(_nonStaffExcel);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!singleStaffState.isLoading && !singleStaffState.isError && singleStaffState.data) {
      setSingleStaff(singleStaffState.data);

      let _staffExcel = singleStaffState.data.salary.map((x) => {
        return {
          month: moment(x?.createdAt).format('MMMM'),
          releaseDate: moment(x?.createdAt).format('LL'),
          deduction: '\u20B9' + x?.deduction,
          salary: '\u20B9' + x?.amount,
        };
      });

      if (Array.isArray(_staffExcel)) setExcelUserData(_staffExcel);
    }
  }, [singleStaffState]);

  useEffect(() => {
    if (staffId) {
      setView(true);
      getSingleStaff(STAFF_SALARY_HISTORY(staffId), 'GET');
    }
  }, [staffId]);

  useEffect(() => {
    if (
      !updatedSalaryState.isLoading &&
      !updatedSalaryState.isError &&
      (updatedSalaryState.status === 202 || updatedSalaryState.status === 201)
    ) {
      alert('Success');
      getStaffSalaries(STAFF_SALARY, 'GET');
      setUpdate(false);
      setAdd(false);
    }

    if (!updatedSalaryState.isLoading && updatedSalaryState.isError) {
      alert('Failed');
    }
  }, [updatedSalaryState]);

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!increment || increment <= 0) {
      alert('Increment Percentage must be greater than 0');
      return;
    }

    const newSalary = parseInt(selectedStaff.salary) + selectedStaff.salary * (+increment / 100);
    updateSalary(UPDATE_STAFF_SALARY(selectedStaff.id), 'PUT', {
      user_id: selectedStaff.user_id,
      new_salary: newSalary,
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    if (!salary || salary <= 0) {
      alert('Salary must be greater than 0');
      return;
    }

    updateSalary(STAFF_SALARY, 'POST', {
      user_id: selectedStaff.user_id,
      salary: salary,
    });
  };

  const releaseSalaries = () => {
    releaseSalary(STAFF_SALARY_RELEASE, 'PUT', { userId: userIds });
  };

  useEffect(() => {
    if (!releaseSalaryRes.isLoading && !releaseSalaryRes.isError && releaseSalaryRes.status == 201) {
      alert('Success');
      setUserIds([]);
      setReleased(false);
    }

    if (releaseSalaryRes.isError) alert('Error');
  }, [releaseSalaryRes]);

  const addUserIds = (id) => {
    if (userIds.indexOf(id) === -1) {
      let x = userIds;
      x.push(id);
      setUserIds([...x]);
    } else {
      const y = userIds.filter((x) => x !== id);
      setUserIds([...y]);
    }
  };

  const selectAll = () => {
    if (userIds.length === staffSalaries.length) {
      setUserIds([]);
    } else {
      const ids = staffSalaries.map((x) => x.user_id);
      setUserIds([...ids]);
    }
  };

  let filteredStaffData = staffSalaries || [];

  if (sort) {
    if (sort === 'Ascending Order') {
      filteredStaffData = filteredStaffData.sort((a, b) =>
        a.user_name.toLowerCase() > b.user_name.toLowerCase() ? 1 : -1
      );
    }
    if (sort === 'Descending Order') {
      filteredStaffData = filteredStaffData.sort((a, b) =>
        a.user_name.toLowerCase() < b.user_name.toLowerCase() ? 1 : -1
      );
    }
  }

  let filteredNonTeachStaffData = nonTeachingStaffSalaries || [];
  if (nonTeachSort) {
    if (nonTeachSort === 'Ascending Order') {
      filteredNonTeachStaffData = filteredNonTeachStaffData.sort((a, b) =>
        a.user_name.toLowerCase() > b.user_name.toLowerCase() ? 1 : -1
      );
    }
    if (nonTeachSort === 'Descending Order') {
      filteredNonTeachStaffData = filteredNonTeachStaffData.sort((a, b) =>
        a.user_name.toLowerCase() < b.user_name.toLowerCase() ? 1 : -1
      );
    }
  }

  return (
    <>
      {released && (
        <div
          className="flex h-full w-full absolute left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className=" bg-white w-11/12 md:w-3/4 pb-2 text-sm  m-auto mt-10 block rounded-2xl ">
            <div className="flex w-full px-2  justify-between relative items-center border-b border-borderYellow">
              <p className="block font-bold p-4 text-textBlue1">Mark Released</p>
              {/* <div className="relative w-1/5 mx-2 mr-10">
                <button
                  className="w-full border px-4 py-1 rounded-3xl text-sm text-gray-400 focus:outline-none flex justify-between items-center"
                  onClick={() => {
                    setSort((prev) => !prev);
                  }}
                >
                  Sort By
                  <img src="assets/img/expandIcon.svg" className="h-2 ml-4" alt="" />
                </button>
                {showSort && (
                  <div className="absolute h-auto shadow-xl w-full text-left rounded-xl px-3 bg-white z-50 text-xs font-semibold">
                    <p className="pt-2 pb-1">Ascending Order</p>
                    <p className="py-1">Descending Order</p>
                    <p className="py-1">Name-a to z</p>
                    <p className="py-1">Name-z to a</p>
                    <p className="py-1">High Pending Fee</p>
                    <p className="py-1">Low Pending Fee</p>
                  </div>
                )}
              </div> */}
              <img
                src="assets/img/Close.svg"
                className="h-3 cursor-pointer z-50 mr-4"
                alt=""
                onClick={() => {
                  setReleased(false);
                  setUserIds([]);
                }}
              />
            </div>

            <div className="w-full pb-2 mt-2 px-2">
              <div className=" flex w-full pt-2 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl min-w-700"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-4">
                      <div className="w-1/12 border-r-2 py-2 px-2">
                        <input type="checkbox" onChange={selectAll} />
                      </div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Name</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Designation</div>
                      <div className="w-2/12 py-2 px-2">Salary</div>
                      <div className="w-2/12 py-2 px-2">Deduction</div>
                      <div className="w-2/12 py-2 px-2">Salary after deduction</div>
                    </div>
                  </div>
                  <div className="overflow-auto customScrollBar" style={{ maxHeight: '70vh' }}>
                    {staffSalaries.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative py-2 px-4 text-xs font-medium ${
                            staffSalaries.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/12 px-2 py-2">
                            <input
                              type="checkbox"
                              onChange={() => addUserIds(x.user_id)}
                              checked={userIds.includes(x.user_id) ? true : false}
                            />
                          </div>
                          <div className="w-2/12 px-2 text-tableRowText">{x?.user_name}</div>
                          <div className="w-2/12 px-2">{x?.username}</div>
                          <div className="w-2/12 px-2">{x?.designation || x?.roles?.join(', ')}</div>
                          <div className="w-2/12 px-2 text-tableRowText">&#x20B9;{x?.salary ? x.salary : 0}</div>
                          <div className="w-2/12 px-2 text-tableRowText">
                            {' '}
                            &#x20B9;{x?.deduction ? (+x.deduction).toFixed(2) : 0}
                          </div>
                          <div className="w-2/12 px-2">
                            &#x20B9;{' '}
                            {x?.salary ? (x?.deduction ? (x?.salary - x?.deduction).toFixed(2) : x?.salary) : 0}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full mt-4 pr-4 flex justify-end">
                <button
                  className="border px-8 py-2 rounded-3xl bg-primary text-xs font-medium text-white mx-1 focus:outline-none"
                  onClick={releaseSalaries}
                >
                  Release
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {view && (
        <div
          className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
          style={{
            background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
          }}
        >
          <div className="relative w-11/12 md:w-10/12 pb-10 mx-auto h-auto mt-8 md:mt-16 bg-white rounded-2xl">
            <div className="block relative border-b border-borderYellow">
              <div className="flex justify-between items-center py-2">
                <div className="font-bold px-6">
                  <p>{singleStaff?.user_name}</p>
                </div>
                <div className="flex pr-6 items-center gap-x-4">
                  <ExcelExportBtn
                    data={excelUserData}
                    text={'Salary Data'}
                    width={'w-40'}
                    fileName={'TeachingStaffSalary.csv'}
                    headers={excelUserHeaders}
                  />
                  <img
                    src="assets/img/Close.svg"
                    className=" h-3 cursor-pointer z-50"
                    alt=""
                    onClick={() => {
                      setView(false);
                      selectStaff(null);
                      setStaffId('');
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className="font-bold">Employee Details</p>
              <div className="flex flex-wrap pb-2 mt-1">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">Name</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {singleStaff?.user_name}
                  </p>
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">ROLEDin ID</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {singleStaff?.username}
                  </p>
                </div>
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">Joining Date</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {moment(singleStaff?.joined).format('LL')}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className="mt-1 font-bold">Bank Account Details</p>
              <div className="flex flex-wrap pb-2">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">Select Bank</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {singleStaff?.bank?.bank || '-'}
                  </p>
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">IFSC Code</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {singleStaff?.bank?.ifsc || '-'}
                  </p>
                </div>
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark">Account Number</label>
                  <p className="w-full text-textDark text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {singleStaff?.bank?.accountNo || '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <p className="mt-1 px-6  font-bold">Salary History</p>
              <div className="flex w-full overflow-auto">
                <div
                  className="m-auto border rounded-2xl min-w-700 mt-4"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl py-1">
                    <div className="flex w-full relative text-xs font-medium px-4">
                      <div className="w-2/12 border-r-2 py-1 px-2">Month</div>
                      <div className="w-3/12 border-r-2 py-1 px-2">Salary Release Date</div>
                      <div className="w-2/12 border-r-2 py-1 px-2">Deduction</div>
                      <div className="w-2/12 py-1 px-2">Salary Amount</div>
                      <div className="w-3/12 py-1 px-2">Salary After Deduction</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {Array.isArray(singleStaff?.salary) && singleStaff?.salary.length > 0 ? (
                      singleStaff?.salary.map((x, idx) => (
                        <div key={x._id} className="w-full py-1">
                          <div
                            className={`justify-center items-center flex w-full relative py-3 px-4 text-xs font-medium ${
                              singleStaff?.salary.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{moment(x?.createdAt).format('MMMM')}</div>
                            <div className="w-3/12 px-2 text-tableRowText">{moment(x?.createdAt).format('LL')}</div>
                            <div className="w-2/12 px-2 text-tableRowText">
                              &#x20B9; {x?.deduction ? (+x?.deduction).toFixed(2) : 0}
                            </div>
                            <div className="w-2/12 px-2 text-tableRowText">&#x20B9;{x?.amount}</div>
                            <div className="w-3/12 px-2 text-tableRowText">
                              &#x20B9;{x?.deduction ? (x?.amount - x?.deduction).toFixed(2) : x?.amount}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {update && (
        <div
          className="flex h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-2/5 flex pb-6 text-sm m-auto mt-10 flex-col rounded-2xl">
            <div className="flex w-full justify-between items-center relative ">
              <div className="border-b border-borderYellow w-full flex justify-between items-center">
                <p className="block font-bold mx-4 py-4 text-textBlue1">{selectedStaff.user_name}</p>
                <img
                  src="assets/img/Close.svg"
                  className="mr-4 h-3 cursor-pointer z-50"
                  alt=""
                  onClick={() => {
                    setUpdate(false);
                    selectStaff(null);
                  }}
                />
              </div>
            </div>
            <div className="flex px-4 py-4">
              <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                <label className="text-sm text-textDark">Current Salary</label>
                <input
                  type="text"
                  // placeholder="Eg-10"
                  value={selectedStaff.salary}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                  disabled
                />
              </div>
              <div className="w-1/10" />
              <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                <label className="text-sm text-textDark">
                  Increment Percentage<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Eg-15"
                  value={increment}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                  onChange={(e) => {
                    let inputValue = e.target.value || '';
                    if (e.target.value.startsWith('-')) {
                      inputValue = e.target.value.slice(1);
                    }
                    setIncrement(inputValue);
                  }}
                />
              </div>
            </div>
            <button
              className="border mx-4 px-6 py-2 rounded-3xl bg-primary border-primary text-xs font-medium text-white ml-auto focus:outline-none flex justify-center text-center items-center"
              onClick={handleUpdate}
            >
              Update Salary
            </button>
          </div>
        </div>
      )}
      {add && (
        <div
          className="flex h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-2/5 flex pb-6 text-sm m-auto mt-10 flex-col rounded-2xl">
            <div className="flex w-full justify-between items-center relative ">
              <div className="border-b border-borderYellow w-full flex justify-between items-center">
                <p className="block font-bold mx-4 py-4 text-textBlue1">{selectedStaff.user_name}</p>
                <img
                  src="assets/img/Close.svg"
                  className="mr-4 h-3 cursor-pointer z-50"
                  alt=""
                  onClick={() => {
                    setAdd(false);
                    selectStaff(null);
                  }}
                />
              </div>
            </div>
            <div className="flex px-4 py-4">
              <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                <label className="text-sm text-textDark">
                  Current Salary<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Eg-35000"
                  value={salary}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                  // disabled
                  onChange={(e) => {
                    let inputValue = e.target.value || '';
                    if (e.target.value.startsWith('-')) {
                      inputValue = e.target.value.slice(1);
                    }
                    setSalary(inputValue);
                  }}
                />
              </div>
            </div>
            <button
              className="border mx-4 px-6 py-2 rounded-3xl bg-primary border-primary text-xs font-medium text-white ml-auto focus:outline-none flex justify-center text-center items-center"
              onClick={handleAdd}
            >
              Add Salary
            </button>
          </div>
        </div>
      )}
      <div className=" w-full md:w-4/5">
        <div className="relative w-full pb-10">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold text-base w-full md:w-2/5">
                  <p>Staff and Salaries - Teaching Staff</p>
                </div>

                <div className="flex justify-end w-full md:w-3/5 pr-4">
                  <button
                    className="border w-40 py-2 mr-4 rounded-3xl bg-primary border-primary text-xs font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    onClick={() => setReleased(true)}
                  >
                    Mark Released
                  </button>
                  <ExcelExportBtn
                    data={excelStaffData}
                    text={'Generate Salary Data'}
                    width={'w-40'}
                    fileName={'TeachingStaffSalary.csv'}
                    headers={excelHeaders}
                  />
                  <DropDown
                    data={['All Staff', 'Ascending Order', 'Descending Order']}
                    width={'w-40'}
                    title={'Sort by'}
                    otherClasses={'ml-4'}
                    handleItemClick={(x) => {
                      setSort(x);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-2">
                      <div className="w-2/12 border-r-2 py-2 px-2">Name</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Designation</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Salary</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Update Salary</div>
                      <div className="w-2/12 py-2 px-2">View Details</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                    {isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : filteredStaffData.length > 0 ? (
                      filteredStaffData.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                              filteredStaffData.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{x?.user_name}</div>
                            <div className="w-2/12 px-2 text-textDark">{x?.username}</div>
                            <div className="w-2/12 px-2 text-textDark">{x?.designation || x?.roles?.join(', ')}</div>
                            <div className="w-2/12 px-2 text-tableRowText">
                              {x.salary && <span className="text-textDark">&#x20B9;</span>}
                              {!x.id ? 'Not Added' : x.salary}
                            </div>
                            <div className="w-2/12 flex items-center">
                              <button
                                className="w-9/12 border border-borderGrey text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  selectStaff(x);
                                  if (x.id) setUpdate(true);
                                  else setAdd(true);
                                }}
                              >
                                {x.id ? 'Update' : 'Add'}
                              </button>
                            </div>
                            <div className="w-2/12 flex items-center">
                              <button
                                className="w-9/12 border border-borderGrey text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  // setView(true);
                                  setStaffId(x.user_id);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full bg-white rounded-xl h-auto mt-4">
            <div className="items-center px-6 py-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold text-base w-full md:w-3/5">
                  <p>Staff and Salaries - Non-Teaching Staff</p>
                </div>
                <div className="flex justify-end w-full pr-4">
                  <button
                    type="submit"
                    className="border mr-4 w-40 py-2 rounded-3xl bg-primary border-primary text-xs font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    onClick={() => setReleased(true)}
                  >
                    Mark Released
                  </button>
                  <ExcelExportBtn
                    data={excelNonStaffData}
                    text={'Generate Salary Data'}
                    width={'w-40'}
                    fileName={'NonTeachingStaffSalary.csv'}
                    headers={excelHeaders}
                  />
                  <DropDown
                    data={['All Staff', 'Ascending Order', 'Descending Order']}
                    width={'w-40'}
                    otherClasses={'ml-4'}
                    title={'Sort by'}
                    handleItemClick={(x) => {
                      setNonStaffSort(x);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl min-w-700"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-2">
                      <div className="w-2/12 border-r-2 py-2 px-2">Name</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Designation</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Salary</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Update Salary</div>
                      <div className="w-2/12 py-2 px-2">View Details</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                    {isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : filteredNonTeachStaffData.length > 0 ? (
                      filteredNonTeachStaffData.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                              filteredNonTeachStaffData.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{x?.user_name}</div>
                            <div className="w-2/12 px-2">{x?.username}</div>
                            <div className="w-2/12 px-2">{x?.designation || x?.roles?.join(', ')}</div>
                            <div className="w-2/12 px-2 text-tableRowText">
                              {x.salary && <span className="text-textDark">&#x20B9;</span>}
                              {!x.id ? 'Not Added' : x.salary}
                            </div>
                            <div className="w-2/12 flex justify-center items-center">
                              <button
                                className="w-9/12 border border-borderGrey text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  selectStaff(x);
                                  if (x.id) setUpdate(true);
                                  else setAdd(true);
                                }}
                              >
                                {x.id ? 'Update' : 'Add'}
                              </button>
                            </div>
                            <div className="w-2/12 flex justify-center items-center">
                              <button
                                className="w-9/12 border border-borderGrey text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  setStaffId(x.user_id);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Salaries;
