import React, { useEffect, useState, useRef } from 'react';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import { CLASS_SUBJECTS, CLASS_SUBJECTS_MARKS } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';

function SubjectMarks({ selectSubjectFrrMarks, selectedSubjectForMarks }) {
  const [classSubjectsMarksResponse, getClassSubjectsMarks] = useApi();
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (selectedSubjectForMarks) {
      getClassSubjectsMarks(
        CLASS_SUBJECTS_MARKS(
          selectedSubjectForMarks.class.classId,
          selectedSubjectForMarks.section.sectionId,
          selectedSubjectForMarks.subject._id
        ),
        'GET'
      );
    }
  }, [selectedSubjectForMarks]);

  let excelHeaders = useRef([{ label: 'Student', key: 'student' }]);

  useEffect(() => {
    if (
      !classSubjectsMarksResponse.isLoading &&
      !classSubjectsMarksResponse.isError &&
      classSubjectsMarksResponse.data
    ) {
      setData(classSubjectsMarksResponse.data?.studentInfo || []);
      setHeaders(classSubjectsMarksResponse.data?.testType || []);

      classSubjectsMarksResponse.data?.testType.map(
        (y) => (excelHeaders.current = [...excelHeaders.current, { label: y, key: y }])
      );
    }
  }, [classSubjectsMarksResponse]);

  useEffect(() => {
    if (data.length > 0) {
      let x = data.map((x) => {
        const entry = {};
        entry.student = x.studentName || '--';

        headers.map((y) => (entry[y] = x.marks.find((z) => z.test_type === y)?.marks));
        return entry;
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-9/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-3 flex justify-between items-center">
          <span className="block font-bold ">{`${selectedSubjectForMarks.subject.subjectName} (${selectedSubjectForMarks.marks} Marks)`}</span>
          <div className="flex items-center">
            <div className="flex flex-col md:flex-row items-center">
              <ExcelExportBtn
                data={excelData}
                headers={excelHeaders.current}
                text={'Export Data'}
                fileName={selectedSubjectForMarks.subject.subjectName + '_Marks.csv'}
                width={'w-40'}
              />
              {/* 
              <DropDown
                data={['All', 'Completed', 'Incomplete']}
                width={'w-36'}
                title={'Sort By'}
                margin={'ml-4'}
                handleItemClick={() => {}}
              /> */}
            </div>
            <img
              src="assets/img/Close.svg"
              className=" h-3 ml-4 cursor-pointer"
              alt=""
              onClick={() => {
                selectSubjectFrrMarks(null);
              }}
            />
          </div>
        </div>
        <div className="mt-2 mb-4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-2 text-xs font-medium">
                  <div className="w-1/6 border-r-2 py-2 px-2">Student Name</div>
                  {headers.map((x, idx) => (
                    <div className={`w-1/6 ${idx !== headers.length - 1 && 'border-r-2'} py-2 px-2`}>{x}</div>
                  ))}
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                {classSubjectsMarksResponse.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
                ) : data.length == 0 ? (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                ) : (
                  data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={` flex w-full relative text-left py-3 px-2 text-xs font-medium items-center ${
                          idx !== data.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/6 px-2 py-2 text-textDark">{x.studentName}</div>
                        {headers.map((y) => (
                          <div className="w-1/6 pr-2 pl-4 py-2 text-textDark">
                            {x.marks.find((z) => z.test_type === y)?.marks}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectMarks;
