import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ _data, color, yLabels = [], labels }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: false,
        data: _data,
        fill: false,
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  const options = {
    title: {
      display: false,
      text: 'Line Chart',
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
          //   precision: 0,
          stepSize: 1,
          callback: (index, value) => {
            if (yLabels.length > 0) {
              if (value % 1 === 0) {
                return yLabels[value];
              }
            } else return value;
          },
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
