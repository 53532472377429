import { INSTITUTE_MANAGEMENT } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const TRANSPORT_ROUTE = `${INSTITUTE_MANAGEMENT}/institute/transport?year=${year}`;

export const GET_UNMAPPED_STUDENTS = `${INSTITUTE_MANAGEMENT}/institute/students/unmapped?year=${year}`;

export const MAP_STUDENT = `${INSTITUTE_MANAGEMENT}/institute/class/section/student/map?year=${year}`;

export const STUDENT_SUBJECT = `${INSTITUTE_MANAGEMENT}/institute/student/subject?year=${year}`;

export const TEACHER_SUBJECT = (classId, sectionId) =>
  `${INSTITUTE_MANAGEMENT}/institute/class/${classId}/section/${sectionId}/subject?year=${year}`;

export const UPLOAD_COURSE = (classId, sectionId, subjectId) =>
  `${INSTITUTE_MANAGEMENT}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/course?year=${year}`;

export const TRANSPORT_FEE = `${INSTITUTE_MANAGEMENT}/institute/transport/fee?year=${year}`;
