import React, { useEffect, useState } from 'react';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import Search from '../../../components/UI/Search';
import useApi from '../../../api/useApi';
import { EDIT_ADDITIONAL_RESPONSIBILITY } from '../../../api/urls/institute';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Role', key: 'role' },
  { label: 'Additional Responsibility', key: 'responsibility' },
];

function AdditionalResponsibilityMapped({ data, setViewUser, getRole, refreshData }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  const [{ isLoading, isError, status }, deleteRecord] = useApi();

  const handleDelete = (id, category) => {
    if (confirm('Are you sure you want to unMap responsibility'))
      deleteRecord(EDIT_ADDITIONAL_RESPONSIBILITY(id, category), 'DELETE');
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      refreshData();
      alert('SUCCESS');
    }

    if (isError && status == 400) {
      alert('FAILED');
    }
  }, [isLoading, isError, status]);

  useEffect(() => {
    if (data?.data?.response && Array.isArray(data.data.response)) {
      let c = [];
      data.data.response.forEach((x) =>
        x?.currentlyMapped?.additionalResponsibilty.forEach((y) => {
          c.push({
            name: x?.full_name,
            username: x?.username,
            role: x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ') || '',
            responsibility: y.category,
          });
        })
      );

      if (Array.isArray(c)) setExcelData(c);
    }
  }, [data]);

  let filteredData = data?.data?.response || [];

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = filteredData.sort((a, b) => (a?.full_name.toLowerCase() > b?.full_name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name- z to a') {
      filteredData = filteredData.sort((a, b) => (a?.full_name.toLowerCase() < b?.full_name.toLowerCase() ? 1 : -1));
    }
  }

  if (search) {
    filteredData = filteredData.filter(
      (x) =>
        x?.full_name.toLowerCase().includes(search.toLowerCase()) ||
        x?.username.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div className="relative w-full pb-10">
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold text-base w-full md:w-2/5">
              <p>Additional Responsibility - Mapped</p>
            </div>

            <div className="flex justify-end w-full md:w-3/5 gap-x-6">
              <ExcelExportBtn data={excelData} text={'Export Data'} headers={excelHeaders} fileName={'mapped.csv'} />
              <DropDown
                data={['Name- a to z', 'Name- z to a']}
                width={'w-32 md:w-1/4'}
                title={'Sort By'}
                handleItemClick={(x) => {
                  setSort(x);
                }}
              />
              <Search
                onChange={(x) => {
                  setSearch(x);
                }}
                onEnter={() => {}}
                width={'w-36'}
                placeholder={'Search staff '}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl mx-5 border-borderYellow overflow-hidden min-w-700">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-xs font-medium px-2">
                  <div className="w-1/5 border-r-2 py-2 px-2">ROLEDin Id</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Name</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Role</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Additional Responsibility</div>
                  <div className="w-1/5 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                {data.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : filteredData.length > 0 ? (
                  filteredData.map((x, _idx) =>
                    x?.currentlyMapped?.additionalResponsibilty.map((y, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                            (x?.currentlyMapped?.additionalResponsibilty.length - 1 !== idx ||
                              filteredData.length - 1 !== _idx) &&
                            'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 px-2 text-textDark">{x?.username}</div>
                          <div className="w-1/5 px-2 text-tableRowText">{x?.full_name} </div>
                          <div className="w-1/5 px-2 text-tableRowText">
                            {x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ')}
                          </div>
                          <div className="w-1/5 px-2 text-tableRowText">{y.category}</div>
                          <div className="w-1/5 flex justify-center items-center pr-6 gap-x-4">
                            <button
                              className="w-10/12 border border-borderLight text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                setViewUser({ user: x, responsibility: y });
                              }}
                            >
                              View
                            </button>

                            <button
                              className="w-10/12 border border-borderLight text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                handleDelete(x?._id, y.category);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdditionalResponsibilityMapped;
