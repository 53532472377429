import React from 'react';
import moment from 'moment';

function SubmitSuccess({ selectedTest, handleDone }) {
  return (
    <>
      <div className="border-b border-borderYellow px-6 py-4 ">
        <div className="flex flex-col justify-between items-center">
          <span className="block font-bold mb-2">{`${selectedTest?.testDetail?.title}`}</span>
          <span className="block font-bold">{`${selectedTest.subjectCode} ${selectedTest.subject_name}`}</span>
        </div>

        <div className="flex justify-between my-2">
          <div className="flex flex-col justify-betwee">
            <span className="text-xs mb-1">{`Date - ${moment(selectedTest?.testDetail?.test_date).format('ll')}`}</span>
            <span className="text-xs">{`Time - ${moment(selectedTest?.testDetail?.end).diff(
              moment(selectedTest?.testDetail?.start),
              'minutes',
              true
            )} Minutes`}</span>
          </div>

          <div className="flex flex-col justify-between items-end">
            <span className="text-xs mb-1">{`Maximum Marks - ${selectedTest?.testDetail?.maxMarks}`}</span>
            <span className="text-xs">{`Minumum Marks - ${selectedTest?.testDetail?.minMarks}`}</span>
          </div>
        </div>
      </div>

      <div className="px-6 py-4 w-full flex flex-col">
        <p className="text-3xl mb-2 text-center py-10">Copy Submitted Successfully</p>

        <button
          type="submit"
          className={`border py-2 w-40 rounded-3xl text-xs font-medium focus:outline-none ml-auto text-center text-white 
      bg-primary border-primary`}
          onClick={() => {
            handleDone();
          }}
        >
          Back To Home
        </button>
      </div>
    </>
  );
}

export default SubmitSuccess;
