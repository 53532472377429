import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { CREATE_USER, SEND_EMAIL, SET_TEMP_PWD } from '../../../api/urls/user';
import DropDown from '../../UI/DropDown';

function ViewStudentDetails({ toggleView, selectedStudent, selectedStep, refreshPasswords }) {
  const [step, setStep] = useState(selectedStep);
  const [medicalCert, setMedicalCert] = useState();
  const [transferCertificate, setTransferCertificate] = useState();
  const [provisionalCertificate, setProvisionalCertificate] = useState();
  const [otherCertificate, setOtherCertificate] = useState();
  const [supportingDocument, setSupportingDocument] = useState();
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState();
  const [markSheet, setMarkSheet] = useState();
  const [addressProof, setAddress] = useState();
  const [type, setType] = useState();
  // const medicalRef = useRef();
  // const markSheetRef = useRef();
  // const transferRef = useRef();
  // const provisionalRef = useRef();
  // const otherRef = useRef();
  // const supportingRef = useRef();
  // const addressRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  const infoModel = {
    full_name: '',
    gender: '',
    placeOfBirth: '',
    dateOfBirth: '',

    // aadhar: '',

    roleType: 'st',
    role: 'student',
  };

  const parentModel = {
    companyName: '',
    designation: '',
    officeAddress: '',
    phone: '',
    email_Id: '',
    role: 'parent',
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const lastSchoolModel = {
    schoolName: '',
    class: '',
    address: '',
    medium: '',
  };

  const roledInModel = {
    userName: '',
    fullName: '',
    emailId: '',
    contact: '',
  };

  const [infoState, setInfoState] = useState(infoModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [caddressState, setcAddressState] = useState(addressModel);
  const [fatherState, setFatherState] = useState({ father_name: '', ...parentModel });
  const [motherState, setMotherState] = useState({ mother_name: '', ...parentModel });
  const [guardianState, setGuardianState] = useState({ guardian_name: '', ...parentModel });
  const [lastSchoolState, setLastSchoolState] = useState(lastSchoolModel);

  const [userRoledInId, setUserRoledInId] = useState(roledInModel);
  const [fatherRoledInId, setFatherRoledInId] = useState(roledInModel);
  const [motherRoledInId, setMotherRoledInId] = useState(roledInModel);

  const [isFatherChecked, checkFather] = useState(true);
  const [isMotherChecked, checkMother] = useState(false);
  const [isGuardianChecked, checkGuardian] = useState(false);
  const [isSameAddress, setSameAddress] = useState(false);

  const handleInfoChange = (e) => setInfoState({ ...infoState, [e.target.name]: e.target.value });

  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const handlecAddressInputChange = (e) => setcAddressState({ ...caddressState, [e.target.name]: e.target.value });

  const handleLastSchoolChange = (e) => setLastSchoolState({ ...lastSchoolState, [e.target.name]: e.target.value });

  const handleFatherChange = (e) => setFatherState({ ...fatherState, [e.target.name]: e.target.value });

  const handleMotherChange = (e) => setMotherState({ ...motherState, [e.target.name]: e.target.value });

  const handGuardianChange = (e) => setGuardianState({ ...guardianState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createStaff] = useApi();
  const [sendEmailToUserRes, sendEmail] = useApi();
  const [setPasswordRes, setPassword] = useApi();

  useEffect(() => {
    if (selectedStudent && typeof selectedStudent === 'object') {
      setInfoState(selectedStudent);
      setAddressState(typeof selectedStudent.address === 'object' ? selectedStudent.address : addressModel);
      setcAddressState(selectedStudent?.caddress || addressModel);
      setLastSchoolState(selectedStudent.lastSchool || lastSchoolModel);
      setUserRoledInId({
        emailId: selectedStudent.email_id,
        userName: selectedStudent.username,
        fullName: selectedStudent.full_name,
        contact: selectedStudent.phone,
      });
      if (selectedStudent.parentDetail?.length > 0) {
        selectedStudent.parentDetail.forEach((x) => {
          if ('father_name' in x) {
            setFatherState(x);
            setFatherRoledInId({
              fullName: x.father_name,
              emailId: x.email_Id,
              userName: selectedStudent.parent1?.username,
              contact: x.phone,
            });
          } else if ('mother_name' in x) {
            setMotherState(x);
            setMotherRoledInId({
              fullName: x.mother_name,
              emailId: x.email_Id,
              userName: selectedStudent.parent2?.username,
              contact: x.phone,
            });
          } else if ('guardian_name' in x) setGuardianState(x);
        });
      }

      if (selectedStudent.fileArray.length > 0) {
        setImage(getFile('profileImg'));
      }
      // setFatherState(selectedStudent);
      // setMotherState(selectedStudent);
      // setGuardianState(selectedStudent);
      // setFeeState(selectedStudent);
    }
  }, [selectedStudent]);

  const getFile = (fileName) => {
    let z = selectedStudent.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const parentDetail = [];
    if (fatherState.father_name) parentDetail.push(fatherState);
    if (motherState.mother_name) parentDetail.push(motherState);
    if (guardianState.guardian_name) parentDetail.push(guardianState);

    const formData = new FormData();
    // formData.append('markSheet', markSheet);
    // formData.append('medicalCertificate', medicalCert);
    // formData.append('transferCertificate', transferCertificate);
    // formData.append('provisionalCertificate', provisionalCertificate);
    // formData.append('otherCertificate', otherCertificate);
    // formData.append('supportingDocument', supportingDocument);
    formData.append('profileImg', profileImg);
    // formData.append('addressProof', addressProof);

    formData.append('address', JSON.stringify(addressState));
    formData.append('caddress', JSON.stringify(caddressState));
    formData.append('lastSchool', JSON.stringify(lastSchoolState));
    formData.append('parentDetail', JSON.stringify(parentDetail));
    formData.append('full_name', infoState.full_name);
    formData.append('email_id', infoState.email_id);
    formData.append('gender', infoState.gender);
    formData.append('placeOfBirth', infoState.placeOfBirth);
    formData.append('dateOfBirth', infoState.dateOfBirth);
    // formData.append('aadhar', infoState.aadhar);
    formData.append('roleType', 'st');
    formData.append('role', 'student');

    // const body = {
    //   ...infoState,
    //   address: addressState,
    //   caddress: caddressState,
    //   lastSchool: lastSchoolState,
    //   parentDetail,
    //   username: `${infoState.full_name.substring(0, 2)}${new Date().getFullYear()}`,
    // };
    // console.log(body);
    createStaff(CREATE_USER, 'POST', formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setInfoState(infoModel);
      setAddressState(addressModel);
      setcAddressState(addressModel);
      setLastSchoolState(lastSchoolModel);
      setFatherState({ father_name: '', ...parentModel });
      setMotherState({ mother_name: '', ...parentModel });
      setGuardianState({ guardian_name: '', ...parentModel });
    }
  }, [isLoading, isError, data, status]);

  const sendDetails = () => {
    if (type === 'student') {
      if (userRoledInId.emailId) {
        let body = { ...userRoledInId };
        sendEmailToUser(body);
      } else if (fatherRoledInId.emailId) {
        let body = {
          ...userRoledInId,
          emailId: fatherRoledInId.emailId,
        };
        sendEmailToUser(body);
      } else if (motherRoledInId.emailId) {
        let body = {
          ...userRoledInId,
          emailId: motherRoledInId.emailId,
        };
        sendEmailToUser(body);
      } else {
        sendEmailToCLassTeacher(userRoledInId.userName, userRoledInId.fullName);
      }
    } else if (type === 'father') {
      if (fatherRoledInId.emailId) {
        let body = {
          ...fatherRoledInId,
        };
        sendEmailToUser(body);
      } else {
        sendEmailToCLassTeacher(fatherRoledInId.userName, fatherRoledInId.fullName);
      }
    } else if (type === 'mother') {
      if (motherRoledInId.emailId) {
        let body = {
          ...motherRoledInId,
        };
        sendEmailToUser(body);
      } else {
        sendEmailToCLassTeacher(motherRoledInId.userName, motherRoledInId.fullName);
      }
    }
  };

  const sendEmailToUser = (body) => {
    sendEmail(SEND_EMAIL, 'POST', body);
  };

  const sendEmailToCLassTeacher = (userName, fullName) => {
    const body = {
      userName,
      fullName,
    };
    setPassword(SET_TEMP_PWD, 'POST', body);
  };

  useEffect(() => {
    if (!sendEmailToUserRes.isLoading && !sendEmailToUserRes.isError && sendEmailToUserRes.status == 200) {
      alert('Email sent successfully');
    }

    if (!sendEmailToUserRes.isLoading && sendEmailToUserRes.isError) {
      alert('Something went wrong');
    }
  }, [sendEmailToUserRes]);

  useEffect(() => {
    if (!setPasswordRes.isLoading && !setPasswordRes.isError && setPasswordRes.status == 201) {
      alert('User Password is stored');
      refreshPasswords();
    }

    if (!setPasswordRes.isLoading && setPasswordRes.isError) {
      alert('Something went wrong');
    }
  }, [setPasswordRes]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-4/5 pb-6 mx-auto h-auto mt-16 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-5">
            <div className="font-bold px-8">
              <p>Student Detail</p>
            </div>
            <div className="flex px-8">
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleView} />
            </div>
          </div>
        </div>

        <div className="py-2 px-8 relative grid grid-cols-3 md:grid-cols-5 gap-x-4 border-b border-borderYellow">
          <button
            onClick={() => setStep(0)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 0 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Student Information
          </button>
          <button
            onClick={() => setStep(1)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 1 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Parents Information
          </button>
          <button
            onClick={() => setStep(2)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 2 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Address
          </button>
          <button
            onClick={() => setStep(3)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 3 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Last School
          </button>
          <button
            onClick={() => setStep(4)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 4 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            ROLEDin ID
          </button>
        </div>

        {step === 0 && (
          <form autoComplete="off" className="px-8">
            <div className="items-center py-4 ">
              <p className=" font-medium ">Student Information</p>
            </div>

            <div className="flex flex-col md:flex-row w-full">
              <div>
                <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                  {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full md:pl-12">
                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">Full Name</label>
                  <input
                    type="text"
                    value={infoState.full_name}
                    name="full_name"
                    onChange={handleInfoChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">
                    Email<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="email"
                    required
                    value={infoState.email_id}
                    name="email_id"
                    onChange={handleInfoChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">Gender</label>
                  <DropDown
                    data={['Male', 'Female']}
                    handleItemClick={(x) => {
                      handleInfoChange({ target: { value: x, name: 'gender' } });
                    }}
                    title={selectedStudent ? infoState.gender : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">Place of Birth</label>
                  <input
                    type="text"
                    value={infoState.placeOfBirth}
                    name="placeOfBirth"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label className="text-textDark font-medium text-sm">Date of Birth</label>
                  {selectedStudent ? (
                    <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                      {moment(infoState.dateOfBirth).format('D MMMM YYYY')}
                    </p>
                  ) : (
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={infoState.dateOfBirth}
                      onChange={(date) => setInfoState({ ...infoState, dateOfBirth: date })}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  )}
                </div>

                {/* <div className="my-2 w-full relative font-semibold ">
                  <label  className="text-textDark font-medium text-sm">
                    AADHAR
                  </label>
                  <input
                    type="text"
                    value={infoState.aadhar}
                    name="aadhar"
                    onChange={handleInfoChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div> */}
              </div>
            </div>

            <div className="flex gap-x-4 justify-end">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */}
              <button
                className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(1);
                }}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {step === 1 && (
          <form autoComplete="off ">
            <p className=" font-bold text-black pt-4 px-8">Father</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Name</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={fatherState.father_name}
                  name="father_name"
                  onChange={handleFatherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Company Name</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={fatherState.companyName}
                  name="companyName"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Designation</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={fatherState.designation}
                  name="designation"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Office Address</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={fatherState.officeAddress}
                  name="officeAddress"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact Number</label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={fatherState.phone}
                  name="phone"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={fatherState.email_Id}
                  name="email_Id"
                  onChange={handleFatherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Mother</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Name</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={motherState.mother_name}
                  name="mother_name"
                  onChange={handleMotherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Company Name</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={motherState.companyName}
                  name="companyName"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Designation</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={motherState.designation}
                  name="designation"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Office Address</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={motherState.officeAddress}
                  name="officeAddress"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact Number</label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={motherState.phone}
                  name="phone"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={motherState.email_Id}
                  name="email_Id"
                  onChange={handleMotherChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Guardian</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Name</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={guardianState.guardian_name}
                  name="guardian_name"
                  onChange={handGuardianChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Company Name</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={guardianState.companyName}
                  name="companyName"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Designation</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={guardianState.designation}
                  name="designation"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Office Address</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={guardianState.officeAddress}
                  name="officeAddress"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact Number</label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={guardianState.phone}
                  name="phone"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={guardianState.email_Id}
                  name="email_Id"
                  onChange={handGuardianChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-between px-8">
              <button
                className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              >
                Back
              </button>
              <button
                className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep(2);
                }}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {step === 2 && (
          <form autoComplete="off ">
            <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={addressState?.hno}
                  name="hno"
                  onChange={handleAddressInputChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Colony / Society</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={addressState?.society}
                  name="society"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">District</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={addressState?.district}
                  name="district"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">City</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={addressState?.city}
                  name="city"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">State</label>
                <select
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={addressState?.state}
                  name="state"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                >
                  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Ladakh">Ladakh</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Pin Code</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={addressState?.pincode}
                  name="pincode"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Address Proof Document</label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  // value={infoState.full_name}
                  name="full_name"
                  // onChange={handleInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Upload Document</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  // value={infoState.full_name}
                  name="full_name"
                  // onChange={handleInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={caddressState.hno}
                  name="hno"
                  onChange={handlecAddressInputChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Colony / Society</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={caddressState.society}
                  name="society"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">District</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={caddressState.district}
                  name="district"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">City</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={caddressState.city}
                  name="city"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">State</label>
                <select
                  type="text"
                  // placeholder="Eg-Punjab"
                  value={caddressState.state}
                  name="state"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                >
                  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Ladakh">Ladakh</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Pin Code</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  value={caddressState.pincode}
                  name="pincode"
                  onChange={handlecAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Address Proof Document</label>
                <input
                  type="text"
                  // placeholder="Eg-Punjab"
                  // value={infoState.full_name}
                  name="full_name"
                  // onChange={handleInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Upload Document</label>
                <input
                  type="text"
                  // placeholder="Eg-141401"
                  // value={infoState.full_name}
                  name="full_name"
                  // onChange={handleInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-between px-8">
              <button
                className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              >
                Back
              </button>
              <div className="flex gap-x-4">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {}}
                >
                  Save
                </button>
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}

        {step === 3 && (
          <form autoComplete="off ">
            <p className=" font-bold text-black pt-4 px-8">Last School</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">School Name</label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  value={lastSchoolState.schoolName}
                  name="schoolName"
                  onChange={handleLastSchoolChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Class</label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={lastSchoolState.class}
                  name="class"
                  onChange={handleLastSchoolChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Address</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  value={lastSchoolState.address}
                  name="address"
                  onChange={handleLastSchoolChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Medium</label>
                <DropDown
                  data={['English', 'Hindi']}
                  handleItemClick={(x) => {
                    handleLastSchoolChange({ target: { value: x, name: 'medium' } });
                  }}
                  title={selectedStudent ? lastSchoolState.medium : 'Select'}
                  width={'w-full'}
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-between px-8">
              <button
                className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setStep((prev) => prev - 1);
                }}
              >
                Back
              </button>

              <div className="flex gap-x-4">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {}}
                >
                  Save
                </button>
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(4);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}

        {step === 4 && (
          <form autoComplete="off ">
            <p className=" font-bold text-black pt-4 px-8">Student’s ROLEDin Id</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">User Name</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-198"
                  value={userRoledInId?.userName}
                  name="father_name"
                  //    onChange={handleFatherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              {/* <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Temporary Password
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={userRoledInId?.tempPassword}
                  name="companyName"
                  onChange={(e) => {
                    setUserRoledInId({
                      ...userRoledInId,
                      tempPassword: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div> */}
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact No</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-Jalandhar"
                  value={userRoledInId?.contact}
                  name="designation"
                  onChange={(e) => {
                    setUserRoledInId({
                      ...userRoledInId,
                      contact: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-Jalandhar"
                  value={userRoledInId?.emailId}
                  name="officeAddress"
                  onChange={(e) => {
                    setUserRoledInId({
                      ...userRoledInId,
                      email: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="flex justify-end px-8 md:col-span-2 -mr-8 mt-4">
                {type === 'student' && (sendEmailToUserRes.isLoading || setPasswordRes.isLoading) ? (
                  <img src="assets/img/loader.svg" className="w-14 " alt="loader" />
                ) : (
                  <button
                    className="w-36 border ml-auto py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setType('student');
                      sendDetails();
                    }}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Father’s ROLEDin Id</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">User Name</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-198"
                  value={fatherRoledInId?.userName}
                  name="mother_name"
                  //    onChange={handleMotherChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              {/* <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Temporary Password
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={fatherRoledInId?.tempPassword}
                  name="companyName"
                  onChange={(e) => {
                    setFatherRoledInId({
                      ...fatherRoledInId,
                      tempPassword: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div> */}
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact No</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-Jalandhar"
                  value={fatherRoledInId?.contact}
                  name="designation"
                  onChange={(e) => {
                    setFatherRoledInId({
                      ...fatherRoledInId,
                      contact: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-Jalandhar"
                  value={fatherRoledInId?.emailId}
                  name="officeAddress"
                  onChange={(e) => {
                    setFatherRoledInId({
                      ...fatherRoledInId,
                      email: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="flex justify-end px-8 md:col-span-2 -mr-8 mt-4">
                {type === 'father' && (sendEmailToUserRes.isLoading || setPasswordRes.isLoading) ? (
                  <img src="assets/img/loader.svg" className="w-14 " alt="loader" />
                ) : (
                  <button
                    className="w-36 border ml-auto py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setType('father');
                      sendDetails();
                    }}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>

            <p className=" font-bold text-black pt-4 px-8">Mother’s ROLEDin Id</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full  px-8">
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">User Name </label>
                <input
                  type="text"
                  disabled
                  // placeholder="Eg-198"
                  value={motherRoledInId?.userName}
                  name="guardian_name"
                  //    onChange={handGuardianChange}
                  autoComplete="off"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              {/* <div className="my-2 w-full relative font-semibold">
                <label  className="text-textDark font-medium text-sm">
                  Temporary Password
                </label>
                <input
                  type="text"
                  // placeholder="Eg-Amar Colony"
                  value={motherRoledInId?.tempPassword}
                  name="companyName"
                  onChange={(e) => {
                    setMotherRoledInId({
                      ...motherRoledInId,
                      tempPassword: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div> */}
              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Contact No</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  disabled
                  value={motherRoledInId?.contact}
                  name="designation"
                  onChange={(e) => {
                    setMotherRoledInId({
                      ...motherRoledInId,
                      contact: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label className="text-textDark font-medium text-sm">Email</label>
                <input
                  type="text"
                  // placeholder="Eg-Jalandhar"
                  disabled
                  value={motherRoledInId?.emailId}
                  name="officeAddress"
                  onChange={(e) => {
                    setMotherRoledInId({
                      ...motherRoledInId,
                      email: e.target.value,
                    });
                  }}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8">
              {/* <button
              className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
              onClick={() => {}}
            >
              Save
            </button> */}
              {type === 'mother' && (sendEmailToUserRes.isLoading || setPasswordRes.isLoading) ? (
                <img src="assets/img/loader.svg" className="w-14 " alt="loader" />
              ) : (
                <button
                  className="w-36 border ml-auto py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setType('mother');
                    sendDetails();
                  }}
                >
                  Send
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ViewStudentDetails;
