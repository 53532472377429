import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useStateValue } from '../../../ContextApi/StateProvider';
import DatePicker from 'react-datepicker';
import useApi from '../../../api/useApi';
import {
  STUDENT_ATTENDANCE,
  STUDENT_ATTENDANCE_FOR_PARENT,
  STUDENTS_LEAVE_DETAILS,
  STUDENT_LEAVE_APPLY,
} from '../../../api/urls/attendance';
import DropDown from '../../../components/UI/DropDown';

function Attendance({ role }) {
  const attachment = useRef();

  const [sort, setSort] = useState('');
  const [allEvents, setAllEvents] = useState([]);
  const [selectedAttachemnt, selectAttachemnt] = useState();
  const [selectedDay, selectDay] = useState();
  const [calenderFilter, setCalenderFilter] = useState('');

  const [{ user }, _] = useStateValue();

  const [attendanceState, getAttendance] = useApi();
  const [leaveRequest, getLeaveRequest] = useApi();
  const [formData, setFormData] = useState({
    leave: 'Select',
    reason: '',
    dateFrom: '',
    dateTo: '',
    days: 0,
    files: null,
  });

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, applyForLeave] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (moment(formData.dateTo).diff(moment(formData.dateFrom)) < 0) {
      alert('Start(From) Date should be less than end(To) Date');
      return;
    }

    const fd = new FormData();
    fd.append('leave', formData.leave);
    fd.append('reason', formData.reason);
    fd.append('date', moment(formData.dateFrom));
    // fd.append('days', moment(formData.dateTo).diff(moment(formData.dateFrom), 'days') + '');
    fd.append('files', formData.files);
    fd.append('days', formData.days);

    applyForLeave(STUDENT_LEAVE_APPLY, 'POST', fd);
  };

  useEffect(() => {
    if (formData.dateFrom && formData.dateTo) {
      if (moment(formData.dateTo).diff(moment(formData.dateFrom)) < 0) {
        alert('Start(From) Date should be less than end(To) Date');
        setFormData({ ...formData, dateTo: null });
        return;
      }

      setFormData({ ...formData, days: moment(formData.dateTo).diff(moment(formData.dateFrom), 'days') + 1 });
    }
  }, [formData.dateFrom, formData.dateTo]);

  useEffect(() => {
    if (!isLoading && !isError && status) { 
      if( data.ApiMsg  ){ alert(data.ApiMsg); }
      else { alert('Something unexpected happened.'); }
      if( data.isDone  ){ 
        handleReset();
        if (role === 'student') user && getAttendance(STUDENT_ATTENDANCE(user.user._id), 'GET');
        else {
          getAttendance(STUDENT_ATTENDANCE_FOR_PARENT, 'GET');
        }
        getLeaveRequest(STUDENTS_LEAVE_DETAILS, 'GET');
      } 
    }

    if (isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  const handleReset = () => {
    setFormData({ leave: 'Select', reason: '', date: '', days: '', dateTo: '', files: null });
    if (attachment.current) attachment.current.value = '';
  };

  useEffect(() => {
    getLeaveRequest(STUDENTS_LEAVE_DETAILS, 'GET');
  }, []);

  useEffect(() => {
    if (role === 'student') user && getAttendance(STUDENT_ATTENDANCE(user.user._id), 'GET');
    else {
      getAttendance(STUDENT_ATTENDANCE_FOR_PARENT, 'GET');
    }
  }, [user, role]);

  useEffect(() => {
    if (
      !attendanceState.isLoading &&
      !attendanceState.isError &&
      attendanceState.data
      // !leaveRequest.isLoading &&
      // !leaveRequest.isError &&
      // leaveRequest.data
    ) {
      let x = [];
      x = Array.isArray(attendanceState?.data?.attendance)
        ? attendanceState?.data?.attendance
            .filter((c) => c.isPresent === true || c.isPresent === false)
            .map((x) => {
              return {
                title: x.isPresent ? 'Present' : 'Absent',
                allDay: true,
                start: new Date(x.date),
                end: new Date(x.date),
                remark: x.remark,
              };
            })
        : [];
      //
      if( Array.isArray(leaveRequest?.data)   )
      {leaveRequest?.data?.forEach((z) => {
        x.push({
          title: z?.leaveCategory || 'Leave',
          start: new Date(z.date),
          end: new Date(z.date),
        });
      });}

      setAllEvents(x);

      // let y = [];
      // y = Array.isArray(leaveRequest?.data)
      //   ? leaveRequest?.data.map((x) => {
      //       return {
      //         title: x.leave,
      //         allDay: true,
      //         start: new Date(x.start_date),
      //         end: new Date(x.end_date),
      //       };
      //     })
      //   : [];

      // setAllEvents([...y, ...x]);
    }
  }, [attendanceState]);

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.title.includes('Absent') && {
        className: 'redEventColor',
      }),
      ...(event.title.includes('Present') && {
        className: 'greenEventColor',
      }),
    }),
    []
  );

  let sortedData = Array.isArray(leaveRequest?.data) ? leaveRequest?.data : [];
  // console.log(" -- leave Request Data : ", leaveRequest?.data ); 
  if (sort === 'Latest') {
    sortedData = sortedData.sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1));
  } else if (sort === 'Oldest') {
    sortedData = sortedData.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1));
  }

  let calenderData = allEvents || [];

  if (calenderFilter === 'Absent') {
    calenderData = calenderData.filter((x) => x.title === 'Absent');
  } else if (calenderFilter === 'Present') {
    calenderData = calenderData.filter((x) => x.title === 'Present');
  }

  return (
    <>
      {selectedAttachemnt && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">{`Attachment - ${selectedAttachemnt?.leave}`}</span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectAttachemnt(null)}
            />
            <div className="mt-2 mb-4 mx-6 ">
              <div className="mt-2 mb-4 relative font-semibold ">
                {selectedAttachemnt?.leaveAttachments?.length > 0 ? (
                  <img
                    src={selectedAttachemnt?.leaveAttachments[0]?.location}
                    className="w-auto mx-auto p-6"
                    style={{ height: '75vh' }}
                    alt=""
                  />
                ) : (
                  <div className="border h-32 flex justify-center items-center mt-2 rounded-2xl">No Attachment</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedDay && (
        <div
          className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 mb-auto mt-16 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 border-b border-borderYellow">{`${moment(
              selectedDay.start
            ).format('DD MMM YYYY')} - ${selectedDay?.title}`}</span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectDay(null)}
            />
            <div className="mt-3 mb-4 mx-6 ">
              <div className="mt-2 mb-4 relative font-semibold ">
                {selectedDay?.remark ? (
                  <div>
                    <p className="text-black text-base font-bold mb-3">Attendance Remark</p>
                    <p className="text-textDark text-xs font-medium">{selectedDay?.remark}</p>
                  </div>
                ) : (
                  <div className=" flex justify-center items-center mt-6 rounded-2xl">No Remark given.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full md:w-4/5">
        <div className=" bg-white rounded-xl h-auto mb-5 w-full">
          <div
            className="items-center px-6 py-3.5 flex justify-between"
            style={{ borderBottom: '0.71px solid #E7E700' }}
          >
            <p className="font-bold text-base">{`Attendance (April ${new Date().getFullYear()} - March ${
              new Date().getFullYear() + 1
            })`}</p>
            <div className="relative mx-2 w-1/2 md:w-1/6">
              <DropDown
                data={['All', 'Absent', 'Present']}
                width={'w-36'}
                title={'Sort By'}
                handleItemClick={(x) => {
                  setCalenderFilter(x);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl" style={{ borderColor: '#E7E700', width: '96%' }}>
                <Calendar
                  localizer={localizer}
                  events={calenderData}
                  style={{ height: 400 }}
                  views={['month']}
                  onSelectEvent={(e) => selectDay(e)}
                  eventPropGetter={eventPropGetter}
                />
              </div>
            </div>
          </div>
        </div>

        {role !== 'student' && (
          <div className=" bg-white rounded-xl h-auto mb-5 w-full">
            <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className="font-bold text-base">Leave Application</p>
            </div>
            <form className="mt-2 mb-4 mx-6 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
              <div className="gap-x-6 flex flex-col md:flex-row w-full">
                <div className="mt-2 w-full relative font-semibold disabled">
                  <label htmlFor="testType" className="block text-parameter text-sm">
                    Select Type
                  </label>
                  <div className="border border-borderLight rounded-3xl px-4 mt-2 py-2 focus:outline-none text-xs">
                    <select
                      type="text"
                      autoComplete="off"
                      name="leave"
                      value={formData.leave}
                      onChange={handleInputChange}
                      className="w-full"
                    >
                      <option value="Select">Select</option>
                      <option value="Medical Leave">Medical</option>
                      {/* <option value="Duty Leave">Duty</option> */}
                    </select>
                  </div>
                </div>

                <div className="mt-2 mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-sm">
                    Total Number of Days<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="number"
                    name="days"
                    disabled
                    value={formData.days}
                    onChange={handleInputChange}
                    className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                  />
                </div>
              </div>

              <div className="gap-x-6 flex flex-col md:flex-row w-full">
                <div className="mt-1 mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-sm">
                    From Date<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DatePicker
                    className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    selected={formData.dateFrom}
                    required
                    onChange={(date) => setFormData({ ...formData, dateFrom: date })}
                    dateFormat={'dd-MM-yyyy'}
                  />
                </div>

                <div className="mt-1 mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-sm">
                    To Date<span className="text-red-500 ml-1">*</span>
                  </label>
                  <DatePicker
                    className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    selected={formData.dateTo}
                    onChange={(date) => setFormData({ ...formData, dateTo: date })}
                    dateFormat={'dd-MM-yyyy'}
                    required
                  />
                </div>
              </div>

              <div className="mt-1 mb-4 w-full relative font-semibold">
                <label className="block text-parameter text-sm">Description</label>
                <textarea
                  rows="4"
                  cols="50"
                  name="reason"
                  value={formData.reason}
                  onChange={handleInputChange}
                  placeholder="Write your reason here"
                  className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="gap-x-6 flex w-full">
                <div className="mt-1 mb-4 w-full md:w-2.25/5 relative font-semibold ">
                  <label className="block text-parameter text-sm">Upload Document</label>
                  <div className="relative flex">
                    <input
                      hidden
                      ref={attachment}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => setFormData({ ...formData, files: e.target.files[0] })}
                      className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <input
                      // required
                      type="text"
                      disabled
                      value={formData.files?.name || ''}
                      className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                      onClick={(e) => {
                        e.preventDefault();
                        attachment.current.click();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-end w-full mb-6">
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    <button
                      className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border border-gray-400 px-12 py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReset();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center bg-primary text-white"
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        )}

        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div className="flex flex-col md:flex-row justify-between md:items-center">
              <div className="font-bold text-base ">
                <p>Leave Application Status</p>
              </div>

              <div className="relative mx-2 ml-auto">
                <DropDown
                  data={['Latest', 'Oldest']}
                  width={'w-36'}
                  title={'Sort By'}
                  handleItemClick={(x) => {
                    setSort(x);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 overflow-auto" style={{ marginTop: '-1px' }}>
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-2xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-bold">
                    <div className="w-1/5 border-r-2 py-2 px-2">Leave Type</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Day</div>
                    {/* <div className="w-2/12 border-r-2 py-2 px-2">Leave From</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Leave To</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Total Days</div> */}
                    <div className="w-1/5 border-r-2 py-2 px-2">Action</div>
                    <div className="w-1/5  py-2 px-2">Status</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {leaveRequest.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : (
                    sortedData?.map((x, idx) => (
                      <div className="w-full py-2">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                            idx !== sortedData.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 text-tableRowText px-2">{x.leaveCategory || '--'}</div>
                          <div className="w-1/5 text-tableRowText px-2">{moment(x.date).format('ll')}</div>
                          <div className="w-1/5 text-tableRowText px-2">{x?.dayOfWeek}</div>
                          {/* <div className="w-2/12 text-tableRowText px-2">{x.days}</div> */}
                          <div className="w-1/5 px-2">
                          { x?.leaveAttachments?.length > 0 ?
                            <button
                              className="border border-red-400 px-2 py-2 rounded-3xl text-red-400 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                              onClick={() => selectAttachemnt(x)}
                            >
                              View Attachment
                            </button>: 
                            "No Attachment" }
                          </div>
                          <div className="w-1/5 text-tableRowText px-2">Approved</div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Attendance;
