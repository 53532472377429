import React from 'react';
import moment from 'moment';

function AttendanceRegularization({ data, select }) {
  let filteredData = [];
  if (data?.data?.staffHistory?.length)
    filteredData = data?.data?.staffHistory?.filter((item) => item?.status != 'Full Time');
  // let filteredData = [
  //   {
  //     _id: '651a4cb749d0a5001ca855fe',
  //     isAr: true,
  //     status: 'Half-Day',
  //     shift: 'Half-Day',
  //     punchInTime: '4:53:11 AM',
  //     punchOutTime: '8:17:46 AM',
  //     hours: '3',
  //     date: '2023-10-02T04:53:11.000Z',
  //     userId: '64351aca7b6eb0001dc39ab8',
  //     instituteId: '6410a9883395dd001ce525da',
  //     location: {
  //       type: 'Point',
  //       coordinates: [74.7805645, 32.7983082],
  //     },
  //   },
  //   {
  //     _id: '651cfc76334bcf001d94ed6d',
  //     isAr: true,
  //     status: 'Half-Day',
  //     shift: 'Half-Day',
  //     punchInTime: '5:47:34 AM',
  //     punchOutTime: '8:30:24 AM',
  //     hours: '3',
  //     date: '2023-10-04T05:47:34.000Z',
  //     userId: '64351aca7b6eb0001dc39ab8',
  //     instituteId: '6410a9883395dd001ce525da',
  //     location: {
  //       type: 'Point',
  //       coordinates: [74.7805624, 32.7983084],
  //     },
  //   },
  //   {
  //     _id: '651e3e94334bcf001d94ed72',
  //     isAr: true,
  //     status: 'Full Time',
  //     shift: 'Full Time',
  //     punchInTime: '4:41:56 AM',
  //     punchOutTime: '10:53:09 AM',
  //     hours: '6',
  //     date: '2023-10-05T04:41:56.000Z',
  //     userId: '64351aca7b6eb0001dc39ab8',
  //     instituteId: '6410a9883395dd001ce525da',
  //     location: {
  //       type: 'Point',
  //       coordinates: [74.7805673, 32.798314],
  //     },
  //   },
  // ].filter((item) => item?.status != 'Full Time' || item?.isAr == false);

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
        <div className="lg:flex block sm:block  items-center">
          <div className="font-medium text-base">
            <p>Attendance Regularization</p>
          </div>
          <div className="flex ml-auto">
            {/* <ExcelExportBtn text={'Export Data'} data={excelData} headers={excelHeaders} fileName={'leaves.csv'} /> */}
            <div className="relative ml-4">
              {/* <DropDown
            data={['Newest to oldest', 'Oldest to Newest']}
            width={'w-40'}
            title={'Sort By'}
            handleItemClick={(x) => {
              setSort(x);
            }}
          /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl min-w-700"
            style={{ borderColor: '#E7E700', width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-1/5 border-r-2 py-2 px-2">Date</div>
                <div className="w-1/5 py-2 px-2">Worked Hours</div>
                <div className="w-1/5 py-2 px-2">In Time</div>
                <div className="w-1/5 py-2 px-2">Out Time</div>
                <div className="w-1/5 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div key={x._id} className="w-full">
                    <div
                      className={`items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/5 px-2 text-tableRowText">{moment(x.date).format('ll')}</div>
                      <div className="w-1/5 px-2">{x?.hours}</div>
                      <div className="w-1/5 px-2">
                        {x?.punchInTime ? moment(new Date(`2/2/2020 ${x?.punchInTime} UTC`)).format('hh:mm A') : '--'}
                      </div>
                      <div className="w-1/5 px-2">
                        {x?.punchOutTime ? moment(new Date(`2/2/2020 ${x?.punchOutTime} UTC`)).format('hh:mm A') : '--'}
                      </div>
                      <div className="w-1/5">
                        <button
                          className={`w-8/12 border ${
                            x?.isAr ? 'border-primary bg-primary' : 'border-primaryLight bg-primaryLight'
                          } text-white px-4 py-2 rounded-3xl text-xs focus:outline-none`}
                          onClick={() => {
                            x?.isAr && select(x);
                          }}
                          disabled={!x?.isAr}
                        >
                          {x?.isAr ? 'Submit' : 'Submitted'}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceRegularization;
