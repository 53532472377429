import React from 'react';
import moment from 'moment';

function TestDetails({ selectedTest, setOpenDetails, startTest }) {
  const checkDateTime = () => {
    let time = moment();
    let beforeTime = moment(selectedTest?.testDetail.start).local();
    let afterTime = moment(selectedTest?.testDetail.end).local();

    if (
      moment(selectedTest?.testDetail.test_date).isSame(moment(), 'day') &&
      !time.isAfter(afterTime) &&
      !time.isBefore(beforeTime)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const downloadSyllabus = (x) => {
    if (!x || x.length == 0) {
      alert('No Syllabus uploaded');
      return;
    }

    const link = document.createElement('a');
    link.download = 'syllabus';
    link.href = x[0].location;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="flex justify-center  h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-3/5  text-sm m-auto rounded-2xl relative mt-20">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">{`${selectedTest?.testDetail?.title} - ${selectedTest?.subjectCode} ${selectedTest?.subject_name}`}</span>
          <img
            src="assets/img/Close.svg"
            className=" h-3 cursor-pointer"
            alt=""
            onClick={() => setOpenDetails(false)}
          />
        </div>
        <form className="my-4 px-6 " autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Date</label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
                {moment(selectedTest?.testDetail?.test_date).format('ll')}
              </p>
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Time</label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">{`${moment(
                selectedTest?.testDetail?.start
              ).format('hh:mm a')} to ${moment(selectedTest?.testDetail?.end).format('hh:mm a')}`}</p>
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Subject Code</label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedTest?.subjectCode}
              </p>
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label htmlFor="resignation_date" className="block text-textDark text-sm">
                Mode
              </label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedTest?.testDetail?.mode}
              </p>
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Maximum Marks</label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedTest?.testDetail?.maxMarks}
              </p>
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold ">
              <label className="block text-textDark text-sm">Minimum Marks</label>
              <p className="w-full text-tableRowText border-borderLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedTest?.testDetail?.minMarks}
              </p>
            </div>
          </div>

          <div className=" my-4 w-full flex gap-x-4 justify-end">
            {/* {response.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <> */}
            {/* {!checkDateTime() && ( */}
            <button
              onClick={(e) => {
                e.preventDefault();
                downloadSyllabus(selectedTest?.testDetail.syallabus);
              }}
              className="border py-2 w-40 rounded-3xl text-xs font-medium focus:outline-none text-center bg-primary border-primary text-white"
            >
              Download Syllabus
            </button>
            {/* )} */}

            <button
              type="submit"
              className={`border py-2 w-40 rounded-3xl text-xs font-medium focus:outline-none text-center text-white ${
                checkDateTime() && selectedTest?.testDetail.attemptAvailable > 0
                  ? 'bg-primary border-primary'
                  : 'bg-borderLight border-borderLight opacity-50 cursor-not-allowed'
              }`}
              onClick={() => {
                startTest(true);
                setOpenDetails(false);
              }}
              disabled={!checkDateTime() || selectedTest?.testDetail.attemptAvailable === 0}
            >
              Start Test
            </button>
            {/* </>
            )} */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default TestDetails;
