import moment from 'moment';
import React, { useState } from 'react';

const OtherHoliday = ({ handleCloseOther, handleAddOtherHoliday, date }) => {
  const formDataModel = { name: '', date, eventType: 'Other Holiday' };

  const [formData, setFormData] = useState(formDataModel);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.date) {
      handleAddOtherHoliday({ ...formData, date: moment(date).format('YYYY-MM-DD') });
      handleCloseOther();
    } else {
      alert('Fill all the values');
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();

    setFormData({ ...formDataModel, date });
  };

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-1/2 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">Add Other Holiday</span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleCloseOther} />
          </div>
        </div>

        <div className=" w-full  px-4 pb-6">
          <form className="px-4 pb-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-x-6 mt-4 relative">
              <div className="my-2 relative font-semibold w-full">
                <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                  Holiday Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Holiday Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                  Select Date
                </label>
                <p className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none">
                  {moment(date).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>

            <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
              {false ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <button
                    className="border border-borderLight px-12 py-2 text-xs rounded-3xl bg-white font-medium text-tableRowText focus:outline-none"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none"
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtherHoliday;
