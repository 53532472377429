import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

const Transport = ({ toggleView, selectedRoute }) => {
  const [sorting, setSorting] = useState(false);
  const [route, setRoute] = useState(false);
  const [facultyType, setFacultyType] = useState('busStaff');
  const [liveLocation, setLiveLocation] = useState(false);
  const [step, setStep] = useState(0);
  const [center, setCenter] = useState({
    lat: 32.874525,
    lng: -117.2160221,
  });

  const defaultProps = {
    center: {
      lat: 32.7266,
      lng: 74.857,
    },
    zoom: 18,
  };

  return (
    <>
      <div
        className=" w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
        style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
      >
        <div className="relative w-11/12 md:w-4/5 pb-6 mx-auto h-auto mt-12 bg-white rounded-2xl">
          <div className="text-sm block relative border-b border-borderYellow">
            <div className={`flex justify-between items-center ${liveLocation ? 'py-2' : 'py-4'}`}>
              <div className="font-bold px-8">
                <p>{`Route No. ${selectedRoute?.routeName}`}</p>
              </div>
              <div className="flex pr-8 items-center gap-x-6">
                {liveLocation && (
                  <button
                    className="focus:outline-none border border-primary text-white bg-primary text-xs rounded-full py-2 px-8"
                    onClick={() => setLiveLocation(false)}
                  >
                    Back
                  </button>
                )}
                <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleView} />
              </div>
            </div>
          </div>

          {liveLocation ? (
            <div className="flex p-4 flex-col items-end" style={{ height: '75vh' }}>
              <GoogleMapReact
                // key={coordinates}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                bootstrapURLKeys={{ key: '' }}
                // options={getMapOptions}
                // center={center}
                // onGoogleApiLoaded={({ map, maps }) => handlegoogleApi(map, maps, coordinates)}
                // yesIWantToUseGoogleMapApiInternals
              />
            </div>
          ) : (
            <>
              <div className="py-2 px-8 relative grid grid-cols-2 md:grid-cols-3 gap-x-8 border-b border-borderYellow">
                <button
                  onClick={() => setStep(0)}
                  className={`py-2 w-full rounded-full text-xs ${
                    step === 0 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
                  }`}
                >
                  {`Bus and Bus Staff(Total Buses - ${selectedRoute?.busDriverDetail.length})`}
                </button>
                <button
                  onClick={() => setStep(1)}
                  className={`py-2 w-full rounded-full text-xs ${
                    step === 1 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
                  }`}
                >
                  {`Faculty(Total Faculty-${selectedRoute?.facultyPassenger?.length})`}
                </button>
                <button
                  onClick={() => setStep(2)}
                  className={`py-2 w-full rounded-full text-xs ${
                    step === 2 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
                  }`}
                >
                  {`Students(Total Students-${selectedRoute?.studentPassenger?.length})`}
                </button>
              </div>

              {step === 0 &&
                selectedRoute?.busDriverDetail.map((x, idx) => (
                  <div
                    className={`py-4 px-8 ${
                      selectedRoute?.busDriverDetail.length - 1 !== idx && 'border-b border-borderYellow'
                    }`}
                  >
                    <div className="flex items-center justify-between ">
                      <p className="font-bold text-lg">{`Bus No. - ${x?.busNumber}`}</p>
                      <button
                        className="focus:outline-none border border-primary text-white bg-primary text-xs rounded-full py-2 px-5"
                        onClick={() => {
                          // setRoute(false);
                          setLiveLocation(true);
                        }}
                      >
                        Track Live Location
                      </button>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="mt-2 w-2.25/5 relative">
                        <label className="text-textDark font-medium text-xs">Driver Name</label>
                        <p className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none">
                          {x?.driverName}
                        </p>
                      </div>
                      <div className="w-1/10" />
                      <div className="mt-2 w-2.25/5 relative">
                        <label className="text-textDark font-medium text-xs">Conductor Name</label>
                        <p className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none">
                          {x?.conductor}
                        </p>
                      </div>

                      <div className=" mt-3 w-2.25/5 relative">
                        <label className="text-textDark font-medium text-xs">Driver's Driving License No.</label>
                        <p className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none">
                          {x?.driverLicenseNo}
                        </p>
                      </div>
                      <div className="w-1/10" />
                      <div className="mt-3 mb-4 w-2.25/5 relative">
                        <label className="text-textDark font-medium text-xs">Contact No.</label>
                        <p className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none">
                          {x?.contact}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              {step === 1 && (
                <>
                  <div className="flex flex-wrap w-full h-1/4">
                    <div className="w-full py-4">
                      <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
                        <div className="w-full flex bg-tableHeader rounded-3xl">
                          <div className="flex w-full relative text-left text-xs font-medium">
                            <div className="w-2/12 border-r-2 py-2 px-4">Photograph</div>
                            <div className="w-6/12 border-r-2 py-2 px-4">ROLEDin ID</div>
                            <div className="w-4/12 py-2 px-4">Name</div>
                          </div>
                        </div>
                        <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                          {selectedRoute?.facultyPassenger.map((x, idx) => (
                            <div className="w-full">
                              <div
                                className={`justify-center items-center flex w-full relative text-left py-2 text-xs  font-medium ${
                                  selectedRoute?.facultyPassenger.length - 1 !== idx && 'border-b border-borderYellow'
                                }`}
                              >
                                <div className="w-2/12 px-4 py-2">
                                  <img
                                    className="schoolLogo"
                                    style={{ width: '3em', height: '3em' }}
                                    src="assets/img/user_placeholder.svg"
                                  />
                                </div>
                                <div className="w-6/12 px-4 text-tableRowText">{x?.userName}</div>
                                <div className="w-4/12 px-4 text-tableRowText">{x?.name}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="flex flex-wrap w-full h-1/4">
                    <div className="w-full py-4">
                      <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
                        <div className="w-full flex bg-tableHeader rounded-3xl">
                          <div className="flex w-full relative text-left text-xs  font-medium">
                            <div className="w-2/12 border-r-2 py-2 px-4">Photograph</div>
                            <div className="w-4/12 border-r-2 py-2 px-4">ROLEDin ID</div>
                            <div className="w-4/12 py-2 px-4">Name</div>
                            <div className="w-2/12 py-2 px-4">Class</div>
                          </div>
                        </div>
                        <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                          {selectedRoute?.studentPassenger.map((x, idx) => (
                            <div className="w-full">
                              <div
                                className={`justify-center items-center flex w-full relative text-left py-2 text-xs  font-medium ${
                                  selectedRoute?.studentPassenger.length - 1 !== idx && 'border-b border-borderYellow'
                                }`}
                              >
                                <div className="w-2/12 px-4 py-2">
                                  <img
                                    className="schoolLogo"
                                    style={{ width: '3em', height: '3em' }}
                                    src="assets/img/user_placeholder.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="w-4/12 px-4 text-tableRowText">{x?.userName}</div>
                                <div className="w-4/12 px-4 text-tableRowText">{x?.name}</div>
                                <div className="w-2/12 px-4 text-tableRowText">{x?.class}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Transport;
