import React, { useState, useEffect } from 'react';
import Search from '../../../components/Owner/Student/Search';
import StudentProfile from '../../../components/StudentProfile';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { PENDING_FEE } from '../../../api/urls/dashboard';
import { CLASS_SECTIONS, SECTION_STUDENTS } from '../../../api/urls/institute';
import { SEND_MESSAGE } from '../../../api/urls/user';

const Students = () => {
  const msg = React.useRef();

  const [message, setMessage] = useState(false);
  const [showDetail, setDetail] = useState(false);
  const [selectedClass, selectClass] = useState();

  const [selectedClassForFee, selectClassForFee] = useState();

  const [classSections, setClassSections] = useState([]);
  const [studentList, setStudents] = useState([]);
  const [pendingFeesList, setPendingFees] = useState([]);

  const [pendingFees, getPendingFees] = useApi();
  const [classSectionResponse, getClassSections] = useApi();
  const [students, getStudents] = useApi();
  const [msgRes, sendMessage] = useApi();

  useEffect(() => {
    getPendingFees(PENDING_FEE, 'GET');
    getClassSections(CLASS_SECTIONS, 'GET');
  }, []);

  useEffect(() => {
    if (!classSectionResponse.isLoading && !classSectionResponse.isError && classSectionResponse.data) {
      setClassSections(classSectionResponse.data.studentInfo);
    }
  }, [classSectionResponse]);

  const hideDetail = () => {
    setDetail((prev) => !prev);
  };

  const handleSelectClass = (classId, sectionId) => {
    getStudents(SECTION_STUDENTS(classId, sectionId), 'GET');
  };

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
    }
  }, [students]);

  useEffect(() => {
    if (!pendingFees.isLoading && !pendingFees.isError && pendingFees.data) {
      if (pendingFees?.data?.classWiseReport) setPendingFees(pendingFees.data.classWiseReport);
    }
  }, [pendingFees]);

  const getPhase = (_class) => {
    if (!_class) return '';

    if (_class.toLowerCase().includes('kg') || _class.toLowerCase().includes('nursery')) {
      return 'Pre Primary';
    } else if (_class.includes(11) || _class.includes(12)) {
      return 'Senior Secondary';
    } else if (_class.includes(9) || _class.includes(10)) {
      return 'Secondary';
    } else if (_class.includes(6) || _class.includes(7) || _class.includes(8)) {
      return 'Middle';
    } else if (
      _class.includes(1) ||
      _class.includes(2) ||
      _class.includes(3) ||
      _class.includes(4) ||
      _class.includes(5)
    ) {
      return 'Primary';
    }
  };

  const refreshList = () => {
    getStudents(SECTION_STUDENTS(selectedClass.classId, selectedClass.sectionId), 'GET');
  };

  const handleSendMessage = () => {
    const body = {
      message: msg.current.value,
    };
    sendMessage(SEND_MESSAGE(message.student_id), 'POST', body);
  };

  useEffect(() => {
    if (!msgRes.isLoading && !msgRes.isError && msgRes.status === 200) {
      alert('Success');
      msg.current.value = '';
      setMessage(false);
    }

    if (!msgRes.isLoading && msgRes.isError) {
      alert('Failed');
    }
  }, [msgRes]);

  return (
    <div className="w-full md:w-4/5">
      {/* {search && (
        <Search
          searchName={searchName}
          setSearch={setSearch}
          setMessage={setMessage}
          choice={choice}
          searchType={searchType}
          setDetail={setDetail}
        />
      )} */}
      {selectedClassForFee && (
        <Search setMessage={setMessage} selectedClass={selectedClassForFee} selectClassForFee={selectClassForFee} />
      )}
      {message && (
        <div
          className="flex h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-5/12  text-sm mb-auto mx-auto mt-16 block rounded-2xl relative">
            <p className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
              {message?.name}{' '}
              <span className="text-tableRowText font-normal text-xs">{`(${selectedClassForFee?.class} - ${selectedClassForFee?.section})`}</span>
            </p>
            <img
              src="assets/img/Close.svg"
              className="absolute top-5 right-8 h-3 cursor-pointer"
              alt=""
              onClick={() => setMessage(false)}
            />
            <div className="relative px-6 flex flex-col py-4">
              <textarea
                rows="6"
                cols="64"
                className="outline-none text-xs mb-2"
                placeholder="Write your Message Here"
                ref={msg}
              />
              {msgRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <button
                  className="w-28 border px-6 py-2 ml-auto mt-2 rounded-3xl text-xs text-white bg-primary  focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    handleSendMessage();
                  }}
                >
                  Send
                </button>
              )}
            </div>
            {/* <div className="flex justify-between text-xs font-bold p-2 px-6 relative">
              <p>Message History</p>
              <button
                className="w-1/3 px-2 py-2 text-xs absolute top-1 right-0 focus:outline-none flex justify-center items-center"
                onClick={() => {
                  setSort((prev) => !prev);
                }}
              >
                Participants in Chat
                <img src="assets/img/expandIcon.svg" className="h-2 ml-4" alt="" />
              </button>
            </div>
            <div className="text-center items-center justify-center text-xs px-6 text-gray-500 py-3">
              <p className="mb-2" style={{ fontSize: 10 }}>
                20-April-2020
              </p>
              <div className="flex items-center  gap-x-2 justify-between">
                <p className="text-sm w-11/12 text-left text-black">
                  Me -{' '}
                  <span className="text-xs text-tableRowText">
                    Please pay your fee by tomorrow EOD to avoid late fee payment
                  </span>
                </p>
                <p className="text-tableRowText w-1/12 text-right" style={{ fontSize: 10 }}>
                  01:30PM
                </p>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {!showDetail && (
        <div className="relative  pb-10">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block  items-center">
                <div className="font-bold">
                  <p>
                    Pending Fee{' '}
                    <span className="text-xs font-normal text-tableRowText">{`(${
                      (Array.isArray(pendingFeesList) &&
                        pendingFeesList.length > 0 &&
                        pendingFeesList.reduce((acc, cuu) => (acc += cuu.pendingFeeStudennt), 0)) ||
                      0
                    } Students)`}</span>
                  </p>
                </div>
                <div className="flex justify-end ml-auto">
                  <DropDown
                    data={[
                      'Ascending Order',
                      'Descending Order',
                      'Name - a to z',
                      'Name - z to a',
                      'High Pending Fee',
                      'Low Pending Fee',
                    ]}
                    width={'w-44'}
                    margin={'mr-4'}
                    handleItemClick={() => {}}
                  />

                  {/* <div className="w-44 border px-4 py-1 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-between">
                    <img src="assets/img/Search.svg" className="h-4 mt-1 w-3 mr-3 " alt="" />
                    <input
                      type="text"
                      placeholder="Search student"
                      autoComplete="off"
                      className="text-left w-full bg-transparent text-gray-500 focus:outline-none"
                      // value={searchName}
                      // onChange={(x) => {
                      //   setSearchName(x.target.value);
                      // }}
                      // onKeyDown={(x) => {
                      //   if (x.which === 13) {
                      //     setSearch((prev) => !prev);
                      //     setChoice('search');
                      //     setSearchType('pendingFee');
                      //   }
                      // }}
                      style={{ borderColor: '#e6e6e6' }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full px-4 relative  text-xs font-medium">
                      <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                      <div className="w-1/12 border-r-2 py-2 px-2">Section</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Total Sudents</div>
                      <div className="w-3/12 border-r-2 py-2 px-2">Students with Pending Fee</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Phase</div>
                      <div className="w-2/12 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {pendingFees.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto my-6" alt="" />
                    ) : Array.isArray(pendingFeesList) && pendingFeesList.length > 0 ? (
                      pendingFeesList.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative px-4 py-2.5 text-xs font-medium ${
                              pendingFeesList.length - 1 !== idx && 'border-borderYellow border-b'
                            }`}
                          >
                            <div className="px-2 w-2/12 text-textDark">{x?.class}</div>
                            <div className="px-2 w-1/12 text-textDark">{x?.section}</div>
                            <div className="px-2 w-2/12 text-tableRowText">{x?.totalStudent}</div>
                            <div className="px-2 w-3/12 text-tableRowText">{x?.pendingFeeStudennt}</div>
                            <div className="px-2 w-2/12 text-tableRowText">{getPhase(x.class)}</div>
                            <div className="px-2 w-2/12 flex  items-center">
                              <button
                                className={`w-11/12  border-tableRowText border px-4 py-2 rounded-3xl text-xs focus:outline-none ${
                                  x?.fees_pending == 0
                                    ? 'opacity-30 bg-gray-300 cursor-not-allowed'
                                    : 'text-tableRowText'
                                }`}
                                disabled={x?.fees_pending == 0}
                                onClick={() => {
                                  // setSearch(true);
                                  // setChoice('viewMore');
                                  // setSearchType('pendingFee');
                                  selectClassForFee(x);
                                }}
                              >
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-xl h-auto my-4">
            <div className="items-center px-6 py-3 border-borderYellow border-bborder-borderYellow border-b">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold">
                  <p>
                    Student Detail{' '}
                    <span className="text-sm font-normal text-tableRowText">
                      {`(${
                        Array.isArray(classSections)
                          ? classSections.length > 0 && classSections.reduce((acc, cuu) => (acc += cuu.totalStudent), 0)
                          : 0
                      } Students)`}
                    </span>
                  </p>
                </div>
                <div className="flex justify-end">
                  <DropDown
                    data={['Ascending Order', 'Descending Order', 'Name - a to z', 'Name - z to a']}
                    width={'w-44'}
                    margin={'mr-4'}
                    handleItemClick={() => {}}
                  />
                  {/* <div className="w-44 border px-4 py-1 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-between">
                    <img src="assets/img/Search.svg" className="h-4 mt-1 w-3 mr-3 " alt="" />
                    <input
                      type="text"
                      placeholder="Search Student"
                      autoComplete="off"
                      className="text-left w-full bg-transparent text-gray-500 focus:outline-none"
                      // value={searchName}
                      // onChange={(x) => {
                      //   setSearchName(x.target.value);
                      // }}
                      // onKeyDown={(x) => {
                      //   console.log(x.target.value);
                      //   if (x.which === 13) {
                      //     setSearch((prev) => !prev);
                      //     setChoice('search');
                      //     setSearchType('studentDetails');
                      //   }
                      // }}
                      style={{ borderColor: '#e6e6e6' }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full px-2 relative  text-xs font-medium">
                      <div className="p-2 w-2/12 border-r-2 py-2">Class</div>
                      <div className="p-2 w-1/12 border-r-2 py-2">Section</div>
                      <div className="p-2 w-3/12 border-r-2 py-2">Class Coordinator</div>
                      <div className="p-2 w-2/12 border-r-2 py-2">Total Students </div>
                      <div className="p-2 w-2/12 border-r-2 py-2">Phase</div>
                      <div className="p-2 w-2/12 py-2">Action</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {classSectionResponse.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : Array.isArray(classSections) && classSections.length > 0 ? (
                      classSections.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative px-4 py-2 text-xs font-medium ${
                              classSections.length - 1 !== idx && 'border-borderYellow border-b'
                            }`}
                          >
                            <div className="px-2 w-2/12 text-textDark">{x.class}</div>
                            <div className="px-2 w-1/12 text-textDark">{x.section}</div>
                            <div className="px-2 w-3/12 text-tableRowText">{x.headTeacher}</div>
                            <div className="px-2 w-2/12 text-tableRowText">{x.totalStudent}</div>
                            <div className="px-2 w-2/12 text-tableRowText">{getPhase(x.class)}</div>
                            <div className="px-2 w-2/12 flex items-center">
                              <button
                                className=" w-11/12 text-tableRowText border-tableRowText  border px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                onClick={() => {
                                  handleSelectClass(x.classId, x.sectionId);
                                  selectClass(x);
                                  setDetail(true);
                                }}
                              >
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDetail && (
        <StudentProfile
          setDetail={hideDetail}
          studentList={studentList}
          selectedClass={selectedClass}
          refreshList={refreshList}
        />
      )}
    </div>
  );
};
export default Students;
