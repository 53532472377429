import React, { useState, useEffect } from 'react';
import { INSTITUTE_FEEDBACK } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';
import DropDown from '../../UI/DropDown';
import { getScreenFeatures, getScreens } from '../../../constants';

function RoledInFeedback() {
  const [screen, setScreen] = useState('');
  const [feature, setFeature] = useState('');
  const [features, setFeatures] = useState('');
  const [feedback, setFeedback] = useState('');

  const [{ isLoading, isError, data, status }, sendFeedback] = useApi();

  useEffect(() => {
    if (screen) {
      setFeature('');
      setFeatures(getScreenFeatures(screen));
    }
  }, [screen]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      feature: feature,
      category: screen,
      remarks: feedback,
    };

    sendFeedback(INSTITUTE_FEEDBACK, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      alert('Success');
      setFeedback('');
      setFeatures([]);
      setFeature('');
      setScreen('');
    }

    if (!isLoading && isError) {
      alert('Something went wrong!');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div className="w-full md:w-3/4">
      <div className="bg-white rounded-2xl shadow-sm">
        <div className="px-6 py-4 flex gap-x-3.5 items-center" style={{ borderBottom: '1px solid #E7E700' }}>
          <img alt="logo" src="/assets/img/roledin icon.svg" className="w-7 cursor-pointer" />
          <p className="font-bold ">Feedback for ROLEDin </p>
        </div>

        <div className="p-6">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="my-2 w-1/2 relative font-semibold ">
              <label className="text-textDark font-medium text-sm">Feedback for</label>
              <DropDown
                data={getScreens()}
                title={screen || 'Select'}
                handleItemClick={(x) => {
                  setScreen(x);
                }}
                width={'w-full'}
                otherClasses={'border-borderLight mt-2'}
              />
            </div>

            <div className="my-2 w-1/2 relative font-semibold ">
              <label className="text-textDark font-medium text-sm">Feature</label>
              <DropDown
                key={screen}
                data={features}
                title={feature || 'Select'}
                handleItemClick={(x) => {
                  setFeature(x);
                }}
                width={'w-full'}
                otherClasses={'border-borderLight mt-2'}
                disabled={!screen}
              />
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label className="text-textDark font-medium text-sm">Write your feedback</label>
              <textarea
                type="text"
                name="description"
                rows="10"
                value={feedback}
                cols="50"
                className="w-full rounded-2xl text-xs border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none"
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className=" bg-primary py-2 text-white text-xs font-medium w-36 rounded-full ml-auto mt-3"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RoledInFeedback;
