import React from 'react';

const LoadingPopup = ({ text }) => {
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-80 text-sm m-auto rounded-2xl relative my-auto py-10">
        <p className="text-center font-medium text-lg mb-6">{text}</p>
        <img src="assets/img/roledin_graphic.svg" alt="loading" className="m-auto animate-ping h-5 w-5" />
      </div>
    </div>
  );
};

export default LoadingPopup;
