import React from 'react';
import moment from 'moment';

const CustomToolbar = ({ date, view, onNavigate }) => {
  const currentYear = moment().year();
  const startDate = moment(`${currentYear}-04-01`);
  const endDate = moment(`${currentYear + 1}-03-31`);

  const currentMoment = moment(date);
  const currentMonth = currentMoment.month(); // 0 (January) to 11 (December)
  const currentYearDate = currentMoment.year();

  const isAtStart = currentMonth === 3 && currentYearDate === currentYear; // April of the current year
  const isAtEnd = currentMonth === 2 && currentYearDate === currentYear + 1; // March of the next year

  const handleNavigate = (action) => {
    onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => handleNavigate('TODAY')}>
          Today
        </button>
        <button
          disabled={isAtStart}
          className={`${isAtStart ? 'opacity-0' : 'opacity-100'}`}
          type="button"
          onClick={() => handleNavigate('PREV')}
        >
          Back
        </button>
        <button
          disabled={isAtEnd}
          className={`${isAtEnd ? 'opacity-0' : 'opacity-100'}`}
          type="button"
          onClick={() => handleNavigate('NEXT')}
        >
          Next
        </button>
      </span>
      <span className="rbc-toolbar-label">{moment(date).format('MMMM')}</span>
    </div>
  );
};

export default CustomToolbar;
