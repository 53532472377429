import React, { useState, useEffect, useRef } from 'react';

import useApi from '../../../api/useApi';
import { FEEDBACK } from '../../../api/urls/remarks';

function Feedback({ role }) {
  const [feedback, setFeedback] = useState('');
  const [{ isLoading, isError, data, status }, sendFeedback] = useApi();

  const fb = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!feedback?.trim()) {
      alert('Please add a feedback');
      return;
    }

    sendFeedback(FEEDBACK, 'POST', {
      feedback: feedback?.trim(),
      role,
    });
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      setFeedback('');
      fb.current.value = '';
      alert('Success');
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div className="w-full md:w-4/5">
      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <p className="font-bold">Share Feedback</p>
        </div>
        <form className="mt-4 mb-4 mx-6 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-1 mb-4 w-full relative font-semibold">
            <label className="block text-parameter text-sm">
              Feedback Description<span className="text-red-500 ml-1">*</span>
            </label>
            <textArea
              rows="6"
              cols="50"
              ref={fb}
              // placeholder="Write your reason here"
              className="w-full rounded-2xl border px-4 mt-2 py-1 focus:outline-none"
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>

          {/* <div className="w-1/3" /> */}
          <div className="flex justify-end w-full mb-6">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <>
                <button
                  type="submit"
                  className="mt-1 mr-8 lg:mt-0 w-auto lg:w-max border border-gray-400 px-12 py-4 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center h-7"
                  // onClick={() => setModify(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                  // onClick={() => setModify(false)}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Feedback;
