import React, { useState, useEffect } from 'react';
// import UploadModal from './UploadModal';
// import { SYLLABUS_GET } from '../../../api/urls/timeTable';
// import { Generate_Schedule_POST } from '../../../api/urls/ai';
import { GET_EXAM_SEQUENCE } from '../../../api/urls/test';
import useApi from '../../../api/useApi';
import { GET_CLASS_SUBJECT_NOTES , Save_Notes_POST } from '../../../api/urls/notes';
import { Generate_Notes_POST } from "../../../api/urls/ai" ;
// import { utils, writeFile } from 'xlsx';
// import Schedule from './Schedule';
// import Notes from './Notes';
// import axios from 'axios';
import { getRandomNumber, getExamName } from '../../../utils';
import LoadingPopup from '../../Principal/AcademicCalender/LoadingPopup';
import reactRouterDom from 'react-router-dom';


const LecturePlan = ({ subjects , role }) => {
  const [UnitTermTestFromAcademicCalander, getUnitTermTestFromAcademicCalander] = useApi(); 
  const [ViewClassSubject, getViewClassSubject] = useApi();
  const [GenrateNotes, postGenrateNotes] = useApi(); 
  const [SaveNotes, postSaveNotes] = useApi(); 

  const [ShowLoading, setShowLoading] = useState(false);
  const [Topic , setTopic]  = useState();
  const [ViewTopics , setViewTopics ] = useState({subjectName:"ENGLISH",exam:"u1",topics:[],topicSchduleID:null,syllabustId:null });
  const [targetIndex , settargetIndex ] = useState(-1);
  const [viewIndex , setviewIndex ] = useState(-1);
  const [LoadNotes , setNotes ] = useState(-1);
  const [GenNotes, setGenNotes] = useState(false);
  const [ViewLoadedNotes , setViewLoadedNotes] = useState([]);


  useEffect(() => {
    const sess = sessionStorage.getItem('session') ;
    getUnitTermTestFromAcademicCalander(GET_EXAM_SEQUENCE(sess), 'GET');
    getViewClassSubject(GET_CLASS_SUBJECT_NOTES,'GET');
    // setsession(sess);
  }, []);

  useEffect(() => { // console.log("load data : ", ViewClassSubject );
    if(ViewClassSubject){
      if(ViewClassSubject.data?.noError){
        setTopic( ViewClassSubject.data.topic );
      }
    } 
  },[ViewClassSubject]);

  //
  useEffect(() => { // console.log("Genrated notes : ", ViewClassSubject ); 
    if(!GenrateNotes.isError && !GenrateNotes.isLoading && GenrateNotes.data ){ 
      setShowLoading(false); 
      if(GenrateNotes.data.noError){
        setGenNotes(GenrateNotes.data.notes );
      }
    }
    if(GenrateNotes.isError){ alert("Error occured."); }
  },[GenrateNotes])

  useEffect(() => {  console.log(" --- SaveNotes : ", SaveNotes );
    if(!GenrateNotes.isError && !GenrateNotes.isLoading && GenrateNotes.data ){ 
      setShowLoading(false); 
      if(GenrateNotes.data && !GenrateNotes.data.noError ){
        alert("Error occured.")
      }else if(GenrateNotes.data && GenrateNotes.data.noError ){
        alert("Sucess.")
      }
    }
  },[SaveNotes]) ;
  const ViewNotes = (viewCurrIndex) => {  // console.log(" -- ViewNotes ",LoadNotes);
    for(let noteIndex = 0 ; noteIndex < LoadNotes.length ; noteIndex++ ){
      let objIndex = LoadNotes[noteIndex] ;
      if( objIndex.IndexID ){
        let indexes = objIndex.IndexID ; // .split('±');
        console.log(" -- indexes : ", indexes , " -- " ,objIndex.IndexID );
        if(indexes[0] == viewCurrIndex){
          console.log(" -- ViewNotes -- find : ");
          return objIndex ;
        }
      }
    } 
    return false ;
  }
  
  const generateNotes = (currIndex,btn) => { // console.log(" -- generateNotes - ViewTopics : ", ViewTopics ) ; // return ;
    let checkNotes = ViewNotes(currIndex);
    if( checkNotes ){ 
      if(viewIndex == currIndex){
        setViewLoadedNotes([]);
        setviewIndex(-1) ;
        settargetIndex(-1);
      }else{
        setViewLoadedNotes(checkNotes.summary? checkNotes.summary.split("\n") : [] );
        setviewIndex(currIndex) ;
        settargetIndex(-1);
      } 
    } else {
      setviewIndex(-1) ;
      settargetIndex(currIndex);
      if(ViewTopics){ 
        if(ViewTopics.topics.length > 0 ){
          let topic = ViewTopics.topics[currIndex]; // console.log(" -- for gen topic : ", topic );
          if( btn == "regenrate" ){
            setShowLoading(true); 
            postGenrateNotes( Generate_Notes_POST , 'POST', {topicSchduleID: ViewTopics.topicSchduleID,IndexID:topic.IndexID ,notesfor : topic.topic +" " + topic.subtopic  } );
          } 
          setGenNotes("");
        }
      }
    } 
  }
  const saveGenerateNotes = (currIndex) => {
    // console.log("--- saveGenerateNotes : " ); 
    // console.log(" -- generateNotes - ViewTopics : ", ViewTopics ) ; //  return ;
    settargetIndex(-1);
    setShowLoading(true); // 
    if(ViewTopics){ 
      if(ViewTopics.topics.length > 0 ){
        let topic = ViewTopics.topics[currIndex]; // console.log(" -- for gen topic : ", topic );
        postSaveNotes( Save_Notes_POST , 'POST', {syllabustId:ViewTopics.syllabustId,topicSchduleID: ViewTopics.topicSchduleID,IndexID:topic.IndexID ,SaveNotes : GenNotes  } );
        setGenNotes("");
      }
    }
  }

  const checkIfUploaded = (subjectId, exam) => {
    // const x = data?.syllabus?.find((c) => c.subjectId == subjectId && c.type == exam)?.syllabus;
    // return x;
    return false ;
  };
  
  const findExamTopic = (subjectId,examCode) => { 
    let index_1 = -1 ;
    let index_2 = -1 ;
    if(Topic){
      for(let topicindex = 0 ; topicindex < Topic.length; topicindex++ ){
        let examobj = Topic[topicindex] ;
        if( examobj.subjectId == subjectId){
          for(let examindex = 0 ; examindex < examobj.topics.length; examindex++ ){
            let find = examobj.topics[examindex] ;
            if(find.exam == examCode){
              // console.log("match --", find) ;;
              index_1 = topicindex;
              index_2 = examindex;
              break ;
            }
          }
        } 
        if(index_2 > -1){ break ; }
      }
    }
    // console.log("--- index_1 : ", index_1 , " -- index_2 : ", index_2 ) 
    if(index_1 > -1 && index_2 > -1 ){
      return [ index_1 , index_2 ]
    }
    return null
  }
  
  const viewGenrateNotes = (subjectClassData , examCode ) => { 
    // console.log("\n -- view data : ",subjectClassData ); // find and load data from var: Topic
    let match  = findExamTopic(subjectClassData.subjectId,examCode);
    // console.log(" match : ", match );
    if(match){
      let showtopics = Topic[match[0]].topics[match[1]].content ;
      let topicSchduleID = Topic[match[0]].topics[match[1]]._id ;
      let syllabustId = Topic[match[0]].topics[match[1]].syllabustId ;
      // console.log("selectd exam : ", Topic[match[0]].Notes  ); 
      // console.log("-- showtopics : ", Topic[match[0]].topics[match[1]].syllabustId )
      // console.log(" -- showtopics : ", showtopics ,"\n---", Topic[match[0]].topics[match[1]]._id );
      setNotes(Topic[match[0]].Notes);
      setViewTopics({subjectName:subjectClassData.subjectName, exam:examCode,topics:showtopics,topicSchduleID:topicSchduleID,syllabustId:syllabustId })
    }
  }
  const isTopicsLoaded = (subjectClassData , examCode ) => { 
    let match  = findExamTopic(subjectClassData.subjectId,examCode);
    // console.log(" match : ", match );
    return match ? true : false ;
    // if(Topic){
    //   let findTopics = Topic.filter((obj)=>{ 
    //     if(obj.subjectId == subjectClassData.subjectId){
    //       if(obj.topics){ //  console.log(" is topics : ", obj.topics , "\n---", )
    //         let findexam = obj.topics.filter((obj)=>{ return obj.exam == examCode; }) 
    //         if( findexam.length > 0 ){
    //           return true ;
    //         }
    //       }
    //     }
    //     return false ;
    //   })
    //   // console.log(" is topics find : ", findTopics )
    //   return findTopics.length > 0 ? true : false ;
    // }
    // return false ; 
  }

  // const [open, setOpen] = useState();
  

  // const [printData, setPrintData] = useState();
  // const [selected, select] = useState();
  // const [notes, setNotes] = useState();
  // const [schedule, setSchedule] = useState();
  // const [loading, setLoading] = useState(false);
  // const [excelData, setExcelData] = useState();
  // const [extractedText, setextractedText] = useState();
  // 
  // const [CurrExam , setCurrExam]  = useState();
  // const [Currclass , setCurrclass] = useState();
  // 
  // const [formData, setFormData] = useState({OnlineClassTest:"",OfflineClassTest:"",assignments:"" });

  // const [{ isLoading, data, isError }, getSyllabus] = useApi();
  // 
  // const [Topics, ScheduleTopics] = useApi();


  // const handleInputChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleOpenDownload = (exam, _class, section, subjectName, subjectId) => {
  //   setOpen({ exam, _class, section, subjectName, subjectId });
  //   setCurrExam(exam);
  //   setCurrclass(_class);
  //   setextractedText('');
  //   setExcelData('');
  // };

  // useEffect(() => {
  //   getSyllabus(SYLLABUS_GET, 'GET');
  // }, []);

  

  // const handleClose = (refresh) => {
  //   if (refresh) {
  //     getSyllabus(SYLLABUS_GET, 'GET');
  //   }
  //   setOpen(null);
  // };
  // const generateSchedule = async (type) => {
  //   // const body = JSON.stringify(type === 'image' ? extractedText : excelData);
  //   if(ViewSyllabus){
  //     setLoading(true);
  //     setShowLoading(true);
  //     ScheduleTopics( Generate_Schedule_POST , 'POST', { extractedData: ViewSyllabus.Syallbus , exam:ViewSyllabus.exam , classname : ViewSyllabus.class,section : ViewSyllabus.section,subjectId:ViewSyllabus.subjectId ,teacherInput:formData } ); 
  //   }else{ alert('Error'); console.log("Please select syllabus data first"); }
  //   // after ajax complete
  // };

  // useEffect(() => { 
  //   if(!Topics.isLoading){  setShowLoading(false); }
  //   setLoading(false);// console.log("Ai model call from server : ",Topics );
  //   //  return ;
  //   if(!Topics.isError && !Topics.isLoading){ 
  //     // handleClose();
  //     setViewSyllabus(null);
  //     setSchedule(Topics.data); 
  //   } else {  setSchedule(null);  }
  // }, [Topics]);


  

  // function ExportData(data, examName,Syallbus) {  console.log("View : ", data,"\n-- examName : ", examName ,"--Syallbus:",Syallbus  );
  //   if(Syallbus ){ 
  //     if(Syallbus.length == 1 && typeof Syallbus[0] == "string"){ Syallbus = Syallbus[0]; }
  //   }
  //   setViewSyllabus({...data,exam:examName,Syallbus:Syallbus});
  //   setSchedule(null) ;
  // } 
  let AcademicCalanderCheck = "No Data Available" ;
  let APIError = false ; 
  let SeqUnitTermTest = []
  let GenNotesloading = false ;
  if( !UnitTermTestFromAcademicCalander.isError && !UnitTermTestFromAcademicCalander.isLoading) //  & UnitTermTestFromAcademicCalander.data.msg)
  { AcademicCalanderCheck = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.msg : AcademicCalanderCheck ; 
    APIError = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.isError : false ; 
    SeqUnitTermTest = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.examSequence : [] ;
  }
  // // console.log(" - UnitTermTestFromAcademicCalander : ", UnitTermTestFromAcademicCalander," -- ", AcademicCalanderCheck  )
  // let isArray_syallbus = false;
  // let isArray_syallbus_not = false
  // if(ViewSyllabus ){ 
  //   isArray_syallbus = Array.isArray(ViewSyllabus.Syallbus);
  //   isArray_syallbus_not = isArray_syallbus ? false : true ;
  // }
  //
  return (
    UnitTermTestFromAcademicCalander.isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
      ) : 
    UnitTermTestFromAcademicCalander.isError || APIError ? (  
      <div  className="bg-white m-auto rounded-2xl w-full md:w-4/5" // border  min-w-700" style={{ borderColor: '#E7E700' }}
          > 
      <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">{AcademicCalanderCheck}</p>
      </div>
    ) :
    <div className="relative w-full md:w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-4 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Lecture Plan</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  {SeqUnitTermTest.map((examName, examName_index) => (
                    <>
                      <div className={`w-1/5 py-2 px-2
                      ${
                        examName_index !== SeqUnitTermTest.length - 1 && 'border-r-2'
                      }`}
                      >{getExamName(examName)}</div>
                    </>
                  ))} 
                </div>
              </div>
              <div>
                {Array.isArray(subjects) && subjects.length > 0 ? (
                  subjects.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs items-center ${
                          idx !== subjects.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-tableRowText">
                          {x?.subjectName.split(' ').length > 0 ? x?.subjectName.split(' ')[1] : x?.subjectName}
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.subjectName.split(' ')[0]}</div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.class?.name + '-' + x?.section.name}</div>
                        {SeqUnitTermTest.map((examName, examName_index) => (  
                          <div className="w-1/5 px-2">
                            {isTopicsLoaded( x, examName) &&
                            <button
                              className={`border ${
                                checkIfUploaded(x?.subjectId, examName) ? 'text-primary bg-white' : 'text-white bg-primary'
                              } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                              onClick={() => {
                                 viewGenrateNotes(x,examName);
                              }}
                            > 
                              {checkIfUploaded(x?.subjectId, examName) ? 'View' : 'Notes'}
                            </button>}
                          </div> 
                        ))} 
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {ShowLoading && <LoadingPopup text={'Generating...'} />}


      <div className="w-full bg-white rounded-xl h-auto mt-6">
        {ViewTopics.topics.map((topic, topicindex) => (<>
          <div className="border-b border-borderYellow flex justify-between items-center px-6 py-4">
            <span className={`block font-bold px-2 w-full ${ targetIndex != topicindex ? 'border-r-2' : '' }` }>{`Generate Notes for  ${ViewTopics?.subjectName} - ${getExamName(ViewTopics?.exam)} - ${topic?.week} - ${topic?.day} ${topic?.topic} ${topic?.subtopic}`} </span>
            { targetIndex != topicindex &&
            <div className="flex gap-x-4 mx-2" style={{width : '170px'}} >
              <button
                className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                  GenNotesloading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  generateNotes(topicindex);
                }}
                disabled={GenNotesloading}
              >
               { ViewNotes(topicindex) ?  viewIndex == topicindex ? "Close" : "View Notes" :"Create  Notes" }
              </button> 
            </div>}
          </div>
          { viewIndex == topicindex && 
          <div className="px-6 pb-8 border-borderYellow border-b  "> 
            {ViewLoadedNotes.map((notesText, indx) => (
              <p className="text-base font-medium mb-2">{notesText}</p>
            ))} 
          </div>
          }
          { targetIndex == topicindex && 
          <div className="px-6 pb-8 border-borderYellow border-b  "> 
            <p className="text-base font-medium mb-2">Please verify that the Notes in the box below is correct before send it to Students</p>
            <textarea
              value={GenNotes} // {extractedText}
              rows="20"
              cols="50"
              onChange={(e) => setGenNotes(e.target.value)}
              placeholder=""
              className="w-full rounded-2xl border text-xs border-borderLight px-4 py-2 mb-8 focus:outline-none"
            />
            <div className="w-full pr-2 flex justify-end gap-x-4 mt-4">
              {/* <button
                className={`border border-primary mx-4 px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                  GenNotesloading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  settargetIndex(-1);
                }}
                disabled={GenNotesloading}
              >
                Close
              </button>  */}
              <button
                className={`border border-primary mx-4 px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                  GenNotesloading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  generateNotes(topicindex,"regenrate");
                }}
                disabled={GenNotesloading}
              >
                Genrate Notes
              </button> 
              <button
                className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                  GenNotesloading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  saveGenerateNotes(topicindex);
                }}
                disabled={GenNotesloading}
              >
                Send to Students
              </button> 
            </div>
          </div> } 
        </> ))}  
        </div>
    </div>
  );
};

export default LecturePlan;
