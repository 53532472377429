import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { EVALUATE_TEST } from '../../../api/urls/test';

function ViewSubmittedTest({ test, handleClose }) {
  const [questions, setQuestions] = useState(test.test?.question);

  const [evaluateRes, evaluateTest] = useApi();

  const handleMarksChange = (e, ques) => {
    let _questions = test.test.question;
    let studentIndex = _questions.findIndex((x) => x.question === ques.question && x.type === ques.type);
    let y = _questions[studentIndex];

    if (+y.marks < +e.target.value) {
      alert(`Obtained marks should be less than or equal to ${y.marks}`);
      return;
    }
    let x = {
      ...y,
      assignedMarks: +e.target.value,
    };
    _questions[studentIndex] = x;
    setQuestions([..._questions]);
  };

  const handleSubmit = () => {
    let _questions = questions.map((x) => {
      if (x.type === 'Multiple choice question') {
        return { ...x, assignedMarks: x.answer === x.submitAnswer ? +x.marks : 0 };
      } else return x;
    });
    const body = {
      question: _questions,
    };
    evaluateTest(EVALUATE_TEST(test.test._id), 'PUT', body);
  };

  useEffect(() => {
    if (!evaluateRes.isLoading && !evaluateRes.isError && evaluateRes.status == 202) {
      alert('Success');
      handleClose(true);
    }

    if (!evaluateRes.isLoading && evaluateRes.isError) {
      alert('Failed');
    }
  }, [evaluateRes]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-8/12 pb-6 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="border-b border-borderYellow py-4">
          <span className=" font-bold px-6">{test.name}</span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-4 h-3 cursor-pointer"
            alt=""
            onClick={handleClose}
          />
        </div>
        <div>
          {questions ? (
            questions.map((x, idx) => (
              <div
                className={`p-6 grid grid-cols-1 md:grid-cols-3  gap-x-4 ${
                  questions.length - 1 !== idx && 'border-b border-borderYellow'
                }`}
              >
                <div className="mb-4 full relative font-semibold disabled md:col-span-2 row-span-2">
                  <label htmlFor="testType" className="block text-xs text-textDark">
                    {`Question ${idx + 1}`}
                  </label>
                  <p
                    className="w-full h-full text-xs text-tableRowText rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    style={{ height: '85%' }}
                  >
                    {x.question}
                  </p>
                </div>

                <div className="mb-4 full relative font-semibold disabled">
                  <label htmlFor="testType" className="block text-xs text-textDark">
                    Marks
                  </label>
                  <p className="w-full text-xs rounded-2xl text-tableRowText border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                    {x.marks}
                  </p>
                </div>

                <div className="mt-2 mb-4 full relative font-semibold ">
                  <label className="block text-xs text-textDark">Marks Obtained</label>
                  {x.type === 'Multiple choice question' ? (
                    <p className="w-full text-xs rounded-3xl border text-tableRowText border-borderLight px-4 mt-2 py-2 focus:outline-none">
                      {x.answer === x.submitAnswer ? x.marks : 0}
                    </p>
                  ) : (
                    <input
                      type="number"
                      placeholder="Ex-10"
                      min={0}
                      required
                      value={x.assignedMarks}
                      name="marks"
                      onChange={(e) => handleMarksChange(e, x)}
                      className="w-full text-xs rounded-3xl border font-semibold border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                  )}
                </div>

                {x.type === 'Multiple choice question' ? (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:col-span-2">
                      <div className="mt-2 mb-4 full relative font-semibold ">
                        <label className="block text-xs text-textDark">Answers</label>
                        <p
                          className={`w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none ${
                            x.submitAnswer === x.option1
                              ? x.answer === x.submitAnswer
                                ? 'text-white bg-textGreen'
                                : 'text-white bg-textRed'
                              : 'text-tableRowText bg-white'
                          }`}
                        >
                          {x?.option1}
                        </p>
                      </div>

                      <div className="mt-2 mb-4 full relative font-semibold ">
                        <p
                          className={`w-full text-xs rounded-3xl border border-borderLight  px-4 mt-6 py-2 focus:outline-none ${
                            x.submitAnswer === x.option2
                              ? x.answer === x.submitAnswer
                                ? 'text-white bg-textGreen'
                                : 'text-white bg-textRed'
                              : 'text-tableRowText bg-white'
                          }`}
                        >
                          {x?.option2}
                        </p>
                      </div>

                      <div className="mt-2 mb-4 full relative font-semibold ">
                        <p
                          className={`w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none ${
                            x.submitAnswer === x.option3
                              ? x.answer === x.submitAnswer
                                ? 'text-white bg-textGreen'
                                : 'text-white bg-textRed'
                              : 'text-tableRowText bg-white'
                          }`}
                        >
                          {x?.option3}
                        </p>
                      </div>

                      <div className="mt-2 mb-4 full relative font-semibold ">
                        <p
                          className={`w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none ${
                            x.submitAnswer === x.option4
                              ? x.answer === x.submitAnswer
                                ? 'text-white bg-textGreen'
                                : 'text-white bg-textRed'
                              : 'text-tableRowText bg-white'
                          }`}
                        >
                          {x?.option4}
                        </p>
                      </div>
                    </div>

                    <div className="mt-2 mb-4 full relative font-semibold">
                      <label className="block text-xs text-textDark">Correct Answer</label>
                      <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-6 py-2 focus:outline-none">
                        {x?.answer}
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-x-4 md:col-span-2">
                    <div className="mt-2 mb-4 w-full relative font-semibold ">
                      <label className="block text-xs text-textDark">Answers</label>
                      <p
                        className={`w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-tableRowText`}
                      >
                        {x?.submitAnswer}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">Test Not Submitted </p>
          )}
          {questions && (
            <div className="w-full mt-2 px-6 flex justify-end gap-x-6">
              {/* <button
                className="w-36 border py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                //   onClick={() => {
                //     setStep((prev) => prev - 1);
                //   }}
              >
                Reset
              </button> */}
              {evaluateRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
              ) : (
                <button
                  className={`w-36 border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center ${
                    !test.test?.isActive
                      ? 'border-borderLight text-tableRowText cursor-not-allowed'
                      : 'bg-primary text-white cursor-pointer'
                  }`}
                  onClick={handleSubmit}
                  disabled={!test.test?.isActive}
                >
                  Save
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewSubmittedTest;
