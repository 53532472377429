import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { STUDENT_UPCOMING_LIVE_SESSIONS } from '../../../api/urls/notes';
import { useStateValue } from '../../../ContextApi/StateProvider';

function LiveSessions({ role }) {
  const [sessions, getAllSessions] = useApi();

  const [{ user }, _] = useStateValue();

  useEffect(() => {
    if (user) {
      if (role === 'student') {
        const studentInfo = user?.user?.currentlyMapped?.classInfo;
        getAllSessions(STUDENT_UPCOMING_LIVE_SESSIONS(studentInfo?.classId, studentInfo?.sectionId), 'GET');
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo;
        getAllSessions(STUDENT_UPCOMING_LIVE_SESSIONS(childInfo?.classId, childInfo?.sectionId), 'GET');
      }
    }
  }, [user]);

  return (
    <div className="w-full md:w-4/5">
      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold text-base ">
                <p>Upcoming Sessions</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Subject Code</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Timings</div>
                    <div className="w-1/6 border-r-2 py-2 px-2"> Faculty</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>

                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                  {sessions.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : sessions.data && Array.isArray(sessions?.data) && sessions.data.length > 0 ? (
                    sessions?.data.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                            sessions?.data.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/6 text-tableRowText px-2">{x?.subject_id?.subjectCode}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.subject_id?.subjectName}</div>
                          <div className="w-1/6 text-tableRowText px-2">{moment(x?.date).local().format('ll')}</div>
                          <div className="w-1/6 text-tableRowText px-2">{`${moment
                            .utc(x.startTime)
                            .local()
                            .format('hh:mm A')} to ${moment.utc(x.endTime).local().format('hh:mm A')}`}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.teacher_id?.full_name}</div>
                          <div className="w-1/6 px-2">
                            <button
                              className={`border w-11/12 px-2 py-2 rounded-3xl ${
                                x.is_active ? 'bg-primary text-white' : 'bg-gray-200 text-white'
                              } font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                              onClick={() => window.open(x.link)}
                            >
                              Join Now
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveSessions;
