import React, { useEffect, useState, useCallback } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import DropDown from '../../../components/UI/DropDown';
import DoughnutChart from '../../../components/DoughnutChart';
import moment from 'moment';

function Report({ onClose, selectedStudent }) {
  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    if (selectedStudent) {
      const events = [];
      selectedStudent?.attendance?.attendance?.forEach((x) => {
        events.push({
          title: x.isPresent ? 'Present' : 'Absent',
          start: new Date(x.date),
          end: new Date(x.date),
        });
      });

      selectedStudent?.leaves?.forEach((x) => {
        events.push({
          title: x?.leaveCategory || 'Leave',
          start: new Date(x.date),
          end: new Date(x.date),
        });
      });

      setAllEvents(events);
    }
  }, [selectedStudent]);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.title.includes('Absent') && {
        className: 'redEventColor',
      }),
      ...(event.title.includes('Present') && {
        className: 'greenEventColor',
      }),
    }),
    []
  );

  const noOfPresent = selectedStudent?.attendance?.attendance.filter((x) => x.isPresent).length;
  const noOfAbsent = selectedStudent?.attendance?.attendance.filter((x) => !x.isPresent).length;

  return (
    <div
      className="customScrollBar flex justify-center items-start h-screen pb-10 overflow-auto w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-11/12 md:w-2/3 block m-auto rounded-2xl relative mt-10 ">
        <div className="border-b border-borderYellow flex items-center">
          <span className="block font-bold py-4 px-6 text-textBlue1 ">Attendance Report</span>

          <DropDown
            data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior Secondary']}
            width={'w-40'}
            title={'Sort By'}
            margin={'ml-auto'}
            handleItemClick={() => {}}
          />

          <img src="assets/img/Close.svg" className="h-3 cursor-pointer mx-6" alt="" onClick={onClose} />
        </div>

        <div className="flex flex-col md:flex-row justify-between p-6 gap-x-6 border-b border-borderYellow">
          <div className="bg-yellow-50 rounded-xl block pl-6 py-4 w-full md:w-1/3">
            <p className="text-xs text-tableRowText"> Student Name</p>
            <p className="text-xl text-black font-light">{selectedStudent?.studentInfo?.full_name}</p>

            <p className="text-xs text-tableRowText mt-3">Roll No</p>
            <p className="text-xl text-black font-light">
              {selectedStudent?.studentInfo?.currentlyMapped?.rollNumber || '--'}
            </p>

            <p className="text-xs text-tableRowText mt-3">ROLEDin ID</p>
            <p className="text-xl text-black font-light">{selectedStudent?.studentInfo?.username}</p>

            <p className="text-xs text-tableRowText mt-3">Attendance Duration</p>
            <p className="text-xl text-black font-light">
              {selectedStudent?.attendance?.attendance.length > 0 &&
                `${moment(selectedStudent?.attendance?.attendance[0].date).format('D MMMM yyyy')} to ${moment(
                  selectedStudent?.attendance?.attendance[selectedStudent?.attendance?.attendance.length - 1].date
                ).format('D MMMM yyyy')}
                `}
            </p>

            <p className="text-xs text-tableRowText mt-3">Total Number Of Days</p>
            <p className="text-xl text-black font-light">
              {selectedStudent?.attendance?.attendance.length + selectedStudent?.leaves?.length}
            </p>
          </div>

          <div className="w-full md:w-2/3 flex flex-col md:flex-row gap-x-6">
            <div className="w-full md:w-1/3">
              <div className=" pl-6 ">
                <p className="text-xs text-tableRowText"> Attendance Percentage</p>
                <p className="text-xl"> {selectedStudent?.attendance?.cumulative} %</p>

                <p className="text-xs text-tableRowText mt-3"> Total Present Days</p>
                <p className="text-xl text-textGreen flex items-center gap-1">
                  <div className="h-2.5 w-2.5 rounded-full bg-textGreen mt-1"></div>
                  {noOfPresent}
                </p>

                <p className="text-xs text-tableRowText mt-3"> Total Absent Days</p>
                <p className="text-xl text-textRed flex items-center gap-1">
                  <div className="h-2.5 w-2.5 rounded-full bg-textRed mt-1"></div>
                  {noOfAbsent}
                </p>
              </div>
            </div>
            <div
              className="w-full md:w-2/3 flex justify-center items-center m-auto "
              style={{ height: 300, width: 300 }}
            >
              <DoughnutChart
                labels={['']}
                values={[noOfPresent, noOfAbsent]}
                color={[' rgba(16, 185, 129, 0.5)', 'rgba(220, 68, 68,0.7)']}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between p-6 ">
          <div className="w-full">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <Calendar
                localizer={localizer}
                events={allEvents}
                style={{ height: 400 }}
                views={['month']}
                eventPropGetter={eventPropGetter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
