import React, { useState, useEffect, useCallback } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { STAFF_MONTHLY_ATTENDANCE } from '../../../api/urls/attendance';
import { useStateValue } from '../../../ContextApi/StateProvider';
import moment from 'moment/moment';

function Attendance() {
  const [data, getData] = useApi();
  const [{ user }, _] = useStateValue();
  const [selectedDate, selectDate] = useState();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (user) getData(STAFF_MONTHLY_ATTENDANCE(user.user._id), 'GET');
  }, [user]);

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };

  useEffect(() => {
    if (data?.data) {
      const e = [];
      data?.data?.attendance?.forEach((x) => {
        e.push({
          title: x.type,
          start: new Date(x?.x?.date),
          end: new Date(x?.x?.date),
          details: x.x,
        });
      });
      setEvents(e);
    }
  }, [data]);

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.title.includes('absent') && {
        className: 'redEventColor',
      }),
      ...(event.title.includes('present') && {
        className: 'greenEventColor',
      }),
      ...(event.title.includes('non-working') && {
        className: 'regionalHolidayColor',
      }),
      ...(event.title.includes('leave') && {
        className: 'restrictedHolidayColor',
      }),
    }),
    []
  );

  return (
    <div className="w-full md:w-4/5">
      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <p className="font-bold text-base">Attendance</p>
        </div>

        <div className="flex flex-col md:flex-row w-full ">
          <div className="w-full md:w-1/2 py-4 mx-2 gap-x-2">
            <div className="bg-white m-auto border rounded-2xl" style={{ borderColor: '#E7E700', width: '96%' }}>
              <Calendar
                localizer={localizer}
                events={events}
                style={{ height: 400 }}
                views={['month']}
                onSelectEvent={(e) => selectDate(e)}
                eventPropGetter={eventPropGetter}
              />
            </div>
          </div>

          <div className="w-full md:w-1/2 py-4 mr-4 gap-x-2">
            <div className="bg-white m-auto border border-borderYellow rounded-2xl w-full overflow-hidden">
              <div className="w-full  bg-tableHeader ">
                <p className="py-2 px-4 text-sm text-black font-medium">
                  Date Selected - {selectedDate?.details?.date || ''}
                </p>
              </div>
              <div className="overflow-auto">
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Shift</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Shift IN-TIME</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Shift OUT-TIME</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Shift Total Hour</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>

                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">In Time</div>
                    <div className="text-tableRowText ">{selectedDate?.details?.inTime || '--'}</div>
                  </div>
                </div>
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Out Time</div>
                    <div className="text-tableRowText ">{selectedDate?.details?.outTime || '--'}</div>
                  </div>
                </div>
                <div className="w-full py-3.5 border-b border-borderYellow">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Total Hours</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>
                <div className="w-full py-3.5">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium items-center justify-between">
                    <div className="text-black ">Attendance Status</div>
                    <div className="text-tableRowText ">--</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-4" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold text-base ">
              <p>{`Attendance Summary - ${moment().clone().startOf('month').format('DD MMMM YYYY')} to ${moment()
                .clone()
                .endOf('month')
                .format('DD MMMM YYYY')}`}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full ">
          <div className="w-full py-3.5" style={{ marginTop: '-2px' }}>
            <div className="bg-white m-auto border border-borderYellow rounded-2xl" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-2xl">
                <div className="flex w-full relative text-left px-4 text-xs font-bold">
                  <div className="w-1/2 border-r-2 py-2 px-2">Date</div>
                  <div className="w-1/2 border-r-2 py-2 px-2">Date</div>
                </div>
              </div>
              <div className="overflow-auto">
                <div className="w-full py-2 border-b border-borderYellow">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">Total Present</div>
                    <div className="w-1/2 text-tableRowText px-2">{data?.data?.presents + '' || '--'}</div>
                  </div>
                </div>
                <div className="w-full py-2 border-b border-borderYellow">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">Total Absent</div>
                    <div className="w-1/2 text-tableRowText px-2">{data?.data?.absents + '' || '--'}</div>
                  </div>
                </div>
                <div className="w-full py-2 border-b border-borderYellow">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">Late In / Early Out</div>
                    <div className="w-1/2 text-tableRowText px-2">{'--'}</div>
                  </div>
                </div>
                <div className="w-full py-2 border-b border-borderYellow">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">Leave</div>
                    <div className="w-1/2 text-tableRowText px-2">{data?.data?.leaves + '' || '--'}</div>
                  </div>
                </div>
                <div className="w-full py-2 border-b border-borderYellow">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">CompOff Acquired</div>
                    <div className="w-1/2 text-tableRowText px-2">{'--'}</div>
                  </div>
                </div>
                <div className="w-full py-2 ">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">Weekly Off</div>
                    <div className="w-1/2 text-tableRowText px-2">{data?.data?.weekoffs || '--'}</div>
                  </div>
                </div>
                {/* <div className="w-full py-2">
                  <div className="justify-between flex w-full relative text-left py-3 px-4 text-xs font-medium items-center">
                    <div className="w-1/2 text-tableRowText px-2">CompOff Acquired</div>
                    <div className="w-1/2 text-tableRowText px-2">{'--'}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
