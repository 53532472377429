import { ATTENDANCE } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const LEAVE_HISTORY = `${ATTENDANCE}/institute/staff/leave?year=${year}`;

export const STAFF_LEAVE_HISTORY = `${ATTENDANCE}/institute/staff/leaves/request?year=${year}`; //principal and admin

export const STAFF_ATTENDANCE_HISTORY = (staffId) =>
  `${ATTENDANCE}/institute/staff/${staffId}/attendancehistory?year=${year}`;

export const LEAVE_APPLY = `${ATTENDANCE}/institute/staff/leave?year=${year}`;

export const STUDENT_LEAVE_APPLY = `${ATTENDANCE}/institute/student/leave?year=${year}`;

export const STAFF_USER_LEAVE_HISTORY = `${ATTENDANCE}/institute/staff/leave/request/get?year=${year}`; //for staff's leaves

export const LEAVE_TYPES = `${ATTENDANCE}/institute/user/leaves?year=${year}`;

export const STAFF_ATTENDANCE = (role) => `${ATTENDANCE}/institute/attendance/staff/${role}?year=${year}`;

export const STUDENT_ATTENDANCE_FOR_PARENT = `${ATTENDANCE}/institute/user`;

export const STUDENT_ATTENDANCE = (id) => `${ATTENDANCE}/institute/student/${id}?year=${year}`;

export const TODAY_CLASS_ATTENDANCE = (classId, sectionId, date) =>
  `${ATTENDANCE}/institute/class/${classId}/section/${sectionId}/date/${date}?year=${year}`;

export const STUDENTS_LEAVE_DETAILS = `${ATTENDANCE}/institute/student/leaveDetail?year=${year}`;

export const STUDENTS_ATTENDANCE_TOTAL = `${ATTENDANCE}/institute/student?year=${year}`;

export const MARK_ATTENDANCE = (classId, sectionId) =>
  `${ATTENDANCE}/Institute/class/${classId}/section/${sectionId}?year=${year}`;

export const STAFF_TODAY_ATTENDANCE = `${ATTENDANCE}/institute/staff/attendance/dailyReport?year=${year}`;

export const SECTION_LEAVES = (classId, sectionId) =>
  `${ATTENDANCE}/institute/class/${classId}/section/${sectionId}/studentReport?year=${year}`;

export const SECTION_STUDENT_LEAVES = (classId, sectionId) =>
  `${ATTENDANCE}/institute/class/${classId}/section/${sectionId}/student?year=${year}`;

export const STAFF_MONTHLY_ATTENDANCE = (staffId) =>
  `${ATTENDANCE}/institute/staff/${staffId}/monthly/attendance?year=${year}`;

export const APPROVE_LEAVE = (id) => `${ATTENDANCE}/institute/staff/leave/${id}/approve?year=${year}`;

export const REJECT_LEAVE = (id) => `${ATTENDANCE}/institute/staff/leave/${id}/reject?year=${year}`;

export const ATTENDANCE_REGULARIZATION = (staffId) =>
  `${ATTENDANCE}/institute/staff/${staffId}/attendance?year=${year}`;

export const ATTENDANCE_REGULARIZATION_REQUESTS = (staffId) =>
  `${ATTENDANCE}/institute/staff/attendance/arrequest?year=${year}`;

export const UPDATE_REGULARIZATION_REQUEST = (requestId) => `${ATTENDANCE}/institute/staff/${requestId}?year=${year}`;
