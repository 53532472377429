import React, { useState, useEffect } from 'react';
import BusRoute from '../../../components/Admin/Transport/BusRoute';
import RouteItem from '../../../components/Admin/Transport/RouteItem';
import DropDown from '../../../components/UI/DropDown';

import useApi from '../../../api/useApi';
import { TRANSPORT_ROUTE } from '../../../api/urls/instituteManagement';
import TransportItem from './TransportItem';
import TransportDetails from './TransportDetails';

function index({ role }) {
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, selectRoute] = useState();

  const [{ isLoading, isError, data }, getTransportRoutes] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
    getTransportRoutes(TRANSPORT_ROUTE, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setRoutes(data || []);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (selectedRoute) {
      toggleView();
    }
  }, [selectedRoute]);

  const refreshList = () => {
    getTransportRoutes(TRANSPORT_ROUTE, 'GET');
  };

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    selectRoute(null);
    toggleView();
  };

  return (
    <>
      {open && <TransportDetails toggleView={handleClose} selectedRoute={selectedRoute} />}

      <div className="w-full md:w-4/5">
        <div className="relative pb-10">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3.5 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-medium w-1/2">
                  <p>Transport</p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="grid md:grid-cols-3 grid-cols-1  w-full gap-4">
                {Array.isArray(routes) && routes.length > 0 ? (
                  routes.map((x) => <TransportItem item={x?.route} selectRoute={selectRoute} />)
                ) : (
                  <p className="md:col-span-3 text-tableRowText text-xl font-light leading-6 my-8 text-center">
                    No Data Available
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
