import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

import DropDown from '../../../components/UI/DropDown';
import ViewIncident from '../../../components/Owner/IncidentReport/ViewIncident';

import { useStateValue } from '../../../ContextApi/StateProvider';

import useApi from '../../../api/useApi';
import { INCIDENT } from '../../../api/urls/incident';
import { SECTION_STUDENTS } from '../../../api/urls/institute';

const Reports = () => {
  const [open, setOpen] = useState(false);
  const [selectedIncident, selectIncident] = useState();
  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [studentList, setStudents] = useState([]);
  const [selectedStudent, selectStudent] = useState();
  const [reset, setReset] = useState(0); //to force reset fields
  const [{ classSections }, dispatch] = useStateValue();

  const [{ isLoading, isError, data }, getIncidents] = useApi();
  const [students, getStudents] = useApi();

  const formDataModel = {
    class: '',
    section: '',
    student: '',
    incident: '',
    action: '',
    date: '',
    time: null,
    studentName: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [createState, createIncident] = useApi();

  useEffect(() => {
    if (classSections && Array.isArray(classSections)) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.class) {
      alert('Please select Class');
      return;
    }

    if (!formData.student) {
      alert('Please select Student');
      return;
    }

    delete formData.studentName;

    const _time = new Date(formData.time).toLocaleTimeString();

    const body = {
      ...formData,
      date: new Date(formData.date + ' ' + _time).toISOString(),
    };

    delete body.time;
    createIncident(INCIDENT, 'POST', body);
  };

  useEffect(() => {
    if (!createState.isLoading && !createState.isError && [201, 202].includes(createState.status)) {
      refreshList();
      handleReset();
      alert('Success');
    }

    if (!createState.isLoading && createState.isError) {
      alert('Failed');
    }
  }, [createState]);

  const refreshList = () => {
    getIncidents(INCIDENT, 'GET');
  };

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
    }
  }, [students]);

  useEffect(() => {
    if (selectedIncident) {
      toggleView();
    }
  }, [selectedIncident]);

  useEffect(() => {
    getIncidents(INCIDENT, 'GET');
  }, []);

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    selectIncident(null);
    toggleView();
  };

  const handleReset = () => {
    setFormData({ ...formDataModel });
    selectStudent(null);
    setStudents(null);
    setReset((prev) => ++prev);
  };

  return (
    <>
      {open && (
        <ViewIncident
          combinedClassSection={combinedClassSection}
          handleClose={handleClose}
          selectedIncident={selectedIncident}
          role={'teacher'}
          refreshList={refreshList}
        />
      )}
      <div className="relative w-full md:w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-4 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-full">
                <p>Report New Incident</p>
              </div>
            </div>
          </div>
          <form className="mt-2  px-6 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
            <div className="mt-4 w-full md:w-2.25/5 relative font-semibold disabled">
              <label htmlFor="testType" className="block  text-sm text-textDark">
                Select Class<span className="text-red-500 ml-1">*</span>
              </label>
              <DropDown
                field={'name'}
                handleItemClick={(x) => {
                  setFormData({
                    ...formData,
                    class: x.classId,
                    section: x.sectionId,
                    student: '',
                    studentName: '',
                  });
                  selectStudent(null);
                  getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
                }}
                title={'Select Class'}
                data={combinedClassSection}
                width={'w-full'}
                margin={'mt-2'}
                otherClasses={'border-borderLight'}
                type={'obj'}
                key={reset}
                // className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="w-1/10" />
            <div className=" w-full md:w-2.25/5"></div>

            <div className="mt-4  w-full md:w-2.25/5 relative font-semibold ">
              <label className="block  text-sm text-textDark">
                Student Name<span className="text-red-500 ml-1">*</span>
              </label>
              <DropDown
                field={'full_name'}
                handleItemClick={(x) => {
                  selectStudent(x);
                  setFormData({
                    ...formData,
                    student: x._id,
                    studentName: x?.full_name,
                  });
                }}
                title={formData.studentName || 'Select Student'}
                data={studentList}
                width={'w-full'}
                margin={'mt-2'}
                otherClasses={'border-borderLight'}
                type={'obj'}
                key={reset}
              />
            </div>
            <div className="w-1/10" />
            <div className="mt-4  w-full md:w-2.25/5 relative font-semibold ">
              <label className="block  text-sm text-textDark">ROLEDin ID</label>
              <input
                type="text"
                name="userName"
                disabled
                value={selectedStudent?.username || ''}
                key={reset}
                className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-4  w-full md:w-2.25/5 relative font-semibold ">
              <label className="block  text-sm text-textDark">
                Date<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="date"
                placeholder="Ex- DD/MM/YY"
                name="date"
                required
                value={formData.date}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="w-1/10" />
            <div className="mt-4  w-full md:w-2.25/5 relative font-semibold ">
              <label className="block mb-2 text-sm text-textDark">Time</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  value={formData.time}
                  onChange={(time) => setFormData({ ...formData, time: time })}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </div>
            <div className="mt-4  w-full relative font-semibold">
              <label className="block  text-sm text-textDark">
                Incident Detail<span className="text-red-500 ml-1">*</span>
              </label>
              <textarea
                rows="4"
                cols="50"
                name="incident"
                required
                value={formData.incident}
                onChange={handleInputChange}
                placeholder="Ex- Rs 25"
                className="w-full rounded-2xl border text-xs border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-4  w-full relative font-semibold">
              <label className="block  text-sm text-textDark">Action Taken</label>
              <textarea
                rows="4"
                cols="50"
                name="action"
                value={formData.action}
                onChange={handleInputChange}
                placeholder="Ex- Rs 25"
                className="w-full rounded-2xl border text-xs border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>
            <div className="w-full float-right mb-5 mt-4 relative ">
              {/* <div className="w-1/3" /> */}
              {createState.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex float-right">
                  <button
                    className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border border-tableRowText text-tableRowText px-12 py-2 rounded-3xl text-xs  focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleReset();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs  focus:outline-none flex justify-center items-center bg-primary text-white"
                    // onClick={() => setModify(false)}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>

        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-5/12">
                <p>Incident History</p>
              </div>

              <div className="relative mx-2 w-1/2 md:w-2/12">
                <DropDown data={['List View']} width={'w-full'} handleItemClick={() => {}} />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-3xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-3/12 border-r-2 py-2 px-2">Incident Date</div>
                    <div className="w-3/12 border-r-2 py-2 px-2">Incident Time</div>
                    <div className="w-3/12 border-r-2 py-2 px-2">Student Name</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                    <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                    <div className="w-3/12 py-2 px-2">Incident Details</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                  {isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-8" alt="" />
                  ) : Array.isArray(data) && data.length > 0 ? (
                    data.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                            idx !== data.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-3/12 px-2 text-tableRowText">{moment(x.date).format('D MMMM YYYY')}</div>
                          <div className="w-3/12 px-2 text-textDark">{moment(x.date).format('hh:mm a')}</div>
                          <div className="w-3/12 px-2 text-textDark">{x.student}</div>
                          <div className="w-2/12 px-2 text-textDark">{x.class}</div>
                          <div className="w-3/12 px-2 text-textDark">5{x.studentId}</div>
                          <div className="w-3/12 px-2 text-textDark">
                            <button
                              type="submit"
                              className="border border-primary w-10/12 px-8 py-2 rounded-3xl text-primary text-xs mx-1 focus:outline-none flex justify-center text-center items-center"
                              onClick={() => selectIncident(x)}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
