import React from "react";
import {
  ViewPicture,
  FlexWrapperFour,
  FlexWrapperSix,
  PostProfilePicture,
  FlexWrapperTen,
  PostUserName,
  PostUserDesignation,
  POstTiming,
  MoreVertBlack24dp11,
  Vector,
  RelativeWrapperOne,
  PostText,
  PostEmoticons,
  VectorTwo,
  FlexWrapperSeven,
  Rectangle78,
  FlexWrapperOne,
  Rectangle78Two,
  Rectangle78Three,
  Rectangle78Four,
  FlexWrapperTwo,
  Rectangle78Five,
  ScrollMoreShape,
  ScrollMoreIcon,
  Divider,
  FlexWrapperEight,
  Group187,
  ChargeCountIcon,
  VectorThree,
  ChargeCountLabel,
  CommentCount,
  DividerTwo,
  FlexWrapperNine,
  Group184,
  VectorFour,
  ChargedLabel,
  CommentIcon,
  CommentLabel,
  DividerThree,
  FlexWrapperFive,
  CommentSectionLabel,
  FlexWrapperThree,
  FlexWrapperTwelve,
  COmmentProfilePicture,
  CommentUserName,
  CommentText,
  CloseWindowIcon,
  VectorFive,
  ViewWrapper
} from "./style";

const ViewPostComponent = () => {
  return (
    <ViewWrapper>
    <ViewPicture>
      <FlexWrapperFour>
        <FlexWrapperSix>
          <PostProfilePicture
            alt=""
            src="https://static.overlay-tech.com/assets/9688a59b-71a9-481e-9a4c-95ab42acff75.png"
          />
          <FlexWrapperTen>
            <PostUserName>Amit Sharma</PostUserName>
            <PostUserDesignation>
              AS Groups - Founder
            </PostUserDesignation>
            <POstTiming>20 July at 09:00 AM</POstTiming>
          </FlexWrapperTen>
          <MoreVertBlack24dp11>
            <Vector
              alt=""
              src="https://static.overlay-tech.com/assets/22659ed0-0b2e-42a3-8e23-e91f63135454.svg"
            />
          </MoreVertBlack24dp11>
        </FlexWrapperSix>
        <RelativeWrapperOne>
          <PostText>Happiness with Friends .....</PostText>
          <PostEmoticons>
            <VectorTwo
              alt=""
              src="https://static.overlay-tech.com/assets/71de1956-50b7-441a-8a8f-3246dc743814.svg"
            />
          </PostEmoticons>
        </RelativeWrapperOne>
        <FlexWrapperSeven>
          <Rectangle78
            alt=""
            src="https://static.overlay-tech.com/assets/961a8ec1-a5a6-4a3f-9258-c37241ab0691.png"
          />
          <FlexWrapperFour>
            <FlexWrapperOne>
              <Rectangle78Two
                alt=""
                src="https://static.overlay-tech.com/assets/d9e39e2e-bcf4-4342-8242-99a7cd820229.png"
              />
            </FlexWrapperOne>
            <Rectangle78Three
              alt=""
              src="https://static.overlay-tech.com/assets/a67006a1-6f91-4a0d-aad3-9932ce1a91f8.png"
            />
            <Rectangle78Three
              alt=""
              src="https://static.overlay-tech.com/assets/3372ad35-d683-4cce-b9e5-f2fc6a408ac4.png"
            />
            <Rectangle78Three
              alt=""
              src="https://static.overlay-tech.com/assets/75162424-0c98-4cfc-8c8d-35ce44a0faee.png"
            />
            <Rectangle78Four
              alt=""
              src="https://static.overlay-tech.com/assets/da5f83be-1c6e-406e-a638-3dc851dbd81d.png"
            />
            <FlexWrapperTwo>
              <Rectangle78Five
                alt=""
                src="https://static.overlay-tech.com/assets/54b284e0-6c2b-49f1-a010-30ca9dcdff5d.png"
              />
              <ScrollMoreShape />
              <ScrollMoreIcon
                alt=""
                src="https://static.overlay-tech.com/assets/c5ff8a95-f89e-4d06-a9ae-0bc4203bacf6.svg"
              />
            </FlexWrapperTwo>
          </FlexWrapperFour>
        </FlexWrapperSeven>
        <Divider />
        <FlexWrapperEight>
          <Group187>
            <ChargeCountIcon>
              <VectorThree
                alt=""
                src="https://static.overlay-tech.com/assets/b3bf372b-d568-4ce3-b997-dd88a1088f73.svg"
              />
            </ChargeCountIcon>
            <ChargeCountLabel>
              Charged by 68 Peoples
            </ChargeCountLabel>
          </Group187>
          <CommentCount>1 Comment</CommentCount>
        </FlexWrapperEight>
        <DividerTwo />
        <FlexWrapperNine>
          <Group184>
            <VectorFour
              alt=""
              src="https://static.overlay-tech.com/assets/39efa5bc-a9d5-49e9-b062-3d6fe9c921c8.svg"
            />
            <ChargedLabel>Charged </ChargedLabel>
          </Group184>
          <Group184>
            <CommentIcon
              alt=""
              src="https://static.overlay-tech.com/assets/1d4d8513-b37b-4247-aea0-453c6770d95f.svg"
            />
            <CommentLabel>Comment</CommentLabel>
          </Group184>
        </FlexWrapperNine>
      </FlexWrapperFour>
      <DividerThree />
      <FlexWrapperFive>
        <CommentSectionLabel>Comments</CommentSectionLabel>
        <FlexWrapperThree>
          <FlexWrapperTwelve>
            <COmmentProfilePicture
              alt=""
              src="https://static.overlay-tech.com/assets/cbc5e9d6-5161-4b03-9cfa-8155e4f4facb.png"
            />
            <CommentUserName>Amit Sharma</CommentUserName>
          </FlexWrapperTwelve>
          <CommentText>Mantra to live life</CommentText>
        </FlexWrapperThree>
      </FlexWrapperFive>
      <CloseWindowIcon>
        <VectorFive
          alt=""
          src="https://static.overlay-tech.com/assets/e8d98900-c352-403d-bb2b-a1600b10bfba.svg"
        />
      </CloseWindowIcon>
    </ViewPicture>
    </ViewWrapper>
  );
};

export default ViewPostComponent;