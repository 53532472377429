import React from 'react';
import moment from 'moment';
import { useStateValue } from '../../../ContextApi/StateProvider';

function UpcomingParentMeetings({ meetings, setStatus, role }) {
  const [{ user }, _] = useStateValue();

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-4/12">
            <p>{`Upcoming Meetings (With ${role === 'Parent' ? 'Teachers' : 'Parents'})`}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 pr-2">{role === 'Parent' ? 'Teacher Name' : 'Student Name'}</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Meeting Request Raised by</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Agenda</div>
                <div className="w-3/12 py-2 px-2">Status</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {meetings?.data && meetings?.data?.length > 0 ? (
                meetings?.data?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                        meetings?.data?.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-tableRowText">
                        {role === 'Parent'
                          ? user?.user?._id === x?.user_id?._id
                            ? x?.meeting_with?.full_name
                            : x?.user_id?.full_name
                          : x?.meeting?.child_id?.full_name}
                      </div>
                      <div className="w-3/12 px-2 text-tableRowText">
                        {user?.user?._id === x?.user_id?._id ? 'You' : role === 'Parent' ? 'Teacher' : 'Parent'}
                      </div>
                      <div className="w-2/12 px-2 text-tableRowText">
                        {x.date ? moment(x?.date).format('ll') : moment(x?.meeting?.date).format('ll')}
                      </div>
                      <div className="w-2/12 px-2 text-tableRowText">
                        <button
                          className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            setStatus({
                              option: 'View',
                              x: {
                                date: x?.date || x?.meeting?.date,
                                reason: x.reason || x?.meeting?.reason,
                              },
                              hideBtn: true,
                            });
                          }}
                        >
                          View
                        </button>
                      </div>
                      <div className="w-3/12 flex items-center px-2 gap-x-4">
                        {x.status || x?.meeting?.status ? (
                          x?.status === 'Approved' || x?.meeting?.status === 'Approved' ? (
                            <div className="py-2 text-tableRowText rounded-3xl text-xs focus:outline-none">
                              Accepted
                            </div>
                          ) : (
                            <div className="py-2 text-tableRowText rounded-3xl text-xs focus:outline-none">
                              Rejected
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingParentMeetings;
