import React, { useState, useEffect, useReducer } from 'react';
import { useStateValue } from '../ContextApi/StateProvider';
// import { LOGIN } from './urls/authentication';
import { apiClient } from './axiosClient';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const apiReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        status: action.status,
      };
    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: action.status,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const useApi = () => {
  // const BASE_URL = 'https://roledin.com/api/';
  // const BASE_URL = 'http://localhost:';

  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [method, setMethod] = useState('');
  const [apiHeaders, setHeaders] = useState({});
  const [{}, dispatch2] = useStateValue();
  const history = useHistory();
  const token = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    isError: false,
    data: data,
    status: '',
  });

  const setApiRequest = (url, method, data = {}, headers) => {
    setMethod(method);
    setData(data);
    let finalUrl = url;

    if (url.indexOf('?') !== -1) {
      finalUrl += `&session=${sessionStorage.getItem('session')}`;
    } else {
      finalUrl += `?session=${sessionStorage.getItem('session')}`;
    }
    setUrl(finalUrl);

    setHeaders(headers);
  };

  useEffect(() => {
    let didCancel = false;

    const callApi = async () => {
      dispatch({ type: 'INIT' });
      try {
        let result;
        if (method.toLowerCase() === 'get') {
          result = await apiClient.get(url, {
            headers: url.includes('password')
              ? {
                  'Content-Type': 'application/json',
                }
              : {
                  Authorization: `Bearer ${token?.access_token}`,
                  'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
                },
          });
        } else if (method.toLowerCase() === 'post') {
          result = await apiClient.post(url, data, {
            headers: url.includes('password')
              ? {
                  'Content-Type': 'application/json',
                }
              : url.includes('oauth/token')
              ? {
                  Authorization: 'Basic Um9sZWRJbjpSb2xlZEluIzEyMw==',
                  'Content-Type': 'application/x-www-form-urlencoded',
                }
              : {
                  Authorization: `Bearer ${token?.access_token}`,
                  'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
                },
          });
        } else if (method.toLowerCase() === 'put') {
          result = await apiClient.put(url, data, {
            headers: url.includes('password')
              ? {
                  'Content-Type': 'application/json',
                }
              : {
                  Authorization: `Bearer ${token?.access_token}`,
                  'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
                },
          });
        } else if (method.toLowerCase() === 'delete') {
          result = await apiClient.delete(
            url,
            {
              headers: {
                Authorization: `Bearer ${token?.access_token}`,
                'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
              },
            },
            data
          );
        }
        // const result = await axios({
        //   method,
        //   url,
        //   data,
        //   headers: url.includes('password')
        //     ? {
        //         'Content-Type': 'application/json',
        //       }
        //     : url.includes('oauth/token')
        //     ? {
        //         Authorization: 'Basic Um9sZWRJbjpSb2xlZEluIzEyMw==',
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //       }
        //     : {
        //         Authorization: `Bearer ${token?.access_token}`,
        //         'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
        //       },
        // });
        if (!didCancel) {
          console.log('URL:' + url + ' Result ', result);
          dispatch({ type: 'SUCCESS', payload: result.data, status: result.status });
        }
      } catch (error) {
        if (error && error === '401') {
          dispatch2({
            type: 'TOKEN',
            token: null,
          });
          Cookies.remove('auth');
          history.push('/login');
          return;
        }
        console.log('error', error?.response?.data || error);
        if (!didCancel) {
          dispatch({
            type: 'FAILURE',
            payload: error?.response && error?.response?.data ? error?.response?.data : '',
            status: error?.response?.status,
          });
        }
      }
      setMethod('');
      setData(null);
      setUrl('');
    };

    if (url && method) callApi();

    return () => {
      didCancel = true;
    };
  }, [url, method, data]);

  return [state, setApiRequest];
};

export default useApi;
