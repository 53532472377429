export const SCREENS_FEATURES = [
  {
    screen: 'Social',
    features: ['Uploading Post', 'Uploading Comment', 'Charging Post'],
  },
  {
    screen: 'Owner',
    features: [
      'Academics',
      'Students',
      'Staff',
      'Budget & Transactions',
      'Meeting Request',
      'Task Board',
      'Manage Calender',
      'Notices',
      'Manage Feedback',
      'Transport',
    ],
  },
  {
    screen: 'Admin',
    features: [
      'Academics',
      'Student Management',
      'Staff Management',
      'Attendance Report',
      'Budget and Expenses',
      'Events',
      'Manage Leaves',
      'Incident Reports',
      'Transport Routes',
      'Student Report',
      'Meeting Request',
      'Feedback',
    ],
  },
  {
    screen: 'Principal',
    features: [
      'Academics',
      'Attendance Report',
      'Teaching Staff',
      'Admissions',
      'Manage Leaves',
      'Incident Reports',
      'Meeting Request',
      'Student Report',
      'Task Board',
      'Share Notice',
      'Feedback',
      'Release Result',
    ],
  },
  {
    screen: 'Teacher',
    features: [
      'Course Handout',
      'Attendance',
      'Lectures',
      'Live Sessions',
      'Assignments and Test',
      'Marks',
      'Events/Timetable',
      'Remarks',
      'Report Incident',
      'Apply for Leave',
      'Meeting Request',
      'Task Board',
      'Share Feedback',
    ],
  },
  {
    screen: 'Parent',
    features: [
      'Attendance',
      'Events/Timetable',
      'Course Details',
      'Lecture Summary',
      'Live Sessions',
      'Assignment and Evaluation',
      'Marks and Result',
      'Share Feedback',
      'Fee',
    ],
  },
  {
    screen: 'Student',
    features: [
      'Attendance',
      'Events/Timetable',
      'Course Details',
      'Lecture Summary',
      'Live Sessions',
      'Assignment and Evaluation',
      'Report Card',
      'Share Feedback',
      'Fee ',
    ],
  },
  {
    screen: 'Finance',
    features: [
      'Staff and Salaries',
      'Class Wise Fee Structure',
      'Expense Ledger',
      'Record Fee Payment',
      'Apply For Leave',
      'Meeting Request',
      'Task Board',
      'Share Feedback',
      'Attendance',
    ],
  },
];

export const getScreens = () => {
  return SCREENS_FEATURES.map((x) => x.screen);
};

export const getScreenFeatures = (screen) => {
  return SCREENS_FEATURES.find((x) => x.screen === screen).features;
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
