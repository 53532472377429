import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../../UI/DropDown';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { CREATE_TEST, UPDATE_TEST } from '../../../api/urls/test';
import { EXAM_PATTERN_GET } from '../../../api/urls/institute';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import 'moment/locale/de';

function CreateTest({ setCreateTest, refreshList, selectedTest, selectTest, subjectDetails, isEdit }) {
  const [step, setStep] = useState(1);
  const [examTypes, setExamTypes] = useState([]);

  const [examTypesResponse, getExamTypes] = useApi();

  const syllabusRef = useRef();

  const formDataModel = {
    title: '',
    start: null,
    testType: 'Select',
    mode: 'Online',
    instruction: '',
    testDate: '',
    testReminder: null,
    question: [],
    autoSubmit: false,
    attempt: 1,
    maxMarks: '',
    minMarks: '',
    syallabus: '',
    end: null,
    marks: 0,
  };

  const questionModel = {
    question: '',
    type: 'Select',
    marks: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const [questions, setQuestions] = useState([questionModel]);
  const [questionsList, setQuestionsList] = useState([0]);

  const [testResponse, createTest] = useApi();

  useEffect(() => {
    if (subjectDetails) getExams(subjectDetails.class.name);
  }, [subjectDetails]);

  useEffect(() => {
    if (selectedTest) {
      const {
        start,
        end,
        test_date,
        time_reminder,
        marks,
        title,
        test_type,
        mode,
        instruction,
        auto_submit,
        attempt,
        maxMarks,
        minMarks,
        question,
      } = selectedTest;

      setFormData({
        start: start && moment(start),
        end: end && moment(end),
        testDate: test_date && new Date(test_date).toISOString().substring(0, 10),
        testReminder: time_reminder ? (time_reminder.indexOf(':') === 2 ? time_reminder : moment(time_reminder)) : '',
        marks,
        title,
        testType: test_type,
        mode,
        instruction,
        autoSubmit: auto_submit ? 'Off' : 'On',
        attempt,
        maxMarks,
        minMarks,
        marks,
      });

      setQuestions(question);
      setQuestionsList(question.map((x, idx) => idx));
    }
  }, [selectedTest]);

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }
    setFormData({ ...formData, [e.target.name]: inputValue });
  };

  const handleQuestionChange = (e, index) => {
    const x = questions;
    const y = {
      ...questions[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setQuestions([...x]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.mode === 'Online') {
      let moreMarks = [];
      let totalMarks = 0;
      if (questions.length === 0) {
        alert(`Please add Questions`);
        return;
      }
      let str = [];
      questions.forEach((x, idx) => {
        if (!x?.type || x.type === 'Select') str.push(`type in question ${idx + 1}`);
        if (!x.question) str.push(`question in question ${idx + 1}`);
        if (x.answer === 'Select') str.push(`answer in question ${idx + 1}`);
        if (!x.marks) str.push(`marks in question ${idx + 1}`);
        if (+x.marks > formData.maxMarks) moreMarks.push(`marks in question ${idx + 1} `);
        totalMarks += +x.marks;
      });

      if (str.length > 0) {
        alert(`Missing ${str.join(', ')}`);
        return;
      }

      if (moreMarks.length > 0) {
        alert(`${moreMarks.join(', ')} is more than maximum marks`);
        return;
      }

      if (totalMarks > +formData.maxMarks) {
        alert(`Total marks ${totalMarks} are more than maximum marks ${formData.maxMarks}`);
        return;
      }
    }
    // const body = {
    //   ...formData,
    //   title: formData.testType,
    //   question: questions,
    //   start: formData.start ? moment(formData.start, 'HH:mm').toLocaleString() : '',
    //   testReminder: formData.testReminder ? moment(formData.testReminder, 'HH:mm').toLocaleString() : '',
    //   end: formData.end ? moment(formData.end, 'HH:mm').toLocaleString() : '',
    //   marks: formData.maxMarks,
    // };

    const { start, end, testDate, testReminder } = formData;

    const _start = new Date(start).toLocaleTimeString();
    const _end = new Date(end).toLocaleTimeString();
    const _reminder = new Date(testReminder).toLocaleTimeString();

    const combinedStartString = `${testDate} ${_start}`;
    const newStartTime = moment(combinedStartString);

    const combinedEndString = `${testDate} ${_end}`;
    const newEndTime = moment(combinedEndString);

    const combinedReminderString = `${testDate} ${_reminder}`;
    const newReminderTime = moment(combinedReminderString);

    const fd = new FormData();
    fd.append('title', formData.title || formData.testType);
    fd.append('start', newStartTime);
    fd.append('testType', formData.testType);
    fd.append('mode', formData.mode);
    fd.append('instruction', formData.instruction);
    fd.append('testDate', formData.testDate);
    fd.append('testReminder', newReminderTime);
    fd.append('question', JSON.stringify(questions));
    fd.append('autoSubmit', formData.autoSubmit);
    fd.append('attempt', formData.attempt);
    fd.append('maxMarks', formData.maxMarks);
    fd.append('minMarks', formData.minMarks);
    fd.append('syallabus', formData.syallabus);
    fd.append('end', newEndTime);
    fd.append('marks', formData.maxMarks);

    // console.log(newStartTime, newEndTime, newReminderTime);
    const classId = selectedTest ? selectedTest.class_id : subjectDetails.class._id;
    const sectionId = selectedTest ? selectedTest.section_id : subjectDetails.section._id;
    const subjectId = selectedTest ? selectedTest.subject_id : subjectDetails.subjectId;

    if (selectedTest) createTest(UPDATE_TEST(selectedTest?._id), 'PUT', fd);
    else createTest(CREATE_TEST(classId, sectionId, subjectId), 'POST', fd);
  };

  useEffect(() => {
    if (!testResponse.isLoading && !testResponse.isError && [201, 202, 200].includes(testResponse.status)) {
      setFormData(formDataModel);
      setQuestionsList(0);
      setQuestions([questionModel]);
      setCreateTest(false);
      refreshList();
      if (subjectDetails) getExams(subjectDetails.class.name);
      alert('Success');
    }

    if (!testResponse.isLoading && testResponse.isError) {
      alert('Failed');
    }
  }, [testResponse]);

  const gotoStep2 = () => {
    let str = '';

    if (formData.testType === 'Select') str += 'Test type, ';
    if (!formData.testDate) str += 'Test Date, ';
    if (!formData.maxMarks) str += 'Maximum Marks, ';
    if (!formData.minMarks) str += 'Minimun Marks, ';
    if (formData.testType === 'Class Test' && !formData.title) str += 'Title, ';
    if (str) {
      alert(`Please add ${str}`);
      return;
    }

    if (+formData.maxMarks <= 0) {
      alert(`Maximum Marks should be greater than 0`);
      return;
    }

    if (+formData.minMarks < 0) {
      alert(`Minimum Marks should be greater than or equal to 0`);
      return;
    }

    if (+formData.maxMarks < +formData.minMarks) {
      alert(`Maximum Marks should be greater than Minimum marks`);
      return;
    }

    setStep(2);
  };

  const gotoStep3 = () => {
    let str = '';
    if (!formData.start) str += 'Start Time, ';
    if (!formData.end) str += 'End Time, ';
    if (formData.mode === 'Online') {
      if (!formData.instruction) str += 'Instruction, ';
    }

    if (str) {
      alert(`Please add ${str}`);
      return;
    } else setStep(3);
  };

  const getExams = (_class) => {
    let category = '';
    if (_class.toLowerCase().includes('kg') || _class.toLowerCase().includes('nursery')) {
      category = 'PrePrimary';
    } else if (_class.includes(11) || _class.includes(12)) {
      category = 'Senior-Secondary';
    } else if (_class.includes(9) || _class.includes(10)) {
      category = 'Secondary';
    } else if (_class.includes(6) || _class.includes(7) || _class.includes(8)) {
      category = 'Middle';
    } else if (
      _class.includes(1) ||
      _class.includes(2) ||
      _class.includes(3) ||
      _class.includes(4) ||
      _class.includes(5)
    ) {
      category = 'Primary';
    }
    getExamTypes(EXAM_PATTERN_GET(category), 'GET');
  };

  useEffect(() => {
    if (!examTypesResponse.isLoading && !examTypesResponse.isError && examTypesResponse.data) {
      setExamTypes(examTypesResponse.data || []);
    }
  }, [examTypesResponse]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-7/12 pb-6 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="border-b border-borderYellow py-4">
          <span className=" font-bold px-6">Create Class Test</span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-4 h-3 cursor-pointer"
            alt=""
            onClick={() => {
              setCreateTest(false);
              selectTest(null);
            }}
          />
        </div>

        <div className="font-bold p-2 flex items-center justify-evenly w-full border-b border-borderYellow">
          <button
            className={`w-1/3 px-8 py-2 rounded-3xl ${
              step === 1 ? 'bg-primary text-white' : 'bg-white text-textDark'
            } text-xs mx-1 focus:outline-none flex justify-center text-center items-center`}
          >
            Exam Detail
          </button>
          <button
            className={`w-1/3 px-8 py-2 rounded-3xl ${
              step === 2 ? 'bg-primary text-white' : 'bg-white text-textDark'
            } text-xs mx-1 focus:outline-none flex justify-center text-center items-center`}
          >
            Exam Conditions
          </button>
          {formData?.mode === 'Online' && (
            <button
              className={`w-1/3 px-8 py-2 rounded-3xl ${
                step === 3 ? 'bg-primary text-white' : 'bg-white text-textDark'
              } text-xs mx-1 focus:outline-none flex justify-center text-center items-center`}
            >
              Questions
            </button>
          )}
        </div>

        {step === 1 && (
          <div className="px-6 pt-6 grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mb-4 full relative font-semibold disabled">
              <label htmlFor="testType" className="block text-xs text-textDark">
                Test Type<span className="text-red-500 ml-1">*</span>
              </label>
              <DropDown
                key={formData.testType}
                width={'w-full'}
                data={
                  examTypes.examPattern && Array.isArray(examTypes.examPattern)
                    ? [
                        'Class Test',
                        ...examTypes.examPattern
                          .filter((x) => !subjectDetails?.createdExams?.includes(x.type))
                          .map((x) => x.type),
                      ]
                    : ['Class Test']
                }
                title={formData.testType}
                handleItemClick={(x) => {
                  setFormData({
                    ...formData,
                    testType: x,
                    maxMarks: x !== 'Class Test' && examTypes.examPattern.find((z) => z.type === x)?.marks,
                  });
                }}
                otherClasses={'border-borderLight mt-2'}
              />
            </div>

            {formData.testType === 'Class Test' ? (
              <div className="mb-4 full relative font-semibold ">
                <label className="block text-xs text-textDark">Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Ex- Test"
                  className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
            ) : (
              <div></div>
            )}

            <div className="mt-2 mb-4 full relative font-semibold ">
              <label className="block text-xs text-textDark">
                Date<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                required
                type="date"
                name="testDate"
                value={formData.testDate}
                onChange={handleInputChange}
                placeholder="Ex- 25 August 2022"
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold ">
              <label className="block text-xs text-textDark">Mode</label>
              <DropDown
                key={formData.mode}
                width={'w-full'}
                data={['Online', 'Offline']}
                title={formData.mode}
                handleItemClick={(x) => {
                  setFormData({ ...formData, mode: x });
                }}
                otherClasses={'border-borderLight mt-2'}
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold">
              <label className="block text-xs text-textDark">
                Maximum Marks<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="number"
                placeholder="Ex- 25"
                name="maxMarks"
                min={0}
                required
                value={formData.maxMarks}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold">
              <label className="block text-xs text-textDark">
                Minimum Marks<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="number"
                name="minMarks"
                value={formData.minMarks}
                onChange={handleInputChange}
                placeholder="Ex-10"
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold">
              <label className="block text-xs text-textDark">Test Syllabus</label>
              <div className="relative flex">
                <input
                  hidden
                  ref={syllabusRef}
                  type="file"
                  onChange={(e) => setFormData({ ...formData, syallabus: e.target.files[0] })}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <input
                  type="text"
                  value={formData.syallabus?.name}
                  disabled
                  className="w-11/12 h-9 rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                  onClick={(e) => {
                    e.preventDefault();
                    syllabusRef.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>

            <div />

            <div className="w-full md:col-span-2">
              <div className=" float-right">
                <button
                  className="mt-1 w-36 border border-primary py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary text-white"
                  onClick={() => gotoStep2()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="px-6 pt-6 grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mb-4 full relative font-semibold disabled">
              <label htmlFor="testType" className="block text-xs text-textDark mb-2">
                Start Time<span className="text-red-500 ml-1">*</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
                <TimePicker
                  label=""
                  required
                  value={formData.start}
                  onChange={(newValue) => {
                    setFormData({ ...formData, start: newValue });
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </div>

            <div className="mb-4 full relative font-semibold disabled">
              <label htmlFor="testType" className="block text-xs mb-2 text-textDark">
                End Time<span className="text-red-500 ml-1">*</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
                <TimePicker
                  label=""
                  required
                  value={formData.end}
                  onChange={(time) => setFormData({ ...formData, end: time })}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </div>

            <div className="mt-2 mb-4 full relative font-semibold ">
              <label className="block text-xs mb-2 text-textDark">Time Reminder</label>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
                <TimePicker
                  label=""
                  value={formData.testReminder}
                  onChange={(time) => setFormData({ ...formData, testReminder: time })}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </div>

            <div className="mt-2 mb-4 full relative font-semibold ">
              <label className="block text-xs text-textDark">Auto Submit</label>
              <DropDown
                width={'w-full'}
                data={['Off', 'On']}
                title={formData.autoSubmit}
                handleItemClick={(x) => {
                  setFormData({ ...formData, autoSubmit: x === 'Off' ? false : true });
                }}
                otherClasses={'border-borderLight mt-2'}
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold">
              <label className="block text-xs text-textDark">No of Attempts per question</label>
              <DropDown
                width={'w-full'}
                data={['Unlimited', 1, 2]}
                title={formData.attempt}
                handleItemClick={(x) => {
                  setFormData({ ...formData, attempt: x === 'Unlimited' ? 999 : x });
                }}
                otherClasses={'border-borderLight mt-2'}
              />
            </div>

            <div className="mt-2 mb-4 full relative font-semibold md:col-span-2">
              <label className="block text-xs text-textDark">
                General Instructions<span className="text-red-500 ml-1">*</span>
              </label>
              <textarea
                rows={4}
                required
                type="text"
                name="instruction"
                value={formData.instruction}
                onChange={handleInputChange}
                placeholder="Wirte instructions here......"
                className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="w-full md:col-span-2">
              <div className=" flex justify-between mt-1">
                {/* <> */}
                <button
                  className="w-36 border py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
                {formData?.mode !== 'Online' && testResponse.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
                ) : (
                  <button
                    className=" w-36 border border-primary py-2 rounded-3xl text-xs focus:outline-none bg-primary text-white"
                    onClick={formData?.mode === 'Online' ? gotoStep3 : handleSubmit}
                  >
                    {formData?.mode === 'Online' ? 'Next' : 'Submit'}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <>
            {questionsList &&
              questionsList.map((x, idx) => (
                <div
                  className={`p-6 grid grid-cols-1 md:grid-cols-3  gap-x-4 ${
                    questionsList.length - 1 !== idx && 'border-b border-borderYellow'
                  }`}
                >
                  <div className="mb-4 full relative font-semibold disabled md:col-span-2 row-span-2">
                    <label htmlFor="testType" className="block text-xs text-textDark">
                      {`Question ${questionsList.length}`}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <textarea
                      rows={4}
                      placeholder="Write your question here"
                      value={questions[x].question}
                      name="question"
                      onChange={(e) => handleQuestionChange(e, x)}
                      style={{ height: '85%' }}
                      className="w-full h-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="mb-4 full relative font-semibold disabled">
                    <label htmlFor="testType" className="block text-xs text-textDark">
                      Question Type<span className="text-red-500 ml-1">*</span>
                    </label>
                    <DropDown
                      width={'w-full'}
                      data={['Select', 'Multiple choice question', 'Subjective Question']}
                      handleItemClick={(e) => {
                        handleQuestionChange({ target: { value: e, name: 'type' } }, x);
                      }}
                      title={questions[x].type}
                      otherClasses={'border-borderLight mt-2'}
                    />
                  </div>

                  <div className="mt-2 mb-4 full relative font-semibold ">
                    <label className="block text-xs text-textDark">
                      Marks<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Ex-10"
                      value={questions[x].marks}
                      name="marks"
                      onChange={(e) => handleQuestionChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  {questions[x].type === 'Multiple choice question' && (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:col-span-2">
                        <div className="mt-2 mb-4 full relative font-semibold ">
                          <label className="block text-xs text-textDark">Answers</label>
                          <input
                            type="text"
                            placeholder="Option 1"
                            value={questions[x].option1}
                            name="option1"
                            onChange={(e) => handleQuestionChange(e, x)}
                            className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                          />
                        </div>

                        <div className="mt-2 mb-4 full relative font-semibold ">
                          <input
                            type="text"
                            placeholder="Option 2"
                            value={questions[x].option2}
                            name="option2"
                            onChange={(e) => handleQuestionChange(e, x)}
                            className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-6 py-2 focus:outline-none"
                          />
                        </div>

                        <div className="mt-2 mb-4 full relative font-semibold ">
                          <input
                            type="text"
                            value={questions[x].option3}
                            name="option3"
                            onChange={(e) => handleQuestionChange(e, x)}
                            placeholder="Option 3"
                            className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-6 py-2 focus:outline-none"
                          />
                        </div>

                        <div className="mt-2 mb-4 full relative font-semibold ">
                          <input
                            type="text"
                            value={questions[x].option4}
                            name="option4"
                            onChange={(e) => handleQuestionChange(e, x)}
                            placeholder="Option 4"
                            className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-6 py-2 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div className="mt-2 mb-4 full relative font-semibold">
                        <label className="block text-xs text-textDark">Correct Answer</label>
                        <DropDown
                          width={'w-full'}
                          data={[
                            'Select',
                            questions[x].option1,
                            questions[x].option2,
                            questions[x].option3,
                            questions[x].option4,
                          ]}
                          handleItemClick={(e) => {
                            handleQuestionChange({ target: { value: e, name: 'answer' } }, x);
                          }}
                          title={questions.answer}
                          otherClasses={'border-borderLight mt-2'}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            <div className="w-full col-span-3 flex justify-between items-center mt-2 px-6">
              {(!selectedTest || isEdit) && (
                <button
                  className="w-36 border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setQuestionsList([...questionsList, questionsList.length]);
                    setQuestions([...questions, questionModel]);
                  }}
                >
                  Add Question
                </button>
              )}
              <div className="flex gap-x-6">
                {testResponse.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
                ) : (
                  <>
                    <button
                      className="w-36 border py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                    {(!selectedTest || isEdit) && (
                      <button
                        className="w-36 border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary text-white"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CreateTest;
