import React from 'react';

function Preview({
  selectedTest,
  answers,
  handleSubmit,
  handleBackToHome,
  attentedQues,
  handleGoToSelectedQues,
  timeRemaining,
}) {
  return (
    <>
      <div className="border-b border-borderYellow px-6 py-3 ">
        <div className="flex items-center justify-between">
          <span className="block font-bold mr-auto">{`${selectedTest?.testDetail?.title} - ${selectedTest.subjectCode} ${selectedTest.subject_name}`}</span>

          <button
            className={
              'border py-2 w-36 mr-4 ml-auto rounded-3xl text-xs font-medium focus:outline-none  text-center text-primary bg-white border-primary'
            }
            onClick={() => {
              handleBackToHome();
            }}
          >
            Back To Home
          </button>

          <button
            className={
              'border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none  text-center bg-primary text-white border-primary'
            }
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>

      <div className="border-b border-borderYellow px-6 py-4 flex items-center justify-between">
        <span className="text-xs">{`Time Remaining - ${timeRemaining}`}</span>
        <span className="text-xs">{`${attentedQues}/${selectedTest?.testDetail.question.length} attended`}</span>
      </div>

      <div className="pb-4">
        {answers.map((x, idx) => {
          if (!x.submitAnswer) return null;
          return (
            <div className={`border-b border-borderYellow px-6 pt-4 pb-12`}>
              <p className="font-semibold mb-2">{`Question ${idx + 1}`}</p>

              <p className="mb-3">{x.question}</p>

              <p className="font-semibold mb-2 mt-6">Your Answer</p>

              <div className="flex items-center mb-1.5">
                {x.type === 'Multiple choice question' && (
                  <input type="radio" className="mr-6 mt-1" checked={!!x.submitAnswer} />
                )}
                <label>{x.submitAnswer}</label>
              </div>

              <button
                className={
                  'border py-2 w-36 float-right rounded-3xl text-xs font-medium focus:outline-none  text-center bg-primary text-white border-primary'
                }
                onClick={() => {
                  handleGoToSelectedQues(idx + 1);
                }}
              >
                {`Go To Question ${idx + 1}`}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Preview;
