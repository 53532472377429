import React, { useState, useEffect } from 'react';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import useApi from '../../../api/useApi';
import { TEACHER } from '../../../api/urls/dashboard';
import moment from 'moment';

const excelHeaders = [
  { label: 'Timings', key: 'timings' },
  { label: 'Subject', key: 'subject' },
  { label: 'Class', key: 'class' },
  { label: 'Live Session', key: 'isLiveSession' },
];

const Dashboard = () => {
  const [selected, select] = useState(null);
  const [excelData, setExcelData] = useState([]);

  const [data, getData] = useApi();

  useEffect(() => {
    getData(TEACHER, 'GET');
  }, []);

  useEffect(() => {
    if (
      !data.isLoading &&
      !data.isError &&
      data.data &&
      data.data.todayLectures &&
      Array.isArray(data?.data?.todayLectures)
    ) {
      const _data = data?.data?.todayLectures;
      let x = _data.map((x) => {
        return {
          timings: `${moment(x.startTime, ['h:mm']).format('hh:mm A')} to ${moment(x.endTime, ['h:mm']).format(
            'hh:mm A'
          )}`,
          subject: x?.subjectName,
          class: x.class + '-' + x.section,
          isLiveSession: !!x.liveSession ? 'YES' : 'NO',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {selected && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 text-sm m-auto mt-20 rounded-2xl relative">
            <div className="flex justify-between border-b border-borderYellow items-center">
              {' '}
              <span className="font-bold px-6 py-4">Lecture Detail - {selected?.subjectName}</span>
              <div className="flex gap-x-4 items-center pr-2">
                {/* <ExcelExportBtn data={[]} text={'Export Data'} fileName={'students.csv'} /> */}

                <img
                  src="assets/img/Close.svg"
                  className="h-3 mr-4 cursor-pointer"
                  alt=""
                  onClick={() => select(null)}
                />
              </div>
            </div>

            <div className="p-6 m-auto" autoComplete="off">
              <div className="w-full relative mb-6">
                <span className="font-bold">Title</span>
                <p className="text-sm text-textDark mt-2">{selected?.summary?.title}</p>
              </div>

              <div className="mt-2 mb-6 w-full relative ">
                <span className="font-bold">Description</span>
                <p className="text-sm text-textDark mt-2">{selected?.summary?.summary}</p>
              </div>

              {selected.notes && (
                <div className="mx-4 w-full flex">
                  <a
                    className="border border-primary ml-auto w-40  mr-6 rounded-3xl text-xs focus:outline-none text-center py-2 bg-primary text-white"
                    href={selected.notes.files[0].location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Notes
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Today's Lecture Detail</p>
            </div>
            <div className="flex justify-end items-center w-full">
              <ExcelExportBtn
                data={excelData}
                headers={excelHeaders}
                text={'Export Data'}
                fileName={'TodayLecture.csv'}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-3/12 border-r-2 py-2 px-2">Timings</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Live Session</div>
                  <div className="w-3/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '65vh' }}>
                {data.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto my-8" alt="" />
                ) : Array.isArray(data?.data?.todayLectures) && data?.data?.todayLectures.length > 0 ? (
                  data?.data?.todayLectures.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs items-center  ${
                          idx !== data?.data?.todayLectures.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-3/12 px-2 py-2 text-tableRowText">{`${moment(x.startTime, ['h:mm']).format(
                          'hh:mm A'
                        )} to ${moment(x.endTime, ['h:mm']).format('hh:mm A')}`}</div>
                        <div className="w-4/12 px-2 text-tableRowText">{x.subjectName}</div>
                        <div className="w-2/12 px-2 text-tableRowText">{x.class + '-' + x.section}</div>
                        <div className="w-3/12 px-2">
                          {x.liveSession && (
                            <a
                              className="w-11/12 border bg-primary border-primary px-6 py-2 rounded-3xl text-white font-medium text-xs focus:outline-none flex justify-center text-center items-center"
                              href={x.liveSession.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Join Now
                            </a>
                          )}
                        </div>
                        <div className="w-3/12 px-2">
                          {x.summary && (
                            <button
                              className="w-11/12 border border-primary px-6 py-2 rounded-3xl text-primary font-medium text-xs focus:outline-none flex justify-center text-center items-center"
                              onClick={() => select(x)}
                            >
                              View Detail
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
