import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'id' },
  { label: 'Roll No', key: 'rollNo' },
  { label: 'Student Name', key: 'name' },
  { label: 'Status', key: 'status' },
];

function TodayAttendance({ info, setMarkAttendance, todayAttendance }) {
  const [excelData, setExcelData] = useState([]);
  const [sort, setSort] = useState('');

  useEffect(() => {
    if (
      !todayAttendance.isLoading &&
      !todayAttendance.isError &&
      todayAttendance.data &&
      todayAttendance.data.attendance &&
      Array.isArray(todayAttendance.data.attendance)
    ) {
      const _data = todayAttendance?.data?.attendance;
      let x = _data.map((x) => {
        return {
          id: x.userName || x.roledInId || '--',
          rollNo: x.rollNo || '--',
          name: x?.name,
          status: todayAttendance.data.isMarked
            ? x.leave
              ? 'On Leave'
              : x.isPresent
              ? 'Present'
              : 'Absent'
            : x.leave
            ? 'On Leave'
            : 'Attendance Not Marked',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [todayAttendance]);

  let filteredData = todayAttendance?.data?.attendance || [];

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = filteredData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name- z to a') {
      filteredData = filteredData.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-full md:w-5/12">
            <p>{`Today's Attendance ${info.length > 0 ? info[0]?.classID?.name : ''} - ${
              info.length > 0 ? info[0]?.sectionID?.name : ''
            }`}</p>
          </div>
          <div className="w-full md:w-7/12 flex justify-end items-center">
            <ExcelExportBtn
              data={excelData}
              headers={excelHeaders}
              text={'Export Data'}
              fileName={'TodayClassAttendance.csv'}
              width={'w-40'}
            />

            <button
              className="border w-40 py-2 rounded-3xl bg-primary border-primary text-xs text-white mx-4 focus:outline-none flex justify-center text-center items-center"
              onClick={() => setMarkAttendance(true)}
            >
              Mark Attendance
            </button>

            <DropDown
              data={['Name- a to z', 'Name- z to a']}
              width={'w-40'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">Roll Number</div>
                <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-5/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-3/12 py-2 px-2">Status</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {todayAttendance.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-8" alt="" />
              ) : filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-5 px-4 text-xs font-medium items-center ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-textDark">{x?.rollNo || '--'}</div>
                      <div className="w-3/12 px-2 text-textDark">{x.userName || x.roledInId || '--'}</div>
                      <div className="w-5/12 px-2 text-textDark">{x.name}</div>
                      <div className="w-3/12 px-2 text-textDark">
                        {todayAttendance.data.isMarked
                          ? x.leave
                            ? 'On Leave'
                            : x.isPresent
                            ? 'Present'
                            : 'Absent'
                          : x.leave
                          ? 'On Leave'
                          : 'Attendance Not Marked'}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TodayAttendance;
