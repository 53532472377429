import React from 'react';
import { useStateValue } from '../../../ContextApi/StateProvider';

function Teachers({ setOpenCreate, teachers }) {
  const [{ user }, _] = useStateValue();

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-4/12">
            <p>Teachers</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/4 border-r-2 py-2 pr-2">Teacher Name</div>
                <div className="w-1/4 border-r-2 py-2 px-2">Subject</div>
                <div className="w-1/4 border-r-2 py-2 px-2">RoledIN ID</div>
                <div className="w-1/4 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {teachers.length > 0 ? (
                teachers.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                        teachers.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/4 px-2 text-tableRowText">{x?.subjectTeacher?.teacher}</div>
                      <div className="w-1/4 px-2 text-tableRowText">{x?.name + ' - ' + x?.code}</div>
                      <div className="w-1/4 px-2 text-tableRowText">{x?.subjectTeacher?.userName}</div>
                      <div className="w-1/4 px-2 text-tableRowText">
                        <button
                          className="w-full lg:w-3/4 border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            setOpenCreate({
                              teacherId: x?.subjectTeacher?.id,
                              name: user?.Responsbility[0]?.childInfo?.full_name,
                              id: user?.Responsbility[0]?.childInfo?._id,
                              roledinId: user?.Responsbility[0]?.childInfo?.username,
                              parent: user?.user?.full_name,
                            });
                          }}
                        >
                          Create Request
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teachers;
