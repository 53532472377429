import React from 'react';
import { useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import Budget from './Budget';
import Staff from '../Admin/Staff';
import Students from './Students';
import Academics from './Academics';
import Meeting from './Meeting';
import Notice from './Notice';
import TaskBoard from './TaskBoard';
import Feedback from './Feedback';
import Transport from './Transport';
import Calender from './Calender';
import AcademicCalender from '../Principal/AcademicCalender';
import ManageLeaves from '../Admin/ManageLeaves';

const Owner = () => {
  const location = useLocation();

  return (
    <div>
      {location.search === '' && <Dashboard />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('Academics') && <Academics />}
      {location.search.includes('Budget&Transactions') && <Budget role={'owner'} />}
      {location.search.includes('MeetingRequest') && <Meeting />}
      {location.search.includes('Leaves') && <ManageLeaves />}
      {location.search.includes('Staff') && <Staff />}
      {location.search.includes('Students') && <Students />}
      {location.search.includes('Notices') && <Notice />}
      {location.search.includes('TaskBoard') && <TaskBoard />}
      {location.search.includes('ManageFeedback') && <Feedback />}
      {location.search.includes('Transport') && <Transport />}
      {location.search.includes('ManageCalender') && <Calender />}
    </div>
  );
};
export default Owner;
