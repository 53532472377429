import React from 'react';

function PendingFeeApproval({ data, selectFee }) {
  let pendingApproval = data?.data || [];

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center px-6 py-3 border-b border-tableHeader">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold text-base w-1/4">
            <p>Student Fee Approval</p>
          </div>
          <div className="flex justify-end w-full gap-x-3">
            {/* <ExcelExportBtn
          data={excelData}
          headers={excelHeaders}
          fileName={'fee_record.csv'}
          text={'Export Data'}
          width={'w-36'}
        />
        <Search
          placeholder={'Search'}
          width={'w-36'}
          onChange={(x) => setSearch(x)}
          onEnter={() => console.log('enter')}
        />
        <DropDown
          field={'name'}
          handleItemClick={(x) => {
            getData(x.classId, x.sectionId);
          }}
          title={'Select Class'}
          data={combinedClassSection}
          width={'w-40'}
          // otherClasses={'border-borderLight'}
          type={'obj'}
        /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl min-w-700"
            style={{ borderColor: '#E7E700', width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                {/* <div className="w-1/5 border-r-2 py-2 px-2">ROLEDin ID</div> */}
                <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Father’s Name</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Fee</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Month</div>
                {/* <div className="w-1/6 border-r-2 py-2 px-2">Mode</div> */}
                <div className="w-1/6 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {data?.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : pendingApproval && pendingApproval.length > 0 ? (
                pendingApproval.map((x, idx) => (
                  <div className="w-full py-2">
                    <div
                      className={`items-center flex w-full relative py-2 px-3 text-xs font-medium ${
                        pendingApproval.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/6 px-2">{x?.studentId?.full_name}</div>
                      <div className="w-1/6 px-2">{x?.classId?.name + '-' + x?.sectionId?.name}</div>
                      <div className="w-1/6 px-3">{x?.fatherName}</div>
                      <div className={`w-1/6 px-3 text-textDark`}>&#x20b9; {x?.amount}</div>
                      <div className="w-1/6 px-3">{x?.month}</div>
                      {/* <div className="w-1/6 px-3">{x?.mode}</div> */}
                      <div className="w-1/6 px-2">
                        {x?.mode === 'Challan' && (
                          <button
                            className="w-full border border-tableRowText text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectFee(x);
                            }}
                          >
                            View Challan
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingFeeApproval;
