import React from 'react';

function Receipt({ onClose, receipt }) {
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12  rounded-2xl relative">
        <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">
          Reciept - {receipt?.name}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-6 right-6 h-3 cursor-pointer"
          alt=""
          onClick={onClose}
        />
        <div className="mt-2 mb-4 mx-6 ">
          <div className="mt-2 mb-4 relative font-semibold ">
            {receipt?.files.length > 0 && receipt?.files[0].link ? (
              <img src={receipt?.files[0].link} alt="" className="w-auto h-full m-auto max-h-96" />
            ) : (
              <div className="border h-32 flex justify-center items-center mt-2 rounded-2xl">No Attachment</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
