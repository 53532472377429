import React from 'react';
import moment from 'moment';

function ExpenseRequest({ selectRequestDetail, expensesRequests }) {
  return (
    <div className="w-full bg-white rounded-xl h-auto min-w-700">
      <div className="w-full bg-white rounded-xl h-auto my-4">
        <div className="items-center px-6 py-3 border-borderYellow border-bborder-borderYellow border-b">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold">
              <p>Expense Request</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full px-4 relative  text-xs font-medium">
                  <div className="px-2 w-2/12 border-r-2 py-2">Request Date</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Requested By</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Request Title</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Category</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Amount </div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Status</div>
                  <div className="px-2 w-2/12 py-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
                {
                  //   classSectionResponse.isLoading ? (
                  //     <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  Array.isArray(expensesRequests) && expensesRequests.length > 0 ? (
                    expensesRequests.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative px-4 py-2.5 text-xs font-medium ${
                            expensesRequests.length - 1 !== idx && 'border-borderYellow border-b'
                          }`}
                        >
                          <div className="px-2 w-2/12 text-tableRowText">{moment(x.date).format('DD MMM YYYY')}</div>
                          <div className="px-2 w-2/12 text-tableRowText py-2">{x?.user?.full_name}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{x?.name}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{x?.category?.category}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{'\u20B9' + ' ' + x?.amount}</div>
                          <div
                            className={`px-2 w-2/12 capitalize ${
                              x.status.toLowerCase() === 'pending'
                                ? 'text-black'
                                : x.status.toLowerCase() === 'declined'
                                ? 'text-textRed'
                                : 'text-textGreen'
                            }`}
                          >
                            {x?.status}
                          </div>
                          <div className={`px-2 w-2/12 flex items-center `}>
                            <button
                              className=" w-11/12 text-tableRowText border-borderGrey  border px-4 py-2 rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                selectRequestDetail(x);
                              }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseRequest;
