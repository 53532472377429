import React, { useState } from 'react';
import DropDown from '../../UI/DropDown';
import Search from '../../UI/Search';
import moment from 'moment';

function StaffRemoved({ toggleRemoveStaffPopup, setSearch, selectStaff, setSearchName, data, setSearchedData }) {
  const [sort, setSort] = useState('');

  const handleSearch = (q) => {
    if (data?.data) {
      let x = data.data.filter(
        (x) =>
          x.userId.full_name.toLowerCase().includes(q.toLowerCase()) ||
          x.userId.username.toLowerCase().includes(q.toLowerCase())
      );
      setSearchedData(x.map((x) => x.userId));
      setSearch(true);
      setSearchName(q);
    }
  };

  let filteredData = data.data || [];

  if (sort) {
    if (sort === 'Name - a to z') {
      filteredData = filteredData.sort((a, b) =>
        a.userId?.full_name.toLowerCase() > b.userId?.full_name.toLowerCase() ? 1 : -1
      );
    }
    if (sort === 'Name - z to a') {
      filteredData = filteredData.sort((a, b) =>
        a.userId?.full_name.toLowerCase() < b.userId?.full_name.toLowerCase() ? 1 : -1
      );
    }
    if (sort === 'Last Date - Recent') {
      filteredData = filteredData.sort((a, b) =>
        moment(a.lastWorkingdate).isBefore(moment(b.lastWorkingdate)) ? 1 : -1
      );
    }
    if (sort === 'Last Date - Oldest') {
      filteredData = filteredData.sort((a, b) =>
        moment(a.lastWorkingdate).isAfter(moment(b.lastWorkingdate)) ? 1 : -1
      );
    }
  }
  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold ">
            <p>Staff Removed</p>
          </div>
          <div className="flex">
            <button
              className="w-36 border py-2 rounded-3xl bg-primary text-xs  text-white mx-3 focus:outline-none flex justify-center text-center items-center"
              onClick={toggleRemoveStaffPopup}
            >
              Remove Staff
            </button>
            <DropDown
              data={['Name - a to z', 'Name - z to a', 'Last Date - Recent', 'Last Date - Oldest']}
              title={'Sort By'}
              width={'w-40'}
              margin={'mr-4'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
            <Search placeholder={'Search staff'} width={'w-44'} onChange={(x) => {}} onEnter={handleSearch} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full">
        <div className="w-full py-4 px-6 overflow-auto">
          <div className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative px-2 text-xs font-medium">
                <div className="w-2/12 border-r-2 p-2">Staff Type</div>
                <div className="w-3/12 border-r-2 p-2">ROLEDin ID</div>
                <div className="w-3/12 border-r-2 p-2">Name</div>
                <div className="w-3/12 border-r-2 p-2">Designation</div>
                <div className="w-3/12 border-r-2 p-2">Last Date</div>
                <div className="w-1/4 p-2">Action</div>
              </div>
            </div>
            <div
              className="customScrollBar"
              style={{
                overflowY: 'scroll',
                maxHeight: '35vh',
              }}
            >
              {data?.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : filteredData && Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-4 text-tableRowText">{x?.staffType}</div>
                      <div className="w-3/12 text-tableRowText px-2">{x?.userId?.username}</div>
                      <div className="w-3/12  px-2">{x?.userId?.full_name}</div>
                      <div className="w-3/12 text-tableRowText px-2">
                        {x?.userId?.jobProfile.length > 0 && x?.userId?.jobProfile[0]?.title}
                      </div>
                      <div className="w-3/12 text-tableRowText px-2">
                        {moment(x.lastWorkingdate).format('D MMMM YYYY') || '---'}
                      </div>
                      <div className="w-1/4 flex justify-center items-center px-2">
                        <button
                          className="w-4/5  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                          onClick={() => {
                            selectStaff(x?.userId);
                          }}
                        >
                          Show More
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffRemoved;
