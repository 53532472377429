import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MarkAttendance from '../../../components/Teacher/Attendance/MarkAttendance';
import Deatils from '../../../components/Teacher/Attendance/LeaveDetails';
import Report from '../../../components/Teacher/Attendance/Report';
import useApi from '../../../api/useApi';
import { TODAY_CLASS_ATTENDANCE, SECTION_LEAVES, SECTION_STUDENT_LEAVES } from '../../../api/urls/attendance';
import { useStateValue } from '../../../ContextApi/StateProvider';
import TodayAttendance from '../../../components/Teacher/Attendance/TodayAttendance';
import LeaveRequest from '../../../components/Teacher/Attendance/LeaveRequest';
import TotalClassAttendance from '../../../components/Teacher/Attendance/TotalClassAttendance';

const Attendance = () => {
  const [markAttendance, setMarkAttendance] = useState(false);
  const [details, setDetails] = useState(false);
  const [selectedStudent, selectStudent] = useState(false);

  const [{ user }, _] = useStateValue();

  const [todayAttendance, getTodayAttendance] = useApi();
  const [attendanceRes, getAttendance] = useApi();
  const [leaveseRes, getLeaves] = useApi();

  let additionalResponsibilty = user?.user?.currentlyMapped?.additionalResponsibilty;
  let info = [];
  if (additionalResponsibilty && additionalResponsibilty.length > 0) {
    info = additionalResponsibilty.filter((x) => x.category === 'Class Teacher');
    // if( info.length > 1 ){ info = [ info[info.length -1 ] ]  }
    
  }

  useEffect(() => {
    if (user) {
      if (info.length > 0) {
        getTodayAttendance(
          TODAY_CLASS_ATTENDANCE(
            info[0]?.classID?._id,
            info[0]?.sectionID?._id,
            moment(new Date()).format('YYYY-MM-DD')
          ),
          'GET'
        );

        getAttendance(SECTION_LEAVES(info[0]?.classID?._id, info[0]?.sectionID?._id), 'GET');

        getLeaves(SECTION_STUDENT_LEAVES(info[0]?.classID?._id, info[0]?.sectionID?._id), 'GET');
      } else {
        alert('No Class Assigned');
      }
    }
  }, [user]);

  const toggleReport = () => {
    selectStudent(null);
  };

  const refreshList = () => {
    if (info.length > 0) {
      getTodayAttendance(
        TODAY_CLASS_ATTENDANCE(info[0]?.classID?._id, info[0]?.sectionID?._id, moment(new Date()).format('YYYY-MM-DD')),
        'GET'
      );
      getAttendance(SECTION_LEAVES(info[0]?.classID?._id, info[0]?.sectionID?._id), 'GET');
    }
  };

  return (
    <>
      {markAttendance && (
        <MarkAttendance
          setMarkAttendance={setMarkAttendance}
          todayAttendance={todayAttendance}
          refreshList={refreshList}
          additionalResponsibilty={additionalResponsibilty}
        />
      )}
      {details && <Deatils details={details} onClose={() => setDetails(null)} />}
      {selectedStudent && <Report onClose={toggleReport} selectedStudent={selectedStudent} />}

      <div className="relative w-full md:w-4/5 pb-10">
        {/* Todays attendance */}
        <TodayAttendance info={info} setMarkAttendance={setMarkAttendance} todayAttendance={todayAttendance} />

        {/* Leave Request */}
        <LeaveRequest setDetails={setDetails} leaveseRes={leaveseRes} />

        {/* Total Attendance */}
        <TotalClassAttendance selectStudent={selectStudent} attendanceRes={attendanceRes} info={info} />
      </div>
    </>
  );
};

export default Attendance;
