import React, { useState, useEffect } from 'react';
import Result from './Result';
import ResultReport from './ResultReport';
import ResultReseaseDataTable from './ResultReseaseDataTable';
import { GET_RELEASE_DATES, CLASS_RESULTS, RESULT_APPROVAL } from '../../../api/urls/test';
import useApi from '../../../api/useApi';

function ReleaseResult() {
  const [viewResult, setViewResult] = useState(false);
  const [selectedClass, selectClass] = useState();

  const [releaseDatesRes, getReleaseDates] = useApi();
  const [resultRes, getResult] = useApi();
  const [approvalRes, approveResult] = useApi();

  useEffect(() => {
    getReleaseDates(GET_RELEASE_DATES, 'GET');
  }, []);

  useEffect(() => {
    if (selectedClass && viewResult)
      getResult(
        CLASS_RESULTS(selectedClass.y.classId._id, selectedClass.y.sectionId._id) + `&testType=${viewResult}`,
        'GET'
      );
  }, [selectedClass, viewResult]);

  useEffect(() => {
    if (!viewResult) {
      selectClass(null);
    }
  }, [viewResult]);

  const handleApproval = (id) => {
    approveResult(RESULT_APPROVAL(id), 'PUT');
  };

  useEffect(() => {
    if (!approvalRes.isLoading && !approvalRes.isError && approvalRes.status == 201) {
      getReleaseDates(GET_RELEASE_DATES, 'GET');
      alert('Success');
    }

    if (!approvalRes.isLoading && approvalRes.isError) {
      alert('Failed');
    }
  }, [approvalRes]);

  if (!releaseDatesRes.data) {
    return <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />;
  }

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {viewResult ? (
        resultRes.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
        ) : (
          <>
            <Result
              setViewResult={setViewResult}
              data={resultRes.data ? resultRes.data.tests : []}
              students={resultRes.data ? resultRes.data.totalStudent : 0}
              selectedClass={selectedClass}
              examType={viewResult}
            />
            <ResultReport
              data={resultRes.data ? resultRes.data.studentMarks : []}
              students={resultRes.data ? resultRes.data.totalStudent : 0}
              selectedClass={selectedClass}
            />
          </>
        )
      ) : (
        <>
          <ResultReseaseDataTable
            selectClass={selectClass}
            setViewResult={setViewResult}
            title={'Pre-Primary'}
            handleApproval={handleApproval}
            data={releaseDatesRes?.data['Pre Primary']}
          />
          <ResultReseaseDataTable
            selectClass={selectClass}
            setViewResult={setViewResult}
            title={'Primary'}
            handleApproval={handleApproval}
            data={releaseDatesRes?.data?.Primary}
          />
          <ResultReseaseDataTable
            selectClass={selectClass}
            setViewResult={setViewResult}
            title={'Middle'}
            handleApproval={handleApproval}
            data={releaseDatesRes?.data?.Middle}
          />
          <ResultReseaseDataTable
            selectClass={selectClass}
            setViewResult={setViewResult}
            title={'Secondary'}
            handleApproval={handleApproval}
            data={releaseDatesRes?.data?.Secondary}
          />
          <ResultReseaseDataTable
            selectClass={selectClass}
            setViewResult={setViewResult}
            title={'Senior-Secondary'}
            handleApproval={handleApproval}
            data={releaseDatesRes?.data['Senior-Secondary']}
          />
        </>
      )}
    </div>
  );
}

export default ReleaseResult;
