import React, { useState } from 'react';
import DropDown from '../../UI/DropDown';
import Search from '../../UI/Search';

function ClassesSections({ toggleOpenClassSection, classSections }) {
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState('');

  let _classes = classSections || [];
  if (filter && filter !== 'All') {
    _classes = classSections.filter((x) => {
      if (filter === 'Pre - Primary') {
        return x.class.toLowerCase().includes('kg') || x.class.toLowerCase().includes('nursery');
      } else if (filter === 'Senior Secondary') {
        return x.class.toLowerCase().includes('11') || x.class.toLowerCase().includes('12');
      } else if (filter === 'Secondary') {
        return x.class.toLowerCase().includes('9') || x.class.toLowerCase().includes('10');
      } else if (filter === 'Middle') {
        return (
          x.class.toLowerCase().includes('6') ||
          x.class.toLowerCase().includes('7') ||
          x.class.toLowerCase().includes('8')
        );
      } else {
        return (
          (x.class.toLowerCase().includes('1') &&
            !x.class.toLowerCase().includes('11') &&
            !x.class.toLowerCase().includes('12')) ||
          (x.class.toLowerCase().includes('2') && !x.class.toLowerCase().includes('12')) ||
          x.class.toLowerCase().includes('3') ||
          x.class.toLowerCase().includes('4') ||
          x.class.toLowerCase().includes('5')
        );
      }
    });
  }

  if (search) {
    _classes = _classes.filter((x) => x?.class.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>Classes and Sections</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <button
              className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
              onClick={() => toggleOpenClassSection()}
            >
              New Class/Section
            </button>

            <Search
              placeholder={'Search'}
              width={'w-1/4'}
              onChange={(x) => setSearch(x)}
              onEnter={() => console.log('enter')}
            />

            <DropDown
              data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior Secondary']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setFilter(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Section</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Total No of Students</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Class Teacher</div>
                <div className="w-1/5 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {Array.isArray(_classes) && _classes.length > 0 ? (
                _classes.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                          idx !== _classes.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-textDark ">{x.class}</div>
                        <div className="w-1/5 px-2 text-textDark">{x.section}</div>
                        <div className={`w-1/5 px-2 text-tableRowText`}>{x.totalStudent}</div>
                        <div className={`w-1/5 px-2 text-textDark`}>{x.headTeacher || 'Not Assigned'}</div>{' '}
                        <div className={`w-1/5 px-2 `}>
                          {' '}
                          <button
                            className="w-11/12 border  py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => {
                              toggleOpenClassSection();
                            }}
                          >
                            View Class
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassesSections;
