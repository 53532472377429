import React, { useRef } from 'react';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useStateValue } from '../../../ContextApi/StateProvider';
import { useReactToPrint } from 'react-to-print';

const Receipt = ({ printContent, onClose, showPrint = true }) => {
  const [{ user }, _] = useStateValue();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    const input = document.getElementById('section-to-print2');
    // Specify the id of the element you want to convert to PDF
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('fee-receipt.pdf');
      // Specify the name of the downloaded PDF file
    });
  };

  // const handlePrint = () => {
  // window.print();
  // printReceipt();
  // const input = document.getElementById('section-to-print2');
  // // Specify the id of the element you want to convert to PDF
  // html2canvas(input).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF();
  //   pdf.addImage(imgData, 'PNG', 0, 0);
  //   const pdfWindow = window.open();
  //   pdfWindow.document.write(
  //     '<html><head><title>Print</title></head><body><embed width="100%" height="100%" name="plugin" src="' +
  //       pdf.output('datauristring') +
  //       '" type="application/pdf" /></body></html>'
  //   );
  //   pdfWindow.document.close();
  //   pdfWindow.print();
  // });
  // };

  return (
    <div
      className="flex justify-center pb-6 h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="relative w-11/12 md:w-2/5 pb-4 m-auto  mt-6 md:mt-8 bg-white rounded-2xl">
        <div className="flex w-full justify-between items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 py-4 text-textBlue1">Fee Receipt</p>
          <img
            src="assets/img/Close.svg"
            className="absolute right-4 h-3 cursor-pointer z-50"
            alt=""
            onClick={onClose}
          />
        </div>

        <div className="flex flex-col" id="section-to-print2" ref={componentRef}>
          {/* <div className="flex px-6 mt-6 justify-end">
            <img src="assets/img/roledin_logo.svg" className="w-16 h-auto cursor-pointer" alt="" />
          </div> */}
          <div className="my-6">
            <p className="font-bold text-center">{user?.institute?.name}</p>
            <p className="font-bold text-center">Fee Receipt</p>
            <p className="font-bold text-center">{`Month - ${printContent?.fee?.month} ${new Date().getFullYear()}`}</p>
          </div>

          <div className="receipt-items mt-2 gap-x-4">
            <div className="flex flex-col mb-3 ">
              <span className="text-sm">Receipt No:</span>
              <p className="font-medium">{printContent?.fee?.receiptNo}</p>
            </div>
            <div className="flex flex-col mb-3">
              <span className="text-sm">Student Name:</span>
              <p className="font-medium">{`${printContent?.student?.full_name} (${printContent?.student?.username})`}</p>
            </div>
            <div className="flex flex-col mb-3">
              <span className="text-sm">Class:</span>
              <span className="font-medium">{`${printContent?.class?.name} ${printContent?.section?.name || ''}`}</span>
            </div>

            {printContent?.fee?.miscFee?.length > 0 && (
              <div className="flex flex-col mb-3">
                <span className="text-sm">Miscellaneous Fee:</span>
                <span className="font-medium">
                  {printContent?.fee?.miscFee.map((x) => `${x.name}${x?.amount ? '- Rs.' + x.amount : ''} `).join(', ')}
                </span>
              </div>
            )}

            {!!printContent?.fee?.prevBalance && (
              <div className="flex flex-col mb-3">
                <span className="text-sm">{+printContent?.fee?.prevBalance < 0 ? 'Advance' : 'Previous Balance:'}</span>
                <span className="font-medium">{'Rs. ' + Math.abs(+printContent?.fee?.prevBalance)}</span>
              </div>
            )}

            {!!printContent?.fee?.monthlyFee && (
              <div className="flex flex-col mb-3">
                <span className="text-sm">{`${printContent?.fee?.month} month Fee`}</span>
                <span className="font-medium">{'Rs. ' + printContent?.fee?.monthlyFee}</span>
              </div>
            )}

            <div className="flex flex-col mb-3">
              <span className="text-sm">Total Amount:</span>
              <span className="font-medium">{'Rs. ' + printContent?.fee?.totalAmount}</span>
            </div>

            {(printContent?.fee?.waiveoff || printContent?.waiveoff) && (
              <div className="flex flex-col mb-3">
                <span className="text-sm">Waived off amount:</span>
                <span className="font-medium">
                  {'Rs. ' + printContent?.fee?.waiveoff || printContent?.waiveoff || 0}
                </span>
              </div>
            )}
            <div className="flex flex-col mb-3">
              <span className="text-sm">Amount Paid:</span>
              <span className="font-medium">{'Rs. ' + printContent?.fee?.amount}</span>
            </div>
            <div className="flex flex-col mb-3">
              <span className="text-sm">
                {(printContent?.fee?.balance && +printContent?.fee?.balance < 0) ||
                (printContent?.balance && +printContent?.balance < 0)
                  ? 'Advance:'
                  : 'Balance:'}
              </span>
              <span className="font-medium">
                {'Rs. ' + Math.abs(printContent?.fee?.balance || printContent?.balance || 0)}
              </span>
            </div>
            <div className="flex flex-col mb-3">
              <span className="text-sm">Submission Date:</span>
              <span className="font-medium">
                {printContent?.fee?.date ? moment(printContent?.fee?.date).format('ll') : ''}
              </span>
            </div>
            {(printContent?.fee?.paidBy?.full_name || user?.user?.full_name) && (
              <div className="flex flex-col mb-3">
                <span className="text-sm">Received By:</span>
                <span className="font-medium">{printContent?.fee?.paidBy?.full_name || user?.user?.full_name}</span>
              </div>
            )}
          </div>
        </div>

        <div className="mt-6 mr-6">
          {showPrint && (
            <button
              className="border border-primary text-white bg-primary px-6 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
              onClick={handlePrint}
            >
              Print
            </button>
          )}

          <button
            className="border border-primary text-white bg-primary px-6 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
            onClick={handleDownload}
          >
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
