import React, { useState, useEffect } from 'react';
import Search from '../../UI/Search';
import moment from 'moment';

export default function StaffOnLeave({ setSearch, setShowList, setSearchName, staffLeaves, setSearchedData }) {
  const [teachingStaff, setTeachingStaff] = useState([]);
  const [nonTeachingStaff, setNonTeachingStaff] = useState([]);

  useEffect(() => {
    let z = [];
    let n = [];
    if (staffLeaves) {
      z = staffLeaves.filter(
        (x) => x.userDetail?.currentlyMapped.role.includes('te') || x.userDetail?.currentlyMapped.role.includes('pr')
      );
      n = staffLeaves.filter(
        (x) => !(x.userDetail?.currentlyMapped.role.includes('te') || x.userDetail?.currentlyMapped.role.includes('pr'))
      );
    }
    setTeachingStaff(z);
    setNonTeachingStaff(n);
  }, [staffLeaves]);

  const handleSearch = (q) => {
    if (staffLeaves?.leaves) {
      let x = staffLeaves?.leaves.filter(
        (x) =>
          x?.userDetail?.full_name.toLowerCase().includes(q.toLowerCase()) ||
          x?.userDetail?.username.toLowerCase().includes(q.toLowerCase())
      );
      setSearchedData(x);
      setSearch(true);
      setSearchName(q);
    }
  };

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="flex items-center px-5 pt-4 pb-4 relative border-b border-borderYellow justify-between">
        <p className="font-bold">Staff on Leave</p>
        <Search placeholder={'Search staff on leave'} width={'w-44'} onChange={(q) => {}} onEnter={handleSearch} />
        {/* <div className="w-36 border absolute right-6 px-3 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex">
          <img src="assets/img/Search.svg" className="w-4 mr-2 " alt="" />
          <input
            type="text"
            placeholder="Search staff on leave"
            autoComplete="off"
            // value="Search staff on leave"
            className="text-left w-full bg-transparent text-tableRowText focus:outline-none"
            onChange={(x) => {
              setSearchName(x.target.value);
            }}
            onKeyDown={(x) => {
              if (x.which === 13) {
                setSearch((prev) => !prev);
              }
            }}
          />
        </div> */}
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 mx-6 pb-6 pt-4">
        <div className="w-full h-80  rounded-xl relative mt-2 border border-borderYellow">
          <div className="h-5/6 px-2 customScrollBar overflow-auto">
            {teachingStaff.length > 0 ? (
              teachingStaff.map((x) => (
                <div className="pt-4 w-full flex text-left px-2 items-center">
                  <div className="w-1/12">
                    {x?.userDetail?.fileArray.length > 0 &&
                    x?.userDetail?.fileArray[0].profileImg &&
                    x?.userDetail?.fileArray[0].profileImg.location ? (
                      <img
                        className="schoolLogo"
                        style={{
                          width: '2em',
                          height: '2em',
                        }}
                        src={x?.userDetail?.fileArray[0].profileImg.location}
                        alt=""
                      />
                    ) : (
                      <img
                        className="schoolLogo"
                        style={{
                          width: '2em',
                          height: '2em',
                        }}
                        src={'/assets/img/user_placeholder.svg'}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="w-2/3 text-sm font-medium pl-3">
                    <p>
                      {x?.userDetail?.full_name} <span>{' (' + x?.userDetail?.username + ')'}</span>
                    </p>
                    <p className="font-normal text-gray-400 text-xs">{`${moment(x?.date).format('DD-MM-YYYY')}`}</p>
                  </div>
                  <div className="w-1/6 ml-auto text-tableRowText text-xs flex text-right items-center">
                    {'(Today)'}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-tableRowText text-2xl font-light leading-6 mt-28 text-center">No Data Available</p>
            )}
          </div>

          <div className="h-1/6 w-full flex items-center justify-between border-t border-borderYellow px-2">
            <span className="ml-3 text-tableRowText font-medium"> Teaching Staff</span>
            <button
              className="w-28  mr-3 text-center text-xs py-2 rounded-3xl text-tableRowText border border-tableRowText"
              onClick={() => {
                setSearchedData(teachingStaff);
                setShowList((prev) => !prev);
              }}
            >
              View More
            </button>
          </div>
        </div>

        <div className="w-full h-80  rounded-xl relative mt-2 border border-borderYellow">
          <div className="h-5/6 px-2 customScrollBar overflow-auto">
            {nonTeachingStaff.length > 0 ? (
              nonTeachingStaff.map((x) => (
                <div className="pt-4 w-full flex text-left px-2 items-center">
                  <div className="w-1/12">
                    {x?.userDetail?.fileArray.length > 0 &&
                    x?.userDetail?.fileArray[0].profileImg &&
                    x?.userDetail?.fileArray[0].profileImg.location ? (
                      <img
                        className="schoolLogo"
                        style={{
                          width: '2em',
                          height: '2em',
                        }}
                        src={x?.userDetail?.fileArray[0].profileImg.location}
                        alt=""
                      />
                    ) : (
                      <img
                        className="schoolLogo"
                        style={{
                          width: '2em',
                          height: '2em',
                        }}
                        src={'/assets/img/user_placeholder.svg'}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="w-2/3 text-sm font-medium pl-3">
                    <p>
                      {x?.userDetail?.full_name} <span>{' (' + x?.userDetail?.username + ')'}</span>
                    </p>
                    <p className="font-normal text-gray-400 text-xs">{`${moment(x?.date).format(
                      'DD-MM-YYYY'
                    )} to ${moment(x?.endDate).format('DD-MM-YYYY')}`}</p>
                  </div>
                  <div className="w-1/6 ml-auto text-tableRowText text-xs flex text-right items-center">
                    {x?.days > 0 ? `(${x?.days} Days)` : '(Today)'}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-tableRowText text-2xl font-light leading-6 mt-28 text-center">No Data Available</p>
            )}
          </div>

          <div className="h-1/6 w-full flex items-center justify-between border-t border-borderYellow px-2">
            <span className="ml-3 text-tableRowText font-medium"> Non-Teaching Staff</span>
            <button
              onClick={() => {
                setSearchedData(nonTeachingStaff);
                setShowList((prev) => !prev);
              }}
              className="w-28  mr-3 text-center text-xs py-2 rounded-3xl text-tableRowText border border-tableRowText"
            >
              {' '}
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
