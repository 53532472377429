import React from "react";
import {
  Component1,
  NotificationSettings,
  Line15,
  FlexWrapperOne,
  Sound,
  Toggle,
  Vector,
  Line20,
  FlexWrapperTwo,
  DesktopAlert,
  Line16,
  TurnOffAllDesktopNotification,
  ProfileWrapper
} from "./style";

const NotificationComponent = () => {
  return (
    <ProfileWrapper>
    <Component1>
      <NotificationSettings>
        Notification Settings
      </NotificationSettings>
      <Line15 />
      <FlexWrapperOne>
        <Sound>Sound</Sound>
        <Toggle>
          <Vector
            alt=""
            src="https://static.overlay-tech.com/assets/c448a6f3-eed8-4eef-8cc6-2fdcc89cb455.svg"
          />
        </Toggle>
      </FlexWrapperOne>
      <Line20
        alt=""
        src="https://static.overlay-tech.com/assets/f9ea7bac-9d34-4c6c-b2ef-dbf5ff319ada.svg"
      />
      <FlexWrapperTwo>
        <DesktopAlert>Desktop Alert</DesktopAlert>
        <Toggle>
          <Vector
            alt=""
            src="https://static.overlay-tech.com/assets/24cb6f8f-81cc-48c3-86ba-463e7c607632.svg"
          />
        </Toggle>
      </FlexWrapperTwo>
      <Line16
        alt=""
        src="https://static.overlay-tech.com/assets/da8f8503-38b7-47fc-9758-8d0fbbcfde23.svg"
      />
      <FlexWrapperTwo>
        <TurnOffAllDesktopNotification>
          Turn off all desktop notification
        </TurnOffAllDesktopNotification>
        <Toggle>
          <Vector
            alt=""
            src="https://static.overlay-tech.com/assets/b2b9a5d6-9903-4c4a-a405-fca21a287812.svg"
          />
        </Toggle>
      </FlexWrapperTwo>
    </Component1>
    </ProfileWrapper>
  );
};

export default NotificationComponent;