import React, { useState, useEffect } from 'react';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import moment from 'moment';

const excelHeaders = [
  { label: 'Roll No', key: 'rollNo' },
  { label: 'Student Name', key: 'name' },
  { label: 'Leave From', key: 'from' },
  { label: 'Leave To', key: 'to' },
];

function LeaveRequest({ leaveseRes, setDetails }) {
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (!leaveseRes.isLoading && !leaveseRes.isError && leaveseRes.data && Array.isArray(leaveseRes.data)) {
      const _data = leaveseRes?.data;
      let x = _data.map((x) => {
        return {
          rollNo: x.student_id?.currentlyMapped?.rollNumber || '--',
          name: x.student_id?.full_name,
          from: moment(x.start_date).format('ll'),
          to: moment(x.end_date).format('ll'),
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [leaveseRes]);

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-6/12">
            <p>Leave Request</p>
          </div>
          <div className="flex justify-end items-center w-full">
            <ExcelExportBtn
              data={excelData}
              headers={excelHeaders}
              text={'Export Data'}
              fileName={'leaveRequests.csv'}
              width={'w-40'}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/4 border-r-2 py-2 px-2">Roll Number</div>
                <div className="w-1/4 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-1/4 border-r-2 py-2 px-2">Leaves</div>
                {/* <div className="w-3/12 border-r-2 py-2 px-2">Leave To</div> */}
                <div className="w-1/4 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {leaveseRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-8" alt="" />
              ) : Array.isArray(leaveseRes.data) && leaveseRes.data.length > 0 ? (
                leaveseRes.data?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        idx !== leaveseRes.data.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/4 px-2 text-textDark">
                        {x.studentInfo?.currentlyMapped?.rollNumber || '--'}
                      </div>
                      <div className="w-1/4 px-2 text-textDark">{x.studentInfo?.full_name}</div>
                      <div className="w-1/4 px-2 text-textDark">{x.leaveInfo?.length}</div>
                      {/* <div className="w-3/12 px-2 text-tableRowText">{moment(x.start_date).format('ll')}</div> */}
                      {/* <div className="w-3/12 px-2 text-tableRowText">{moment(x.end_date).format('ll')}</div> */}
                      <div className="w-1/4 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                          onClick={() => setDetails(x)}
                        >
                          View Detail
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveRequest;
