import styled from 'styled-components';
import fontFamily from '../../fontFamily';

export const FeedUpdates = styled.div`
  background-color: ${fontFamily.colors.white};
  border-radius: 20px;
  padding: 11px 0 0px;
  box-shadow: 0 0 3px 0 ${fontFamily.colors.transparentRed};
  display: flex;
  margin-bottom: 0.5em;
  flex-direction: column;
  align-items: flex-start;
  height: 160px;
  // position: absolute;
  //   width: 334px;
  //   height: 380px;
  //   left: 1164px;
  //   top: 635px;
`;
export const UpdatesTabLabel = styled.p`
  // width: 21.86%;
  // height: 10.57%;
  color: ${fontFamily.colors.black4};
  margin-bottom: 5.5px;
  margin-left: 19px;
  font-family: ${fontFamily.fonts.lato20Regular.family};
  font-size: 17px;
  font-weight: ${fontFamily.fonts.lato20Regular.weight};
  line-height: ${fontFamily.fonts.lato20Regular.lineHeight};
`;
export const Divider = styled.img`
  align-self: stretch;
  margin-bottom: 0.5px;
  object-fit: cover;
`;
export const FlexWrapperOne = styled.div`
  margin-bottom: 17px;
  padding: 0 0 0 19px;
  display: flex;
  align-items: flex-start;
`;
export const Group145 = styled.div`
  margin-top: 7px;
  margin-right: 19px;
  display: flex;
  align-items: flex-start;
`;
export const Rectangle150 = styled.img`
  width: 15.92%;
  align-self: stretch;
  margin-right: 14px;
  object-fit: cover;
`;
export const RelativeWrapperOne = styled.div`
  width: 79.24%;
  height: 69.57%;
  position: relative;
`;
export const PostUpdateTime = styled.p`
  // width: 36.24%;
  height: 43.75%;
  color: ${fontFamily.colors.green};
  position: absolute;
  left: 0;
  bottom: -13px;
  font-family: ${fontFamily.fonts.roboto12Regular.family};
  font-size: ${fontFamily.fonts.roboto12Regular.size};
  font-weight: ${fontFamily.fonts.roboto12Regular.weight};
  line-height: ${fontFamily.fonts.roboto12Regular.lineHeight};
`;
export const PostUpdateHyperlink = styled.p`
  width: 100%;
  height: 100%;
  position: relative;
  color: ${fontFamily.colors.transparentBlack3};
  font-family: ${fontFamily.fonts.roboto14Regular.family};
  font-size: ${fontFamily.fonts.roboto14Regular.size};
  font-weight: ${fontFamily.fonts.roboto14Regular.weight};
  line-height: ${fontFamily.fonts.roboto14Regular.lineHeight};
`;
export const PostUpdateHyperlinkEmphasis0 = styled.strong`
  color: ${fontFamily.colors.transparentBlack3};
  font-family: ${fontFamily.fonts.roboto14Medium.family};
  font-size: ${fontFamily.fonts.roboto14Medium.size};
  font-weight: ${fontFamily.fonts.roboto14Medium.weight};
  line-height: ${fontFamily.fonts.roboto14Medium.lineHeight};
`;
export const Scroll = styled.div`
  width: 2.36%;
  height: 84.62%;
  background-color: ${fontFamily.colors.transparentGold};
`;
export const Group146 = styled.div`
  margin-left: 19px;
  display: flex;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin-bottom: 17px;
  }
`;
