import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { TEST_MARKS, UPLOAD_ANSWER_SHEET } from '../../../api/urls/test';

function SaveMarks({ handleClose, id }) {
  const [marks, getTestMArks] = useApi();
  const [uploadState, uploadTestMArks] = useApi();
  const [answerSheetRes, uploadAnswerSheet] = useApi();

  const [data, setData] = useState([]);
  const [index, setId] = useState();

  const examSheet = useRef();

  useEffect(() => {
    window.scroll(0, 0);
    getTestMArks(TEST_MARKS(id._id), 'GET');
  }, []);

  useEffect(() => {
    if (!marks.isLoading && !marks.isError && marks.data) {
      setData(marks.data.result);
    }
  }, [marks]);

  const handleMarksChange = (e, studentId) => {
    let studentIndex = data.findIndex((x) => x._id === studentId);
    let y = data[studentIndex];
    let x = {
      ...y,
      marks: e.target.value,
    };
    data[studentIndex] = x;
    setData([...data]);
  };

  const handleSubmit = () => {
    const marksBody = data.map((x) => {
      return {
        id: x._id,
        marks: x.marks || 0,
        result_id: x.result_id,
      };
    });

    if (marks.data.isMarked) uploadTestMArks(TEST_MARKS(id._id), 'PUT', marksBody);
    else uploadTestMArks(TEST_MARKS(id._id), 'POST', marksBody);
  };

  const handleUploadAnswerSheet = (e) => {
    const formData = new FormData();
    formData.append('answerSheet', e.target.files[0]);

    uploadAnswerSheet(UPLOAD_ANSWER_SHEET(id._id, index), 'POST', formData);
  };

  useEffect(() => {
    if (!answerSheetRes.isLoading && !answerSheetRes.isError && answerSheetRes.status === 201) {
      getTestMArks(TEST_MARKS(id._id), 'GET');
      alert('AnswerSheet uploaded successfully!');
      setId(null);
      examSheet.current.value = '';
    }

    if (!answerSheetRes.isLoading && answerSheetRes.isError) {
      alert('AnswerSheet uploaded failed!');
      setId(null);
      examSheet.current.value = '';
    }
  }, [answerSheetRes]);

  useEffect(() => {
    if (!uploadState.isLoading && !uploadState.isError && uploadState.status) {
      if ([200, 201, 202].includes(uploadState.status)) {
        handleClose(false);
        alert('Success');
      }
    }

    if (!uploadState.isLoading && uploadState.isError) {
      alert(uploadState?.data || 'Failed');
    }
  }, [uploadState.isLoading, uploadState.isError, uploadState.data]);

  return (
    <div
      className="flex justify-center items-center h-auto min-h-full w-full absolute left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <input hidden ref={examSheet} type="file" onChange={(e) => handleUploadAnswerSheet(e)} />
      <div className="bg-white w-11/12 md:w-2/3 text-sm block m-auto mt-10 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">{id?.title}</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
        </div>

        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto overflow-hidden border rounded-2xl border-borderYellow min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/4 border-r-2 py-2 px-2">Roll Number</div>
                  <div className="w-1/4 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-1/4 py-2 px-2">Marks Obtained</div>
                  <div className="w-1/4 py-2 px-2">Answer Sheet</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                {marks.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : (
                  Array.isArray(data) &&
                  data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs items-center ${
                          idx !== marks.data.result.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/4 px-2 py-2 text-textDark">{x?.rollNo || '--'}</div>
                        <div className="w-1/4 px-2 text-textDark">{x.name}</div>
                        <div className="w-1/4 px-2">
                          <input
                            type={'number'}
                            placeholder="00"
                            value={x.marks}
                            className="w-1/4 text-center border border-borderLight px-2 py-2 rounded-3xl text-textDark text-xs focus:outline-none "
                            onChange={(e) => handleMarksChange(e, x._id)}
                          />
                        </div>
                        <div className="w-1/4 px-2">
                          {x.answerSheet ? (
                            <a
                              className="py-2 w-36 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                              download
                              href={x?.answerSheet?.location}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          ) : uploadState.isLoading ? (
                            <img src="assets/img/loader.svg" className="w-14 mx-auto" alt="" />
                          ) : (
                            <button
                              className="py-2 w-36 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                examSheet.current.click();
                                setId(x._id);
                              }}
                            >
                              Upload
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            {uploadState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
            ) : (
              <button
                className="w-40 mt-6 ml-auto mr-6 border border-primary px-6 py-2 rounded-3xl text-white bg-primary  text-xs focus:outline-none flex justify-center text-center items-center"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveMarks;
