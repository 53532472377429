import React, { useState, useEffect } from 'react';
import MapStudent from '../../../components/Admin/StudentManagement/MapStudent';
import Students from '../../../components/Admin/StudentManagement/Students';
import AdmissionWithdrawl from '../../../components/Admin/StudentManagement/AdmissionWithdrawl';
import AddStudent from '../../../components/Admin/StudentManagement/AddStudent';

import useApi from '../../../api/useApi';
import { GET_STUDENT, GET_WITHDRAWAL_STUDENT } from '../../../api/urls/user';
import { GET_UNMAPPED_STUDENTS } from '../../../api/urls/instituteManagement';

function StudentManagement() {
  const [selectedStudent, selectStudent] = useState();
  const [action, selectAction] = useState();
  const [openMapStudent, setOpenMapStudent] = useState(false);
  const [openWithdrawl, setOpenWithdrawl] = useState(false);
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [unMappedStudents, setUnMappedStudents] = useState([]);

  const [{ isLoading, isError, data }, getStudents] = useApi();
  const [unMappedStudentState, getUnMappedStudents] = useApi();
  const [withdrawalRes, getWithdrawalStudents] = useApi();

  useEffect(() => {
    getStudents(GET_STUDENT, 'GET');
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
    getWithdrawalStudents(GET_WITHDRAWAL_STUDENT, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setStudents(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!unMappedStudentState.isLoading && !unMappedStudentState.isError && unMappedStudentState.data) {
      setUnMappedStudents(unMappedStudentState.data.students);
    }
  }, [unMappedStudentState]);

  const refreshList = () => {
    getStudents(GET_STUDENT, 'GET');
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
    getWithdrawalStudents(GET_WITHDRAWAL_STUDENT, 'GET');
  };

  const toggleMapStudent = () => {
    setOpenMapStudent((prev) => !prev);
  };

  const selectStudentToMap = (x) => {
    selectStudent(x);
    toggleMapStudent();
  };

  const toggleWithdrawl = () => {
    setOpenWithdrawl((prev) => !prev);
  };

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const openDetailsView = (x, y) => {
    selectAction(y);
    selectStudent(x);
    toggleView();
  };

  return (
    <div className="w-full md:w-4/5 pb-10">
      {openMapStudent && (
        <MapStudent toggleMapStudent={toggleMapStudent} selectedStudent={selectedStudent} refreshList={refreshList} />
      )}
      {openWithdrawl && (
        <AdmissionWithdrawl
          toggleWithdrawl={toggleWithdrawl}
          selectedStudent={selectedStudent}
          refreshList={refreshList}
        />
      )}
      {open && (
        <AddStudent
          toggleView={toggleView}
          selectedStudent={selectedStudent}
          refreshList={refreshList}
          action={action}
        />
      )}

      <Students
        title={'New Students'}
        selectStudent={selectStudentToMap}
        openDetails={openDetailsView}
        data={unMappedStudents}
        isLoading={unMappedStudentState.isLoading}
      />
      <Students title={'Student Detail'} openDetails={openDetailsView} data={students} isLoading={isLoading} />
      <Students
        title={'Admission withdrawal'}
        toggleWithdrawl={toggleWithdrawl}
        data={withdrawalRes?.data || []}
        isLoading={withdrawalRes?.isLoading}
        openDetails={openDetailsView}
      />
    </div>
  );
}

export default StudentManagement;
