import React, { useState } from 'react';
import { Rectangle78 } from './style';

function ImageCarousel({ images }) {
  const [current, setCurrent] = useState(0);

  const handleNext = (len) => {
    if (current + 1 <= len - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };

  const handlePrevious = (len) => {
    if (current - 1 >= 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(len - 1);
    }
  };

  return (
    <div className="flex relative" style={{ height: 430 }}>
      {images.length > 1 && (
        <div
          className="h-full w-10 hover:bg-primaryLight  flex justify-center items-center"
          onClick={() => handlePrevious(images.length)}
        >
          <img src={'/assets/img/previous.svg'} alt="left" />
        </div>
      )}
      {images.map((z, idx) => {
        const left = (idx - current) * 100;
        return (
          <div className="w-full h-full absolute transition-all" style={{ left: `${left}%` }}>
            <Rectangle78 key={z.name} alt={z.name} src={z.location} />
          </div>
        );
      })}
      {images.length > 1 && (
        <div
          className="h-full w-10 hover:bg-primaryLight  absolute right-0 flex justify-center items-center"
          onClick={() => handleNext(images.length)}
        >
          <img src={'/assets/img/next.svg'} alt="right" />
        </div>
      )}
      <div className="flex gap-x-1 justify-center items-center absolute left-0 right-0 bottom-0">
        {images.map((z, idx) => {
          return (
            <div
              className={`w-1.5 h-1.5 rounded-full ${
                idx === current ? 'bg-primary' : 'bg-borderLight'
              } z-30 transition-all`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default ImageCarousel;
