import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import DropDown from '../../UI/DropDown';

const excelHeaders = [
  { label: 'Expense Date', key: 'date' },
  { label: 'Title', key: 'title' },
  { label: 'Category', key: 'category' },
  { label: 'Amount', key: 'amount' },
];

function ExpenseList({
  expenses,
  isLoading,
  setFilter,
  openReceipt,
  setOpenSubmitJustification,
  handleDelete,
  openEdit,
  categories,
}) {
  const [excelData, setExcelData] = useState([]);
  const [sort, setSort] = useState('All Categories');

  useEffect(() => {
    if (expenses) {
      if (expenses) {
        let _excel = expenses.map((x) => {
          return {
            date: x.date ? moment(x.date).format('LL') : '',
            category: x?.category?.category || '',
            amount: '\u20B9' + ' ' + (x?.amount || 0),
            title: x?.name || '',
          };
        });

        if (Array.isArray(_excel)) setExcelData(_excel);
      }
    }
  }, [expenses]);

  let filteredData = expenses || [];

  if (sort === 'All Categories') {
    filteredData = expenses;
  } else {
    filteredData = expenses.filter((x) => x?.category?.category === sort);
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold">
            <p>Ledger</p>
          </div>
          <div className="flex justify-end w-7/12 gap-x-4">
            <ExcelExportBtn
              width={'w-36'}
              data={excelData}
              headers={excelHeaders}
              fileName={'Ledger.csv'}
              text={'Export Data'}
            />

            <button
              className="border w-36 py-2 text-xs rounded-3xl text-textLight border-tableRowText font-medium focus:outline-none flex justify-center text-center items-center"
              onClick={() => {
                setFilter(true);
              }}
            >
              Filter
            </button>

            <DropDown
              handleItemClick={(x) => {
                setSort(x);
              }}
              title={'Sort by'}
              data={['All Categories', ...categories.map((x) => x?.category)]}
              width={'w-40'}
              // otherClasses={'border-borderLight'}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl overflow-hidden min-w-700"
            style={{ borderColor: '#E7E700', width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl -mt-0.5">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-2/12 border-r-2 py-2 px-2">Expense Date</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Added By</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Category</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Amount</div>
                <div className="px-2 w-2/12 border-r-2 py-2">Justification</div>
                <div className="w-2/12 py-2 border-r-2 px-2">View Reciept</div>
                <div className="w-2/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                Array.isArray(filteredData) &&
                filteredData.map((x, idx) => (
                  <div key={x._id} className="w-full">
                    <div
                      className={`items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                        idx !== filteredData.length - 1 && 'border-b border-tableHeader'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-tableRowText">
                        {x.date && moment(x.date).format('DD MMM YYYY')}
                      </div>
                      <div className="w-2/12 px-2 text-tableRowText">{x?.user?.full_name || '--'}</div>
                      <div className="w-2/12 px-2 text-tableRowText">{x?.category?.category}</div>
                      <div className="w-2/12 px-2 text-tableRowText">{'\u20B9' + ' ' + x?.amount}</div>
                      <div className="w-2/12 flex pl-2 items-center">
                        {x.askJustication && (
                          <button
                            className={`w-9/12 ${
                              x.justification
                                ? 'text-primary border-primary bg-white opacity-60 cursor-not-allowed'
                                : 'text-white border-primary bg-primary'
                            } border py-2 rounded-3xl text-xs focus:outline-none`}
                            onClick={() => {
                              setOpenSubmitJustification(x);
                            }}
                            disabled={x?.justification}
                          >
                            {x.justification ? 'Submitted' : 'Submit'}
                          </button>
                        )}
                      </div>
                      <div className="w-2/12 flex pl-2 items-center">
                        <button
                          className=" w-9/12 text-tableRowText border-borderGrey border px-4 py-2 rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            openReceipt(x);
                          }}
                        >
                          View
                        </button>
                      </div>
                      <div className="w-2/12 flex pl-2 items-center gap-x-4">
                        <button
                          onClick={() => {
                            openEdit(x);
                          }}
                        >
                          <img src="assets/img/edit.svg" alt="edit" />
                        </button>
                        <button
                          onClick={() => {
                            handleDelete(x._id);
                          }}
                        >
                          <img src="assets/img/delete.svg" alt="delete" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseList;
