import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/UI/DropDown';

import useApi from '../../../api/useApi';
import { MAP_RESPONSIBILITY, EDIT_ADDITIONAL_RESPONSIBILITY } from '../../../api/urls/institute';

import { useStateValue } from '../../../ContextApi/StateProvider';

function MapResponsibility({ selectedUser, selectUser, refreshData, isView, setViewUser, getRole }) {
  const [selectedResponsibility, selectResponsibility] = useState('');
  const [sections, setSections] = useState([]);
  const [{ classSections }, dispatch] = useStateValue();
  console.log(selectedUser);
  const formDataModel = {
    classId: null,
    sectionId: null,
    user_id: isView ? selectedUser?.user?.full_name : selectedUser?._id,
    category: '',
  };
  const [formData, setFormData] = useState(formDataModel);
  const [{ isLoading, isError, data, status }, map] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedResponsibility === 'Select' || selectedResponsibility === '') {
      alert('Please select responsibility');
      return;
    }

    const body = {
      ...formData,
      category: selectedResponsibility,
    };

    if (!isView) map(MAP_RESPONSIBILITY, 'POST', body);
    else
      map(EDIT_ADDITIONAL_RESPONSIBILITY(selectedUser?.user?._id, selectedUser?.responsibility?.category), 'PUT', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setFormData(formDataModel);
      selectUser();
      setViewUser();
      refreshData();
    }

    if (isError && status == 400) {
      alert(data);
    }
  }, [isLoading, isError, data, status]);

  useEffect(() => {
    if (selectedResponsibility !== 'Class Teacher') {
      setFormData({
        ...formData,
        classId: null,
        sectionId: null,
      });
    }
  }, [selectedResponsibility]);

  useEffect(() => {
    if (isView) {
      selectResponsibility(selectedUser?.responsibility?.category);
      setFormData({
        ...formData,
        classId: selectedUser?.responsibility?.classID?._id || '',
        sectionId: selectedUser?.responsibility?.sectionID?._id || '',
        category: selectedUser?.responsibility?.category,
      });
    }
  }, [isView]);

  const getClassName = React.useMemo(() => {
    if (isView && formData.classId) {
      const x = classSections.filter((x) => x.id === formData.classId)[0];
      if (x && x.sections) setSections(x.sections);
      return x?.name;
    }
  }, [formData, isView]);

  const getSectionName = React.useMemo(() => {
    if (isView && formData.sectionId && sections) {
      const x = sections?.filter((x) => x.id === formData.sectionId);
      if (x.length > 0) {
        return x[0]?.name;
      } else return '';
    }
  }, [formData, isView, sections]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/5 px-2 text-sm m-auto rounded-2xl relative">
        <div className=" flex justify-between border-b border-borderYellow items-center">
          <span className="block font-bold p-4 text-textBlue1">
            Map Additional Responsibilities - {isView ? selectedUser?.user?.full_name : selectedUser?.full_name}
          </span>
          <div className="flex gap-x-4 items-center pr-2">
            <img
              src="assets/img/Close.svg"
              className="h-3 cursor-pointer"
              alt=""
              onClick={() => {
                selectUser();
                setViewUser();
              }}
            />
          </div>
        </div>

        <div className=" bg-white rounded-xl h-auto mb-5 w-full px-6 mt-6">
          <div className="flex ">
            <img
              src={
                isView
                  ? selectedUser?.user?.fileArray?.length > 0 && selectedUser?.user?.fileArray[0]?.profileImg?.location
                    ? selectedUser?.user?.fileArray[0].profileImg.location
                    : '/assets/img/user_placeholder.svg'
                  : selectedUser?.fileArray?.length > 0 && selectedUser?.fileArray[0]?.profileImg?.location
                  ? selectedUser?.fileArray[0].profileImg.location
                  : '/assets/img/user_placeholder.svg'
              }
              alt="img"
              className="profileLogo overflow-hidden bg-gray-500 w-40 h-40"
            />

            <div className="flex flex-col ml-6">
              <p className="text-textDark text-lg">
                {isView ? selectedUser?.user?.full_name : selectedUser?.full_name}
              </p>
              <p className="text-tableRowText text-sm">
                {isView ? selectedUser?.user?.username : selectedUser?.username}
              </p>
              <p className="text-textDark text-xs mb-4 capitalize">
                {isView
                  ? selectedUser?.user?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ')
                  : selectedUser?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ')}
              </p>
              <p className="text-tableRowText text-sm">
                {isView ? selectedUser?.responsibility?.category : 'No Additional Responsibility Mapped'}
              </p>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="w-full relative font-semibold px-6 mb-6">
            <label className="text-textDark font-medium text-sm">Map Additional Responsibility</label>
            <DropDown
              data={[
                'Select',
                'Admission Incharge',
                'Examination Incharge',
                'Timetable Incharge',
                'Transport Incharge',
                'Class Teacher',
                'Inventory Incharge',
              ]}
              handleItemClick={(x) => {
                selectResponsibility(x);
              }}
              title={isView ? selectedUser?.responsibility?.category : 'Select'}
              width={'w-full'}
              otherClasses={'border-borderLight mt-2 text-textDark'}
            />
          </div>

          {selectedResponsibility === 'Class Teacher' && (
            <>
              <div className="w-full relative font-semibold px-6 mb-6">
                <label className="text-textDark font-medium text-sm">Class</label>
                <DropDown
                  handleItemClick={(x) => {
                    setFormData({
                      ...formData,
                      classId: x.id,
                    });
                    setSections(x.sections);
                  }}
                  title={getClassName || 'Select Class'}
                  data={classSections}
                  width={'w-full'}
                  type={'obj'}
                  field={'name'}
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div>

              <div className="w-full relative font-semibold px-6 mb-6">
                <label className="text-textDark font-medium text-sm">Section</label>
                <DropDown
                  handleItemClick={(x) => {
                    setFormData({
                      ...formData,
                      sectionId: x.id,
                    });
                  }}
                  title={getSectionName || 'Select Section'}
                  data={sections}
                  width={'w-full'}
                  type={'obj'}
                  field={'name'}
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div>
            </>
          )}

          <div className="w-full px-6">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto mt-2" alt="" />
            ) : (
              <button
                type="submit"
                className="w-25 mb-6 float-right border border-primary text-white bg-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
              >
                {isView ? 'Edit' : 'Map'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default MapResponsibility;
