import React, { useState } from 'react';
import DropDown from '../../../components/UI/DropDown';
import moment from 'moment';

function ResultReseaseDataTable({ title, setViewResult, data, selectClass, handleApproval }) {
  const [sort, setSort] = useState('');

  let filteredData = data || [];

  if (sort && sort !== 'All') {
    if (sort === 'Released') {
      filteredData = filteredData.filter((x) => x.y.approval === 'approved');
    } else if (sort === 'Pending') {
      filteredData = filteredData.filter((x) => x.y.approval !== 'approved');
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-full md:w-6/12">
            <p>{`Release Result (${title})`}</p>
          </div>
          <div className="flex justify-end items-center w-full">
            <DropDown
              data={['All', 'Released', 'Pending']}
              width={'w-1/2 md:w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{
              width: '96%',
            }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/6 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Exam</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Result Date</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Class Teacher</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Action</div>
                <div className="w-1/6 py-2 px-2">Approval</div>
              </div>
            </div>
            <div
              className="customScrollBar"
              style={{
                overflowY: 'scroll',
                maxHeight: '50vh',
              }}
            >
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/5 px-2 text-textDark">{`${x?.y?.classId?.name}-${x?.y?.sectionId?.name}`}</div>
                      <div className="w-1/5 px-2 text-textDark">{x?.testType}</div>
                      <div className="w-1/5 px-2 text-textDark">{moment(x?.resultDate).format('ll')}</div>
                      <div className="w-1/5 px-2 text-textDark">{x?.y?.classTeacherName}</div>
                      <div className="w-1/5 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                          onClick={() => {
                            setViewResult(x?.testType);
                            selectClass(x);
                          }}
                        >
                          View Result
                        </button>
                      </div>
                      <div className="w-1/5 px-2">
                        {x.y.approval !== 'approved' ? (
                          <button
                            className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                            onClick={() => handleApproval(x.y._id)}
                          >
                            Release
                          </button>
                        ) : (
                          <p className="border border-borderLight w-9/12 py-2 rounded-3xl  font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs text-borderLight opacity-60">
                            Released
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultReseaseDataTable;
