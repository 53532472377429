import React, { useState, useEffect } from 'react';
import OpenAttendance from '../../../components/Admin/Dashboard/OpenAttendance';
import StaffAttendance from '../../../components/Admin/Dashboard/StaffAttendance';
import StudentAttendance from '../../../components/Admin/Dashboard/StudentAttendance';
import useApi from '../../../api/useApi';
import { STAFF_TODAY_ATTENDANCE } from '../../../api/urls/attendance';
import { ADMIN, PRINCIPAL } from '../../../api/urls/dashboard';

function Dashboard({ role }) {
  const [staffLeaves, setStaffLeaves] = useState([]);
  const [selectedClass, selectClass] = useState();

  const [staffLeaveRes, getStaffLeave] = useApi();
  const [dashboardRes, getDashboardData] = useApi();

  useEffect(() => {
    getStaffLeave(STAFF_TODAY_ATTENDANCE, 'GET');
    if (role === 'principal') getDashboardData(PRINCIPAL, 'GET');
    else getDashboardData(ADMIN, 'GET');
  }, []);

  useEffect(() => {
    if (!staffLeaveRes.isLoading && !staffLeaveRes.isError && staffLeaveRes.data) {
      setStaffLeaves(staffLeaveRes.data);
    }
  }, [staffLeaveRes]);

  return (
    <div className="w-full md:w-4/5 pb-10 overflow-x-hidden">
      {selectedClass && <OpenAttendance selectClass={selectClass} selectedClass={selectedClass} />}
      <StaffAttendance title={'Teaching Staff Attendance (Today)'} data={staffLeaves.staffAttendanceData} />
      <StaffAttendance title={'Non-Teaching Staff Attendance (Today)'} data={staffLeaves.nonTeachingStaff} />
      <StudentAttendance selectClass={selectClass} dashboardRes={dashboardRes} />
    </div>
  );
}

export default Dashboard;
