import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import Budget from '../../../components/Finance/Dashboard/Budget';
import QuaterlyFeeCollection from '../../../components/Finance/Dashboard/QuaterlyFeeCollection';
import PendingFee from '../../../components/Finance/Dashboard/PendingFee';
import { FINANCE } from '../../../api/urls/dashboard';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  const [dashboardRes, getDashboardData] = useApi();

  useEffect(() => {
    getDashboardData(FINANCE, 'GET');
  }, []);

  useEffect(() => {
    if (!dashboardRes.isLoading && !dashboardRes.isError && dashboardRes.data) {
      setDashboardData(dashboardRes.data);
    }
  }, [dashboardRes]);

  return (
    <div className="w-full md:w-4/5">
      <div className="rounded-3xl h-auto relative pb-10">
        <PendingFee />

        <Budget />

        <QuaterlyFeeCollection
          data={dashboardData?.classWiseReport ? dashboardData?.classWiseReport : []}
          isLoading={dashboardRes.isLoading}
        />
      </div>
    </div>
  );
};

export default Dashboard;
