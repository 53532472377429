import { TIME_TABLE } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const EVENT = `${TIME_TABLE}/institute?year=${year}`;

export const CHILD_SCHOOL_EVENT = (start, end) =>
  `${TIME_TABLE}/institute/user?startDate=${start}&endDate=${end}&year=${year}`;

export const STUDENT_EVENT = (classId, sectionId) =>
  `${TIME_TABLE}/institute/class/${classId}/section/${sectionId}?year=${year}`;

export const ALL_CLASSES_TIMETABLE = `${TIME_TABLE}/institute/class/class_id/section/section_id/timetable/addtionalResponsibility?year=${year}`;

export const TIMETABLE_POST = (classId, sectionId) =>
  `${TIME_TABLE}/institute/class/${classId}/section/${sectionId}/timetable/addtionalResponsibility?year=${year}`;

export const TIMETABLE_PUT = (classId, sectionId, timetableId) =>
  `${TIME_TABLE}/institute/class/${classId}/section/${sectionId}/timetable/${timetableId}/addtionalResponsibility`;

export const TIMETABLE_DELETE = (id) => `${TIME_TABLE}/deletetimeTable/${id}?year=${year}`;

export const GET_TIMETABLE_FOR_SINGLE_CLASS = (classId, sectionId) =>
  `${TIME_TABLE}/institute/class/${classId}/section/${sectionId}/gettimetablemonth?year=${year}`;

export const TEACHER_TIMETABLE = `${TIME_TABLE}/institute/teacher/timeTable?year=${year}`;

export const TEACHER_SUBJECTS = `${TIME_TABLE}/institute/teacher/subjects?year=${year}`;

export const SYLLABUS_UPLOAD = (classId, sectionId, subjectId) =>
  `${TIME_TABLE}/class/${classId}/section/${sectionId}/susbject/${subjectId}/syllabus?year=${year}`;

export const SYLLABUS_GET = `${TIME_TABLE}/institute/teacher/subjects/syllabus?year=${year}`;
