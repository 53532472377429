import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CenterFeedForm from '../../components/Social/CenterFeedForm';
import UserProfile from '../../components/Social/UserProfile';
import NewPostComponent from '../../components/Social/CreatePost';
import FeedUpdatesComponent from '../../components/Social/FeedUpdate';
import ProfileGeneralSettingsComponent from '../../components/Social/ProfileGeneralSetting';
import RoledInFeedback from '../../components/Social/RoledInFeedback';
import NotificationComponent from '../../components/NotificationSetting';
import { useStateValue } from '../../ContextApi/StateProvider';
import { GET_FEEDS } from '../../api/urls/social';
import useApi from '../../api/useApi';

const Social = () => {
  const location = useLocation();
  const [{ user, institutes, openSocialMenu }, dispatch] = useStateValue();

  const [socialFeed, getSocialFeed] = useApi();
  const [feed, setFeed] = useState([]);

  const [newUpdate, setNewUpdates] = useState({
    userId: '',
    action: '',
  });

  useEffect(() => {
    if (location && openSocialMenu) {
      dispatch({
        type: 'TOGGLE_SOCIAL_MENU',
        openSocialMenu: false,
      });
    }
  }, [location]);

  const newUpdates = (userId, action) => {
    setNewUpdates({ userId, action });
  };

  useEffect(() => {
    if (user) {
      getSocialFeed(GET_FEEDS(user?.institute?._id), 'GET');
    }
  }, [user]);

  const refreshData = () => {
    getSocialFeed(GET_FEEDS(user?.institute?._id), 'GET');
  };

  useEffect(() => {
    if (!socialFeed.isLoading && !socialFeed.isError && socialFeed.data) {
      setFeed(socialFeed.data);
    }
    if (!socialFeed.isLoading && socialFeed.isError) {
      setFeed([]);
    }
  }, [socialFeed.isLoading, socialFeed.isError, socialFeed.data]);

  return (
    <div className="w-full md:w-4/5 flex gap-x-4">
      {!location.search && (
        <CenterFeedForm getUserId={newUpdates} feed2={feed} profile={user} refreshData={refreshData} />
      )}
      {location.search.includes('feedback') && <RoledInFeedback />}
      {location.search.includes('setting') && <ProfileGeneralSettingsComponent profile={user} />}
      {location.search.includes('notification') && <NotificationComponent />}
      {
        <div
          className={`md:relative md:w-1/3 flex-col ${
            openSocialMenu ? 'flex w-3/4 right-0 top-0 fixed bg-white p-4 h-screen' : 'hidden md:flex'
          }`}
          // style={{ width: '30%' }}
        >
          {openSocialMenu && (
            <img
              src="assets/img/Close.svg"
              className="block md:hidden w-4 ml-auto mt-6 mr-6 mb-4"
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_SOCIAL_MENU',
                  openSocialMenu: false,
                })
              }
            />
          )}
          <UserProfile profile={user} />
          {user?.user?.currentlyMapped?.role?.some((c) => ['ow','dir','pr','ad','te'].includes(c)) && (
            <NewPostComponent refreshData={refreshData} institutes={institutes} />
          )}
          <FeedUpdatesComponent feed={newUpdate} />
        </div>
      }
    </div>
  );
};
export default Social;
