import React, { useEffect } from 'react';
import useApi from '../../../api/useApi';
import { SEND_MESSAGE } from '../../../api/urls/user';

function Message({ setMessage, message }) {
  const [msgRes, sendMessage] = useApi();
  const msg = React.useRef();
  console.log(message);
  const handleSendMessage = () => {
    const body = {
      message: msg.current.value,
    };
    sendMessage(SEND_MESSAGE(message._id), 'POST', body);
  };

  useEffect(() => {
    if (!msgRes.isLoading && !msgRes.isError && msgRes.status === 200) {
      alert('Success');
      msg.current.value = '';
      setMessage(false);
    }

    if (!msgRes.isLoading && msgRes.isError) {
      alert('Failed');
    }
  }, [msgRes]);

  return (
    <div
      className="flex h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-5/12 text-sm  m-auto mt-16 flex flex-col rounded-2xl relative">
        <p className="block font-bold py-4 px-6 border-b border-borderYellow">
          {message?.full_name} <span className="text-tableRowText font-normal text-xs">{`(${message?.username})`}</span>
        </p>
        <img
          src="assets/img/Close.svg"
          className="absolute top-5 right-8 h-3 cursor-pointer"
          alt=""
          onClick={() => setMessage(false)}
        />
        <div className="relative px-6 flex flex-col py-4">
          <textarea
            rows="6"
            ref={msg}
            cols="64"
            className="outline-none text-xs mb-2"
            placeholder="Write your Message Here"
          />
          {msgRes.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
          ) : (
            <button
              className="w-28 border px-6 py-2 ml-auto mt-2 rounded-3xl text-xs text-white bg-primary  focus:outline-none flex justify-center items-center"
              onClick={() => {
                handleSendMessage();
              }}
            >
              Send
            </button>
          )}
        </div>
        {/* <div className="flex justify-between text-xs font-bold p-2 px-6 relative">
          <p>Message History</p>
        </div>
        <div className="text-center items-center justify-center text-xs px-6 pt-3 pb-6">
          <p className="mb-2 text-xxs">20-April-2020</p>

          <div className="flex items-center  gap-x-2 justify-between">
            <p className="text-sm w-11/12 text-left text-black">
              Me - <span className="text-xs text-tableRowText">Connect back on priority</span>
            </p>
            <p className="text-tableRowText w-1/12 text-right text-xxs">01:30PM</p>
          </div>

          <div className="flex items-center mt-4 gap-x-2 justify-between">
            <p className="text-sm w-11/12 text-left text-black">
              Principal (Mr. Gagan Chandra) - -{' '}
              <span className="text-xs text-tableRowText">
                Connect back on priority, we are looking for admission documents
              </span>
            </p>
            <p className="text-tableRowText w-1/12 text-right text-xxs">10:30AM</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Message;
