import React from 'react';

const PendingFeeDetails = ({ data, onClose }) => {
  console.log(data);
  return (
    <div
      className="flex justify-center pb-6 h-full w-full fixed left-0 top-0 z-30 overflow-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="relative w-11/12 md:w-2/5 pb-4 m-auto  mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="flex w-full justify-between items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 py-4 text-textBlue1">{`Pending Fee (${data?.prevBal || 0})`}</p>
          <img
            src="assets/img/Close.svg"
            className="absolute right-4 h-3 cursor-pointer z-50"
            alt=""
            onClick={onClose}
          />
        </div>

        <div className="flex flex-col px-6">
          {data?.prevBalRecord?.map((x) => {
            return x?.type === 'misc' ? (
              <div className="flex flex-col mb-3 mt-4">
                <span className="text-sm font-semibold underline">{x?.month}</span>
                <p className="font-medium">{`Actual Fee: ${x?.amount}`}</p>
                <p className="font-medium">{`Amount Paid: ${x?.amountPaid}`}</p>
                <p className="font-medium">{`Balance: ${x?.bal}`}</p>
              </div>
            ) : (
              <div className="flex flex-col mb-3 mt-4">
                <span className="text-sm font-semibold underline">{x?.month}</span>
                <p className="font-medium">{`Actual Fee: ${x?.actualMonthFee}`}</p>
                <p className="font-medium">{`Balance: ${x?.bal}`}</p>
                <p className="font-medium">{`Extra Payment: ${x?.extraPayment}`}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PendingFeeDetails;
