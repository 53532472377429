import React, { useState, useEffect } from 'react';

function Questions({
  selectedTest,
  setAnswers,
  answers,
  handleOpenHelp,
  handleOpenPreview,
  attentedQues,
  setAttentedQues,
  currentQues,
  timeRemaining,
}) {
  const [current, setCurrent] = useState(currentQues || 1);

  const onValueChange = (e) => {
    const x = [...answers];
    let y = { ...x[current - 1] };
    y.submitAnswer = e.target.value;
    x[current - 1] = { ...y, ...selectedTest?.testDetail.question[current - 1] };
    setAnswers(() => [...x]);
  };

  useEffect(() => {
    setAttentedQues(answers.filter((x) => x.submitAnswer).length);
  }, [answers]);

  return (
    <>
      <div className="border-b border-borderYellow px-6 py-3 ">
        <div className="flex items-center justify-between">
          <span className="block font-bold">{`${selectedTest?.testDetail?.title} - ${selectedTest?.subjectCode} ${selectedTest?.subject_name}`}</span>

          <button
            className={
              'border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none ml-auto text-center text-primary bg-white border-primary'
            }
            onClick={() => {
              handleOpenHelp();
            }}
          >
            Help
          </button>
        </div>
      </div>

      <div className="border-b border-borderYellow px-6 py-4 flex items-center justify-between">
        <span className="text-xs">{`Time Remaining - ${timeRemaining}`}</span>
        <span className="text-xs">{`${attentedQues}/${selectedTest?.testDetail.question.length} attended`}</span>
      </div>

      <div className="px-6 py-4">
        <p className="font-semibold mb-2">{`Question ${current}`}</p>

        <p className="mb-3">{selectedTest?.testDetail.question[current - 1].question}</p>

        {selectedTest?.testDetail.question[current - 1].type === 'Subjective Question' ? (
          <div>
            <textarea
              type="text"
              key={current}
              rows={15}
              className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              onChange={onValueChange}
              value={answers[current - 1].submitAnswer}
            />
          </div>
        ) : (
          <div>
            <div className="flex items-center mb-1.5">
              <input
                type="radio"
                id="option1"
                name="options"
                className="mr-6 mt-1"
                checked={answers[current - 1].submitAnswer === selectedTest?.testDetail.question[current - 1].option1}
                onChange={onValueChange}
                value={selectedTest?.testDetail.question[current - 1].option1}
              />
              <label for="option1">{selectedTest?.testDetail.question[current - 1].option1}</label>
            </div>
            <div className="flex items-center mb-1.5">
              <input
                type="radio"
                id="option2"
                name="options"
                className="mr-6 mt-1"
                checked={answers[current - 1].submitAnswer === selectedTest?.testDetail.question[current - 1].option2}
                onChange={onValueChange}
                value={selectedTest?.testDetail.question[current - 1].option2}
              />
              <label for="option2">{selectedTest?.testDetail.question[current - 1].option2}</label>
            </div>
            <div className="flex items-center mb-1.5">
              <input
                type="radio"
                id="option3"
                name="options"
                className="mr-6 mt-1"
                checked={answers[current - 1].submitAnswer === selectedTest?.testDetail.question[current - 1].option3}
                onChange={onValueChange}
                value={selectedTest?.testDetail.question[current - 1].option3}
              />
              <label for="option3">{selectedTest?.testDetail.question[current - 1].option3}</label>
            </div>
            <div className="flex items-center mb-1.5">
              <input
                type="radio"
                id="option4"
                name="options"
                className="mr-6 mt-1"
                checked={answers[current - 1].submitAnswer === selectedTest?.testDetail.question[current - 1].option4}
                onChange={onValueChange}
                value={selectedTest?.testDetail.question[current - 1].option4}
              />
              <label for="option4">{selectedTest?.testDetail.question[current - 1].option4}</label>
            </div>
          </div>
        )}
      </div>

      <div className="w-full px-6 pb-4 mt-7 flex justify-end">
        {attentedQues > 0 && (
          <button
            className={
              'border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none mr-auto text-center bg-primary text-white border-primary'
            }
            onClick={() => {
              handleOpenPreview();
            }}
          >
            Preview
          </button>
        )}

        {current > 1 && (
          <button
            className={
              'border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none  text-center text-primary bg-white border-primary'
            }
            onClick={() => {
              setCurrent((prev) => prev - 1);
            }}
          >
            Previous Question
          </button>
        )}

        {current < selectedTest?.testDetail.question.length && (
          <button
            className={
              'border py-2 w-36 rounded-3xl text-xs ml-4 font-medium focus:outline-none  text-center bg-primary text-white border-primary'
            }
            onClick={() => {
              setCurrent((prev) => prev + 1);
            }}
          >
            Next Question
          </button>
        )}
      </div>
    </>
  );
}

export default Questions;
