import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { ADMISSION_WITHDRAW } from '../../../api/urls/user';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import DropDown from '../../../components/UI/DropDown';
import { useStateValue } from '../../../ContextApi/StateProvider';

function AdmissionWithdrawl({ toggleWithdrawl, refreshList }) {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [studentList, setStudents] = useState([]);
  const [combinedClassSection, setCombinedClassSection] = useState([]);

  const [response, removeStudent] = useApi();
  const [students, getStudents] = useApi();

  const [{ classSections }, _] = useStateValue();

  useEffect(() => {
    if (classSections && Array.isArray(classSections)) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
    }
  }, [students]);

  const handleSubmit = (e) => {
    e.preventDefault();

    removeStudent(ADMISSION_WITHDRAW(id), 'DELETE');
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [200, 201, 204].includes(response.status)) {
      setId('');
      alert('SUCCESS');
      toggleWithdrawl();
      refreshList();
    }

    if (response.isError) {
      alert('FAILED');
    }
  }, [response]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-full md:w-5/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">Admission withdrawal</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleWithdrawl} />
        </div>
        <form className="my-4 px-6 " autoComplete="off" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="my-2  relative font-semibold w-1/2 pr-2 col-span-2">
              <label className="block text-sm text-textDark">
                Class<span className="text-red-500 ml-1">*</span>
              </label>
              <DropDown
                field={'name'}
                handleItemClick={(x) => {
                  setId(null);
                  setName(null);
                  getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
                }}
                title={'Select'}
                data={combinedClassSection}
                width={'w-full'}
                margin={'mt-2'}
                otherClasses={'border-borderLight'}
                type={'obj'}
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className="block text-sm text-textDark">Name</label>
              <DropDown
                field={'full_name'}
                handleItemClick={(x) => {
                  setId(x.username);
                  setName(x?.full_name);
                }}
                title={name || 'Select Student'}
                data={studentList}
                width={'w-full'}
                margin={'mt-2'}
                otherClasses={'border-borderLight'}
                type={'obj'}
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">ROLEDin Id</label>
              <input
                className="w-full  rounded-3xl border border-borderLight opacity-50 px-4 mt-2 py-2 text-xs focus:outline-none"
                // onChange={(e) => setId(e.target.value)}
                value={id}
                disabled
              />
            </div>
          </div>

          <div className=" my-4 w-full flex gap-x-4 justify-end">
            {response.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <button
                type="submit"
                className="border py-2 px-12 rounded-3xl text-xs font-bold focus:outline-none text-center bg-primary border-primary text-white"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdmissionWithdrawl;
