import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { TEST_MARKS, GET_SUBMITTED_TEST } from '../../../api/urls/test';
import ViewSubmittedTest from './ViewSubmittedTest';

function TestMarks({ testId, setTestId, setTestCheck }) {
  const [testMarks, setTestMarks] = useState([]);
  const [viewTest, setTestForView] = useState();

  const [testMarksState, getTestMarks] = useApi();
  const [uploadState, uploadTestMArks] = useApi();

  useEffect(() => {
    if (testId) {
      window.scrollTo(0, 0);
      setTestCheck(true);
      getTestMarks(GET_SUBMITTED_TEST(testId.class_id, testId.section_id, testId._id), 'GET');
    }
  }, [testId]);

  useEffect(() => {
    if (!testMarksState.isLoading && !testMarksState.isError && testMarksState.data) {
      let x = testMarksState.data.result.map((y) => {
        return {
          ...y,
          // marks: y.marks ? y.marks : '',
        };
      });
      setTestMarks(x);
    }
  }, [testMarksState]);

  const handleMarksChange = (e, studentId) => {
    let studentIndex = testMarks.findIndex((x) => x.studentId === studentId);
    let y = testMarks[studentIndex];

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    let x = {
      ...y,
      marks: inputValue,
    };
    testMarks[studentIndex] = x;
    setTestMarks([...testMarks]);
  };

  const handleSubmit = () => {
    let isMarksMissing = false;

    const marksBody = testMarks.map((x) => {
      if (!x?.marks) {
        isMarksMissing = true;
      }
      return {
        id: x.studentId,
        marks: x.marks ? +x.marks : 0,
      };
    });

    if (isMarksMissing) {
      alert("Some students's marks are missing");
      return;
    }
    // console.log(testId);
    if (testMarksState.data.isMarked) uploadTestMArks(TEST_MARKS(testId._id), 'PUT', marksBody);
    else uploadTestMArks(TEST_MARKS(testId._id), 'POST', marksBody);
  };

  useEffect(() => {
    if (!uploadState.isLoading && !uploadState.isError && (uploadState.status == 201 || uploadState.status == 202)) {
      alert('Success');
      getTestMarks(GET_SUBMITTED_TEST(testId.class_id, testId.section_id, testId._id), 'GET');
    }

    if (!uploadState.isLoading && uploadState.isError) {
      alert('Failed');
    }
  }, [uploadState.isLoading, uploadState.isError, uploadState.data]);

  const handleClose = (ok) => {
    if (ok) {
      getTestMarks(GET_SUBMITTED_TEST(testId.class_id, testId.section_id, testId._id), 'GET');
    }
    setTestForView(null);
  };

  return (
    <>
      {viewTest && <ViewSubmittedTest test={viewTest} handleClose={handleClose} />}
      <div className="w-full bg-white rounded-xl h-auto mb-5 pb-5">
        <div className="items-center px-6 py-4 border-b border-borderYellow">
          <p className="text-xs text-tableRowText mb-3 cursor-pointer">
            {`Test & Assignments > ${testId?.subject_name}  (${testId?.className} - ${testId?.sectionName}) > ${testId?.test_type}`}
          </p>
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-medium text-lg w-5/12">
              <p>{testId?.test_type}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-3/12 border-r-2 py-2 px-2">Roll Number</div>
                  <div className="w-5/12 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Marks Obtained</div>
                  <div className="w-3/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '56vh' }}>
                {testMarksState.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : Array.isArray(testMarks) && testMarks.length > 0 ? (
                  testMarks.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs items-center ${
                          idx !== testMarks.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-3/12 px-2">--</div>
                        <div className="w-5/12 px-2">{x.name}</div>
                        <div className="w-3/12 px-2">
                          <input
                            type="number"
                            placeholder="00"
                            className={`w-20 px-4 text-left rounded-3xl h-7 border ${
                              testMarksState.data?.isMarked || (x.test && !x.test?.isActive)
                                ? 'border-borderLight text-tableRowText'
                                : 'border-tableRowText text-textDark'
                            }`}
                            value={x.marks}
                            disabled={testMarksState.data?.isMarked || x.test ? !x.test?.isActive : false}
                            onChange={(e) => handleMarksChange(e, x.studentId)}
                          />
                        </div>
                        <div className="w-3/12 px-2">
                          <button
                            type="submit"
                            className="border w-full border-red-400 px-4 py-2 rounded-3xl text-red-400 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                            onClick={() => setTestForView(x)}
                          >
                            View Test
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-x-4 mt-3 justify-end">
          {uploadState.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
          ) : (
            <>
              <button
                className="border border-borderLight w-32 ml-auto mr-5 px-4 py-2 rounded-3xl text-xs focus:outline-none flex justify-center text-center items-center text-tableRowText"
                onClick={() => {
                  setTestCheck(false);
                  setTestId(null);
                }}
              >
                Back
              </button>
              {!testMarksState.data?.isMarked && (
                <button
                  className="border bg-primary w-32 mr-5 px-4 py-2 rounded-3xl text-xs focus:outline-none flex justify-center text-center items-center text-white"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TestMarks;
