import React, { useState, useEffect, useCallback } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { MONTHS } from '../../../constants';

function Calender({ data }) {
  const [allEvents, setEvents] = useState([]);

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const components = React.useMemo(
    () => ({
      toolbar: () => {
        return <div className="text-center py-2 font-medium">{MONTHS[new Date().getMonth()]}</div>;
      },
    }),
    []
  );

  useEffect(() => {
    if (data?.data?.staffHistory && Array.isArray(data?.data?.staffHistory)) {
      const x = data?.data?.staffHistory.map((x) => {
        // if (+x.hours === 0)
        return {
          title: x.status,
          start: new Date(x.date),
          end: new Date(x.date),
        };
        // else
        //   return {
        //     title: 'Present',
        //     start: new Date(x.date),
        //     end: new Date(x.date),
        //   };
      });
      setEvents(x);
    }
  }, [data]);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.title.includes('Absent') && {
        className: 'redEventColor',
      }),
      ...(event.title.includes('Half-Day') && {
        className: 'gazettedHolidayColor',
      }),
      ...(event.title.includes('Full Time') && {
        className: 'greenEventColor',
      }),
      ...(event.title.includes('Missed Punch') && {
        className: 'stateHolidayColor',
      }),
      ...(event.title.includes('Leave') && {
        className: 'winterHolidayColor',
      }),
    }),
    []
  );

  return (
    <div className="w-full">
      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <p className="font-bold text-base">{`Attendance (${
            MONTHS[new Date().getMonth()]
          } ${new Date().getFullYear()})`}</p>
        </div>

        <div className=" py-4 mx-4 gap-x-2">
          <div className="bg-white m-auto border rounded-2xl" style={{ borderColor: '#E7E700' }}>
            <Calendar
              localizer={localizer}
              events={allEvents}
              views={['month']}
              style={{ height: 450 }}
              popup
              eventPropGetter={eventPropGetter}
              components={components}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calender;
