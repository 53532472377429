import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { MEETING_STATUS_CHANGE } from '../../../api/urls/appointment';
import useApi from '../../../api/useApi';

function MeetingAcceptReject({ handleClose, status }) {
  const [rejectReason, setRejectReason] = useState();

  const [response, changeStatus] = useApi();

  const handleSubmit = (_status) => {
    let body = {};
    if (_status === 'Reject') {
      body = {
        reason: rejectReason,
        status: _status,
      };
    } else {
      body = {
        status: _status,
      };
    }

    changeStatus(MEETING_STATUS_CHANGE(status?.x?._id), 'PUT', body);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && response.status === 202) {
      alert('Success');
      handleClose(true);
    }

    if (!response.isLoading && response.isError) {
      alert('Failed');
    }
  }, [response]);

  return (
    <div
      className="flex justify-center md:items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/5 text-sm block mx-auto mb-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow">
          <span className="block font-bold p-4 px-6">Meeting Request </span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-6 h-3 cursor-pointer"
            alt=""
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div className="mt-2 mb-2 flex flex-wrap px-6" autoComplete="off">
          <div className="mt-2 w-full relative font-semibold">
            <label className="block text-sm">Meeting Date</label>
            <p className="w-full text-textLight rounded-3xl border px-4 mt-2 py-2 text-xs focus:outline-none">
              {moment(status.x.date).format('ll')}
            </p>
          </div>

          <div className="mt-4 mb-2 w-full relative font-semibold">
            <label htmlFor="reason" className="block text-sm">
              {status.option === 'Reject' ? 'Reason' : 'Agenda'}
            </label>
            <textarea
              required
              type="text"
              name="reason"
              disabled={status.option === 'View'}
              rows={5}
              value={status.option === 'View' ? status.x.reason : rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="..."
              className="w-full text-textLight rounded-2xl border px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>
          {status.hideBtn ? null : status.option === 'Reject' ? (
            <div className="my-4 w-full relative flex justify-end gap-x-4">
              {response.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
              ) : (
                <>
                  <button
                    className="border border-borderLight px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-white text-textLight"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="border border-primary px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-primary text-white"
                    onClick={() => handleSubmit('Reject')}
                  >
                    Reject
                  </button>
                  )
                </>
              )}
            </div>
          ) : status.x.status ? null : (
            <div className="my-4 w-full relative flex justify-end gap-x-4">
              {response.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
              ) : (
                <>
                  <button
                    className="border border-borderLight px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-white text-textLight"
                    onClick={() => handleSubmit('Reject')}
                  >
                    Reject
                  </button>
                  <button
                    className="border border-primary px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-primary text-white"
                    onClick={() => handleSubmit('Approved')}
                  >
                    Approve
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeetingAcceptReject;
