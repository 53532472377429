import React, { useEffect } from 'react';
import useApi from '../../../api/useApi';
import { GET_CLASS_PENDING_FEE } from '../../../api/urls/finance';

export default function Search({
  searchName,
  setSearch,
  setMessage,
  choice,
  searchType,
  setDetail,
  selectedClass,
  selectClassForFee,
}) {
  const [pendingFees, getPendingFees] = useApi();

  useEffect(() => {
    if (selectedClass.classId) {
      getPendingFees(GET_CLASS_PENDING_FEE(selectedClass.classId, selectedClass.sectionId), 'GET');
    }
  }, [selectedClass]);

  return (
    <div
      className="flex h-full w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-3/4 px-2 text-sm h-auto mx-auto mt-16 mb-auto rounded-2xl relative">
        <span
          className="block font-bold p-4 text-textBlue1"
          style={{
            borderBottom: '0.71px solid #E7E700',
          }}
        >
          {choice === 'search'
            ? searchType === 'PendingFee'
              ? `Pending Fee- Search Results for ${searchName}`
              : `Student Details- Search Results for ${searchName}`
            : `Pending Fee - ${selectedClass.class} - ${selectedClass.section}`}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-5 right-8 h-3 cursor-pointer"
          alt=""
          onClick={() => {
            // setSearch(false);
            selectClassForFee(null);
          }}
        />
        <div className="flex flex-wrap w-full">
          <div className="w-full py-4">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow"
              style={{
                width: '96%',
              }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative px-2 text-xs font-medium">
                  <div className="w-3/12 border-r-2 py-2 px-2">Photograph</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Roll Number</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Student Name</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Father's Name</div>
                  <div className="w-3/12 border-r-2 py-2 px-2">Contact</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Amount of Fee</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Months</div>
                  <div className="w-1/4 py-2 px-2">Action</div>
                </div>
              </div>
              <div
                className="customScrollBar"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '62vh',
                }}
              >
                {pendingFees.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto my-6" alt="" />
                ) : pendingFees?.data && Array.isArray(pendingFees?.data) && pendingFees?.data.length > 0 ? (
                  pendingFees?.data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                          pendingFees?.data.length - 1 !== idx && 'border-b border-tableHeader'
                        }`}
                      >
                        <div className="w-3/12 px-4">
                          <img
                            className="schoolLogo"
                            style={{
                              width: '3em',
                              height: '3em',
                            }}
                            src={x?.image?.profileImg?.location || '/assets/img/user_placeholder.svg'}
                          />
                        </div>
                        <div className="px-2 w-3/12 text-tableRowText"></div>
                        <div className="px-2 w-3/12 text-tableRowText">{x?.name}</div>
                        <div className="px-2 w-3/12 text-tableRowText">{x?.father?.father_name}</div>
                        <div className="px-2 w-3/12 text-tableRowText">{x?.father?.phone || ''}</div>
                        <div className="px-2 w-4/12 text-tableRowText">&#x20b9; {x?.pendingFee}</div>
                        <div className="px-2 w-4/12 text-tableRowText">{x?.months.join(', ')}</div>
                        <div className="px-2 w-1/4 flex  items-center">
                          <button
                            className="w-full lg:w-max border border-primary px-4 py-2 rounded-3xl text-xs text-primary focus:outline-none"
                            onClick={() => {
                              // setSearch((prev) => !prev);
                              setMessage(x);
                            }}
                          >
                            {/* {searchType === 'pendingFee' ? 'Message' : 'View More'} */}
                            Message
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
