import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { MEETING } from '../../../api/urls/appointment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { getRole } from '../../../utils';

function ScheduleMeeting({ teachingStaff, nonTeachingStaff, handleClose, selectedUser }) {
  const [staffType, setStaffType] = useState('All');
  const [selectedList, selectList] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [query, setSearchQuery] = useState('');

  const [createState, scheduleMeeting] = useApi();

  const [formData, setFormData] = useState({
    date: '',
    time: null,
    url: '',
    reason: '',
    requested_date: '',
    mode: 'Online',
    staff: [],
  });

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (selectedUser) {
      selectList([selectedUser]);
    }
  }, [selectedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData.date) str.push('Date');
    if (!formData.time) str.push('Time');
    if (!formData.reason?.trim()) str.push('Agenda');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (selectedList.length === 0) {
      alert(`Please select team members`);
      return;
    }

    const _time = new Date(formData.time).toLocaleTimeString();
    const _date = new Date(formData.date).toLocaleDateString();

    const body = {
      ...formData,
      reason: formData.reason?.trim(),
      date: new Date(_date + ' ' + _time).toISOString(),
      requested_date: new Date(_date + ' ' + _time).toISOString(),
      time: formData.time,
      staff: selectedList.map((x) => x._id),
    };

    delete body.time;
    scheduleMeeting(MEETING, 'POST', body);
  };

  useEffect(() => {
    if (!createState.isLoading && !createState.isError && createState.status === 201) {
      alert('Success');
      handleClose(true);
    }

    if (!createState.isLoading && createState.isError) {
      alert('Failed');
    }
  }, [createState]);

  useEffect(() => {
    let list = [];
    if (staffType === 'All') list = [...teachingStaff, ...nonTeachingStaff];
    else if (staffType === 'Teaching') list = teachingStaff;
    else list = nonTeachingStaff;

    if (query) {
      list = list.filter((x) => {
        return x.full_name ? x.full_name.toLowerCase().includes(query.toLowerCase()) : false;
      });
    }

    setFilteredStaffList(list);
  }, [staffType, query]);

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-1/2 text-sm block mt-6 mx-auto rounded-2xl relative">
        <div className="border-b border-borderYellow">
          <span className="block font-bold p-4 px-6">{selectedUser ? 'Meeting Request' : 'New Meeting'}</span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-6 h-3 cursor-pointer"
            alt=""
            onClick={() => {
              handleClose();
              selectList([]);
              setOpenList(false);
              setSearchQuery('');
              setFilteredStaffList([]);
            }}
          />
        </div>
        <form onSubmit={handleSubmit} className="mt-2 mb-2 flex flex-wrap px-6" autoComplete="off">
          <div className="mt-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">
              Date<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              required
              selected={formData.date}
              onChange={(date) => setFormData({ ...formData, date: date })}
              dateFormat={'dd-MM-yyyy'}
              className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>
          <div className="w-1/10" />
          <div className="mt-2 mb-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm mb-2">
              Time<span className="text-red-500 ml-1">*</span>
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label=""
                required
                value={formData?.time}
                onChange={(time) => setFormData({ ...formData, time: time })}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="mt-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">Meeting Mode</label>
            <select
              type="text"
              name="mode"
              onChange={handleInputChange}
              placeholder="Select Meeting Mode"
              autoComplete="off"
              className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            >
              <option>Online</option>
              <option>Offline</option>
            </select>
          </div>
          <div className="w-1/10" />
          <div className="mt-2 mb-2 w-full md:w-2.25/5 relative font-semibold">
            {!selectedUser && (
              <>
                <label className="block text-sm">Staff</label>
                <select
                  type="text"
                  onChange={(e) => setStaffType(e.target.value)}
                  placeholder="Select Staff Type"
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                >
                  <option>All</option>
                  <option>Teaching</option>
                  <option>Non Teaching</option>
                </select>
              </>
            )}
          </div>
          <div className="mt-2 mb-2 w-full relative font-semibold">
            <label className="block text-sm">
              Agenda<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              required
              type="text"
              name="reason"
              onChange={handleInputChange}
              placeholder="..."
              className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-2 w-full relative font-semibold">
            <label className="block text-sm">{formData.mode === 'Online' ? 'Meeting Link' : 'Meeting Venue'}</label>
            <input
              type="text"
              name="url"
              onChange={handleInputChange}
              placeholder={formData.mode === 'Online' ? 'Add Meeting Url' : 'Add Meeting Venue'}
              className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>

          {!selectedUser && (
            <div className="mt-2 mb-6 w-full relative h-44 ">
              <label className="block text-sm font-semibold">
                Team Members<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="w-full h-44 rounded-2xl mt-2 border border-borderLight focus:outline-none flex-col justify-start">
                <div className="w-1/3 relative border px-3 m-1.5 border-b border-borderLight rounded-2xl text-sm text-gray-400 focus:outline-none flex">
                  <input
                    type="text"
                    placeholder="Search Staff"
                    autoComplete="off"
                    className="text-left w-full bg-transparent text-gray-500  focus:outline-none h-7 text-xs"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setOpenList(true)}
                    // onBlur={() => setOpenList(false)}
                  />
                  {openList && (
                    <div className="absolute bg-white shadow-2xl top-7 left-0 rounded-md p-4 max-h-56 overflow-auto z-50">
                      {Array.isArray(filteredStaffList) &&
                        filteredStaffList.map((x) => (
                          <div
                            className="my-2 flex cursor-pointer"
                            key={x._id}
                            onClick={() => {
                              // const isPresent = selectedList.filter((y) => x._id === y._id);
                              // if (isPresent.length > 0) {
                              //   selectList([...selectedList.filter((y) => x._id !== y._id)]);
                              // } else {
                              selectList([...new Set([...selectedList, x])]);
                              setOpenList(false);
                              // }
                            }}
                          >
                            <img src={getFile(x, 'profileImg')} className="h-9 w-9 rounded-tl-md rounded-br-md" />
                            <div className="flex flex-col ml-2">
                              <span className="text-black text-xxs">{x.full_name}</span>
                              <span className="text-tableRowText text-xxs -mt-1">
                                {x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ') || ''}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="p-2 border-t flex flex-wrap gap-2 w-full overflow-auto customScrollBar">
                  {Array.isArray(selectedList) &&
                    selectedList.map((x) => (
                      <div className="gap-x-2 rounded-3xl flex justify-between text-xs bg-tableHeader border px-3 py-1.5 focus:outline-none">
                        <span>{x.full_name}</span>
                        <img
                          src="assets/img/Close.svg"
                          alt=""
                          className="cursor-pointer"
                          onClick={() => {
                            selectList([...selectedList.filter((y) => x._id !== y._id)]);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div className=" my-4 w-full relative">
            {createState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <button
                type="submit"
                className="border float-right px-12 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-primary text-white"
              >
                {selectedUser ? 'Send Request' : 'Schedule a meeting'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ScheduleMeeting;
