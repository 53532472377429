import React from 'react';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import moment from 'moment';

function ExpenseJustification({ expenses, selectJustificationDetail, selectTransaction }) {
  return (
    <div className="w-full bg-white rounded-xl h-auto min-w-700">
      <div className="w-full bg-white rounded-xl h-auto my-4">
        <div className="items-center px-6 py-3 border-borderYellow border-bborder-borderYellow border-b">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold">
              <p>Expense Justification</p>
            </div>
            <div className="flex justify-end gap-x-4">
              {/* <ExcelExportBtn data={[]} fileName={'attendance.csv'} text={'Export Data'} /> */}

              <button
                className=" w-36 text-textLight border-tableRowText  border px-4 py-2 rounded-3xl text-xs focus:outline-none"
                onClick={() => {}}
              >
                Filter
              </button>

              <DropDown
                data={[
                  'Ascending Order',
                  'Descending Order',
                  'Name - a to z',
                  'Name - z to a',
                  'High Pending Fee',
                  'Low Pending Fee',
                ]}
                width={'w-36'}
                title={'Sort by'}
                handleItemClick={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full px-4 relative  text-xs font-medium">
                  <div className="px-2 w-2/12 border-r-2 py-2">Expense Date</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Added By</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Expense Title</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Category</div>
                  <div className="px-2 w-2/12 border-r-2 py-2">Amount </div>
                  <div className="px-2 w-2/12 py-2">Justification</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
                {
                  //   classSectionResponse.isLoading ? (
                  //     <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  Array.isArray(expenses) && expenses.length > 0 ? (
                    expenses.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative px-4 py-2.5 text-xs font-medium ${
                            expenses.length - 1 !== idx && 'border-borderYellow border-b'
                          }`}
                        >
                          <div className="px-2 w-2/12 text-tableRowText py-2">
                            {moment(x.date).format('DD MMM YYYY')}
                          </div>
                          <div className="px-2 w-2/12 text-tableRowText py-2">{x?.user?.full_name}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{x?.name}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{x?.category?.category}</div>
                          <div className="px-2 w-2/12 text-tableRowText">{'\u20B9' + ' ' + x?.amount}</div>
                          <div className="px-2 w-2/12 text-tableRowText">
                            {/* {x?.justification && ( */}
                            <button
                              className={`w-11/12  ${
                                x?.askJustication
                                  ? x?.justification
                                    ? 'border-borderGrey text-tableRowText'
                                    : 'border-primary text-white bg-primary opacity-50 cursor-not-allowed'
                                  : ' border-primary text-white bg-primary'
                              } border px-4 py-2 rounded-3xl text-xs focus:outline-none`}
                              onClick={() => {
                                x?.askJustication
                                  ? x?.justification
                                    ? selectJustificationDetail(x)
                                    : null
                                  : selectTransaction(x);
                              }}
                            >
                              {x?.askJustication ? (x?.justification ? 'View' : 'Requested') : 'Request'}
                            </button>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseJustification;
