import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CreateAssignment from '../../../components/Teacher/TestNAssignment/CreateAssignment';
import CreateTest from '../../../components/Teacher/TestNAssignment/CreateTest';
import useApi from '../../../api/useApi';
import TestMarks from '../../../components/Teacher/TestNAssignment/TestMarks';
import AssignmentMarks from '../../../components/Teacher/TestNAssignment/AssignmentMarks';
import { GET_TEACHER_ASSIGNMENT, DELETE_ASSIGNMENT } from '../../../api/urls/notes';
import { GET_TEACHER_TEST, DELETE_TEST } from '../../../api/urls/test';

const AssignmentsAndTest = ({ subjects }) => {
  const [testAssignMent, setTestAssignMent] = useState(false);
  const [testCheck, setTestCheck] = useState(false);
  const [detail, setDetail] = useState(false);
  const [list, setList] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [createTest, setCreateTest] = useState(false);
  const [selectedTest, selectTest] = useState();
  const [testId, setTestId] = useState();

  const [createAssignment, setAssignment] = useState(false);
  const [selectedAssignment, selectAssignment] = useState();
  const [assignmentId, setAssignmentId] = useState();

  const [tests, getTests] = useApi();
  const [deleteState, deleteTest] = useApi();

  const [assignments, getAssignments] = useApi();
  const [deleteAsgRes, deleteAsg] = useApi();

  useEffect(() => {
    getAssignments(GET_TEACHER_ASSIGNMENT, 'GET');
    getTests(GET_TEACHER_TEST, 'GET');
  }, []);

  const handleDelete = (id) => {
    const x = confirm('Are you sure you want to delete?');
    if (x) deleteTest(DELETE_TEST(id?.class_id, id?.section_id, id?._id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteState.isLoading && !deleteState.isError && [200, 204].includes(deleteState.status)) {
      refreshList();
      alert('Successfully deleted');
    }

    if (!deleteState.isLoading && deleteState.isError) {
      alert('Error deleting');
    }
  }, [deleteState]);

  const refreshList = () => {
    let groupedItems = {};
    subjects.forEach((subject) => {
      groupedItems[subject.subjectId + subject.section._id] = [];
    });
    setList(groupedItems);
    getTests(GET_TEACHER_TEST, 'GET');
    getAssignments(GET_TEACHER_ASSIGNMENT, 'GET');
  };

  useEffect(() => {
    if (assignmentId) {
      setTestAssignMent(true);
    }
  }, [assignmentId]);

  useEffect(() => {
    if (testId) {
      setTestCheck(true);
    }
  }, [testId]);

  useEffect(() => {
    if (subjects.length > 0) {
      let groupedItems = {};
      subjects.forEach((subject) => {
        groupedItems[subject.subjectId + subject.section._id] = [];
      });
      setList(groupedItems);
    }
  }, [subjects]);

  useEffect(() => {
    if (
      !tests.isLoading &&
      !tests.isError &&
      tests.data &&
      subjects.length > 0 &&
      !assignments.isLoading &&
      !assignments.isError &&
      assignments.data
    ) {
      if (Array.isArray(assignments.data)) {
        assignments.data.forEach((assignment) => {
          if (Array.isArray(list[assignment.subject_id + assignment.section_id]))
            list[assignment.subject_id + assignment.section_id].push({ ...assignment, type: 'assignment' });
        });
        setList(list);
      }

      if (Array.isArray(tests.data)) {
        tests.data.forEach((test) => {
          if (Array.isArray(list[test.subject_id + test.section_id]))
            list[test.subject_id + test.section_id].push({ ...test, type: 'test' });
        });
        setList(list);
      }
    }
  }, [tests, subjects, assignments]);

  const handleDeleteAssignment = (id) => {
    const x = confirm('Are you sure you want to delete?');
    if (x) deleteAsg(DELETE_ASSIGNMENT(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteAsgRes.isLoading && !deleteAsgRes.isError && deleteAsgRes.status == 200) {
      refreshList();
      alert('Successfully deleted');
    }

    if (!deleteAsgRes.isLoading && deleteAsgRes.isError) {
      alert('Error deleting');
    }
  }, [deleteAsgRes]);

  // useEffect(() => {
  //   if (
  //     !assignments.isLoading &&
  //     !assignments.isError &&
  //     assignments.data &&
  //     Array.isArray(assignments.data) &&
  //     subjects.length > 0
  //   ) {
  //     assignments.data.forEach((assignment) => {
  //       if (Array.isArray(list[assignment.subject_id + assignment.section_id]))
  //         list[assignment.subject_id + assignment.section_id].push({ ...assignment, type: 'assignment' });
  //     });
  //     setList(list);
  //   }
  // }, [assignments, subjects]);

  return (
    <>
      {(createAssignment || selectedAssignment) && (
        <CreateAssignment
          setAssignment={setAssignment}
          refreshList={refreshList}
          selectedAssignment={selectedAssignment}
          selectAssignment={selectAssignment}
          subjectDetails={createAssignment}
        />
      )}

      {(createTest || selectedTest) && (
        <CreateTest
          setCreateTest={setCreateTest}
          refreshList={refreshList}
          selectedTest={selectedTest}
          selectTest={selectTest}
          subjectDetails={createTest}
          isEdit={isEdit}
        />
      )}

      <div className="relative w-full md:w-4/5 pb-10">
        {detail && (
          <div
            className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
            style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
          >
            <div className="bg-white w-11/12 md:w-7/12 px-2 text-sm block m-auto rounded-2xl relative">
              <span className="block font-bold p-4 text-textBlue1" style={{ borderBottom: '0.71px solid #E7E700' }}>
                Course Detail
              </span>
              <div className="w-full font-semibold">
                <button
                  type="submit"
                  className=" absolute right-14 top-2 px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                  // onClick={() => setModify(false)}
                >
                  Export Data
                </button>
              </div>
              <img
                src="assets/img/Close.svg"
                className="absolute top-5 right-8 h-3 cursor-pointer"
                alt=""
                onClick={() => setDetail(false)}
              />
              <form className="mt-2 mb-4 mx-4 flex flex-wrap" autoComplete="off">
                <div className="mt-2 w-full md:w-2.25/5 relative font-semibold disabled">
                  <label htmlFor="testType" className="block text-parameter text-sm text-gray-500">
                    Course Name
                  </label>
                  <input
                    type="text"
                    placeholder="Eg- Lession Name"
                    autoComplete="off"
                    // disabled
                    className="w-full h-10 rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="w-1/10" />
                <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
                  <label className="block text-parameter text-sm text-gray-500">Course Code</label>
                  <input
                    type="text"
                    placeholder="Ex- 06"
                    className="w-full h-10 rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                {/* <div className="w-1/10" /> */}
                <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
                  <label className="block text-parameter text-sm text-gray-500">Total Marks</label>
                  <input
                    type="text"
                    placeholder="Ex- 100"
                    className="w-full h-10 rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="w-1/10" />
                <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
                  <label className="block text-parameter text-sm text-gray-500">Total Assignment and Test</label>
                  <input
                    type="text"
                    placeholder="Ex- 100"
                    className="w-full h-10 rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="flex flex-wrap w-full h-1/4">
                  <div className="w-full py-4">
                    <div className="bg-white m-auto border rounded-3xl" style={{ borderColor: '#E7E700' }}>
                      <div className="w-full flex bg-tableHeader rounded-3xl">
                        <div className="flex w-full relative text-left px-4 text-xs font-bold">
                          <div className="w-2/12 border-r-2 py-2 px-2">Term</div>
                          <div className="w-3/12 border-r-2 py-2 px-2">Type</div>
                          <div className="w-3/12 border-r-2 py-2 px-2">Date</div>
                          <div className="w-2/12 border-r-2 py-2 px-2">Max. Marks</div>
                          <div className="w-2/12 border-r-2 py-2 px-2">Min. Marks</div>
                          <div className="w-3/12 py-2 px-2">Total Questions</div>
                        </div>
                      </div>
                      <div className="customScrollBar" style={{ overflowY: 'scroll', height: '35vh' }}>
                        <div className="w-full">
                          <div
                            className="justify-center flex w-full relative text-left py-6 px-4 text-xs font-medium items-center"
                            style={{ borderBottom: '0.50px solid #E7E700' }}
                          >
                            <div className="w-2/12 px-2">Term 1</div>
                            <div className="w-3/12 px-2">Assignment 1</div>
                            <div className="w-3/12 px-2">27 December 2021</div>
                            <div className="w-2/12 px-2">05</div>
                            <div className="w-2/12 px-2">02</div>
                            <div className="w-3/12 px-2">05</div>
                          </div>
                        </div>
                        <div className="w-full">
                          <div
                            className="justify-center flex w-full relative text-left py-6 px-4 text-xs font-medium items-center"
                            style={{ borderBottom: '0.50px solid #E7E700' }}
                          >
                            <div className="w-2/12 px-2">Term 1</div>
                            <div className="w-3/12 px-2">Assignment 1</div>
                            <div className="w-3/12 px-2">27 December 2021</div>
                            <div className="w-2/12 px-2">05</div>
                            <div className="w-2/12 px-2">02</div>
                            <div className="w-3/12 px-2">05</div>
                          </div>
                        </div>
                        <div className="w-full">
                          <div
                            className="justify-center flex w-full relative text-left py-6 px-4 text-xs font-medium items-center"
                            style={{ borderBottom: '0.50px solid #E7E700' }}
                          >
                            <div className="w-2/12 px-2">Term 1</div>
                            <div className="w-3/12 px-2">Assignment 1</div>
                            <div className="w-3/12 px-2">27 December 2021</div>
                            <div className="w-2/12 px-2">05</div>
                            <div className="w-2/12 px-2">02</div>
                            <div className="w-3/12 px-2">05</div>
                          </div>
                        </div>
                        <div className="w-full">
                          <div
                            className="justify-center flex w-full relative text-left py-6 px-4 text-xs font-medium items-center"
                            style={{ borderBottom: '0.50px solid #E7E700' }}
                          >
                            <div className="w-2/12 px-2">Term 1</div>
                            <div className="w-3/12 px-2">Assignment 1</div>
                            <div className="w-3/12 px-2">27 December 2021</div>
                            <div className="w-2/12 px-2">05</div>
                            <div className="w-2/12 px-2">02</div>
                            <div className="w-3/12 px-2">05</div>
                          </div>
                        </div>
                        <div className="w-full">
                          <div
                            className="justify-center flex w-full relative text-left py-6 px-4 text-xs font-medium items-center"
                            // style={{ borderBottom: '0.50px solid #E7E700' }}
                          >
                            <div className="w-2/12 px-2">Term 1</div>
                            <div className="w-3/12 px-2">Assignment 1</div>
                            <div className="w-3/12 px-2">27 December 2021</div>
                            <div className="w-2/12 px-2">05</div>
                            <div className="w-2/12 px-2">02</div>
                            <div className="w-3/12 px-2">05</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {!testAssignMent && !testCheck ? (
          subjects && subjects.length > 0 ? (
            subjects.map((y) => (
              <div className="w-full bg-white rounded-xl h-auto mb-5">
                <div className="items-center px-6 py-3 border-b border-borderYellow">
                  <div className="lg:flex block sm:block justify-between items-center">
                    <div className="font-bold w-4/12">
                      <p>{`${y.subjectName} (${y.class.name} - ${y.section.name})`}</p>
                    </div>
                    <div className=" w-full ml-2 flex justify-end">
                      <button
                        className="border px-6 py-2 rounded-3xl bg-primary text-xs  text-white  focus:outline-none flex justify-center text-center items-center"
                        onClick={() => setAssignment(y)}
                      >
                        Create Assignment
                      </button>

                      <button
                        className="border px-8 py-2 rounded-3xl bg-primary text-xs  text-white mx-4 focus:outline-none flex justify-center text-center items-center"
                        onClick={() =>
                          setCreateTest({
                            ...y,
                            createdExams: list[y.subjectId + y.section._id]
                              .filter((x) => x.type !== 'assignment' && x.test_type !== 'Class Test')
                              .map((x) => x.test_type),
                          })
                        }
                      >
                        Create Class Test
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full p-4">
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                    {assignments.isLoading || tests.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
                    ) : (
                      list[y.subjectId + y.section._id] &&
                      list[y.subjectId + y.section._id].map((x) =>
                        x.type === 'assignment' ? (
                          <div className="border rounded-2xl w-full border-borderYellow">
                            <div className="w-full flex bg-tableHeader rounded-3xl text-center items-center justify-center text-sm py-1">
                              <span>Assignment</span>
                            </div>
                            <div className="h-1/3">
                              <div className="w-full">
                                <div className="justify-center flex w-full relative text-left py-5 px-4 text-xs font-medium items-center border-b border-borderYellow">
                                  <div className="w-5/12 px-2 text-tableRowText">
                                    <span className="text-xxs">Submission Date</span>
                                    <p className="text-2xl  font-light">{moment(x.end).format('D/MM/yyyy')}</p>
                                  </div>

                                  <div className="w-4/12 text-tableRowText">
                                    <span className="text-xxs">Mode</span>
                                    <p className="text-2xl  font-light">Offline</p>
                                  </div>

                                  <div className="w-3/12 text-tableRowText">
                                    <span className="text-xxs">Max. Marks</span>
                                    <p className="text-2xl  font-light">{x?.marks}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full flex justify-end gap-x-2 px-2">
                                <button
                                  className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 text-xs focus:outline-none"
                                  onClick={() => handleDeleteAssignment(x._id)}
                                >
                                  Delete
                                </button>

                                <button
                                  className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 text-xs focus:outline-none"
                                  onClick={() => selectAssignment(x)}
                                >
                                  View Detail
                                </button>

                                {/* {moment(x.end).isAfter(new Date()) && ( */}
                                <button
                                  className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 text-xs focus:outline-none"
                                  onClick={() =>
                                    setAssignmentId({
                                      ...x,
                                      className: y.class.name,
                                      sectionName: y.section.name,
                                      subjectName: y.subjectName,
                                    })
                                  }
                                >
                                  Evaluate
                                </button>
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="border rounded-2xl w-full border-borderYellow">
                            <div className="w-full flex bg-tableHeader rounded-3xl text-center items-center justify-center text-sm py-1">
                              <span>{x.title} </span>
                            </div>
                            <div className="h-1/3">
                              <div className="w-full">
                                <div className="justify-center flex w-full relative text-left py-5 px-4 text-xs font-medium items-center border-b border-borderYellow">
                                  <div className="w-5/12 px-2 text-tableRowText">
                                    <span className="text-xxs">Date</span>
                                    <p className="text-2xl  font-light">{`${moment(x.test_date).format(
                                      'DD/M/yyyy'
                                    )}`}</p>
                                  </div>

                                  <div className="w-4/12 text-tableRowText">
                                    <span className="text-xxs">Mode</span>
                                    <p className="text-2xl  font-light">{x.mode}</p>
                                  </div>

                                  <div className="w-3/12 text-tableRowText">
                                    <span className="text-xxs">Max. Marks</span>
                                    <p className="text-2xl  font-light">{x?.maxMarks}</p>
                                  </div>
                                </div>
                              </div>
                              {x.mode === 'Online' ? (
                                <div className="w-full flex justify-end">
                                  <button
                                    className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 mx-2 text-xs focus:outline-none"
                                    onClick={() => {
                                      handleDelete(x);
                                    }}
                                  >
                                    Delete
                                  </button>

                                  {moment().isAfter(moment(x.end).local()) ? (
                                    <button
                                      className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 mx-2 text-xs focus:outline-none"
                                      onClick={() => setTestId(x)}
                                    >
                                      Evaluate
                                    </button>
                                  ) : (
                                    <button
                                      className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 mx-2 text-xs focus:outline-none"
                                      onClick={() => selectTest(x)}
                                    >
                                      View Detail
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <div className="w-full flex justify-end">
                                  <button
                                    className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 mx-2 text-xs focus:outline-none"
                                    onClick={() => {
                                      handleDelete(x);
                                    }}
                                  >
                                    Delete
                                  </button>

                                  <button
                                    className="border border-primary w-32 py-2 rounded-3xl text-primary my-2 mx-2 text-xs focus:outline-none"
                                    onClick={() => {
                                      selectTest(x);
                                      setEdit(true);
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
          )
        ) : null}

        {testAssignMent && (
          <AssignmentMarks
            assignmentId={assignmentId}
            setTestAssignMent={setTestAssignMent}
            setAssignmentId={setAssignmentId}
          />
        )}

        {testCheck && <TestMarks testId={testId} setTestCheck={setTestCheck} setTestId={setTestId} />}
      </div>
    </>
  );
};

export default AssignmentsAndTest;
