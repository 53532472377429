import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { CSVLink } from 'react-csv';

const ExcelExportBtn = forwardRef(
  ({ data, fileName, text, handleClick, headers, width = 'w-40', hidden, secondary }, ref) => {
    const childRef = useRef();

    useImperativeHandle(
      ref, // forwarded ref
      function () {
        return {
          click() {
            childRef.current.link.click();
          },
        }; // the forwarded ref value
      },
      []
    );

    return (
      <CSVLink
        className={`${width} border py-2 rounded-3xl text-xs ${
          secondary ? 'bg-white border-primary text-primary' : 'text-white bg-primary border-primary'
        } focus:outline-none flex justify-center items-center ${hidden && 'hidden'}`}
        data={data}
        filename={fileName}
        asyncOnClick={true}
        onClick={handleClick}
        headers={headers}
        ref={childRef}
      >
        {text || 'Export to Excel'}
      </CSVLink>
    );
  }
);

export default ExcelExportBtn;
