import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import {
  CLASS_FEE_STRUCTURE,
  CLASS_FEE,
  STUDENT_FEE,
  ADD_DISCOUNT,
  GET_FEE_STRUCTURE_FOR_STUDENT,
} from '../../../api/urls/finance';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import Search from '../../../components/UI/Search';
import { useStateValue } from '../../../ContextApi/StateProvider';
import { convertToNumber, getFeeCycleMonths } from '../../../utils';
import CreateFeeStructure from '../../../components/Finance/ClassWiseFeeStructure/CreateFeeStructure';
import FeeReceipt from '../../../components/Finance/ClassWiseFeeStructure/FeeReceipt';

const excelHeaders = [
  { label: 'Class', key: 'class' },
  { label: 'Section', key: 'section' },
  { label: 'Monthly Fee', key: 'monthlyFee' },
  { label: 'Late Fee', key: 'lateFee' },
];

const excelHeaders2 = [
  { label: 'Name', key: 'name' },
  { label: 'ROLEDin ID  ', key: 'roledinId' },
  { label: 'Monthly Fee', key: 'monthlyFee' },
  { label: 'Discount', key: 'discount' },
];

const ClasswiseFeeStructure = () => {
  const [{ classSections, user }, _] = useStateValue();

  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [fee, setFee] = useState([]);
  const [feeRecordData, setFeeRecord] = useState();
  const [selectedStudent, selectStudent] = useState();
  const [studentFee, setStudentFee] = useState([]);
  const [sort, setSort] = useState('All Classes');
  const [excelData, setExcelData] = useState([]);
  const [excelData2, setExcelData2] = useState([]);
  const [search, setSearch] = useState('');

  const [openFeeStructure, setOpenFeeStructure] = useState(false);
  const [openFeeReceipt, setOpenFeeReceipt] = useState(false);
  const [discount, setDiscount] = useState({ type: '', discount: '', inPercent: false });
  const [transportFee, setTransportFee] = useState();
  const [isChecked, setChecked] = useState(false);

  const [{ isLoading, isError, data }, getFee] = useApi();
  const [feeRecordRes, getFeeRecord] = useApi();
  const [studentFeeRes, getStudentFee] = useApi();
  const [addDiscountRes, addDiscount] = useApi();
  const [feeStructureRes, getFeeStructure] = useApi();

  let _classId = React.useRef();
  let _sectionId = React.useRef();

  useEffect(() => {
    getFee(CLASS_FEE_STRUCTURE, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setFee(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (classSections) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  const getData = (classId, sectionId) => {
    _classId.current = classId;
    _sectionId.current = sectionId;
    getFeeRecord(CLASS_FEE(classId, sectionId), 'GET');
  };

  useEffect(() => {
    if (!feeRecordRes.isLoading && !feeRecordRes.isError && feeRecordRes.data) {
      setFeeRecord(feeRecordRes.data);
    }
  }, [feeRecordRes]);

  useEffect(() => {
    if (selectedStudent) {
      getStudentFee(STUDENT_FEE(selectedStudent.student_id), 'GET');
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (!studentFeeRes.isLoading && !studentFeeRes.isError && studentFeeRes.data) {
      setStudentFee(studentFeeRes.data);
      if (studentFeeRes.data?.response?.userDetail?.discount) {
        setDiscount(studentFeeRes.data?.response?.userDetail?.discount);
        setChecked(studentFeeRes.data?.response?.userDetail?.discount?.inPercent);
      }
    }
  }, [studentFeeRes]);

  const handleCloseFeeStructure = (refresh = false) => {
    if (refresh) getFee(CLASS_FEE_STRUCTURE, 'GET');
    setOpenFeeStructure(false);
  };

  const handleCloseFeeReceipt = () => {
    setOpenFeeReceipt(false);
  };

  const handleSubmit = () => {
    const body = {};
    if (discount.discount) {
      body.discount = { ...discount, inPercent: isChecked };
    }
    if (transportFee) {
      body.transportFee = transportFee;
    }

    addDiscount(ADD_DISCOUNT(selectedStudent.student_id), 'POST', body);
  };

  useEffect(() => {
    if (!addDiscountRes.isLoading && !addDiscountRes.isError && addDiscountRes.status === 200) {
      alert(addDiscountRes.data);
      selectStudent(null);
      getFeeRecord(CLASS_FEE(_classId.current, _sectionId.current), 'GET');
    }

    if (!addDiscountRes.isLoading && addDiscountRes.isError) {
      alert(addDiscountRes.data);
    }
  }, [addDiscountRes]);

  const getStudentFeeStructure = (id) => {
    getFeeStructure(GET_FEE_STRUCTURE_FOR_STUDENT(id), 'GET');
  };

  useEffect(() => {
    if (!feeStructureRes.isLoading && !feeStructureRes.isError && feeStructureRes.data) {
      setOpenFeeReceipt({ receipt: feeStructureRes?.data, role: 'Parent' });
    }
  }, [feeStructureRes]);

  useEffect(() => {
    if (fee) {
      let _excel = fee.map((x) => {
        return {
          class: x?.className,
          section: x?.sectionName,
          monthlyFee: x?.monthlyFee ? '\u20B9' + x.monthlyFee : 'Not Added',
          lateFee: x?.lateFee === 'Added' ? '\u20B9' + x?.receipt?.lateFee?.lateFee : 'Not Added',
        };
      });

      if (Array.isArray(_excel)) setExcelData(_excel);
    }
  }, [fee]);

  useEffect(() => {
    if (feeRecordData?.fees) {
      let _excel = feeRecordData.fees.map((x) => {
        let _monthlyFee = 0;
        if (x?.fees && x?.fees?.monthlyFee) _monthlyFee = x?.fees?.monthlyFee.reduce((a, c) => (a += +c.amount), 0);

        if (x?.discountAdded) {
          if (x?.discountAdded?.inPercent) {
            _monthlyFee = _monthlyFee - (_monthlyFee * +x?.discountAdded?.discount) / 100;
          } else {
            _monthlyFee = _monthlyFee - +x?.discountAdded?.discount;
          }
        }
        return {
          name: x?.name,
          roledinId: x?.userName,
          monthlyFee: '\u20B9' + _monthlyFee,
          discount: x?.discountAdded
            ? x?.discountAdded?.inPercent
              ? x?.discountAdded?.discount + ' %'
              : '\u20B9' + ' ' + x?.discountAdded?.discount
            : '-',
        };
      });

      if (Array.isArray(_excel)) setExcelData2(_excel);
    }
  }, [feeRecordData]);

  let filteredData = fee || [];

  if (sort) {
    if (sort === 'Ascending Order') {
      filteredData = data.sort((a, b) =>
        convertToNumber(a.className.toLowerCase()) > convertToNumber(b.className.toLowerCase()) ? 1 : -1
      );
    } else if (sort === 'Descending Order') {
      filteredData = data.sort((a, b) =>
        convertToNumber(a.className.toLowerCase()) < convertToNumber(b.className.toLowerCase()) ? 1 : -1
      );
    } else if (sort === 'Fee Added') {
      filteredData = data.filter((x) => x.monthlyFee > 0);
    } else if (sort === 'Fee Not Added') {
      filteredData = data.filter((x) => x.monthlyFee === 0);
    } else {
      filteredData = data;
    }
  }

  let _studentList = feeRecordData?.fees || [];

  if (search) {
    _studentList = _studentList.filter((x) => x?.name.toLowerCase().includes(search.toLowerCase()));
  }
  return (
    <>
      {selectedStudent && (
        <div
          className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-10/12 text-sm block m-auto mt-10 rounded-2xl relative pb-6">
            <div
              className="flex w-full justify-between items-center relative"
              style={{ borderBottom: '0.71px solid #E7E700' }}
            >
              <p className="block font-bold py-4 px-6 text-black">{studentFee?.response?.userDetail?.userName}</p>
              <button
                className="border px-8 py-2 mr-16 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                onClick={() => {}}
              >
                Export Data
              </button>
              <img
                src="assets/img/Close.svg"
                className="absolute right-4 h-3 cursor-pointer z-50"
                alt=""
                onClick={() => selectStudent(null)}
              />
            </div>
            <div style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className=" pt-4 px-6 font-bold text-black">Student Details</p>
              <div className="flex flex-wrap px-6 py-2">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">Name</label>
                  <p className="w-full text-textDark rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.userName}
                  </p>
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">ROLEDin ID</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.roledId}
                  </p>
                </div>
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-textDark text-sm font-medium">Class</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs">
                    {studentFee?.response?.userDetail?.class}
                  </p>
                </div>
              </div>
            </div>

            <div style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className=" pt-4 px-6 font-bold">Discount Details</p>
              <div className="flex flex-wrap px-6 py-2">
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="block text-sm text-textDark font-medium">Discount Type</label>
                  <input
                    placeholder="Enter Type"
                    className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                    type="text"
                    value={discount.type}
                    onChange={(e) => setDiscount({ ...discount, type: e.target.value })}
                  />
                </div>
                <div className="w-1/10" />
                <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                  <label className="text-sm text-textDark font-medium flex justify-between items-center">
                    <span>Discount</span>
                    <div>
                      <input
                        type="checkbox"
                        className="mr-2 mt-1"
                        checked={isChecked}
                        onChange={() => setChecked((prev) => !prev)}
                      />
                      In Percentage
                    </div>
                  </label>
                  <input
                    placeholder="Enter Amount"
                    className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                    type="number"
                    value={discount.discount}
                    onChange={(e) => {
                      let inputValue = e.target.value || '';
                      if (e.target.value.startsWith('-')) {
                        inputValue = e.target.value.slice(1);
                      }
                      setDiscount({ ...discount, discount: inputValue });
                    }}
                  />
                </div>
              </div>
            </div>

            {user?.institute?.transportFee === 'Variable' && (
              <div style={{ borderBottom: '0.71px solid #E7E700' }}>
                <p className=" pt-4 px-6 font-bold">Transport Fee</p>
                <div className="flex flex-wrap px-6 py-2">
                  <div className="my-2 relative font-semibold w-full md:w-2.25/5">
                    <label className="block text-sm text-textDark font-medium">Transport Fee</label>
                    <input
                      placeholder="Enter Amount"
                      className="w-full rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none text-xs"
                      type="number"
                      onChange={(e) => setTransportFee(e.target.value)}
                    />
                  </div>
                  <div className="w-1/10" />
                </div>
              </div>
            )}

            <div className="w-full">
              <p className="px-6 font-bold py-4">Fee Payment History</p>
              <div className=" flex w-full overflow-auto">
                <div
                  className="m-auto border rounded-2xl"
                  style={{ borderColor: '#E7E700', width: '96%', minWidth: 500 }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-4 py-1">
                      <div className="w-2/12 border-r-2 py-1 px-2">Quarter</div>
                      <div className="w-3/12 border-r-2 py-1 px-2">Month</div>
                      <div className="w-3/12 border-r-2 py-1 px-2">Mode Of Payment</div>
                      <div className="w-3/12 py-1 px-2">Transaction ID</div>
                      <div className="w-2/12 py-1 px-2">Fee Amount</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '30vh' }}>
                    {studentFee &&
                      Array.isArray(studentFee?.response?.paymentHistory) &&
                      studentFee?.response?.paymentHistory.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-3.5 px-4 text-xs font-medium ${
                              studentFee?.response?.paymentHistory.length - 1 !== idx && 'border-b border-tableHeader'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{'-'}</div>
                            <div className="w-3/12 px-2 text-tableRowText">{x?.month}</div>
                            <div className="w-3/12 px-2 text-tableRowText">{x?.mode}</div>
                            <div className="w-3/12 px-2 text-tableRowText">{'-'}</div>
                            <div className="w-2/12 px-2 text-tableRowText">&#x20b9; {x?.amount}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end pr-6 mt-6">
              {addDiscountRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <button
                  onClick={handleSubmit}
                  className="w-36 border border-primary bg-primary text-white px-4 py-2 rounded-3xl text-xs focus:outline-none"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {openFeeStructure && (
        <CreateFeeStructure handleClose={handleCloseFeeStructure} selectedClass={openFeeStructure} />
      )}
      {openFeeReceipt && <FeeReceipt handleClose={handleCloseFeeReceipt} fees={openFeeReceipt} />}

      <div className="w-full md:w-4/5">
        <div className="relative w-full pb-10">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <div className="lg:flex block sm:block  items-center">
                <div className="font-bold text-base">
                  <p>Fee Structure</p>
                </div>
                <div className="flex ml-auto gap-x-4">
                  <ExcelExportBtn
                    data={excelData}
                    text={'Export Data'}
                    width={'w-36'}
                    fileName={'FeeStructure.csv'}
                    headers={excelHeaders}
                  />

                  <DropDown
                    data={['All Classes', 'Ascending Order', 'Descending Order', 'Fee Added', 'Fee Not Added']}
                    width={'w-36'}
                    title={'Sort By'}
                    handleItemClick={(x) => {
                      setSort(x);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl min-w-700"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-2">
                      <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Section</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Monthly Fee</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Late Fee</div>
                      <div className="w-2/12 border-r-2 py-2 px-2">Receipts</div>
                      <div className="w-2/12 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData?.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                              filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{x?.className}</div>
                            <div className="w-2/12 px-2 text-tableRowText">{x?.sectionName}</div>
                            <div className="w-2/12 px-2 text-tableRowText">
                              {x?.monthlyFee ? '\u20B9' + ' ' + x?.monthlyFee : 'Not Added'}
                            </div>
                            <div className="w-2/12 px-2 text-tableRowText">{x?.lateFee}</div>
                            <div className="w-2/12 px-2 text-tableRowText">
                              {x?.receipt ? (
                                <button
                                  className="w-9/12 border border-borderLight text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                  onClick={() => {
                                    setOpenFeeReceipt(x);
                                  }}
                                >
                                  View
                                </button>
                              ) : (
                                'Not Available'
                              )}
                            </div>

                            <div className="w-2/12 flex justify-center items-center">
                              {!x?.receipt ? (
                                <button
                                  className="w-9/12 border border-primary text-white bg-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                  onClick={() => {
                                    setOpenFeeStructure(x);
                                  }}
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  className="w-9/12 border border-borderLight text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                                  onClick={() => {
                                    setOpenFeeStructure(x);
                                  }}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full bg-white rounded-xl h-auto mt-4">
            <div className="items-center px-6 pt-3 pb-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <div className="lg:flex block sm:block  items-center">
                <div className="font-bold text-base">
                  <p>Class Wise Student List</p>
                </div>
                <div className="flex ml-auto gap-x-4">
                  <ExcelExportBtn
                    data={excelData2}
                    text={'Export Data'}
                    width={'w-36'}
                    fileName={'classFee.csv'}
                    headers={excelHeaders2}
                  />

                  <Search
                    placeholder={'Search'}
                    width={'w-36'}
                    onChange={(x) => setSearch(x)}
                    onEnter={() => console.log('enter')}
                  />

                  <DropDown
                    field={'name'}
                    handleItemClick={(x) => {
                      getData(x.classId, x.sectionId);
                    }}
                    title={'Select Class'}
                    data={combinedClassSection}
                    width={'w-36'}
                    type={'obj'}
                  />
                </div>
              </div>
            </div>

            {/* {feeRecordData ? ( */}
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl min-w-700"
                  style={{ borderColor: '#E7E700', width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-xs font-medium px-2">
                      <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                      <div className="w-1/6 border-r-2 py-2 px-2">ROLEDin ID</div>
                      <div className="w-1/6 border-r-2 py-2 px-2">Monthly Fee</div>
                      <div className="w-1/6 border-r-2 py-2 px-2">Discount</div>
                      <div className="w-1/6 border-r-2 py-2 px-2">Add Discount</div>
                      <div className="w-1/6 py-2 px-2">View Details</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                    {feeRecordRes.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : feeRecordData ? (
                      Array.isArray(_studentList) && _studentList.length > 0 ? (
                        _studentList.map((x, idx) => {
                          let _monthlyFee = 0;
                          if (x?.fees && x?.fees?.monthlyFee)
                            _monthlyFee = x?.fees?.monthlyFee.reduce((a, c) => (a += +c.amount), 0);

                          if (x?.discountAdded) {
                            if (x?.discountAdded?.inPercent) {
                              _monthlyFee = _monthlyFee - (_monthlyFee * +x?.discountAdded?.discount) / 100;
                            } else {
                              _monthlyFee = _monthlyFee - +x?.discountAdded?.discount;
                            }
                          }
                          return (
                            <div className="w-full">
                              <div
                                className={`items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                                  feeRecordData.fees.length - 1 !== idx && 'border-b border-borderYellow'
                                }`}
                              >
                                <div className="w-1/6 px-2 text-tableRowText">{x?.name}</div>
                                <div className="w-1/6 px-2">{x?.userName}</div>
                                <div className="w-1/6 px-2 text-tableRowText">{'\u20B9' + ' ' + _monthlyFee}</div>
                                <div className="w-1/6 px-2">
                                  {x?.discountAdded
                                    ? x?.discountAdded?.inPercent
                                      ? x?.discountAdded?.discount + ' %'
                                      : '\u20B9' + ' ' + x?.discountAdded?.discount
                                    : '-'}
                                </div>
                                <div className="w-1/6 flex justify-center items-center pr-3">
                                  {x?.discountAdded ? (
                                    <button
                                      className="w-9/12 border border-primary text-primary bg-white py-2 rounded-3xl text-xs focus:outline-none"
                                      onClick={() => {
                                        selectStudent(x);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  ) : (
                                    <button
                                      className="w-9/12 border border-primary text-white bg-primary py-2 rounded-3xl text-xs focus:outline-none"
                                      onClick={() => {
                                        selectStudent(x);
                                      }}
                                    >
                                      Add
                                    </button>
                                  )}
                                </div>
                                <div className="w-1/6 flex justify-center items-center pr-3">
                                  <button
                                    className="w-9/12 border border-borderLight text-tableRowText py-2 rounded-3xl text-xs focus:outline-none"
                                    onClick={() => {
                                      getStudentFeeStructure(x?.student_id);
                                    }}
                                  >
                                    View
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                          No Data Available
                        </p>
                      )
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        Please select class from dropdown
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* ) : (
              <p className="text-center text-lg m-10 pb-10">Please select class from dropdown</p>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ClasswiseFeeStructure;
