import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { STUDENT_FEE, FEE_CYCLE, GET_FEE_STRUCTURE_FOR_STUDENT } from '../../../api/urls/finance';
import { useStateValue } from '../../../ContextApi/StateProvider';
import DropDown from '../../../components/UI/DropDown';
import { MONTHS } from '../../../constants';
import FeeReceipt from '../../../components/Finance/ClassWiseFeeStructure/FeeReceipt';
import Receipt from '../../../components/Finance/Fee/Receipt';
var converter = require('number-to-words');

function Fee({ role }) {
  const [{ user }, _] = useStateValue();

  const feeUpload = useRef(_);
  const lateFeeRef = useRef(0);
  const totalAmountRef = useRef(0);

  const formDataModel = {
    student: '',
    fee_id: '',
    fee_cycle: '',
    fatherName: '',
    payment: '', //mode
    total_fee: '',
    breakdown: [],
    feeUpload: null,
    name: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [feeCycle, setFeeCycle] = useState([]);
  const [reset, setReset] = useState(0); //to reset fields
  const [selectedFee, selectFee] = useState();
  const [openFeeReceipt, setOpenFeeReceipt] = useState(false);
  const [months, setMonths] = useState();
  const [details, setDetails] = useState();

  const [uploadFeeRes, UploadFee] = useApi();
  const [historyState, getFeeHistory] = useApi();
  const [feeCycleRes, getFeeCycle] = useApi();
  const [feeStructureRes, getFeeStructure] = useApi();

  useEffect(() => {
    if (user) {
      let id;
      if (role === 'student') {
        id = user.user._id;
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo;
        id = childInfo?._id;
      }
      getFeeHistory(STUDENT_FEE(id), 'GET');
      // getFeeCycle(FEE_CYCLE, 'GET');
      getFeeStructure(GET_FEE_STRUCTURE_FOR_STUDENT(id), 'GET');
    }
  }, [user]);

  // useEffect(() => {
  //   if (!feeCycleRes.isLoading && !feeCycleRes.isError && feeCycleRes.data) {
  //     setFeeCycle(feeCycleRes.data);
  //   }
  // }, [feeCycleRes]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!months || months.length == 0) {
      alert('Please Select Month');
      return;
    }
    if (!formData.payment) {
      alert('Please Select Payment method');
      return;
    }

    if (formData.payment === 'Challan' && !formData.feeUpload) {
      alert('Please attach Challan');
      return;
    }
    const fd = new FormData();
    // fd.append('fee_id', formData.fee_id);
    // fd.append('fee_cycle', formData.fee_cycle);
    // fd.append(
    //   'amount',
    //   formData.breakdown.reduce((acc, cuu) => (acc += +cuu.value), 0)
    //   );
    //   fd.append('breakdown', JSON.stringify(formData.breakdown));

    fd.append('month', months);
    fd.append(
      'fatherName',
      role !== 'student' ? user?.user?.full_name : user?.user?.parentDetail.find((x) => x.father_name)?.father_name
    );
    fd.append('date', new Date());
    fd.append('payment', formData.payment);
    fd.append('amount', totalAmountRef?.current?.value.substring(1));
    fd.append('lateFee', lateFeeRef?.current?.value.substring(1));
    fd.set('feeUpload', formData.feeUpload);

    let id;

    if (role === 'student') {
      id = user.user._id;
    } else {
      const childInfo = user?.Responsbility[0]?.childInfo;
      id = childInfo._id;
    }
    UploadFee(STUDENT_FEE(id), 'POST', fd);
  };

  useEffect(() => {
    if (!uploadFeeRes.isLoading && !uploadFeeRes.isError && [201, 202, 200, 204].includes(uploadFeeRes.status)) {
      let id;
      if (role === 'student') {
        id = user.user._id;
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo;
        id = childInfo?._id;
      }
      getFeeHistory(STUDENT_FEE(id), 'GET');
      alert('SUCCESS');
      setFormData({ ...formDataModel });
      setReset((prev) => (prev += 1));
      setMonths([]);
    }

    if (uploadFeeRes.isError) {
      alert('FAILED');
    }
  }, [uploadFeeRes]);

  const handleCloseFeeReceipt = () => {
    setOpenFeeReceipt(false);
  };

  let amount = 0;
  const feeStructure = feeStructureRes?.data;
  if (feeStructure && months) {
    // months.forEach((month) => {
    amount += feeStructure?.monthFee || 0;
    let _examFee = feeStructure?.examFee.filter((item) => item.month === months);
    if (_examFee.length > 0) {
      _examFee.forEach((x) => {
        amount += +x.fee;
      });
    }
    let _annualFee = feeStructure?.annualFee.filter((item) => item.month === months && item.fee);

    if (_annualFee.length > 0) {
      _annualFee.forEach((x) => {
        x.fee.forEach((y) => {
          amount += +y.amount;
        });
      });
    }

    const transportFee = feeStructureRes?.data?.transportFee || 0;

    amount += transportFee;
    // });
  }

  const paidMonths = historyState?.data?.response?.paymentHistory
    ? historyState?.data?.response?.paymentHistory
        ?.filter((x) => x?.status?.toLowerCase() === 'approved' || x?.status?.toLowerCase() === 'pending')
        .map((x) => x.month)
    : '';

  let lateFee = 0;
  if (!paidMonths.includes(MONTHS[new Date().getMonth()])) {
    if (feeStructureRes?.data?.lateFee) {
      if (feeStructureRes?.data?.lateFee?.basedOn === 'Per Day') {
        let startOn = feeStructureRes?.data?.lateFee?.startOn;
        startOn = startOn.split(' ')[1][0];
        let currentDate = new Date().getDate();
        const diff = currentDate - startOn;
        if (diff > 0) {
          lateFee = +feeStructureRes?.data?.lateFee?.lateFee * diff;
        }
      } else {
        let startOn = feeStructureRes?.data?.lateFee?.startOn;
        startOn = startOn.split(' ')[1][0] - 1;
        let currentDate = Math.ceil(new Date().getDate() / 7);
        const diff = currentDate - startOn;
        if (diff > 0) {
          lateFee = +feeStructureRes?.data?.lateFee?.lateFee * diff;
        }
      }
    }
  }

  const totalAmount = amount + lateFee;
  if (lateFeeRef?.current) lateFeeRef.current.value = '\u20B9' + lateFee;
  if (totalAmountRef?.current) totalAmountRef.current.value = '\u20B9' + totalAmount;

  let studentClass;
  if (user?.Responsbility.length > 0) {
    let classInfo =
      user.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo ||
      user.Responsbility[0]?.studentInfo?.currentlyMapped?.classInfo;
    studentClass = `${classInfo.className} - ${classInfo.sectionName}`;
  }

  return (
    <>
      {selectedFee && (
        <div
          className="flex justify-center items-center h-auto min-h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-2/5 text-sm block m-auto mt-10 rounded-2xl relative">
            <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
              <span className="block font-bold text-textBlue1 ">Challan </span>
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={() => selectFee(null)} />
            </div>

            {selectedFee?.files && selectedFee?.files.length > 0 && (
              <div className="p-10 ">
                <img src={selectedFee?.files[0].location} alt="" className="h-65 w-full border rounded-md p-6" />
              </div>
            )}
          </div>
        </div>
      )}

      {openFeeReceipt && <FeeReceipt handleClose={handleCloseFeeReceipt} fees={openFeeReceipt} />}

      {details && (
        <Receipt
          printContent={details}
          onClose={() => {
            setDetails(null);
          }}
          showPrint={false}
        />
      )}

      <div className="w-full md:w-4/5">
        {user?._instituteFeeStructure?.feeStructure.some((x) => ['Challan', 'Online'].includes(x)) && (
          <div className=" bg-white rounded-xl h-auto mb-5 w-full">
            <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
              <p className="font-bold">Fee Payment</p>
            </div>
            <form className="mt-2 mb-4 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
              <div className="gap-x-6 grid grid-cols-1 md:grid-cols-2 w-full border-b border-tableHeader px-6">
                <div className="mt-2 w-full font-semibold">
                  <label className="block text-parameter text-xs">Student Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    value={feeStructureRes?.data?.student?.full_name}
                    disabled
                    // onChange={(e) => setFormData({ ...formData, student: e.target.value })}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="mt-2 mb-4 w-full font-semibold ">
                  <label className="block text-parameter text-xs">Father's Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    value={
                      user?.user?.roleType === 'st'
                        ? user?.user?.parentDetail.find((x) => x.father_name)?.father_name
                        : user?.user?.full_name
                    }
                    disabled
                    // onChange={(e) => setFormData({ ...formData, fatherName: e.target.value })}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="mt-1 mb-4 w-full font-semibold ">
                  <label className="block text-parameter text-xs">Class</label>
                  <input
                    value={studentClass}
                    disabled
                    // onChange={(e) => setFormData({ ...formData, fatherName: e.target.value })}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <div className="px-6 mt-4 w-full">
                <p className="font-bold mb-1">Fee Payment</p>
                <label className="text-textDark font-medium mb-3 text-xs">Fee Month</label>
                <div className="mt-2 mb-4 w-full relative font-semibold grid grid-cols-5">
                  {MONTHS.map((x) => (
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={months === x}
                        onChange={() => {
                          // if (months.includes(x)) setMonths(months.filter((_x) => _x !== x));
                          // else setMonths([...months, x]);
                          if (months === x) setMonths();
                          else setMonths(x);
                        }}
                        className="mr-2"
                        disabled={paidMonths.includes(x)}
                      />
                      <span className="text-tableRowText ml-1 text-xs font-semibold" style={{ color: '#212121B8' }}>
                        {x}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="gap-x-6 grid grid-cols-1 md:grid-cols-2 w-full px-6 border-b border-tableHeader">
                <div className="mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-xs">Total Amount</label>
                  <div className="relative flex">
                    <input
                      // required
                      type="text"
                      disabled
                      value={'\u20B9' + (amount || 0)}
                      className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                    <button
                      className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenFeeReceipt({ receipt: feeStructureRes?.data, role: 'Parent' });
                      }}
                    >
                      View Fee Breakup
                    </button>
                  </div>
                </div>

                <div className=" mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-xs">Amount (in words)</label>
                  <input
                    type="text"
                    disabled
                    value={converter.toWords(amount)}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="mt-1 mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-xs">Late Fee</label>
                  <input
                    // type="number"
                    ref={lateFeeRef}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                    disabled
                    // value={lateFee || 0}
                    key={reset}
                  />
                </div>

                <div className="mt-1 mb-4 w-full relative font-semibold ">
                  <label className="block text-parameter text-xs">Total Amount (Total fee + Late Fee)</label>
                  <input
                    // type="number"
                    ref={totalAmountRef}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                    // value={totalAmount}
                    disabled
                    key={reset}
                  />
                </div>
              </div>

              <div className="gap-x-6 flex flex-col md:flex-row w-full px-6 mt-4">
                <div className="mt-1 mb-4 relative font-semibold w-full">
                  <label className="block text-parameter text-xs">Payment Method</label>
                  <DropDown
                    title={'Select'}
                    handleItemClick={(x) => {
                      setFormData({ ...formData, payment: x === 'Challan/Checque/Bank Draft' ? 'Challan' : x });
                    }}
                    margin={'mt-2'}
                    data={['Challan/Checque/Bank Draft']}
                    otherClasses={'border-borderLight'}
                    key={reset}
                  />
                </div>

                <div className="my-1 w-full relative font-semibold">
                  {formData.payment === 'Challan' && (
                    <>
                      <label className="block text-parameter text-xs">Upload Challan</label>
                      <div className="relative flex">
                        <input
                          hidden
                          ref={feeUpload}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          key={reset}
                          onChange={(e) => setFormData({ ...formData, feeUpload: e.target.files[0] })}
                          className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                        />
                        <input
                          // required
                          type="text"
                          disabled
                          value={formData.feeUpload?.name}
                          className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                        />
                        <button
                          className="w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                          onClick={(e) => {
                            e.preventDefault();
                            feeUpload.current.click();
                          }}
                        >
                          Upload
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex justify-end w-full mb-6 mt-3 mr-6">
                {/* <button
                className="mt-1 mr-8 lg:mt-0 w-auto lg:w-max border border-gray-400 px-12 py-4 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center h-7"
                onClick={() => {
                  setReset((prev) => (prev += 1));
                  setFormData({ ...formDataModel });
                }}
              >
                Cancel
              </button> */}
                {uploadFeeRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <button
                    type="submit"
                    className="mt-1 border w-36 py-2 rounded-3xl text-xs focus:outline-none bg-primary text-white"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        )}

        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Fee Payment History</p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl min-w-700"
                style={{ borderColor: '#E7E700', width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-xs font-medium px-2">
                    <div className="w-1/6 border-r-2 py-2 px-2">Date of Payment</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Fee Month</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Fee Deposited</div>
                    <div className="w-1/6 border-r-2 py-2 px-2"> Received By</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Fee Pending</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Status</div>
                    <div className="w-1/6 py-2 px-2">More Details</div>
                  </div>
                </div>
                <div className="overflow-auto">
                  {historyState.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : (
                    historyState?.data &&
                    historyState?.data.response &&
                    historyState?.data.response.paymentHistory &&
                    Array.isArray(historyState?.data.response.paymentHistory) &&
                    historyState?.data.response.paymentHistory.map((x, idx) => (
                      <div
                        className={`w-full ${
                          idx !== historyState?.data.response.paymentHistory.length - 1 && 'border-b border-tableHeader'
                        }`}
                      >
                        <div className="items-center flex w-full relative py-2 px-3 text-xs font-medium">
                          <div className="w-1/6 text-tableRowText px-2">{moment(x.date).format('ll')}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.month}</div>
                          <div className="w-1/6 text-tableRowText px-2">&#x20b9; {x.amount || 0}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.paidBy?.full_name || '--'}</div>
                          <div className="w-1/6 text-tableRowText px-2">&#x20b9; {x?.balance}</div>
                          <div
                            className={`w-1/6 capitalize px-2 ${
                              x?.status === 'approved'
                                ? 'text-textGreen'
                                : x?.status === 'rejected'
                                ? 'text-textRed'
                                : 'text-tableRowText'
                            } `}
                          >
                            {' '}
                            {x?.status === 'approved' ? 'Received' : x?.status || '--'}
                          </div>
                          <div className="w-1/6 px-2">
                            <button
                              type="submit"
                              className="border border-primary px-2 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center w-11/12"
                              onClick={() => {
                                setDetails({
                                  fee: x,
                                  class: {
                                    name: historyState?.data.response?.userDetail?.class,
                                  },
                                  student: {
                                    full_name: historyState?.data.response?.userDetail?.userName,
                                    username: historyState?.data.response?.userDetail?.roledId,
                                  },
                                });
                              }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fee;
