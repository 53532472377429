import React, { useState, useEffect } from 'react';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import DropDown from '../../UI/DropDown';
import { convertToNumber } from '../../../utils';
import { useStateValue } from '../../../ContextApi/StateProvider';

const excelHeaders = [
  { label: 'Class', key: 'class' },
  { label: 'Section', key: 'section' },
  { label: 'Students', key: 'students' },
  { label: 'Estimated Fee Collection', key: 'fees' },
  { label: 'Fee Collected', key: 'feeCollected' },
  { label: 'Total Student(Pending Fee)', key: 'studentPending' },
  { label: 'Pending Fee Amount', key: 'feePending' },
];

function QuaterlyFeeCollection({ data, isLoading }) {
  const [excelData, setExcelData] = useState([]);
  const [sort, setSort] = useState();
  const [{ user }, _] = useStateValue();

  useEffect(() => {
    if (data) {
      let x = data.map((x) => {
        return {
          class: x.class,
          section: x.section,
          students: x.totalStudent,
          fees: '\u20B9' + x?.totalestimation,
          feeCollected: '\u20B9' + x?.collectedpayment,
          studentPending: x?.pendingFeeStudennt,
          feePending: '\u20B9' + x?.pendingFeeAmount,
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  let filteredData = data;

  if (sort) {
    if (sort === 'Descending Order') {
      filteredData = data.sort((a, b) => {
        const numA = convertToNumber(a.class);
        const numB = convertToNumber(b.class);
        if (numA < numB) {
          return 1;
        }
        if (numA > numB) {
          return -1;
        }
        return 0;
      });
    }
    if (sort === 'Ascending Order') {
      filteredData = data.sort((a, b) => {
        const numA = convertToNumber(a.class);
        const numB = convertToNumber(b.class);
        if (numA < numB) {
          return -1;
        }
        if (numA > numB) {
          return 1;
        }
        return 0;
      });
    }
  }

  const getTitle = () => {
    let financeCycle = user?.institute?.financeCycle || 1;

    if (financeCycle === 1) return 'Monthly Fee Collection Report';
    else if (financeCycle === 3) return 'Quarterly Fee Collection Report';
    else if (financeCycle === 6) return 'Half Yearly Fee Collection Report';
    else if (financeCycle === 12) return 'Yearly Fee Collection Report';
  };

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block items-center">
          <div className="font-bold text-base">
            <p>{getTitle()}</p>
          </div>
          <div className="flex ml-auto">
            <ExcelExportBtn
              data={excelData}
              text={'Export Data'}
              width={'w-36'}
              fileName={'Report.csv'}
              headers={excelHeaders}
            />
            <DropDown
              data={['Ascending Order', 'Descending Order']}
              width={'w-36'}
              margin={'ml-4'}
              title={'Sort by'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border border-borderYellow rounded-2xl overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-b-2xl">
              <div className="flex w-full relative text-xs  font-medium px-2">
                <div className="w-1/12 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/12 border-r-2 py-2 px-2">Section</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Total Students</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Estimated Fee Collection</div>
                <div className="w-2/12 py-2 px-2 border-r-2">Fee Collected</div>
                <div className="w-3/12 py-2 px-2 border-r-2">Total Student(Pending Fee)</div>
                <div className="w-3/12 py-2 px-2">Pending Fee Amount</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div key={x.section_id} className="w-full">
                    <div
                      className={`items-center flex w-full relative py-2 text-xs font-medium pl-3 ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/12 py-2.5 px-2 text-tableRowText">{x?.class}</div>
                      <div className="w-1/12 py-2.5 px-2 text-tableRowText">{x?.section}</div>
                      <div className="w-2/12 py-2.5 px-2 text-tableRowText">{x?.totalStudent}</div>
                      <div className="w-3/12 py-2.5 px-2 text-tableRowText">&#8377;{x?.totalestimation}</div>
                      <div className="w-2/12 py-2.5 px-2 text-textDark">&#8377;{x?.collectedpayment}</div>
                      <div className="w-3/12 py-2.5 px-2 text-textDark">{x?.pendingFeeStudennt}</div>
                      <div className="w-3/12 py-2.5 px-2 text-textDark">&#8377;{x?.pendingFeeAmount}</div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuaterlyFeeCollection;
