import React, { useState, useEffect } from 'react';
import { UPDATE_INCIDENT } from '../../../api/urls/incident';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';
import DropDown from '../..//UI/DropDown';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

function ViewIncident({ handleClose, role, selectedIncident, combinedClassSection, refreshList }) {
  const formDataModel = {
    class: '',
    section: '',
    student: '',
    incident: '',
    action: '',
    date: '',
    time: null,
  };

  const [formData, setFormData] = useState(formDataModel);
  const [isEdit, setEdit] = useState(false);
  const [selectedStudent, selectStudent] = useState();
  const [studentList, setStudents] = useState([]);

  const [updateRes, updateIncident] = useApi();
  const [students, getStudents] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (selectedIncident) {
      setFormData(selectedIncident);
      if (role === 'teacher') {
        let x = combinedClassSection.filter((y) => y.name === selectedIncident.class)[0];
        setFormData({
          ...selectedIncident,
          class: x.classId,
          section: x.sectionId,
          date: getDate(selectedIncident.date),
          time: moment(selectedIncident.date),
        });
        getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
      }
    }
  }, [selectedIncident]);

  const handleReset = () => {
    let x = combinedClassSection.filter((y) => y.name === selectedIncident.class)[0];
    setFormData({
      ...selectedIncident,
      class: x.classId,
      section: x.sectionId,
      date: getDate(selectedIncident.date),
      time: moment(selectedIncident.date),
    });
    getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');

    setEdit(false);
  };

  const getTime = (t) => {
    let x = new Date(t);

    return `${x.getHours()}:${x.getMinutes()}`;
  };

  const getDate = (t) => {
    let x = new Date(t);
    let month = x.getMonth() + 1;
    return `${x.getFullYear()}-${month < 10 ? '0' + month : month}-${x.getDate()}`;
  };

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
      if (selectedIncident) {
        let stu = students.data.filter((x) => x.full_name === selectedIncident.student);
        setFormData({
          ...formData,
          student: stu.length > 0 && stu[0]._id,
        });
      }
    }
  }, [students]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const _time = new Date(formData.time).toLocaleTimeString();

    const body = {
      ...formData,
      date: new Date(formData.date + ' ' + _time).toISOString(),
    };

    delete body.time;
    updateIncident(UPDATE_INCIDENT(selectedIncident._id), 'PUT', body);
  };

  useEffect(() => {
    if (!updateRes.isLoading && !updateRes.isError && [201, 202, 200, 204].includes(updateRes.status)) {
      refreshList();
      setFormData(formDataModel);
      alert('Success');
      handleClose();
    }

    if (!updateRes.isLoading && updateRes.isError) {
      alert('Failed');
    }
  }, [updateRes]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-2/3 pb-6 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">
            {selectedIncident?.student + ' - ' + selectedIncident?.studentId}
          </span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
        </div>
        <form className="mb-2 mt-4 px-6 " onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">Select Class</label>
              {isEdit ? (
                <DropDown
                  field={'name'}
                  handleItemClick={(x) => {
                    setFormData({
                      ...formData,
                      class: x.classId,
                      section: x.sectionId,
                    });
                    getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
                  }}
                  title={selectedIncident?.class || 'Select Class'}
                  data={combinedClassSection}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  type={'obj'}
                  // className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {selectedIncident?.class}
                </p>
              )}
            </div>

            <div></div>

            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">Student Name</label>
              {isEdit ? (
                <DropDown
                  field={'full_name'}
                  handleItemClick={(x) => {
                    selectStudent(x);
                    setFormData({
                      ...formData,
                      student: x._id,
                    });
                  }}
                  title={selectedIncident?.student || 'Select Student'}
                  data={studentList}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  type={'obj'}
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {selectedIncident?.student}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">ROLEDin ID</label>
              {isEdit ? (
                <input
                  type="text"
                  name="userName"
                  disabled
                  value={selectedStudent?.username || selectedIncident?.studentId}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {selectedIncident?.studentId}
                </p>
              )}
            </div>

            <div className="mt-2.5 w-full relative font-medium">
              <label htmlFor="resignation_date" className="block text-textDark text-sm">
                Date
              </label>
              {isEdit ? (
                <input
                  type="date"
                  placeholder="Ex- DD/MM/YY"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {moment(selectedIncident?.date).format('ll')}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium">
              <label className="block mb-2 text-textDark text-sm">Time</label>
              {isEdit ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label=""
                    // value={formData?.time}
                    onChange={(time) => setFormData({ ...formData, time: time })}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 py-2 text-xs focus:outline-none">
                  {moment(selectedIncident?.date).format('hh:mm a')}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium md:col-span-2">
              <label className="block text-textDark text-sm">Incident Detail</label>
              {isEdit ? (
                <textarea
                  rows="4"
                  cols="50"
                  name="incident"
                  value={formData.incident}
                  onChange={handleInputChange}
                  placeholder="Ex- Rs 25"
                  className="w-full rounded-2xl border text-xs border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full rounded-xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20">
                  {selectedIncident?.incident}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium md:col-span-2">
              <label className="block text-textDark text-sm">Action Taken</label>
              {isEdit ? (
                <textarea
                  rows="4"
                  cols="50"
                  name="action"
                  value={formData.action}
                  onChange={handleInputChange}
                  placeholder="Ex- Rs 25"
                  className="w-full rounded-2xl border text-xs border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full rounded-xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20">
                  {selectedIncident?.action}
                </p>
              )}
            </div>
          </div>
          {role === 'teacher' &&
            (isEdit ? (
              updateRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex gap-x-4 justify-end mt-4">
                  <button
                    className="border border-tableRowText px-8 py-2 rounded-3xl text-tableRowText text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-40"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </button>

                  <button
                    type="submit"
                    className="border border-primary bg-primary px-8 py-2 rounded-3xl text-white text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-40"
                  >
                    Save
                  </button>
                </div>
              )
            ) : (
              <button
                className="border border-tableRowText ml-auto mt-4 px-8 py-2 rounded-3xl text-tableRowText text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-40"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
            ))}
        </form>
      </div>
    </div>
  );
}

export default ViewIncident;
