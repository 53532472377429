import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DoughnutChart = ({ labels, values, color, cutout }) => {
  const data = {
    labels: labels || ['Red', 'Blue'],
    datasets: [
      {
        label: '',
        data: values || [12, 19],
        backgroundColor: color || ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        datalabels: {
          color: 'grey',
          align: 'top',
          formatter: (value) => {
            return ``;
          },
        },
      },
    ],
  };

  const options = {
    responsive: true,
    labels: false,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: cutout || 50,
  };
  return (
    <>
      <Doughnut data={data} options={options} />
    </>
  );
};

export default DoughnutChart;
