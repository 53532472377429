import React from 'react';

function Balance({ leaveTypes }) {
  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-4 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-medium text-base">
            <p>Leave Balance</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-6/12 border-r-2 py-2 px-2">Leave Type</div>
                <div className="w-4/12 border-r-2 py-2 px-2">Total Leaves</div>
                <div className="w-2/12 py-2 px-2">Leave Balance</div>
              </div>
            </div>
            <div>
              {Array.isArray(leaveTypes) && leaveTypes.length > 0 ? (
                leaveTypes.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`items-center flex w-full relative py-4 px-2 text-xs font-medium ${
                        leaveTypes.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-6/12 px-2 text-tableRowText">{x?.leave?.leave_type}</div>
                      <div className="w-4/12 px-2 text-tableRowText">{x?.leave?.leave_count}</div>
                      <div className="w-2/12 px-2 text-tableRowText">{x?.remaining}</div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Balance;
