import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { PARENT_TEACHER_MEETING } from '../../../api/urls/appointment';

function CreateRequest({ handleClose, selectedStudent }) {
  const formModel = {
    role: '',
    studentId: '',
    date: '',
    reason: '',
    teacherId: '',
  };

  const [formData, setFormData] = useState(formModel);

  const [createState, scheduleMeeting] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData.date) str.push('Date');
    if (!formData.reason?.trim()) str.push('Agenda');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    const body = {
      ...formData,
      studentId: selectedStudent?.id,
      date: new Date(formData.date),
      role: selectedStudent?.teacherId ? 'pa' : 'st',
    };
    if (selectedStudent?.teacherId) body.teacherId = selectedStudent?.teacherId;
    scheduleMeeting(PARENT_TEACHER_MEETING, 'POST', body);
  };

  useEffect(() => {
    if (!createState.isLoading && !createState.isError && createState.status === 201) {
      alert('Success');
      handleClose(true);
    }

    if (!createState.isLoading && createState.isError) {
      alert('Failed');
    }
  }, [createState]);

  return (
    <div
      className="flex justify-center md:items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-1/2 text-sm block m-auto rounded-2xl relative">
        <div className="border-b border-borderYellow">
          <span className="block font-bold p-4 px-6">{selectedStudent?.name + ' - Create Request'}</span>
          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-6 h-3 cursor-pointer"
            alt=""
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <form onSubmit={handleSubmit} className="mt-2 mb-2 flex flex-wrap px-6" autoComplete="off">
          <div className="mt-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">Student Name</label>
            <p className="w-full text-textLight rounded-3xl border border-borderLight bg-gray-50 px-4 mt-2 py-2 text-xs focus:outline-none">
              {selectedStudent?.name}
            </p>
          </div>
          <div className="w-1/10" />
          <div className="mt-2 mb-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">Student RoledID</label>
            <p className="w-full text-textLight rounded-3xl border border-borderLight bg-gray-50 px-4 mt-2 py-2 text-xs focus:outline-none">
              {selectedStudent?.roledinId}
            </p>
          </div>
          <div className="mt-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">Father’s Name</label>
            <p className="w-full text-textLight rounded-3xl border border-borderLight bg-gray-50 px-4 mt-2 py-2 text-xs focus:outline-none">
              {selectedStudent?.parent}
            </p>
          </div>
          <div className="w-1/10" />
          <div className="mt-2 mb-2 w-full md:w-2.25/5 relative font-semibold">
            <label className="block text-sm">
              Date<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              required
              selected={formData.date}
              onChange={(date) => setFormData({ ...formData, date: date })}
              className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-2 w-full relative font-semibold">
            <label className="block text-sm">
              Agenda<span className="text-red-500 ml-1">*</span>
            </label>
            <textarea
              required
              rows={5}
              value={formData.reason}
              type="text"
              name="reason"
              onChange={handleInputChange}
              placeholder="..."
              className="w-full rounded-2xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
            />
          </div>

          <div className="my-4 w-full relative flex justify-end gap-x-4">
            {createState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <>
                <button
                  className="border border-borderLight px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-white text-textLight"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormData({
                      ...formModel,
                    });
                  }}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="border border-primary px-16 rounded-3xl text-xs font-medium focus:outline-none text-center py-2 bg-primary text-white"
                >
                  Send
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateRequest;
