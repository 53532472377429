import React, { useState, useEffect } from 'react';
import NewRemark from '../../../components/Teacher/Remarks/NewRemark';
import RemarkHistory from '../../../components/Teacher/Remarks/RemarkHistory';
import ViewRemark from '../../../components/Teacher/Remarks/ViewRemark';
import useApi from '../../../api/useApi';
import { REMARKS } from '../../../api/urls/remarks';

const Remarks = () => {
  const [open, setOpen] = useState(false);
  const [selected, select] = useState();

  const [remarks, getRemarks] = useApi();

  useEffect(() => {
    getRemarks(REMARKS, 'GET');
  }, []);

  useEffect(() => {
    if (selected) {
      toggleView();
    }
  }, [selected]);

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    select(null);
    toggleView();
  };

  const refreshList = () => {
    getRemarks(REMARKS, 'GET');
  };

  return (
    <>
      {open && <ViewRemark handleClose={handleClose} selected={selected} refreshList={refreshList} />}
      <div className="relative w-full md:w-4/5 pb-10">
        <NewRemark refreshList={refreshList} />
        <RemarkHistory openView={toggleView} data={remarks} select={select} />
      </div>
    </>
  );
};

export default Remarks;
