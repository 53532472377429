import React, { useEffect } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { EXPENSE_REQUEST_STATUS_UPDATE } from '../../../api/urls/finance';

function RequestDetail({ selectedRequestDetail, onClose, refreshData }) {
  const [{ isLoading, isError, data, status }, updateStatus] = useApi();

  const handleSubmit = (status) => {
    const body = {
      status,
    };
    updateStatus(EXPENSE_REQUEST_STATUS_UPDATE(selectedRequestDetail._id), 'PUT', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 202) {
      refreshData();
      alert('Status updated successfully');
      onClose();
    }

    if (!isLoading && isError) {
      alert('Error in updating status');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-5/12 -mt-20  rounded-2xl relative">
        <div className="border-b border-borderYellow flex justify-between items-center py-4 px-6">
          <span className=" font-bold text-textBlue1 ">Request</span>
          <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={onClose} />
        </div>

        <div className="flex flex-wrap w-full px-2 pb-6">
          <div className="px-4 w-full">
            <div className="my-2  relative  w-full ">
              <label htmlFor="date" className="text-sm text-textDark font-medium">
                Request Date
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {moment(selectedRequestDetail?.date).format('DD MMMM YYYY')}
              </p>
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="title" className="text-sm text-textDark font-medium">
                Request Title
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedRequestDetail?.name}
              </p>
            </div>
            <div className="my-2  relative  w-full">
              <label htmlFor="description" className="text-sm text-textDark font-medium">
                Description
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedRequestDetail?.description}
              </p>
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="amount" className="text-sm text-textDark font-medium">
                Amount
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedRequestDetail?.amount}
              </p>
            </div>

            <div className="my-2 relative  w-full">
              <label className="text-sm text-textDark font-medium">Estimate</label>
              <div className="relative flex">
                <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                  {selectedRequestDetail?.files[0].name}
                </p>
                <a
                  href={selectedRequestDetail.files[0].link}
                  download
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0 no-underline"
                >
                  Download
                </a>
              </div>
            </div>

            {selectedRequestDetail?.status.toLowerCase() === 'pending' && (
              <div className="w-full flex justify-end">
                {/* {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : ( */}
                <div className="gap-x-4 mt-6 flex justify-end">
                  <button
                    className="border w-32 py-2 rounded-3xl text-tableRowText border-tableRowText text-xs focus:outline-none flex justify-center text-center items-center"
                    onClick={() => {
                      handleSubmit('Declined');
                    }}
                  >
                    Reject
                  </button>

                  <button
                    className="border w-32 py-2 rounded-3xl bg-primary border-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                    onClick={() => {
                      handleSubmit('Approved');
                    }}
                  >
                    Approve
                  </button>
                </div>
                {/* )} */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDetail;
