import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { REMARKS } from '../../../api/urls/remarks';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import { useStateValue } from '../../../ContextApi/StateProvider';
import DropDown from '../../../components/UI/DropDown';

function NewRemark({ refreshList }) {
  const [{ classSections }, dispatch] = useStateValue();

  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [studentList, setStudents] = useState([]);
  const [selectedStudent, selectStudent] = useState();
  const [reset, setReset] = useState(0); //to force reset fields

  const formDataModel = {
    student: '',
    sectionId: '',
    classId: '',
    studentName: '',
    userName: '',
    remarkType: '',
    remark: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const [remarkState, createRemark] = useApi();
  const [students, getStudents] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.classId) {
      alert('Please select Class');
      return;
    }

    if (!formData.student) {
      alert('Please select Student');
      return;
    }

    const body = {
      ...formData,
    };

    createRemark(REMARKS, 'POST', body);
  };

  useEffect(() => {
    if (classSections && Array.isArray(classSections)) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  useEffect(() => {
    if (!remarkState.isLoading && !remarkState.isError && [201, 202].includes(remarkState.status)) {
      alert('SUCCESS');
      handleReset();
      refreshList();
    }

    if (!remarkState.isLoading && remarkState.isError) {
      alert('FAILED');
    }
  }, [remarkState]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data.map((x) => x));
    }
  }, [students]);

  const handleReset = () => {
    setFormData({ ...formDataModel });
    selectStudent(null);
    setStudents(null);
    setReset((prev) => ++prev);
  };

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-4 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-5/12">
            <p>New Report</p>
          </div>
        </div>
      </div>
      <form className="mt-2 mb-4 mx-4 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
        <div className="mt-2 w-full md:w-2.25/5 relative font-semibold disabled">
          <label htmlFor="testType" className="block text-sm text-textDark">
            Select Class<span className="text-red-500 ml-1">*</span>
          </label>
          <DropDown
            field={'name'}
            handleItemClick={(x) => {
              setFormData({
                ...formData,
                classId: x.classId,
                sectionId: x.sectionId,
                student: '',
                studentName: '',
                userName: '',
              });
              selectStudent(null);
              setStudents(null);
              getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
            }}
            key={reset}
            title={'Select Class'}
            data={combinedClassSection}
            width={'w-full'}
            margin={'mt-2'}
            otherClasses={'border-borderLight'}
            type={'obj'}
          />
        </div>
        <div className="w-1/10" />
        <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
          <label className="block text-sm text-textDark">Remark Type</label>
          <input
            type="text"
            name="remarkType"
            value={formData.remarkType}
            onChange={handleInputChange}
            className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
          />
        </div>
        <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
          <label className="block text-sm text-textDark">
            Student Name<span className="text-red-500 ml-1">*</span>
          </label>
          <DropDown
            field={'full_name'}
            handleItemClick={(x) => {
              selectStudent(x);
              setFormData({
                ...formData,
                student: x._id,
                studentName: x.full_name,
                userName: x.username,
              });
            }}
            key={reset}
            title={formData.studentName || 'Select Student'}
            data={studentList}
            width={'w-full'}
            margin={'mt-2'}
            otherClasses={'border-borderLight'}
            type={'obj'}
          />
        </div>
        <div className="w-1/10" />
        <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
          <label className="block text-sm text-textDark">ROLEDin ID</label>
          <input
            type="text"
            name="userName"
            disabled
            key={reset}
            value={selectedStudent?.username || ''}
            className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
          />
        </div>
        <div className="mt-2 mb-4 w-full relative font-semibold">
          <label className="block text-sm text-textDark">
            Description<span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            rows="4"
            cols="50"
            required
            name="remark"
            value={formData.remark}
            onChange={handleInputChange}
            className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
          />
        </div>
        <div className="w-full float-right mb-5 relative font-semibold">
          {remarkState.isLoading ? (
            <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
          ) : (
            <div className="flex float-right">
              <button
                className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border border-tableRowText px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center "
                onClick={(e) => {
                  e.preventDefault();
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  bg-primary text-white"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default NewRemark;
