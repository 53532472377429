import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { RECENT_FEES } from '../../../api/urls/finance';
import RecentTransactions from '../../../components/Finance/Fee/RecentFee';
import DepositFee from '../../../components/Finance/Fee/DepositFee';
import FeeRecord from '../../../components/Finance/Fee/FeeRecord';
import { useStateValue } from '../../../ContextApi/StateProvider';
import Receipt from '../../../components/Finance/Fee/Receipt';

const FeePayment = () => {
  const [{ classSections }, _] = useStateValue();

  const [recentFee, setRecentFee] = useState([]);
  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [printContent, setPrintContent] = useState();

  const [recentFeeRes, getRecentFees] = useApi();

  useEffect(() => {
    if (classSections) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  useEffect(() => {
    getRecentFees(RECENT_FEES, 'GET');
  }, []);

  useEffect(() => {
    if (!recentFeeRes.isLoading && !recentFeeRes.isError && recentFeeRes.data) {
      setRecentFee(recentFeeRes.data);
    }
  }, [recentFeeRes]);

  const refreshList = () => {
    getRecentFees(RECENT_FEES, 'GET');
  };

  function print(data) {
    setPrintContent({ ...data });
  }

  return (
    <div className="w-full md:w-4/5 relative pb-10">
      {printContent && (
        <Receipt
          printContent={printContent}
          onClose={() => {
            setPrintContent(null);
          }}
        />
      )}

      <DepositFee combinedClassSection={combinedClassSection} refreshList={refreshList} print={print} />

      <RecentTransactions recentFee={recentFee} isLoading={recentFeeRes.isLoading} print={print} />

      <FeeRecord combinedClassSection={combinedClassSection} />
    </div>
  );
};
export default FeePayment;
