import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getExamName } from '../../../utils';
import useApi from '../../../api/useApi';
import { SCHEDULE } from '../../../api/urls/notes';

const DownloadModal = ({ schedule, onClose, setNotes, data, generateSchedule, loading }) => { 
  const [isLoading, setLoading] = useState(false);
  const [SaveSchBtnDisable, setSaveSchBtnDisable] = useState(Array.isArray(schedule) ? false : true );

  const [selectedTopic, selectTopic] = useState(false);

  const [uploadRes, uploadSchedule] = useApi();

  const generateNotes = async (topic) => {
    selectTopic(topic);
    const prompt = `Generate notes for topic:\n\n${topic}`;
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: prompt,
                },
              ],
            },
          ],
          max_tokens: 2000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer sk-proj-2GYEsmjsjf7T48hED44WGT3BlbkFJbmWXUqIup8gjYP9xjieX',
          },
        }
      );
      onClose();
      setNotes(response?.data?.choices?.[0]?.message?.content);
      // console.log(response?.data?.choices?.[0]?.message?.content);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const renderSchedule = () => {
    // console.log("-- schedule is array ? : ", Array.isArray(schedule) );
    if (!schedule) return null; 
    // let splitDayWise = schedule.split('\n').slice(2);
    if( !Array.isArray(schedule) ){
      return (
        <div key={0} className="flex justify-between gap-x-4 text-primary">
              <span className="font-semibold">AI API Error</span>
            </div>
        )
    }
    //
    return  schedule.map((line, index) => {  if(line){ } else{ return null ; }
        // if( index == splitDayWise.length - 1 ||  line == "---" ){ return null ; }
        if (line.topic != ""){  // line.replaceAll('*', '').trim().includes('Topic: ')) {
          // const topic = line.replaceAll('*', '').split('Topic: ')[1]?.split(',')[0] || '';
          return (
            <div key={index} className="flex justify-between gap-x-4 text-primary">
              <span className="font-semibold">{line.topic}</span>
              {/* {topic && (   line.replaceAll('*', '')
                <button
                  onClick={() => generateNotes(topic)}
                  className={`border border-primary w-36 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                    isLoading && topic === selectedTopic ? 'opacity-50' : 'opacity-100'
                  }`}
                  disabled={isLoading}
                >
                  {isLoading && topic === selectedTopic ? 'Generating...' : 'Generate Notes'}
                </button>
              )} */}
            </div>
          );
        }

        if (line.day != ""){  // line.trim().replaceAll('*', '').includes('Day')) {
          // const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2">
              <p className="font-medium text-gray-400">
                <i>{line.day}</i>
              </p>
            </div>
          );
        }

        if (line.week != ""){  //line.trim().replaceAll('*', '').includes('Week')) {
          // const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2 font-semibold text-lg">
              <p className="">{line.week}</p>
            </div>
          );
        }

        return <div key={index}>{line.line}</div>;
      });
  };

  const handleSave = () => { // console.log("need more work to handle save data here..")
    uploadSchedule(SCHEDULE(data.class._id, data.section._id, data.subjectId), 'POST', {
      // content: schedule,
      exam:data.exam
    }); 
    // setTimeout(() => {
    //   alert('Success!');
    //   onClose();
    // }, 1000);
  };

  useEffect(() => { // console.log(" -- uploadRes : ", uploadRes );
    let alertMsg = "" ;
    if (!uploadRes.isLoading && uploadRes.data ) {
      alertMsg = 'Success!' ;
      onClose();
    }
    if (uploadRes.isError) {
      alertMsg ='Failed!';
    }
    if(alertMsg != "" ){
      setTimeout(() => {
        alert(alertMsg);
      }, 700);
    } 
  }, [uploadRes]);

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-6">
      <div className="border-b border-borderYellow flex justify-between items-center px-6 py-4">
        <span className="block font-bold">{`Schedule for  ${data?.subjectName} - ${getExamName(data?.exam)}`}</span>
        <div className="flex gap-x-4">
          {/* <button
            className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
              loading ? 'opacity-50' : 'opacity-100'
            }`}
            onClick={(e) => {
              e.preventDefault();
              generateSchedule();
            }}
            disabled={loading}
          >
            {loading ? 'Generating' : 'Generate again'}
          </button> */}

          <button
            className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none text-white bg-primary ${
              SaveSchBtnDisable ? 'opacity-50' : 'opacity-100'
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleSave();
            }}
            disabled={SaveSchBtnDisable}
          >
            {uploadRes.isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>

      <div className="px-6 py-4">{renderSchedule()}</div>
    </div>
  );
};

export default DownloadModal;
