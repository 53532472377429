import React from 'react';
import moment from 'moment';

function SubjectMarks({ selectedSubject, selectSubject }) {
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-3/5 px-2 text-sm m-auto rounded-2xl relative">
        <div className=" flex justify-between border-b border-borderYellow items-center">
          <span className="block font-bold p-4 text-textBlue1">
            Result -{' '}
            {`${selectedSubject[0]?.test_id?.subject_id?.subjectCode} ${selectedSubject[0]?.test_id?.subject_id?.subjectName}`}
          </span>
          <div className="flex gap-x-4 items-center pr-2">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={() => selectSubject(null)} />
          </div>
        </div>

        <div className=" bg-white rounded-xl h-auto mb-5 w-full">
          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Type</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Due Date</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Result Date</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Total Marks</div>
                    <div className="w-1/6 py-2 px-2"> Marks Obtained</div>
                    <div className="w-1/6 py-2 px-2"> Answer Sheet</div>
                  </div>
                </div>

                <div className="overflow-auto">
                  {Array.isArray(selectedSubject) &&
                    selectedSubject.map((x, idx) => (
                      <div
                        key={x?.test_id?._id}
                        className={`"w-full ${selectedSubject.length - 1 !== idx && 'border-b border-borderYellow'}`}
                      >
                        <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center">
                          <div className="w-1/6 text-tableRowText px-2">
                            {x?.test_id?.title || x?.assignment?.title}
                          </div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {x.test_id ? moment(x.test_id.start).format('ll') : moment(x.assignment.end).format('ll')}
                          </div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {x.test_id ? moment(x.test_id.end).format('ll') : ''}
                          </div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.total_marks || x?.marks}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x?.marks}</div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {x?.answerSheet && x.answerSheet.length > 0 && (
                              <a
                                className="py-2 w-10/12 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                                download
                                href={x?.answerSheet[0].location}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </a>
                            )}
                            {x?.files && x.files?.files.length > 0 && (
                              <a
                                className="py-2 w-10/12 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                                download
                                href={x?.files?.files[0].location}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectMarks;
