import React, { useEffect, useState } from 'react';
import MapAdditionalResponsibility from './MapAdditionalResponsibility';
import AdditionalResponsibilityMapped from './AdditionalResponsibilityMapped';
import MapResponsibilityPopUp from './MapResponsibilityPopUp';

import useApi from '../../../api/useApi';
import { UMMAPPED_RESPONSIBILITY, MAPPED_RESPONSIBILITY } from '../../../api/urls/institute';
import MapRole from './MapRole';

function MapResponsibility() {
  const [selectedUser, selectUser] = useState();
  const [viewUser, setViewUser] = useState();
  const [selectedUserForRole, selectUserForRole] = useState();

  const [unMapped, getUnMapped] = useApi();
  const [mapped, getMapped] = useApi();

  useEffect(() => {
    getUnMapped(UMMAPPED_RESPONSIBILITY, 'GET');
    getMapped(MAPPED_RESPONSIBILITY, 'GET');
  }, []);

  const refreshData = () => {
    getUnMapped(UMMAPPED_RESPONSIBILITY, 'GET');
    getMapped(MAPPED_RESPONSIBILITY, 'GET');
  };

  const getRole = (role) => {
    switch (role) {
      case 'te':
        return 'Teacher';
      case 'ow':
        return 'Owner';
      case 'fi':
        return 'Finance';
      case 'ad':
        return 'Admin';
      case 'pr':
        return 'Principal';
      case 'nt':
        return 'Non-Teaching';
    }
  };

  return (
    <>
      {(selectedUser || viewUser) && (
        <MapResponsibilityPopUp
          selectedUser={selectedUser || viewUser}
          selectUser={selectUser}
          refreshData={refreshData}
          getRole={getRole}
          isView={!!viewUser}
          setViewUser={setViewUser}
        />
      )}
      {selectedUserForRole && (
        <MapRole
          selectedUser={selectedUserForRole}
          selectUser={selectUserForRole}
          refreshData={refreshData}
          getRole={getRole}
        />
      )}
      <div className="w-full md:w-4/5">
        <MapAdditionalResponsibility
          data={unMapped}
          selectUser={selectUser}
          getRole={getRole}
          selectUserForRole={selectUserForRole}
        />
        <AdditionalResponsibilityMapped
          data={mapped}
          setViewUser={setViewUser}
          getRole={getRole}
          refreshData={refreshData}
        />
      </div>
    </>
  );
}

export default MapResponsibility;
