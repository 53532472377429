import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { SUBJECT, SUBJECT_EDIT } from '../../../api/urls/institute';
// import { GET_TEACHING_STAFF } from '../../../api/urls/user';
// import DropDown from '../../UI/DropDown';

function AddSubject({ onClose, tabName, selectedClass, refreshList, selectedSubject }) {
  const [formData, setFormData] = useState({
    subjectName: '',
    subjectCode: '',
    // , teacherId: ''
  });

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, addSubject] = useApi();
  // const [teacherRes, getTeachers] = useApi();
  // const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    if (selectedSubject) {
      setFormData({
        subjectName: selectedSubject?.subjectName,
        subjectCode: selectedSubject.subjectCode,
        // teacherId: selectedSubject?.teacherId?.full_name,
      });
    }
  }, [selectedSubject]);

  // useEffect(() => {
  //   getTeachers(GET_TEACHING_STAFF, 'GET');
  // }, []);

  // useEffect(() => {
  //   if (!teacherRes.isLoading && !teacherRes.isError && teacherRes.data) {
  //     setTeachers(teacherRes.data);
  //   }
  // }, [teacherRes]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.subjectCode?.trim()) {
      alert('Please Select Subject Code');
      return;
    }

    if (!formData.subjectName?.trim()) {
      alert('Please add Subject Name');
      return;
    }

    let method = 'POST';
    if (selectedSubject) method = 'PUT';

    let classId = selectedClass;
    if (selectedSubject) classId = selectedSubject.classID;
    if (selectedSubject) addSubject(SUBJECT_EDIT(classId, selectedSubject._id), method, formData);
    else addSubject(SUBJECT(classId), method, formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setFormData({
        subjectName: '',
        subjectCode: '',
        // teacherId: ''
      });
      refreshList();
      onClose();
      alert('Success');
    }

    if (isError) {
      alert(data || 'Failed');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 mb-auto mt-52  rounded-2xl relative">
        <span className="block font-bold py-4 px-6 text-textBlue1" style={{ borderBottom: '0.71px solid #E7E700' }}>
          {tabName} Subject
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-6 right-6 h-3 cursor-pointer"
          alt=""
          onClick={onClose}
        />
        {/* {teacherRes.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 m-auto " alt="" />
        ) : ( */}
        <form className="mt-2 mb-4 mx-6 " autoComplete="off" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">
                Subject Code <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                placeholder="Eg- 10A01"
                autoComplete="off"
                required
                value={formData?.subjectCode}
                onChange={handleInputChange}
                name="subjectCode"
                className="w-full rounded-3xl text-xs border  border-borderLight px-4 mt-2.5 py-2 focus:outline-none"
              />
            </div>
            <div className="mt-2 mb-4 relative font-semibold ">
              <label className="block text-textDark text-sm">
                Subject Name<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                placeholder="Eg. Information Technology"
                onChange={handleInputChange}
                required
                value={formData?.subjectName}
                name="subjectName"
                className="w-full rounded-3xl text-xs border border-borderLight px-4 mt-2.5 py-2 focus:outline-none"
              />
            </div>
            {/* <div className="mt-2 mb-4 relative font-semibold">
                <label  className="block text-textDark text-sm">
                  Subject Teacher
                </label>
                <DropDown
                  title={selectedSubject ? formData?.teacherId : 'Select'}
                  field="full_name"
                  type={'obj'}
                  data={Array.isArray(teachers) ? teachers : []}
                  handleItemClick={(x) => setFormData({ ...formData, teacherId: x?._id })}
                  otherClasses={' border-borderLight mt-2.5'}
                />
              </div> */}
          </div>
          <div className="w-full">
            <button
              type="submit"
              className="w-36 border ml-auto py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center bg-primary text-white"
            >
              {tabName === 'Edit' ? 'Save' : 'Add Subject'}
            </button>
          </div>
        </form>
        {/* )} */}
      </div>
    </div>
  );
}

export default AddSubject;
