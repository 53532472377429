import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { NOTICE } from '../../../api/urls/social';
import moment from 'moment';

const Notice = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [expand, setExpand] = useState(false);
  const [title, setTitle] = useState('');
  const [file, setFile] = useState();
  const [reset, setReset] = useState(0); //to reset dropdowns
  const [sort, setSort] = useState('');

  const noticeRef = useRef();

  const [createRes, createNotice] = useApi();
  const [notices, getNotices] = useApi();

  useEffect(() => {
    getNotices(NOTICE, 'GET');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];

    if (!title?.trim()) str.push('Title');
    if (!file) str.push('Attachment');

    if (str.length > 0) {
      alert(`Missing Required fields: ${str.join(', ')}`);
      return;
    }

    const formData = new FormData();
    formData.append('images', file);
    formData.append('summary', title);
    createNotice(NOTICE, 'POST', formData);
  };

  useEffect(() => {
    if (!createRes.isLoading && !createRes.isError && createRes.status == 201) {
      setFile();
      setTitle();
      alert('SUCCESS');
      getNotices(NOTICE, 'GET');
      setOpenCreate(false);
    }

    if (createRes.isError) {
      alert('FAILED');
    }
  }, [createRes]);

  const handleClose = () => {
    setFile();
    setTitle();
    setReset((prev) => (prev += 1));
    setOpenCreate(false);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFile();
    setTitle();
    setReset((prev) => (prev += 1));
  };

  let sortedData = notices?.data && Array.isArray(notices?.data) ? notices?.data : [];

  if (sort === 'Latest') {
    sortedData = sortedData.sort((a, b) => (moment(a.created).isBefore(b.created) ? 1 : -1));
  } else if (sort === 'Oldest') {
    sortedData = sortedData.sort((a, b) => (moment(a.created).isAfter(b.created) ? 1 : -1));
  }

  return (
    <div className="w-full md:w-4/5">
      {openCreate && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-1/2 -mt-52 rounded-2xl relative">
            <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
              Notice
            </span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
              alt=""
              onClick={handleClose}
            />
            <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label className="block text-parameter text-sm">
                  Title<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  required
                  type="text"
                  placeholder="Enter Title"
                  autoComplete="off"
                  value={title}
                  key={reset}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2.5 focus:outline-none"
                />
              </div>

              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label className="block text-parameter text-sm">
                  Notice Attachment<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                    ref={noticeRef}
                    key={reset}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <input
                    value={file?.name}
                    required
                    type="text"
                    key={reset}
                    disabled
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      noticeRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>

              <div className="flex justify-end mt-2">
                {createRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    <button
                      className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 mr-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-tableRowText text-tableRowText text-white"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary border-primary text-white"
                    >
                      Publish
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {expand && (
        <div
          className="flex pb-10 w-full h-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-1/2 rounded-2xl relative m-auto mt-auto">
            <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
              {expand?.summary}
            </span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
              alt=""
              onClick={() => setExpand(false)}
            />
            <div className="mt-2 mb-4 mx-4 flex flex-col px-2">
              <img src={expand.images.length > 0 && expand.images[0].location} className=" max-h-full m-auto" alt="" />
            </div>
          </div>
        </div>
      )}

      <div className="relative  pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Notices</p>
              </div>
              <div className="flex justify-end items-center w-full">
                <button
                  className="border px-8 py-2 border-primary text-xs w-36 mr-4 rounded-3xl bg-primary font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={() => setOpenCreate(true)}
                >
                  Create Notice
                </button>
                <DropDown
                  data={['Latest', 'Oldest']}
                  width={'w-36'}
                  // title={'Sort By'}
                  handleItemClick={(x) => {
                    setSort(x);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-1/4">
            <div className="w-full py-4">
              {notices && notices.data?.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : sortedData.length > 0 ? (
                sortedData.map((x) => (
                  <div className="m-auto mb-4 relative bg-borderLight" style={{ height: '410px' }}>
                    <img src={x.images.length > 0 && x.images[0].location} className="w-auto h-full m-auto" alt="" />
                    <div
                      className="absolute bottom-0 h-12 w-full px-6 flex items-center"
                      style={{ background: '#212121BF' }}
                    >
                      <span className="text-white text-sm font-medium">{x?.summary}</span>
                    </div>
                    <img
                      src="assets/img/expand.png"
                      className="absolute right-6 top-6 cursor-pointer"
                      alt=""
                      onClick={() => setExpand(x)}
                    />
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
