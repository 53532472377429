import React from 'react';
import moment from 'moment';

function Instructions({ selectedTest, handleStartTest, onClose }) {
  return (
    <>
      <div className="border-b border-borderYellow px-6 py-4 ">
        <div className="flex flex-col justify-between items-center">
          <span className="block font-bold mb-2">{`${selectedTest?.testDetail?.title}`}</span>
          <span className="block font-bold">{`${selectedTest?.subjectCode} ${selectedTest?.subject_name}`}</span>
        </div>

        <div className="flex justify-between my-2">
          <div className="flex flex-col justify-betwee">
            <span className="text-xs mb-1">{`Date - ${moment(selectedTest?.testDetail?.test_date).format('ll')}`}</span>
            <span className="text-xs">{`Time - ${moment(selectedTest?.testDetail?.end).diff(
              moment(selectedTest?.testDetail?.start),
              'minutes',
              true
            )} Minutes`}</span>
          </div>

          <div className="flex flex-col justify-between items-end">
            <span className="text-xs mb-1">{`Maximum Marks - ${selectedTest?.testDetail?.maxMarks}`}</span>
            <span className="text-xs">{`Minumum Marks - ${selectedTest?.testDetail?.minMarks}`}</span>
          </div>
        </div>
      </div>

      <div className="px-6 py-4 w-full flex flex-col">
        <p className="text-sm font-semibold mb-2">General Instructions</p>
        <p className="text-xs mb-10">{selectedTest?.testDetail?.instruction}</p>

        <div className="flex justify-end gap-x-4">
          <button
            className={`border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none ml-auto text-center text-tableRowText 
      bg-white border-borderLight`}
            onClick={onClose}
          >
            Close
          </button>
          <button
            className={`border py-2 w-36 rounded-3xl text-xs font-medium focus:outline-none text-center text-white 
      bg-primary border-primary`}
            onClick={() => {
              handleStartTest();
            }}
          >
            Start Test
          </button>
        </div>
      </div>
    </>
  );
}

export default Instructions;
