import React from 'react';
import moment from 'moment';

function EventDetails({ toggleOpenEvent, selectedEvent }) {
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-1/2 text-sm block m-auto mt-10 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">Event </span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleOpenEvent} />
        </div>

        <div className="grid grid-cols-2 gap-x-6 p-6 ">
          <div className=" relative font-semibold w-full">
            <label className="block text-parameter text-sm text-textDark">Event Date</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {moment(selectedEvent.eventDate).format('D MMMM YYYY') || '---'}
            </p>
          </div>

          <div className=" relative font-semibold w-full">
            <label className="block text-parameter text-sm text-textDark">Audience</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {selectedEvent.audience || '---'}
            </p>
          </div>
          <div className="my-2 relative font-semibold w-full">
            <label className="block text-parameter text-sm text-textDark">Start Time</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {moment(selectedEvent.start).format('hh:mm a') || '---'}
            </p>
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className="block text-parameter text-sm text-textDark">End Time</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {moment(selectedEvent.end).format('hh:mm a') || '---'}
            </p>
          </div>
          <div className="my-2 w-full relative font-semibold col-span-2">
            <label className="block text-parameter text-sm text-textDark">Title</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {selectedEvent.title || '---'}
            </p>
          </div>

          <div className="my-2 w-full relative font-semibold col-span-2">
            <label className="block text-parameter text-sm text-textDark">Description</label>
            <p className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none">
              {selectedEvent.description || '---'}
            </p>
          </div>
          <div className="my-2 relative font-semibold w-full">
            <label className="block text-parameter text-sm text-textDark">Attachment</label>
            <div className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText mt-2 focus:outline-none flex justify-between">
              <p className="py-2 text-tableRowText px-2 truncate">
                {selectedEvent.files.length > 0 ? selectedEvent.files[0].name : ''}
              </p>
              <a
                href={selectedEvent?.files?.length > 0 && selectedEvent.files[0].location}
                download
                className="bg-primary rounded-full py-2 px-6 text-center text-xs text-white"
              >
                Download
              </a>
            </div>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetails;
