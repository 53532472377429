import React, { useEffect, useState } from 'react';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { FEEDBACK } from '../../../api/urls/remarks';
import moment from 'moment';

const Feedback = () => {
  const [feedbacks, getFeedbacks] = useApi();
  const [sort, setSort] = useState('');

  useEffect(() => {
    getFeedbacks(FEEDBACK, 'GET');
  }, []);

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '';
  };

  let sortedData = feedbacks?.data || [];

  if (sort === 'Latest') {
    sortedData = sortedData.sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1));
  } else if (sort === 'Oldest') {
    sortedData = sortedData.sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1));
  }

  return (
    <div className="w-full md:w-4/5">
      <div className="relative pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-6/12">
                <p>Manage Feedback</p>
              </div>
              <div className="flex justify-end w-full">
                <div className="relative mx-2 flex w-full justify-end">
                  {/* <DropDown
                    data={['All Categories', 'Infrastucture', 'School Experience', 'Staff', 'Clieanless']}
                    width={'w-1/2'}
                    handleItemClick={() => {}}
                  /> */}

                  <DropDown
                    data={['Latest', 'Oldest']}
                    width={'w-4/12'}
                    // title={'Sort by'}
                    margin={'ml-4'}
                    handleItemClick={(x) => {
                      setSort(x);
                    }}
                  />

                  {/* <button
                    className="w-4/12 border ml-4 px-4 relative py-2 rounded-3xl text-sm text-tableRowText border-tableRowText focus:outline-none flex"
                    onClick={() => {
                      setSorting((prev) => !prev);
                    }}
                  >
                    Latest
                    <img src="assets/img/expandIcon.svg" className="h-2 ml-4 absolute right-4 top-2" alt="" />
                  </button> */}
                  {/* {sorting && (
                  <div className="absolute h-40 shadow-xl w-full text-left rounded-xl px-3 bg-white z-50 text-xs font-semibold">
                    <p className="pt-2 pb-1">Latest</p>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-2xl">
                  <div className="flex w-full relative text-xs font-medium">
                    <div className="w-3/12 border-r-2 py-2 px-4">Feedback by</div>
                    <div className="w-7/12 border-r-2 py-2 px-4">Description</div>
                    <div className="w-2/12 py-2 px-4">Date</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {sortedData.length > 0 ? (
                    sortedData.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 text-xs font-medium border-b ${
                            sortedData.length - 1 !== idx && 'border-borderYellow'
                          }`}
                        >
                          <div className="w-3/12 px-4">
                            <div className="w-full">
                              {getFile(x?.userId, 'profileImg') && (
                                <img
                                  className="schoolLogo"
                                  style={{ width: '3em', height: '3em' }}
                                  src={getFile(x?.userId, 'profileImg')}
                                />
                              )}
                              <p className="font-medium text-sm pt-1">{x.user_id.full_name}</p>
                              <p className="text-xs font-bold text-tableRowText">{x?.role}</p>
                            </div>
                          </div>
                          <div className="w-7/12 px-4 text-tableRowText">{x?.feedback}</div>
                          <div className="w-2/12 px-4">{moment(x.date).format('ll') || '--'}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
