import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { CREATE_ASSIGNMENT } from '../../../api/urls/notes';

function CreateAssignment({ setAssignment, selectedAssignment, selectAssignment, refreshList, subjectDetails }) {
  const formDataModel = {
    summary: '',
    start: '',
    end: '',
    marks: '',
    title: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [assignmentState, createAssignment] = useApi();

  useEffect(() => {
    if (selectedAssignment) {
      const { summary, start, end, marks, title } = selectedAssignment;

      setFormData({
        summary: summary,
        start: new Date(start).toISOString().substring(0, 10),
        end: new Date(end).toISOString().substring(0, 10),
        marks,
        title,
      });
    }
  }, [selectedAssignment]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    // const info = user?.Responsbility[0]?.additionalResponsbility;

    const { summary, start, end, marks, title } = formData;

    let str = [];
    if (!summary?.trim()) str.push('Description');
    if (!title?.trim()) str.push('Title');
    if (!marks) str.push('Marks');
    if (!start) str.push('Start Date');
    if (!end) str.push('End Date');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (marks <= 0) {
      alert('Marks should be greater than 0');
      return;
    }

    if (new Date(end).getTime() < new Date(start).getTime()) {
      alert('End Date should be after start date');
      return;
    }

    createAssignment(
      CREATE_ASSIGNMENT(subjectDetails.class._id, subjectDetails.section._id, subjectDetails.subjectId),
      'POST',
      formData
    );
  };

  useEffect(() => {
    if (!assignmentState.isLoading && !assignmentState.isError && [201, 202, 200].includes(assignmentState.status)) {
      alert('Success');
      setFormData(formDataModel);
      setAssignment(false);
      refreshList();
    }

    if (!assignmentState.isLoading && assignmentState.isError) {
      alert('Failed');
    }
  }, [assignmentState]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="bg-white w-11/12 md:w-7/12  m-auto rounded-2xl relative">
        <div className="border-b border-borderYellow py-4 px-6 flex justify-between items-center">
          <span className="block font-bold ">{selectedAssignment ? 'Assignment' : 'Create Assignment'}</span>
          <img
            src="assets/img/Close.svg"
            className=" h-3 cursor-pointer"
            alt=""
            onClick={() => {
              setAssignment(false);
              selectAssignment(null);
            }}
          />
        </div>
        <form className="px-6 py-3 grid grid-cols-1 md:grid-cols-2 gap-x-6" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-2  relative font-semibold disabled">
            <label htmlFor="testType" className="text-sm text-textDark">
              Start Date<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="date"
              placeholder="Eg- 25 August 2022"
              autoComplete="off"
              name="start"
              required
              disabled={!!selectedAssignment}
              value={formData.start}
              onChange={handleInputChange}
              className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="mt-2 mb-4  relative font-semibold ">
            <label className="text-sm text-textDark">
              End Date<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="date"
              name="end"
              required
              value={formData.end}
              onChange={handleInputChange}
              disabled={!!selectedAssignment}
              placeholder="Ex- 25 August 2022"
              className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="mt-2 mb-4  relative font-semibold ">
            <label className="text-sm text-textDark">
              Total Marks<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="number"
              name="marks"
              required
              value={formData.marks}
              disabled={!!selectedAssignment}
              onChange={handleInputChange}
              placeholder="Ex- 10"
              className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="mt-2 mb-4 w-full relative font-semibold md:col-span-2">
            <label className="text-sm text-textDark">
              Title<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              required
              name="title"
              value={formData.title}
              disabled={!!selectedAssignment}
              onChange={handleInputChange}
              placeholder="Ex- title"
              className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="mt-2 mb-4 w-full relative font-semibold md:col-span-2">
            <label className="text-sm text-textDark">
              Description<span className="text-red-500 ml-1">*</span>
            </label>
            <textarea
              rows="4"
              cols="50"
              required
              disabled={!!selectedAssignment}
              name="summary"
              value={formData.summary}
              onChange={handleInputChange}
              placeholder="Ex- xyz"
              className="w-full rounded-2xl border text-sm border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div>

          <div className="w-full relative font-semibold md:col-span-2">
            <div className="flex float-right">
              {!selectedAssignment && (
                <button
                  type="submit"
                  className=" border w-32 py-4 rounded-3xl text-xs  focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAssignment;
