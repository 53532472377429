import React, { useState, useEffect } from 'react';
import DoughnutChart from '../../../components/DoughnutChart';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { STUDENT_REPORT } from '../../../api/urls/test';
import moment from 'moment';

function MarksResult({ role }) {
  const [viewDetails, setViewDetails] = useState(false);
  const [report, setReport] = useState([]);
  const [selectedSubject, selectSubject] = useState();

  const [reportRes, getReport] = useApi();

  useEffect(() => {
    getReport(STUDENT_REPORT, 'GET');
  }, []);

  useEffect(() => {
    const { isLoading, isError, data } = reportRes;
    if (!isLoading && !isError && data) {
      let list = [];
      if (Array.isArray(data)) {
        data.forEach((x) => {
          let idx = list.findIndex((y) => y.code === x.test_id.subject_id.subjectCode);
          if (idx !== -1) {
            let m = list[idx];
            m.tests = [{ test: x }, ...m.tests];
          } else {
            list.push({
              code: x.test_id.subject_id.subjectCode,
              name: x.test_id.subject_id.subjectName,
              tests: [{ test: x }],
            });
          }
        });
      }
      setReport(list);
    }
  }, [reportRes]);

  const handleClose = () => {
    setViewDetails(false);
    selectSubject(null);
  };

  const totalMarks =
    reportRes?.data && Array.isArray(reportRes?.data)
      ? reportRes?.data.reduce((acc, record) => (acc += record.total_marks || 0), 0)
      : 0;
  const acquiredMarks =
    reportRes?.data && Array.isArray(reportRes?.data)
      ? reportRes?.data.reduce((acc, record) => (acc += record.marks || 0), 0)
      : 0;
  const percentage = ((acquiredMarks / totalMarks) * 100).toFixed(2);

  return (
    <>
      {viewDetails && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-3/5 px-2 text-sm m-auto rounded-2xl relative">
            <div className=" flex justify-between border-b border-borderYellow items-center">
              <span className="block font-bold p-4 text-textBlue1">{`Result - ${selectedSubject.code} ${selectedSubject.name}`}</span>
              <div className="flex gap-x-4 items-center pr-2">
                <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={handleClose} />
              </div>
            </div>

            <div className=" bg-white rounded-xl h-auto mb-5 w-full">
              <div className="flex flex-wrap w-full ">
                <div className="w-full py-4 overflow-auto">
                  <div
                    className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                    style={{ width: '96%' }}
                  >
                    <div className="w-full flex bg-tableHeader rounded-3xl">
                      <div className="flex w-full relative text-left px-4 text-xs font-medium">
                        <div className="w-1/5 border-r-2 py-2 px-2">Test Type</div>
                        <div className="w-1/5 border-r-2 py-2 px-2">Test Date</div>
                        <div className="w-1/5 border-r-2 py-2 px-2">Total Marks</div>
                        <div className="w-1/5 border-r-2 py-2 px-2"> Marks Obtained</div>
                        <div className="w-1/5 py-2 px-2"> Answer Sheet</div>
                      </div>
                    </div>

                    <div className="overflow-auto">
                      {Array.isArray(selectedSubject?.tests) &&
                        selectedSubject?.tests.map((x, idx) => (
                          <div
                            className={`w-full ${
                              selectedSubject.tests.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center">
                              <div className="w-1/5 text-tableRowText px-2">{x?.test?.test_id?.title}</div>
                              <div className="w-1/5 text-tableRowText px-2">
                                {moment(x.test.test_id.test_date).format('ll')}
                              </div>
                              <div className="w-1/5 text-tableRowText px-2">{x.test.total_marks}</div>
                              <div className="w-1/5 text-tableRowText px-2">{x.test.marks}</div>
                              <div className="w-1/5 text-tableRowText px-2">
                                {x?.test?.answerSheet?.location && (
                                  <a
                                    className="py-2 w-36 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                                    download
                                    href={x.test.answerSheet.location}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Download
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full md:w-4/5">
        <div className=" bg-white rounded-xl h-auto mb-5 w-full">
          <div className="w-full bg-white rounded-xl h-auto mb-5">
            <div className="items-center px-6 pt-3 pb-3 border-b border-borderYellow">
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <div className="font-bold text-base ">
                  <p>{role === 'student' ? 'Report Card' : 'Result'}</p>
                </div>

                {/* <div className="relative mx-2 w-1/2 md:w-1/6">
                  <DropDown data={['All Terms']} width={'w-1/4full'} handleItemClick={() => {}} />
                </div> */}
              </div>
            </div>
          </div>

          <p className="text-sm text-black font-semibold ml-10 my-3"> Examination Result</p>

          <div className="flex flex-col md:flex-row justify-between items-stretch px-8 gap-x-6">
            <div className="w-full md:w-4/6 bg-yellow-50 rounded-xl relative overflow-hidden">
              {!Array.isArray(reportRes?.data) && (
                <div
                  className="absolute top-0 left-0 right-0 text-xs text-center text-textRed capitalize"
                  style={{ background: '#E7E70040' }}
                >
                  {reportRes?.data}
                </div>
              )}
              <div className="block px-6 py-4">
                <div className="flex justify-between">
                  <div className="w-1/2">
                    <p className="text-xs text-black opacity-50 mt-1">Total Percentage Obtained</p>
                    <p className="text-xl text-black">{!percentage ? 0 : percentage + '%'}</p>
                  </div>

                  <div className="w-1/2 pl-8">
                    <p className="text-xs text-black opacity-50 mt-1">Total Marks</p>
                    <p className="text-xl text-black">{totalMarks}</p>
                  </div>

                  {/* <div className="w-1/3 pl-8">
                    <p className="text-xs text-black opacity-50 mt-1"> Total Credits</p>
                    <p className="text-xl text-black">--</p>
                  </div> */}
                </div>

                <div className="flex justify-between mt-8">
                  <div className="w-1/2">
                    <p className="text-xs text-black opacity-50 mt-1"> Position Scored</p>
                    <p className="text-xl text-black">--</p>
                  </div>

                  <div className="w-1/2 pl-8">
                    <p className="text-xs text-black opacity-50 mt-1"> Marks Obtained</p>
                    <p className="text-xl text-black">{acquiredMarks}</p>
                  </div>

                  {/* <div className="w-1/3 pl-8">
                    <p className="text-xs text-black opacity-50 mt-1"> Credits Scored</p>
                    <p className="text-xl text-black">--</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="w-full md:w-2/6 bg-yellow-50 rounded-xl px-6 py-4 flex flex-col">
              <div className="w-2/5 flex justify-center items-center m-auto">
                <DoughnutChart
                  labels={['']}
                  values={[percentage]}
                  color={percentage >= 40 ? ['rgba(52, 211, 153,0.5)'] : '#FF0000'}
                  cutout={15}
                />
              </div>

              <div className="mt-auto">
                <p className="text-xs text-black opacity-50 mt-1"> Overall Result</p>
                <p className="text-xl text-black">
                  {Array.isArray(reportRes.data) ? (percentage >= 40 ? 'Pass' : 'Fail') : '--'}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 px-8 overflow-auto">
              <div className="bg-white m-auto border border-borderYellow rounded-2xl w-full min-w-700">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/5 border-r-2 py-2 px-2"> Total Marks</div>
                    <div className="w-1/5 border-r-2 py-2 px-2"> Marks Obtained</div>
                    <div className="w-1/5 py-2 px-2">Action</div>
                  </div>
                </div>

                <div className="overflow-auto">
                  {report.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                          report.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 text-tableRowText px-2">{x.code}</div>
                        <div className="w-1/5 text-tableRowText px-2">{x.name}</div>
                        <div className="w-1/5 text-tableRowText px-2">
                          {x.tests.reduce((acc, cuu) => (acc += cuu.test.total_marks || 0), 0)}
                        </div>
                        <div className="w-1/5 text-tableRowText px-2">
                          {x.tests.reduce((acc, cuu) => (acc += cuu.test.marks || 0), 0)}
                        </div>
                        <div className="w-1/5 px-2">
                          <button
                            type="submit"
                            className="border border-red-400  py-2 rounded-3xl text-red-400 font-medium  focus:outline-none flex justify-center text-center items-center w-full"
                            onClick={() => {
                              setViewDetails(true);
                              selectSubject(x);
                            }}
                          >
                            View Detail
                            {/* <img src="assets/img/expandIcon.svg" className="h-2 ml-16" alt="" /> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarksResult;
