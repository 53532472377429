import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { TRANSPORT_ROUTE, TRANSPORT_FEE } from '../../../api/urls/instituteManagement';

function TransportFee() {
  const [selectrdRoute, selectRoute] = useState();
  const [amount, setAmount] = useState({});

  const [routesRes, getRoutes] = useApi();
  const [reansportFeeRes, addTransportFee] = useApi();

  useEffect(() => {
    getRoutes(TRANSPORT_ROUTE, 'GET');
  }, []);

  useEffect(() => {
    if (routesRes.data && routesRes.data) {
      let y = {};
      routesRes.data.forEach((x) => {
        y[x.route.routeName] = x.transportFee;
      });

      setAmount(y);
    }
  }, [routesRes]);

  const handleSave = (_route) => {
    selectRoute(_route);
    const body = {
      routeName: _route,
      amount: amount[_route],
    };
    addTransportFee(TRANSPORT_FEE, 'POST', body);
  };

  useEffect(() => {
    if (!reansportFeeRes.isLoading && !reansportFeeRes.isError && [201, 200].includes(reansportFeeRes.status)) {
      getRoutes(TRANSPORT_ROUTE, 'GET');
      alert('Success');
    }

    if (!reansportFeeRes.isLoading && reansportFeeRes.isError) {
      alert('Failed');
    }
  }, [reansportFeeRes]);

  return (
    <div className="relative pb-10 w-full md:w-4/5">
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-1/2">
              <p>Transport Fee</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Route No</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Total Students</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Total Faculties</div>
                  <div className="w-4/12 border-r-2 py-2 px-2">Amount</div>
                  <div className="w-2/12 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                {Array.isArray(routesRes?.data) && routesRes?.data?.length > 0 ? (
                  routesRes?.data?.map((x, idx) => (
                    <div className="w-full">
                      <div
                        key={x?.route._id}
                        className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                          routesRes?.data?.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2 text-tableRowText">{x?.route?.routeName}</div>
                        <div className="w-2/12 px-2 text-tableRowText">{x?.route?.studentPassenger?.length}</div>
                        <div className="w-2/12 px-2 text-tableRowText">{x?.route?.facultyPassenger?.length}</div>
                        <div className="w-4/12 px-2 text-tableRowText">
                          <input
                            className="w-11/12 text-xs rounded-3xl border border-borderLight text-tableRowText px-4 py-2 focus:outline-none"
                            placeholder="Enter Amount Eg-100"
                            type="number"
                            value={amount[x?.route?.routeName]}
                            onChange={(e) => {
                              let inputValue = e.target.value || '';
                              if (e.target.value.startsWith('-')) {
                                inputValue = e.target.value.slice(1);
                              }
                              setAmount({ ...amount, [x?.route?.routeName]: inputValue });
                            }}
                          />
                        </div>
                        <div className="w-2/12 px-2">
                          {reansportFeeRes.isLoading && x?.route?.routeName === selectrdRoute ? (
                            <img src="assets/img/loader.svg" className="w-14 mx-auto" alt="" />
                          ) : x.transportFee ? (
                            <button
                              className="w-10/12 border bg-primary border-primary text-white py-2  rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                handleSave(x?.route?.routeName);
                              }}
                            >
                              Edit
                            </button>
                          ) : (
                            <button
                              className="w-10/12 border border-borderLight text-textLight py-2  rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                handleSave(x?.route?.routeName);
                              }}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransportFee;
