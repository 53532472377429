import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import useApi from '../../../api/useApi';
import { EXPENSES, EXPENSE_CATEGORIES } from '../../../api/urls/finance';
import moment from 'moment';
import { MONTHS } from '../../../constants';

function CreateExpense({ refreshData }) {
  const reciptUpload = useRef();

  const [formData, setFormData] = useState({
    name: '',
    date: '',
    category: '',
    amount: '',
    transcationType: 'Debit',
    bills: null,
  });

  const handleInputChange = (e) => {
    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }
    setFormData({ ...formData, [e.target.name]: inputValue });
  };

  const [{ isLoading, isError, data, status }, createExpense] = useApi();
  const [categories, getCategories] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData.name?.trim()) str.push('Title');
    if (!formData.date) str.push('Date');
    if (!formData.category) str.push('Category');
   // if (!formData.transcationType) str.push('Transcation Type');
    if (!formData.amount) str.push('Amount');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (formData.amount <= 0) {
      alert('Amount must be greater than zero');
      return;
    }

    if (!formData.bills) {
      alert('Please attach receipt');
      return;
    }

    const fd = new FormData();
    fd.append('name', formData.name);
    fd.append('date', new Date(moment(formData.date).format('D MMMM YYYY')));
    fd.append('category', formData.category);
    fd.append('amount', formData.amount);
//    fd.append('transcationType', formData.transcationType);
    fd.append('bills', formData.bills);

    createExpense(EXPENSES, 'POST', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      alert('Success');
      setFormData({ name: '', date: '', category: '', amount: '', transcationType: '', bills: null });
      refreshData();
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);
  // console.log(" --- category : ", formData.category )
  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center py-2.5">
        <div
          className="flex w-full justify-between items-center relative px-6"
          style={{ borderBottom: '0.71px solid #E7E700' }}
        >
          <p className=" font-bold py-2 pb-3.5 text-textBlue1">Add New Expense</p>
        </div>
      </div>
      <p className=" font-bold px-6 py-2 text-textBlue1">Expense Detail</p>
      <div className="flex flex-wrap w-full h-1/4 px-4 pb-6">
        <form className="flex flex-wrap px-4" onSubmit={handleSubmit}>
          <div className="my-2  relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Expense Title<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              required
              placeholder="Eg-New Flower Pot"
              autoComplete="off"
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-1/10" />
          <div className="my-2  relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Expense Date<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              selected={formData.date}
              required
              placeholderText="--:--"
              onChange={(date) => {
                getCategories(`${EXPENSE_CATEGORIES}&month=${MONTHS[new Date(date).getMonth()]}`, 'GET');
                setFormData({ ...formData, date: date, category:'' });
              }}
              dateFormat={'dd-MM-yyyy'}
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div>
          <div className="my-2  relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Category<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
              <select
                required
                type="text"
                placeholder="Eg-Maintance"
                autoComplete="off"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                className="w-full outline-none"
              >
                <option value="">Select</option>
                {categories &&
                  Array.isArray(categories?.data) &&
                  categories?.data?.map((x) => <option value={x?.category?._id}>{x?.category?.category}</option>)}
              </select>
            </div>
          </div>
          <div className="w-1/10" />
          {/* <div className="my-2  relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Transaction Type<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
              <select
                type="text"
                placeholder="Eg-Maintance"
                autoComplete="off"
                name="transcationType"
                required
                value={formData.transcationType}
                onChange={handleInputChange}
                className="w-full outline-none"
              >
                <option value="">Select</option>
                <option>Credit</option>
                <option>Debit</option>
              </select>
            </div>
          </div> */}
          <div className="my-2  relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Amount<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="number"
              required
              placeholder="Eg-1000"
              name="amount"
              value={formData.amount}
              onChange={(e) => {
                let inputValue = e.target.value || '';
                if (e.target.value.startsWith('-')) {
                  inputValue = e.target.value.slice(1);
                }
                setFormData({ ...formData, amount: inputValue });
              }}
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div>
          {/* <div className="w-1/10" /> */}
          <div className="my-2 relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">
              Upload Receipt<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="relative flex">
              <input
                hidden
                ref={reciptUpload}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setFormData({ ...formData, bills: e.target.files[0] })}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <input
                // required
                type="text"
                disabled
                value={formData.bills?.name || ''}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <button
                className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                onClick={(e) => {
                  e.preventDefault();
                  reciptUpload.current.click();
                }}
              >
                Upload
              </button>
            </div>
          </div>

          <div className="w-full flex justify-end">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <div className="gap-x-4 mt-6 flex justify-end">
                <button
                  type="reset"
                  className="border w-32 py-2 rounded-3xl text-tableRowText border-tableRowText text-xs focus:outline-none flex justify-center text-center items-center"
                  onClick={() => {
                    // setTabName('Add');
                  }}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="border w-32 py-2 rounded-3xl bg-primary border-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateExpense;
