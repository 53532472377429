import React, { useState, useEffect } from 'react';

function ResultReport({ selectedClass, data, students }) {
  const [report, setReport] = useState([
    {
      scale: '100 - 90 %',
      students: 0,
      remark: 'Outstanding',
    },
    {
      scale: '90 - 80 %',
      students: 0,
      remark: 'Very Good',
    },
    {
      scale: '80 - 70 %',
      students: 0,
      remark: 'Good',
    },
    {
      scale: '70 - 60 %',
      students: 0,
      remark: 'Average',
    },
    {
      scale: '60 - 50 %',
      students: 0,
      remark: 'Satisfactory',
    },
    {
      scale: '50 - 40 %',
      students: 0,
      remark: 'Pass',
    },
    {
      scale: '40 - 30 %',
      students: 0,
      remark: 'Fail',
    },
    {
      scale: '30 - 20 %',
      students: 0,
      remark: 'Fail',
    },
    {
      scale: '20 - 10 %',
      students: 0,
      remark: 'Fail',
    },
    {
      scale: '10 - 0 %',
      students: 0,
      remark: 'Fail',
    },
  ]);

  useEffect(() => {
    if (data) {
      let _report = report;

      data.forEach((x) => {
        let pc = (x.marks / x.totalMarks) * 100;

        if (pc <= 100 && pc > 90) {
          _report[0] = {
            ..._report[0],
            students: _report[0].students + 1,
          };
        } else if (pc <= 90 && pc > 80) {
          _report[1] = {
            ..._report[1],
            students: _report[1].students + 1,
          };
        } else if (pc <= 80 && pc > 70) {
          _report[2] = {
            ..._report[2],
            students: _report[2].students + 1,
          };
        } else if (pc <= 70 && pc > 60) {
          _report[3] = {
            ..._report[3],
            students: _report[3].students + 1,
          };
        } else if (pc <= 60 && pc > 50) {
          _report[4] = {
            ..._report[4],
            students: _report[4].students + 1,
          };
        } else if (pc <= 50 && pc > 40) {
          _report[5] = {
            ..._report[5],
            students: _report[5].students + 1,
          };
        } else if (pc <= 40 && pc > 30) {
          _report[6] = {
            ..._report[6],
            students: _report[6].students + 1,
          };
        } else if (pc <= 30 && pc > 20) {
          _report[7] = {
            ..._report[7],
            students: _report[7].students + 1,
          };
        } else if (pc <= 20 && pc > 10) {
          _report[8] = {
            ..._report[8],
            students: _report[8].students + 1,
          };
        } else if (pc <= 10 && pc >= 0) {
          _report[9] = {
            ..._report[9],
            students: _report[9].students + 1,
          };
        }
      });

      setReport(_report);
    }
  }, [data]);

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-full">
            <p>
              Result Report {`(${selectedClass.y.classId.name} - ${selectedClass.y.sectionId.name})`}{' '}
              <span className="text-xs font-medium">{`Total Students - ${students}`}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{
              width: '96%',
            }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/3 border-r-2 py-2 px-2">Percentage Scale</div>
                <div className="w-1/3 border-r-2 py-2 px-2">Total Number of Students</div>
                <div className="w-1/3 border-r-2 py-2 px-2">Result Remark</div>
              </div>
            </div>
            <div
              className="customScrollBar"
              style={{
                overflowY: 'scroll',
                maxHeight: '50vh',
              }}
            >
              {report.map((x, idx) => (
                <div className="w-full">
                  <div
                    className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                      idx !== report.length - 1 && 'border-b border-borderYellow'
                    }`}
                  >
                    <div className="w-1/3 px-2 text-textDark">{x.scale}</div>
                    <div className="w-1/3 px-2 text-textDark">{x.students}</div>
                    <div className="w-1/3 px-2 text-textDark">{x.remark}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultReport;
