import React from 'react';
import moment from 'moment';

function RecentFee({ recentFee, print, isLoading }) {
  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-4" style={{ borderBottom: '0.71px solid #E7E700' }}>
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold text-base">
            <p>Recent Transactions</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl min-w-700"
            style={{ borderColor: '#E7E700', width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                {/* <div className="w-2/12 border-r-2 py-2 px-2">ROLEDin ID</div> */}
                <div className="w-2/12 border-r-2 py-2 px-2">Name</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Received By</div>
                {/* <div className="w-2/12 border-r-2 py-2 px-2">Approved By</div> */}
                <div className="w-2/12 border-r-2 py-2 px-2">Fee Payment</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Month</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Receipt</div>
                {/* <div className="w-2/12 border-r-2 py-2 px-2">Mode</div> */}
                <div className="w-2/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
              ) : Array.isArray(recentFee) && recentFee.length > 0 ? (
                recentFee
                  // .sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1))
                  .map((x, idx) => (
                    <div className="w-full" key={x.fee._id}>
                      <div
                        className={`items-center flex w-full relative py-4 px-2 text-xs font-medium ${
                          recentFee.length - 1 !== idx && 'border-b border-tableHeader'
                        }`}
                      >
                        <div className="w-2/12 px-2 text-tableRowText">
                          {moment(x?.fee?.date).format('DD MMM YYYY')}
                        </div>
                        {/* <div className="w-2/12 px-2">{x?.student?.username}</div> */}
                        <div className="w-2/12 px-2">{x?.student?.full_name}</div>
                        <div className="w-2/12 px-2">{`${x?.class?.name} - ${x?.section?.name}`}</div>
                        <div className="w-2/12 px-2">{x?.fee?.paidBy?.full_name || '--'}</div>
                        {/* <div className="w-2/12 px-2">{x?.fee?.approvedBy?.full_name || '--'}</div> */}
                        <div className="w-2/12 px-2">&#x20b9; {x?.fee?.amountPaid || x?.fee?.amount}</div>
                        <div className="w-2/12 px-2">{x?.fee?.month}</div>
                        <div className="w-2/12 px-2 truncate">{x?.fee?.receipt || x?.fee?.receiptNo}</div>
                        {/* <div className="w-2/12 px-2">{x?.fee?.mode}</div> */}
                        <div className="w-2/12 px-2">
                          <button
                            className="border border-primary text-primary px-6 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
                            onClick={() => {
                              if (x?.fee?.type === 'misc')
                                print({
                                  ...x,
                                  fee: {
                                    month: x?.fee?.month,
                                    amount: x?.fee?.amountPaid,
                                    miscFee: x?.fee?.orderType,
                                    balance: x?.fee?.bal,
                                    receiptNo: x?.fee?.receipt,
                                    date: x?.fee?.date,
                                    totalAmount: x?.fee?.amount,
                                    prevBalance: x?.fee?.prevBalance,
                                    waiveoff: x?.fee?.waiveoff,
                                  },
                                });
                              else print(x);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentFee;
