import { SOCIAL } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const GET_FEEDS = (instituteId) => `${SOCIAL}/user/feeds?year=${year}`;

export const CREATE_TEXT_FEEDS = `${SOCIAL}/createPost?year=${year}`;

export const CREATE_TEXT_PICTUREFEEDS = `${SOCIAL}/feed?year=${year}`;

export const LIKE_FEED = `${SOCIAL}/likes?year=${year}`;

export const COMMENT_FEED = `${SOCIAL}/comments?year=${year}`;

export const DELETE_FEED = (id) => `${SOCIAL}/feed/${id}?year=${year}`;

export const NOTICE = `${SOCIAL}/notices?year=${year}`;
