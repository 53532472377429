import axios from 'axios';
import Cookies from 'js-cookie';
import { LOGIN } from './urls/authentication';
// import { useStateValue } from '../ContextApi/StateProvider';
// const [{}, dispatch2] = useStateValue();

const apiClient = axios.create();

const tokenKey = 'auth';

var isRefreshing = false;

const getTokenExpiration = () => {
  const token = Cookies.get('expiry') ? Cookies.get('expiry') : null;
  // console.log({ token });
  const tokenExpiredTime = token || 0;
  // console.log({ tokenExpiredTime });
  return +tokenExpiredTime;
};

const getRefreshedToken = async () => {
  isRefreshing = true;

  return await apiClient.post(
    `${LOGIN}`,
    new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: localStorage.refresh_token,
    }),
    {
      headers: {
        Authorization: 'Basic Um9sZWRJbjpSb2xlZEluIzEyMw==',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

apiClient.interceptors.request.use(
  (config) => {
    if (!config.url.includes('/auth/api/oauth/token') && !isRefreshing) {
      // Check if the token is expired or about to expire
      const tokenExpiration = getTokenExpiration();
      // console.log({ tokenExpiration }, tokenExpiration - new Date().getTime());
      if (tokenExpiration > 0 && tokenExpiration - new Date().getTime() < 60000) {
        // Refresh token if it's about to expire in less than 1 minute
        return getRefreshedToken().then((newToken) => {
          const expires = new Date(new Date().getTime() + newToken.data.expires_in * 1000);
          Cookies.set('auth', JSON.stringify(newToken.data), { expires });
          Cookies.set('expiry', expires.getTime(), { expires });
          localStorage.refresh_token = newToken.data.refresh_token;

          isRefreshing = false;
          //   dispatch2({
          //     type: 'TOKEN',
          //     token: newToken,
          //   });

          config.headers.Authorization = 'Bearer ' + newToken?.data?.access_token;
          return config;
        });
      } else if (tokenExpiration <= 0) {
        return Promise.reject('401');
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { apiClient };
