import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/UI/DropDown';

import useApi from '../../../api/useApi';
import { STUDENT_SUBJECT } from '../../../api/urls/instituteManagement';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'Topic', key: 'Topics' },
  { label: 'Description', key: 'Description' },
];

function CourseDetails() {
  const [selectedLesson, selectLesson] = useState([]);
  const [selectedSubject, selectSubject] = useState('');

  const [subjects, getStudentSubjects] = useApi();

  useEffect(() => {
    getStudentSubjects(STUDENT_SUBJECT, 'GET');
  }, []);

  useEffect(() => {
    if (
      !subjects.isLoading &&
      !subjects.isError &&
      subjects.data &&
      subjects.data.subjects &&
      subjects.data.subjects.length > 0
    ) {
      selectSubject(subjects.data.subjects[0].name);
    }
  }, [subjects]);

  useEffect(() => {
    if (selectedLesson) {
    }
  }, [selectedLesson]);

  const lessons = subjects?.data?.subjects?.find((subject) => subject.name === selectedSubject);

  return (
    <>
      <div className="w-full md:w-4/5">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3  border-b border-borderYellow">
            <div className="flex flex-col md:flex-row justify-between md:items-center">
              <div className="font-bold ">
                <p>Syllabus</p>
              </div>

              <div className="relative mx-2 w-1/2 md:w-1/6">
                <DropDown
                  data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                  width={'w-full'}
                  title={'Sort By'}
                  handleItemClick={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border border-borderYellow overflow-hidden rounded-2xl min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Course Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Total Lessons</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Lesson Delivered</div>
                    <div className="w-1/5 py-2 px-2">Lesson Pending</div>
                  </div>
                </div>
                <div className="overflow-auto customScrollBar" style={{ maxHeight: '45vh' }}>
                  {subjects.data &&
                    subjects.data.subjects &&
                    subjects.data.subjects.map((x, idx) => (
                      <div
                        className={`w-full ${
                          subjects.data.subjects.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center">
                          <div className="w-1/5 text-tableRowText px-2">{x?.code}</div>
                          <div className="w-1/5 text-tableRowText px-2">{x.name}</div>
                          <div className="w-1/5 text-tableRowText px-2">{x?.course?.course?.length}</div>
                          <div className="w-1/5 text-tableRowText px-2">--</div>
                          <div className="w-1/5 text-tableRowText px-2">--</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold text-base ">
                <p>Course Detail - {selectedSubject}</p>
              </div>

              <div className="relative mx-2 w-1/6">
                <DropDown
                  data={subjects?.data?.subjects?.map((x) => x.name) || []}
                  width={'w-full'}
                  handleItemClick={(x) => {
                    selectSubject(x);
                  }}
                  title={selectedSubject || 'Select Subject'}
                  key={selectedSubject}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4">
              <div className="bg-white m-auto border border-borderYellow rounded-2xl" style={{ width: '96%' }}>
                <div className="w-full  bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">Lesson No.</div>
                    <div className="w-3/5 border-r-2 py-2 px-2">Lesson Name</div>
                    <div className="w-1/5 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="overflow-auto customScrollBar" style={{ maxHeight: '45vh' }}>
                  {lessons?.course?.course.map((x, idx) => (
                    <div
                      className={`w-full ${
                        lessons?.course?.course.length - 1 !== idx && 'border-borderYellow border-b'
                      }`}
                    >
                      <div className="justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center">
                        <div className="w-1/5 text-tableRowText px-2">{x?.lessonNo}</div>
                        <div className="w-3/5 text-tableRowText px-2">{x?.lessonName}</div>
                        <div className="w-1/5 text-tableRowText px-2">
                          {/* <button
                            className="border border-red-400 px-2 py-2 w-11/12 rounded-3xl text-red-400 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                            onClick={() => selectLesson(x?.lessonDetails || [])}
                          >
                            Download
                          </button> */}
                          <ExcelExportBtn
                            data={x.lessonDetails}
                            fileName={x?.lessonName + '_LessonPlan.csv'}
                            text={'Download'}
                            width={'w-40'}
                            headers={excelHeaders}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
