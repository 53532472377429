import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStateValue } from '../../ContextApi/StateProvider';
import UserProfile from '../Social/UserProfile';
import useApi from '../../api/useApi' ;
import { Set_oauth_role } from '../../api/urls/authentication' ; 


const NavigationBarComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const dropdownRef = useRef(null);
  const rolesRef = useRef(null);

  const [{ user, openMenu }, dispatch] = useStateValue();
  const _role = user ? user.user?.currentlyMapped?.role : [];
  const [openRoles, setOpen] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [userNavigation, setuserNavigation ] = useState(true);
  const [resRoleOnServer, setRoleOnServer] = useApi();

  const onClick = (e) => {
    e.preventDefault();
    setOpenSetting((prev) => !prev);
  };
  const handleNavigation_withServer = (role) => { 
    
  }; 
  useEffect(() => { // console.log(" resRoleOnServer : ", resRoleOnServer )
    if (!resRoleOnServer.isLoading && !resRoleOnServer.isError && resRoleOnServer.data) {
      if(resRoleOnServer.data?.roleDone && resRoleOnServer.data?.role){
        let role = resRoleOnServer.data?.role ;
        if (role === 'ow') history.push('/owner');
        if (role === 'fi') history.push('/finance');
        if (role === 'ad') history.push('/admin');
        if (role === 'pr') history.push('/principal');
        if (role === 'st') history.push('/student');
        if (role === 'pa') history.push('/parents');
        if (role === 'te') history.push('/teacher'); 
      } else { 
        alert("Something unexpected happans.")
      }
    }
  }, [resRoleOnServer]);


  const reEstateRole = () => { // on page reload this provide lock on user role
      //   
      if( user?.roles?.length > 0 ){ 
        let fixRole = "" ;
        if(location.pathname === '/owner'){fixRole = "ow"; }
        if(location.pathname === '/admin'){fixRole = "ad"; }
        if(location.pathname === '/principal'){fixRole = "pr"; } 
        if(location.pathname === '/teacher'){fixRole = "te"; } 
        if(location.pathname === '/student'){fixRole = "st"; } 
        if(location.pathname === '/parents'){fixRole = "pa"; } 
        if(location.pathname === '/finance'){fixRole = "fi"; } 
        //console.log(" -- handleNavigation : ",fixRole,'---', user.roles ) ; 
        if( fixRole && user.roles[0].role != fixRole && userNavigation ){  handleNavigation(fixRole);  }
      } 
      // else {
      //   setTimeout(() => {
      //     reEstateRole();
      //   }, 1200);   
      // }
    
  }
  useEffect(() => { 
    // setTimeout(() => { 
       reEstateRole() ; 
    // }, 2700);   
  },[user])
  //
  const handleNavigation = (role) => { 
    setuserNavigation(false);
    setRoleOnServer(Set_oauth_role(role), 'GET');
    setOpen(false);
    let selectedRoleOnTop = user.roles ;
    selectedRoleOnTop.sort(function(a, b){ if(a.role == role){ return -1 ; } return 1 ; });
    dispatch({
      type: 'USER',
      user: {...user , roles : selectedRoleOnTop },
    });
  };

  const toggleSetting = () => {
    setOpenSetting((prev) => !prev);
  };

  useEffect(() => {
    if (location && openSetting && openRoles) {
      toggleSetting();
      setOpen(false);
    }
  }, [location]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setOpenSetting(!openSetting);
      }

      if (rolesRef.current !== null && !rolesRef.current.contains(e.target)) {
        setOpen(!openRoles);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (openSetting || openRoles) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [openSetting, openRoles]);

  return (
    <>
      {openRoles && (
        <div
          ref={rolesRef}
          className="absolute left-1/2 transform translate-x-10 border top-16 bg-white rounded-xl shadow-2xl py-6 w-40 pl-6 z-30"
        >
          <ui className="list-none flex flex-col gap-4 text-sm">
            {_role.includes('ow') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('ow');
                }}
              >
                {location.pathname === '/owner' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Owner</span>
              </li>
            )}
            {_role.includes('ad') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('ad');
                }}
              >
                {location.pathname === '/admin' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Admin</span>
              </li>
            )}
            {_role.includes('pr') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('pr');
                }}
              >
                {location.pathname === '/principal' && (
                  <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />
                )}
                <span className="ml-6">Principal</span>
              </li>
            )}
            {_role.includes('te') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('te');
                }}
              >
                {location.pathname === '/teacher' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Teacher</span>
              </li>
            )}
            {_role.includes('st') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('st');
                }}
              >
                {location.pathname === '/student' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Student</span>
              </li>
            )}
            {_role.includes('pa') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('pa');
                }}
              >
                {location.pathname === '/parents' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Parent</span>
              </li>
            )}
            {_role.includes('fi') && (
              <li
                className="flex gap-x-4 items-center relative cursor-pointer"
                onClick={() => {
                  handleNavigation('fi');
                }}
              >
                {location.pathname === '/finance' && <img src="/assets/img/tick.png" className="h-3 absolute" alt="" />}
                <span className="ml-6">Finance</span>
              </li>
            )}
          </ui>
        </div>
      )}

      {openSetting && (
        <div
          ref={dropdownRef}
          className="absolute right-16 transform translate-x-10 border top-14 bg-white rounded-xl shadow-2xl pr-4 pb-6 z-40"
        >
          <UserProfile profile={user} isCommonSetting={true} />
        </div>
      )}

      <div className="relative">
        <div className="h-20 relative bg-white flex">
          <div className="w-18 h-20 md:h-40 absolute top-0 md:-left-0.5 flex justify-center items-center flex-col gap-y-3 bg-white rounded-br-xl overflow-hidden">
            <img
              className="w-auto h-4/6"
              alt=""
              src={user?.institute?.logo?.link || '/assets/img/institute_placeholder.svg'}
            />
            <p className="text-sm -mt-1 font-medium hidden md:block">{user?.institute?.name}</p>
          </div>

          <div className="mx-auto transform h-full flex gap-x-6 justify-center items-center cursor-pointer">
            <div
              className="relative w-12 h-20 flex-col flex justify-center items-center"
              onClick={() => history.push('/social')}
            >
              <img
                alt=""
                className="mx-auto"
                src={
                  location.pathname === '/social' || location.pathname === '/'
                    ? '/assets/img/social_selected.svg'
                    : '/assets/img/social.svg'
                }
              />
              {(location.pathname === '/social' || location.pathname === '/') && (
                <span className="mt-1 -mb-4 font-medium text-tableRowText" style={{ fontSize: 9 }}>
                  Social
                </span>
              )}

              {(location.pathname === '/social' || location.pathname === '/') && (
                <div className="absolute h-1.5 bottom-2 rounded-full w-full bg-primary"></div>
              )}
            </div>
            {_role && _role.length > 0 && (
              <div className="relative">
                <div
                  className="relative w-12 h-20 flex-col flex justify-center items-center cursor-pointer"
                  onClick={() => (_role.length > 1 ? setOpen((prev) => !prev) : handleNavigation(_role[0]))}
                >
                  <img
                    alt=""
                    className="mx-auto"
                    src={
                      location.pathname !== '/social' && location.pathname !== '/'
                        ? '/assets/img/dashboard_selected.svg'
                        : '/assets/img/dashboard.svg'
                    }
                  />
                  {/* {location.pathname === '/owner' && ( */}
                  <span className="mt-0.5 -mb-3 font-medium capitalize text-tableRowText" style={{ fontSize: 9 }}>
                    {location.pathname !== '/social' && location.pathname !== '/' && location.pathname.substring(1)}
                  </span>

                  {_role.length > 1 && (
                    <img
                      src={openRoles ? '/assets/img/collapse_orange.svg' : '/assets/img/expandIcon_orange.svg'}
                      alt=""
                      className="h-1.5 -right-1 top-1/2 z-30 absolute"
                    />
                  )}
                  {location.pathname !== '/social' && location.pathname !== '/' && (
                    <div className="absolute h-1.5 bottom-2 rounded-full w-full bg-primary"></div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="absolute flex right-6 top-5 gap-x-4">
            {location.pathname !== '/social' && (
              <div className="bg-borderLight rounded-full p-2.5 my-auto cursor-pointer" onClick={onClick}>
                <img src="assets/img/setting.svg" className=" w-3.5 m-auto" />
              </div>
            )}

            {!openMenu && (
              <img
                src="assets/img/menu.svg"
                className=" md:hidden w-7"
                onClick={() =>
                  location.pathname === '/social'
                    ? dispatch({
                        type: 'TOGGLE_SOCIAL_MENU',
                        openSocialMenu: true,
                      })
                    : dispatch({
                        type: 'TOGGLE_MENU',
                        openMenu: true,
                      })
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationBarComponent;
