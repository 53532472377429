import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'Date', key: 'date' },
  { label: 'Leave Type', key: 'type' },
  { label: 'Date From', key: 'from' },
  { label: 'Date To', key: 'to' },
  { label: 'Total Days', key: 'days' },
  { label: 'Approval Date', key: 'approval' },
];

function History({ openDetails, leaveHistory }) {
  const [excelData, setExcelData] = useState([]);
  const [sort, setSort] = useState('');

  useEffect(() => {
    if (Array.isArray(leaveHistory)) {
      const _data = leaveHistory;
      let x = _data.map((x) => {
        return {
          date: moment(x.createdAt).format('ll'),
          type: x?.leave?.leave_type,
          from: moment(x.date).format('ll'),
          to: moment(x.endDate).format('ll'),
          approval: x.status ? moment(x.updatedAt).format('ll') : x.rejected ? 'Rejected' : 'Pending',
          days: x?.days,
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [leaveHistory]);

  let filteredData = leaveHistory || [];

  if (sort) {
    if (sort === 'Newest to oldest') {
      filteredData = filteredData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    }
    if (sort === 'Oldest to Newest') {
      filteredData = filteredData.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
        <div className="lg:flex block sm:block  items-center">
          <div className="font-medium text-base">
            <p>Leave History</p>
          </div>
          <div className="flex ml-auto">
            <ExcelExportBtn text={'Export Data'} data={excelData} headers={excelHeaders} fileName={'leaves.csv'} />
            <div className="relative ml-4">
              <DropDown
                data={['Newest to oldest', 'Oldest to Newest']}
                width={'w-40'}
                title={'Sort By'}
                handleItemClick={(x) => {
                  setSort(x);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl min-w-700"
            style={{ borderColor: '#E7E700', width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Leave Type</div>
                <div className="w-2/12 py-2 px-2">Date-From</div>
                <div className="w-2/12 py-2 px-2">Date-To</div>
                <div className="w-2/12 py-2 px-2">Approval Date</div>
                <div className="w-2/12 py-2 px-2">Total Days</div>
                <div className="w-2/12 py-2 px-2">View Details</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((x, idx) => (
                  <div key={x._id} className="w-full">
                    <div
                      className={`items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-tableRowText">{moment(x.createdAt).format('ll')}</div>
                      <div className="w-2/12 px-2">{x.leave.leave_type}</div>
                      <div className="w-2/12 px-2">{moment(x.date).format('ll')}</div>
                      <div className="w-2/12 px-2">{moment(x.endDate).format('ll')}</div>
                      <div
                        className={`w-2/12 px-2 ${x.status ? 'black' : x.rejected ? 'text-textLight' : 'text-textRed'}`}
                      >
                        {x.status ? moment(x.updatedAt).format('ll') : x.rejected ? 'Rejected' : 'Pending'}
                      </div>
                      <div className="w-2/12 px-2">{x.days}</div>
                      <div className="w-2/12 flex justify-center items-center">
                        <button
                          className="w-10/12 border border-borderGrey text-tableRowText px-4 py-2 rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            openDetails(x);
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
