import React, { useState, useEffect } from 'react';
import UploadModal from './UploadModal';
import { SYLLABUS_GET } from '../../../api/urls/timeTable';
import { Generate_Schedule_POST } from '../../../api/urls/ai';
import { GET_EXAM_SEQUENCE } from '../../../api/urls/test';
import useApi from '../../../api/useApi';
import { utils, writeFile } from 'xlsx';
import Schedule from './Schedule';
import Notes from './Notes';
import axios from 'axios';
import { getRandomNumber, getExamName } from '../../../utils';
import LoadingPopup from '../../Principal/AcademicCalender/LoadingPopup';

const Syllabus = ({ subjects }) => {
  const [open, setOpen] = useState();
  const [ShowLoading, setShowLoading] = useState();

  const [printData, setPrintData] = useState();
  const [selected, select] = useState();
  const [notes, setNotes] = useState();
  const [schedule, setSchedule] = useState();
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState();
  const [extractedText, setextractedText] = useState();
  const [session , setsession]  = useState();
  const [CurrExam , setCurrExam]  = useState();
  const [Currclass , setCurrclass] = useState();
  const [ViewSyllabus , setViewSyllabus ] = useState();
  const [Show_schedule , setShowSchedule ] = useState(false);
  const [not_disable_Genrate , setNotDisableGenrate ] = useState(true);
  const [formData, setFormData] = useState({OnlineClassTest:"",OfflineClassTest:"",assignments:"" });

  const [{ isLoading, data, isError }, getSyllabus] = useApi();
  const [UnitTermTestFromAcademicCalander, getUnitTermTestFromAcademicCalander] = useApi();
  const [Topics, ScheduleTopics] = useApi();


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOpenDownload = (exam, _class, section, subjectName, subjectId) => {
    setOpen({ exam, _class, section, subjectName, subjectId });
    setCurrExam(exam);
    setCurrclass(_class);
    setextractedText('');
    setExcelData('');
  };

  useEffect(() => {
    getSyllabus(SYLLABUS_GET, 'GET');
  }, []);

  useEffect(() => {
    const sess = sessionStorage.getItem('session') ;
    getUnitTermTestFromAcademicCalander(GET_EXAM_SEQUENCE(sess), 'GET');
    setsession(sess);
  }, []);

  const handleClose = (refresh) => {
    if (refresh) {
      getSyllabus(SYLLABUS_GET, 'GET');
    }
    setOpen(null);
  };
  const generateSchedule = async (type) => {
    // const body = JSON.stringify(type === 'image' ? extractedText : excelData);
    if(ViewSyllabus){
      setLoading(true);
      setShowLoading(true);
      ScheduleTopics( Generate_Schedule_POST , 'POST', { extractedData: ViewSyllabus.Syallbus , exam:ViewSyllabus.exam , classname : ViewSyllabus.class,section : ViewSyllabus.section,subjectId:ViewSyllabus.subjectId,SyllabustId:ViewSyllabus.ID ,teacherInput:formData } ); 
    }else{ alert('Error'); console.log("Please select syllabus data first"); }
    // after ajax complete
  };

  useEffect(() => { 
    if(!Topics.isLoading){  setShowLoading(false); }
    setLoading(false); // console.log("Ai model call from server : ",Topics );
    //  return ;
    if(!Topics.isError && !Topics.isLoading){ 
      // handleClose();
      setViewSyllabus(null);
      if(Topics.data){  // console.log(" schedule is loaded",Topics.data) ; 
        setSchedule(Topics.data.ApiResult);
        setShowSchedule(true);
      }
      // 
    } else {  setSchedule(null); setShowSchedule(false); }
  }, [Topics]);

  //
  // const generateSchedule = async (type) => {
  //   const body = JSON.stringify(type === 'image' ? extractedText : excelData);
  //   const prompt = `Please schedule this course syllabus over 30 days according to level of difficulty with 2 class tests and 1 assignment. Generate in format week\nDay\nTopic\nsubtopics:\n\n${body}`;

  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       'https://api.openai.com/v1/chat/completions',
  //       {
  //         model: 'gpt-4o',
  //         messages: [
  //           {
  //             role: 'user',
  //             content: [
  //               {
  //                 type: 'text',
  //                 text: prompt,
  //               },
  //             ],
  //           },
  //         ],
  //         max_tokens: 2000,
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer sk-proj-2GYEsmjsjf7T48hED44WGT3BlbkFJbmWXUqIup8gjYP9xjieX',
  //         },
  //       }
  //     );
  //     handleClose();
  //     setSchedule(response?.data?.choices?.[0]?.message?.content);
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //   }
  // };

  const checkIfUploaded = (subjectId, exam) => {
    const x = data?.syllabus?.find((c) => c.subjectId == subjectId && c.type == exam)?.syllabus;
    //  console.log("find syllabus : ", data?.syllabus?.find((c) => c.subjectId == subjectId && c.type == exam)  )
    return x;
  };

  function ExportData(dataLocal, examName,Syallbus) {  // console.log("View : ", data,"\n-- examName : ", examName ,"--Syallbus:",Syallbus  );
    if(Syallbus ){ 
      if(Syallbus.length == 1 && typeof Syallbus[0] == "string"){ Syallbus = Syallbus[0]; }
    } 
    // 
    const SyllabusId = data?.syllabus?.find((c) => c.subjectId == dataLocal?.subjectId && c.type == examName)?._id ;
    // console.log("SyllabusId : ", SyllabusId )
    let isSchudleGenrated = data.Schedule.filter((obj)=>{ return obj.syllabustId == SyllabusId })
    // console.log("View -> Schedule: ", data.Schedule  ) ;
    // console.log("View -> isSchudleGenrated: ", isSchudleGenrated  ) ;
    if( isSchudleGenrated && isSchudleGenrated.length > 0 ){ setNotDisableGenrate(false); }
    else{ setNotDisableGenrate(true);  }
    if(SyllabusId){
      setViewSyllabus({...dataLocal,exam:examName,Syallbus:Syallbus,ID:SyllabusId});
      setSchedule(null) ;
      setShowSchedule(false) ;
    } else { 
      console.log(" Error : logic Error > logic faild to extract SyllabusId") ; 
      alert("Some Error Occured.\nPlease contact to admin.")
    }
    // if (data.length > 0 && typeof data[0] == 'string') {
    //   setPrintData(data);
    //   // setTimeout(() => {
    //   //   window.print();
    //   // }, 600);
    //   let w=window.open( data , "popupWindow", "width=800,height=500,scrollbars=yes");
    //   w.document.write(data);
    //   // w.print();
    //   // w.close();
    // } else {
    //   const filename = _filename + '.xlsx';

    //   var ws = utils.json_to_sheet(data);
    //   var wb = utils.book_new();
    //   utils.book_append_sheet(wb, ws, 'People');
    //   writeFile(wb, filename);
    // }
  } 
  // UnitTermTestFromAcademicCalander.isLoading = true ; 
  let AcademicCalanderCheck = "No Data Available" ;
  let APIError = false ; 
  let SeqUnitTermTest = ['check']
  if( !UnitTermTestFromAcademicCalander.isError && !UnitTermTestFromAcademicCalander.isLoading) //  & UnitTermTestFromAcademicCalander.data.msg)
  { AcademicCalanderCheck = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.msg : AcademicCalanderCheck ; 
    APIError = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.isError : false ; 
    SeqUnitTermTest = UnitTermTestFromAcademicCalander.data? UnitTermTestFromAcademicCalander.data.examSequence : [] ;
  }
  // console.log(" - UnitTermTestFromAcademicCalander : ", UnitTermTestFromAcademicCalander," -- ", AcademicCalanderCheck  )
  let isArray_syallbus = false;
  let isArray_syallbus_not = false
  if(ViewSyllabus ){ 
    isArray_syallbus = Array.isArray(ViewSyllabus.Syallbus);
    isArray_syallbus_not = isArray_syallbus ? false : true ;
  }
  // console.log(" -- ViewSyllabus : ", ViewSyllabus )
  // let ShowSchedule = Show_schedule ; console.log("--ShowSchedule : ", ShowSchedule )
  return (
    UnitTermTestFromAcademicCalander.isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
      ) : 
    UnitTermTestFromAcademicCalander.isError || APIError ? (  
      <div  className="bg-white m-auto rounded-2xl w-full md:w-4/5" // border  min-w-700" style={{ borderColor: '#E7E700' }}
          > 
      <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">{AcademicCalanderCheck}</p>
      </div>
    ) :
    <div className="relative w-full md:w-4/5 pb-10">
      {open && (
        <UploadModal
          data={open}
          onClose={handleClose}
          setSchedule={(setdata)=>{setSchedule(setdata);setShowSchedule(true);}}
          loading={loading}
          generateSchedule={generateSchedule}
          setExcelData={setExcelData}
          excelData={excelData}
          setExtractedText={setextractedText}
          extractedText={extractedText}
          setShowLoading={setShowLoading}
        />
      )}

      {notes && (
        <Notes
          notes={notes}
          onClose={() => {
            setNotes(null);
          }}
        />
      )}

      {/* Print UI if data is simple string*/}
      {!notes && (
        <div className="flex flex-col overflow-auto px-10" id="section-to-print">
          <div className="flex flex-col mt-4 mb-4">
            <h2 className="text-black font-medium mb-2">{`Syllabus`}</h2>
            <h3 className="text-black font-medium mb-5">{`Class: ${selected?.class?.name}-${selected?.section?.name}, Exam: ${selected?.exam} Subject: ${selected?.subjectName}`}</h3>
            <p className="">{printData}</p>
          </div>
        </div>
      )}

      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-4 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Academic Syllabus</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  {SeqUnitTermTest.map((examName, examName_index) => (
                    <>
                      <div className={`w-1/5 py-2 px-2
                      ${
                        examName_index !== SeqUnitTermTest.length - 1 && 'border-r-2'
                      }`}
                      >{getExamName(examName)}</div>
                    </>
                  ))}
                  {/* <div className="w-1/5 border-r-2 py-2 px-2">Unit 1</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Unit 2</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Term 1</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Unit 3</div>
                  <div className="w-1/5 py-2 px-2">Term 2</div> */}
                </div>
              </div>
              <div>
                {Array.isArray(subjects) && subjects.length > 0 ? (
                  subjects.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs items-center ${
                          idx !== subjects.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-tableRowText">
                          {x?.subjectName.split(' ').length > 0 ? x?.subjectName.split(' ')[1] : x?.subjectName}
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.subjectName.split(' ')[0]}</div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.class?.name + '-' + x?.section.name}</div>
                        {SeqUnitTermTest.map((examName, examName_index) => ( 

                          <div className="w-1/5 px-2">
                            <button
                              className={`border ${
                                checkIfUploaded(x?.subjectId, examName) ? 'text-primary bg-white' : 'text-white bg-primary'
                              } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                              onClick={() => {
                                const _data = checkIfUploaded(x?.subjectId, examName);
                                select({ ...x, exam: examName });

                                _data
                                  ? ExportData( x,examName,_data)
                                  : handleOpenDownload(
                                    examName,
                                      x?.class,
                                      x?.section,
                                      x?.subjectName.split(' ').length > 0
                                        ? x?.subjectName.split(' ')[1]
                                        : x?.subjectName,
                                      x?.subjectId
                                    );
                              }}
                            >
                              {checkIfUploaded(x?.subjectId, examName) ? 'View' : 'Upload'}
                            </button>
                          </div>
                        ))}
                        {/*
                        <div className="w-1/5 px-2">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u1') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u1');
                              select({ ...x, exam: 'u1' });

                              _data
                                ? ExportData(_data, 'u1')
                                : handleOpenDownload(
                                    'u1',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u1') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u2') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u2');
                              select({ ...x, exam: 'u2' });

                              _data
                                ? ExportData(_data, 'u2')
                                : handleOpenDownload(
                                    'u2',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u2') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 't1') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 't1');
                              select({ ...x, exam: 't1' });

                              _data
                                ? ExportData(_data, 't1')
                                : handleOpenDownload(
                                    't1',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 't1') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u3') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u3');
                              select({ ...x, exam: 'u3' });

                              _data
                                ? ExportData(_data, 'u3')
                                : handleOpenDownload(
                                    'u3',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u3') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 't2') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 't2');
                              select({ ...x, exam: 't2' });

                              _data
                                ? ExportData(_data, 't2')
                                : handleOpenDownload(
                                    't2',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 't2') ? 'Download' : 'Upload'}
                          </button>
                        </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {ShowLoading && <LoadingPopup text={'Generating...'} />}

      { Show_schedule && (
        <Schedule
          schedule={schedule}
          onClose={() => {
            setSchedule(null);
            setShowSchedule(false);
            getSyllabus(SYLLABUS_GET, 'GET');
          }}
          setNotes={setNotes}
          data={selected}
          generateSchedule={generateSchedule}
          loading={loading}
        />
      )}
      {ViewSyllabus && 
        <div className="w-full bg-white rounded-xl h-auto mt-6">
          <div className="border-b border-borderYellow flex justify-between items-center px-6 py-4">
            <span className="block font-bold">{`Generate Schedule for  ${ViewSyllabus?.subjectName} - ${getExamName(ViewSyllabus?.exam)}`}</span>
            <div className="flex gap-x-4">
              { not_disable_Genrate && <button
                className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                  loading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  generateSchedule();
                }}
                disabled={loading}
              >
                Generate   {/* {loading ? 'Generating' : 'Generate again'} */}
              </button>
              }
              {/* <button
                className={`border border-primary px-6 rounded-3xl text-xs py-1.5 font-medium focus:outline-none text-white bg-primary 
                  opacity-100`
                } //  ${ uploadRes.isLoading ? 'opacity-50' : 'opacity-100' }
                onClick={(e) => {
                  // e.preventDefault();
                  // handleSave();
                }}
                // disabled={uploadRes.isLoading}
              >
              Save {/* {uploadRes.isLoading ? 'Saving...' : 'Save'} 
              </button> */}
            </div>
          </div>
          { not_disable_Genrate &&
          <div className="border-b border-borderYellow grid grid-cols-2">
            <div className="border-r border-borderYellow px-6 py-4">
              {/* <div className="mt-3">
                <p className="text-xs mb-2">How many online class test do you want to take?</p>
                <input
                  type="text"
                  className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
                  name="OnlineClassTest"
                  onChange={handleInputChange}
                />
              </div> */}
              <div className="mt-3">
                <p className="text-xs mb-2">How many class test do you want to take?</p>
                <input
                  type="text"
                  className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
                  name="OfflineClassTest"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="border-r border-borderYellow px-6 py-4">
              <div className="mt-3">
                <p className="text-xs mb-2">How many assignment do you want to take?</p>
                <input
                  type="text"
                  className="py-2 text-xs border border-borderLight px-2 w-full rounded-full"
                  name="assignments"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div> }
          { isArray_syallbus && (
            ViewSyllabus.Syallbus.map((arraySyallbus, index) => ( 
            <>
              <div className="px-6 py-4">{arraySyallbus.chapter}</div>
              <div className="px-6 py-4">{arraySyallbus.topic}</div>
            </> )) 
          ) } 

          { isArray_syallbus_not && (
               <div className="px-6 py-4">{ViewSyllabus.Syallbus}</div>
          ) } 
        </div>
      }
    </div>
  );
};

export default Syllabus;
