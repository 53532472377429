import React, { useState, useEffect } from 'react';
import BusRoute from '../../../components/Admin/Transport/BusRoute';
import RouteItem from '../../../components/Admin/Transport/RouteItem';

import useApi from '../../../api/useApi';
import { TRANSPORT_ROUTE } from '../../../api/urls/instituteManagement';
import Transport from '../../Owner/Transport/TransportDetails';

function index({ role }) {
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, selectRoute] = useState();

  const [{ isLoading, isError, data }, getTransportRoutes] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
    getTransportRoutes(TRANSPORT_ROUTE, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setRoutes(data || []);
    }
  }, [isLoading, isError, data]);

  // useEffect(() => {
  //   if (selectedRoute) {
  //     toggleView();
  //   }
  // }, [selectedRoute]);

  const refreshList = () => {
    getTransportRoutes(TRANSPORT_ROUTE, 'GET');
  };

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    selectRoute(null);
    // toggleView();
  };

  return (
    <>
      {open && <BusRoute toggleView={toggleView} refreshList={refreshList} selectedRoute={selectedRoute} />}
      {selectedRoute && <Transport toggleView={handleClose} refreshList={refreshList} selectedRoute={selectedRoute} />}

      <div className="w-full md:w-4/5">
        <div className="relative pb-10">
          <div className="w-full bg-white rounded-xl h-auto">
            <div className="items-center px-6 py-3.5 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-medium w-1/2">
                  <p>Bus Routes</p>
                </div>
                {role !== 'owner' && (
                  <div className="flex justify-end w-full">
                    <button
                      className="w-40 mr-4 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
                      onClick={toggleView}
                    >
                      Create Bus Route
                    </button>
                    {/* <DropDown title={'Sort by'} data={[]} width={'w-1/4'} /> */}
                  </div>
                )}
              </div>
            </div>
            <div className="p-4">
              <div className="grid md:grid-cols-3 grid-cols-1  w-full gap-4">
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : Array.isArray(routes) && routes.length > 0 ? (
                  routes.map((x) => <RouteItem item={x?.route} selectRoute={selectRoute} />)
                ) : (
                  <p className="md:col-span-3 text-tableRowText text-xl font-light leading-6 my-8 text-center">
                    No Data Available
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
