import React from 'react';

function FeeReceipt({ handleClose, fees }) {
  let monthWiseFee = {};

  if (fees?.receipt) {
    const { annualFee, examFee, student } = fees.receipt;

    const discount = student?.discount;

    for (const { month } of annualFee) {
      if (discount) {
        monthWiseFee[month] = [
          ...fees.receipt.monthlyFee?.map((x) => {
            const actualAmount = x.amount;
            const amount = discount?.inPercent
              ? x.amount - (x.amount * +discount?.discount) / 100
              : x.amount - +discount?.discount;
            return {
              ...x,
              discount: discount?.inPercent ? discount?.discount + '%' : 'Rs. ' + discount?.discount,
              amount,
              actualAmount,
            };
          }),
        ];
      } else {
        monthWiseFee[month] = [...fees.receipt.monthlyFee];
      }
      if (fees?.receipt?.transportFee) {
        monthWiseFee[month] = [...monthWiseFee[month], { name: 'Transport Fee', amount: fees?.receipt?.transportFee }];
      }

      let _annualFee = annualFee.filter((item) => item.month === month && item.fee);
      if (_annualFee.length > 0) {
        _annualFee.forEach((x) => {
          monthWiseFee[month] = [...monthWiseFee[month], ...x.fee];
        });
      }

      let _examFee = examFee.filter((item) => item.month === month);
      if (_examFee.length > 0) {
        _examFee.forEach((x) => {
          monthWiseFee[month] = [
            ...monthWiseFee[month],
            {
              name: 'Exam Fee',
              amount: x.fee,
            },
          ];
        });
      }
    }
  }

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-2/5 pb-6 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="text-sm relative border-b border-borderYellow flex justify-between mb-1 items-center py-5 px-6">
          <div className="font-bold">
            <p>
              {['Parent', 'finance_payment'].includes(fees?.role)
                ? 'Fee Breakup'
                : `Fee Receipt (${fees?.className} - ${fees?.sectionName})`}
            </p>
          </div>
          <div className="flex">
            <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className="px-4 w-full pt-4">
          {Object.entries(monthWiseFee).map((_x) => (
            <div
              key={_x}
              className="bg-white m-auto border rounded-2xl mb-4"
              style={{ borderColor: '#E7E700', width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <p className="text-center font-medium w-full text-xs py-1.5">{_x[0]}</p>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
                {_x[1]?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                        _x[1].length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/2 px-2 text-tableRowText">{x?.name}</div>
                      {x?.discount ? (
                        <div className="w-1/2 px-2">
                          <span className="text-textRed">{`Rs. ${x?.actualAmount} - ${x?.discount}`}</span>
                          {` = Rs. ${x?.amount}`}
                        </div>
                      ) : (
                        <div className="w-1/2 px-2">{'Rs. ' + x?.amount}</div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="font-medium w-full text-xs py-1.5 flex bg-tableHeader rounded-3xl px-2">
                  <div className="w-1/2 px-2 text-tableRowText">Total Fee</div>
                  <div className="w-1/2 px-2">
                    {'Rs. ' +
                      _x[1].reduce((a, c) => {
                        a += +c.amount;
                        return a;
                      }, 0)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeeReceipt;
