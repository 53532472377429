import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LOG_OUT } from '../../../api/urls/authentication';
import useApi from '../../../api/useApi';
import { useStateValue } from '../../../ContextApi/StateProvider';

const UserProfile = ({ profile, isCommonSetting = false }) => {
  const location = useLocation();
  const [expand, setExpand] = useState(isCommonSetting ? true : false);
  const history = useHistory();

  const [response, logOut] = useApi();

  const [_, dispatch] = useStateValue();

  const toggleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleLogout = () => {
    Cookies.remove('auth');
    sessionStorage.removeItem('session')
    sessionStorage.removeItem('year')
    sessionStorage.removeItem('isActive')

    dispatch({
      type: 'LOG_OUT',
    });
    history.push('/');
    logOut(LOG_OUT, 'DELETE');
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [201, 202, 200, 204].includes(response.status)) {
      // Cookies.remove('auth');
      // dispatch({
      //   type: 'TOKEN',
      //   token: null,
      // });
      // history.push('/');
    }

    if (response.isError) {
    }
  }, [response]);

  const getRole = (role) => {
    switch (role) {
      case 'st':
        return 'Student';
      case 'pr':
        return 'Principal';
      case 'ow':
        return 'Owner';
      case 'dir':
        return 'Director';
      case 'te':
        return 'Teacher';
      case 'ad':
        return 'Admin';
      case 'fi':
        return 'Finance';
      case 'pa':
        return 'Parent';
    }
  };

  const getFile = (user, fileName) => {
    if (!user?.fileArray) return '';
    let z = user.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '';
  };

  return (
    <div className="">
      <div className={`h-auto w-full bg-white rounded-2xl ${!isCommonSetting && 'shadow-sm'}`}>
        <div className={`flex ${isCommonSetting && 'gap-x-6'}`}>
          <img
            className="h-24 w-2h-24 rounded-tl-2xl rounded-br-2xl"
            alt=""
            // src="https://static.overlay-tech.com/assets/ca3ae4b6-1f0c-490b-ae1d-cd9784fc0ac5.png"
            src={getFile(profile?.user, 'profileImg') || '/assets/img/user_placeholder.svg'}
          />

          {/* <div className="flex justify-center items-center gap-x-4 w-full">
            <img
              className="h-9 w-9 cursor-pointer rounded-tl-2xl rounded-br-2xl"
              alt=""
              src="/assets/img/facebook.svg"
              onClick={() => {
                window.open('https://www.facebook.com/facebookappIndia', '_blank');
              }}
            />
            <img
              className="h-9 w-9 cursor-pointer rounded-tl-2xl rounded-br-2xl"
              alt=""
              src="/assets/img/linkedin.svg"
              onClick={() => {
                window.open('https://www.linkedin.com/', '_blank');
              }}
            />
            <img
              className="h-9 w-9 cursor-pointer rounded-tl-2xl rounded-br-2xl"
              alt=""
              src="/assets/img/insta.svg"
              onClick={() => {
                window.open('https://www.instagram.com/instagram/', '_blank');
              }}
            />
          </div> */}
        </div>
        <div className="pt-3 pl-4">
          <p className="text-lg text-textDark">{`${profile?.user?.full_name}`}</p>
          <p className="text-xs text-gray-600">{getRole(profile?.roles[0].role)}</p>
          <p className="text-sm mt-1.5 text-textDark opacity-60 ">{`ROLEDin ID - ${profile?.user?.username}`}</p>
        </div>

        <div className={`mx-4 overflow-hidden transition-all ${expand ? 'h-auto' : 'h-0'}`}>
          {/* <div className="flex items-center cursor-pointer my-4 bg-primary text-white rounded-full px-4 py-2" onClick={toggleNotification}>
            <img alt="expand" src="/assets/img/notification.svg" className="mr-5 cursor-pointer" />
            <span className=" text-xs">Notifications Settings</span>
          </div> */}

          <div
            className={`flex items-center cursor-pointer my-2 mt-4 ${
              location.search.includes('feedback') ? 'bg-primary text-white' : 'bg-white text-textDark'
            } rounded-full px-3.5 py-2`}
            onClick={() => history.push('/social?menu=feedback')}
          >
            <img alt="feedback" src="/assets/img/roledin icon.svg" className="mr-5 cursor-pointer" />
            <span className=" text-xs">Feedback for ROLEDin</span>
          </div>

          <div
            className={`flex items-center cursor-pointer my-2 ${
              location.search.includes('setting') ? 'bg-primary text-white' : 'bg-white text-textDark'
            } rounded-full px-4 py-2`}
            onClick={() => history.push('/social?menu=setting')}
          >
            <img
              alt="settings"
              src={location.search.includes('setting') ? '/assets/img/setting_white.svg' : '/assets/img/setting.svg'}
              className="mr-5 cursor-pointer"
            />
            <span className=" text-xs">Settings</span>
          </div>

          <a
            href="mailto:support@roledin.com"
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center cursor-pointer my-2 text-textDark
            } rounded-full px-4 py-2`}
          >
            <img alt="help" src="/assets/img/help.svg" className="mr-5 cursor-pointer" />
            <span className=" text-xs">Help</span>
          </a>

          <div
            className={`flex items-center cursor-pointer my-2 text-textDark rounded-full px-4 py-2`}
            onClick={handleLogout}
          >
            <img alt="logout" src="/assets/img/logout.svg" className="mr-5 cursor-pointer" />
            <span className=" text-xs">Log Out</span>
          </div>
        </div>

        {!isCommonSetting && (
          <button className="bg-transparent border-none w-full" onClick={() => toggleExpand()}>
            <img
              alt="expand"
              src={expand ? '/assets/img/collapseIcon.svg' : '/assets/img/expandIcon.svg'}
              className="mx-auto pt-4 pb-2"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
