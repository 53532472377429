import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useStateValue } from './ContextApi/StateProvider';

// Components
import Layout from './components/Layout';

// Pages
import Login from './pages/Login';
import About from './pages/About';
import Admin from './pages/Admin';
import Social from './pages/Social';
import Owner from './pages/Owner';
import Finance from './pages/Finance';
import Teacher from './pages/Teacher';
import Parents from './pages/Parents';
import Principal from './pages/Principal';
import Student from './pages/Student';

const App = () => {
  const [{ token }, dispatch] = useStateValue();

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            {token ? <Social /> : <Login />}
          </Route>
          <Route exact path="/healthcheck">
            <h2>Hello There!</h2>
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/admin">
            <Admin />
          </Route>
          <Route exact path="/owner">
            <Owner />
          </Route>
          <Route exact path="/finance">
            <Finance />
          </Route>
          <Route exact path="/teacher">
            <Teacher />
          </Route>
          <Route exact path="/parents">
            <Parents />
          </Route>
          <Route exact path="/principal">
            <Principal />
          </Route>
          <Route exact path="/social">
            <Social />
          </Route>
          <Route exact path="/student">
            <Student />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
