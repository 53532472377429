import styled from 'styled-components';
import fontFamily from '../../fontFamily';

export const CreatePostWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #e5e5e5ab;
  z-index: 6;
`;

export const CreatePostWindow = styled.form`
  background-color: ${fontFamily.colors.white};
  border-radius: 20px;
  box-shadow: 0 0 12px 0 ${fontFamily.colors.transparentBlack5};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 3%;
  top: 7%;
  width: 94%;

  @media screen and (min-width: 768px) {
    left: 25%;
    top: 10%;
    width: 44%;
  }
`;
export const FlexWrapperFive = styled.div`
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  postition: relative;
  width: 100%;
`;
export const CreatePostMainLabel = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;
export const RelativeWrapperOne = styled.div`
  cursor: pointer;
`;

export const CloseWindowIcon = styled.img``;
export const Divider = styled.div`
  margin-bottom: 26.5px;
  width: 100%;
  height: 1px;
  background: #e7e700;
`;
export const PostTextAffordance = styled.textarea`
  width: 100%;
  height: 12.98%;
  color: ${fontFamily.colors.Black};
  // margin-bottom: 172.5px;
  padding-left: 40px;
  // font-family: ${fontFamily.fonts.roboto14Regular.family};
  font-size: 16px;
  font-weight: ${fontFamily.fonts.roboto14Regular.weight};
  line-height: ${fontFamily.fonts.roboto14Regular.lineHeight};
`;
export const DividerTwo = styled.img`
  width: 99.94%;
  margin-bottom: 26.5px;
  margin-left: 0.5px;
`;
export const FlexWrapperSix = styled.div`
  margin-bottom: 12px;
  padding: 0 0 0 40px;
  display: flex;
  align-items: center;
`;
export const SelectSchoolLabel = styled.p`
  width: 100%;
  font-weight: 600;
  color: ${fontFamily.colors.black4};
  align-self: stretch;
  margin-right: 3.99px;
  font-size: 1rem;
`;
export const SelectMultipleAffordance = styled.p`
  width: 53.28%;
  height: 63.16%;
  color: ${fontFamily.colors.black4};
  font-size: ${fontFamily.fonts.roboto10Regular.size};
  font-weight: ${fontFamily.fonts.roboto10Regular.weight};
  line-height: ${fontFamily.fonts.roboto10Regular.lineHeight};
`;
export const FlexWrapperSeven = styled.div`
  margin-bottom: 1rem;
  padding: 0 0 0 53px;
  display: flex;
  align-items: flex-start;
`;
export const FlexWrapperTwo = styled.input`
  margin-right: 23px;
  padding: 3px;
  display: flex;
  align-items: center;
  width: 25px;
  border: 1px solid grey;
  height: 25px;
  border-radius: 5px;
`;
export const SchoolCheckboxIcon = styled.img`
  flex: 1;
  align-self: stretch;
  object-fit: cover;
`;
export const SchoolName1 = styled.p`
  width: 103.21%;
  height: 79.17%;
  color: ${fontFamily.colors.transparentBlack3};
  margin-top: 4px;
  font-size: ${fontFamily.fonts.roboto16Regular.size};
  font-weight: ${fontFamily.fonts.roboto16Regular.weight};
  line-height: ${fontFamily.fonts.roboto16Regular.lineHeight};
`;
export const FlexWrapperEight = styled.div`
  margin-bottom: 27px;
  padding: 0 0 0 53px;
  display: flex;
  align-items: flex-start;
`;
export const SchoolName2 = styled.p`
  width: 103.66%;
  height: 79.17%;
  color: ${fontFamily.colors.transparentBlack3};
  margin-top: 4px;
  font-size: ${fontFamily.fonts.roboto16Regular.size};
  font-weight: ${fontFamily.fonts.roboto16Regular.weight};
  line-height: ${fontFamily.fonts.roboto16Regular.lineHeight};
`;
export const FlexWrapperNine = styled.div`
  margin-bottom: 24px;
  padding: 0 0 0 53px;
  display: flex;
  align-items: flex-start;
`;
export const SchoolNAme3 = styled.p`
  width: 104.58%;
  height: 79.17%;
  color: ${fontFamily.colors.transparentBlack3};
  margin-top: 4px;
  font-size: ${fontFamily.fonts.roboto16Regular.size};
  font-weight: ${fontFamily.fonts.roboto16Regular.weight};
  line-height: ${fontFamily.fonts.roboto16Regular.lineHeight};
`;
export const FlexWrapperOne = styled.button`
  background-color: ${fontFamily.colors.darkOrange};
  border-radius: 26px;
  padding: 0.5rem 2.8rem;
  display: flex;
  align-items: center;
  margin: 1rem;
  margin-left: auto;
`;
export const PostButtonLabel = styled.p`
  color: ${fontFamily.colors.white};
  flex: 1;
  align-self: stretch;
  // font-family: ${fontFamily.fonts.roboto14Regular.family};
  font-size: 14px;
  font-weight: ${fontFamily.fonts.roboto14Regular.weight};
`;
