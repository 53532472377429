import React, { useEffect, useState } from 'react';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import Search from '../../UI/Search';
import moment from 'moment';

const excelHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'In Time', key: 'inTime' },
  { label: 'Out Time', key: 'outTime' },
  { label: 'Attendance Status', key: 'attendanceStatus' },
];
function StaffAttendance({ title, data }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {  // console.log("-- title : ", title ,"\n -- StaffAttendance : ", data );
    if (data && Array.isArray(data)) { 
      let x = data.map((x) => {
        return {
          name: x?.userDetail?.full_name,
          username: x?.userDetail?.username,
          // inTime: x?.inTime || '',
          // outTime: x?.outTime || '',
          inTime: moment(new Date(`2/2/2020 ${x?.inTime} UTC`)).format('hh:mm A'),
          outTime: moment(new Date(`2/2/2020 ${x?.outTime} UTC`)).format('hh:mm A'),
          attendanceStatus: !x.isPresent ? 'Absent' : 'Present',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  let filteredData = data;

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = data.sort((a, b) =>
        a.userDetail?.full_name.toLowerCase() > b.userDetail?.full_name.toLowerCase() ? 1 : -1
      );
    }
    if (sort === 'Name- z to a') {
      filteredData = data.sort((a, b) =>
        a.userDetail?.full_name.toLowerCase() < b.userDetail?.full_name.toLowerCase() ? 1 : -1
      );
    }
  }

  if (search) {
    filteredData = data.filter((x) => x.userDetail?.full_name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4 ">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>{title}</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'attendance.csv'} text={'Export Data'} />

            <Search
              placeholder={'Search staff'}
              width={'w-1/4'}
              onChange={(x) => setSearch(x)}
              onEnter={() => console.log('enter')}
            />

            <DropDown
              data={['Name- a to z', 'Name- z to a']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700 overflow-hidden">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">Name</div>
                <div className="w-1/5 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-1/5 border-r-2 py-2 px-2">In Time</div>
                <div className="w-1/5 border-r-2 py-2 px-2">Out Time</div>
                <div className="w-1/5 py-2 px-2">Attendance Status</div>
              </div>
            </div>
            <div className="customScrollBar overflow-auto" style={{ maxHeight: '55vh' }}>
              {filteredData && filteredData.length > 0 ? (
                filteredData?.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 ">{x.userDetail?.full_name}</div>
                        <div className="w-1/5 px-2 text-textDark ">{x.userDetail?.username}</div>
                        <div className={`w-1/5 px-2 text-textDark `}>
                          {x?.inTime ? moment(new Date(`2/2/2020 ${x?.inTime} UTC`)).format('hh:mm A') : '--'}
                        </div>
                        <div className={`w-1/5 px-2 text-textDark `}>
                          {x?.outTime ? moment(new Date(`2/2/2020 ${x?.outTime} UTC`)).format('hh:mm A') : '--'}
                        </div>
                        <div className={`w-1/5 px-2 text-textDark capitalize`}>{x.type}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffAttendance;
