import React, { useEffect, useState, useCallback } from 'react';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const Calender = ({ events }) => {
  const [selectedDay, selectDay] = useState();
  const [allEvents, setEvents] = useState([]);
  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(!(event.title.includes('PTM') || event.title.includes('MEETING')) && {
        className: 'eventColor',
      }),
    }),
    []
  );

  useEffect(() => {
    if (Array.isArray(events)) {
      const x = events.map((x) => {
        return {
          title: x.title,
          start: new Date(x.eventDate),
          end: new Date(x.eventDate),
          description: x?.description,
          startTime: moment(x.start).format('hh:mm a') || '---',
          endTime: moment(x.end).format('hh:mm a') || '---',
        };
      });
      setEvents(x);
    }
  }, [events]);

  return (
    <div className="relative mb-5">
      {selectedDay && (
        <div
          className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 pb-4 mb-auto mt-16 rounded-2xl relative">
            <span className="block font-bold py-4 px-6 border-b border-borderYellow">{`${moment(
              selectedDay.start
            ).format('DD MMM YYYY')}`}</span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectDay(null)}
            />
            <div className="mt-4 mb-4 mx-6 ">
              <div className="grid grid-cols-2">
                <div className="mt-2 mb-4 relative font-semibold ">
                  <p className="text-black text-sm font-bold mb-0.5">Start Time</p>
                  <p className="text-tableRowText text-sm">{selectedDay?.startTime}</p>
                </div>

                <div className="mt-2 mb-4 relative font-semibold ">
                  <p className="text-black text-sm font-bold mb-0.5">End Time</p>
                  <p className="text-tableRowText text-sm">{selectedDay?.endTime}</p>
                </div>
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">Title</p>
                <p className="text-tableRowText text-sm">{selectedDay?.title}</p>
              </div>

              <div className="mt-2 mb-4 relative font-semibold ">
                <p className="text-black text-sm font-bold mb-0.5">
                  {selectedDay?.type === 'MEETING' ? 'Agenda' : 'Description'}
                </p>
                <p className="text-tableRowText text-sm">{selectedDay?.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full bg-white rounded-xl pb-1 ">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold ">
              <p>Calender</p>
            </div>
            <div className="flex justify-end ml-auto">
              {/* <div className="flex gap-x-4 relative mx-2">
                <button className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center">
                  Upload CSV
                </button>
                <DropDown
                  data={[]}
                  width={'w-40'}
                  handleItemClick={(e) => {
                    setDropDown1(e);
                  }}
                  title={'Sort by'}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="bg-white border rounded-2xl border-borderYellow m-6 ">
          <Calendar
            localizer={localizer}
            events={allEvents}
            views={['month']}
            style={{ height: 450 }}
            popup
            onSelectEvent={(e) => selectDay(e)}
            eventPropGetter={eventPropGetter}
          />
        </div>
      </div>
    </div>
  );
};

export default Calender;
