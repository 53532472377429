import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { SECTION } from '../../../api/urls/institute';

function AddSection({ onClose, tabName, classes, refreshList }) {
  const [classId, setClass] = useState();
  const [section, setSection] = useState();

  const [{ isLoading, isError, status, data }, addSection] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!classId?.trim()) {
      alert('Please Select Class');
      return;
    }

    if (!section?.trim()) {
      alert('Please add Section');
      return;
    }

    const body = {
      section: section,
    };

    addSection(SECTION(classId), 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 202) {
      setClass();
      setSection();
      refreshList();
      onClose();
      alert('Section added successfully');
    }

    if (isError) {
      alert(data || 'Failed to add section');
    }
  }, [isLoading, isError, status, data]);

  return (
    <div
      className="flex justify-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-4/12 mb-auto mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          {tabName} Section
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        />
        <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label className="block text-parameter text-sm">
              Select Class<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="w-full text-xs rounded-3xl border px-3 mt-2 py-2.5 focus:outline-none">
              <select
                autoComplete="off"
                value={classId}
                onChange={(e) => setClass(e.target.value)}
                className="w-full text-xs  focus:outline-none"
              >
                {[{ id: '', name: 'Select' }, ...classes].map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label className="block text-parameter text-sm">
              Section Name<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              placeholder="Eg- A"
              autoComplete="off"
              value={section}
              required
              onChange={(e) => setSection(e.target.value)}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div>

          <div className="flex justify-end">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <button
                type="submit"
                className="mt-1 lg:mt-0 lg:w-max border px-8 py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center bg-primary text-white"
              >
                Add Section
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddSection;
