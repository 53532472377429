import React, { useEffect, useState } from 'react';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import Search from '../../../components/UI/Search';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Role', key: 'role' },
];

function MapAdditionalResponsibility({ data, selectUser, getRole, selectUserForRole }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      let x = data.data.map((x) => {
        return {
          name: x?.full_name,
          username: x?.username,
          role: x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ') || '',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [data]);

  let filteredData = data?.data || [];

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = filteredData.sort((a, b) => (a?.full_name.toLowerCase() > b?.full_name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name- z to a') {
      filteredData = filteredData.sort((a, b) => (a?.full_name.toLowerCase() < b?.full_name.toLowerCase() ? 1 : -1));
    }
  }

  if (search) {
    filteredData = filteredData.filter(
      (x) =>
        x?.full_name.toLowerCase().includes(search.toLowerCase()) ||
        x?.username.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div className="relative w-full pb-4">
      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold text-base w-full md:w-2/5">
              <p>Map Additional Responsibilities / Roles</p>
            </div>

            <div className="flex justify-end w-full md:w-3/5 gap-x-6">
              <ExcelExportBtn data={excelData} text={'Export Data'} headers={excelHeaders} fileName={'unmapped.csv'} />
              <DropDown
                data={['Name- a to z', 'Name- z to a']}
                width={'w-32 md:w-1/4'}
                title={'Sort By'}
                handleItemClick={(x) => {
                  setSort(x);
                }}
              />
              <Search
                onChange={(x) => {
                  setSearch(x);
                }}
                onEnter={() => {}}
                width={'w-36'}
                placeholder={'Search staff '}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl mx-5 border-borderYellow overflow-hidden min-w-700">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-xs font-medium px-2">
                  <div className="w-1/6 border-r-2 py-2 px-2">ROLEDin Id</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                  <div className="w-2/6 border-r-2 py-2 px-2">Role</div>
                  <div className="w-2/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                {data.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : (
                  filteredData.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                          filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/6 px-2 text-textDark">{x?.username}</div>
                        <div className="w-1/6 px-2 text-tableRowText">{x?.full_name} </div>
                        <div className="w-2/6 px-2 text-tableRowText">
                          {x?.currentlyMapped?.role?.map((x) => getRole(x)).join(', ')}
                        </div>

                        <div className="w-2/6 flex justify-center items-center px-4 gap-x-4">
                          <button
                            className="w-9/12 border border-primary text-white bg-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectUser(x);
                            }}
                          >
                            Map
                          </button>
                          <button
                            className="w-9/12 border border-primary text-white bg-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
                            onClick={() => {
                              selectUserForRole(x);
                            }}
                          >
                            Role
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MapAdditionalResponsibility);
