import React, { useState, useEffect, useRef } from 'react';

import useApi from '../../../api/useApi';
import { EXPENSE_REQUEST, EXPENSE_CATEGORIES } from '../../../api/urls/finance';
import moment from 'moment';
import { MONTHS } from '../../../constants';

function RaiseExpenseRequest({ onClose, refreshData, selectedRequest }) {
  const reciptUpload = useRef();
  const downloadRef = useRef();

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: new Date(),
    category: '',
    amount: '',
    bills: null,
  });

  const [categories, setCategories] = useState([]);
  const [categoryState, getCategories] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, raiseRequest] = useApi();

  useEffect(() => {
    if (selectedRequest) setFormData({ ...selectedRequest, category: selectedRequest?.category?.category });
  }, [selectedRequest]);

  useEffect(() => {
    if (!selectedRequest) getCategories(`${EXPENSE_CATEGORIES}&month=${MONTHS[new Date().getMonth()]}`, 'GET');
  }, []);

  useEffect(() => {
    if (!categoryState.isLoading && !categoryState.isError && categoryState.data) {
      setCategories(categoryState.data);
    }
  }, [categoryState.isLoading, categoryState.isError, categoryState.data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData.name?.trim()) str.push('Title');
    if (!formData.category) str.push('Category');
    if (!formData.description) str.push('Description');
    if (!formData.amount) str.push('Amount');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (formData.amount <= 0) {
      alert('Amount must be greater than zero');
      return;
    }

    if (!formData.bills) {
      alert('Please attach estimate');
      return;
    }

    const fd = new FormData();
    fd.append('name', formData.name);
    fd.append('description', formData.description);
    fd.append('date', formData.date);
    fd.append('category', formData.category);
    fd.append('amount', formData.amount);
    fd.append('bills', formData.bills);

    raiseRequest(EXPENSE_REQUEST, 'POST', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      setFormData({ name: '', description: '', date: '', amount: '' });
      refreshData();
      alert('Request raised successfully');
      onClose();
    }

    if (!isLoading && isError) {
      alert('Error in raising request');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12  rounded-2xl relative">
        <div className="border-b border-borderYellow flex justify-between items-center py-4 px-6">
          <span className=" font-bold text-textBlue1 ">{selectedRequest ? selectedRequest.name : 'Raise Request'}</span>
          <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={onClose} />
        </div>

        <div className="flex flex-wrap w-full px-2 pb-6">
          <form className="px-4 w-full" onSubmit={handleSubmit}>
            <div className="my-2  relative  w-full ">
              <label htmlFor="date" className="text-sm text-textDark font-medium">
                Request Date
              </label>
              <input
                autoComplete="off"
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                name="date"
                disabled
                value={moment(formData.date).format('DD MMMM YYYY')}
              />
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="name" className="text-sm text-textDark font-medium">
                Request Title<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Eg-new Computer"
                autoComplete="off"
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                name="name"
                disabled={!!selectedRequest}
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="my-2  relative font-semibold w-full">
              <label className=" text-sm text-textDark">
                Category<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                <select
                  type="text"
                  placeholder="Eg-Maintance"
                  autoComplete="off"
                  required
                  name="category"
                  disabled={!!selectedRequest}
                  value={formData.category}
                  onChange={handleInputChange}
                  className="w-full outline-none"
                >
                  <option value="">{selectedRequest ? formData.category : 'Select'}</option>
                  {Array.isArray(categories) &&
                    categories.map((x) => <option value={x?.category?._id}>{x.category?.category}</option>)}
                </select>
              </div>
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="description" className="text-sm text-textDark font-medium">
                Description<span className="text-red-500 ml-1">*</span>
              </label>
              <textarea
                type="text"
                required
                autoComplete="off"
                name="description"
                placeholder="..."
                rows="5"
                disabled={!!selectedRequest}
                value={formData.description}
                onChange={handleInputChange}
                className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="amount" className="text-sm text-textDark font-medium">
                Amount<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="number"
                name="amount"
                required
                placeholder="Eg-1999"
                disabled={!!selectedRequest}
                value={formData.amount}
                onChange={(e) => {
                  let inputValue = e.target.value || '';
                  if (e.target.value.startsWith('-')) {
                    inputValue = e.target.value.slice(1);
                  }
                  setFormData({ ...formData, amount: inputValue });
                }}
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 relative  w-full">
              <label className="text-sm text-textDark font-medium">
                Attach Estimate<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="relative flex">
                <input
                  hidden
                  ref={reciptUpload}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setFormData({ ...formData, bills: e.target.files[0] })}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <input
                  // required
                  type="text"
                  disabled
                  value={selectedRequest ? selectedRequest.files[0].name : formData.bills?.name}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                {selectedRequest && <a href={selectedRequest.files[0].link} ref={downloadRef} download hidden />}
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedRequest) downloadRef.current.click();
                    else reciptUpload.current.click();
                  }}
                >
                  {selectedRequest ? 'Download' : 'Upload'}
                </button>
              </div>
            </div>

            {!selectedRequest && (
              <div className="w-full flex justify-end">
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
                ) : (
                  <button
                    type="submit"
                    className="border w-32 py-2 mt-6 float-right rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    Submit
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default RaiseExpenseRequest;
