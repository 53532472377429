import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../../UI/DropDown';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { UPLOAD_ASSIGNMENT } from '../../../api/urls/notes';

function AssignmentDetails({ selectedAssignment, handleClose, role }) {
  const [file, setFile] = useState();
  const [assignment, selectAssignment] = useState('');
  const [reset, setReset] = useState(0);

  const [uploadRes, upload] = useApi();

  const uploadRef = useRef();

  useEffect(() => {
    if (file) {
      const fd = new FormData();
      fd.append('assignment', file);
      upload(
        UPLOAD_ASSIGNMENT(
          selectedAssignment.class_id,
          selectedAssignment.section_id,
          selectedAssignment.subject_id,
          assignment
        ),
        'POST',
        fd
      );
    }
  }, [file]);

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && [200, 201, 202].includes(uploadRes.status)) {
      alert('Successfully uploaded');
      selectAssignment('');
      setFile(null);
      setReset((prev) => (prev += 1));
      handleClose();
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('Failed to upload');
    }
  }, [uploadRes.isLoading, uploadRes.isError, uploadRes.data]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">Assignment - {selectedAssignment?.subject}</span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <DropDown
              width={'w-40'}
              data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
              title={'Sort by'}
              handleItemClick={(x) => {}}
              otherClasses={'border-borderLight'}
            />
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className=" bg-white  h-auto mb-5 w-full border-b border-borderYellow">
          <div className="flex flex-wrap w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/4 border-r-2 py-2 px-2">Title</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">Start Date</div>
                    <div className="w-1/4 border-r-2 py-2 px-2">Submission Date</div>
                    <div className="w-1/4 py-2 px-2">Status</div>
                  </div>
                </div>

                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                  {Array.isArray(selectedAssignment?.assignments) &&
                    selectedAssignment?.assignments?.map((x, idx) => (
                      <div
                        className={
                          selectedAssignment.assignments.length - 1 !== idx &&
                          `w-full ${'border-b border-borderYellow'}`
                        }
                      >
                        <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center">
                          <div className="w-1/4 text-tableRowText px-2">{x.title}</div>
                          <div className="w-1/4 text-tableRowText px-2">{x.start && moment(x.start).format('ll')}</div>
                          <div className="w-1/4 text-tableRowText px-2">{x.end && moment(x.end).format('ll')}</div>
                          <div className="w-1/4 text-tableRowText px-2">
                            {!x.files ? (
                              role === 'student' ? (
                                <>
                                  <input
                                    hidden
                                    ref={uploadRef}
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    key={reset}
                                    onChange={(e) => setFile(e.target.files[0])}
                                    className="w-34  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                                  />
                                  {assignment === x._id && uploadRes.isLoading ? (
                                    <img src="assets/img/loader.svg" className="w-8 mx-auto" alt="" />
                                  ) : (
                                    <button
                                      className="w-3/4 border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-primary"
                                      onClick={() => {
                                        uploadRef.current.click();
                                        selectAssignment(x._id);
                                      }}
                                    >
                                      Upload
                                    </button>
                                  )}
                                </>
                              ) : (
                                'Pending'
                              )
                            ) : (
                              <button
                                className="w-3/4 border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-borderLight text-textLight cursor-default"
                                disabled
                              >
                                Submitted
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          {Array.isArray(selectedAssignment?.assignments) &&
            selectedAssignment?.assignments.map((item) => (
              <div className="mt-4 w-full relative font-semibold mb-6">
                <span className="block text-base font-bold mb-3">{item.title}</span>
                <p className="text-sm font-medium text-gray-700">{item.summary}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default AssignmentDetails;
