import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import useApi from '../../../api/useApi';
import { TRANSPORT_ROUTE } from '../../../api/urls/instituteManagement';
import { isValidPhone } from '../../../utils';
import DropDown from '../../UI/DropDown';

function BusRoute({ toggleView, refreshList, selectedRoute }) {
  const [step, setStep] = useState(0);

  const formDataModel = {
    location: [],
    busDriverDetail: [],
    facultyPassenger: [],
    studentPassenger: [],
    routeName: '',
    pickUpRoute: null,
    dropUpRoute: null,
  };

  const routeModel = {
    start: '',
    end: '',
  };

  const locationModel = {
    location: '',
    time: null,
  };

  const busModel = {
    busNumber: '',
    driverName: '',
    driverLicenseNo: '',
    conductor: '',
    contact: '',
  };

  const passengerModel = {
    userName: '',
    name: '',
    pickupLocation: '',
    contact: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [pickUpState, setPickUpState] = useState(routeModel);
  const [dropState, setDropState] = useState(routeModel);
  const [locationState, setLocationState] = useState([locationModel]);
  const [busState, setBusState] = useState([busModel]);
  const [studentPassengerState, setStudentPassengerState] = useState([passengerModel]);
  const [facultyPassengerState, setFacultyPassengerState] = useState([passengerModel]);

  const [locationList, setLocationList] = useState([0]);
  const [busList, setBusList] = useState([0]);
  const [studentList, setStudentList] = useState([0]);
  const [facultyList, setFacultyList] = useState([0]);

  useEffect(() => {
    if (selectedRoute) {
      setFormData(selectedRoute);

      setLocationState(selectedRoute.location || []);
      setLocationList(selectedRoute.location.map((x, idx) => idx) || []);

      setPickUpState(selectedRoute.pickUpRoute || null);
      setDropState(selectedRoute.dropUpRoute || null);

      setBusState(selectedRoute.busDriverDetail || []);
      setBusList(selectedRoute.busDriverDetail.map((x, idx) => idx) || []);

      setStudentPassengerState(selectedRoute.studentPassenger || []);
      setStudentList(selectedRoute.studentPassenger.map((x, idx) => idx) || []);

      setFacultyPassengerState(selectedRoute.facultyPassenger || []);
      setFacultyList(selectedRoute.facultyPassenger.map((x, idx) => idx) || []);
    }
  }, [selectedRoute]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const handlePickupChange = (e) => setPickUpState({ ...pickUpState, [e.target.name]: e.target.value });
  const handleDropChange = (e) => setDropState({ ...dropState, [e.target.name]: e.target.value });
  const handleLocationChange = (e, index) => {
    const x = locationState;
    const y = {
      ...locationState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setLocationState([...x]);
  };
  const handleBusChange = (e, index) => {
    const x = busState;
    const y = {
      ...busState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setBusState([...x]);
  };
  const handleStudentChange = (e, index) => {
    const x = studentPassengerState;
    const y = {
      ...studentPassengerState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setStudentPassengerState([...x]);
  };
  const handleFacultyChange = (e, index) => {
    const x = facultyPassengerState;
    const y = {
      ...facultyPassengerState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setFacultyPassengerState([...x]);
  };

  const [{ isLoading, isError, data, status }, createTransportRoute] = useApi();

  const handleSubmit = () => {
    let str = [];
    facultyPassengerState.forEach((x, idx) => {
      let k = [];
      if (!x.userName?.trim()) k.push('RoledIn ID');
      if (!x.name?.trim()) k.push('Name');
      if (!x.contact?.trim()) k.push('Contact');
      if (!x.pickupLocation?.trim()) k.push('Pick up Location');

      if (k.length > 0) {
        str.push(`${k.join(', ')} missing in Details of Faculty-${idx + 1}`);
      }
    });

    if (str.length > 0) {
      alert(`${str.join(', ')}`);
      return;
    }

    let str2 = [];
    studentPassengerState.forEach((x, idx) => {
      let k = [];
      if (!x.userName?.trim()) k.push(' RoledIn ID');
      if (!x.name?.trim()) k.push('Name');
      if (!x.contact?.trim()) k.push('Contact');
      if (!x.pickupLocation?.trim()) k.push('Pick up Location');

      if (k.length > 0) {
        str2.push(`${k.join(', ')} missing in Details of Student-${idx + 1}`);
      }
    });

    if (str2.length > 0) {
      alert(`${str2.join(', ')}`);
      return;
    }

    const body = {
      ...formData,
      location: locationState,
      busDriverDetail: busState,
      facultyPassenger: facultyPassengerState,
      studentPassenger: studentPassengerState,
      pickUpRoute: pickUpState,
      dropUpRoute: dropState,
    };

    createTransportRoute(TRANSPORT_ROUTE, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      alert('Success');
      refreshList();
      toggleView();
      setFormData(formDataModel);
      setLocationState(locationModel);
      setPickUpState(routeModel);
      setDropState(routeModel);
      setBusList(busModel);
      setStudentPassengerState(passengerModel);
      setFacultyPassengerState(passengerModel);
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  const handleAddLocation = () => {
    if (locationList.length > 0) setLocationList([...locationList, locationList[locationList.length - 1] + 1]);
    else setLocationList([0]);
  };

  const removeLocation = (id) => {
    if (locationState.length === 1) setLocationState([]);
    else {
      locationState.splice(id, 1);
      setLocationState([...locationState]);
    }

    locationList.pop();
    setLocationList([...locationList]);
  };

  const handleAddBus = () => {
    if (busList.length > 0) setBusList([...busList, busList[busList.length - 1] + 1]);
    else setBusList([0]);
  };

  const removeBus = (id) => {
    if (busState.length === 1) setBusState([]);
    else {
      busState.splice(id, 1);
      setBusState([...busState]);
    }

    busList.pop();
    setBusList([...busList]);
  };

  const handleAddFaculty = () => {
    if (facultyList.length > 0) setFacultyList([...facultyList, facultyList[facultyList.length - 1] + 1]);
    else setFacultyList([0]);
  };

  const removeFaculty = (id) => {
    if (facultyPassengerState.length === 1) setFacultyPassengerState([]);
    else {
      facultyPassengerState.splice(id, 1);
      setFacultyPassengerState([...facultyPassengerState]);
    }

    facultyList.pop();
    setFacultyList([...facultyList]);
  };

  const handleAddStudent = () => {
    if (studentList.length > 0) setStudentList([...studentList, studentList[studentList.length - 1] + 1]);
    else setStudentList([0]);
  };

  const removeStudent = (id) => {
    if (studentPassengerState.length === 1) setStudentPassengerState([]);
    else {
      studentPassengerState.splice(id, 1);
      setStudentPassengerState([...studentPassengerState]);
    }

    studentList.pop();
    setStudentList([...studentList]);
  };

  const goto1 = (e) => {
    e.preventDefault();
    if (!formData?.routeName.trim()) {
      alert('Please add Route name');
      return;
    }

    let str = [];
    locationState.forEach((x, idx) => {
      let k = [];
      if (!x.location) k.push('Location');
      if (!x.time) k.push('Time');

      if (k.length > 0) {
        str.push(`${k.join(', ')} missing in Details of Passenger Pickup Location-${idx + 1}`);
      }
    });

    if (str.length > 0) {
      alert(`${str.join(', ')}`);
      return;
    }

    setStep(1);
  };

  const goto2 = () => {
    if (busState.length === 0) {
      alert('Please add Bus details');
      return;
    }

    let str = [];
    busState.forEach((x, idx) => {
      let k = [];
      if (!x.busNumber) k.push(' Bus Number');
      if (!x.driverName) k.push('Driver Name');
      if (!x.driverLicenseNo) k.push('Driver License No.');
      if (!x.contact) k.push('Contact');
      if (!x.conductor) k.push('Conductor');

      if (k.length > 0) {
        str.push(`${k.join(', ')} missing in Details of Bus-${idx + 1}`);
      }
    });

    if (str.length > 0) {
      alert(`${str.join(', ')}`);
      return;
    }

    setStep(2);
  };

  return (
    <div
      className=" w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="relative w-11/12 md:w-4/5 pb-6 mb-6 mx-auto h-auto mt-12 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-5">
            <div className="font-bold px-8">
              <p>Bus Route</p>
            </div>
            <div className="flex px-8">
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleView} />
            </div>
          </div>
        </div>

        <div className="py-2 px-8 relative grid grid-cols-2 md:grid-cols-3 gap-x-8 border-b border-borderYellow">
          <button
            onClick={() => selectedRoute && setStep(0)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 0 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Route
          </button>
          <button
            onClick={() => selectedRoute && setStep(1)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 1 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Bus and Driver
          </button>
          <button
            onClick={() => selectedRoute && setStep(2)}
            className={`py-2 w-full rounded-full text-xs ${
              step === 2 ? 'bg-primary border-primary text-white' : 'bg-white text-black'
            }`}
          >
            Passengers
          </button>
        </div>

        {step === 0 && (
          <form className="pt-6" onSubmit={goto1}>
            <div className="border-b border-borderYellow px-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 pb-2 w-full">
                <div className="mb-4 w-full relative ">
                  <label className="text-textDark font-medium text-sm">
                    Bus Route Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    disabled={!!selectedRoute}
                    required
                    value={formData?.routeName}
                    name="routeName"
                    placeholder="Enter Route Name"
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <p className="font-bold">Pickup Route</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 pb-2 w-full">
                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">Start</label>
                  <input
                    type="text"
                    disabled={!!selectedRoute}
                    value={pickUpState?.start}
                    name="start"
                    onChange={handlePickupChange}
                    placeholder="Enter route start location"
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">End</label>
                  <input
                    type="text"
                    disabled={!!selectedRoute}
                    value={pickUpState?.end}
                    name="end"
                    onChange={handlePickupChange}
                    placeholder="Eg-Enter route end location"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <p className="font-bold">Drop Route</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full ">
                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">Start</label>
                  <input
                    type="text"
                    value={dropState?.start}
                    disabled={!!selectedRoute}
                    name="start"
                    onChange={handleDropChange}
                    placeholder="Enter route start location"
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative ">
                  <label className="text-textDark font-medium text-sm">End</label>
                  <input
                    type="text"
                    value={dropState?.end}
                    disabled={!!selectedRoute}
                    name="end"
                    onChange={handleDropChange}
                    placeholder="Eg-Enter route end location"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="px-6 pt-4">
              {!selectedRoute && (
                <div className="flex flex-col md:flex-row justify-between items-center">
                  <p className="font-bold">Passenger Pickup Location</p>
                  <button
                    className="w-40 ml-auto border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddLocation();
                    }}
                  >
                    Add Location
                  </button>
                </div>
              )}

              {locationList.map((x) => (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 pb-2 w-full">
                  <div className="my-2 w-full relative ">
                    <label className="text-textDark font-medium text-sm">
                      {`Pickup location ${x + 1}`}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      value={locationState[x]?.location || ''}
                      name="location"
                      onChange={(e) => handleLocationChange(e, x)}
                      placeholder="Enter location"
                      disabled={!!selectedRoute}
                      autoComplete="off"
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative grid grid-cols-4 gap-x-4">
                    <div className={`w-full ${selectedRoute ? 'col-span-4' : 'col-span-3'}`}>
                      <label className="text-textDark font-medium text-sm">
                        Pickup Time<span className="text-red-500 ml-1">*</span>
                      </label>
                      {/* <input
                        type="time"
                        value={locationState[x]?.time}
                        name="time"
                        onChange={(e) => handleLocationChange(e, x)}
                        placeholder="Enter Time"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      /> */}
                      <div className="mt-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label=""
                            disabled={!!selectedRoute}
                            value={locationState[x]?.time}
                            onChange={(time) => handleLocationChange({ target: { name: 'time', value: time } }, x)}
                            slotProps={{
                              textField: {
                                size: 'small',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    {!selectedRoute && (
                      <button
                        onClick={() => removeLocation(x)}
                        className="mt-auto text-tableRowText col-span-1 w-full rounded-3xl border border-borderLight px-4 text-xs py-2 focus:outline-none"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {!selectedRoute && (
              <div className="flex gap-x-4 px-6 mt-3 justify-end">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setLocationList([0]);
                    setLocationState([locationModel]);
                    setPickUpState(routeModel);
                    setDropState(routeModel);
                    setFormData(formDataModel);
                  }}
                >
                  Reset
                </button>
                <button className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center">
                  Next
                </button>
              </div>
            )}
          </form>
        )}
        {step === 1 && (
          <div className="pt-4 w-full px-6">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="font-bold">Bus and Driver Detail</p>
              {!selectedRoute && (
                <button
                  className="w-36 border py-2 rounded-3xl ml-auto text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBus();
                  }}
                >
                  Add Bus
                </button>
              )}
            </div>
            <div className="w-full py-4 overflow-auto">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div
                    className={`grid ${
                      selectedRoute ? 'grid-cols-5' : 'grid-cols-6'
                    } w-full relative text-left text-xs  font-medium`}
                  >
                    <div className="w-full border-r-2 py-2 px-4">
                      Bus Number<span className="text-red-500 ml-1">*</span>
                    </div>
                    <div className="w-full border-r-2 py-2 px-4">
                      Driver Name<span className="text-red-500 ml-1">*</span>
                    </div>
                    <div className="w-full border-r-2 py-2 px-4">
                      Driver Driving License No<span className="text-red-500 ml-1">*</span>
                    </div>
                    <div className="w-full border-r-2 py-2 px-4">
                      Conductor<span className="text-red-500 ml-1">*</span>{' '}
                    </div>
                    <div className="w-full border-r-2 py-2 px-4">
                      Contact No<span className="text-red-500 ml-1">*</span>{' '}
                    </div>
                    {!selectedRoute && <div className="w-full py-2 px-4">Action</div>}
                  </div>
                </div>
                <div>
                  {busList.map((x, idx) => (
                    <div className={'w-full'}>
                      <div
                        className={`${
                          busList.length - 1 != idx && 'border-b border-borderYellow'
                        } justify-center items-center grid ${
                          selectedRoute ? 'grid-cols-5' : 'grid-cols-6'
                        } w-full text-left py-2 text-xs  font-medium`}
                      >
                        <div className="w-full px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter Bus No."
                            autoComplete="off"
                            disabled={!!selectedRoute}
                            value={busState[x]?.busNumber}
                            name="busNumber"
                            onChange={(e) => handleBusChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs py-2 focus:outline-none"
                          />
                        </div>
                        <div className="w-full px-4 text-tableRowText">
                          <input
                            type="text"
                            placeholder="Enter Driver Name"
                            autoComplete="off"
                            value={busState[x]?.driverName}
                            disabled={!!selectedRoute}
                            name="driverName"
                            onChange={(e) => handleBusChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs py-2 focus:outline-none"
                          />
                        </div>
                        <div className="w-full px-4 text-tableRowText">
                          <input
                            type="text"
                            placeholder="Enter License No."
                            autoComplete="off"
                            value={busState[x]?.driverLicenseNo}
                            name="driverLicenseNo"
                            disabled={!!selectedRoute}
                            onChange={(e) => handleBusChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs py-2 focus:outline-none"
                          />
                        </div>
                        <div className="w-full px-4 text-tableRowText">
                          <input
                            type="text"
                            placeholder="Enter Conductor Name"
                            autoComplete="off"
                            value={busState[x]?.conductor}
                            disabled={!!selectedRoute}
                            name="conductor"
                            onChange={(e) => handleBusChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs py-2 focus:outline-none"
                          />
                        </div>
                        <div className="w-full px-4 text-tableRowText">
                          <input
                            type="tel"
                            placeholder="Enter Contact Number"
                            autoComplete="off"
                            value={busState[x]?.contact}
                            name="contact"
                            disabled={!!selectedRoute}
                            onBlur={() => {
                              if (!isValidPhone(busState[x]?.contact)) {
                                alert('You have entered an invalid contact number');
                                handleBusChange({ target: { name: 'contact', value: '' } }, x);
                              }
                            }}
                            onChange={(e) => handleBusChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-x py-2 focus:outline-none"
                          />
                        </div>
                        {!selectedRoute && (
                          <div className="w-full px-4 text-tableRowText">
                            <button
                              onClick={() => removeBus(x)}
                              className="w-full rounded-3xl border border-primary text-primary px-4 text-x py-2 focus:outline-none"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {!selectedRoute && (
              <div className="flex justify-between items-center">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  Back
                </button>
                <div className="flex gap-x-4  mt-2 justify-end">
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setBusList([0]);
                      setBusState([busModel]);
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={goto2}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <>
            <div className="pt-4 w-full border-b border-borderYellow">
              <div className="flex flex-col md:flex-row justify-between px-6 items-center">
                <p className="font-bold">Faculty Passenger</p>
                {!selectedRoute && (
                  <button
                    className="w-40 border px-8 py-2 ml-auto rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddFaculty();
                    }}
                  >
                    Add Passenger
                  </button>
                )}
              </div>
              <div className="w-full py-4 px-6 overflow-auto">
                <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div
                      className={`grid ${
                        selectedRoute ? 'grid-cols-4' : 'grid-cols-5'
                      } w-full relative text-left text-xs  font-medium`}
                    >
                      <div className="w-full border-r-2 py-2 px-4">
                        ROLEDin ID<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Name<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Pick-up Location<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Contact<span className="text-red-500 ml-1">*</span>
                      </div>
                      {!selectedRoute && <div className="w-full py-2 px-4">Action</div>}
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll' }}>
                    {facultyList.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`${
                            facultyList.length - 1 != idx && 'border-b border-borderYellow'
                          } justify-center items-center flex w-full relative text-left py-2 text-xs  font-medium`}
                        >
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter RoledIn Id"
                              disabled={!!selectedRoute}
                              autoComplete="off"
                              value={facultyPassengerState[x]?.userName}
                              name="userName"
                              onChange={(e) => handleFacultyChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter Name"
                              autoComplete="off"
                              disabled={!!selectedRoute}
                              value={facultyPassengerState[x]?.name}
                              name="name"
                              onChange={(e) => handleFacultyChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <select
                              type="text"
                              placeholder="Enter Pickup location"
                              autoComplete="off"
                              value={facultyPassengerState[x]?.pickupLocation}
                              disabled={!!selectedRoute}
                              name="pickupLocation"
                              onChange={(e) => handleFacultyChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            >
                              <option value="">Select</option>
                              {locationState.map((c) => (
                                <option value={c.location} key={c.location}>
                                  {c.location}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter Contact"
                              autoComplete="off"
                              disabled={!!selectedRoute}
                              value={facultyPassengerState[x]?.contact}
                              onBlur={() => {
                                if (!isValidPhone(facultyPassengerState[x]?.contact)) {
                                  alert('You have entered an invalid contact number');
                                  handleFacultyChange({ target: { name: 'contact', value: '' } }, x);
                                }
                              }}
                              name="contact"
                              onChange={(e) => handleFacultyChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          {!selectedRoute && (
                            <div className="w-full px-4 text-tableRowText">
                              <button
                                onClick={() => removeFaculty(x)}
                                className="w-full rounded-3xl border border-primary text-primary px-4 text-x py-2 focus:outline-none"
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4 w-full px-6">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="font-bold">Student Passenger</p>
                {!selectedRoute && (
                  <button
                    className="w-40 border px-8 py-2 ml-auto rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddStudent();
                    }}
                  >
                    Add Passenger
                  </button>
                )}
              </div>
              <div className="w-full py-4 overflow-auto">
                <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div
                      className={`grid ${
                        selectedRoute ? 'grid-cols-4' : 'grid-cols-5'
                      } w-full relative text-left text-xs  font-medium`}
                    >
                      <div className="w-full border-r-2 py-2 px-4">
                        ROLEDin ID<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Name<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Pick-up Location<span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="w-full border-r-2 py-2 px-4">
                        Contact No<span className="text-red-500 ml-1">*</span>
                      </div>
                      {!selectedRoute && <div className="w-full py-2 px-4">Action</div>}
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll' }}>
                    {studentList.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`${
                            idx != studentList.length - 1 && 'border-b border-borderYellow'
                          } justify-center items-center flex w-full relative text-left py-2 text-xs  font-medium`}
                        >
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter RoledIn Id"
                              disabled={!!selectedRoute}
                              autoComplete="off"
                              value={studentPassengerState[x]?.userName}
                              name="userName"
                              onChange={(e) => handleStudentChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter Name"
                              disabled={!!selectedRoute}
                              autoComplete="off"
                              value={studentPassengerState[x]?.name}
                              name="name"
                              onChange={(e) => handleStudentChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <select
                              type="text"
                              disabled={!!selectedRoute}
                              placeholder="Enter Pickup location"
                              autoComplete="off"
                              value={studentPassengerState[x]?.pickupLocation}
                              name="pickupLocation"
                              onChange={(e) => handleStudentChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            >
                              <option value="">Select</option>
                              {locationState.map((c) => (
                                <option value={c.location} key={c.location}>
                                  {c.location}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-full px-4 text-tableRowText">
                            <input
                              type="text"
                              placeholder="Enter Contact"
                              autoComplete="off"
                              disabled={!!selectedRoute}
                              value={studentPassengerState[x]?.contact}
                              onBlur={() => {
                                if (!isValidPhone(studentPassengerState[x]?.contact)) {
                                  alert('You have entered an invalid contact number');
                                  handleStudentChange({ target: { name: 'contact', value: '' } }, x);
                                }
                              }}
                              name="contact"
                              onChange={(e) => handleStudentChange(e, x)}
                              className="w-full rounded-3xl border border-borderLight text-textDark px-4 text-xs mt-2 py-2 focus:outline-none"
                            />
                          </div>
                          {!selectedRoute && (
                            <div className="w-full px-4 text-tableRowText">
                              <button
                                onClick={() => removeStudent(x)}
                                className="w-full rounded-3xl border border-primary text-primary px-4 text-x py-2 focus:outline-none"
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {!selectedRoute && (
                <div className="flex justify-between items-center">
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Back
                  </button>
                  <div className="flex gap-x-4  mt-2 justify-end">
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStudentPassengerState([passengerModel]);
                        setFacultyPassengerState([passengerModel]);
                        setFacultyList([0]);
                        setStudentList([0]);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BusRoute;
