import React, { useState, useEffect } from 'react';
import DropDown from '../../UI/DropDown';
import { CLASS_SUBJECTS } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'Subject', key: 'subject' },
  { label: 'Total Marks', key: 'totalMarks' },
  { label: 'Subject Teacher', key: 'subjectTeacher' },
];

function ClassResult({ handleClose, selectSubjectFrrMarks, selectedClass, category }) {
  const [classSubjectsResponse, getClassSubjects] = useApi();
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (selectedClass) {
      getClassSubjects(CLASS_SUBJECTS(selectedClass.classId, selectedClass.sectionId), 'GET');
    }
  }, [selectedClass]);

  useEffect(() => {
    if (
      classSubjectsResponse &&
      classSubjectsResponse.data &&
      Array.isArray(classSubjectsResponse.data) &&
      classSubjectsResponse.data.length
    ) {
      const _data = classSubjectsResponse.data;
      let x = _data.map((x) => {
        return {
          subject: x.subject.subjectName || '--',
          totalMarks: getMarks(x),
          subjectTeacher: x.subject?.teacherId?.full_name || '',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [classSubjectsResponse]);

  const getMarks = (x) => {
    const sub = [];
    let marks = 0;
    x.result.forEach((y) => {
      if (!sub.includes(y.test_id)) {
        marks += +y.total_marks;
        sub.push(y.test_id);
      }
    });
    return marks;
  };

  return (
    <div className="relative w-11/12 md:w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="text-xs text-tableRowText mb-2.5 cursor-pointer">
            {`${category} > ${selectedClass?.class}-${selectedClass?.section}`}
          </div>
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>{`${selectedClass?.class}-${selectedClass?.section}`}</p>
            </div>
            <div className="flex justify-end items-center w-full">
              {/* <DropDown
                data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior - Secondary']}
                width={'w-1/4'}
                title={'Sort By'}
                handleItemClick={() => {}}
              /> */}
              <ExcelExportBtn
                data={excelData}
                headers={excelHeaders}
                text={'Export Data'}
                fileName={selectedClass?.class + '-' + selectedClass?.section + '_ClassMarks.csv'}
                width={'w-40'}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/4 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/4 border-r-2 py-2 px-2">Total Marks</div>
                  <div className="w-1/4 border-r-2 py-2 px-2">Subject Teacher</div>
                  <div className="w-1/4 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                {classSubjectsResponse.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : Array.isArray(classSubjectsResponse.data) && classSubjectsResponse.data.length > 0 ? (
                  classSubjectsResponse.data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                          idx !== classSubjectsResponse.data.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/4 px-2 text-textDark">{x.subject.subjectName}</div>
                        <div className="w-1/4 px-2 text-textDark">{getMarks(x)}</div>
                        <div className="w-1/4 px-2 text-textDark">{x.teacher?.full_name}</div>

                        <div className="w-1/4 px-2">
                          {!x.isViewed ? (
                            <button className="border border-tableRowText w-9/12 py-2 rounded-3xl text-tabborder-tableRowText font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs opacity-25 cursor-default">
                              View Marks
                            </button>
                          ) : (
                            <button
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() =>
                                selectSubjectFrrMarks({
                                  subject: x.subject,
                                  class: selectedClass,
                                  section: selectedClass,
                                  marks: getMarks(x),
                                })
                              }
                            >
                              View Marks
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>

          <button
            className="w-36 border px-8 py-2 rounded-3xl text-xs text-white border-primary bg-primary my-4 ml-auto mr-6 focus:outline-none flex justify-center items-center"
            onClick={() => handleClose()}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClassResult;
