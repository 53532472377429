import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'id' },
  { label: 'Roll No', key: 'rollNo' },
  { label: 'Student Name', key: 'name' },
  { label: 'Attendence', key: 'attendence' },
];

function TotalClassAttendance({ selectStudent, info, attendanceRes }) {
  const [excelData, setExcelData] = useState([]);
  const [sort, setSort] = useState('');

  useEffect(() => {
    if (!attendanceRes.isLoading && !attendanceRes.isError && attendanceRes.data && Array.isArray(attendanceRes.data)) {
      const _data = attendanceRes?.data;
      let x = _data.map((x) => {
        return {
          id: x?.studentInfo?.username,
          name: x?.studentInfo?.full_name,
          attendence: x?.attendance.cumulative + '%',
          rollNo: x?.studentInfo?.currentlyMapped?.rollNumber || '--',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [attendanceRes]);

  let filteredData = attendanceRes?.data || [];

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = filteredData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name- z to a') {
      filteredData = filteredData.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block justify-between items-center">
          <div className="font-bold w-6/12">
            <p>{`Attendance ${info.length > 0 ? info[0]?.classID?.name : ''} - ${
              info.length > 0 ? info[0]?.sectionID?.name : ''
            }`}</p>
          </div>
          <div className="flex justify-end items-center w-full">
            <ExcelExportBtn
              data={excelData}
              headers={excelHeaders}
              text={'Export Data'}
              fileName={'ClassTotalAttendence.csv'}
              width={'w-40'}
            />
            <DropDown
              data={['Name- a to z', 'Name- z to a']}
              width={'w-40'}
              title={'Sort By'}
              margin={'ml-4'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">Roll Number</div>
                <div className="w-3/12 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-5/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-4/12 border-r-2 py-2 px-2">Attendance Percentage</div>
                <div className="w-4/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {attendanceRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-8" alt="" />
              ) : filteredData.length > 0 ? (
                filteredData?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-textDark">
                        {x?.studentInfo?.currentlyMapped?.rollNumber || '--'}
                      </div>
                      <div className="w-3/12 px-2 text-textDark">{x?.studentInfo?.username}</div>
                      <div className="w-5/12 px-2 text-textDark">{x?.studentInfo?.full_name}</div>
                      <div className="w-4/12 px-2 text-textDark">{x?.attendance.cumulative + '%'}</div>
                      <div className="w-4/12 px-2">
                        <button
                          type="submit"
                          className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                          onClick={() => selectStudent(x)}
                        >
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalClassAttendance;
