import React, { useState, useEffect } from 'react';
import { MEETING_STATUS_CHANGE } from '../../../api/urls/appointment';
import useApi from '../../../api/useApi';
import moment from 'moment';

function ParentMeetingRequest({ setStatus, meetings, refresh }) {
  const [id, setId] = useState();
  const [response, changeStatus] = useApi();

  const handleAccept = (id) => {
    setId(id);
    const body = {
      status: 'Approved',
    };

    changeStatus(MEETING_STATUS_CHANGE(id), 'PUT', body);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && response.status === 202) {
      alert('Success');
      setId();
      refresh();
    }

    if (!response.isLoading && response.isError) {
      alert('Failed');
    }
  }, [response]);

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-4/12">
            <p>Meeting Request from Parents</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 pr-2">Student Name</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Parent Name</div>
                <div className="w-1/12 border-r-2 py-2 px-2">Class</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Agenda</div>
                <div className="w-3/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {meetings?.data?.length > 0 ? (
                meetings?.data?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                        meetings?.data?.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-tableRowText">{x?.meeting?.child_id?.full_name}</div>
                      <div className="w-2/12 px-2 text-tableRowText">{x?.meeting?.user_id?.full_name}</div>
                      <div className="w-1/12 px-2 text-tableRowText">{x.class + ' - ' + x.section}</div>
                      <div className="w-2/12 px-2 text-tableRowText">{moment(x?.meeting?.date).format('ll')}</div>
                      <div className="w-2/12 px-2 text-tableRowText">
                        <button
                          className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            setStatus({
                              option: 'View',
                              x: {
                                date: x?.meeting?.date,
                                reason: x?.meeting?.reason,
                              },
                            });
                          }}
                        >
                          View
                        </button>
                      </div>
                      <div className="w-3/12 flex items-center px-2 gap-x-4">
                        {x?.meeting?.status ? (
                          x?.meeting?.status === 'Approved' ? (
                            <div className="py-2 text-tableRowText  rounded-3xl text-xs focus:outline-none">
                              Accepted
                            </div>
                          ) : (
                            <div className="py-2 text-tableRowText rounded-3xl text-xs focus:outline-none">
                              Rejected
                            </div>
                          )
                        ) : (
                          <>
                            {response.isLoading && id === x?.meeting?._id ? (
                              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
                            ) : (
                              <button
                                className="w-full lg:w-max border px-8 py-2 border-primary text-white bg-primary rounded-3xl text-xs focus:outline-none"
                                onClick={() => handleAccept(x?.meeting?._id)}
                              >
                                Accept
                              </button>
                            )}
                            <button
                              className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                setStatus({ option: 'Reject', x });
                              }}
                            >
                              Reject
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentMeetingRequest;
