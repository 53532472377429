import React, { useState, useEffect } from 'react';
import Chart from '../../Chart';
import useApi from '../../../api/useApi';
import { BUDGET } from '../../../api/urls/finance';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import DropDown from '../../UI/DropDown';

const excelHeaders = [
  { label: 'Student Name', key: 'name' },
  { label: 'Roll No.', key: 'rollno' },
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Class-Section', key: 'class' },
  { label: 'Pending Fee Amount', key: 'feePending' },
];

function Budget() {
  const [{ isLoading, isError, data }, getBudget] = useApi();
  const [budget, setBudget] = useState();
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    getBudget(BUDGET(''), 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const total = data?.totalBudget;
      const expenses = data?.totalExpense;
      const received = data?.fees?.reduce((acc, cuu) => (acc = acc + (+cuu?.amount || 0)), 0) + data?.MiscFeeAmount ;
      setBudget({ total, expenses, received });
    }
  }, [isLoading, isError, data]);

  const handleClick = () => {};

  const getAmountRounded = (amount) => {
    var val = Math.abs(amount);
    // if (val >= 10000000) {
    //   val = (val / 10000000).toFixed(2) + ' Crore';
    // } else if (val >= 100000) {
    //   val = (val / 100000).toFixed(2) + ' Lakhs';
    // } else if (val >= 1000) {
    //   val = (val / 1000).toFixed(2) + ' Thousand';
    // }
    return '\u20B9' + val;
  };

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="lg:flex block sm:block items-center">
          <div className="font-bold text-base">
            <p>Expense</p>
          </div>
          <div className="flex justify-end ml-auto">
            {/* <ExcelExportBtn
              data={excelData}
              text={'Export Data'}
              width={'w-36'}
              fileName={'Report.csv'}
              handleClick={handleClick}
              headers={excelHeaders}
            /> */}
            <DropDown
              data={[
                'Yearly',
                // 'Overall Budget', 'Quaterly', 'Half Yearly'
              ]}
              width={'w-36'}
              margin={'ml-4'}
              handleItemClick={() => {}}
            />
          </div>
        </div>
      </div>
      {/* <div className="flex flex-wrap w-full h-1/4"> */}
      <div className="flex flex-wrap w-full md:h-80">
        <div className="w-full md:w-2.25/5 py-2 md:pl-5">
          <div className="bg-yellow-50 rounded-xl block pl-6 py-4">
            <p className="text-xs opacity-50"> Total Amount spent</p>
            <p className="text-xl text-textRed">{getAmountRounded(budget?.expenses)}</p>
            <p className="text-xs opacity-50"> Total Budget</p>
            <p className="text-xl font-normal">
              {' '}
              {getAmountRounded(budget?.total)}{' '}
              <span className="text-base">
                {' '}
                {`(${Math.round((budget?.expenses / budget?.total) * 100) || 0}% Spent)`}
              </span>
            </p>
          </div>
          <div className="bg-yellow-50 rounded-xl block pl-6 py-4 my-4">
            <p className="text-xs opacity-50"> Total Amount Recieved</p>
            <p className="text-xl text-textGreen">{getAmountRounded(budget?.received)}</p>
          </div>
        </div>
        <div className="w-2/10" />
        <div className="w-full md:w-2.25/5 h-72 rounded-xl relative mt-2 border border-borderYellow">
          <div className="h-full w-full p-3 ">
            <div className="m-auto" style={{ height: '100%', width: '100%' }}>
              <Chart
                key={budget?.total}
                budget={budget?.total}
                spent={budget?.total < budget?.expenses ? budget?.total : budget?.expenses}
                extra={budget?.total < budget?.expenses ? budget?.expenses - budget?.total : 0}
                recieved={budget?.received}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Budget;
