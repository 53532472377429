import React from 'react';
import { useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import Academics from '../Owner/Academics';
import Budget from '../Owner/Budget';
import Meeting from '../Owner/Meeting';
import Feedback from '../Parents/Feedback';
import Staff from './Staff';
import StudentReport from './StudentReport';
import IncidentReport from './IncidentReport';
import AttendanceReport from './AttendanceReport';
import ManageLeaves from './ManageLeaves';
import StudentManagement from './StudentManagement';
import Transport from './Transport';
import Events from './Events';
import AcademicCalender from '../Principal/AcademicCalender';


const Admin = () => {
  const location = useLocation();

  return (
    <div>
      {location.search === '' && <Dashboard />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('Academics') && <Academics />}
      {location.search.includes('StudentManagement') && <StudentManagement />}
      {location.search.includes('Staff') && <Staff />}
      {location.search.includes('Attendance') && <AttendanceReport />}
      {location.search.includes('Budget') && <Budget />}
      {location.search.includes('Event') && <Events />}
      {location.search.includes('Leaves') && <ManageLeaves role={''} />}
      {location.search.includes('Incident') && <IncidentReport />}
      {location.search.includes('StudentReport') && <StudentReport />}
      {location.search.includes('Meeting') && <Meeting />}
      {location.search.includes('Feedback') && <Feedback role="Admin" />}
      {location.search.includes('Transport') && <Transport />}
    </div>
  );
};
export default Admin;
