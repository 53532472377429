export const getFeeCycleMonths = (cycle) => {
  if (!cycle) return '';
  const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const startMonth = new Date(cycle.start_date).getMonth() + 1;
  const endMonth = new Date(cycle.end_date).getMonth() + 1;

  let monthStr = [];

  if (startMonth < endMonth) {
    monthStr = months.slice(startMonth, endMonth + 1);
  } else {
    monthStr = [...months.slice(startMonth)];
    monthStr = [...monthStr, months.slice(1, endMonth + 1)];
  }

  return monthStr.join(', ');
};

export const getRole = (role) => {
  switch (role) {
    case 'st':
      return 'Student';
    case 'pr':
      return 'Principal';
    case 'ow':
      return 'Owner';
    case 'te':
      return 'Teacher';
    case 'ad':
      return 'Admin';
    case 'fi':
      return 'Finance';
    case 'pa':
      return 'Parent';
  }
};

export const convertToNumber = (str) => {
  if (typeof str === 'number') {
    return str;
  }
  if (typeof str === 'string') {
    const num = parseInt(str, 10);
    if (isNaN(num)) {
      const numSuffix = str.slice(-2);
      if (numSuffix === 'th' || numSuffix === 'st' || numSuffix === 'rd' || numSuffix === 'nd') {
        return parseInt(str.slice(0, -2), 10);
      }
    }
    return num;
  }
  return str;
};

export function isValidEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function isValidPhone(inputtxt) {
  var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  if (inputtxt.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

export function getRandomNumber(min, max, float) {
  if (float) return Math.random() * (max - min) + min;
  else return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getExamName(x) {
  if (x[0] === 'u') return 'Unit ' + x[1];
  else return 'Term ' + x[1];
}
