/* This is the project stylesheet */

export default {
  colors: {
    black: "rgba(33,33,33,1)",
    black4: "rgba(0,0,0,1)",
    darkCyan: "rgba(0,122,181,1)",
    darkOrange: "rgba(242,121,0,1)",
    darkOrchid: "rgba(143,57,206,1)",
    dodgerBlue: "rgba(0,110,239,1)",
    forestGreen: "rgba(55,173,0,1)",
    gold: "rgba(231,231,0,1)",
    green: "rgba(0,159,16,1)",
    khaki: "rgba(251,225,138,1)",
    mediumVioletRed: "rgba(213,54,146,1)",
    red: "rgba(220,0,0,1)",
    royalBlue: "rgba(0,70,229,1)",
    salmon: "rgba(247,82,116,1)",
    sandyBrown: "rgba(252,187,69,1)",
    silver: "rgba(196,196,196,1)",
    slateBlue: "rgba(91,79,233,1)",
    transparentBlack: "rgba(0,0,0,0.5)",
    transparentBlack2: "rgba(0,0,0,0.24)",
    transparentBlack3: "rgba(33,33,33,0.72)",
    transparentBlack4: "rgba(33,33,33,0.24)",
    transparentBlack5: "rgba(0,0,0,0.25)",
    transparentBlack6: "rgba(33,33,33,0.5)",
    transparentDarkOrange: "rgba(242,121,0,0.24)",
    transparentDarkOrange2: "rgba(242,121,0,0.5)",
    transparentDarkOrange3: "rgba(242,121,0,0.75)",
    transparentGold: "rgba(231,231,0,0.48)",
    transparentMidnightBlue: "rgba(0,49,122,0.5)",
    transparentRed: "rgba(255,0,0,0.16)",
    transparentWhite: "rgba(255,255,255,0.75)",
    white: "rgba(255,255,255,1)",
    whiteSmoke: "rgba(239,239,239,1)"
  },
  fonts: {
    lato10Regular: {
      family: "Lato",
      size: "10px",
      weight: "400",
      lineHeight: "normal"
    },
    lato11Regular: {
      family: "Lato",
      size: "11px",
      weight: "400",
      lineHeight: "normal"
    },
    lato12Regular: {
      family: "Lato",
      size: "12px",
      weight: "400",
      lineHeight: "normal"
    },
    lato14Bold: {
      family: "Lato",
      size: "14px",
      weight: "700",
      lineHeight: "normal"
    },
    lato14Regular: {
      family: "Lato",
      size: "14px",
      weight: "400",
      lineHeight: "normal"
    },
    lato16Regular: {
      family: "Lato",
      size: "16px",
      weight: "400",
      lineHeight: "normal"
    },
    lato18Regular: {
      family: "Lato",
      size: "18px",
      weight: "400",
      lineHeight: "normal"
    },
    lato20Bold: {
      family: "Lato",
      size: "20px",
      weight: "700",
      lineHeight: "normal"
    },
    lato20Regular: {
      family: "Lato",
      size: "20px",
      weight: "400",
      lineHeight: "normal"
    },
    lato25Regular: {
      family: "Lato",
      size: "25px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto10Medium: {
      family: "Roboto",
      size: "10px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto10Regular: {
      family: "Roboto",
      size: "10px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto11Medium: {
      family: "Roboto",
      size: "11px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto11Regular: {
      family: "Roboto",
      size: "11px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto12Bold: {
      family: "Roboto",
      size: "12px",
      weight: "700",
      lineHeight: "normal"
    },
    roboto12Medium: {
      family: "Roboto",
      size: "12px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto12Regular: {
      family: "Roboto",
      size: "12px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto13Medium: {
      family: "Roboto",
      size: "13px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto14Medium: {
      family: "Roboto",
      size: "14px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto14Regular: {
      family: "Roboto",
      size: "14px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto16Medium: {
      family: "Roboto",
      size: "16px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto16Regular: {
      family: "Roboto",
      size: "16px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto18Medium: {
      family: "Roboto",
      size: "18px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto20Medium: {
      family: "Roboto",
      size: "20px",
      weight: "500",
      lineHeight: "normal"
    },
    roboto7Regular: {
      family: "Roboto",
      size: "7px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto8Bold: {
      family: "Roboto",
      size: "8px",
      weight: "700",
      lineHeight: "normal"
    },
    roboto8Regular: {
      family: "Roboto",
      size: "8px",
      weight: "400",
      lineHeight: "normal"
    },
    roboto9Bold: {
      family: "Roboto",
      size: "9px",
      weight: "700",
      lineHeight: "normal"
    },
    roboto9Regular: {
      family: "Roboto",
      size: "9px",
      weight: "400",
      lineHeight: "normal"
    }
  }
};