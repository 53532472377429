import React from 'react';

function RouteItem({ selectRoute, item }) {
  return (
    <div className=" border border-borderYellow rounded-xl">
      <div className=" py-5 relative border-b border-borderYellow">
        <div className="w-full px-5 flex text-tableRowText ">
          <div className="w-1/3">
            <span className="text-xs text-tableRowText ">Total Buses</span>
            <p className="text-3xl font-light text-tableRowText">{item?.busDriverDetail.length}</p>
          </div>
          <div className="w-1/3">
            <span className="text-xs text-tableRowText ">Total Faculty</span>
            <p className="text-3xl font-light text-tableRowText">{item?.facultyPassenger.length}</p>
          </div>
          <div className="w-1/3">
            <span className="text-xs text-tableRowText ">Total Students</span>
            <p className="text-3xl font-light text-tableRowText">{item?.studentPassenger.length}</p>
          </div>
        </div>
      </div>
      <div className="h-14 w-full flex items-center justify-between px-5">
        <span className=" text-tableRowText">{'Route No ' + item?.routeName}</span>
        <div
          className=" text-center text-xs text-tableRowText py-2 px-5 rounded-3xl border cursor-pointer border-tableRowText"
          onClick={() => selectRoute(item)}
        >
          {' '}
          Show More
        </div>
      </div>
    </div>
  );
}

export default RouteItem;
