import React, { useEffect, useState } from 'react';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import { UPDATE_INVENTORY, INVENTORY } from '../../../api/urls/finance';
import useApi from '../../../api/useApi';

function AddInventory({ handleClose, selectedInventory }) {
  const formDataModel = {
    category: '',
    type: '',
    size: '',
    price: '',
    quantity: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [reset, setReset] = useState(0);

  const [response, mutate] = useApi();

  useEffect(() => {
    if (selectedInventory) {
      setFormData({
        ...selectedInventory,
        quantity: 0,
      });
    }
  }, [selectedInventory]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.category) {
      alert('Please category');
      return;
    }

    if (!formData.type) {
      alert('Please add Inventory name');
      return;
    }

    const body = {
      ...formData,
    };

    if (!selectedInventory) mutate(INVENTORY, 'POST', body);
    else mutate(UPDATE_INVENTORY(selectedInventory._id), 'PUT', body);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [201, 202, 200].includes(response.status)) {
      alert('SUCCESS');
      handleReset();
      handleClose(true);
    }

    if (!response.isLoading && response.isError) {
      alert(response?.data || 'FAILED');
    }
  }, [response]);

  const handleReset = () => {
    setFormData({
      ...formDataModel,
    });
    setReset((prev) => ++prev);
  };

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-9/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-3 flex justify-between items-center">
          <span className="block font-bold ">{`Add Inventory`}</span>
          <div className="flex items-center">
            <img
              src="assets/img/Close.svg"
              className=" h-3 ml-4 cursor-pointer"
              alt=""
              onClick={() => {
                handleClose(false);
              }}
            />
          </div>
        </div>
        <div className="mt-2">
          <div className="w-full px-6 overflow-auto">
            <form
              className="mt-2 mb-4 mx-4 grid grid-cols-1 md:grid-cols-2 gap-x-4"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="mt-2 mb-4 w-full relative font-semibold ">
                <label className="block text-sm text-textDark">
                  Category<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  handleItemClick={(x) => {
                    setFormData({
                      ...formData,
                      category: x,
                    });
                  }}
                  key={reset}
                  title={formData?.category || 'Select Category'}
                  data={['Uniform', 'Other']}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  required
                />
              </div>
              <div className="mt-2 w-full relative font-semibold disabled">
                <label htmlFor="testType" className="block text-sm text-textDark">
                  Inventory Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  name="type"
                  key={reset}
                  value={formData?.type || ''}
                  required
                  onChange={handleInputChange}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="mt-2 mb-4 w-full relative font-semibold ">
                <label className="block text-sm text-textDark">
                  Size{formData?.category === 'Uniform' && <span className="text-red-500 ml-1">*</span>}
                </label>
                <input
                  type="text"
                  name="size"
                  required={formData?.category === 'Uniform'}
                  key={reset}
                  onChange={handleInputChange}
                  value={formData?.size || ''}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="mt-2 mb-4 w-full relative font-semibold ">
                <label className="block text-sm text-textDark">
                  Price <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="number"
                  name="price"
                  onChange={handleInputChange}
                  key={reset}
                  required
                  value={formData?.price || ''}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
              {selectedInventory && (
                <div className="mt-2 mb-4 w-full relative font-semibold">
                  <label className="block text-sm text-textDark">Available Quantity</label>
                  <input
                    type="number"
                    name="quantity"
                    disabled
                    value={selectedInventory?.quantity || 0}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
              )}
              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label className="block text-sm text-textDark">
                  Quantity<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="number"
                  name="quantity"
                  onChange={handleInputChange}
                  key={reset}
                  required
                  value={formData?.quantity || ''}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
              {selectedInventory && (
                <div className="mt-2 mb-4 w-full relative font-semibold">
                  <label className="block text-sm text-textDark">Total Quantity</label>
                  <input
                    type="number"
                    disabled
                    value={+formData?.quantity + selectedInventory.quantity}
                    className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
              )}
              <div className="w-full col-span-2 mb-5 relative font-semibold mt-3">
                {response.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <div className="flex float-right">
                    <button
                      className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border border-tableRowText px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center "
                      onClick={(e) => {
                        e.preventDefault();
                        handleReset();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddInventory;
