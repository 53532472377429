import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ _data, color }) => {
  const data = {
    labels: ['Fully Engaged', 'Engaged', 'DisEngaged', 'Fully DisEngaged'],
    datasets: [
      {
        label: false,
        data: _data,
        fill: false,
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (context, args) => {
          const index = args.dataIndex;
          return args.chart.data.labels[index];
        },
        color: 'black',
        anchor: 'center',
        align: 'end',
        // clamp: true,
        // clip: false,
      },
    },
  };

  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
