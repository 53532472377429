import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import useApi from '../../api/useApi';
import { LOGIN } from '../../api/urls/authentication';
import { USER_PROFILE } from '../../api/urls/profile';
import { useStateValue } from '../../ContextApi/StateProvider';
import { ROLE } from '../../api/urls/baseUrls';
import { FORGET_PASSWORD } from '../../api/urls/authentication';

import './style.css';

const Login = () => {
  const [displayPwd, setDisplayPwd] = useState(false);
  const [isChecked, setChecked] = useState(localStorage.checked || false);
  const [step, setStep] = useState('login');
  const [username, setUsername] = useState('');
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const [{ token }, dispatch] = useStateValue();

  const [{ isLoading, isError, data }, login] = useApi();
  const [roleState, sendRole] = useApi();
  const [profileRes, getProfile] = useApi();
  const [pwdRes, callForgetPwd] = useApi();

  const formModel = {
    username: localStorage.username || '',
    password: localStorage.password || '',
    grant_type: 'password',
  };

  const [formData, setFormData] = useState(formModel);

  const handleInputChange = useCallback((e) => setFormData({ ...formData, [e.target.name]: e.target.value.trim() }));

  useEffect(() => {
    if (location?.search?.indexOf('forgot') > 0) {
      setStep('forgetPassword');
    } else if (location?.search?.indexOf('success') > 0) {
      setStep('passwordSuccess');
    } else {
      setStep('login');
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(LOGIN, 'POST', new URLSearchParams(formData));

    if (isChecked) {
      localStorage.username = formData.username;
      localStorage.password = formData.password;
      localStorage.checked = true;
    }
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      if (data.expires_in) {
        if ( sessionStorage.getItem('session') ) { sessionStorage.removeItem('session' );  }
        const expires = new Date(new Date().getTime() + data.expires_in * 1000);
        Cookies.set('auth', JSON.stringify(data), { expires });
        Cookies.set('expiry', expires.getTime(), { expires });
        localStorage.refresh_token = data.refresh_token;

        dispatch({
          type: 'TOKEN',
          token: data,
        });
        //
        getProfile(USER_PROFILE, 'GET');
      } else {
        alert('Either Username or Password is incorrect');
      }
    }

    if (!isLoading && isError) {
      if (data?.error_description) alert(data?.error_description);
      else {
        alert('Either Username or Password is incorrect');
      }
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (token) {
      history.replace('/');
    }
  }, [token]);

  useEffect(() => {
    if (!profileRes.isLoading && !profileRes.isError && profileRes.data) {
      dispatch({
        type: 'USER',
        user: profileRes.data,
      });

      sendRole(ROLE, 'PUT', {
        // child_id: '5efa280a423118ba665956d5',
        child_id: profileRes.data.roles[0].role === 'pa' ? profileRes.data.roles[0].child_id : '',
        // institute_id: '5e5fb81471f4781624da8c00',
        institute_id: profileRes.data.roles[0].institute_id,
        refreshToken: data.refresh_token,
        role: profileRes.data.roles[0].role,
        // role: 'te',
      });
    }
  }, [profileRes]);

  useEffect(() => {
    if (!roleState.isLoading && !roleState.isError && [201, 202].includes(roleState.status)) {
      if (location.search.includes('parentAdmissionRes')) {
        if (profileRes.data.roles[0].role == 'pa') history.replace('/parents' + location.search);
        else history.replace('/student' + location.search);
      } else history.replace('/social');
      dispatch({
        type: 'VALID_ROLE',
        validRole: true,
      });
    }

    if (roleState.isError) {
      Cookies.remove('auth');
      dispatch({
        type: 'TOKEN',
        token: null,
      });
    }
  }, [roleState]);

  const handlePasswoedForgot = () => {
    callForgetPwd(FORGET_PASSWORD, 'POST', { username });
  };

  useEffect(() => {
    if (!pwdRes.isLoading && !pwdRes.isError && pwdRes.data) {
      history.push('/login?success=yes');
    }

    if (!pwdRes.isLoading && pwdRes.isError) {
      alert('Error');
    }
  }, [pwdRes]);

  return (
    <div className="w-screen h-screen overflow-hidden bg-white flex relative">
      {open && (
        <div
          className="customScrollBar flex justify-center items-start h-screen pb-10 overflow-auto w-full fixed left-0 top-0 z-40"
          style={{
            background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
          }}
        >
          <div className="bg-white w-1/3  m-auto rounded-2xl py-6  z-30 flex items-center justify-between">
            <span className="block font-bold py-4 px-6 text-textBlue1 ">
              Contact us at:<span className="text-primary"> Contact@roledin.com </span>
            </span>

            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mx-6" alt="" onClick={() => setOpen(false)} />
          </div>
        </div>
      )}
      <img
        src="/assets/img/login_bg.png "
        className="absolute overflow-hidden h-screen"
        style={{
          width: '2454px',
          // height: 'auto',
          left: '0px',
          // top: '-282px',
        }}
      />

      {step === 'login' && (
        <div className="px-10 md:px-44 flex py-10 md:py-0 flex-col md:flex-row md:justify-between items-center w-screen h-screen z-30">
          <div className="opacity-50 text-primary font-bold text-3xl md:text-9xl">
            <h3>Plan</h3>
            <h3>Manage</h3>
            <h3>Share</h3>
          </div>
          <div className="relative border-b border-tableRowText py-10">
            <img
              src="/assets/img/roledin_logo.svg"
              alt=""
              className="md:absolute mb-6 md:mb-0 h-12 w-52 md:w-auto z-50 mx-auto -top-10 left-1/2 md:transform md:-translate-x-1/2"
              // style={{ height: '161.99961853027344px', width: '613.2913208007812px' }}
            />

            <form onSubmit={handleSubmit}>
              <div className="border border-borderYellow rounded-xl p-5 bg-white" style={{ width: 330 }}>
                <p className="text-3xl opacity-30 text-black font-light text-center">Welcome Back!</p>
                <p className="opacity-80 text-black font-normal text-base text-center">Login to Continue</p>
                <input
                  type="text"
                  className="rounded-full border text-sm border-borderLight text-tableRowText w-full px-4 py-2.5 mt-12"
                  value={formData.username}
                  name="username"
                  onChange={handleInputChange}
                  placeholder="Username"
                />
                <div className="relative">
                  {displayPwd ? (
                    <img
                      src="assets/img/HidePassword.svg"
                      className="absolute right-4 cursor-pointer top-1/2 w-5"
                      onClick={() => setDisplayPwd((prev) => !prev)}
                    />
                  ) : (
                    <img
                      src="assets/img/ShowPassword.svg"
                      className="absolute right-4 cursor-pointer top-1/2 w-5"
                      onClick={() => setDisplayPwd((prev) => !prev)}
                    />
                  )}
                  <input
                    type={displayPwd ? 'text' : 'password'}
                    value={formData.password}
                    name="password"
                    onChange={handleInputChange}
                    className="rounded-full border text-sm border-borderLight text-tableRowText w-full px-4 py-2.5 mt-2.5"
                    placeholder="Password"
                  />
                </div>

                <input
                  type="submit"
                  className="bg-primary rounded-3xl w-full text-xs text-white py-2.5 mt-4 cursor-pointer"
                  value="Login"
                />

                <div className="flex items-center mt-3 mb-8">
                  <input
                    type="checkbox"
                    className=""
                    checked={isChecked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <p className="text-sm px-4 cursor-pointer" style={{ color: '#686868' }}>
                    Remember Me
                  </p>
                </div>

                <p
                  className="text-sm px-4 pt-4 cursor-pointer underline"
                  style={{ color: '#899DCF' }}
                  onClick={() => {
                    history.push('/login?forgot=yes');
                    // setStep('forgetPassword');
                  }}
                >
                  Forgot password?
                </p>
              </div>
            </form>

            <div className="flex gap-x-10 cursor-pointer absolute -bottom-10 text-sm justify-center w-full">
              <p onClick={() => setOpen(true)}>
                {/* <a href="mailto:Contact@roledin.com"> */}
                Contact us
                {/* </a> */}
              </p>
              <p>
                <a href="https://www.roledin.com/PrivacyPolicy.html" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      {step === 'forgetPassword' && (
        <div className="m-auto border border-borderYellow rounded-lg pl-6 pr-8 py-5 w-full md:w-5/12 z-50 bg-white transform -translate-y-10">
          <h4 className="text-3xl opacity-30 text-black font-light">Get Your Password</h4>
          <p className="opacity-80 text-black font-normal text-base mt-4">Please enter your username</p>

          <div className="flex flex-col md:flex-row gap-4 md:items-center mt-2">
            <input
              type="text"
              placeholder="Username"
              className="rounded-full border text-sm border-borderLight text-tableRowText w-full px-4 py-2.5"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {pwdRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
            ) : (
              <button
                onClick={handlePasswoedForgot}
                className="bg-primary rounded-3xl w-40 text-sm text-white py-2.5 cursor-pointer"
              >
                Send
              </button>
            )}
          </div>

          <p className="text-black opacity-80 text-xs font-normal mt-1">
            You can get new password from your <span className="text-primary">Class Teacher</span> or{' '}
            <span className="text-primary">Your Parents</span>{' '}
          </p>
        </div>
      )}

      {step === 'passwordSuccess' && (
        <div className="m-auto border border-borderYellow rounded-lg p-6 w-full md:w-5/12 z-50 bg-white transform -translate-y-10">
          <h4 className="text-3xl opacity-30 text-black font-light">Password sent successfully</h4>

          <p className="text-black opacity-80 text-lg font-normal mt-6">
            Please connect with your <span className="text-primary">Class Teacher</span> or{' '}
            <span className="text-primary">Your Parents</span> for new password
          </p>

          <button
            className="bg-primary rounded-3xl w-40 text-sm text-white py-2.5 cursor-pointer mt-8"
            onClick={() => {
              history.push('/login');
              // setStep('login');
            }}
          >
            Back to Login
          </button>
        </div>
      )}

      <div className="hidden absolute right-3 bottom-3 z-30 md:flex gap-x-2 items-center mt-2.5">
        {/* <span className="text-textLight font-normal" style={{ fontSize: 9 }}>
          Powered by
        </span> */}
        <img
          src="https://roledin-images-bucket.s3.ap-south-1.amazonaws.com/roledin.png"
          className="h-4"
          alt="roledin"
        />
      </div>
    </div>
  );
};

export default Login;
