import styled from 'styled-components';
import fontFamily from '../../fontFamily';

export const CentralFeed = styled.div`
  background-color: ${fontFamily.colors.white};
  border-radius: 14px;
  padding: 0 0 15.65px;
  box-shadow: 0 0 2px 0 ${fontFamily.colors.transparentRed};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  left: 19%;
  top: 5em;
  width: 43%;
  margin-bottom: 1em;
`;
export const FlexWrapperThree = styled.div`
  width: 100%;
  margin-bottom: 0.6em;
  display: flex;
  align-items: flex-start;
`;
export const FeedProfilePicture = styled.img`
  width: 10%;
  align-self: stretch;
  margin-right: 9.25px;
  border-radius: 14px 0px;
  object-fit: cover;
`;
export const FlexWrapperSeven = styled.div`
  width: 30%;
  margin-right: 50%;
  padding: 5.69px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const FeedUserName = styled.p`
  height: 53.03%;
  color: ${fontFamily.colors.black4};
  align-self: stretch;
  margin-bottom: 0.08px;

  font-size: ${fontFamily.fonts.lato14Regular.size};
  font-weight: ${fontFamily.fonts.lato14Regular.weight};
  line-height: ${fontFamily.fonts.lato14Regular.lineHeight};
`;
export const FeedUserDesignation = styled.p`
  width: 96.3%;
  height: 31.19%;
  color: ${fontFamily.colors.transparentBlack};
  margin-bottom: 0.67px;

  font-size: ${fontFamily.fonts.roboto9Regular.size};
  font-weight: ${fontFamily.fonts.roboto9Regular.weight};
  line-height: ${fontFamily.fonts.roboto9Regular.lineHeight};
`;
export const FeedTimings = styled.p`
  width: 96.3%;
  height: 31.19%;
  color: ${fontFamily.colors.green};

  font-size: ${fontFamily.fonts.roboto9Bold.size};
  font-weight: ${fontFamily.fonts.roboto9Bold.weight};
  line-height: ${fontFamily.fonts.roboto9Bold.lineHeight};
`;
export const MoreOptionForProfile = styled.div`
  width: 20px;
  margin-top: 14.06px;
  padding: 2.85px 7.12px 2.85px 7.11px;
  display: flex;
  align-items: center;
`;
export const Vector = styled.img`
  width: 100%;
  height: 100%;
`;
export const FeedMainText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 0.6em;
  // margin-bottom: 303.79px;
  // margin-left: 24.9px;
`;
export const FeedText = styled.p`
  width: 100%;
  height: 100%;
  padding: 0 1em;
  color: ${fontFamily.colors.black4};
  margin-right: 0.04px;

  font-size: ${fontFamily.fonts.lato18Regular.size};
  font-weight: ${fontFamily.fonts.lato18Regular.weight};
  line-height: ${fontFamily.fonts.lato18Regular.lineHeight};
`;
export const FeedEmoticon = styled.div`
  width: calc(11.01% - 4.27px);
  padding: 3.2px 2.13px 2.83px 2.14px;
  display: flex;
  align-items: center;
  align-self: stretch;
`;
export const VectorTwo = styled.img`
  height: 100%;
  flex: 1;
  object-fit: cover;
`;
export const FeedPictureCarouselIndicators = styled.div`
  margin-bottom: 9.25px;
  margin-left: 276.04px;
  padding: 0 3.11px;
  display: flex;
  align-items: flex-start;
  border-left: 4px solid ${fontFamily.colors.darkOrange};
  border-right: 4px solid ${fontFamily.colors.silver};
`;
export const Num5 = styled.div`
  width: 16.67%;
  background-color: ${fontFamily.colors.silver};
  border-radius: 50%;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-right: 2.85px;
  }
`;
export const Num4 = styled.div`
  width: 16.67%;
  background-color: ${fontFamily.colors.silver};
  margin-right: 2.84px;
  border-radius: 50%;
  align-self: stretch;
`;
export const Divider = styled.div`
  background: #e7e700;
  width: 100%;
  height: 0.5px;
  // height: 0.06%;
  // background-color: ${fontFamily.colors.gold};
  // margin-bottom: 6.04px;
  // align-self: stretch;
`;
export const FlexWrapperFour = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  height: auto;
  // margin-bottom: 6.4px;
  // padding: 0 0 0 7.82px;
`;
export const ChargeCountIcon = styled.div`
  width: 1.2em;
  margin-right: 2.85px;
  padding: 2.13px 4.98px 2.14px;
  display: flex;
  align-items: center;
  align-self: stretch;
`;
export const ChargeCountLabel = styled.p`
  width: 15.71%;
  height: 58.55%;
  color: ${fontFamily.colors.transparentBlack};
  margin-right: 415px;

  font-size: ${fontFamily.fonts.roboto9Regular.size};
  font-weight: ${fontFamily.fonts.roboto9Regular.weight};
  line-height: ${fontFamily.fonts.roboto9Regular.lineHeight};
`;
export const CommentCount = styled.p`
  width: 8.03%;
  height: 58.55%;
  color: ${fontFamily.colors.transparentBlack};

  font-size: ${fontFamily.fonts.roboto9Regular.size};
  font-weight: ${fontFamily.fonts.roboto9Regular.weight};
  line-height: ${fontFamily.fonts.roboto9Regular.lineHeight};
`;
export const DividerTwo = styled.div`
  height: 0.06%;
  background-color: ${fontFamily.colors.gold};
  margin-bottom: 7.47px;
  align-self: stretch;
`;
export const FlexWrapperFive = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6em;
`;
export const RelativeWrapperOne = styled.div`
  width: 50%;
  // margin-right: 109.56px;
  // align-self: stretch;
  // padding:0.5em;
  // position: relative;
`;
export const ChargeLabelAndIcon = styled.div`
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ChargeIcon = styled.div`
  margin-right: 8px;
`;
export const ChargeLabel = styled.p`
  // width: 63.28%;
  // height: 70.26%;
  color: ${fontFamily.colors.darkOrange};

  font-size: ${fontFamily.fonts.roboto10Medium.size};
  font-weight: ${fontFamily.fonts.roboto10Medium.weight};
  line-height: ${fontFamily.fonts.roboto10Medium.lineHeight};
`;

export const ChargeLabel2 = styled.p`
  // width: 63.28%;
  // height: 70.26%;

  font-size: ${fontFamily.fonts.roboto10Medium.size};
  font-weight: ${fontFamily.fonts.roboto10Medium.weight};
  line-height: ${fontFamily.fonts.roboto10Medium.lineHeight};
`;

export const FeedHover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(242, 121, 0, 0.24);
  border-radius: 16px;
  position: relative;
  padding: 4px;
  cursor: pointer;
`;

export const FeedHover2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  position: relative;
  padding: 4px;
  cursor: pointer;
`;
export const CommentIconAndLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  position: relative;
  cursor: pointer;
`;
export const CommentIcon = styled.div`
  width: 18px;
  margin-right: 8.53px;
  // padding: 1.42px 1.43px 1.43px 1.42px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  // align-self: stretch;
  // position: absolute;
  // left: 36%;
  // bottom: -14px;
`;
export const CommentLabel = styled.p`
  // width: 63.21%;
  // height: 70.26%;
  color: ${fontFamily.colors.transparentBlack3};
  margin-top: 1.42px;

  font-size: ${fontFamily.fonts.roboto10Regular.size};
  font-weight: ${fontFamily.fonts.roboto10Regular.weight};
  line-height: ${fontFamily.fonts.roboto10Regular.lineHeight};
  // position: absolute;
  // left: 45%;
  // bottom: 4px;
`;
export const DividerThree = styled.div`
  height: 0.06%;
  background-color: ${fontFamily.colors.gold};
  margin-bottom: 12.44px;
  align-self: stretch;
`;
export const CommentSectionLabel = styled.p`
  color: ${fontFamily.colors.transparentBlack3};
  margin: 12px;

  font-size: ${fontFamily.fonts.roboto11Medium.size};
  font-weight: ${fontFamily.fonts.roboto11Medium.weight};
  line-height: ${fontFamily.fonts.roboto11Medium.lineHeight};
`;
export const FlexWrapperOne = styled.div`
  background-color: ${fontFamily.colors.whiteSmoke};
  margin-left: 7.82px;
  border-radius: 14px;
  padding: 0 97.26px 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.7em;
  position: relative;
`;
export const FlexWrapperSix = styled.div`
  width: 100%;
  margin-bottom: 0.71px;
  display: flex;
  align-items: center;
  position: relative;
`;
export const CommentProfilePicture = styled.img`
  width: 25px;
  align-self: stretch;
  margin-right: 7.83px;
  border-radius: 14px 0px;
  object-fit: cover;
`;

export const CommentProfilePicture2 = styled.img`
  width: 36px;
  height: 36px;
  align-self: stretch;
  margin-right: 7.83px;
  border-radius: 14px 0px;
  object-fit: cover;
  margin-top: 3px;
  margin-left: 4px;
`;
export const CommentUserName = styled.p`
  width: 100%;
  color: #000;

  font-size: ${fontFamily.fonts.roboto13Medium.size};
  font-weight: 500;
  line-height: ${fontFamily.fonts.roboto13Medium.lineHeight};
`;
export const CommentText = styled.p`
  width: 100%;
  color: ${fontFamily.colors.black};
  margin-left: 2.8rem;
  color: #555;
  margin-top: -5px;

  font-size: ${fontFamily.fonts.roboto11Regular.size};
  font-weight: ${fontFamily.fonts.roboto11Regular.weight};
  line-height: ${fontFamily.fonts.roboto11Regular.lineHeight};
`;
export const FlexWrapperTwo = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin: auto;
  margin-bottom: 1em;
  margin-top: 10px;
  // padding: 0 0.01px 0 0;
  // display: flex;
  // align-items: flex-start;
  // position: absolute;
  // right: -0.01px;
  // top: 94.62px;
`;

export const CommentInputWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0px 10px;
  margin-top: 10px;
`;
export const CommentInput = styled.input`
  width: 100%;
  height: 40px;

  padding: 0.8em;
  background: #efefef;
  border-radius: 50px;
`;

export const Rectangle78 = styled.img`
  width: auto;
  margin: auto;
  height: 410px;
  // margin-top: 10px;
  // align-self: stretch;
  // position: relative;
  // object-fit: cover;
`;

export const VideoTag = styled.video`
  width: auto;
  margin: auto;
  height: 410px;
  // margin-top: 10px;
  // align-self: stretch;
  // position: relative;
  // object-fit: cover;
`;
export const NextPictureAffordance = styled.div`
  width: 3.01%;
  height: 99.93%;
  background-color: ${fontFamily.colors.transparentDarkOrange2};
  position: absolute;
  right: 0;
  top: 0;
`;
export const VectorThree = styled.img`
  width: 2.44%;
  height: 5.84%;
  position: absolute;
  right: 1.85px;
  top: 104.74px;
`;
