import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DropDown from '../../UI/DropDown';
import useApi from '../../../api/useApi';
import { CLASS } from '../../../api/urls/institute';
import { MAP_STUDENT } from '../../../api/urls/instituteManagement';

function MapStudent({ toggleMapStudent, selectedStudent, refreshList }) {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);

  const [selectedClass, selectClass] = useState();
  const [selectedSection, selectSection] = useState();

  const [{ isLoading, isError, data }, getClasses] = useApi();

  const [mapState, mapStudentToClass] = useApi();

  useEffect(() => {
    getClasses(CLASS, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setClasses(data);
    }
  }, [isLoading, isError, data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedClass) {
      alert('Please select Class');
      return;
    }
    if (!selectedSection) {
      alert('Please select Section');
      return;
    }

    const body = {
      classId: selectedClass.id,
      sectionId: selectedSection.id,
      // doa: selectedStudent.joined,
      // lastClass: '',
      studentId: selectedStudent.id,
    };
    mapStudentToClass(MAP_STUDENT, 'PUT', body);
  };

  useEffect(() => {
    if (!mapState.isLoading && !mapState.isError && mapState.status === 201) {
      alert('Successfully Mapped');
      toggleMapStudent();
      refreshList();
    }

    if (!mapState.isLoading && mapState.isError) {
      alert('Error in Mapping');
    }
  }, [mapState]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">Map Class</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleMapStudent} />
        </div>
        <form onSubmit={handleSubmit} className="my-4 px-6 " autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Student Name</label>
              <p className="w-full  rounded-3xl border border-borderLight opacity-50 px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedStudent?.name}
              </p>
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Father’s Name</label>
              <p className="w-full  rounded-3xl border border-borderLight opacity-50 px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedStudent?.parent1}
              </p>
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">Date of Admission</label>
              <p className="w-full  rounded-3xl border border-borderLight opacity-50 px-4 mt-2 py-2 text-xs focus:outline-none">
                {moment(selectedStudent?.joined).format('ll')}
              </p>
            </div>

            <div className="mt-2 w-full relative font-semibold">
              <label htmlFor="resignation_date" className="block text-textDark text-sm">
                Last Class Attended
              </label>
              <p className="w-full  rounded-3xl border border-borderLight opacity-50 px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedStudent?.lastClass || '--'}
              </p>
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-sm">New Class</label>
              <DropDown
                data={classes}
                title={'Select New Class to Map'}
                width={'w-full'}
                handleItemClick={(e) => {
                  selectClass(e);
                  setSections(e.sections);
                }}
                margin={'mt-2'}
                type={'obj'}
                field={'name'}
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold ">
              <label className="block text-textDark text-sm">Section</label>
              <DropDown
                data={sections}
                title={'Select New Section to Map'}
                width={'w-full'}
                handleItemClick={(e) => {
                  selectSection(e);
                }}
                margin={'mt-2'}
                type={'obj'}
                field={'name'}
              />
            </div>
          </div>

          <div className=" my-4 w-full flex gap-x-4 justify-end">
            {mapState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto " alt="" />
            ) : (
              <>
                <button
                  className="border py-2 px-12 rounded-3xl text-xs font-bold focus:outline-none text-center text-tableRowText border-tableRowText"
                  onClick={toggleMapStudent}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="border py-2 px-12 rounded-3xl text-xs font-bold focus:outline-none text-center bg-primary border-primary text-white"
                  // onClick={() => setAddClass(false)}
                >
                  Map Class
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default MapStudent;
