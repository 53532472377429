import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { STUDENT_FEE_APPROVAL, APPROVE_FEE } from '../../../api/urls/finance';
import { useStateValue } from '../../../ContextApi/StateProvider';
import PendingFeeApproval from '../../../components/Finance/Fee/PendingFeeApproval';

const FeeApproval = () => {
  const [{ classSections }, _] = useStateValue();

  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [selectedFee, selectFee] = useState();

  const [pendingFeeRes, pendingFeeApproval] = useApi();
  const [approveRes, approveFee] = useApi();

  useEffect(() => {
    if (classSections) {
      let combinedClassSectionList = [];
      classSections.forEach((x) => {
        x.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  useEffect(() => {
    pendingFeeApproval(STUDENT_FEE_APPROVAL, 'GET');
  }, []);

  const handleAction = (id, action) => {
    const body = {
      status: action === 'approve' ? 'approved' : 'rejected',
    };

    approveFee(APPROVE_FEE(id), 'PUT', body);
  };

  useEffect(() => {
    if (!approveRes.isLoading && !approveRes.isError && approveRes.status === 200) {
      pendingFeeApproval(STUDENT_FEE_APPROVAL, 'GET');
      alert('Success');
      selectFee();
    }

    if (!approveRes.isLoading && approveRes.isError) {
      alert('Something went wrong');
    }
  }, [approveRes]);

  return (
    <div className="w-full md:w-4/5 relative pb-10">
      {selectedFee && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12  rounded-2xl relative">
            <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">
              {selectedFee?.studentId?.full_name}
            </span>
            <img
              src="assets/img/Close.svg"
              className="absolute top-6 right-6 h-3 cursor-pointer"
              alt=""
              onClick={() => selectFee(null)}
            />
            <div className="mt-2 mb-4 mx-6 ">
              <div className="mt-2 mb-4 relative font-semibold ">
                {selectedFee?.files.length > 0 && selectedFee?.files[0].location ? (
                  <img src={selectedFee?.files[0].location} alt="" className="w-auto h-full m-auto max-h-96" />
                ) : (
                  <div className="border h-32 flex justify-center items-center mt-2 rounded-2xl">No Attachment</div>
                )}
              </div>
            </div>

            <div className="w-full justify-end pr-5 my-6">
              {approveRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto mr-6" alt="" />
              ) : (
                <div className="ml-auto flex justify-end">
                  <button
                    className="border w-1/5 border-borderLight py-2 text-xs rounded-3xl mx-1 focus:outline-none flex justify-center text-center items-center"
                    onClick={() => handleAction(selectedFee._id, 'reject')}
                  >
                    Reject
                  </button>
                  <button
                    className="border w-1/5 border-primary py-2 text-xs rounded-3xl bg-primary text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                    onClick={() => handleAction(selectedFee._id, 'approve')}
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <PendingFeeApproval data={pendingFeeRes} selectFee={selectFee} />
    </div>
  );
};
export default FeeApproval;
