import React, { useEffect, useState } from 'react';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import useApi from '../../../api/useApi';
import { TODAY_CLASS_ATTENDANCE } from '../../../api/urls/attendance';
import moment from 'moment';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'userName' },
  { label: 'Student Name', key: 'name' },
  { label: 'Absent / Present', key: 'absent_present' },
];

function OpenAttendance({ selectClass, selectedClass }) {
  const [todayStudentAttendance, getTodayStudentAttendance] = useApi();

  useEffect(() => {
    if (selectedClass) {
      getTodayStudentAttendance(
        TODAY_CLASS_ATTENDANCE(selectedClass.classId, selectedClass.sectionId, moment(new Date()).format('ll')),
        'GET'
      );
    }
  }, [selectedClass]);

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (
      !todayStudentAttendance.isLoading &&
      !todayStudentAttendance.isError &&
      todayStudentAttendance.data &&
      todayStudentAttendance.data.attendance &&
      Array.isArray(todayStudentAttendance.data.attendance)
    ) {
      const data = todayStudentAttendance.data.attendance;
      let x = data.map((x) => {
        return {
          userName: x.roledInId || x.username || '',
          name: x?.name,
          absent_present: todayStudentAttendance.data.isMarked
            ? x.leave
              ? 'On Leave'
              : x.isPresent
              ? 'Present'
              : 'Absent'
            : 'Attendance Not Marked',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [todayStudentAttendance]);

  return (
    <div
      className="w-full h-full fixed left-0 top-0  z-30  pt-12"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-full md:w-3/5 text-sm mx-auto block rounded-2xl pb-6 ">
        <div className="flex w-full justify-between py-3 items-center relative border-b border-borderYellow">
          <p className="block font-bold px-6 text-textBlue1">{selectedClass?.class}</p>
          <div className="flex gap-x-6 items-center">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'attendance.csv'} text={'Export Data'} />
            <img
              src="assets/img/Close.svg"
              className=" mr-4 h-3 cursor-pointer z-50"
              alt=""
              onClick={() => {
                selectClass(null);
              }}
            />
          </div>
        </div>
        <div className="w-full mt-6 px-6">
          <div className=" flex w-full">
            <div className="w-full border rounded-3xl border-borderYellow ">
              <div className="w-full flex bg-tableHeader rounded-3xl py-1">
                <div className="flex w-full relative text-xs font-medium px-4">
                  <div className="w-1/3 border-r-2 py-1 px-2">ROLEDin ID</div>
                  <div className="w-1/3 border-r-2 py-1 px-2">Student Name</div>
                  <div className="w-1/3 py-1 px-2">Absent / Present</div>
                </div>
              </div>
              <div className="overflow-auto customScrollBar" style={{ height: '70vh' }}>
                {todayStudentAttendance.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : (
                  todayStudentAttendance.data &&
                  todayStudentAttendance.data.attendance.map((x, idx) => (
                    <div key={x._id} className="w-full py-1">
                      <div
                        className={`justify-center items-center flex w-full relative py-3.5 px-4 text-xs font-medium ${
                          todayStudentAttendance.data.attendance.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/3 px-2 text-tableRowText">{x.userName || x.roledInId || '--'}</div>
                        <div className="w-1/3 px-2 text-tableRowText">{x.name}</div>
                        <div className="w-1/3 px-2 text-textDark">
                          {' '}
                          {todayStudentAttendance.data.isMarked
                            ? x.leave
                              ? 'On Leave'
                              : x.isPresent
                              ? 'Present'
                              : 'Absent'
                            : 'Attendance Not Marked'}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenAttendance;
