import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useApi from '../../../api/useApi';
import { CHANGE_ADMISSION_STATUS } from '../../../api/urls/test';

function NextAdmission() {
  const location = useLocation();

  const reciptUpload = useRef();

  const [feeReceipt, setFeeReceipt] = useState();

  const [{ isLoading, isError, data, status }, sendStatus] = useApi();

  const handleSubmit = (status) => {
    let x = location.search.split('notificationId=');
    if (x.length === 0) return;
    if (status === 'Approved' && !feeReceipt) {
      alert('Please upload Fee Receipt');
      return;
    }

    const fd = new FormData();
    fd.append('status', status);
    fd.append('feeRecipt', feeReceipt);

    sendStatus(CHANGE_ADMISSION_STATUS(x[1]), 'PUT', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      alert('Success');
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div className="w-full md:w-4/5">
      <div className=" bg-white rounded-xl h-auto mb-5 w-full">
        <div className="items-center px-6 py-3.5" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <p className="font-bold">Approve Admission For Next Class</p>
        </div>
        <div className="mt-4 mb-4 mx-6 flex flex-wrap">
          <div className="my-2 relative font-semibold w-full md:w-2.25/5">
            <label className=" text-sm text-textDark">Upload Fee Receipt</label>
            <div className="relative flex">
              <input
                hidden
                ref={reciptUpload}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setFeeReceipt(e.target.files[0])}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <input
                // required
                type="text"
                disabled
                value={feeReceipt?.name}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <button
                className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                onClick={(e) => {
                  e.preventDefault();
                  reciptUpload.current.click();
                }}
              >
                Upload
              </button>
            </div>
          </div>

          {/* <div className="w-1/3" /> */}
          <div className="flex justify-center w-full mb-6 mt-6">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 mx-auto" alt="" />
            ) : (
              <>
                <button
                  className="mt-1 mr-8 lg:mt-0 w-auto lg:w-max border border-gray-400 px-12 py-4 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center h-7"
                  onClick={(e) => handleSubmit('Rejected')}
                >
                  Reject
                </button>
                <button
                  className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                  onClick={(e) => handleSubmit('Approved')}
                >
                  Approve
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NextAdmission;
