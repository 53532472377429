import React, { useState } from 'react';
import Profile from '../Profile';

const StudentProfile = ({ setDetail, studentList, selectedClass, refreshList }) => {
  const [showProfile, setProfile] = useState(false);
  const [selectedStudent, selectStudent] = useState();

  const hideDetail = () => {
    selectStudent(null);
    setProfile(false);
  };

  const getFile = (selectedStudent, fileName) => {
    let z = selectedStudent.fileArray.filter((x) => x[fileName] && x[fileName].location);
    if (z.length > 0) return z[0][fileName].location;
    return '/assets/img/user_placeholder.svg';
  };

  return (
    <div>
      {(selectedStudent || showProfile) && (
        <Profile
          closePopUp={hideDetail}
          selectedStudent={selectedStudent}
          selectedClass={selectedClass}
          refreshList={refreshList}
        />
      )}

      <div className="relative w-w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 pt-3 pb-4 border-b border-borderYellow">
            <p className="text-xs pb-2 cursor-pointer">
              {`Students > Student Details > ${selectedClass.class} - ${selectedClass.section}`}
            </p>
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold">
                <p>{`${selectedClass.class} - ${selectedClass.section}`}</p>
              </div>
              <div className="flex">
                <button
                  className="w-36 border py-2 rounded-3xl text-xs text-white bg-primary m2-4 focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setProfile(true);
                  }}
                >
                  Add Student
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-xs font-medium px-2">
                    <div className="w-2/12 border-r-2 p-2">Photograph</div>
                    <div className="w-2/12 border-r-2 p-2">Roll No</div>
                    <div className="w-3/12 border-r-2 p-2">Student Name</div>
                    <div className="w-3/12 border-r-2 p-2">Father's Name</div>
                    <div className="w-2/12 border-r-2 p-2">Contact No</div>
                    <div className="w-1/4 p-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                  {studentList.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium  ${
                          studentList.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-4">
                          {/* {getFile(x, 'profileImg') && ( */}
                          <img
                            className="schoolLogo"
                            style={{ width: '3em', height: '3em' }}
                            src={getFile(x, 'profileImg')}
                          />
                          {/* )} */}
                        </div>
                        <div className="w-2/12 text-tableRowText px-2">{x?.currentlyMapped?.rollNumber || '--'}</div>
                        <div className="w-3/12 text-tableRowText px-2">{x.full_name}</div>
                        <div className="w-3/12 text-tableRowText px-2">
                          {x.parentDetail.length > 0 && x.parentDetail[0].father_name && x.parentDetail[0].father_name}
                        </div>
                        <div className="w-2/12 px-2">
                          {x.parentDetail.length > 0 && x.parentDetail[0].phone && x.parentDetail[0].phone}
                        </div>
                        <div className="w-1/4 flex  items-center px-2">
                          <button
                            className="w-5/6  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                            onClick={() => {
                              selectStudent(x);
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button
              className="py-2 w-36 text-xs bg-primary border-primary text-white rounded-full mb-4 mr-4 ml-auto"
              onClick={() => setDetail()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentProfile;
