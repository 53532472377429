import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { HOLIDAY_LIST, UPDATE_CALANDER_EVENT } from '../../../api/urls/institute';
import moment from 'moment';
import CustomToolbar from './CustomToolBar';

const HolidayMapping = ({ handleAddOther, moveNext, allEvents, handleAddHoliday, otherHolidays, calanderId }) => {
  const localizer = momentLocalizer(moment);

  const currentYear = moment().year();
  const startDate = moment(`${currentYear}-04-01`).toDate();
  const endDate = moment(`${currentYear + 1}-03-31`).toDate();

  const [holidayList, setHolidayList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().month());

  const [holidays, getHolidatList] = useApi();
  const [saveRes, saveHolidatList] = useApi();

  useEffect(() => {
    getHolidatList(HOLIDAY_LIST, 'GET');
  }, []);

  useEffect(() => {
    if (holidays?.data?.holidayList?.holidayList) {
      const holidaysByType = holidays?.data?.holidayList?.holidayList.reduce((acc, holiday) => {
        if (!acc[holiday.type]) {
          acc[holiday.type] = [];
        }
        acc[holiday.type].push({
          name: holiday.name || holiday.type,
          date: holiday.date,
          startDate: holiday.startDate,
          endDate: holiday.endDate,
        });
        return acc;
      }, {});

      holidaysByType['Summer Holidays']?.forEach((element) => {
        let start = moment(element.startDate, 'YYYY-MM-DD');
        let end = moment(element.endDate, 'YYYY-MM-DD');

        // Array to hold the dates
        let dates = [];
        console.log({ start, end });
        // Iterate from start date to end date
        while (start <= end) {
          // Push the formatted date into the array
          console.log({ start });
          dates.push(start.format('YYYY-MM-DD'));
          handleAddHoliday({
            name: 'Summer Holidays',
            date: start.format('YYYY-MM-DD'),
            eventType: 'Summer Holidays',
          });
          // Add one day
          start = start.add(1, 'days');
        }
      });

      holidaysByType['Winter Holidays']?.forEach((element) => {
        let start = moment(element.startDate, 'YYYY-MM-DD');
        let end = moment(element.endDate, 'YYYY-MM-DD');

        // Array to hold the dates
        let dates = [];

        // Iterate from start date to end date
        while (start <= end) {
          // Push the formatted date into the array
          dates.push(start.format('YYYY-MM-DD'));
          handleAddHoliday({
            name: 'Winter Holidays',
            date: start.format('YYYY-MM-DD'),
            eventType: 'Winter Holidays',
          });
          // Add one day
          start = start.add(1, 'days');
        }
      });
      // console.log(" holidaysByType : ", holidaysByType )
      setHolidayList(holidaysByType);
    }
  }, [holidays]);

  const getDate = (date) => {
    return date ? moment(date).format('DD MMM') : null;
  };

  const getDayName = (date) => {
    return date ? moment(date).format('ddd') : null;
  };

  const getColor = (holiday) => {
    switch (holiday) {
      case 'Gazetted Holiday':
        return 'gazettedHolidayColor';
      case 'Regional Holiday':
        return 'regionalHolidayColor';
      case 'Restricted Holiday':
        return 'restrictedHolidayColor';
      case 'State Holiday':
        return 'stateHolidayColor';
      case 'Summer Holidays':
        return 'summerHolidayColor';
      case 'Winter Holidays':
        return 'winterHolidayColor';
    }
  };

  const onNavigate = React.useCallback((newDate) => setCurrentMonth(moment(newDate).month()), []);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.eventType === 'Gazetted Holiday' && {
        className: 'gazettedHolidayColor',
      }),
      ...(event.eventType === 'Regional Holiday' && {
        className: 'regionalHolidayColor',
      }),
      ...(event.eventType === 'Restricted Holiday' && {
        className: 'restrictedHolidayColor',
      }),
      ...(event.eventType === 'State Holiday' && {
        className: 'stateHolidayColor',
      }),
      ...(event.eventType === 'Other Holiday' && {
        className: 'otherHolidayColor',
      }),
      ...(event.eventType === 'Summer Holidays' && {
        className: 'summerHolidayColor',
      }),
      ...(event.eventType === 'Winter Holidays' && {
        className: 'winterHolidayColor',
      }),
    }),
    []
  );

  const handleSaveHolidays = () => {
    if (!window.confirm('Are you sure you want to save?')) return;

    const body = {
      eventList: allEvents.map((event) => {
        return {
          date: event.date,
          events: [
            {
              key: event.name,
              eventType: event.eventType,
            },
          ],
        };
      }),
    };

    saveHolidatList(UPDATE_CALANDER_EVENT(calanderId), 'PUT', body);
  };

  useEffect(() => {
    if (saveRes?.status === 200) {
      moveNext();
    }

    if (saveRes.isError) {
      alert('Error saving');
    }
  }, [saveRes]);

  let calEvents = [];

  if (Array.isArray(allEvents))
    calEvents = allEvents.map((x) => {
      return {
        title: x.name,
        start: new Date(x.date),
        eventType: x.eventType,
        end: new Date(x.date),
        startTime: moment(x.date).format('hh:mm a') || '---',
        endTime: moment(x.date).format('hh:mm a') || '---',
      };
    });

  return (
    <div className="px-4 pb-6 mt-4">
      <div className="flex gap-x-4 items-start">
        <div className="bg-white m-auto border rounded-2xl border-borderYellow w-3/4">
          <Calendar
            localizer={localizer}
            events={calEvents}
            style={{ height: 500 }}
            views={['month']}
            eventPropGetter={eventPropGetter}
            onNavigate={onNavigate}
            min={startDate}
            max={endDate}
            selectable="ignoreEvents"
            onSelectSlot={(x) => handleAddOther(x.start)}
            components={{
              toolbar: CustomToolbar, // Use the custom toolbar
            }}
          />
        </div>

        <div className="bg-white border rounded-2xl border-borderYellow w-1/4">
          <p className="text-center font-medium text-lg py-2">2024 - 2025</p>
          <div className="bg-tableHeader text-xs p-2 font-medium">Holidays</div>
          <div>
            {holidayList &&
              Object.entries(holidayList).map((x) => (
                <div className="flex flex-col flex-wrap w-full border-b border-borderYellow py-1.5 px-2.5">
                  <div className="flex justify-between items-center pr-4">
                    <p className="text-sm mb-0.5 text-black">{x[0]}</p>
                    <div className={`w-8 h-3 rounded-full ${getColor(x[0])}`}></div>
                  </div>
                  <div className="flex flex-col px-2">
                    {Array.isArray(x[1])
                      ? x[1]
                          .filter((v) => moment(v.date).month() === currentMonth)
                          .map((c, idx) => {
                            if (x[0] === 'Winter Holidays' || x[0] === 'Summer Holidays') {
                              return (
                                <div key={idx} className="flex py-1 justify-between items-center">
                                  <p className="text-xs" style={{ color: '#212121B8' }}>{`${getDate(
                                    c?.startDate
                                  )} - ${getDate(c?.endDate)}`}</p>
                                </div>
                              );
                            } else {
                              return (
                                <div key={idx} className="flex py-1 justify-between items-center">
                                  <p className="text-xs" style={{ color: '#212121B8' }}>{`${getDate(c?.date)} - ${
                                    c?.name
                                  } - ${getDayName(c?.date)}`}</p>

                                  <input
                                    type="checkbox"
                                    checked={allEvents.find((m) => m.name === c.name && m.date === c.date)}
                                    onChange={() => handleAddHoliday({ ...c, eventType: x[0], name: c.name })}
                                    className="mr-2"
                                  />
                                </div>
                              );
                            }
                          })
                      : null}
                  </div>
                </div>
              ))}

            <div className="flex flex-col flex-wrap w-full py-1.5 px-2.5">
              <div className="flex justify-between items-center pr-4">
                <p className="text-sm mb-0.5 text-black">Other Holiday</p>
                <div className="w-8 h-3 rounded-full otherHolidayColor"></div>
              </div>
              {otherHolidays.map((c, idx) => (
                <div key={idx} className="flex py-1 justify-between items-center pl-2">
                  <p className="text-xs" style={{ color: '#212121B8' }}>{`${getDate(c?.date)} - ${
                    c?.name
                  } - ${getDayName(c?.date)}`}</p>
                </div>
              ))}
              {/* <div className="flex py-1 justify-between items-center px-3">
                <p className="text-xs" style={{ color: '#212121B8' }}>
                  Add Holiday
                </p>

                <div
                  className="border border-tableRowText text-tableRowText px-1.5 py-0.5 rounded-md flex justify-center items-center cursor-pointer"
                  onClick={handleAddOther}
                >
                  <span className="-mt-1">+</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center mt-6 px-2">
        {/* <button className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none">
          Reset
        </button> */}

        <div className="flex gap-x-4">
          <button
            className="w-28 py-2 text-xs rounded-3xl bg-bgBlue border border-bgBlue font-medium text-white focus:outline-none"
            onClick={handleSaveHolidays}
            disabled={saveRes?.isLoading}
          >
            Save
          </button>
          {/* <button
            className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none"
            onClick={moveNext}
          >
            Next
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default HolidayMapping;
