import { AI } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const POST_IMAGE = `${AI}/callAIModel?year=${year}`;
export const Generate_Schedule_POST  = `${AI}/ScheduleTopics`;
export const Generate_Notes_POST = `${AI}/genrateNotes`;
export const Teach_Topic_POST = `${AI}/student/teach/Notes`;