import React, { useState } from 'react';
import {
  FeedUpdates,
  UpdatesTabLabel,
  Divider,
  FlexWrapperOne,
  Group145,
  Rectangle150,
  RelativeWrapperOne,
  PostUpdateTime,
  PostUpdateHyperlink,
  PostUpdateHyperlinkEmphasis0,
  Scroll,
  Group146,
} from './style';
import '../../../scrollbar.css';

const FeedUpdatesComponent = ({ feed }) => {
  return (
    <div className=" w-full bg-white rounded-2xl shadow-sm mt-4 max-h-80 flex flex-col">
      {/* <div className="px-4 py-2.5 border-b border-borderYellow text-black">
        <p className=" ">Updates</p>
      </div>

      <div className="p-2">
        {[1, 2, 3].map((x) => (
          <div className="flex mb-4">
            <img
              className="rounded-r-lg mr-3 h-12 w-12"
              alt=""
              src="https://static.overlay-tech.com/assets/6e615f33-2214-4e7d-a043-223decbda96b.png"
            />
            <div className="pr-2 flex flex-col">
              <p className="text-xs text-tableRowText font-medium">
                <strong>Kanika Gupta</strong> commented on Sakshi Tanwar’s post.
              </p>
              <p className="text-xs text-green-500">15 minutes ago</p>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default FeedUpdatesComponent;
