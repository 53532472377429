
import React, { useState, useEffect } from 'react';
import moment from 'moment';

function SiblingDetails({ onSibling , currStudent , SiblingList }) {
  // let currChildIndex = 0 
  // const [selectedIndex, setIndex] = useState(currChildIndex);
  // let AllChild = [ {_id : "12345", studentName : "test name" , class : {name:"3"} , section : {name:"A"}, roledinId:"abcs1243534dsgdfgds" , parents : "Abjit Sharma , Sunita Kumari" },
  //   {_id : "1234", studentName : "test name" , class : {name:"5"} , section : {name:"A"}, roledinId:"abcs1243534dsgdfgds" , parents : "Abjit Sharma , Sunita Kumari" }
  //  ]
  let isLoading = false ;
  let selectedStudentData = currStudent ; // AllChild[selectedIndex]
  // let SiblingList = []
  // if(AllChild.length > 1){ SiblingList = AllChild.filter(function(x,filterindex) { return filterindex !== selectedIndex; }); }
  // selectedStudentData = null ;
  // console.log("SiblingList.length --", AllChild.length )
  // SiblingList = null ; 
  // console.log(" -- SiblingList : ", SiblingList );
  return (

    // <div className="w-full bg-white rounded-xl h-auto mt-4">
    <>
    <div className="my-2  relative font-semibold w-full"> 
       
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl" //  min-w-700"
            style={{ borderColor: '#E7E700' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-3/4 border-r-2 py-2 px-2">Student Details</div> 
                {/* <div className="w-1/4 border-r-2 py-2 px-2">Class</div>  */}
                <div className="w-1/4 py-2 px-2">Class</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
              {
              // isLoading ? (
              //   <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
              // ) : 
              selectedStudentData ? ( 
                  <div>
                    <div className="w-full" key={selectedStudentData._id}>
                      <div className={`items-center flex w-full relative py-1 px-2  font-medium`} >
                        <div className="w-3/4 px-2 ">
                          Name - {selectedStudentData.full_name }
                        </div> 
                        <div className="w-1/4 px-2">{`${selectedStudentData.class?.name} - ${selectedStudentData.section?.name}`}</div> 
                      </div>
                      
                    </div>

                    <div className="w-full" key={selectedStudentData._id + "abc"}>
                      <div className={`items-center flex w-full relative py-1 px-2 text-xs font-medium`} >
                        <div className="w-4/4 px-2">
                          RoledIN ID - {selectedStudentData.roledinId }
                        </div>  
                      </div> 
                    </div>

                    <div className="w-full" key={selectedStudentData._id + "abcd"}>
                      <div className={`items-center flex w-full relative py-1 px-2 text-xs font-medium`} >
                        <div className="w-4/4 px-2">
                          Parents/Guardian  - {selectedStudentData.parents }
                        </div>  
                      </div> 
                    </div>
                  </div> 
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      
    </div>

    <div className="my-2  relative font-semibold w-full"> 
    <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl" //  min-w-700"
            style={{ borderColor: '#E7E700' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-xs font-medium px-2">
                <div className="w-2/4 border-r-2 py-2 px-2">Student with same Parent's Detail</div> 
                <div className="w-1/4 border-r-2 py-2 px-2">Class</div> 
                <div className="w-1/4 py-2 px-2">View Details</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 mx-auto my-6" alt="" />
              ) : Array.isArray(SiblingList) && SiblingList.length > 0 ? (
                SiblingList
                  // .sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1))
                  .map((x, idx) => (
                    <div className="w-full" key={x._id}>
                      <div
                        className={`items-center flex w-full relative py-1 px-2 text-xs font-medium ${
                          SiblingList.length - 1 !== idx && 'border-b border-tableHeader'
                        }`}
                      > 
                        <div className="w-2/4 px-2">{x?.full_name}</div>
                        <div className="w-1/4 px-2">{`${x?.class?.name} - ${x?.section?.name}`}</div>
                         
                        <div className="w-1/4 px-2">
                          <button
                            className="border border-primary text-primary px-6 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
                            onClick={() => {
                              onSibling( x ) //  `${x?.class?.name} - ${x?.section?.name}` )
                              // if (x?.fee?.type === 'misc')
                              //   print({
                              //     ...x,
                              //     fee: {
                              //       month: x?.fee?.month,
                              //       amount: x?.fee?.amountPaid,
                              //       miscFee: x?.fee?.orderType,
                              //       balance: x?.fee?.bal,
                              //       receiptNo: x?.fee?.receipt,
                              //       date: x?.fee?.date,
                              //       totalAmount: x?.fee?.amount,
                              //       prevBalance: x?.fee?.prevBalance,
                              //       waiveoff: x?.fee?.waiveoff,
                              //     },
                              //   });
                              // else print(x);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 py-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SiblingDetails;
