import React, { useEffect, useState } from 'react';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import Search from '../../UI/Search';

const excelHeaders = [
  { label: 'Class', key: 'class' },
  { label: 'Section', key: 'section' },
  { label: 'Class Teacher', key: 'teacher' },
  { label: 'Total Students', key: 'totalStudents' },
  { label: 'Present', key: 'present' },
  { label: 'Absent', key: 'absent' },
];

function StudentAttendance({ selectClass, dashboardRes }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  const data = dashboardRes?.data?.class_attendance_report || [];

  useEffect(() => {
    if (dashboardRes?.data?.class_attendance_report && Array.isArray(dashboardRes?.data?.class_attendance_report)) {
      let x = data.map((x) => {
        return {
          class: x?.class.split('-')[0],
          section: x?.class.split('-')[1],
          teacher: x?.class_teacher || '',
          totalStudents: x?.totalstudent || '',
          present: x.presentStudent || '',
          absent: x.absentStudent || '',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [dashboardRes]);

  let filteredData = data;

  if (sort) {
    if (sort === 'Name- a to z') {
      filteredData = data.sort((a, b) => (a.class.toLowerCase() > b.class.toLowerCase() ? 1 : -1));
    }
    if (sort === 'Name- z to a') {
      filteredData = data.sort((a, b) => (a.class.toLowerCase() < b.class.toLowerCase() ? 1 : -1));
    }
  }

  if (search) {
    filteredData = data.filter((x) => x.class.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>Student Attendance (Today)</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <ExcelExportBtn
              data={excelData}
              headers={excelHeaders}
              fileName={'student-attendance.csv'}
              text={'Export Data'}
            />

            <Search
              placeholder={'Search'}
              width={'w-1/4'}
              onChange={(x) => setSearch(x)}
              onEnter={() => console.log('enter')}
            />

            <DropDown
              data={['Name- a to z', 'Name- z to a']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">Class </div>
                <div className="w-1/12 border-r-2 py-2 px-2">Section</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Class Teacher</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Total Students</div>
                <div className="w-1/12 border-r-2 py-2 px-2">Present</div>
                <div className="w-1/12 border-r-2 py-2 px-2">Absent</div>
                <div className="w-2/12 py-2 px-2">Attendance</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {dashboardRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : filteredData && filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2 ">{x?.class.split('-')[0]}</div>
                        <div className="w-1/12 px-2 ">{x?.class.split('-')[1]}</div>
                        <div className={`w-3/12 px-2 `}>{x?.class_teacher}</div>
                        <div className={`w-2/12 px-2 `}>{x?.totalstudent || '--'}</div>
                        <div className={`w-1/12 px-2 `}>{x?.presentStudent || '--'}</div>
                        <div className={`w-1/12 px-2 `}>{x?.absentStudent || '--'}</div>
                        <div className={`w-2/12 px-2 `}>
                          {' '}
                          <button
                            className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => selectClass(x)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentAttendance;
