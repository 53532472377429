import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import useApi from '../../../api/useApi';
import { ATTENDANCE_REGULARIZATION } from '../../../api/urls/attendance';
import { useStateValue } from '../../../ContextApi/StateProvider';

function RegularizationPopup({ data, handleClose }) {
  const [response, sendReq] = useApi();
  const [{ user }, dispatch] = useStateValue();

  const [formData, setFormData] = useState({
    date: '',
    inTime: '',
    outTime: '',
    reason: '',
    attendanceId: '',
  });

  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        inTime: moment(new Date(`2/2/2020 ${data?.punchInTime} UTC`)),
        outTime: moment(new Date(`2/2/2020 ${data?.punchOutTime} UTC`)),
        date: data.date,
        attendanceId: data?._id,
        reason: data?.reason,
      });
    }
  }, [data]);

  const handleSubmit = () => {
    sendReq(ATTENDANCE_REGULARIZATION(user?.user?._id), 'POST', formData);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && response.status === 200) {
      alert('Success');
      handleClose(true);
    }

    if (!response.isLoading && response.isError) {
      alert('Failed');
    }
  }, [response]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 pb-4 rounded-2xl relative">
        <span className="block font-bold py-4 px-6 text-textBlue1 border-b border-borderYellow">Leave Details</span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-6 right-6 h-3 cursor-pointer"
          alt=""
          onClick={handleClose}
        />
        <div className="mt-2 mb-4 mx-6 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">Date</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {moment(data.date).format('ll')}
              </p>
            </div>
            <div className=" mb-4 relative font-semibold mt-2">
              <label className="block text-textDark text-sm">Hours</label>
              <p className="w-full rounded-2xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {data?.hours || '--'}
              </p>
            </div>
            <div className="mt-2 mb-4 relative font-semibold ">
              <label className="block text-textDark text-sm mb-2">In Time</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  disabled={!data?.isAr}
                  value={formData?.inTime}
                  onChange={(time) => setFormData({ ...formData, inTime: time })}
                  slotProps={{ textField: { size: 'small' } }}
                  required
                />
              </LocalizationProvider>
            </div>
            <div className="mt-2 mb-4 relative font-semibold ">
              <label className="block text-textDark text-sm mb-2">Out Time</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  disabled={!data?.isAr}
                  value={formData?.outTime}
                  onChange={(time) => setFormData({ ...formData, outTime: time })}
                  slotProps={{ textField: { size: 'small' } }}
                  required
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="mt-2 mb-4 relative font-semibold ">
            <label className="block text-textDark text-sm">Reason</label>
            <textarea
              rows={4}
              name="reason"
              disabled={!data?.isAr}
              value={formData.reason}
              className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none"
              onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
            />
          </div>
        </div>

        {data?.isAr && (
          <div className="w-full justify-end pr-5">
            {response.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
            ) : (
              <div className="ml-auto flex justify-end px-6">
                <button
                  className="border w-1/5 border-primary py-2 text-xs rounded-3xl bg-primary text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default RegularizationPopup;
