import { getExamName } from '../../../utils';
import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { read, utils } from 'xlsx';
import { SYLLABUS_UPLOAD } from '../../../api/urls/timeTable';

import { POST_IMAGE } from '../../../api/urls/ai';
import axios from 'axios';

const DownloadModal = ({ data, onClose, setSchedule, generateSchedule, loading, excelData, setExcelData , setExtractedText , extractedText, setShowLoading }) => {
  const [selectedFile, setFile] = useState();
  const [selectedImg, setImage] = useState();
  const [base64Imgstate, setBase64Img] = useState();
  // const [extractedText, setExtractedText] = useState('');
  const [_id, setId] = useState('');

  const [uploadRes, uploadFile] = useApi();
  const [imageRes, uploadImage] = useApi();

  const file = useRef();
  const image = useRef();

  useEffect(() => { // console.log("excel file : ", selectedFile )
    if (selectedFile) {
      setExtractedText(null) ; setBase64Img(null); image.current.value = "" ;
      readData(); 
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedImg) {
      setExcelData(null); file.current.value = "" ;
      const reader = new FileReader();
      reader.readAsDataURL(selectedImg);
      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1]; // Extract base64 part
        // setShowLoading(true);
        // Send base64 image to your Node.js server
        uploadImage(POST_IMAGE, 'POST', { image: base64Image });
        setBase64Img( base64Image);
      };
    }
  }, [selectedImg]);

  useEffect(() => { 
    // console.log("generating data here 2")
    if (!imageRes.isLoading && !imageRes.isError && imageRes.data) {
      setShowLoading(false);
      setImage(null); // setBase64Img('');
      image.current.value = null;
      setExtractedText(imageRes.data) // .split('```')[1]);
      // console.log("generating data here ", imageRes.data.split('```') )
    }

    if (!imageRes.isLoading && imageRes.isError) {
      setShowLoading(false);
      alert('Failed to extract text from image');
      setImage(null); setBase64Img(''); setExtractedText('');
      image.current.value = null;
    }
  }, [imageRes]);

  const readData = () => {
    const file = selectedFile;

    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        // setCsvArray(rows);
        const data = rows.map((x) => {
          return {
            chapter: x.Chapter,
            topic: x.Topic,
          };
        });
        setExcelData(data);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUpload = (type) => {
    const body = new FormData();
    // body.append('file', selectedFile);
    body.append('type', data.exam);
    body.append('syllabus', JSON.stringify(type === 'image' ? extractedText : excelData));
    uploadFile(SYLLABUS_UPLOAD(data._class?._id, data.section?._id, data.subjectId), 'POST', body);

    // generateSchedule(type);
  };

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && [200, 201, 202].includes(uploadRes.status)) {
      // alert('Success');
      setFile(null);
      file.current.value = null;
      onClose(true);
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('API Error');
      setFile(null);
      file.current.value = null;
    }
  }, [uploadRes]);

  const headers = excelData?.length && Object.keys(excelData[0]);
  //  console.log("--- extractedText : ", extractedText );
  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-3/5 px-2 pb-2 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow flex justify-between items-center pr-8">
          <span className="block font-bold p-4">{`${data?._class?.name}-${data?.section?.name} - ${
            data?.subjectName
          } - ${getExamName(data?.exam)}`}</span>

          <div className="">
            <a
              className="border border-primary text-xs px-6 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
              href={window.location.origin + '/Syallbus.xlsx'}
              download
            >
              Download Syllabus Template
            </a>
          </div>

          <img
            src="assets/img/Close.svg"
            className="absolute top-5 right-3 h-3 cursor-pointer"
            alt=""
            onClick={() => onClose()}
          />
        </div>

        <div className="mx-4 my-8 flex justify-start gap-x-6">

          <input hidden ref={image} type="file" onChange={(e) => setImage(e.target.files[0])} />
          <input hidden ref={file} type="file" onChange={(e) => setFile(e.target.files[0])} />

          <button
            className="border w-36 rounded-3xl text-xs font-bold focus:outline-none text-center h-9 bg-primary text-white"
            onClick={(e) => {
              e.preventDefault();
              file.current.click();
            }}
          >
            Upload Excel
          </button>

          <button
            className="border w-36 rounded-3xl text-xs font-bold focus:outline-none text-center h-9 bg-primary text-white"
            onClick={(e) => {
              e.preventDefault();
              image.current.click();
            }}
          >
            Upload Image
          </button>
          
        </div>
        {excelData && (
          <div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      {headers.map((h) => (
                        <div key={h} className="w-1/2 border-r-2 py-2 px-2 capitalize">
                          {h}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
                    {excelData.length > 0 ? (
                      excelData.map((x, idx) => (
                        <div key={idx} className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs items-center ${
                              idx !== excelData.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >
                            {headers.map((h, i) => (
                              <div key={i} className="w-1/2 px-2 text-tableRowText">
                                {x?.[h]}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data in Excel FIle
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pr-2 pb-4 flex justify-end gap-x-4">
              <button
                className="border w-20 rounded-3xl text-xs focus:outline-none text-center py-2 border-primary text-primary"
                onClick={() => {
                  setExcelData(null);
                  file.current.value = null;
                  setFile(null);
                }}
              >
                Reset
              </button>

              <button
                className={`border w-auto px-4 rounded-3xl text-xs focus:outline-none text-center py-2 bg-primary text-white ${
                  loading ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={handleUpload}
                disabled={loading}
              >
                Save
                {/* {loading ? 'Generating...' : 'Generate Schedule'} */}
              </button>
            </div>
          </div>
        )}

        {imageRes.isLoading && <img src="assets/img/loader.svg" className="w-14 mx-auto my-8" alt="" />}
        {base64Imgstate && (<img className="h-64 w-auto mx-auto" src={"data:image/jpeg;base64,"+base64Imgstate}></img>) }
        {extractedText && (
         
          <div className="mx-6 mb-8"> 
            <p className="text-base font-medium mb-2">Please verify that the text the box below is in the format chapter name : topic1,topic2,topic3... </p>
            <p className="text-base font-medium mb-2">Extracted Syllabus from image: </p>
            <textarea
              value={extractedText}
              rows="20"
              cols="50"
              onChange={(e) => setExtractedText(e.target.value)}
              placeholder="Ex- Rs 25"
              className="w-full rounded-2xl border text-xs border-borderLight px-4 py-2 focus:outline-none"
            />

            <div className="w-full pr-2 flex justify-end gap-x-4 mt-4">
              <button
                className="border w-20 rounded-3xl text-xs focus:outline-none text-center py-2 border-primary text-primary"
                onClick={() => {
                  // setExcelData(null);
                  // setExtractedText('');
                  // image.current.value = null;
                  // setImage(null);
                  // setBase64Img('');
                  setShowLoading(true);
                  uploadImage(POST_IMAGE, 'POST', { image: base64Imgstate });
                }}
              >
                Regenrate
              </button>

              <button
                className="border w-auto px-4 rounded-3xl text-xs focus:outline-none text-center py-2 bg-primary text-white"
                onClick={() => handleUpload('image')}
                disabled={loading}
              >
                Save
                {/* {loading ? 'Generating...' : 'Generate Schedule'} */}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadModal;
