import { USER } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const CREATE_USER = USER;

export const GET_TEACHING_STAFF = `${USER}/usertype/teaching`;

export const GET_NON_TEACHING_STAFF = `${USER}/usertype/non-teaching`;

export const GET_STUDENT = `${USER}/usertype/student`;

export const GET_WITHDRAWAL_STUDENT = `${USER}/user/getadmissionWithdrawal`;

export const ADMISSION_WITHDRAW = (username) => `${USER}/user/${username}/admissionWithdrawal`;

export const STAFF_REMOVE = (username) => `${USER}/user/${username}/staff/exit`;

export const GET_REMOVED_STAFF = `${USER}/user/staffWithdrawal`;

export const UPDATE_USER = (id) => `${USER}/${id}`;

export const SEND_EMAIL = `${USER}/email`;

export const SET_TEMP_PWD = `${USER}/setTemporaryPassword`;

export const GET_TEMP_PWD = `${USER}/getTemporaryPassword`;

export const SEARCH_STAFF = (key) => `${USER}/institute/staff/search?key=${key}`;

export const SEARCH_STUDENT = `${USER}/institute/student/search`;

export const SEND_MESSAGE = (userId) => `${USER}/email/${userId}`;
