import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import useApi from '../../../api/useApi';
import { EVENT } from '../../../api/urls/timeTable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import DropDown from '../../UI/DropDown';

function CreateEvent({ refreshEvents }) {
  const attachment = useRef();

  const formDataModel = {
    title: '',
    description: '',
    audience: 'All',
    eventDate: '',
    start: null,
    end: null,
    files: null,
  };
  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createEvent] = useApi();
  const formatDateISO = (date) => {
      const selectedDate = new Date(date)
      return selectedDate.getFullYear() + "/"+ parseInt(selectedDate.getMonth()+1) +"/"+ selectedDate.getDate() ;
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData?.title?.trim()) str.push('Title');
    if (!formData.eventDate) str.push('Event Date');
    if (!formData.start) str.push('Start Time');
    if (!formData.end) str.push('End Time');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (new Date(formData.start).getTime() >= new Date(formData.end).getTime()) {
      alert('Start time should be before end time');
      return;
    }

    const fd = new FormData();
    fd.append('title', formData.title);
    fd.append('description', formData.description);
    fd.append('eventDate', formatDateISO(formData.eventDate) );
    fd.append('audience', formData.audience);
    fd.append('start', formData.start);
    fd.append('end', formData.end);
    fd.append('files', formData.files);
    // console.log(" creating event with data : formData.title : ", formData.title )
    // console.log(" creating event with data : formData.title : ", formData.description )
    // console.log(" creating event with data : formData.eventDate : ", formatDateISO(formData.eventDate) )
    // console.log(" creating event with data : formData.audience : ", formData.audience )
    // console.log(" creating event with data : formData.start : ", formData.start )
    // console.log(" creating event with data : formData.end : ", formData.end ) 
    // console.log(" creating event with data : formData.files : ", formData.files)
    createEvent(EVENT, 'POST', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      let taskDone = false ;
      if( data.message ){ alert( data.message );  if(data.DoneTask){ taskDone = true ; } }
      else{ alert('Something unexpected happans.'); } 
      if( taskDone ){
        setFormData(formDataModel);
        refreshEvents();
        attachment.current.value = '';
      } 
    }

    if (!isLoading && isError) { 
      if( data.message ){ alert( data.message );   }
      else{ alert('Failed!');   }
      
    }
  }, [isLoading, isError, data, status]);

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center py-3 ">
        <div className="flex w-full justify-between items-center relative px-6 border-b border-borderYellow">
          <p className="block font-bold  pt-1 pb-4 text-textBlue1">Create Event</p>
        </div>
      </div>
      <div className="w-full px-4 pb-6">
        <form className="grid grid-cols-1 md:grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
          <div className="my-2 relative font-semibold w-full">
            <label className="w-full text-sm text-textDark">
              Event Date<span className="text-red-500 ml-1">*</span>
            </label>
            <DatePicker
              className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              selected={formData.eventDate}
              onChange={(date) => setFormData({ ...formData, eventDate: date })}
              dateFormat={'dd-MM-yyyy'}
              required
            />
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className=" text-sm text-textDark">Audience</label>
            <DropDown
              data={['All', 'Staff', 'Students', 'Parents']}
              handleItemClick={(e) => {
                setFormData({ ...formData, audience: e });
              }}
              margin={'mt-2'}
              otherClasses={'border border-borderLight text-textDark'}
            />
          </div>
          <div className="my-2 relative font-semibold w-full">
            <label className=" text-sm text-textDark  mb-2">
              Start Time<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="mt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  value={formData?.start}
                  onChange={(time) => setFormData({ ...formData, start: time })}
                  slotProps={{ textField: { size: 'small' } }}
                  required
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className=" text-sm text-textDark mb-2">
              End Time<span className="text-red-500 ml-1">*</span>
            </label>
            <div className="mt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  value={formData?.end}
                  onChange={(time) => setFormData({ ...formData, end: time })}
                  slotProps={{ textField: { size: 'small' } }}
                  required
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="my-2 w-full relative font-semibold md:col-span-2">
            <label className=" text-sm text-textDark">
              Title<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              name="title"
              // placeholder="Title"
              required
              value={formData.title}
              className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              onChange={handleInputChange}
            />
          </div>

          <div className="my-2 w-full relative font-semibold md:col-span-2">
            <label className=" text-sm text-textDark">Description</label>
            <textarea
              type="text"
              name="description"
              // placeholder="Description"
              rows="4"
              value={formData.description}
              cols="50"
              className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              onChange={handleInputChange}
            />
          </div>
          <div className="my-2 relative font-semibold w-full">
            <label className=" text-sm text-textDark">Attachment</label>
            <div className="relative flex">
              <input
                hidden
                ref={attachment}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setFormData({ ...formData, files: e.target.files[0] })}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <input
                // required
                type="text"
                disabled
                value={formData.files?.name || ''}
                className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              />
              <button
                className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                onClick={(e) => {
                  e.preventDefault();
                  attachment.current.click();
                }}
              >
                Upload
              </button>
            </div>
          </div>

          <div className="w-full mt-4 flex justify-end md:col-span-2 gap-x-4">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <>
                <button
                  type="reset"
                  className="border border-tableRowText text-tableRowText px-12 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={() => {
                    setFormData({ ...formDataModel });
                    attachment.current.value = '';
                  }}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                >
                  Create
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateEvent;
