import React, { useState, useEffect } from 'react';
import DropDown from '../../UI/DropDown';
import { EXAM_PATTERN_GET } from '../../../api/urls/institute';
import { RESULT_RELEASE, GET_RESULT_RELEASE, DELETE_RESULT_RELEASE_DATE } from '../../../api/urls/test';
import useApi from '../../../api/useApi';
import moment from 'moment';

function ResultReleaseDate({ setOpenResultDate, category }) {
  const [examTypes, setExamTypes] = useState([]);
  const [formData, setFormData] = useState({
    test_id: '',
    test_type: '',
    class_type: category.split(' ').join(''),
    releaseDate: '',
  });

  const [examTypesResponse, getExamTypes] = useApi();
  const [releaseDates, getReleaseDates] = useApi();
  const [deleteRes, deleteReleaseDates] = useApi();
  const [res, saveDate] = useApi();

  useEffect(() => {
    getExamTypes(EXAM_PATTERN_GET(category.split(' ').join('')), 'GET');
    getReleaseDates(GET_RESULT_RELEASE(category.split(' ').join('')), 'GET');
  }, []);

  useEffect(() => {
    if (!examTypesResponse.isLoading && !examTypesResponse.isError && examTypesResponse.data) {
      setExamTypes(examTypesResponse.data || []);
    }
  }, [examTypesResponse]);

  const handlePublish = () => {
    saveDate(RESULT_RELEASE, 'POST', formData);
  };

  useEffect(() => {
    if (!res.isLoading && !res.isError && res.status && res.status == 201) {
      setOpenResultDate(false);
      alert('Success');
    }

    if (!res.isLoading && res.isError) {
      alert(res?.data || 'Failed');
    }
  }, [res]);

  const handleDelete = (id) => {
    deleteReleaseDates(DELETE_RESULT_RELEASE_DATE(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 200) {
      getReleaseDates(GET_RESULT_RELEASE(category.split(' ').join('')), 'GET');
      alert('Success');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert(deleteRes?.data || 'Failed');
    }
  }, [deleteRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/5 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-3 flex justify-between items-center">
          <span className="block font-bold ">Result Release Date</span>
          <div className="flex items-center">
            <img
              src="assets/img/Close.svg"
              className=" h-3 cursor-pointer"
              alt=""
              onClick={() => {
                setOpenResultDate(false);
              }}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 place-items-end px-6">
            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-xs ">Select Test Type</label>
              <DropDown
                data={
                  examTypes.examPattern && Array.isArray(examTypes.examPattern)
                    ? examTypes.examPattern.map((x) => x.type)
                    : []
                }
                width={'w-full'}
                title={'Select'}
                otherClasses={'mt-2 border-borderLight'}
                handleItemClick={(x) => {
                  setFormData({
                    ...formData,
                    test_type: x,
                  });
                }}
              />
            </div>

            <div className="mt-2 mb-2 w-full relative font-semibold">
              <label className="block text-textDark text-xs">Result Date</label>
              <input
                type="date"
                required
                placeholder="Enter Result Release Date"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    releaseDate: e.target.value,
                  });
                }}
                value={formData.releaseDate}
                className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
              />
            </div>
          </div>

          <div className="mt-2 mb-2 pr-6 w-full relative font-semibold">
            <button
              className="w-36 border ml-auto  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-primary text-white bg-primary"
              onClick={() => {
                handlePublish();
              }}
            >
              Publish
            </button>
          </div>
        </div>

        <div className="w-full pb-4 px-6 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/3 border-r-2 py-2 px-2">Test Type</div>
                <div className="w-1/3 border-r-2 py-2 px-2">Release Date</div>
                <div className="w-1/3 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
              {!releaseDates.data?.getReleaseDates?.length ? (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              ) : (
                releaseDates.data?.getReleaseDates?.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                        idx !== releaseDates.data?.getReleaseDates?.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/3 px-2 text-textDark">{x.test_type}</div>
                      <div className="w-1/3 px-2 text-textDark">{moment(x?.releaseDate).format('ll')}</div>
                      <div className="w-1/3 px-2">
                        <button
                          className="border border-primary w-11/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                          onClick={() => handleDelete(x?._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultReleaseDate;
