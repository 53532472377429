import React, { useState, useEffect } from 'react';
import OpenAttendanceStudent from '../../../components/Admin/AttendanceReport/OpenAttendanceStudent';
import OpenAttendanceStaff from '../../../components/Admin/AttendanceReport/OpenAttendanceStaff';
import StaffReport from '../../../components/Admin/AttendanceReport/StaffReport';
import StudentReport from '../../../components/Admin/AttendanceReport/StudentReport';
import ExportReport from '../../../components/Admin/AttendanceReport/ExportReport';
import AttendanceRegularization from '../../../components/Admin/Leaves/AttendanceRegularization';

import useApi from '../../../api/useApi';
import { STAFF_ATTENDANCE, STUDENTS_ATTENDANCE_TOTAL } from '../../../api/urls/attendance';

function Dashboard({ role }) {
  const [openExport, setOpenExport] = useState(false);
  const [type, setType] = useState();

  const [selectedStaff, selectStaff] = useState();
  const [selectedStudent, selectStudent] = useState();

  const [teachingStaffAtt, setTeachingStaffAtt] = useState([]);
  const [nonTeachingStaffAtt, setNonTeachingStaffAtt] = useState([]);
  const [studentAtt, setStudentAtt] = useState([]);

  const [teachingStaffState, getTeachingStaffAtt] = useApi();
  const [nonTeachingStaffState, getNonTeachingStaffAtt] = useApi();
  const [studentState, getStudentAtt] = useApi();

  useEffect(() => {
    const _date = new Date();
    const startDate = new Date(_date.getFullYear(), _date.getMonth(), 1).toISOString();
    const endDate = new Date(_date.getFullYear(), _date.getMonth() + 1, 0).toISOString();

    getTeachingStaffAtt(`${STAFF_ATTENDANCE('teaching')}&startDate=${startDate}&endDate=${endDate}`, 'GET');
    getNonTeachingStaffAtt(`${STAFF_ATTENDANCE('non-teaching')}&startDate=${startDate}&endDate=${endDate}`, 'GET');
    getStudentAtt(`${STUDENTS_ATTENDANCE_TOTAL}&startDate=${startDate}&endDate=${endDate}`, 'GET');
  }, []);

  useEffect(() => {
    if (!teachingStaffState.isLoading && !teachingStaffState.isError && teachingStaffState.data) {
      setTeachingStaffAtt(teachingStaffState.data);
    }
  }, [teachingStaffState]);

  useEffect(() => {
    if (!nonTeachingStaffState.isLoading && !nonTeachingStaffState.isError && nonTeachingStaffState.data) {
      setNonTeachingStaffAtt(nonTeachingStaffState.data);
    }
  }, [nonTeachingStaffState]);

  useEffect(() => {
    if (!studentState.isLoading && !studentState.isError && studentState.data) {
      setStudentAtt(studentState.data);
    }
  }, [studentState]);

  // useEffect(() => {
  //   if (selectedStaff) {
  //     toggleStaffView();
  //   }
  // }, [selectedStaff]);

  // useEffect(() => {
  //   if (selectedStudent) {
  //     toggleStudentView();
  //   }
  // }, [selectedStudent]);

  useEffect(() => {
    if (type) {
      toggleExport();
    }
  }, [type]);

  const toggleStudentView = () => {
    selectStudent(null);
  };

  const toggleStaffView = () => {
    selectStaff(null);
  };

  const toggleExport = () => {
    if (openExport) {
      setType(null);
    }
    setOpenExport((prev) => !prev);
  };

  return (
    <div className="w-full md:w-4/5 pb-10">
      {selectedStudent && <OpenAttendanceStudent toggleView={toggleStudentView} selectedStudent={selectedStudent} />}
      {selectedStaff && <OpenAttendanceStaff toggleView={toggleStaffView} selectedStaff={selectedStaff} />}
      {openExport && <ExportReport toggleView={toggleExport} type={type} />}

      <StaffReport
        title={'Teaching Staff'}
        toggleExport={toggleExport}
        data={teachingStaffAtt}
        selectStaff={selectStaff}
        type={'teaching'}
        setType={setType}
        isLoading={teachingStaffState.isLoading}
      />
      <StaffReport
        title={'Non-Teaching Staff'}
        toggleExport={toggleExport}
        data={nonTeachingStaffAtt}
        selectStaff={selectStaff}
        type={'non-teaching'}
        setType={setType}
        isLoading={nonTeachingStaffState.isLoading}
      />

      {role === 'principal' && <AttendanceRegularization />}
      <StudentReport
        toggleExport={toggleExport}
        data={studentAtt}
        selectStudent={selectStudent}
        setType={setType}
        isLoading={studentState.isLoading}
      />
    </div>
  );
}

export default Dashboard;
