import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DoughnutChart from '../../../components/DoughnutChart';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import useApi from '../../../api/useApi';
import { STUDENT, PARENT } from '../../../api/urls/dashboard';

function Dashboard({ role }) {
  const [showSort, setSort] = useState();
  const [selected, select] = useState(null);

  const [data, getData] = useApi();

  useEffect(() => {
    if (role === 'student') getData(STUDENT, 'GET');
    else getData(PARENT, 'GET');
  }, []);

  return (
    <>
      {selected && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 text-sm m-auto mt-20 rounded-2xl relative">
            <div className="flex justify-between border-b border-borderYellow items-center">
              {' '}
              <span className="font-bold px-6 py-4">Lecture Detail - {selected?.subject.subjectName}</span>
              <div className="flex gap-x-4 items-center pr-2">
                {/* <ExcelExportBtn data={[]} text={'Export Data'} fileName={'students.csv'} /> */}

                <img
                  src="assets/img/Close.svg"
                  className="h-3 mr-4 cursor-pointer"
                  alt=""
                  onClick={() => select(null)}
                />
              </div>
            </div>

            <div className="p-6 m-auto" autoComplete="off">
              <div className="w-full relative mb-6">
                <span className="font-bold">Title</span>
                <p className="text-sm text-textDark mt-2">{selected?.summary?.title}</p>
              </div>

              <div className="mt-2 mb-6 w-full relative ">
                <span className="font-bold">Description</span>
                <p className="text-sm text-textDark mt-2">{selected?.summary?.summary}</p>
              </div>

              {selected.notes && (
                <div className="mx-4 w-full flex">
                  <a
                    className="border border-primary ml-auto w-40  mr-6 rounded-3xl text-xs focus:outline-none text-center py-2 bg-primary text-white"
                    href={selected.notes.files[0].location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Notes
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full md:w-4/5">
        <div className=" bg-white rounded-xl h-auto mb-5 w-full">
          <div className="items-center px-6 py-3.5 border-b border-borderYellow">
            <p className="font-bold text-base">Attendance Summary</p>
          </div>

          <div className="flex flex-col md:flex-row justify-between px-8 pt-8 pb-2">
            <div className="w-full md:w-1/2">
              <div className="">
                <div className="bg-yellow-50 rounded-xl block pl-6 py-4">
                  <p className="text-xs opacity-50"> Attendance Duration</p>
                  <p className="text-xl text-black">
                    {' '}
                    {`April ${new Date().getFullYear()} - March ${new Date().getFullYear() + 1}`}
                  </p>
                  <p className="text-xs text-black opacity-50 mt-1"> Total Number of Days</p>
                  <p className="text-xl text-black">
                    {data?.data?.attendance_report?.present?.value + data?.data?.attendance_report?.absent?.value || 0}
                  </p>
                </div>
                <div className=" pl-6 py-4 my-4">
                  <p className="text-xs"> Attendance Percentage</p>
                  <p className="text-xl"> {`${data?.data?.attendance_report?.cumulative || 0}%`}</p>

                  <p className="text-xs mt-1"> Total Present Days</p>
                  <p className="text-xl text-green-500 flex items-center gap-1">
                    <div className="h-2.5 w-2.5 rounded-full bg-green-500 mt-1"></div>
                    {data?.data?.attendance_report?.present?.value || 0}
                  </p>

                  <p className="text-xs mt-1"> Total Absent Days</p>
                  <p className="text-xl text-red-500 flex items-center gap-1">
                    <div className="h-2.5 w-2.5 rounded-full bg-red-600 mt-1"></div>
                    {data?.data?.attendance_report?.absent?.value || 0}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <div className="w-full md:w-8/12 flex justify-center items-center m-auto">
                <DoughnutChart
                  labels={['']}
                  values={[data?.data?.attendance_report?.present?.value, data?.data?.attendance_report?.absent?.value]}
                  color={[' rgba(16, 185, 129, 0.5)', 'rgba(220, 68, 68,0.7)']}
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-white rounded-xl h-auto mb-5 w-full">
          <div className="w-full bg-white rounded-xl h-auto mb-5">
            <div className="items-center px-6 pt-3 pb-3 border-b border-borderYellow">
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <div className="font-bold text-base ">
                  <p>Today’s Lecture Detail</p>
                </div>

                <div className="relative mx-2 w-1/2 md:w-1/6">
                  <DropDown
                    data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                    width={'w-full'}
                    title={'Sort By'}
                    handleItemClick={() => {}}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full ">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border border-borderYellow rounded-2xl min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-1/5 border-r-2 py-2 px-2">Timings</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                      <div className="w-1/5 border-r-2 py-2 px-2"> Faculty</div>
                      <div className="w-1/5 py-2 px-2">Action</div>
                    </div>
                  </div>

                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                    {data.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                    ) : (
                      Array.isArray(data?.data?.todayLectures) &&
                      data?.data?.todayLectures.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                              data?.data?.todayLectures.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/5 text-tableRowText px-2">{`${moment(x.startTime, ['h:mm']).format(
                              'hh:mm A'
                            )} to ${moment(x.endTime, ['h:mm']).format('hh:mm A')}`}</div>
                            <div className="w-1/5 text-tableRowText px-2">{x.subject.subjectCode}</div>
                            <div className="w-1/5 text-tableRowText px-2">{x.subject.subjectName}</div>
                            <div className="w-1/5 text-tableRowText px-2">{x.teacher}</div>
                            <div className="w-1/5 px-2">
                              {x.summary && (
                                <button
                                  type="submit"
                                  className="border w-11/12 border-primary px-2 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                                  onClick={() => select(x)}
                                >
                                  View Detail
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
