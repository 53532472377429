import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../../api/useApi';
import { UPDATE_INSTITUTE } from '../../../api/urls/institute';

const BrandingSettingComponent = ({ institute, refresh }) => {
  const [edit, setEdit] = useState('');
  const [file, setFile] = useState();

  const formDataModel = {
    name: '',
    address: '',
    phone: '',
    slug: '',
    url: '',
    about: '',
    financeCycle: '',
    type: '',
    logo: null,
    cover: null,
  };

  const logoRef = useRef();

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, updateInstitution] = useApi();

  useEffect(() => {
    if (institute) {
      setFormData(institute);
    }
  }, [institute]);

  const handleSubmit = () => {
    const body = new FormData();
    body.append('name', formData.name);

    updateInstitution(UPDATE_INSTITUTE(institute._id), 'PUT', body);
  };

  useEffect(() => {
    if (file) {
      const body = new FormData();
      body.append('logo', file);

      updateInstitution(UPDATE_INSTITUTE(institute._id), 'PUT', body);
    }
  }, [file]);

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      refresh();
      alert('SUCCESS');
      setEdit();
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div>
      <div className="bg-white rounded-2xl shadow-sm">
        <div className="px-6 py-4" style={{ borderBottom: '1px solid #E7E700' }}>
          <p className="font-bold ">Branding </p>
        </div>

        <div className="mx-6 my-0 px-0 py-3 flex items-center border-b border-borderYellow">
          <input hidden ref={logoRef} type="file" onChange={(e) => setFile(e.target.files[0])} />
          <p className="text-textDark text-xs font-medium w-1/5">Company Logo </p>
          <p
            className={`text-tableRowText text-xs w-3/5 bg-white mr-10 px-4 py-2 rounded-full ${
              edit === 'logo' && ' border border-borderLight'
            }`}
          >{`${formData?.name}.${institute.logo ? institute.logo.type.split('/')[1] : ''}`}</p>
          <button
            className={`${
              edit === 'logo' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
            }   text-xxs ml-auto cursor-pointer  py-1.5`}
            onClick={() => (edit === 'logo' ? setEdit('') : logoRef.current.click())}
          >
            {edit === 'logo' ? 'Save' : 'Upload'}
          </button>
        </div>

        <div className="mx-6 my-0 px-0 py-3 flex items-center">
          <p className="text-textDark text-xs font-medium w-1/5">Company Name</p>
          <input
            className={`text-tableRowText text-xs w-3/5 bg-white mr-10 px-4 py-2 rounded-full ${
              edit === 'name' && ' border border-borderLight'
            }`}
            disabled={edit !== 'name'}
            onChange={handleInputChange}
            name="name"
            value={formData?.name}
          />
          <button
            className={`${
              edit === 'name' ? 'text-white bg-primary rounded-full px-4 ' : 'text-textBlue underline'
            }   text-xxs ml-auto cursor-pointer  py-1.5`}
            onClick={() => (edit === 'name' ? handleSubmit() : setEdit('name'))}
          >
            {edit === 'name' ? 'Save' : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandingSettingComponent;
