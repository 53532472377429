import React, { useState } from 'react';
import moment from 'moment';
import DropDown from '../../UI/DropDown';
import Search from '../../UI/Search';

function IncidetReported({ selectIncident, data, isLoading }) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');

  let filteredData = data || [];

  if (sort && sort !== 'All') {
    filteredData = filteredData.filter((x) => {
      if (sort === 'Pre - Primary') {
        return x.class.toLowerCase().includes('kg') || x.class.toLowerCase().includes('nursery');
      } else if (sort === 'Senior Secondary') {
        return x.class.toLowerCase().includes('11') || x.class.toLowerCase().includes('12');
      } else if (sort === 'Secondary') {
        return x.class.toLowerCase().includes('9') || x.class.toLowerCase().includes('10');
      } else if (sort === 'Middle') {
        return (
          x.class.toLowerCase().includes('6') ||
          x.class.toLowerCase().includes('7') ||
          x.class.toLowerCase().includes('8')
        );
      } else {
        return (
          (x.class.toLowerCase().includes('1') &&
            !x.class.toLowerCase().includes('11') &&
            !x.class.toLowerCase().includes('12')) ||
          (x.class.toLowerCase().includes('2') && !x.class.toLowerCase().includes('12')) ||
          x.class.toLowerCase().includes('3') ||
          x.class.toLowerCase().includes('4') ||
          x.class.toLowerCase().includes('5')
        );
      }
    });
  }

  if (search) {
    filteredData = filteredData.filter(
      (x) =>
        x?.teacher.toLowerCase().includes(search.toLowerCase()) ||
        x?.student.toLowerCase().includes(search.toLowerCase()) ||
        x?.class.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>Incident Reported</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <Search
              onChange={(x) => {
                setSearch(x);
              }}
              onEnter={() => {}}
              width={'w-36'}
              placeholder={'Search'}
            />

            <DropDown
              data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior Secondary']}
              width={'w-1/2 md:w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">Incident Date</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Incident Time</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Class</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Reported By</div>
                <div className="w-2/12 py-2 px-2">Incident Details</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '75vh' }}>
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                          idx !== filteredData.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-2/12 px-2 text-tableRowText">{moment(x.date).format('D MMMM YYYY')}</div>
                        <div className="w-2/12 px-2 text-textDark ">{moment(x.date).format('HH:mm a')}</div>
                        <div className={`w-2/12 px-2 text-textDark `}>{x.student}</div>
                        <div className={`w-2/12 px-2 text-textDark `}>{x.class}</div>
                        <div className={`w-2/12 px-2 text-textDark `}>{x.teacher}</div>
                        <div className={`w-2/12 px-2 py-0.5`}>
                          <button
                            className="w-11/6 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => selectIncident(x)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidetReported;
