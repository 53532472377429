import styled from "styled-components";
import fontFamily from "../fontFamily";

export const ProfileWrapper= styled.div`
position: relative;
left: 19%;
top: 5em;
width: 43%;
margin-bottom: 1em;
z-index:10;
`;

export const Component1 = styled.div`
  background-color: ${fontFamily.colors.white};
  border-radius: 14px;
  padding: 22.77px 0 9.96px;
  box-shadow: 0 0 2px 0
    ${fontFamily.colors.transparentRed};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const NotificationSettings = styled.p`
  height: 9.27%;
  width: 22%;
  color: ${fontFamily.colors.black4};
  display: flex;
  margin-bottom: 21.34px;
  margin-left: 26.32px;
  font-family: ${fontFamily.fonts.lato14Bold.family};
  font-size: ${fontFamily.fonts.lato14Bold.size};
  font-weight: ${fontFamily.fonts.lato14Bold.weight};
  line-height: ${fontFamily.fonts.lato14Bold.lineHeight};
`;
export const Line15 = styled.div`
  height: 0.39%;
  background-color: ${fontFamily.colors.gold};
  margin-bottom: 9.25px;
  align-self: stretch;
`;
export const FlexWrapperOne = styled.div`
  margin-bottom: 6.4px;
  padding: 0 0 0 26.32px;
  display: flex;
  align-items: flex-start;
`;
export const Sound = styled.p`
  height: 39.59%;
  width: 6.41%;
  color: ${fontFamily.colors.black};
  display: flex;
  margin-top: 6.4px;
  margin-right: 470.28px;
  font-family: ${fontFamily.fonts.roboto11Medium.family};
  font-size: ${fontFamily.fonts.roboto11Medium.size};
  font-weight: ${fontFamily.fonts.roboto11Medium.weight};
  line-height: ${fontFamily.fonts.roboto11Medium.lineHeight};
`;
export const Toggle = styled.div`
  width: calc(6.69% - 2.85px);
  padding: 8.54px 1.43px 8.53px 1.42px;
  display: flex;
  align-items: center;
  align-self: stretch;
`;
export const Vector = styled.img`
  width: 100%;
  align-self: stretch;
  object-fit: cover;
`;
export const Line20 = styled.img`
  width: 91.11%;
  margin-bottom: 8.54px;
  margin-left: 24.19px;
`;
export const FlexWrapperTwo = styled.div`
  padding: 0 0 0 26.32px;
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 9.96px;
  }
`;
export const DesktopAlert = styled.p`
  height: 39.59%;
  width: 13.65%;
  color: ${fontFamily.colors.black};
  display: flex;
  margin-right: 433.29px;
  font-family: ${fontFamily.fonts.roboto11Medium.family};
  font-size: ${fontFamily.fonts.roboto11Medium.size};
  font-weight: ${fontFamily.fonts.roboto11Medium.weight};
  line-height: ${fontFamily.fonts.roboto11Medium.lineHeight};
`;
export const Line16 = styled.img`
  width: 91.11%;
  margin-bottom: 8.53px;
  margin-left: 24.19px;
`;
export const TurnOffAllDesktopNotification = styled.p`
  height: 39.59%;
  width: 31.34%;
  color: ${fontFamily.colors.black};
  display: flex;
  margin-right: 342.93px;
  font-family: ${fontFamily.fonts.roboto11Medium.family};
  font-size: ${fontFamily.fonts.roboto11Medium.size};
  font-weight: ${fontFamily.fonts.roboto11Medium.weight};
  line-height: ${fontFamily.fonts.roboto11Medium.lineHeight};
`;