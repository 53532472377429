import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/UI/DropDown';

import useApi from '../../../api/useApi';
import { MAP_ROLE, REMOVE_ROLE } from '../../../api/urls/institute';

function MapRole({ selectedUser, selectUser, refreshData, getRole }) {
  const [selectedResponsibility, selectResponsibility] = useState('');

  const formDataModel = {
    user_id: selectedUser?._id,
    role: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const [{ isLoading, isError, data, status }, map] = useApi();
  const [removeRes, removeRole] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedResponsibility === 'Select' || selectedResponsibility === '') {
      alert('Please select Role');
      return;
    }

    let role = '';
    if (selectedResponsibility === 'Admin') role = 'ad';
    else if (selectedResponsibility === 'Owner') role = 'ow';
    else if (selectedResponsibility === 'Principal') role = 'pr';
    else if (selectedResponsibility === 'Finance') role = 'fi';
    else if (selectedResponsibility === 'Teacher') role = 'te';

    const body = {
      ...formData,
      role: role,
    };

    map(MAP_ROLE, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      alert('Success');
      setFormData(formDataModel);
      selectUser();
      refreshData();
    }

    if (isError && status == 400) {
      alert(data);
    }
  }, [isLoading, isError, data, status]);

  const handleRemove = (role) => {
    removeRole(REMOVE_ROLE(selectedUser?._id, role), 'DELETE');
  };

  useEffect(() => {
    if (!removeRes.isLoading && !removeRes.isError && removeRes.status === 200) {
      alert('Success');
      selectUser();
      refreshData();
    }

    if (removeRes.isError && removeRes.status == 400) {
      alert(data);
    }
  }, [removeRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-2/5 px-2 text-sm m-auto rounded-2xl relative">
        <div className=" flex justify-between border-b border-borderYellow items-center">
          <span className="block font-bold p-4 text-textBlue1">Map Role - {selectedUser?.full_name}</span>
          <div className="flex gap-x-4 items-center pr-2">
            <img
              src="assets/img/Close.svg"
              className="h-3 cursor-pointer"
              alt=""
              onClick={() => {
                selectUser();
              }}
            />
          </div>
        </div>

        <div className=" bg-white rounded-xl h-auto mb-5 w-full px-6 mt-6">
          <div className="flex ">
            <img
              src={
                selectedUser?.fileArray?.length > 0 && selectedUser?.fileArray[0]?.profileImg?.location
                  ? selectedUser?.fileArray[0].profileImg.location
                  : '/assets/img/user_placeholder.svg'
              }
              alt="img"
              className="profileLogo overflow-hidden bg-gray-500 w-40 h-40"
            />

            <div className="flex flex-col ml-6">
              <p className="text-textDark text-lg">{selectedUser?.full_name}</p>
              <p className="text-tableRowText text-sm">{selectedUser?.username}</p>
              {/* <p className="text-textDark text-xs mb-4">{selectedUser?.role}</p> */}

              <div className="mt-4 ">
                <p className="text-textDark text-lg">Roles</p>
                {selectedUser?.currentlyMapped?.role ? (
                  selectedUser?.currentlyMapped?.role.map((x) => (
                    <div className="flex gap-x-4 mb-2">
                      <p className="text-tableRowText text-sm w-1/2">{getRole(x)}</p>
                      <button
                        onClick={() => handleRemove(x)}
                        className="border w-1/2 border-primary text-primary  px-4 rounded-3xl text-xxs focus:outline-none"
                      >
                        Remove
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-sm"> No Role Mapped</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="w-full relative font-semibold px-6 mb-6">
            <label className="text-textDark font-medium text-sm">Map Role</label>
            <DropDown
              data={['Select', 'Admin', 'Principal', 'Finance', 'Teacher']}
              handleItemClick={(x) => {
                selectResponsibility(x);
              }}
              title={'Select'}
              width={'w-full'}
              otherClasses={'border-borderLight mt-2 text-textDark'}
            />
          </div>

          <div className="w-full px-6">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto mt-2" alt="" />
            ) : (
              <button
                type="submit"
                className="w-25 mb-6 float-right border border-primary text-white bg-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
              >
                {'Map'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default MapRole;
