import React from 'react';
import moment from 'moment';

function JustificationDetail({ onClose, selectedJustificationDetail }) {
  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="relative w-1/2 pb-6 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="border-b border-borderYellow flex justify-between items-center py-4 px-6">
          <span className=" font-bold text-textBlue1 ">Justification</span>
          <img src="assets/img/Close.svg" className="h-3 cursor-pointer" alt="" onClick={onClose} />
        </div>

        <h4 className="font-bold text-base text-black ml-6 mt-4 mb-2">Expense Detail</h4>

        <div className="flex flex-wrap w-full px-2 pb-6">
          <div className="px-4 w-full grid grid-cols-2 gap-x-4">
            <div className="my-2  relative  w-full ">
              <label htmlFor="date" className="text-sm text-textDark font-medium">
                Expense Title
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedJustificationDetail?.name}
              </p>
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="title" className="text-sm text-textDark font-medium">
                Expense Date
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {moment(selectedJustificationDetail?.date).format('DD MMMM YYYY')}
              </p>
            </div>
            <div className="my-2  relative  w-full">
              <label htmlFor="description" className="text-sm text-textDark font-medium">
                Category
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedJustificationDetail?.category?.category}
              </p>
            </div>

            <div className="my-2  relative  w-full">
              <label htmlFor="amount" className="text-sm text-textDark font-medium">
                Amount
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedJustificationDetail?.amount}
              </p>
            </div>

            <div className="my-2  relative  w-full col-span-2">
              <label htmlFor="amount" className="text-sm text-textDark font-medium">
                Justification
              </label>
              <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                {selectedJustificationDetail?.justification}
              </p>
            </div>

            <div className="my-2 relative  w-full col-span-2">
              <label className="text-sm text-textDark font-medium">Justification Attachment</label>
              <div className="relative flex">
                <p className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                  {selectedJustificationDetail?.justificationFile.length > 0 &&
                    selectedJustificationDetail?.justificationFile[0].name}
                </p>
                <a
                  href={
                    selectedJustificationDetail?.justificationFile.length > 0 &&
                    selectedJustificationDetail?.justificationFile[0].location
                  }
                  download
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0 no-underline"
                >
                  Download
                </a>
              </div>

              <div className="mt-4">
                <p className="text-sm text-textDark font-medium">Receipt</p>
                <div className="mt-2 relative font-semibold border border-borderLight rounded-md">
                  {selectedJustificationDetail?.files.length > 0 && selectedJustificationDetail?.files[0].link ? (
                    <img src={selectedJustificationDetail?.files[0].link} alt="" className="w-full mx-auto h-auto" />
                  ) : (
                    <div className="border h-32 flex justify-center items-center mt-2 rounded-2xl">No Receipt</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JustificationDetail;
