import { FINANCE } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const STAFF_SALARY = `${FINANCE}/staff/salary?year=${year}`;

export const STAFF_SALARY_HISTORY = (userId) => `${FINANCE}/user/${userId}/salaryhistory?year=${year}`;

export const SINGLE_STAFF = (id) => `${FINANCE}/staff/${id}/salary?year=${year}`;

export const UPDATE_STAFF_SALARY = (id) => `${FINANCE}/staff/salary/${id}?year=${year}`;

export const EXPENSES = `${FINANCE}/expenses?year=${year}`;

export const EXPENSE_CATEGORIES = `${FINANCE}/expenses/categories?year=${year}`;

export const ASK_FOR_JUSTIFICATION = (id) => `${FINANCE}/justification/expenses/${id}?year=${year}`;

export const SEND_EXPENSE_JUSTIFICATION = (id) => `${FINANCE}/sendJustification/expenses/${id}?year=${year}`;

export const BUDGET = (month) => `${FINANCE}/budget?year=${year}&month=${month}`;

export const CREATE_CATEGORY = `${FINANCE}/expenses/categories?year=${year}`;

export const UPDATE_CATEGORY = (id) => `${FINANCE}/expenses/categories/${id}?year=${year}`;

export const DELETE_CATEGORY = (id, month) => `${FINANCE}/expenses/categories/${id}?month=${month}&year=${year}`;

export const CLASS_FEE_STRUCTURE = `${FINANCE}/fees/class?year=${year}`;

export const PENDING_FEE = `${FINANCE}/fees/cycle/pending?year=${year}`;

export const STUDENT_FEE = (studentId) => `${FINANCE}/fees/student/${studentId}?year=${year}`;

export const STUDENT_FEE_APPROVAL = `${FINANCE}/fees/feeApproval?year=${year}`;

export const APPROVE_FEE = (feeId) => `${FINANCE}/fees/${feeId}/feeApproval?year=${year}`;

export const RECENT_TRANSACTIONS = `${FINANCE}/recent/transcation?year=${year}`;

export const FEE_CYCLE = `${FINANCE}/fees/cycles?year=${year}`;

export const RECENT_FEES = `${FINANCE}/getRecentFee?year=${year}`;

export const CLASS_FEE = (class_id, section_id) => `${FINANCE}/fees/class/${class_id}/${section_id}?year=${year}`;

export const STAFF_SALARY_RELEASE = `${FINANCE}/staff/markReleasedSalary?year=${year}`;

export const UPDATE_CLASS_FEE = (fee_id) => `${FINANCE}/fees/class/${fee_id}?year=${year}`;

export const ADD_CLASS_FEE_STRUCTURE = (classId, sectionId) =>
  `${FINANCE}/fees/class/${classId}/section/${sectionId}?year=${year}`;

export const EXPENSE_REQUEST = `${FINANCE}/preapproval/expenses?year=${year}`;

export const EXPENSE_REQUEST_LIST = `${FINANCE}/expenses/request?year=${year}`;

export const EXPENSE_UPDATE = (id) => `${FINANCE}/expenses/${id}?year=${year}`;

export const EXPENSE_REQUEST_STATUS_UPDATE = (id) => `${FINANCE}/expenses/request/${id}?year=${year}`;

export const ADD_DISCOUNT = (studentId) => `${FINANCE}/staff/discount/${studentId}?year=${year}`;

export const GET_FEE_STRUCTURE_FOR_STUDENT = (studentId) => `${FINANCE}/fees/newstudentFee/${studentId}?year=${year}`;

export const GET_CLASS_PENDING_FEE = (classId, sectionId) =>
  `${FINANCE}/fees/class/${classId}/section/${sectionId}/pending?year=${year}`;

export const GET_BALANCE_FEE = (studentId) => `${FINANCE}/student/${studentId}?year=${year}`;

export const MISC_FEE = (studentId) => `${FINANCE}/miscfees/student/${studentId}?year=${year}`;

export const INVENTORY = `${FINANCE}/inventory?year=${year}`;

export const UPDATE_INVENTORY = (id) => `${FINANCE}/inventory/${id}?year=${year}`;
