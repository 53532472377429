import React, { useState, useRef, useEffect } from 'react';

function DropDown({
  data = [],
  width,
  margin,
  handleItemClick,
  disabled = false,
  title,
  otherClasses,
  src,
  type = 'string',
  field,
}) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedItem, selectItem] = useState(title || data[0]);

  const onClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  const onItemClick = (x) => {
    if (field) selectItem(x[field]);
    else selectItem(x);
    handleItemClick(x);
    setIsActive(false);
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    if (title) {
      selectItem(title);
    }
  }, [title]);

  return (
    <div className={`relative ${width} ${margin}`}>
      {src ? (
        <div>
          <img
            alt=""
            src={src}
            onClick={!disabled && onClick}
            className={`w-full px-4 py-2 relative rounded-3xl text-xs  focus:outline-none flex items-center justify-between ${
              disabled && 'opacity-50 cursor-default'
            } ${otherClasses}`}
          />
        </div>
      ) : (
        <button
          className={`w-full border pl-4 py-2 relative rounded-3xl text-xs border-tableRowText focus:outline-none flex items-center justify-between ${
            !disabled ? ' text-textDark' : 'text-textLight'
          }  ${disabled && 'opacity-50 cursor-default text-textDark'} ${otherClasses}`}
          onClick={!disabled && onClick}
        >
          {selectedItem}
          {!disabled && (
            <img
              src="assets/img/expandIcon.svg"
              className="h-2 ml-4 absolute right-3 top-1/2 transform -translate-y-1/2 "
              alt=""
            />
          )}
        </button>
      )}
      {isActive && (
        <nav
          ref={dropdownRef}
          className={`absolute overflow-auto max-h-48 flex flex-col px-4 pt-4 pb-3 shadow-2xl rounded-xl bg-white z-50 ${
            src ? 'right-0 w-40 top-8' : 'w-full top-10'
          }`}
        >
          {data.map((x) => (
            <button
              className="text-xs text-left text-black font-medium mb-2.5 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                onItemClick(x);
              }}
            >
              {type === 'obj' ? x[field] : x}
            </button>
          ))}
        </nav>
      )}
    </div>
  );
}

export default DropDown;
