import React, { useState, useEffect } from 'react';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';
import { EXAM_DATES } from '../../../api/urls/test';
import useApi from '../../../api/useApi';

function ExamDates({ selectedClassForDates, selectClassForDates }) {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [datesRes, getDates] = useApi();

  useEffect(() => {
    getDates(EXAM_DATES(selectedClassForDates?.classId, selectedClassForDates?.sectionId), 'GET');
  }, []);

  useEffect(() => {
    if (!datesRes.isLoading && !datesRes.isError && datesRes.data && Array.isArray(datesRes.data)) {
      let x = datesRes.data;
      const _headers = x.map((z) => z.testType);
      const _map = new Map();

      x.forEach((z) => {
        if (_map.has(z.subjectCode)) {
          let m = _map.get(z.subjectCode);
          m = {
            ...m,
            [z.testType]: z.examDate,
          };
          _map.set(z.subjectCode, m);
        } else {
          _map.set(z.subjectCode, {
            [z.testType]: z.examDate,
            subjectCode: z.subjectCode,
            subjectName: z.subjectName,
          });
        }
      });

      setData(_map);
      setHeaders([...new Set(_headers)]);
    }
  }, [datesRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-9/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-3 flex flex-col md:flex-row justify-between md:items-center">
          <span className="block font-bold ">{`${selectedClassForDates?.class} - ${selectedClassForDates?.section}`}</span>
          <div className="flex items-center">
            {/* <ExcelExportBtn data={[]} text={'Export Data'} fileName={'Report.csv'} handleClick={() => {}} /> */}

            <DropDown
              data={['All', 'Completed', 'Incomplete']}
              width={'w-36'}
              title={'Sort By'}
              margin={'ml-4'}
              handleItemClick={() => {}}
            />

            <img
              src="assets/img/Close.svg"
              className=" h-3 ml-4 cursor-pointer"
              alt=""
              onClick={() => {
                selectClassForDates(null);
              }}
            />
          </div>
        </div>
        <div className="mt-2 mb-4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/6 border-r-2 py-2 px-2">Subject Name</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Subject Code</div>
                  {headers.map((x, _idx) => (
                    <div className={`w-1/6 ${headers.length - 1 != _idx && 'border-r-2'} py-2 px-2`}>Date - {x} </div>
                  ))}
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                {datesRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : (
                  Array.from(data.values()).map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                          idx !== Array.from(data.values()).length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/6 px-2 py-2 text-textDark">{x.subjectName}</div>
                        <div className="w-1/6 px-2 py-2 text-textDark">{x.subjectCode}</div>
                        {headers.map((z) => (
                          <div className="w-1/6 px-2 py-2 text-tableRowText">{x[z]}</div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamDates;
