import React, { useEffect, useState } from 'react';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';
import DropDown from '../../UI/DropDown';

function ClassStudents({ setOpenCreate, subjects }) {
  const [selectedClass, selectClass] = useState();
  const [students, getStudents] = useApi();

  useEffect(() => {
    if (selectedClass) {
      getStudents(SECTION_STUDENTS(selectedClass.classId, selectedClass.sectionId), 'GET');
    }
  }, [selectedClass]);

  const classSection = [];
  for (let i = 0; i < subjects.length; i++) {
    const {
      subjectName,
      subjectId,
      class: { _id: classId, name: className },
      section: { _id: sectionId, name: sectionName },
    } = subjects[i];
    classSection.push({ classSection: `${className} - ${sectionName}`, classId, sectionId, subjectName, subjectId });
  }

  let _students = [];
  if (students && students.data && Array.isArray(students.data)) {
    _students = students.data.map((x) => {
      return {
        id: x?._id,
        name: x.full_name,
        roledinId: x.username,
        parent:
          x.parentDetail.length > 0
            ? x.parentDetail[0].father_name || x.parentDetail[0].mother_name || x.parentDetail[0].guardian_name
            : '',
      };
    });
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-4/12">
            <p>My Class</p>
          </div>

          <div>
            <DropDown
              data={classSection}
              type={'obj'}
              field={'classSection'}
              width={'w-36'}
              title={'Select Class'}
              handleItemClick={(x) => {
                selectClass(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-3/12 border-r-2 py-2 pr-2">Student Name</div>
                <div className="w-3/12 border-r-2 py-2 px-2">Parent Name</div>
                <div className="w-3/12 border-r-2 py-2 px-2">RoledIN ID</div>
                <div className="w-3/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '35vh' }}>
              {students.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto " alt="" />
              ) : _students.length > 0 ? (
                _students.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center items-center flex w-full relative text-left py-2 px-4 text-xs font-medium ${
                        _students.length - 1 !== idx && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-3/12 px-2 text-tableRowText">{x.name}</div>
                      <div className="w-3/12 px-2 text-tableRowText">{x.parent}</div>
                      <div className="w-3/12 px-2 text-tableRowText">{x.roledinId}</div>
                      <div className="w-3/12 px-2 text-tableRowText">
                        <button
                          className="w-full lg:w-max border px-8 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                          onClick={() => {
                            setOpenCreate(x);
                          }}
                        >
                          Create Request
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassStudents;
