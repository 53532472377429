import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import {
  GET_ALL_LIVE_LECTURES,
  CREATE_LECTURE_LINK,
  UPDATE_LECTURE_LINK,
  CREATE_SUMMARY,
  UPDATE_SUMMARY,
} from '../../../api/urls/notes';

const LiveSessions = ({ subjects }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [action, setAction] = useState();
  const [allLectures, setAllLectures] = useState([]);
  const [selected, select] = useState();
  const [link, setLink] = useState('');
  const [filter, setFilter] = useState();

  const [formData, setFormData] = useState({
    lesson: '',
    title: '',
    summary: '',
    isLiveSession: true,
  });

  const [lectures, getAllLectures] = useApi();
  const [uploadRes, uploadLink] = useApi();
  const [summaryRes, summaryOperation] = useApi();

  useEffect(() => {
    getAllLectures(GET_ALL_LIVE_LECTURES, 'GET');
  }, []);

  useEffect(() => {
    const { isLoading, isError, data } = lectures;
    if (!isLoading && !isError && data) {
      setAllLectures(data);
    }
  }, [lectures]);

  useEffect(() => {
    if (selected && action) {
      if (action === 'add') {
        setOpenAdd(true);
        if (selected.link) setLink(selected.link.link);
      } else if (action === 'summary') {
        setOpenSummary(true);
        if (selected.summary)
          setFormData({
            lesson: selected.summary.lesson,
            title: selected.summary.title,
            summary: selected.summary.summary,
          });
      }
    }
  }, [selected, action]);

  // useEffect(() => {
  //   if (selected) {
  //     setOpenAdd(true);
  //     if (selected.link) setLink(selected.link.link);
  //   }
  // }, [selected]);

  const convertTimeToDate = (timeString) => {
    const [hours, minutes] = timeString.split(':');

    let date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    return date;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!link?.trim()) str.push('Link');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    const body = {
      link: link,
      startTime: convertTimeToDate(selected.startTime),
      endTime: convertTimeToDate(selected.endTime),
      date: selected.date,
    };

    if (selected.link) {
      uploadLink(
        UPDATE_LECTURE_LINK(selected.class._id, selected.section._id, selected.subjectId, selected.link._id),
        'PUT',
        body
      );
    } else {
      uploadLink(CREATE_LECTURE_LINK(selected.class._id, selected.section._id, selected.subjectId), 'POST', body);
    }
  };

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && [200, 201, 202].includes(uploadRes.status)) {
      alert('SUCCESS');
      handleClose();
      refresh();
    }

    if (uploadRes.isError) {
      alert('FAILED');
    }
  }, [uploadRes]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit2 = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData?.lesson) str.push('Lesson');
    if (!formData?.title?.trim()) str.push('Title');
    if (!formData?.summary.trim()) str.push('Lecture Summary');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    const body = {
      ...formData,
      date: selected.date,
    };

    if (selected.summary) {
      summaryOperation(
        UPDATE_SUMMARY(selected.class._id, selected.section._id, selected.subjectId, selected.summary._id),
        'PUT',
        body
      );
    } else {
      summaryOperation(CREATE_SUMMARY(selected.class._id, selected.section._id, selected.subjectId), 'POST', body);
    }
  };

  useEffect(() => {
    if (!summaryRes.isLoading && !summaryRes.isError && summaryRes.status) {
      alert('SUCCESS');
      handleClose();
      refresh();
    }

    if (summaryRes.isError) {
      alert('FAILED');
    }
  }, [summaryRes]);

  const handleClose = () => {
    select();
    setLink();
    setOpenAdd(false);
    setAction('');
    setOpenSummary(false);
    setFormData({
      lesson: '',
      title: '',
      summary: '',
    });
  };

  const refresh = () => {
    getAllLectures(GET_ALL_LIVE_LECTURES, 'GET');
  };

  const lessons = subjects?.find((subject) => subject?.subjectId === selected?.subjectId) || [];

  let _allLectures = allLectures || [];
  if (filter) {
    _allLectures = allLectures.map((m) => {
      if (filter[m.subject] === 'Upcoming') {
        return {
          ...m,
          lectures: m.lectures.filter((d) => moment(d.lectureDate).isAfter(new Date())),
        };
      } else if (filter[m.subject] === 'Delivered') {
        return {
          ...m,
          lectures: m.lectures.filter((d) => moment(d.lectureDate).isBefore(new Date())),
        };
      } else {
        return {
          ...m,
        };
      }
    });
  }

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {openAdd && (
        <div
          className="flex justify-center items-start h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-7/12 text-sm block mx-auto mt-20 rounded-2xl relative">
            <div className="flex justify-between items-center border-b border-borderYellow py-4 px-6">
              <span className="block font-bold  ">Live Session Link - {selected.subject}</span>
              <img
                src="assets/img/Close.svg"
                className="absolute top-5 right-8 h-3 cursor-pointer"
                alt=""
                onClick={handleClose}
              />
            </div>
            <form className="mt-2 mb-4 mx-6 flex flex-wrap" autoComplete="off" onSubmit={handleSubmit}>
              <div className="mt-2 w-full md:w-2.25/5 relative font-semibold disabled">
                <label htmlFor="date" className="block  text-sm text-textDark">
                  Date<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={moment(selected.date).format('ll')}
                  autoComplete="off"
                  disabled
                  required
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2 focus:outline-none opacity-70"
                />
              </div>
              <div className="w-1/10" />
              <div className="mt-2 mb-4 w-full md:w-2.25/5 relative font-semibold ">
                <label htmlFor="time" className="block  text-sm text-textDark">
                  Time<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  value={`${moment(selected.startTime, ['h:mm']).format('hh:mm A')} to ${moment(selected.endTime, [
                    'h:mm',
                  ]).format('hh:mm A')}`}
                  disabled
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2 focus:outline-none opacity-70"
                />
              </div>
              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label className="block  text-sm disabled">
                  Joining Link<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={link}
                  required
                  placeholder="Ex- Paste your link here"
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>

              {uploadRes.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <button
                  type="submit"
                  className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center  bg-primary text-white ml-auto"
                  // onClick={() => setModify(false)}
                >
                  {selected.link ? 'Edit' : 'Submit'}
                </button>
              )}
            </form>
          </div>
        </div>
      )}

      {openSummary && (
        <div
          className="flex h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-6/12 text-sm m-auto mt-10 rounded-2xl relative">
            <div className="border-b border-borderYellow flex items-center py-4">
              <span className="block font-bold px-6  mr-auto text-textBlue1 ">{`${selected?.subject} - ${moment(
                selected.date
              ).format('ll')}`}</span>
              {/* {selected.summary && (
              <button
                type="submit"
                className="border border-primary px-7 rounded-3xl text-xs font-bold focus:outline-none text-center py-2 text-primary"
                // onClick={() => setView(false)}
              >
                Edit Summary
              </button>
            )} */}
              <img
                src="assets/img/Close.svg"
                className="mx-4 h-3 cursor-pointer"
                alt=""
                onClick={() => handleClose()}
              />
            </div>
            <form className="m-6" autoComplete="off" onSubmit={handleSubmit2}>
              <div className="my-2 relative w-full">
                <span className="text-sm font-medium">
                  Lesson<span className="text-red-500 ml-1">*</span>
                </span>
                {/* <div
              className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
              style={{ overflowY: 'scroll' }}
            >
              <p>{selected?.title || ''}</p>
            </div> */}
                <DropDown
                  data={lessons?.course?.course?.map((x) => x.lessonName) || []}
                  width={'w-full'}
                  handleItemClick={(x) => {
                    setFormData({ ...formData, lesson: x });
                  }}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  title={formData.lesson || selected?.summary?.lesson || 'Select Lesson'}
                />
              </div>
              <div className="my-4 relative w-full">
                <span className="text-sm font-medium">
                  Title<span className="text-red-500 ml-1">*</span>
                </span>
                {/* <div
              className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
              style={{ overflowY: 'scroll' }}
            >
              <p>{selected?.title || ''}</p>
            </div> */}
                <input
                  type="text"
                  name="title"
                  // placeholder="All"
                  required
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-4 relative  w-full">
                <span className="text-sm font-medium">
                  Lecture Summary<span className="text-red-500 ml-1">*</span>
                </span>
                {/* <div
              className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
              style={{ overflowY: 'scroll' }}
            >
              <p>{selected?.summary || ''}</p>
            </div> */}
                <textarea
                  rows={8}
                  name="summary"
                  required
                  // placeholder="All"
                  value={formData.summary}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="pt-3 gap-x-4 w-full flex justify-end ">
                {/* {selected.notes && (
                  <a
                    className="border w-40 rounded-3xl text-xs font-semibold focus:outline-none text-center py-2 bg-primary text-white"
                    href={selected.notes.location}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Notes
                  </a>
                )} */}
                {summaryRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <button
                    type="submit"
                    className="border w-36 rounded-3xl text-xs font-semibold focus:outline-none text-center py-2 bg-primary text-white"
                  >
                    {selected.summary ? 'Edit' : 'Submit'}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {lectures.isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
      ) : _allLectures.length > 0 ? (
        _allLectures.map((x) => (
          <div className="w-full bg-white rounded-xl h-auto mb-5">
            <div className="items-center px-6 pt-3 pb-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold w-5/12">
                  <p>{`${x?.subject} (${x.class.name}-${x.section.name})`}</p>
                </div>

                <div className="relative mx-2 w-2/12">
                  <DropDown
                    data={['All Lectures', 'Delivered', 'Upcoming']}
                    width={'w-full'}
                    handleItemClick={(c) => {
                      setFilter({
                        ...filter,
                        [x.subject]: c,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-2/12 border-r-2 py-2 px-2">Lecture No</div>
                      <div className="w-4/12 border-r-2 py-2 px-2">Lecture Date</div>
                      <div className="w-3/12 border-r-2 py-2 px-2">Summary</div>
                      <div className="w-3/12 border-r-2  py-2 px-2">Status</div>
                      <div className="w-3/12 py-2 px-2">Live Session Link</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                    {Array.isArray(x?.lectures) && x.lectures.length > 0 ? (
                      x.lectures.map((y, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              x.lectures.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">
                              {idx + 1 < 10 ? '0' + (idx + 1) : idx + 1}
                            </div>
                            <div className="w-4/12 px-2 text-tableRowText">{moment(y.lectureDate).format('ll')}</div>
                            <div className="w-3/12 px-2">
                              {/* {y.summary && ( */}
                              <button
                                className="border border-primary text-xs w-10/12 px-8 py-2 rounded-3xl text-primary mx-1 focus:outline-none flex justify-center text-center items-center"
                                onClick={() => {
                                  setAction('summary');
                                  select({
                                    date: y.lectureDate,
                                    startTime: y.startTime,
                                    endTime: y.endTime,
                                    subject: x.subject,
                                    subjectId: x.subjectId,
                                    class: x.class,
                                    section: x.section,
                                    summary: y.summary,
                                  });
                                }}
                              >
                                {!y.summary ? 'Upload' : 'View'}
                              </button>
                              {/* )} */}
                            </div>
                            <div className="w-3/12 px-2 text-tableRowText">
                              {moment(y.lectureDate).isAfter(new Date()) ? 'Upcoming' : 'Lecture Delivered'}
                            </div>
                            <div className="w-3/12 px-2">
                              {moment(y.lectureDate).isAfter(new Date()) ? (
                                <button
                                  className="border border-primary px-8 py-2 rounded-3xl text-primary text-xs w-10/12 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                                  onClick={() => {
                                    setAction('add');
                                    select({
                                      date: y.lectureDate,
                                      startTime: y.startTime,
                                      endTime: y.endTime,
                                      subject: x.subject,
                                      subjectId: x.subjectId,
                                      class: x.class,
                                      section: x.section,
                                      link: y.link,
                                    });
                                  }}
                                >
                                  {y.link ? 'View' : 'Add'}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
      )}
    </div>
  );
};

export default LiveSessions;
