import React, { useState } from 'react';
import DropDown from '../../../components/UI/DropDown';
import SubjectMarks from '../../../components/Teacher/AdditionalResponsibility/SubjectMarks';

function Result({ setViewResult, data, students, selectedClass, examType }) {
  const [selectedSubjectForMarks, selectSubjectFrrMarks] = useState(false);

  return (
    <>
      {selectedSubjectForMarks && (
        <SubjectMarks selectSubjectFrrMarks={selectSubjectFrrMarks} selectedSubjectForMarks={selectedSubjectForMarks} />
      )}
      <div className="w-full bg-white rounded-xl h-auto mb-5 pb-4">
        <div className="text-xs text-tableRowText px-6 pt-3">
          {`Result Release (${selectedClass.y.class_type}) > ${selectedClass.y.classId.name} - ${selectedClass.y.sectionId.name}`}
        </div>
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>
                {`${selectedClass.y.classId.name} - ${selectedClass.y.sectionId.name}`}{' '}
                <span className="text-xs font-medium">{`Total Students - ${students}`}</span>
              </p>
            </div>
            <div className="flex justify-end items-center w-full">
              <DropDown
                data={['All', 'Uploaded/Created', 'Pending']}
                width={'w-1/4'}
                title={'Sort By'}
                handleItemClick={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{
                width: '96%',
              }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/6 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Total Marks</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Subject Teacher</div>
                  <div className="w-1/6 border-r-2 py-2 px-2">Total Fail</div>
                  <div className="w-1/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div
                className="customScrollBar"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '50vh',
                }}
              >
                {data.length > 0 ? (
                  data.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                          idx !== data.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-textDark">{x?.subjectName}</div>
                        <div className="w-1/5 px-2 text-textDark">{x?.subjectCode}</div>
                        <div className="w-1/5 px-2 text-textDark">{x?.totalMarks}</div>
                        <div className="w-1/5 px-2 text-textDark">{x?.subjectTeacher}</div>
                        <div className="w-1/5 px-2 text-textDark">{x?.total_fail}</div>
                        <div className="w-1/5 px-2">
                          <button
                            type="submit"
                            className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                            onClick={() =>
                              selectSubjectFrrMarks({
                                class: { classId: selectedClass.y.classId._id },
                                section: { sectionId: selectedClass.y.sectionId._id },
                                subject: { _id: x?.subjectId, subjectName: x?.subjectName },
                                marks: x?.totalMarks,
                              })
                            }
                          >
                            View Marks
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <button
          className="border border-primary w-36 py-2 rounded-3xl text-white bg-primary font-medium focus:outline-none flex justify-center text-center items-center text-xs mx-6"
          onClick={() => {
            setViewResult(false);
          }}
        >
          Back
        </button>
      </div>
    </>
  );
}

export default Result;
