import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { UPDATE_REMARKS } from '../../../api/urls/remarks';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import { useStateValue } from '../../../ContextApi/StateProvider';
import DropDown from '../../../components/UI/DropDown';

function ViewRemark({ handleClose, selected, refreshList }) {
  const [isEdit, setEdit] = useState(false);
  const [{ classSections }, dispatch] = useStateValue();

  const [combinedClassSection, setCombinedClassSection] = useState([]);
  const [studentList, setStudents] = useState([]);
  const [selectedStudent, selectStudent] = useState();

  const formDataModel = {
    student: '',
    sectionId: '',
    classId: '',
    studentName: '',
    userName: '',
    remarkType: '',
    remark: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const [remarkState, updateRemark] = useApi();
  const [students, getStudents] = useApi();

  useEffect(() => {
    if (selected) {
      setFormData({
        student: selected?.student_id?._id,
        sectionId: selected?.sectionId,
        classId: selected?.classId,
        studentName: selected?.studentName,
        userName: selected?.userName,
        remarkType: selected?.category,
        remark: selected?.remarks,
      });
      getStudents(SECTION_STUDENTS(selected.classId, selected.sectionId), 'GET');
    }
  }, [selected]);

  const handleReset = () => {
    setFormData({
      student: selected?.student_id?._id,
      sectionId: selected?.sectionId,
      classId: selected?.classId,
      studentName: selected?.studentName,
      userName: selected?.userName,
      remarkType: selected?.category,
      remark: selected?.remarks,
    });
    getStudents(SECTION_STUDENTS(selected.classId, selected.sectionId), 'GET');
    setEdit(false);
  };

  const getClassSectionName = (classId, sectionId) => {
    if (classId && sectionId) {
      const className = classSections?.filter((x) => x.id === classId)[0];
      const sectionsName = className?.sections?.filter((x) => x.id === sectionId)[0];
      return `${className?.name} - ${sectionsName?.name}`;
    }
    return '';
  };

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = () => {
    const body = {
      ...formData,
    };

    updateRemark(UPDATE_REMARKS(selected._id), 'PUT', body);
  };

  useEffect(() => {
    if (classSections && Array.isArray(classSections)) {
      let combinedClassSectionList = [];
      classSections?.forEach((x) => {
        x?.sections.forEach((section) => {
          combinedClassSectionList.push({
            classId: x.id,
            name: x.name + ' - ' + section.name,
            sectionId: section.id,
          });
        });
      });
      setCombinedClassSection(combinedClassSectionList);
    }
  }, [classSections]);

  useEffect(() => {
    if (!remarkState.isLoading && !remarkState.isError && [201, 202].includes(remarkState.status)) {
      setFormData(formDataModel);
      alert('SUCCESS');
      refreshList();
      handleClose();
    }

    if (remarkState.isError) {
      alert('FAILED');
    }
  }, [remarkState]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
    }
  }, [students]);

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-2/3 pb-2 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">{`${selected?.student_id?.full_name} - ${selected?.student_id?.username}`}</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
        </div>
        <div className="mb-6 mt-4 px-6 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">Select Class</label>
              {isEdit ? (
                <DropDown
                  field={'name'}
                  handleItemClick={(x) => {
                    setFormData({
                      ...formData,
                      classId: x.classId,
                      sectionId: x.sectionId,
                      student: '',
                      studentName: null,
                      userName: '',
                    });
                    setStudents([]);
                    getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
                  }}
                  title={getClassSectionName(formData?.classId, formData?.sectionId) || 'Select Class'}
                  data={combinedClassSection}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  type={'obj'}
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {getClassSectionName(formData?.classId, formData?.sectionId)}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">Remark Type</label>
              {isEdit ? (
                <input
                  type="text"
                  name="remarkType"
                  value={formData?.remarkType}
                  onChange={handleInputChange}
                  className="w-full text-xs border-borderLight rounded-3xl border px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {formData?.remarkType}
                </p>
              )}
            </div>

            <div className="mt-2 w-full relative font-medium">
              <label className="block text-textDark text-sm">Student Name</label>
              {isEdit ? (
                <DropDown
                  field={'full_name'}
                  handleItemClick={(x) => {
                    selectStudent(x);
                    setFormData({
                      ...formData,
                      student: x._id,
                      studentName: x.full_name,
                      userName: x.username,
                    });
                  }}
                  title={formData.studentName || 'Select Student'}
                  data={studentList}
                  width={'w-full'}
                  margin={'mt-2'}
                  otherClasses={'border-borderLight'}
                  type={'obj'}
                  key={formData.classId}
                />
              ) : (
                <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                  {formData.studentName}
                </p>
              )}
            </div>

            <div className="my-2.5 w-full relative font-medium">
              <label className="block text-textDark text-sm">ROLEDin ID</label>
              <p className="w-full  rounded-3xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                {formData.userName || 'Select Student'}
              </p>
            </div>

            <div className="my-2.5 w-full relative font-medium md:col-span-2">
              <label className="block text-textDark text-sm">Description</label>
              {isEdit ? (
                <textarea
                  rows="4"
                  cols="50"
                  name="remark"
                  value={formData?.remark}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-2xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              ) : (
                <p className="w-full rounded-xl text-textDark opacity-50 border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20">
                  {formData?.remark}
                </p>
              )}
            </div>
          </div>

          {isEdit ? (
            remarkState.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <div className="flex gap-x-4 justify-end mt-4">
                <button
                  className="border border-tableRowText py-2 rounded-3xl text-tableRowText text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-36"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>
                <button
                  className="border border-primary py-2 rounded-3xl text-white bg-primary text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-36"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
            )
          ) : (
            <button
              className="border border-tableRowText ml-auto mt-4 py-2 rounded-3xl text-tableRowText text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-36"
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewRemark;
