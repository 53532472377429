import React, { useEffect } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { APPROVE_LEAVE, REJECT_LEAVE } from '../../../api/urls/attendance';
import { useStateValue } from '../../../ContextApi/StateProvider';

function Deatils({ onClose, role = '', details }) {
  const [approveRes, approveLeave] = useApi();
  const [rejectRes, rejectLeave] = useApi();
  const [{ user }, dispatch] = useStateValue();

  const handleAction = (id, action) => {
    if (action === 'approve') approveLeave(APPROVE_LEAVE(id), 'PUT');
    else rejectLeave(REJECT_LEAVE(id), 'PUT');
  };

  useEffect(() => {
    if (!approveRes.isLoading && !approveRes.isError && [200, 201, 202, 204].includes(approveRes.status)) {
      alert('LEAVE APPROVED');
      onClose(true);
    }

    if (!approveRes.isLoading && approveRes.isError) {
      alert('Something went wrong');
    }
  }, [approveRes]);

  useEffect(() => {
    if (!rejectRes.isLoading && !rejectRes.isError && [200, 201, 202, 204].includes(rejectRes.status)) {
      alert('LEAVE REJECTED');
      onClose(true);
    }

    if (!rejectRes.isLoading && rejectRes.isError) {
      alert('Something went wrong');
    }
  }, [rejectRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-5/12 pb-4 rounded-2xl relative">
        <span className="block font-bold py-2 px-6 text-textBlue1 border-b border-borderYellow">Leave Details</span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 right-6 h-3 cursor-pointer"
          alt=""
          onClick={onClose}
        />
        <div className="mt-2 mb-4 mx-6 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            {user?.user?._id !== details?.user_id && (
              <>
                <div className="mt-2 relative font-semibold">
                  <label className="block text-textDark text-sm">Name</label>
                  <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                    {details?.user_id?.full_name || details?.student_id?.full_name}
                  </p>
                </div>
                <div className="mt-2 relative font-semibold">
                  <label className="block text-textDark text-sm">RoledIn ID</label>
                  <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                    {details?.user_id?.username || details?.student_id?.username}
                  </p>
                </div>
              </>
            )}
            <div className="mt-2 relative font-semibold">
              <label className="block text-textDark text-sm">Leave Type</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {details?.leave?.leave_type || details?.leave}
              </p>
            </div>
            <div></div>
            <div className="mt-2 mb-4 relative font-semibold ">
              <label className="block text-textDark text-sm">From</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {details?.date
                  ? moment(details?.date).format('ll')
                  : details?.start_date
                  ? moment(details?.start_date).format('ll')
                  : details?.startDate
                  ? moment(details?.startDate).format('ll')
                  : '--'}
              </p>
            </div>
            <div className="mt-2 mb-4 relative font-semibold ">
              <label className="block text-textDark text-sm">To</label>
              <p className="w-full rounded-3xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
                {details?.end_date
                  ? moment(details?.end_date).format('ll')
                  : details?.endDate
                  ? moment(details?.endDate).format('ll')
                  : '--'}
              </p>
            </div>
          </div>
          <div className=" mb-4 relative font-semibold ">
            <label className="block text-textDark text-sm">Reason</label>
            <p className="w-full rounded-2xl text-xs text-textDark border border-borderLight px-4 mt-2.5 py-2 focus:outline-none">
              {details?.reason || '--'}
            </p>
          </div>
          <div className="mt-2 mb-4 relative font-semibold ">
            <label className="block text-textDark text-sm">Attachment</label>
            {details?.files?.length > 0 ? (
              <div className="relative">
                <a
                  className="absolute border px-3 border-primary py-2 text-xs text-primary rounded-3xl mx-1 focus:outline-none flex justify-center text-center items-center right-0"
                  href={details?.files[0]?.location}
                  download
                >
                  Download Attachment
                </a>
                <img src={details?.files[0]?.location} className="mx-auto w-44 max-h-28 " alt="" />
              </div>
            ) : (
              <div className="border  border-borderLight h-32 flex justify-center items-center mt-2 rounded-2xl">
                No Attachment
              </div>
            )}
          </div>
        </div>

        {role === 'admin' && user?.user?._id !== details?.user_id && (
          <div className="w-full justify-end pr-5">
            {approveRes.isLoading || rejectRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
            ) : (
              <div className="ml-auto flex justify-end">
                <button
                  className="border w-1/5 border-borderLight py-2 text-xs rounded-3xl mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={() => handleAction(details._id, 'reject')}
                >
                  Reject
                </button>
                <button
                  className="border w-1/5 border-primary py-2 text-xs rounded-3xl bg-primary text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                  onClick={() => handleAction(details._id, 'approve')}
                >
                  Approve
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Deatils;
