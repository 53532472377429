import React, { useEffect, useState } from 'react';
import ViewStudentDetails from '../../../components/Teacher/AdditionalResponsibility/ViewStudentDetails';
import Search from '../../../components/UI/Search';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import { GET_TEMP_PWD } from '../../../api/urls/user';
import { useStateValue } from '../../../ContextApi/StateProvider';
import useApi from '../../../api/useApi';

function ClassTeacher() {
  const [{ user }, _] = useStateValue();

  const [selectedStudent, selectStudent] = useState();
  const [studentList, setStudents] = useState([]);
  const [selectedStep, selectStep] = useState(0);
  const [search, setSearch] = useState('');

  const [students, getStudents] = useApi();
  const [passwordRes, getPasswords] = useApi();

  let additionalResponsibilty = user?.user?.currentlyMapped?.additionalResponsibilty;
  let info = [];
  if (additionalResponsibilty && additionalResponsibilty.length > 0) {
    info = additionalResponsibilty.filter((x) => x.category === 'Class Teacher');
  }
  // console.log(" info : ", info )
  useEffect(() => {
    if (user && info.length > 0){
      getStudents(SECTION_STUDENTS(info[0].classID._id, info[0].sectionID._id), 'GET');
      getPasswords(GET_TEMP_PWD, 'POST');
    }  
  }, [user]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data && Array.isArray(students.data)) {
      setStudents(students?.data || []);
    }
  }, [students]);

  const toggleView = () => {
    selectStudent(null);
  };

  let _studentList = studentList || [];

  if (search) {
    _studentList = _studentList.filter((x) => x?.full_name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <>
      {selectedStudent && (
        <ViewStudentDetails
          toggleView={toggleView}
          selectedStudent={selectedStudent}
          selectedStep={selectedStep}
          refreshPasswords={() => getPasswords(GET_TEMP_PWD, 'POST')}
        />
      )}
      <div className="relative w-full md:w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-6/12">
                <p>{`Student Data (${info.length > 0 ? info[0]?.classID.name : ''} - ${
                  info.length > 0 ? info[0]?.sectionID.name : ''
                })`}</p>
              </div>
              <div className="flex justify-end items-center w-full">
                {/* <button
                  className="border border-primary w-40 py-2 mr-4 rounded-3xl text-white bg-primary text-xs font-medium focus:outline-none flex justify-center text-center items-center"
                  // onClick={() => setDetails(x)}
                >
                  Upload CSV
                </button> */}

                <Search
                  placeholder={'Search'}
                  width={'w-40'}
                  onChange={(x) => setSearch(x)}
                  onEnter={() => console.log('enter')}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">Student Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Father’s Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Student Data Status</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Action</div>
                    <div className="w-1/5 py-2 px-2">ROLEDin Id</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {students.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : _studentList.length === 0 ? (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  ) : (
                    _studentList.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                            idx !== _studentList.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 px-2 text-textDark">{x?.full_name}</div>
                          <div className="w-1/5 px-2 text-textDark">
                            {x?.parentDetail.length > 0 ? x?.parentDetail[0]?.father_name : ''}
                          </div>
                          <div className="w-1/5 px-2 text-textDark">Complete</div>
                          <div className="w-1/5 px-2">
                            <button
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() => {
                                selectStep(0);
                                selectStudent(x);
                              }}
                            >
                              View Detail
                            </button>
                          </div>
                          <div className="w-1/5 px-2">
                            <button
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() => {
                                selectStep(4);
                                selectStudent(x);
                              }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold w-6/12">
                <p>Passwords</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/3 border-r-2 py-2 px-2">ROLEDin Id</div>
                    <div className="w-1/3 border-r-2 py-2 px-2"> Name</div>
                    <div className="w-1/3 py-2 px-2">Password</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                  {passwordRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : Array.isArray(passwordRes.data) && passwordRes.data.length > 0 ? (
                    passwordRes.data.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                            idx !== passwordRes.data.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/3 px-2 text-textDark">{x?.userName}</div>
                          <div className="w-1/3 px-2 text-textDark">{x?.fullName}</div>
                          <div className="w-1/3 px-2 text-textDark">{x?.password}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassTeacher;
