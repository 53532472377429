import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useApi from '../../../api/useApi';
import DropDown from '../../../components/UI/DropDown';
import StudentMarks from '../../../components/Student/Assignment/SubjectMarks';
import { UPCOMING_TEXT, STUDENT_MARKS } from '../../../api/urls/test';
import { STUDENT_ASSIGNMENT, STUDENT_ASSIGNMENT_MARKS } from '../../../api/urls/notes';
import { useStateValue } from '../../../ContextApi/StateProvider';
import TestDetails from '../../../components/Student/Assignment/TestDetails';
import OnlineTest from '../../../components/Student/Assignment/OnlineTest';
import AssignmentDetails from '../../../components/Student/Assignment/AssignmentDetails';

export default function Assignment({ role }) {
  const [viewDetails, setViewDetails] = useState(false);
  const [marks, setMarks] = useState([]);
  const [selectedSubject, selectSubject] = useState();
  const [selectedTest, selectTest] = useState();
  const [testStarted, startTest] = useState(false);
  const [openTestDetails, setOpenDetails] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, selectAssignment] = useState();

  const [assignmentRes, getAssignments] = useApi();
  const [upcomingTests, getUpcomingTests] = useApi();
  const [marksState, getMarks] = useApi();
  const [assMarksState, getAssMarks] = useApi();

  const [{ user }, _] = useStateValue();

  useEffect(() => {
    getAssMarks(STUDENT_ASSIGNMENT_MARKS, 'GET');
    getAssignments(STUDENT_ASSIGNMENT, 'GET');
  }, []);

  useEffect(() => {
    if (user) {
      if (role === 'student') {
        const studentInfo = user?.user?.currentlyMapped?.classInfo;
        getMarks(STUDENT_MARKS(studentInfo?.classId, studentInfo?.sectionId, user.user._id), 'GET');
        getUpcomingTests(UPCOMING_TEXT(studentInfo?.classId, studentInfo?.sectionId), 'GET');
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo;
        getMarks(
          STUDENT_MARKS(childInfo?.classId, childInfo?.sectionId, user?.Responsbility[0]?.childInfo?._id),
          'GET'
        );
        getUpcomingTests(UPCOMING_TEXT(childInfo?.classId, childInfo?.sectionId), 'GET');
      }
    }
  }, [user]);

  const refreshTests = () => {
    if (user) {
      if (role === 'student') {
        const studentInfo = user?.user?.currentlyMapped?.classInfo;
        getUpcomingTests(UPCOMING_TEXT(studentInfo?.classId, studentInfo?.sectionId), 'GET');
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo;
        getUpcomingTests(UPCOMING_TEXT(childInfo?.classId, childInfo?.sectionId), 'GET');
      }
    }
  };

  useEffect(() => {
    const { isLoading, isError, data } = marksState;
    if (
      !isLoading &&
      !isError &&
      data &&
      Array.isArray(data) &&
      !assMarksState.isLoading &&
      !assMarksState.isError &&
      assMarksState.data &&
      Array.isArray(assMarksState.data)
    ) {
      const subjectWise = new Map();
      // console.log(" error here : marksState : ", marksState.data );
      // console.log(" error here : assMarksState : ", assMarksState.data );
      data.forEach((x) => {
        if( x.test_id.subject_id?.subjectCode ){
          if (subjectWise.has(x.test_id.subject_id.subjectCode)) {
            let y = [...subjectWise.get(x.test_id.subject_id.subjectCode), x];
            subjectWise.set(x.test_id.subject_id.subjectCode, y);
          } else {
            subjectWise.set(x.test_id.subject_id.subjectCode, [x]);
          }
        } 
      });

      assMarksState.data.forEach((x) => {
        if( x.subject?.subjectCode ){
          if (subjectWise.has(x.subject.subjectCode)) {
            let y = [...subjectWise.get(x.subject.subjectCode), x];
            subjectWise.set(x.subject.subjectCode, y);
          } else {
            subjectWise.set(x.subject.subjectCode, [x]);
          }
        } 
      });

      setMarks([...Array.from(subjectWise.values())]);
    }
  }, [marksState, assMarksState]);

  // useEffect(() => {
  //   const { isLoading, isError, data } = assMarksState;
  //   if (!isLoading && !isError && data && Array.isArray(data)) {
  //     const subjectWise = marks;
  //     console.log({ subjectWise });
  //     data.forEach((x) => {
  //       if (subjectWise.has(x.subject.subjectCode)) {
  //         let y = [...subjectWise.get(x.subject.subjectCode), x];
  //         subjectWise.set(x.subject.subjectCode, y);
  //       } else {
  //         subjectWise.set(x.subject.subjectCode, [x]);
  //       }
  //     });

  //     setMarks([...Array.from(subjectWise.values())]);
  //   }
  // }, [marksState]);

  useEffect(() => {
    const { isLoading, isError, data } = assignmentRes;
    if (!isLoading && !isError && data && Array.isArray(data)) {
      let list = [];
      data.forEach((x) => {
        console.log(x.start);
        let idx = list.findIndex((y) => y.subject_id === x.subject_id);
        if (idx !== -1) {
          let m = list[idx];
          m.assignments = [
            { title: x.title, summary: x.summary, start: x.start, end: x.end, files: x.files, _id: x._id },
            ...m.assignments,
          ];
          m.submitted = x.files ? m.submitted + 1 : m.submitted;
          list[idx] = m;
        } else {
          list.push({
            subject_id: x.subject_id,
            subject: x.subject.subjectName,
            subjectCode: x.subject.subjectCode,
            assignments: [
              { title: x.title, summary: x.summary, start: x.start, end: x.end, files: x.files, _id: x._id },
            ],
            class_id: x.class_id,
            section_id: x.section_id,
            submitted: x.files ? 1 : 0,
          });
        }
      });
      setAssignments(list);
    }
  }, [assignmentRes]);

  const handleCloseAssignment = () => {
    selectAssignment(null);
    setViewDetails(false);
    getAssignments(STUDENT_ASSIGNMENT, 'GET');
  };

  return (
    <>
      {selectedSubject && <StudentMarks selectedSubject={selectedSubject} selectSubject={selectSubject} />}

      {openTestDetails && (
        <TestDetails selectedTest={selectedTest} setOpenDetails={setOpenDetails} startTest={startTest} />
      )}

      {testStarted && <OnlineTest startTest={startTest} selectedTest={selectedTest} refreshTests={refreshTests} />}

      {viewDetails && (
        <AssignmentDetails selectedAssignment={selectedAssignment} handleClose={handleCloseAssignment} role={role} />
      )}

      <div className="w-full md:w-4/5">
        {/* Assignments */}
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3" style={{ borderBottom: '0.71px solid #E7E700' }}>
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Assignment Details</p>
              </div>

              <div className="relative">
                {/* <DropDown
                  width={'w-40'}
                  data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                  title={'Sort by'}
                  handleItemClick={(x) => {}}
                  otherClasses={'border-borderLight mt-2 w-full'}
                /> */}
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl min-w-700"
                style={{ borderColor: '#E7E700', width: '96%' }}
              >
                <div className="w-full  bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Subject Code</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Total Assignments</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Pending</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Submitted</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="overflow-auto customScrollBar" style={{ maxHeight: '45vh' }}>
                  {assignmentRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : (
                    Array.isArray(assignments) &&
                    assignments.map((x, idx) => (
                      <div className={`w-full ${assignments.length - 1 !== idx && 'border-b border-tableHeader'}`}>
                        <div className="justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center">
                          <div className="w-1/6 text-tableRowText px-2">{x.subjectCode}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.subject}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.assignments.length}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.assignments.length - x.submitted}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.submitted}</div>
                          <div className="w-1/6 text-tableRowText px-2">
                            <button
                              type="submit"
                              className="border border-primary  py-2 rounded-3xl text-primary font-medium  focus:outline-none flex justify-center text-center items-center w-11/12"
                              onClick={() => {
                                selectAssignment(x);
                                setViewDetails(true);
                              }}
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Upcoming Test and Examinations */}
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Upcoming Test and Examinations</p>
              </div>

              <div className="relative">
                {/* <DropDown
                  width={'w-40'}
                  data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                  title={'Sort by'}
                  handleItemClick={(x) => {}}
                  otherClasses={'border-borderLight mt-2 w-full'}
                /> */}
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl min-w-700"
                style={{ borderColor: '#E7E700', width: '96%' }}
              >
                <div className="w-full  bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Type</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Date</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Timings</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Mode</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                  {upcomingTests.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : (
                    upcomingTests.data &&
                    Array.isArray(upcomingTests.data) &&
                    upcomingTests?.data.map((x, idx) => (
                      <div
                        key={x._id}
                        className={`justify-center flex w-full relative text-left text-xs items-center ${
                          idx !== upcomingTests.data.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="justify-center flex w-full relative text-left py-3.5 px-4 text-xs font-medium items-center">
                          <div className="w-1/6 text-tableRowText px-2">{x.testDetail.title}</div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {moment(x.testDetail.test_date).format('ll')}
                          </div>
                          <div className="w-1/6 text-tableRowText px-2">{`${moment(x.testDetail.start).format(
                            'hh:mm a'
                          )} to ${moment(x.testDetail.end).format('hh:mm a')}`}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.subject_name}</div>
                          <div className="w-1/6 text-tableRowText px-2">{x.testDetail.mode}</div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {x.testDetail.mode.toLowerCase() === 'online' && (
                              <button
                                type="submit"
                                className={`border ${
                                  role === 'Parent'
                                    ? 'border-borderLight text-tableRowText opacity-60'
                                    : 'border-primary text-primary opacity-100'
                                }  w-11/12 mx-auto py-2 rounded-3xl  font-medium  focus:outline-none flex justify-center text-center items-center`}
                                disabled={role === 'Parent'}
                                onClick={() => {
                                  setOpenDetails(true);
                                  selectTest(x);
                                }}
                              >
                                View Detail
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Marks */}
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 pt-3 pb-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold ">
                <p>Test and Assignment Marks</p>
              </div>

              <div className="relative">
                <DropDown
                  width={'w-40'}
                  data={['Ascending Order', 'Descending Order', 'Name-a to z', 'Name-z to a']}
                  title={'Sort by'}
                  handleItemClick={(x) => {}}
                  otherClasses={'border-borderLight mt-2 w-full'}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow" style={{ width: '96%' }}>
                <div className="w-full  bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                    <div className="w-3/5 border-r-2 py-2 px-2">Subject</div>
                    <div className="w-1/5 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                  {marksState.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : (
                    Array.isArray(marks) &&
                    marks?.map((x, idx) => (
                      <div
                        key={x[0]._id}
                        className={`justify-center flex w-full relative text-left text-xs items-center ${
                          idx !== marks.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="justify-center flex w-full relative text-left py-3.5 px-4 text-xs font-medium items-center">
                          <div className="w-1/5 text-tableRowText px-2">
                            {x[0]?.test_id?.subject_id?.subjectCode || x[0]?.subject?.subjectCode}
                          </div>
                          <div className="w-3/5 text-tableRowText px-2">
                            {x[0]?.test_id?.subject_id?.subjectName || x[0]?.subject?.subjectName}
                          </div>
                          <div className="w-1/5 text-tableRowText px-2">
                            <button
                              type="submit"
                              className="border border-primary w-11/12 mx-auto py-2 rounded-3xl text-primary font-medium  focus:outline-none flex justify-center text-center items-center"
                              onClick={() => selectSubject(x)}
                            >
                              View Marks
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
