import React from 'react';

function ExamDetails({ toggleOpenExamDetails }) {
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-4/5 text-sm block m-auto mt-16 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">1 - A</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={toggleOpenExamDetails} />
        </div>

        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Name</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Date - Class Test (1) </div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Date - Class Test (2) </div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Date - Unit Test (1) </div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Date - Unit Test (2) </div>
                  <div className="w-1/5 py-2 px-2">Date -Term Exam</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                {[1, 2, 3, 4, 5].map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          idx !== 4 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-textDark">Subject 1</div>
                        <div className="w-1/5 px-2 text-textDark">Code 1</div>
                        <div className="w-1/5 px-2 text-tableRowText">26 December 2021</div>
                        <div className="w-1/5 px-2 text-tableRowText">26 December 2021</div>
                        <div className="w-1/5 px-2 text-tableRowText">26 December 2021</div>
                        <div className="w-1/5 px-2 text-tableRowText">26 December 2021</div>
                        <div className="w-1/5 px-2 text-tableRowText">26 December 2021</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamDetails;
